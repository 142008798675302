exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3PBdNJaiFFLqwUiTKPn_8L{\n    display: table-row-group;\n    cursor: pointer;\n    transition: all .2s ease-in-out;\n}\n._3PBdNJaiFFLqwUiTKPn_8L:hover{\n    /* transform: scale(1.01); */\n    background-color: #d9d9d9 !important;\n}\n.bFFx-25Y1cvblaFHag154{\n    display: table-row;\n    vertical-align: middle;\n}\n._2ft0fftQaupCEAnINvc5VM{\n    display: table-cell;\n    vertical-align: middle;\n    padding: 5px;\n}\n._1BqNl2Uu6rE8TOrJ3lJy2Q{\n    width: 45px;\n}\n._2UfahW_Yg-VpZPMiIspP2j, .pfv9UboUbh46ANdpj4nvC{\n    font-weight: normal;\n}\n.pfv9UboUbh46ANdpj4nvC{\n    width: 100px;\n}\n\n._1ZUBU3PYkPBYRHcHmC7eop{}\n._1EQ5fj5t4u1NSTh9reUz3D{}\n._298GWHkJebpCp_DlVDx3bc{}\n._1aDGkbne_x3fjHr5Ofwp3-{\n    width: 130px;\n    text-align: right;\n}\n._2ykXwAvhZRgnuZPlNlZkjn{\n    width: 27px;\n    padding-right: 10px;\n    cursor: pointer;\n}\n.M49ktFaYYTJoteWsdRm50{\n    width: 12px;\n}\n._37kdu46PtXjJFj-xxSln3k{}\n._3LKyJSIA4FTlteGNkXYB8_{\n    width: 50px;\n}\n._3LKyJSIA4FTlteGNkXYB8_ button:hover{\n    background-color: #f2c004;\n    border-color: #f2c004;\n}", ""]);

// exports
exports.locals = {
	"container": "_3PBdNJaiFFLqwUiTKPn_8L",
	"overviewRow": "bFFx-25Y1cvblaFHag154",
	"cell": "_2ft0fftQaupCEAnINvc5VM",
	"statusCell": "_1BqNl2Uu6rE8TOrJ3lJy2Q",
	"customerNameCell": "_2UfahW_Yg-VpZPMiIspP2j",
	"quoteNumberCell": "pfv9UboUbh46ANdpj4nvC",
	"ownerNameCell": "_1ZUBU3PYkPBYRHcHmC7eop",
	"variantsDescriptionCell": "_1EQ5fj5t4u1NSTh9reUz3D",
	"distributorCell": "_298GWHkJebpCp_DlVDx3bc",
	"dateCell": "_1aDGkbne_x3fjHr5Ofwp3-",
	"collapseIconCell": "_2ykXwAvhZRgnuZPlNlZkjn",
	"icon": "M49ktFaYYTJoteWsdRm50",
	"statusIcon": "_37kdu46PtXjJFj-xxSln3k",
	"duplicateCell": "_3LKyJSIA4FTlteGNkXYB8_"
};