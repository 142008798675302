import * as React from 'react';
import styleable from 'react-styleable';
import * as styles from '../../css/components/ArticleTableRow.css'
import StaircaseShapeAPI from '../apicontroller/staircasedata/StaircaseShapeAPI';
import { RouteComponentProps } from 'react-router';
import IArticleInput from '../../../../shared/datastructures/IArticleInput';
import ArticleCalculation from '../../../../shared/datastructures/ArticleCalculation';
import ArticleInput from '../../../../shared/datastructures/articleinput/ArticleInput';
import ArticleInputDiff, { calculateDifferences } from '../../../../shared/datastructures/diff/ArticleInputDiff';
import ArticleDiff from './ArticleDiff';
import ObjectID from 'bson-objectid';
import { CheckIcon, DeleteIcon, DuplicateIcon, NoCheckIcon, VariantIcon } from './Icon';
import { t } from 'ttag';

/**
 * Article table row.
 *
 * ## styleable
 * * **container** Container styling.
 * * **overviewRow** The first row with the basic article overview.
 * * **cell** Single cell styling.
 *
 * @property {IArticleInput} props.article - Article
 * @property {number} props.distance
 * @property {IArticleInput[]} props.children - Articles
 * @property {string} props.title
 * @property {(IArticleInput?)=>any} props.onClick - onClick callback
 * @property {(IArticleInput?)=>any} props.onVariant - onVariant callback
 * @property {(IArticleInput?)=>any} props.onIncludeVariantToggle - onIncludeVariant callback
 *
 */
class ArticleTableRow extends React.Component<{article: IArticleInput, distance: number, children: IArticleInput[], css, onClick, onVariant, onDuplicate, onDelete, onIncludeVariantToggle, title, distributorId: ObjectID, quoteCalculationVersion: number} & RouteComponentProps, {totalPrice, diff: ArticleInputDiff[], expanded: Set<string>}> {

    constructor(_){
        super(_);
        this.state = {
            totalPrice: {},
            diff: [],
            expanded: new Set(),
        }
    }

    private async calc(){
        const allArticles: IArticleInput[] = [];
        allArticles.push(this.props.article);
        this.props.children.forEach(c => allArticles.push(c));
        allArticles.forEach(async (a: IArticleInput) => {
            (await ArticleCalculation.init(a, this.props.distance, this.props.distributorId, this.props.quoteCalculationVersion)).calculate().then(onfulfilled => {
                this.setState({totalPrice: {...this.state.totalPrice, [a.id.toString()]: onfulfilled.totalPrice}})
            })
        })

        // Calculate differences
        this.setState({diff: await calculateDifferences(this.props.article, this.props.children)});
    }

    componentDidUpdate(prevProps){
        if(this.props.article !== prevProps.article || this.props.children.find((x, i) => x !== prevProps.children[i]))
            this.calc();
    }

    componentDidMount(){
        this.calc();
    }

    toggleExpansion(article: IArticleInput){
        if(this.isExpanded(article)){
            const newExpanded: Set<string> = new Set(this.state.expanded);
            newExpanded.delete(article.id!.toString()); // TODO: ID is in reality not stored as ObjectID, fix this (#202)
            this.setState({
                expanded: newExpanded,
            })
        }else{
            const newExpanded: Set<string> = new Set(this.state.expanded);
            newExpanded.add(article.id!.toString()); // TODO: ID is in reality not stored as ObjectID, fix this (#202)
            this.setState({
                expanded: newExpanded,
            })
        }
    }

    isExpanded(article: IArticleInput){
        return this.state.expanded.has(article.id!.toString()); // TODO: ID is in reality not stored as ObjectID, fix this (#202)
    }

    render() {

        // TODO: add support for variants on article

        const openArticle = (article: IArticleInput)=>{
            if(this.props.onClick !== undefined)
                this.props.onClick(article);
        }

        const createVariant = (article: IArticleInput)=>{
            if(this.props.onVariant !== undefined)
                this.props.onVariant(article);
        }

        const createDuplicate = (article: IArticleInput)=>{
            if(this.props.onDuplicate !== undefined)
                this.props.onDuplicate(article);
        }

        const deleteArticle = (article: IArticleInput)=>{
            if(this.props.onDelete !== undefined)
                this.props.onDelete(article);
        }

        const includeVariantToggle = (article: IArticleInput)=>{
            if(this.props.onIncludeVariantToggle !== undefined)
                this.props.onIncludeVariantToggle(article);
        }

        return (
            <div className={this.props.css.group} >
                <div className={this.props.css.mainGroup} >
                    <div onClick={()=>openArticle(this.props.article)} className={this.props.css.mainLine + " " + (ArticleInput.isIncluded(this.props.article) ? this.props.css.selectedLine : this.props.css.notSelectedLine)}>
                        <table>
                            <tbody>
                                <tr>
                                    <td className={this.props.css.cell + " " + this.props.css.frontCell}>
                                        <button onClick={(e) => {
                                            e.stopPropagation();
                                            includeVariantToggle(this.props.article);
                                        }} title={t`Variant opnemen in offerte of order`}>{ArticleInput.isIncluded(this.props.article) ? <CheckIcon/> : <NoCheckIcon/>}</button>
                                    </td>
                                    <td className={this.props.css.cell + " " + this.props.css.titleCell}>
                                        {this.props.title}
                                    </td>
                                    <td className={this.props.css.cell + " " + this.props.css.shapeCell}>
                                        {
                                            // TODO: replace 'as StaircaseShapeAPI': this is done because getStaircaseShape() can return a Promise as well, but that won't happen since all articles are StaircaseShapeAPI objects, while Article should also support StaircaseShapeDBC objects to be able to use it on the server
                                        }
                                        <b>{this.props.article.reference ? this.props.article.reference + " - " : ""}</b>
                                        {(this.props.article.shape.staircaseShape as StaircaseShapeAPI).getTitle().getLanguages()[0].getValue()}
                                    </td>
                                    <td className={this.props.css.cell + " " + this.props.css.amountCell}>
                                        {this.props.article.unitCount}
                                    </td>
                                    <td className={this.props.css.cell + " " + this.props.css.priceCell}>
                                        {this.state.totalPrice[this.props.article.id.toString()] ? this.state.totalPrice[this.props.article.id.toString()].totalPrice.toFixed(2) : ""}
                                    </td>
                                    <td className={this.props.css.cell + " " + this.props.css.actionsCell}>
                                        <button onClick={(e) => {
                                            e.stopPropagation();
                                            createVariant(this.props.article);
                                        }} title={t`Maak een variant`}><VariantIcon/></button>
                                        <button onClick={(e) => {
                                            e.stopPropagation();
                                            createDuplicate(this.props.article);
                                        }} title={t`Maak een kopie`}><DuplicateIcon/></button>
                                        <button onClick={(e) => {
                                            e.stopPropagation();
                                            deleteArticle(this.props.article);
                                        }} title={t`Verwijder artikel en varianten`}><DeleteIcon/></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={this.props.css.variantsGroup}>
                    
                        {(() => {
                            const variants: React.ReactNode[] = [];

                            this.props.children.forEach((c, i) => {
                                const variantDiff: ArticleInputDiff = this.state.diff[i] ? this.state.diff[i] : null;
                                // console.log(i, variantDiff)
                                variants.push(
                                    <div className={this.props.css.variantLine + " " + (ArticleInput.isIncluded(c) ? this.props.css.selectedLine : this.props.css.notSelectedLine)} key={"variant" + c.id}>
                                        <div onClick={() => openArticle(c)} className={this.props.css.variantLineTop}>
                                            <table >
                                                <tbody>
                                                    <tr>
                                                        <td className={this.props.css.cell + " " + this.props.css.frontCell}>
                                                            <button onClick={(e) => {
                                                                e.stopPropagation();
                                                                includeVariantToggle(c);
                                                            }} title={t`Variant opnemen in offerte of order`}>{ArticleInput.isIncluded(c) ? <CheckIcon/> : <NoCheckIcon/>}</button>
                                                        </td>
                                                        <td className={this.props.css.cell + " " + this.props.css.titleCell}>
                                                            {i+1}
                                                        </td>
                                                        <td className={this.props.css.cell + " " + this.props.css.shapeCell}>
                                                            {
                                                                // TODO: replace 'as StaircaseShapeAPI': this is done because getStaircaseShape() can return a Promise as well, but that won't happen since all articles are StaircaseShapeAPI objects, while Article should also support StaircaseShapeDBC objects to be able to use it on the server
                                                            }
                                                            <b>{c.reference ? c.reference + " - " : ""}</b>
                                                            {(c.shape.staircaseShape as StaircaseShapeAPI).getTitle().getLanguages()[0].getValue()}
                                                        </td>
                                                        <td className={this.props.css.cell + " " + this.props.css.amountCell}>
                                                            {c.unitCount}
                                                        </td>
                                                        <td className={this.props.css.cell + " " + this.props.css.priceCell}>
                                                            {this.state.totalPrice[c.id.toString()] ? this.state.totalPrice[c.id.toString()].totalPrice.toFixed(2) : ""}
                                                        </td>
                                                        <td className={this.props.css.cell + " " + this.props.css.actionsCell}>
                                                            {variantDiff?.differs() ? <button onClick={(e) => {
                                                                e.stopPropagation();
                                                                this.toggleExpansion(c);
                                                            }}>{this.isExpanded(c) ? "Minder" : "Meer"}</button> : undefined}
                                                            <button onClick={(e) => {
                                                                e.stopPropagation();
                                                                createVariant(c);
                                                            }} title={t`Maak een variant`}><VariantIcon/></button>
                                                            <button onClick={(e) => {
                                                                e.stopPropagation();
                                                                createDuplicate(c);
                                                            }} title={t`Maak een kopie`}><DuplicateIcon/></button>
                                                            <button onClick={(e) => {
                                                                e.stopPropagation();
                                                                deleteArticle(c);
                                                            }} title={t`Verwijder artikel`}><DeleteIcon/></button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        {(() => ( this.isExpanded(c) ?
                                            <div className={this.props.css.variantLineExpansion}>
                                                <ArticleDiff 
                                                    diff={variantDiff}
                                                />
                                            </div>
                                        : ""))()}
                                    </div>
                                )
                            })

                            return variants;
                        })()}
                </div>
            </div>
        );
    }
}
export default styleable(styles)(ArticleTableRow)