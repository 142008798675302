exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2eCSiV4ujoOpXNFoivm9F2{\n    font-weight: bold;\n    font-size: 15px;\n}", ""]);

// exports
exports.locals = {
	"subtitle": "_2eCSiV4ujoOpXNFoivm9F2"
};