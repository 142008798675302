exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3HF9ovNd83OUDBdSADyzlB{\n    padding: 0 20px 0 20px;\n    overflow: hidden;\n}\n._3HF9ovNd83OUDBdSADyzlB:hover{\n    height: auto;\n    border-style: solid;\n    border-width: 0 0 1px 0;\n    border-color: #3F3F3F;\n}", ""]);

// exports
exports.locals = {
	"group": "_3HF9ovNd83OUDBdSADyzlB"
};