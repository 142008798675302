import * as React from "react";
import { connect } from "react-redux";
import { loadRiserThickness, loadTreadThickness, loadStringerThickness } from "../../loader";
import { dt } from "webc-reactcore/src/js/stores/GlobalStore";
import { toSelectItems, createTitlePortalCollapsibleLanguageField, createDescriptionPortalCollapsibleLanguageField, createApiTitlePortalCollapsibleLanguageField, createApiDescriptionPortalCollapsibleLanguageField } from "./helper";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import SimpleSettingsTemplate from "./SimpleSettingsTemplate";
import ConfigBlock from "../../components/ConfigBlock";
import MappedInput from "webc-reactcore/src/js/components/mainlayout/MappedInput";
import { inu, toNumber, inn } from "orbiter-core/src/basic";
import { t } from "ttag";
import { RiserThicknessAPI, TreadThicknessAPI, ThicknessAPI, StringerThicknessAPI } from "../../apicontroller/staircasedata/ThicknessAPI";
import Thickness from "../../../../../shared/datastructures/staircasedata/Thickness";
import SettingsTemplate from "./SettingsTemplate";
import ToolbarContextProvider from "webc-reactcore/src/js/components/toolbar/ToolbarContextProvider";
import StyledToolbar from "./StyledToolbar";
import InfoScreen from "../../components/InfoScreen";

@(connect((store: any) => {
    return { settings: { ...store.settings }, globalInformation: { ...store.globalInformation } };
}) as any)
export default class ThicknessSettings extends React.Component<any, any> {

    private async createEmpty(): Promise<Thickness> {
        return new Thickness(0, 0, new MultiLanguageString(), new MultiLanguageString());
    }

    public riserThicknessToSelectItems() {
        return toSelectItems<RiserThicknessAPI>("riserthickness", this.props.globalInformation.riserThickness.sort((x, y) => dt(x.getTitle()) < dt(y.getTitle()) ? -1 : 1), (x) => RiserThicknessAPI.clone(x), (x) => dt(x.getTitle()));
    }

    public riserThicknessToDropdownSelectItems() { // TODO: unify with toSelectItems
        const list = [];
        for (const riserThickness of this.props.globalInformation.riserThickness.sort((x, y) => dt(x.getTitle()) < dt(y.getTitle()) ? -1 : 1)) {
            list.push(
                {
                    title: riserThickness.getTitle().getLanguages()[0].getValue(),
                    id: riserThickness.getSid(),
                    riserThickness,
                }
            ); // TODO: Multilanguage support!!
        }
        return list;
    }

    private async createRiserThickness(data: Thickness) {
        await RiserThicknessAPI.create(data);
    }

    public stringerThicknessToSelectItems() {
        return toSelectItems<StringerThicknessAPI>("stringerthickness", this.props.globalInformation.stringerThickness.sort((x, y) => dt(x.getTitle()) < dt(y.getTitle()) ? -1 : 1), (x) => StringerThicknessAPI.clone(x), (x) => dt(x.getTitle()));
    }

    private async createStringerThickness(data: Thickness) {
        await StringerThicknessAPI.create(data);
    }

    public treadThicknessToSelectItems() {
        return toSelectItems<TreadThicknessAPI>("treadthickness", this.props.globalInformation.treadThickness.sort((x, y) => dt(x.getTitle()) < dt(y.getTitle()) ? -1 : 1), (x) => TreadThicknessAPI.clone(x), (x) => dt(x.getTitle()));
    }

    private async createTreadThickness(data: Thickness) {
        await TreadThicknessAPI.create(data);
    }

    public render() {
        return <SettingsTemplate title={"diktes."}>
            <ToolbarContextProvider>
                <StyledToolbar />
                <SimpleSettingsTemplate
                    title="Dikte treden"
                    selectItems={this.treadThicknessToSelectItems()}

                    reload={loadTreadThickness.bind(this)}
                    createEmpty={this.createEmpty.bind(this)}
                    create={this.createTreadThickness.bind(this)}
                    clone={(i: TreadThicknessAPI) => TreadThicknessAPI.clone(i)}

                    renderEdit={(a: ThicknessAPI, setSelected: (i: ThicknessAPI) => void) => this.renderEdit("trede", TreadThicknessAPI, a, setSelected)}
                    renderCreate={(a: Thickness, setSelected: (i: Thickness) => void) => this.renderCreate("trede", a, setSelected)}

                    excludeSettingsTemplate={true}

                    infoScreen={<InfoScreen title={t`Selecteer een dikte.`} image={"/cdn/images/ruler.svg"} />}
                />
            </ToolbarContextProvider>
            <hr />
            <ToolbarContextProvider>
                <StyledToolbar />
                <SimpleSettingsTemplate
                    title="Dikte tegentreden"
                    selectItems={this.riserThicknessToSelectItems()}

                    reload={loadRiserThickness.bind(this)}
                    createEmpty={this.createEmpty.bind(this)}
                    create={this.createRiserThickness.bind(this)}
                    clone={(i: RiserThicknessAPI) => RiserThicknessAPI.clone(i)}

                    renderEdit={(a: ThicknessAPI, setSelected: (i: ThicknessAPI) => void) => this.renderEdit("tegentrede", RiserThicknessAPI, a, setSelected)}
                    renderCreate={(a: Thickness, setSelected: (i: Thickness) => void) => this.renderCreate("tegentrede", a, setSelected)}

                    excludeSettingsTemplate={true}

                    infoScreen={<InfoScreen title={t`Selecteer een dikte.`} image={"/cdn/images/ruler.svg"} />}
                />
            </ToolbarContextProvider>

            <hr />
            <ToolbarContextProvider>
                <StyledToolbar />
                <SimpleSettingsTemplate
                    title="Dikte wangen"
                    selectItems={this.stringerThicknessToSelectItems()}

                    reload={loadStringerThickness.bind(this)}
                    createEmpty={this.createEmpty.bind(this)}
                    create={this.createStringerThickness.bind(this)}
                    clone={(i: StringerThicknessAPI) => StringerThicknessAPI.clone(i)}

                    renderEdit={(a: ThicknessAPI, setSelected: (i: ThicknessAPI) => void) => this.renderEdit("wang", StringerThicknessAPI, a, setSelected)}
                    renderCreate={(a: Thickness, setSelected: (i: Thickness) => void) => this.renderCreate("wang", a, setSelected)}

                    excludeSettingsTemplate={true}

                    infoScreen={<InfoScreen title={t`Selecteer een dikte.`} image={"/cdn/images/ruler.svg"} />}
                />
            </ToolbarContextProvider>
        </SettingsTemplate>;
    }

    private renderEdit(descriptiveType: string, apiCls: typeof ThicknessAPI, a: ThicknessAPI, setSelected: (i: ThicknessAPI) => void) {
        return this.doRender(
            descriptiveType,
            createApiTitlePortalCollapsibleLanguageField(a, (x) => apiCls.clone(x), setSelected.bind(this)),
            createApiDescriptionPortalCollapsibleLanguageField(a, (x) => apiCls.clone(x), setSelected.bind(this)),
            <MappedInput placeholder={t`Prijs`}
                onBlur={(price) => {
                    const cl: ThicknessAPI = apiCls.clone(a);
                    try {
                        cl.getData().setPrice(toNumber(price));
                    } catch (e) {
                        cl.getData().setPrice(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getPrice(), () => "")} />,
            <MappedInput placeholder={t`Dikte`}
                onBlur={(thickness) => {
                    const cl: ThicknessAPI = apiCls.clone(a);
                    try {
                        cl.getData().setThickness(toNumber(thickness));
                    } catch (e) {
                        cl.getData().setThickness(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getThickness(), () => "")} />,
        );
    }

    private renderCreate(descriptiveType: string, a: Thickness, setSelected: (i: Thickness) => void) {
        return this.doRender(
            descriptiveType,
            createTitlePortalCollapsibleLanguageField(a, setSelected.bind(this)),
            createDescriptionPortalCollapsibleLanguageField(a, setSelected.bind(this)),
            <MappedInput placeholder={t`Prijs`}
                onBlur={(price) => {
                    const cl: Thickness = a.clone();
                    try {
                        cl.setPrice(toNumber(price));
                    } catch (e) {
                        cl.setPrice(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getPrice(), () => "")} />,
            <MappedInput placeholder={t`Dikte`}
                onBlur={(thickness) => {
                    const cl: Thickness = a.clone();
                    try {
                        cl.setThickness(toNumber(thickness));
                    } catch (e) {
                        cl.setThickness(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getThickness(), () => "")} />,
        );
    }

    private doRender(
        type: string,
        titlePCLF: React.ReactNode,
        descriptionPCLF: React.ReactNode,
        priceMI: React.ReactNode,
        thicknessMI: React.ReactNode,
    ): React.ReactNode {
        return <div className={"container-fluid"}>
            <div className="row">
                <div className="col-md-6">
                    <ConfigBlock
                        title="Titel"
                        description={"Geef een titel in voor deze " + type + "-dikte."}
                        topMost={true}
                    >
                        {titlePCLF}
                    </ConfigBlock>

                    <ConfigBlock
                        title="Prijs"
                        description={"Geef een prijs in voor deze " + type + "-dikte."}
                        topMost={false}
                    >
                        {priceMI}
                    </ConfigBlock>
                </div>
                <div className="col-md-6">
                    <ConfigBlock
                        title="Omschrijving"
                        description={"Geef een omschrijving in voor deze " + type + "-dikte."}
                        topMost={true}
                    >
                        {descriptionPCLF}
                    </ConfigBlock>
                    <ConfigBlock
                        title="Dikte"
                        description={"Geef de numerieke dikte in mm in. (Voor regelbepaling)"}
                        topMost={false}
                    >
                        {thicknessMI}
                    </ConfigBlock>

                </div>
            </div>
        </div>;
    }

}
