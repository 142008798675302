exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1KuJbR-5ctipOzqhHuQDzB{\n    border-color: #FFCB04;\n    border-radius: 8px;\n    float: none;\n}\n._1KuJbR-5ctipOzqhHuQDzB p{\n    font-size: 14px;\n}\n._1KuJbR-5ctipOzqhHuQDzB:hover, .FpHIDUT4nJNfWc6tV2ZI{\n    background-color: #fff9e0;\n    color: black;\n}\n._1KuJbR-5ctipOzqhHuQDzB:hover p, .FpHIDUT4nJNfWc6tV2ZI p{\n    color: black;\n}", ""]);

// exports
exports.locals = {
	"item": "_1KuJbR-5ctipOzqhHuQDzB",
	"active": "FpHIDUT4nJNfWc6tV2ZI"
};