import * as React from "react";
import { connect } from "react-redux";
import { loadThicknessRules } from "../../loader";
import { dt } from "webc-reactcore/src/js/stores/GlobalStore";
import { toSelectItems, createTitlePortalCollapsibleLanguageField, createDescriptionPortalCollapsibleLanguageField, createApiTitlePortalCollapsibleLanguageField, createApiDescriptionPortalCollapsibleLanguageField, toObjectSelectItems } from "./helper";
import StaircaseType from "../../../../../shared/datastructures/staircasedata/StaircaseType";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import SimpleSettingsTemplate from "./SimpleSettingsTemplate";
import ConfigBlock from "../../components/ConfigBlock";
import ThicknessRuleAPI from "../../apicontroller/staircasedata/ThicknessRuleAPI";
import ThicknessRule from "../../../../../shared/datastructures/staircasedata/ThicknessRule";
import InfoScreen from "../../components/InfoScreen";
import { t } from "ttag";
import PortalDropdown from "webc-reactcore/src/js/components/mainlayout/PortalDropdown";
import { inu, inun, toNumber } from "orbiter-core/src/basic";
import StaircaseShapeAPI from "../../apicontroller/staircasedata/StaircaseShapeAPI";
import * as PortalDropdownStyle from "../../../css/PortalDropdown.css";
import PortalCheckbox from "webc-reactcore/src/js/components/mainlayout/PortalCheckbox";
import MappedInput from "webc-reactcore/src/js/components/mainlayout/MappedInput";
import * as styles from '../../../css/thicknessRule.css';
import styleable from "react-styleable";
import ObjectID from "bson-objectid";

@(connect((store: any) => {
    return { settings: { ...store.settings }, globalInformation: { ...store.globalInformation } };
}) as any)
class ThicknessRuleSettings extends React.Component<any, any> {

    private getThicknessRules(): ThicknessRuleAPI[] {
        return this.props.globalInformation.thicknessRules.sort((x, y) => x.getOrder() < y.getOrder() ? -1 : 1).map(x => ThicknessRuleAPI.clone(x));
    }

    private getNewRuleOrder(): number{
        let max = 0;
        if(this.props.globalInformation.thicknessRules?.length > 0){
            max = Math.max(...this.props.globalInformation.thicknessRules.map((x: ThicknessRuleAPI) => x.getOrder())) + 1;
        }
        return Math.max(max, this.props.globalInformation.thicknessRules?.length);
    }

    private getOneAbove(target: ThicknessRuleAPI): ThicknessRuleAPI{
        const rules = this.getThicknessRules();
        let above = null;
        for (let i = 0; i < rules.length; i++) {
            const rule = rules[i];
            if(rule.getOrder() < target.getOrder()){
                if(above === null || rule.getOrder() > above.getOrder()){
                    above = rule;
                }
            }else{
                break;
            }
        }

        return above;
    }

    private getOneBelow(target: ThicknessRuleAPI): ThicknessRuleAPI{
        const rules = this.getThicknessRules().reverse();
        let below = null;
        for (let i = 0; i < rules.length; i++) {
            const rule = rules[i];
            if(rule.getOrder() > target.getOrder()){
                if(below === null || rule.getOrder() < below.getOrder()){
                    below = rule;
                }
            }else{
                break;
            }
        }
        return below;
    }

    private async createEmpty(): Promise<ThicknessRule> {
        return new ThicknessRule(null, false, 0, 0, 0, 0, 0, this.getNewRuleOrder());
    }

    private async create(data: ThicknessRule) {
        await ThicknessRuleAPI.create(data);
    }

    public staircaseShapesToSelectItems() {
        return toObjectSelectItems<StaircaseShapeAPI>(this.props.globalInformation.staircaseShapes.sort((x: StaircaseShapeAPI, y: StaircaseShapeAPI) => dt(x.getTitle()) < dt(y.getTitle()) ? -1 : 1), (x) => StaircaseShapeAPI.clone(x), (x) => dt(x.getTitle()));
    }

    private lookupStaircaseShape(id: ObjectID): StaircaseShapeAPI {
        if(id){
            const ssapi = (this.props.globalInformation.staircaseShapes as StaircaseShapeAPI[]).find((x: StaircaseShapeAPI) => x.getId().toHexString() == id.toHexString());
            return ssapi;
        }
        return null;
    }

    public render() {
        return <SimpleSettingsTemplate
            title="Regels voor minimale dikte"
            customSelect={this.renderCustomSelect.bind(this)}

            reload={loadThicknessRules.bind(this)}
            createEmpty={this.createEmpty.bind(this)}
            create={this.create.bind(this)}
            clone={(i: ThicknessRuleAPI) => ThicknessRuleAPI.clone(i)}

            renderEdit={this.renderEdit.bind(this)}
            renderCreate={this.renderCreate.bind(this)}

            infoScreen={<InfoScreen title={t`Selecteer een regel, of maak een nieuwe aan.`} image={"/cdn/images/stack.svg"} />}
        />;
    }

    private renderCustomSelect(selectedItem: ThicknessRuleAPI, select: (item: ThicknessRuleAPI) => void): React.ReactNode {
        const rules = this.getThicknessRules();
        if(rules.length === 0){
            return "";
        }
        return <div className={"container-fluid"}>
            <div className="row">
                <div className="col-md-8">
                    <table className={this.props.css.table}>
                        <thead>
                            <tr>
                                <th>{t`Trapvorm`}</th>
                                <th>{t`Open trap?`}</th>
                                <th colSpan={2}>{t`Breedte (cm)`}</th>
                                <th colSpan={3} className={this.props.css.minThickness}>{t`Minimale dikte`}</th>
                                <th></th>
                            </tr>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>{t`Min.`}</th>
                                <th>{t`Max.`}</th>
                                <th className={this.props.css.minThickness}>{t`Tredes`}</th>
                                <th>{t`Wangen`}</th>
                                <th>{t`Tegentredes`}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {rules.map((r: ThicknessRuleAPI, i: number) => {
                                const staircaseShape: StaircaseShapeAPI = this.lookupStaircaseShape(r.getStaircaseShape());
                                const title: string = dt(staircaseShape?.getTitle());
                                return <tr key={r.getSid()} className={r.getSid() === selectedItem?.getSid() ? this.props.css.selected : ""} onClick={() => {
                                    select(r);
                                }}>
                                    <td>{staircaseShape ? title : <i>{t`Eender welke trapvorm.`}</i>}</td>
                                    <td>{r.getOpenStaircase() ? t`Ja` : t`Nee`}</td>
                                    <td>{r.getMinWidth()}</td>
                                    <td>{r.getMaxWidth()}</td>
                                    <td className={this.props.css.minThickness}>{r.getMinTreadThickness()}</td>
                                    <td>{r.getMinStringerThickness()}</td>
                                    <td>{r.getMinRiserThickness()}</td>
                                    <td className={this.props.css.lastColumn}>
                                        {i < rules.length-1 ? <button onClick={async (e) => {
                                            e.stopPropagation();
                                            const below = this.getOneBelow(r);
                                            if(below){
                                                select(null);
                                                const belowOrder = below.getOrder();
                                                const currentOrder = r.getOrder();
                                                await r.setOrder(belowOrder);
                                                await below.setOrder(currentOrder);
                                                await loadThicknessRules();
                                            }
                                        }}>↓</button> : ""}
                                        {i > 0 ? <button onClick={async (e) => {
                                            e.stopPropagation();
                                            const above = this.getOneAbove(r);
                                            if(above){
                                                select(null);
                                                const aboveOrder = above.getOrder();
                                                const currentOrder = r.getOrder();
                                                await r.setOrder(aboveOrder);
                                                await above.setOrder(currentOrder);
                                                await loadThicknessRules();
                                            }
                                        }}>↑</button> : ""}

                                        <button onClick={async (e) => {
                                            e.stopPropagation();
                                            select(null);
                                            await r.archive();
                                            await loadThicknessRules();
                                        }}>Verwijder</button>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
                
                <div className="col-md-4">
                    <ConfigBlock
                        title={"Regels voor minimale dikte"}
                        description=""
                        topMost={true}
                    >
                        <p>
                            De diktes die gekozen kunnen worden in een geldig artikel worden bepaald door de trapvorm, of het om een open/gesloten trap gaat en de breedte van de trap. 
                            <b> Elk geldig artikel moet aan alle opgestelde regels voldoen</b>.
                        </p>
                        <p>
                            Wanneer <b>geen van de opgelijste regels van toepassing</b> zijn op de tredes/wangen zijn dus respectievelijk alle diktes toegelaten.
                            Bij tegentredes is dit anders.
                            Voor de <b>tegentredes</b> wordt de <b>minimale dikte bepaald door het gekozen materiaal</b> (materiaaltype tegentredes).
                        </p>
                    </ConfigBlock>
                </div>
            </div>
        </div>
    }


    private renderEdit(a: ThicknessRuleAPI, setSelected: (i: ThicknessRuleAPI) => void) {
        return this.doRender(
            <PortalDropdown placeholder={t`Trapvorm`}
                css={{ compose: PortalDropdownStyle }}
                items={this.staircaseShapesToSelectItems()}
                onSelectionChange={async (data) => {
                    const cl: ThicknessRuleAPI = ThicknessRuleAPI.clone(a);
                    if (data) {
                        cl.getData().setStaircaseShape((data.item as StaircaseShapeAPI).getId());
                    } else {
                        cl.getData().setStaircaseShape(null);
                    }
                    setSelected(cl);
                }}
                selectedId={inun(a, (a) => a.getStaircaseShape() ? a.getStaircaseShape().toHexString() : null, () => null)}
            />,
            <PortalCheckbox placeholder={t`Trapvorm`}
                onChange={(val) => {
                    if (val !== a.getOpenStaircase()) {
                        const cl: ThicknessRuleAPI = ThicknessRuleAPI.clone(a);
                        try {
                            cl.getData().setOpenStaircase(val);
                        } catch (e) {
                            cl.getData().setOpenStaircase(false);
                        }
                        setSelected(cl);
                    }
                }}
                value={inu(a, (a) => a.getOpenStaircase(), () => "")}>Open trap</PortalCheckbox>,
            <MappedInput placeholder={t`Min. breedte (cm)`}
                onBlur={(factor) => {
                    const cl: ThicknessRuleAPI = ThicknessRuleAPI.clone(a);
                    try {
                        cl.getData().setMinWidth(toNumber(factor));
                    } catch (e) {
                        cl.getData().setMinWidth(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getMinWidth(), () => "")} />,
            <MappedInput placeholder={t`Max. breedte (cm)`}
                onBlur={(factor) => {
                    const cl: ThicknessRuleAPI = ThicknessRuleAPI.clone(a);
                    try {
                        cl.getData().setMaxWidth(toNumber(factor));
                    } catch (e) {
                        cl.getData().setMaxWidth(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getMaxWidth(), () => "")} />,
            <MappedInput placeholder={t`Minimale dikte tredes`}
                onBlur={(factor) => {
                    const cl: ThicknessRuleAPI = ThicknessRuleAPI.clone(a);
                    try {
                        cl.getData().setMinTreadThickness(toNumber(factor));
                    } catch (e) {
                        cl.getData().setMinTreadThickness(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getMinTreadThickness(), () => "")} />,
            <MappedInput placeholder={t`Minimale dikte wangen`}
                onBlur={(factor) => {
                    const cl: ThicknessRuleAPI = ThicknessRuleAPI.clone(a);
                    try {
                        cl.getData().setMinStringerThickness(toNumber(factor));
                    } catch (e) {
                        cl.getData().setMinStringerThickness(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getMinStringerThickness(), () => "")} />,
            <MappedInput placeholder={t`Minimale dikte tegentredes`}
                onBlur={(factor) => {
                    const cl: ThicknessRuleAPI = ThicknessRuleAPI.clone(a);
                    try {
                        cl.getData().setMinRiserThickness(toNumber(factor));
                    } catch (e) {
                        cl.getData().setMinRiserThickness(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getMinRiserThickness(), () => "")} />,
        );
    }

    private renderCreate(a: ThicknessRule, setSelected: (i: ThicknessRule) => void) {
        return this.doRender(
            <PortalDropdown placeholder={t`Trapvorm`}
                css={{ compose: PortalDropdownStyle }}
                items={this.staircaseShapesToSelectItems()}
                onSelectionChange={async (data) => {
                    const cl: ThicknessRule = a.clone();
                    if (data) {
                        cl.setStaircaseShape((data.item as StaircaseShapeAPI).getId());
                    } else {
                        cl.setStaircaseShape(null);
                    }
                    setSelected(cl);
                }}
                selectedId={inun(a, (a) => a.getStaircaseShape() ? a.getStaircaseShape().toHexString() : null, () => null)}
            />,
            <PortalCheckbox placeholder={t`Trapvorm`}
                onChange={(val) => {
                    if (val !== a.getOpenStaircase()) {
                        const cl: ThicknessRule = a.clone();
                        try {
                            cl.setOpenStaircase(val);
                        } catch (e) {
                            cl.setOpenStaircase(false);
                        }
                        setSelected(cl);
                    }
                }}
                value={inu(a, (a) => a.getOpenStaircase(), () => "")}>Open trap</PortalCheckbox>,
            <MappedInput placeholder={t`Min. breedte (cm)`}
                onBlur={(factor) => {
                    const cl: ThicknessRule = a.clone();
                    try {
                        cl.setMinWidth(toNumber(factor));
                    } catch (e) {
                        cl.setMinWidth(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getMinWidth(), () => "")} />,
            <MappedInput placeholder={t`Max. breedte (cm)`}
                onBlur={(factor) => {
                    const cl: ThicknessRule = a.clone();
                    try {
                        cl.setMaxWidth(toNumber(factor));
                    } catch (e) {
                        cl.setMaxWidth(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getMaxWidth(), () => "")} />,
            <MappedInput placeholder={t`Minimale dikte tredes`}
                onBlur={(factor) => {
                    const cl: ThicknessRule = a.clone();
                    try {
                        cl.setMinTreadThickness(toNumber(factor));
                    } catch (e) {
                        cl.setMinTreadThickness(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getMinTreadThickness(), () => "")} />,
            <MappedInput placeholder={t`Minimale dikte wangen`}
                onBlur={(factor) => {
                    const cl: ThicknessRule = a.clone();
                    try {
                        cl.setMinStringerThickness(toNumber(factor));
                    } catch (e) {
                        cl.setMinStringerThickness(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getMinStringerThickness(), () => "")} />,
            <MappedInput placeholder={t`Minimale dikte tegentredes`}
                onBlur={(factor) => {
                    const cl: ThicknessRule = a.clone();
                    try {
                        cl.setMinRiserThickness(toNumber(factor));
                    } catch (e) {
                        cl.setMinRiserThickness(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getMinRiserThickness(), () => "")} />,
        );
    }

    private doRender(
        staircaseShapeInput: React.ReactNode,
        openStaircaseInput: React.ReactNode,
        minWidthInput: React.ReactNode,
        maxWidthInput: React.ReactNode,
        minTreadThicknessInput: React.ReactNode,
        minStringerThicknessInput: React.ReactNode,
        minRiserThicknessInput: React.ReactNode,
    ): React.ReactNode {
        return <div className={"container-fluid"}>
            <div className="row">
                <div className="col-md-6">
                    <ConfigBlock
                        title={t`Voorwaarden (Als...)`}
                        description={t`Geef de voorwaarden in.`}
                        topMost={true}
                    >
                        {staircaseShapeInput}
                        {openStaircaseInput}
                        {minWidthInput}
                        {maxWidthInput}
                    </ConfigBlock>
                </div>
                <div className="col-md-6">
                    <ConfigBlock
                        title={t`Gevolgen (Dan...)`}
                        description={t`Wanneer aan de voorwaarden voldaan is, zijn volgende regels van toepassing.`}
                        topMost={true}
                    >
                        {minTreadThicknessInput}
                        {minStringerThicknessInput}
                        {minRiserThicknessInput}
                    </ConfigBlock>
                </div>
            </div>
        </div>;
    }

}
export default styleable(styles)(ThicknessRuleSettings);