import * as React from "react";
import styleable from "react-styleable";

import * as styles from "../../css/components/GeneralTable.css";

/**
 * General table.
 *
 * ## styleable
 * * **container** Container styling.
 *
 * @property {objects} props.children - Quote rows
 *
 */
class GeneralTable extends React.Component<any> {
    public render() {
        return (
            <div className={this.props.css.container}>
                {this.props.children}
            </div>
        );
    }
}
export default styleable(styles)(GeneralTable);
