import * as React from "react";
import {connect, DispatchProp} from "react-redux";
import {withRouter, RouteComponentProps} from "react-router-dom";

import BigIconButton from "webc-reactcore/src/js/components/mainlayout/BigIconButton";
import * as BigIconButtonStyle from "../../../css/BigIconButtonStyle.css";
import StepTemplate from "./StepTemplate";
import * as artworkStyle from "../../../css/artwork.css";
import { IQuoteCreationState } from "../../reducers/quoteCreationReducer";
import ArticleTableRow from "../../components/ArticleTableRow";
import { setArticle } from "../../actions/articleCreationActions";
import { IArticleCreationState } from "../../reducers/articleCreationReducer";
import IArticleInput from "../../../../../shared/datastructures/IArticleInput";
import {removeInformation as removeArticleInformation} from "../../actions/articleCreationActions";
import {t} from "ttag";
import { addArticle, deleteArticle, updateArticle } from "../../actions/quoteCreationActions";
import store from "../../store";
import ArticleInput from "../../../../../shared/datastructures/articleinput/ArticleInput";
import VariantGroup from "../../../../../shared/datastructures/articleinput/VariantGroup";
import LoadingQuote from "./LoadingQuote";
import { startLoading, stopLoading } from "./article/loaders/loaderHelper";
import { Popup, PopupManager } from "../../components/Popup";

/**
 * New quote step 2 page.
 *
 */
// TODO: fix 'any' references in all tsx files
@(connect((store: any) => {
    return {quoteCreation: {...store.quoteCreation}, articleCreation: {...store.articleCreation}, globalInformation: {...store.globalInformation}};
}) as any)
class Step2 extends React.Component<{quoteCreation: IQuoteCreationState, articlecreation: IArticleCreationState, globalInformation: any} & DispatchProp & RouteComponentProps, any> {

    private renderNewArticles(): JSX.Element{
        return <span>
            <BigIconButton onClick={() => {
                this.props.dispatch(removeArticleInformation());
                this.props.history.push("/quote/article/wood/1")
            }} title="+ Houten trap" iconPath="/cdn/portal/assets/icons/tree-log.svg"  css={{compose: BigIconButtonStyle}}/>
            {/* <BigIconButton onClick={() => {
                this.props.dispatch(removeArticleInformation());
                this.props.history.push("/quote/article/concrete/1");
            }} title="+ Betonnen trap" iconPath="/cdn/portal/assets/icons/construction-mortar-machine.svg"  css={{compose: BigIconButtonStyle}}/> */}
            {/* <BigIconButton onClick={() => this.props.history.push("/quote/article/1")} title="+ Losse tredes" iconPath="/cdn/portal/assets/icons/stairs.svg"  css={{compose: BigIconButtonStyle}}/>
            <BigIconButton onClick={() => this.props.history.push("/quote/article/1")} title="+ Overloop leuning" iconPath="/cdn/portal/assets/icons/hand-rail.svg"  css={{compose: BigIconButtonStyle}}/> */}
        </span>;
    }

    private renderNoArticles(): JSX.Element{
        return (
            <>
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <img className={artworkStyle.img} src={"/cdn/images/no-articles."+t`locale`+".svg"} />
                    </div>
                    <div className="col-md-2"></div>
                </div>
                {this.renderButtons()}
            </>
        );
    }

    private renderAvailableArticles(): JSX.Element{
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        {/* <SubTitle>{t`Artikels`}</SubTitle> */}
                        {(() => {
                            return VariantGroup.fromArticles(this.props.quoteCreation.articles).map((vg: VariantGroup, i) => {
                                // console.log("VARIANT GROUP", vg)
                                return <ArticleTableRow key={"art" + i} title={i+1} 
                                    article={vg.main} 
                                    distance={this.props.quoteCreation.distance}
                                    children={vg.children}
                                    distributorId={this.props.quoteCreation.distributor?.getId()}
                                    quoteCalculationVersion={this.props.quoteCreation.quoteCalculationVersion}

                                    onClick={(article: IArticleInput) => {
                                        // Reload article into article creation memory
                                        this.props.dispatch(setArticle(article));
                                        // Open new article page
                                        const pagePath = "/quote/article/" + (article.isWood ? "wood" : "concrete") + "/1";
                                        this.props.history.push(pagePath);
                                    }}
                                    onVariant={async (article: IArticleInput) => {

                                        let variantGroup = article.variantGroup;
                                        if(article.variantGroup === null){
                                            // Article doesn't belong to a variant group yet, set to one
                                            variantGroup = article.id;
                                            const updatedArticle: ArticleInput = ArticleInput.from(article)
                                                .setVariantGroup(variantGroup)
                                                .setVariantIncluded(true);
                                            
                                            store.dispatch(await updateArticle(this.props.quoteCreation.quoteId, updatedArticle));
                                        }
                                        
                                        // Create variant
                                        const variant: ArticleInput = ArticleInput.from(article)
                                            .setVariantGroup(variantGroup)
                                            .setVariantIncluded(false);
                                        
                                        store.dispatch(await addArticle(this.props.quoteCreation.quoteId, variant));   
                                    }}
                                    onIncludeVariantToggle={async (article: IArticleInput) => {
                                        startLoading();
                                        const updatedArticle: ArticleInput = ArticleInput.from(article)
                                            .setVariantIncluded(article.variantIncluded ? false : true);
                                        store.dispatch(await updateArticle(this.props.quoteCreation.quoteId, updatedArticle));
                                        stopLoading();
                                    }}
                                    onDuplicate={async (article: IArticleInput) => {                                        
                                        // Create duplicate
                                        const duplicate: ArticleInput = ArticleInput.from(article)
                                            .setVariantGroup(null)
                                            .setVariantIncluded(true);
                                        
                                        store.dispatch(await addArticle(this.props.quoteCreation.quoteId, duplicate));   
                                    }}
                                    onDelete={async (article: IArticleInput) => {                                        
                                        // Delete article
                                        PopupManager.addPopup(new Popup(<p>{t`Opgelet, hiermee wordt het geselecteerde artikel, inclusief bijhorende varianten, verwijderd. Wil je zeker verdergaan?`}</p>, [
                                            {
                                                text: t`Verwijder`,
                                                onClick: async ()=>{
                                                    store.dispatch(await deleteArticle(article.id));   
                                                    PopupManager.closePopup();
                                                }
                                            },
                                            {
                                                text: t`Verwijder niet`,
                                                onClick: ()=>{
                                                    PopupManager.closePopup();
                                                },
                                            }
                                        ]))
                                    }}
                                />
                            })
                        })()}
                    </div>
                </div>
                {this.renderButtons()}
            </>
        );
    }

    private renderButtons(): JSX.Element{
        return <div className="row">
                    <div className="col-md-12" style={{textAlign: "center"}}>
                        {this.renderNewArticles()}
                        <br/>
                        {/* <button onClick={() => devCreateArticle(this.props.quoteCreation.quoteId)}>[DEV] Voeg artikel toe</button> */}
                    </div>
                </div>;
    }

    public render() {
        return  <StepTemplate title={t`artikels.`} next="/quote/3">
                    <LoadingQuote>
                        <div className={"container-fluid"}>
                            {this.props.quoteCreation.articles.length == 0 ? this.renderNoArticles() : this.renderAvailableArticles()}
                        </div>
                    </LoadingQuote>
                </StepTemplate>
    }
}
export default withRouter(Step2);
