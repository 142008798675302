import ObjectID from "bson-objectid";
import {isValidId, toNumber} from "orbiter-core/src/basic";
import DataController from "orbiter-core/src/datastructures/DataController";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import { VALUE_EXCEPTION } from "orbiter-core/src/exceptions";
import TypeDependentPrice from "./TypeDependentPrice";

export interface IStaircaseShape extends DataController {
    getPrice(): number;
    getTitle(): MultiLanguageString;
    getDescription(): MultiLanguageString;
    getTypeDependentPrices(): TypeDependentPrice[];
    getProhibitedHandrailTypeIds(): ObjectID[];
    setPrice(newPrice: number): void | Promise<void>;
    setTitle(newTitle: MultiLanguageString): void | Promise<void>;
    setDescription(newDescription: MultiLanguageString): void | Promise<void>;
    setTypeDependentPrices(prices: TypeDependentPrice[]): void | Promise<void>;
    setProhibitedHandrailTypeIds(ids: ObjectID[]): void | Promise<void>;
}

export default class StaircaseShape extends DataController implements IStaircaseShape {

    @DataController.dataProperty()
    private price: number;
    @DataController.dataProperty(async (x) => await x.asList())
    private title: MultiLanguageString;
    @DataController.dataProperty(async (x) => await x.asList())
    private description: MultiLanguageString;
    @DataController.dataProperty()
    private typeDependentPrices: TypeDependentPrice[];
    @DataController.dataProperty()
    private prohibitedHandrailTypeIds: ObjectID[];

    public constructor(
        price: number,
        title: MultiLanguageString,
        description: MultiLanguageString,
        typeDependentPrices: TypeDependentPrice[],
        prohibitedHandrailTypeIds: ObjectID[],
    ){
        super();
        this.setPrice(price);
        this.setTitle(title);
        this.setDescription(description);
        this.setTypeDependentPrices(typeDependentPrices);
        this.setProhibitedHandrailTypeIds(prohibitedHandrailTypeIds);
    }

    public getTitle(): MultiLanguageString {
        return this.title;
    }

    public getDescription(): MultiLanguageString {
        return this.description;
    }

    public getPrice(): number {
        return this.price;
    }

    public getTypeDependentPrices(): TypeDependentPrice[] {
        return this.typeDependentPrices;
    }

    public getProhibitedHandrailTypeIds(): ObjectID[] {
        return this.prohibitedHandrailTypeIds;
    }

    public setTitle(newTitle: MultiLanguageString): void {
        this.title = newTitle;
    }

    public setDescription(newDescription: MultiLanguageString): void {
        this.description = newDescription;
    }

    public setPrice(newPrice: number): void {
        this.price = toNumber(newPrice);
    }

    public setTypeDependentPrices(prices: TypeDependentPrice[]): void {
        this.typeDependentPrices = prices;
    }

    public setProhibitedHandrailTypeIds(ids: ObjectID[]): void {
        for (const id of ids) {
            if(!isValidId(id) && id != null) {
                throw VALUE_EXCEPTION;
            }
        }
        this.prohibitedHandrailTypeIds = ids;
    }

    public clone(): StaircaseShape {
        return new StaircaseShape(
            this.price,
            this.title.clone(),
            this.description.clone(),
            [...this.typeDependentPrices.map(x => x.clone())],
            [...this.prohibitedHandrailTypeIds],
        )
    }

}
