import * as React from "react";
import { connect } from "react-redux";
import styleable from "react-styleable";
import store from '../store';
import * as styles from "../../css/components/Popup.css";
import { addPopup, closePopup } from "../actions/globalInformationActions";
import RightFloatingMenuItem from "webc-reactcore/src/js/components/mainlayout/RightFloatingMenuItem";
import { t } from "ttag/types";
import * as rightFloatingMenuItemStyle from "../../css/rightFloatingMenuItemStyle.css";

export class PopupManager {
    private popups: Popup[] = [];

    constructor(popups: Popup[]){
        this.popups = popups;
    }

    public static addPopup(popupContent: Popup){
        store.dispatch(addPopup(popupContent));
    }

    public static closePopup(){
        store.dispatch(closePopup());
    }

    public _addPopup(popupContent: Popup): PopupManager{
        return new PopupManager([...this.popups, popupContent]);
    }

    public getPopup(): Popup | null{
        if(this.popups.length > 0){
            return this.popups[0];
        }
        return null;
    }

    public _closePopup(): PopupManager{
        if(this.popups.length > 0){
            return new PopupManager(this.popups.slice(1));
        }
    }
}

export class Popup {
    constructor(
        public readonly content: React.ReactNode,
        public readonly buttons: {text: string, onClick: ()=>void}[] = [{text: "Sluiten", onClick: ()=>PopupManager.closePopup()}]
    ){}

    public getContent(): React.ReactNode{
        return this.content;
    }

    public getButtons(): React.ReactNode{
        return this.buttons.reverse().map((x, i) => {
            return <RightFloatingMenuItem key={"btnpopup-" + i} onClick={x.onClick} title={x.text} css={{compose: rightFloatingMenuItemStyle}}/>
        });
    }
}

@(connect((store: any) => {
    return {
        popups : store.globalInformation.popups,
    };
}) as any)
class PopupRenderer extends React.Component<any> {
    public render() {
        const popupContent: Popup | null = this.props.popups.getPopup();
        if(popupContent === null){
            return "";
        }else{
            return (
                <div className={this.props.css.container}>
                    <div className={this.props.css.box}>
                        {popupContent.getContent()}
                        <div className={this.props.css.separator}></div>
                        <div className={this.props.css.buttons}>
                            {popupContent.getButtons()}
                        </div>
                    </div>
                </div>
            );
        }
    }
}
export default styleable(styles)(PopupRenderer);