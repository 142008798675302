import * as React from "react";
import styleable from "react-styleable";

import * as styles from "../../../css/components/Overview/ValidityIndicator.css";

/**
 * # Styleable
 * * **validityIndicatorContainer**
 * * **validityIndicatorInnerContainer**
 * * **validityIndicatorMessage**
 * * **validityIndicatorLabel**
 * 
 * # Props
 * * **valid: boolean**
 * * **invalidMessage: string**
 * * **children**
 */
class ValidityIndicator extends React.Component<any> {
    public render() {
        if(!this.props.valid)
            return (
                <div className={this.props.css.validityIndicatorContainer}>
                    <div className={this.props.css.validityIndicatorInnerContainer}>
                        <p className={this.props.css.validityIndicatorMessage}>{this.props.invalidMessage}</p>
                        <p className={this.props.css.validityIndicatorLabel}>!</p>
                    </div>
                    {this.props.children}
                </div>
            );
        else return this.props.children === undefined ? null : this.props.children;
    }
}
export default styleable(styles)(ValidityIndicator);
