exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3CQ2tZuZzmAazB5HWvXVc3{\n    min-height: 500px !important;\n}", ""]);

// exports
exports.locals = {
	"styledBoxContainer": "_3CQ2tZuZzmAazB5HWvXVc3"
};