exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3YIMXoACQWtzq7-RQMXdf8{\n    width: 100%;\n}\n._3YIMXoACQWtzq7-RQMXdf8 td{\n    padding-top: 5px;\n    padding-bottom: 5px;\n}\n._3YIMXoACQWtzq7-RQMXdf8 tr td:first-of-type{\n    width: 200px;\n}\n._3YIMXoACQWtzq7-RQMXdf8 tbody tr:nth-child(odd){\n    background-color: #F5F5F5;\n}\n._36wZbQAwXpeSmhQ4MjOyj9{\n    width: 100px;\n}\n._1FS1E3_BZWzssYX_pMekmr{\n    border-style: solid;\n    border-width: 1px;\n    border-color: #FFCB04;\n    background-color: rgba(255, 205, 4, 0.2);\n    width: 100px;\n    border-radius: 3px;\n}", ""]);

// exports
exports.locals = {
	"fullWidthTable": "_3YIMXoACQWtzq7-RQMXdf8",
	"progressBarCell": "_36wZbQAwXpeSmhQ4MjOyj9",
	"progressBorder": "_1FS1E3_BZWzssYX_pMekmr"
};