exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3ZXkJY8pot2_s10o3HpN6B{\n    display: inline;\n    background-color: #FFCB04;\n    color: #3F3F3F;\n    border-radius: 8px;\n    padding-left: 10px;\n    padding-right: 10px;\n    font-weight: bold;\n}\n._3Pf5C3I0TTrCu6aA4t9XPf{\n    border-style: solid;\n    border-color: #FFCB04;\n    border-radius: 8px;\n    border-width: 1px;\n    background-color: #fff9e0;\n    padding: 10px;\n    margin-left: -5px;\n    margin-right: -5px;\n    margin-bottom: 5px;\n    padding-bottom: 40px;\n}\n._2F-6dinlOQD57JEaWbeJgZ{\n    text-align: right;\n    margin-bottom: 10px;\n}\n._10SNuD2qLU1Swv_qSGTw_g{\n    \n}\n._1XMbPIrxw9dP3b-KgfXDOD{\n    display: inline;\n    color: #3F3F3F;\n    margin-right: 5px;\n}", ""]);

// exports
exports.locals = {
	"inputIndicatorLabel": "_3ZXkJY8pot2_s10o3HpN6B",
	"inputIndicatorContainer": "_3Pf5C3I0TTrCu6aA4t9XPf",
	"inputIndicatorInnerContainer": "_2F-6dinlOQD57JEaWbeJgZ",
	"inputIndicatorButtonContainer": "_10SNuD2qLU1Swv_qSGTw_g",
	"inputIndicatorMessage": "_1XMbPIrxw9dP3b-KgfXDOD"
};