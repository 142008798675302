import * as React from "react";
import {connect, DispatchProp} from "react-redux";
import {setArticle, setStaircasePlacementOptionsEqual} from "../../../actions/articleCreationActions";
import SubTitle from "../../../components/SubTitle";
import StepTemplate from "../StepTemplate";
import PortalDropdown from "webc-reactcore/src/js/components/mainlayout/PortalDropdown";
import PortalRadioButton from "webc-reactcore/src/js/components/mainlayout/PortalRadioButton";
import * as PortalDropdownStyle from "../../../../css/PortalDropdown.css";
import * as PortalRadioButtonStyle from "../../../../css/PortalCheckbox.css";
import { IArticleCreationState } from "../../../reducers/articleCreationReducer";
import ArticleInput from "../../../../../../shared/datastructures/articleinput/ArticleInput";
import TransportationInput, { DELIVERY, PICKUP, PLACEMENT } from "../../../../../../shared/datastructures/articleinput/TransportationInput";
import IArticleInput, { ITransportationInput, PageType } from "../../../../../../shared/datastructures/IArticleInput";
import ValidityIndicator from "../../../components/Overview/ValidityIndicator";
import { inun } from "orbiter-core/src/basic";
import ValidityErrorCatcher from "../../../../../../shared/datastructures/articleinput/ValidityErrorCatcher";
import { INPUT_NOT_DEFINED } from "../../../../../../shared/datastructures/articleinput/inputExceptions";
import PortalCheckbox from "webc-reactcore/src/js/components/mainlayout/PortalCheckbox";
import {t} from "ttag";
import { dt } from "webc-reactcore/src/js/stores/GlobalStore";
import { IQuoteCreationState } from "../../../reducers/quoteCreationReducer";
import { performEmptyValidityCheck } from "./helpers/validityCheckHelper";
import { ASSEMBLED_BY_CUSTOMER_FOR_DELIVERY_TEXT, ASSEMBLED_BY_CUSTOMER_FOR_PICKUP_TEXT, ASSEMBLED_BY_HOTEC_FOR_DELIVERY_TEXT, ASSEMBLED_BY_HOTEC_FOR_PICKUP_TEXT, MEASUREMENT_BY_CUSTOMER_TEXT, MEASUREMENT_BY_HOTEC_TEXT } from "../../../helpers";
import SupplementPicker from "../../../components/SupplementPicker";

@(connect((store: any): any => {
    return {article: {...store.articleCreation}, quote: {...store.quoteCreation}};
}) as any)
export default class Transportation extends React.Component<{article: IArticleCreationState, quote: IQuoteCreationState} & DispatchProp, {validity:any}> {

    private updateTransportationMethod(transportationMethod:string){
        if(transportationMethod === this.props.article.article.transportation.transportationMethod)
            return;
        let article = ArticleInput.from(this.props.article.article).setTransportation(TransportationInput.from(this.props.article.article.transportation).setTransportationMethod(transportationMethod));
        article = article.setTransportation(article.transportation.setStaircasePlacementOptions([]));
        (this.props as any).dispatch(setArticle(article));
    }

    private modifyTransportation(f: (transportation: TransportationInput) => TransportationInput){
        let article = ArticleInput.from(this.props.article.article).setTransportation(f(TransportationInput.from(this.props.article.article.transportation)));
        (this.props as any).dispatch(setArticle(article));
    }
    
    
    private async updateArticle(article: ArticleInput) {
        await this.props.dispatch(setArticle(article));
    }
    private async updateStaircasePlacementOptionsEquality(equality: boolean) {
        await this.props.dispatch(setStaircasePlacementOptionsEqual(equality));
    }

    private getArticle(): IArticleInput{
        return this.props.article.article;
    }

    private getArticleInput():ArticleInput{
        return ArticleInput.from(this.props.article.article);
    }

    private updateSupplements(supplements){
        const newTransportation = this.getTransportationInput().setPlacementSupplements(supplements);
        (this.props as any).dispatch(setArticle(ArticleInput.from(this.props.article.article).setTransportation(newTransportation)));
    }

    private getTransportationInput(): TransportationInput{
        return TransportationInput.from(this.props.article.article.transportation);
    }

    private getTransportation(): ITransportationInput{
        return this.props.article.article.transportation;
    }

    private async doValidityChecks(nextProps: IArticleCreationState){
        /**
         * Validity checks
         */
        const enableEmptyCheck: boolean = performEmptyValidityCheck(PageType.TRANSPORTATION); // Validity check empty allowed
        const staircasePlacementOptionsValidity = await new ValidityErrorCatcher(await (async () => TransportationInput.staircasePlacementOptionsValidityCheck(nextProps.article.transportation.staircasePlacementOptions, nextProps.article)))
            .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED, "Kies een geldige plaatsingsoptie voor elke trap.")
            .catchError();

        this.setState({validity:{
            staircasePlacementOptionsValidity,
        }});
    }

    constructor(a){
        super(a);
        this.state = {validity:{
            staircasePlacementOptionsValidity: {},
        }}
    }

    componentDidMount(){
        this.doValidityChecks(this.props.article);
    }

    componentWillReceiveProps(nextProps){
        this.doValidityChecks(nextProps.article);
    }

    private renderPlacementSupplements(){
        return <span>
            {/* TODO: component for supplements */}
            <SubTitle>Supplementen plaatsing</SubTitle>
            <SupplementPicker
                placeholder={t`Supplementen plaatsing`}
                availableSupplements={this.props.quote.availableSupplementsPlacement}
                selectedSupplements={this.getTransportation().placementSupplements}
                onUpdateSupplements={(sup) => {
                    this.updateSupplements(sup);
                }}
            />
        </span>
    }

    public staircasePlacementOptionsToSelectItems() {
        const list = [];
        for (const option of this.props.quote.availableStaircasePlacementOptions) {
            list.push(
                {
                    title: dt(option.getTitle()),
                    id: option.getSid(),
                    staircasePlacementOption: option,
                }
            );
        }
        return list.sort((a, b) => a.title < b.title ? -1 : 1);
    }

    private renderPlacementOptions(){

        const transportationMethod = this.props.article.article.transportation.transportationMethod;
        if(transportationMethod !== PLACEMENT){

            const measurement = this.props.article.article.transportation.measurementByHotec;
            const assembly = this.props.article.article.transportation.assembledByHotec;

            const delivery = transportationMethod === DELIVERY;
            return <>
                <SubTitle>Opmeting</SubTitle>
                <PortalRadioButton css={{compose: PortalRadioButtonStyle}} value={measurement} onChange={(x: boolean) => this.modifyTransportation(t => measurement === false ? t.setMeasurementByHotec(x) : t)}>{MEASUREMENT_BY_HOTEC_TEXT}</PortalRadioButton>
                <PortalRadioButton css={{compose: PortalRadioButtonStyle}} value={!measurement} onChange={(x: boolean) => this.modifyTransportation(t => measurement === true ? t.setMeasurementByHotec(!x) : t)}>{MEASUREMENT_BY_CUSTOMER_TEXT}</PortalRadioButton>
                
                <SubTitle>Montage</SubTitle>
                <PortalRadioButton css={{compose: PortalRadioButtonStyle}} value={assembly} onChange={(x: boolean) => this.modifyTransportation(t => assembly === false ? t.setAssembledByHotec(x) : t)}>{delivery ? ASSEMBLED_BY_HOTEC_FOR_DELIVERY_TEXT : ASSEMBLED_BY_HOTEC_FOR_PICKUP_TEXT}</PortalRadioButton>
                <PortalRadioButton css={{compose: PortalRadioButtonStyle}} value={!assembly} onChange={(x: boolean) => this.modifyTransportation(t => assembly === true ? t.setAssembledByHotec(!x) : t)}>{delivery ? ASSEMBLED_BY_CUSTOMER_FOR_DELIVERY_TEXT : ASSEMBLED_BY_CUSTOMER_FOR_PICKUP_TEXT}</PortalRadioButton>
            </>
        }

        const dropdowns = [];
        const numberOfStaircases = this.props.article.article.unitCount;

        if(this.props.article.temp_show_equal_placement_options || numberOfStaircases === 1){
            dropdowns.push(
                <PortalDropdown key={"placementoption"} placeholder={"Plaatsingsopties trappen"}
                    css={{ compose: PortalDropdownStyle }}
                    items={this.staircasePlacementOptionsToSelectItems()}
                    onSelectionChange={async (data) => {
                        let newTransportation = this.getTransportationInput();
                        const newTransportationStaircasePlacementOptions = [];
                        for(let i = 0; i<numberOfStaircases; i++){
                            newTransportationStaircasePlacementOptions.push(data === null ? undefined : data.staircasePlacementOption);
                        }
                        newTransportation = newTransportation.setStaircasePlacementOptions(newTransportationStaircasePlacementOptions);
                        await this.updateArticle(this.getArticleInput().setTransportation(newTransportation));
                    }}
                    selectedId={inun(this.getArticle().transportation.staircasePlacementOptions[0], (a) => a.getSid(), ()=>null)}
                />
            )
        }else{
            for(let i = 0; i<numberOfStaircases; i++){
                dropdowns.push(
                    <PortalDropdown key={"placementoption" + i} placeholder={"Plaatsingsoptie trap " + (i+1)}
                        css={{ compose: PortalDropdownStyle }}
                        items={this.staircasePlacementOptionsToSelectItems()}
                        onSelectionChange={async (data) => {
                            let newTransportation = this.getTransportationInput();
                            const newTransportationStaircasePlacementOptions = [...newTransportation.staircasePlacementOptions];
                            newTransportationStaircasePlacementOptions[i] = data === null ? undefined : data.staircasePlacementOption;
                            newTransportation = newTransportation.setStaircasePlacementOptions(newTransportationStaircasePlacementOptions);
                            await this.updateArticle(this.getArticleInput().setTransportation(newTransportation));
                        }}
                        selectedId={inun(this.getArticle().transportation.staircasePlacementOptions[i], (a) => a.getSid(), ()=>null)}
                    />
                )
            }        
        }

        return <div>
            <SubTitle>Plaatsingsopties</SubTitle>
            <PortalCheckbox css={{compose: PortalRadioButtonStyle}} value={this.props.article.temp_show_equal_placement_options} onChange={(checked) => this.updateStaircasePlacementOptionsEquality(checked)}>Dezelfde opties voor alle trappen</PortalCheckbox>                         
            <ValidityIndicator
                {...this.state.validity.staircasePlacementOptionsValidity}
            >
                {dropdowns}
            </ValidityIndicator>
        </div>;
    }

    public render() {
        return (
            <StepTemplate pageType={PageType.TRANSPORTATION} title={t`transport & plaatsing.`} next={"/quote/article/" + (this.props.article.article.isWood ? "wood" : "concrete") + "/7"}>
                <div className={"container-fluid"}>
                    <div className="row">
                        <div className="col-md-4">
                            <SubTitle>{t`Transport`}</SubTitle>
                            {/*TODO: transportation/distribution types (and extra information?)*/}
                            <PortalRadioButton css={{compose: PortalRadioButtonStyle}} value={this.props.article.article.transportation.transportationMethod === PLACEMENT} onChange={(x) => this.updateTransportationMethod(PLACEMENT)}>{t`Plaatsing`}</PortalRadioButton>
                            <PortalRadioButton css={{compose: PortalRadioButtonStyle}} value={this.props.article.article.transportation.transportationMethod === DELIVERY} onChange={(x) => this.updateTransportationMethod(DELIVERY)}>{t`Levering`}</PortalRadioButton>
                            <PortalRadioButton css={{compose: PortalRadioButtonStyle}} value={this.props.article.article.transportation.transportationMethod === PICKUP} onChange={(x) => this.updateTransportationMethod(PICKUP)}>{t`Afhaling`}</PortalRadioButton>
                        </div>
                        <div className="col-md-4">
                            {this.renderPlacementOptions()}
                        </div>
                        <div className="col-md-4">
                            {this.renderPlacementSupplements()}
                        </div>
                    </div>
                </div>
            </StepTemplate>
        );
    }
}
