import React, { useState } from 'react';
import IUniformNode from '../../../../../shared/quote-rendering/IUniformNode';
import { IImageNodeProps } from '../../../../../shared/quote-rendering/UniformNodeFactory';

export default class ImageNode implements IUniformNode<React.ReactNode>{
    
    constructor(
        private readonly props: IImageNodeProps
    ) {}

    renderWithManualKey() {
        return <Image key={this.props.key} src={this.props.src} style={{width: this.props.width}}/>;
    }

    render(seedKey:string) {
        return <Image key={seedKey} src={this.props.src} style={{width: this.props.width}}/>;
    }

}

const Image = (props: {src: string | (() => Promise<string>), style: any}) => {

    const [src, setSrc] = useState(typeof props.src === 'string' ? props.src : undefined);
    if(src === undefined){
        const srcPromise: (() => Promise<string>) = props.src as any;
        (async () => {
            setSrc(await srcPromise());
        })()
    }

    return <img src={src} style={props.style}/>;
}