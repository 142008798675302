import Language from "orbiter-core/src/datastructures/languages/Language";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import SingleLanguageString from "orbiter-core/src/datastructures/languages/SingleLanguageString";
import ApiController from "../ApiController";
import {
    IHandrailPoleType,
    default as HandrailPoleType
} from "../../../../../shared/datastructures/staircasedata/HandrailPoleType";
import ObjectID from "bson-objectid";

// TODO: too much code duplication
export default class HandrailPoleTypeAPI extends ApiController<HandrailPoleType> implements IHandrailPoleType {

    public static getBaseEndpoint(): string{
        return "hotec/handrailpoletype";
    }

    public static getApiKey(): string{
        return "handrailPoleType";
    }

    public async parseFromDictionary(dictionary): Promise<void> {

        const title = new MultiLanguageString(
            dictionary.title.map((x) => {
                // TODO: LanguageAPI?
                const l = new Language(
                    x.languageId,
                    null,
                    null,
                );
                return new SingleLanguageString(
                    l,
                    x.value,
                );
            }),
        );

        const desc = new MultiLanguageString(
            dictionary.description.map((x) => {
                // TODO: LanguageAPI?
                const l = new Language(
                    x.languageId,
                    null,
                    null,
                );
                return new SingleLanguageString(
                    l,
                    x.value,
                );
            }),
        );

        const handrailPoleType = new HandrailPoleType(
            title,
            desc,
            dictionary.price,
            dictionary.defaultHandrailPoleFinishId ? new ObjectID(dictionary.defaultHandrailPoleFinishId) : null,
        );
        this.setData(handrailPoleType);
    }

    public getDescription(): MultiLanguageString {
        return this.getData().getDescription();
    }

    public getPrice(): number {
        return this.getData().getPrice();
    }

    public getDefaultHandrailPoleFinishId(): ObjectID | null {
        return this.getData().getDefaultHandrailPoleFinishId();
    }

    public async setPrice(price: number): Promise<void> {
        this.getData().setPrice(price);
        await this.updateProperty("price", price);
    }

    public async setDescription(newDescription: MultiLanguageString): Promise<void> {
        this.getData().setDescription(newDescription);
        await this.updateProperty("description", newDescription);
    }

    public async setTitle(newTitle: MultiLanguageString): Promise<void> {
        this.getData().setTitle(newTitle);
        await this.updateProperty("title", newTitle);
    }

    public async setDefaultHandrailPoleFinishId(handrailPoleFinishId: ObjectID): Promise<void> {
        this.getData().setDefaultHandrailPoleFinishId(handrailPoleFinishId);
        await this.updateProperty("defaultHandrailPoleFinishId", handrailPoleFinishId);
    }

    public getTitle(): MultiLanguageString {
        return this.getData().getTitle();
    }

}
