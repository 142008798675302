import ApiController from "./ApiController";
import Permission from 'orbiter-core/src/datastructures/permissions/Permission';
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import Language from "orbiter-core/src/datastructures/languages/Language";
import SingleLanguageString from "orbiter-core/src/datastructures/languages/SingleLanguageString";

export default class PermissionAPI extends ApiController<Permission>{
    public static getBaseEndpoint(): string{
        return "hotec/permission";
    }

    public static getApiKey(): string{
        return "permission";
    }
    
    public async parseFromDictionary(dictionary): Promise<void> {

        const desc = new MultiLanguageString(
            dictionary.description.map((x) => {
                // TODO: LanguageAPI?
                const l = new Language(
                    x.languageId,
                    null,
                    null,
                );
                return new SingleLanguageString(
                    l,
                    x.value,
                );
            }),
        );
        
        const p = new Permission(dictionary.name, desc);
        this.setData(p);
    }
    
    public getDescription(): MultiLanguageString {
        return this.getData().getDescription();
    }

    public static getArchiveEndpoint(): string {
        return this.getDeleteEndpoint();
    }

    public getName(): string {
        return this.getData().getName();
    }

    public setName(name: string): void {
        this.getData().setName(name);
    }

    public setDescription(description: MultiLanguageString): void {
        this.getData().setDescription(description);
    }
}