import * as React from "react";
import StepTemplate from "./StepTemplate";
import { t } from "ttag";
import { connect, DispatchProp } from "react-redux";
import { IQuoteCreationStateDispatch, quoteCreateStateToQuote } from "../../reducers/quoteCreationReducer";
import styleable from "react-styleable";
import * as styles from "../../../css/pages/newquote/Step3.css";
import { IArticleCreationStateDispatch } from "../../reducers/articleCreationReducer";
import ToggleButton from "../../components/ToggleButton";
import { setBasicInformation } from "../../actions/quoteCreationActions";
import { AnyAction } from "redux";
import { EyeIcon, NoEyeIcon } from "../../components/Icon";
import LoadingQuote from "./LoadingQuote";
import { sendEmail } from "../../apicontroller/quote";
import { PopupManager } from "../../components/Popup";
import { emailInputPopupFactory } from "./article/EmailInputComponent";
import QuoteRenderer from '../../../../../shared/quote-rendering/QuoteRenderer';
import VisibleArticleTree from "../../../../../shared/datastructures/VisibleArticleTree";
import Quote from "../../../../../shared/datastructures/Quote";
import PortalNodeFactory from "../../quote-rendering/PortalNodeFactory";
import { dt } from "webc-reactcore/src/js/stores/GlobalStore";

type props = { quoteCreation: IQuoteCreationStateDispatch, articleCreation: IArticleCreationStateDispatch, css: any } & DispatchProp<AnyAction>;

/**
 * New quote step 3 page.
 *
 */
@(connect((store: any) => {
    return { quoteCreation: { ...store.quoteCreation }, articleCreation: { ...store.articleCreation } };
}) as any)
class Step3 extends React.Component<props, { visibleArticleTree: VisibleArticleTree }> {

    constructor(_) {
        super(_);
        this.state = {
            visibleArticleTree: null,
        };
    }

    private async loadVisibleArticleTree() {
        const qc: IQuoteCreationStateDispatch = this.props.quoteCreation;
        const quote: Quote = await quoteCreateStateToQuote(qc);
        const visibleArticleTree: VisibleArticleTree = new VisibleArticleTree(quote);
        await visibleArticleTree.init();

        this.setState({
            visibleArticleTree,
        })
    }

    componentDidMount() {
        this.loadVisibleArticleTree();
    }

    public render() {
        const qc: IQuoteCreationStateDispatch = this.props.quoteCreation;
        const pdfOptions: string = this.props.quoteCreation._localShowPriceDetails ? "pd" : "";

        return (
            <StepTemplate title={t`finaliseren.`}>
                <LoadingQuote onLoaded={() => this.loadVisibleArticleTree()}>
                    <div className={"container-fluid"}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className={this.props.css.buttons}>
                                    <button onClick={async (e) => {
                                        window.open("/api/hotec/quote/pdf/" + qc.quoteId.toHexString() + "/view/" + pdfOptions, '_blank');
                                    }}>Bekijk pdf</button>
                                    <button onClick={async (e) => {
                                        window.open("/api/hotec/quote/pdf/" + qc.quoteId.toHexString() + "/download/" + pdfOptions);
                                    }}>Download pdf</button>
                                    <button onClick={async (e) => {
                                        if (this.props.quoteCreation.customerEmail.trim() === "") {
                                            alert(t`Vul eerst het e-mailadres van de klant in.`);
                                        } else {
                                            PopupManager.addPopup(emailInputPopupFactory((body: string) => {
                                                sendEmail(qc.quoteId, body, this.props.quoteCreation._localShowPriceDetails);
                                            }, this.props.quoteCreation.quoteState.startsWith("ORDER") ? this.props.quoteCreation.distributor.getOrderEmailTemplate() : this.props.quoteCreation.distributor.getQuoteEmailTemplate(),
                                            "/api/hotec/quote/pdf/" + qc.quoteId.toHexString() + "/view/" + pdfOptions));
                                        }
                                    }}>Verstuur e-mail</button>

                                    <ToggleButton
                                        onClick={() => {
                                            this.props.dispatch(setBasicInformation({ _localShowPriceDetails: !this.props.quoteCreation._localShowPriceDetails }));
                                        }}
                                        selected={this.props.quoteCreation._localShowPriceDetails}
                                        selectedIcon={<EyeIcon />}
                                        unselectedIcon={<NoEyeIcon />}
                                    />
                                </div>
                                {this.renderOverview()}
                            </div>
                        </div>
                    </div>
                </LoadingQuote>
            </StepTemplate>
        );
    }

    private renderOverview() {
        if (!this.state.visibleArticleTree)
            return "";

        const showPriceDetails = this.props.quoteCreation._localShowPriceDetails;
        const distributor = this.props.quoteCreation.distributor;

        const distributorLogo = async () => {
            if (distributor?.getLogoId()) {
                return "/api/hotec/distributor/logo-by-id/" + distributor?.getLogoId();
            } else {
                return "/portal/assets/logohotec.png";
            }
        };

        const qr = new QuoteRenderer(this.state.visibleArticleTree, new PortalNodeFactory(), showPriceDetails, distributor, distributorLogo, dt);
        return <div className={this.props.css.outer}>
            {qr.render()}
        </div>
    }

}
export default styleable(styles)(Step3);
