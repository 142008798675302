import * as React from "react";
import { inu } from "orbiter-core/src/basic";
import { connect } from "react-redux";
import { loadWallHooks } from "../../loader";
import WallHookAPI from "../../apicontroller/staircasedata/WallHookAPI";
import { t } from 'ttag';
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import { dt } from "webc-reactcore/src/js/stores/GlobalStore";
import WallHook from "../../../../../shared/datastructures/staircasedata/WallHook";
import SimpleSettingsTemplate from "./SimpleSettingsTemplate";
import { toSelectItems, createApiTitlePortalCollapsibleLanguageField, createApiDescriptionPortalCollapsibleLanguageField, createTitlePortalCollapsibleLanguageField, createDescriptionPortalCollapsibleLanguageField } from "./helper";
import ConfigBlock from "../../components/ConfigBlock";
import InfoScreen from "../../components/InfoScreen";
import MappedInput from "webc-reactcore/src/js/components/mainlayout/MappedInput";

@(connect((store: any) => {
    return { settings: { ...store.settings }, globalInformation: { ...store.globalInformation } };
}) as any)
export default class WallHookSettings extends React.Component<any, any> {

    public wallHooksToSelectItems() {
        return toSelectItems<WallHookAPI>("wallhooks", this.props.globalInformation.wallHooks.sort((x, y) => dt(x.getTitle()) < dt(y.getTitle()) ? -1 : 1), (x) => WallHookAPI.clone(x), (x) => dt(x.getTitle()));
    }

    private async createEmpty(): Promise<WallHook> {
        return new WallHook(new MultiLanguageString(), new MultiLanguageString(), 0);
    }

    private async create(data: WallHook) {
        await WallHookAPI.create(data);
    }

    public render() {
        return <SimpleSettingsTemplate
            title="Muurhaak types"
            selectItems={this.wallHooksToSelectItems()}

            reload={loadWallHooks.bind(this)}
            createEmpty={this.createEmpty.bind(this)}
            create={this.create.bind(this)}
            clone={(i: WallHookAPI) => WallHookAPI.clone(i)}

            renderEdit={this.renderEdit.bind(this)}
            renderCreate={this.renderCreate.bind(this)}

            infoScreen={<InfoScreen title={t`Selecteer een type muurhaak.`} image={"/cdn/images/staircase.svg"}/>}
        />;
    }

    private renderEdit(a: WallHookAPI, setSelected: (i: WallHookAPI) => void) {
        return this.doRender(
            createApiTitlePortalCollapsibleLanguageField(a, (x) => WallHookAPI.clone(x), setSelected.bind(this)),
            createApiDescriptionPortalCollapsibleLanguageField(a, (x) => WallHookAPI.clone(x), setSelected.bind(this)),
            <MappedInput placeholder={t`Prijs`}
                onBlur={(price) => {
                    const cl: WallHookAPI = WallHookAPI.clone(a);
                    try{
                        cl.getData().setPrice(price);
                    }catch(e){
                        cl.getData().setPrice(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getPrice(), () => "")} />,
        );
    }

    private renderCreate(a: WallHook, setSelected: (i: WallHook) => void) {
        return this.doRender(
            createTitlePortalCollapsibleLanguageField(a, setSelected.bind(this)),
            createDescriptionPortalCollapsibleLanguageField(a, setSelected.bind(this)),
            <MappedInput placeholder={t`Prijs`}
                onBlur={(price) => {
                    const cl: WallHook = a.clone();
                    try{
                        cl.setPrice(price);
                    }catch(e){
                        cl.setPrice(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getPrice(), () => "")} />,
        );
    }

    private doRender(
        titlePCLF: React.ReactNode,
        descriptionPCLF: React.ReactNode,
        priceMI: React.ReactNode,
    ): React.ReactNode {
        return <div className={"container-fluid"}>
            <div className="row">
                <div className="col-md-6">
                    <ConfigBlock
                        title="Titel"
                        description="Geef een titel in voor deze muurhaak."
                        topMost={true}
                    >
                        {titlePCLF}
                    </ConfigBlock>

                    <ConfigBlock
                        title="Prijs"
                        description="Geef een prijs per stuk in voor deze muurhaak."
                        topMost={false}
                    >
                        {priceMI}
                    </ConfigBlock>
                </div>
                <div className="col-md-6">
                    <ConfigBlock
                        title="Omschrijving"
                        description="Geef een omschrijving in voor deze muurhaak."
                        topMost={true}
                    >
                        {descriptionPCLF}
                    </ConfigBlock>
                </div>
            </div>
        </div>;
    }
}
