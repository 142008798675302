exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3s0kAKCa__3f5vZt-Wb4ck{\n    padding: 15px;\n}\n._27IxBfcJlqfrFNpOJqx9we{\n    height: calc(100vh - 150px) ;\n    overflow-y: auto;\n}\n.OKW_m8bNFZ6KVa280pJOS{\n    float: right;\n}", ""]);

// exports
exports.locals = {
	"container": "_3s0kAKCa__3f5vZt-Wb4ck",
	"box": "_27IxBfcJlqfrFNpOJqx9we",
	"toolbarContainer": "OKW_m8bNFZ6KVa280pJOS"
};