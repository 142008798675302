import ObjectID from "bson-objectid";
import * as t from 'io-ts';
import * as tPromise from 'io-ts-promise';
import { either } from 'fp-ts/lib/Either'
import Quote from "./Quote";
import IArticleInput, { IExtraHandrailInput, IHandrailInput, IQuantifiedSupplement } from "./IArticleInput";

/**
 * Simplified network communication types use id's where possible instead of full objects.
 * Simplified network communication types are meant to store data in the database (i/o to and from database) 
 * as well as for client to server traffic in order to minimize transmitted data.
 */

export type tDateFromString = t.TypeOf<typeof tDateFromString>;
export const tDateFromString = new t.Type<Date | null, string | null>(
    'DateFromString',
    (m): m is Date => (m instanceof Date) || m === null,
    (m, c) => {
        if(m === null){
            return t.success(null);
        }
        if(m && Object.prototype.toString.call(m) === "[object Date]" && !isNaN(m as any)){
            return t.success(m as Date);
        }
        return either.chain(t.string.validate(m, c), (s: string) => {
            const d = new Date(s)
            return isNaN(d.getTime()) ? t.failure(s, c) : t.success(d)
        })
    },
    a => a === null ? null : a.toISOString()
)

export type tObjectId = t.TypeOf<typeof tObjectId>;
export const tObjectId = new t.Type<
    ObjectID, // Deserialized type
    string, // Serialized output type
    unknown // Serialized input type
>(
    'ObjectId', // Name of the type, for debugging
    // Type-guard
    (u: unknown): u is ObjectID => u instanceof ObjectID,
    // Decoding function
    (input: any, context: t.Context) => {
        if(input.toHexString !== undefined && ObjectID.isValid(input as ObjectID)){
            return t.success(new ObjectID((input as ObjectID).toHexString()));
        }
        return either.chain(t.string.validate(input, context), (stringInput: string) => {
            try {
                const objectId = ObjectID.createFromHexString(stringInput);
                return t.success(objectId);
            } catch (e) {
                return t.failure(input, context);
            }
        })
    },
    // Encoding function
    (o: ObjectID): string => o.toHexString()
);

export type tVersionedObjectId = t.TypeOf<typeof tVersionedObjectId>;
export const tVersionedObjectId = t.type({
    _id: tObjectId,
    _version: t.number,
})

export type tAddress = t.TypeOf<typeof tAddress>;
export const tAddress = t.type({
    streetAndNumber: t.string,
    postalCode: t.string,
    city: t.string,
    country: t.string,
})

export type tLanguage = t.TypeOf<typeof tLanguage>;
export const tLanguage = t.type({
    id: tObjectId,
    descriptor: t.string,
    description: t.string,
})

export type tSingleLanguageString = t.TypeOf<typeof tSingleLanguageString>;
export const tSingleLanguageString = t.type({
    language: tLanguage,
    value: t.string,
})

export type tMultiLanguageString = t.TypeOf<typeof tMultiLanguageString>;
export const tMultiLanguageString = t.type({
    languages: t.array(tSingleLanguageString),
})

export type tTreatment = t.TypeOf<typeof tTreatment>;
export const tTreatment = t.union([t.null, t.type({
    type: tObjectId,
    colors: t.array(t.string),
})]);

export type tWallHandrail = t.TypeOf<typeof tWallHandrail>;
export const tWallHandrail = t.union([t.null, t.type({
    type: tObjectId,
    hook: t.union([t.null, tObjectId]),
    length: t.number,
    hookCount: t.number,
    bendCount: t.number,
    ignoreWoodType: t.boolean,
})]);

export type tQuantifiedSupplement = t.TypeOf<typeof tQuantifiedSupplement>;
export const tQuantifiedSupplement = t.type({
    amount: t.number,
    supplement: tObjectId,
})

const tShapeRequired = t.type({
    riserCount: t.union([t.null, t.number]),
    isRiserThicknessEnabled: t.boolean,
    staircaseShape: tObjectId,
    stringerThickness: tObjectId,
    treadSupplements: t.array(tQuantifiedSupplement),
    treadThickness: tObjectId,
})
const tShapeOptional = t.partial({
    rawRiserThickness: t.union([t.undefined, t.null, tObjectId]),
    closedStaircase: t.boolean,
})
export type tShape = t.TypeOf<typeof tShape>;
export const tShape = t.intersection([tShapeRequired, tShapeOptional])

export type tHandrail = t.TypeOf<typeof tHandrail>;
export const tHandrailRequired = t.type({
    type: tObjectId,
    supplements: t.array(tQuantifiedSupplement),
    handrailPoleTypes: t.array(tObjectId),
    handrailPoleFinishes: t.array(tObjectId),
})
export const tHandrailOptional = t.partial({
    handle: t.union([t.undefined, t.null, tObjectId]),
    spindleThreadType: t.union([t.undefined, t.null, tObjectId]),
    spindleThreadDescription: t.union([t.undefined, t.null, t.string]),
})
export const tHandrail = t.union([t.null, t.intersection([tHandrailRequired, tHandrailOptional])]);

export type tExtraHandrail = t.TypeOf<typeof tExtraHandrail>;
export const tExtraHandrailRequired = t.type({
    type: tObjectId,
    handrailPoleTypes: t.array(tObjectId),
    handrailPoleFinishes: t.array(tObjectId),
    treadCount: t.number,
    poleCount: t.number,
})
export const tExtraHandrailOptional = t.partial({
    handle: t.union([t.undefined, t.null, tObjectId]),
    spindleThreadType: t.union([t.undefined, t.null, tObjectId]),
    spindleThreadDescription: t.union([t.undefined, t.null, t.string]),
})
export const tExtraHandrail = t.union([t.null, t.intersection([tExtraHandrailRequired, tExtraHandrailOptional])]);

export type tLandingStage = t.TypeOf<typeof tLandingStage>;
export const tLandingStageRequired = t.type({
    concreteEnclosureLength: t.number, // TODO: is this part of landing stage handrail?
    concreteEnclosureWidth: t.number,
    concreteEnclosureWoodType: t.union([t.null, tObjectId]),
    handrailLength: t.number,
    handrailPoleCount: t.number,
    handrailPoleTypes: t.array(tObjectId),
    handrailPoleFinishes: t.array(tObjectId),
})
export const tLandingStageOptional = t.partial({
    handrail: t.union([t.null, tHandrail]),
})
export const tLandingStage = t.intersection([tLandingStageRequired, tLandingStageOptional]);

export type tTransportation = t.TypeOf<typeof tTransportation>;
export const tTransportation = t.type({
    transportationMethod: t.string,
    placementSupplements: t.array(tQuantifiedSupplement),
    staircasePlacementOptions: t.array(tObjectId),
    measurementByHotec: t.boolean,
    assembledByHotec: t.boolean,
})

export type tComments = t.TypeOf<typeof tComments>;
export const tComments = t.array(t.type({
    page: t.string,
    content: t.string,
}))

export type tArticle = t.TypeOf<typeof tArticle>;
export const tArticle = t.type({
    reference: t.string,
    unitCount: t.number,
    width: t.number,
    floorHeight: t.number,
    treadCount: t.number,
    type: tObjectId,
    woodType: tObjectId,
    riserWoodType: tObjectId,
    isWood: t.boolean,
    shape: tShape,
    handrail: tHandrail,
    extraHandrail: tExtraHandrail,
    wallHandrail: tWallHandrail,
    landingStage: tLandingStage,
    treatment: tTreatment,
    transportation: tTransportation,
    comments: tComments,
    variantGroup: t.union([t.null, tObjectId]),
    variantIncluded: t.union([t.null, t.boolean]),
    discountPercentageStaircase: t.number,
    discountPercentageTreatment: t.number,
    discountPercentageDistribution: t.number,
    quoteId: t.union([t.null, t.undefined, tObjectId]),
    id: t.union([t.undefined, tObjectId]),
})

export type tQuote = t.TypeOf<typeof tQuote>;
export const tQuote = t.type({ // TODO: add status
    expirationDate: t.union([t.undefined, tDateFromString]),
    expired: t.union([t.null, t.undefined, t.boolean]),
    creationDate: tDateFromString,
    lastUpdateDate: tDateFromString,
    dataDate: tDateFromString,
    distributorId: tObjectId,
    ownerId: tObjectId,
    ownerName: t.string,
    quoteState: t.string,
    quoteNumber: t.string,
    customerName: t.string,
    customerEmail: t.string,
    customerPhone: t.string,
    customerAddress: tAddress,
    workSiteAddress: tAddress,
    articles: t.union([t.undefined, t.array(tArticle)]),
    distance: t.number,
    origin: t.union([t.null, t.string]),
    destination: t.union([t.null, t.string]),
    stage: t.string,
    identification: t.number,
    title: t.string,
    description: t.string,
    quoteCalculationVersion: t.union([t.undefined, t.null, t.number]),
})
export const tQuoteExact = t.exact(tQuote);

export async function articleInputToTArticle(articleInput: IArticleInput): Promise<tArticle> {

    function inputSupplementsToId(supplements: IQuantifiedSupplement[]): tQuantifiedSupplement[] {
        return supplements.map(s => {
            return {
                amount: s.amount,
                supplement: s.supplement.getId()
            }
        })
    }

    function handrailToTHandrail(hr: IHandrailInput): tHandrail {
        if(hr === null){
            return null;
        }
        return {
            type: hr.type.getId(),
            handle: hr.handle ? hr.handle.getId() : null,
            spindleThreadType: hr.spindleThreadType?.getId(),
            spindleThreadDescription: hr.spindleThreadDescription,
            supplements: inputSupplementsToId(hr.supplements),
            handrailPoleTypes: hr.handrailPoleTypes.map(pt => pt.getId()),
            handrailPoleFinishes: hr.handrailPoleFinishes.map(pt => pt.getId()),
        }
    }

    function extraHandrailToTExtraHandrail(hr: IExtraHandrailInput): tExtraHandrail {
        if(hr === null){
            return null;
        }
        return {
            type: hr.type.getId(),
            handle: hr.handle ? hr.handle.getId() : null,
            spindleThreadType: hr.spindleThreadType?.getId(),
            spindleThreadDescription: hr.spindleThreadDescription,
            handrailPoleTypes: hr.handrailPoleTypes.map(pt => pt.getId()),
            handrailPoleFinishes: hr.handrailPoleFinishes.map(pt => pt.getId()),
            treadCount: hr.treadCount,
            poleCount: hr.poleCount,
        }
    }

    const shape: tShape = {
        riserCount: articleInput.shape.riserCount,
        rawRiserThickness: articleInput.shape.getRiserThickness()?.getId(),
        isRiserThicknessEnabled: await articleInput.shape.isRiserThicknessEnabled(articleInput),
        staircaseShape: articleInput.shape.staircaseShape.getId(),
        stringerThickness: articleInput.shape.stringerThickness.getId(),
        treadSupplements: inputSupplementsToId(articleInput.shape.treadSupplements),
        treadThickness: articleInput.shape.treadThickness.getId(),
        closedStaircase: articleInput.shape.closedStaircase,
    };

    const handrail: tHandrail = handrailToTHandrail(articleInput.handrail);
    const extraHandrail: tExtraHandrail = extraHandrailToTExtraHandrail(articleInput.extraHandrail);
    const wallHandrail: tWallHandrail = articleInput.wallHandrail ? {
        type: articleInput.wallHandrail.type.getId(),
        hook: articleInput.wallHandrail.hook ? articleInput.wallHandrail.hook.getId() : null,
        length: articleInput.wallHandrail.length,
        hookCount: articleInput.wallHandrail.hookCount,
        bendCount: articleInput.wallHandrail.bendCount,
        ignoreWoodType: articleInput.wallHandrail.ignoreWoodType ?? false,  
    } : null;

    const ls = articleInput.landingStage;
    const landingStage: tLandingStage = {
        handrail: ls.handrail ? handrailToTHandrail(ls.handrail) : undefined,
        handrailLength: ls.handrailLength,
        concreteEnclosureLength: ls.concreteEnclosureWoodType === null ? 0 : ls.concreteEnclosureLength,
        concreteEnclosureWidth: ls.concreteEnclosureWoodType === null ? 0 : ls.concreteEnclosureWidth,
        concreteEnclosureWoodType: ls.concreteEnclosureWoodType === null ? null : ls.concreteEnclosureWoodType?.getId(),
        handrailPoleCount: ls.handrailPoleCount,
        handrailPoleTypes: ls.handrailPoleTypes.map(pt => pt.getId()),
        handrailPoleFinishes: ls.handrailPoleFinishes.map(pt => pt.getId()),
    };

    const tm = articleInput.treatment;
    const treatment: tTreatment = tm === null ? null : {
        type: tm.type.getId(),
        colors: tm.colors,
    };

    const t = articleInput.transportation;
    const transportation: tTransportation = {
        transportationMethod: t.transportationMethod,
        placementSupplements: inputSupplementsToId(t.placementSupplements),
        staircasePlacementOptions: t.staircasePlacementOptions.map(po => po.getId()),
        measurementByHotec: t.measurementByHotec,
        assembledByHotec: t.assembledByHotec,
    };

    const tc = articleInput.comments;
    const comments: tComments = tc.map(x => {
        return {
        page: x.page.toString(),
        content: x.content,
        }
    }
    );

    const a: tArticle = {
        reference: articleInput.reference,
        unitCount: articleInput.unitCount,
        width: articleInput.width,
        floorHeight: articleInput.floorHeight,
        treadCount: articleInput.treadCount,
        type: articleInput.type.getId(),
        woodType: articleInput.woodType.getId(),
        riserWoodType: articleInput.riserWoodType.getId(),
        isWood: articleInput.isWood,
        shape,
        handrail,
        extraHandrail,
        wallHandrail,
        landingStage,
        treatment,
        transportation,
        comments,
        variantGroup: articleInput.variantGroup,
        variantIncluded: articleInput.variantIncluded,
        discountPercentageStaircase: articleInput.discountPercentageStaircase,
        discountPercentageTreatment: articleInput.discountPercentageTreatment,
        discountPercentageDistribution: articleInput.discountPercentageDistribution,
        quoteId: articleInput.quoteId,
        id: articleInput.id ? await tPromise.decode(tObjectId, articleInput.id): undefined,
    };
    return a;
}

export async function quoteToTQuote(quote: Quote): Promise<tQuote> {
    const q: tQuote = {
        expirationDate: quote.getExpirationDate(),
        expired: quote.isExpired(),
        creationDate: quote.getCreationDate(),
        lastUpdateDate: quote.getLastUpdateDate(),
        dataDate: quote.getDataDate(),
        distributorId: quote.getDistributorId(),
        ownerId: quote.getOwnerId(),
        ownerName: quote.getOwnerName(),
        quoteState: quote.getQuoteState(),
        quoteNumber: quote.getQuoteNumber(),
        customerName: quote.getCustomerName(),
        customerEmail: quote.getCustomerEmail(),
        customerPhone: quote.getCustomerPhone(),
        customerAddress: quote.getCustomerAddress(),
        workSiteAddress: quote.getWorkSiteAddress(),
        articles: await Promise.all(quote.getArticles().map(async (article) => {
            const articleInput: IArticleInput = article.getArticleInput();
            return await articleInputToTArticle(articleInput);
        })
        ),
        distance: quote.getDistance(),
        origin: quote.getOrigin(),
        destination: quote.getDestination(),
        stage: quote.getStage(),
        identification: quote.getIdentification(),
        title: quote.getTitle(),
        description: quote.getDescription(),
        quoteCalculationVersion: quote.getQuoteCalculationVersion(),
    };
    return await tPromise.decode(tQuote, q);
}

export type tQuoteMinified = t.TypeOf<typeof tQuoteMinified>;
export const tQuoteMinified = t.type({ // TODO: add status, equal address for customer and worksite?
    id: tVersionedObjectId,
    creationDate: tDateFromString,
    lastUpdateDate: tDateFromString,
    dataDate: tDateFromString,
    distributorId: tObjectId,
    distributorName: t.string,
    customerName: t.string,
    articleCount: t.number,
    title: t.string,
    quoteState: t.string,
    quoteNumber: t.string,
    ownerName: t.string,
})