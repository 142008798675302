exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3hLzdOD7xhK0wsYe0-ixiZ{\n    width: 100%;\n}", ""]);

// exports
exports.locals = {
	"img": "_3hLzdOD7xhK0wsYe0-ixiZ"
};