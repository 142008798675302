import React from 'react';
import IUniformNode from '../../../../../shared/quote-rendering/IUniformNode';
import { IStringNodeProps } from '../../../../../shared/quote-rendering/UniformNodeFactory';

export default class StringNode implements IUniformNode<React.ReactNode>{
    
    constructor(
        private readonly props: IStringNodeProps
    ) {}

    renderWithManualKey() {
        const res = [];
        const split = this.props.text.split("\n");        
        for (let i = 0; i < split.length-1; i++) {
            const piece = split[i];
            res.push(piece);
            res.push(<br key={"br" + i}/>);
        }
        res.push(split[split.length - 1]);
        return res;
    }

    render(_seedKey: string) {
        return this.renderWithManualKey();
    }

}