import ObjectID from "bson-objectid";
import DataController from "orbiter-core/src/datastructures/DataController";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";

export interface IBaseConstantSet extends DataController {
    getTitle(): MultiLanguageString | Promise<MultiLanguageString>;
    getDescription(): MultiLanguageString | Promise<MultiLanguageString>;
    setTitle(newTitle: MultiLanguageString): void | Promise<void>;
    setDescription(newDescription: MultiLanguageString): void | Promise<void>;
    getPricePerRiser(): number;
    getLandingStageBase(): number;
    getLandingStageSpindleBase(): number;
    getHandrailSpindleBase(): number;
    getLandingStageEnclosureBase(): number;
    getLandingStageEnclosureSurcharge(): number;
    getLandingStageEnclosureSurchargeThreshold(): number;
    getInstallationMinTreads(): number;
    getDeliveryFreeKmCount(): number;
    getDeliveryKmCost(): number;
    getWidthMultiplier(): number;
    getWidthTreshold(): number;
    getTreadBase(): number;
    getHandrailBase(): number;
    getPlacementPriceConcreteEnclosure(): number;
    getPlacementPriceLandingStageHandrail(): number;
    getPlacementPriceHandrail(): number;
    getPricePerBend(): number,
    getMeasurementSurcharge(): number,
    getAssemblySurcharge(): number,
    getDeliveryBasePrice(): number,
    getGlobalMarginForDealers(): number,
    getDefaultLargeThicknessSupplementId(): ObjectID | null,
}

export default class BaseConstantSet extends DataController implements IBaseConstantSet {

    @DataController.dataProperty(async (x) => await x.asList())
    private title: MultiLanguageString;
    @DataController.dataProperty(async (x) => await x.asList())
    private description: MultiLanguageString;

    @DataController.dataProperty()
    public readonly pricePerRiser: number;

    @DataController.dataProperty()
    public readonly landingStageBase: number;

    @DataController.dataProperty()
    public readonly landingStageSpindleBase: number;

    @DataController.dataProperty()
    public readonly handrailSpindleBase: number;

    @DataController.dataProperty()
    public readonly landingStageEnclosureBase: number;

    @DataController.dataProperty()
    public readonly landingStageEnclosureSurcharge: number;

    @DataController.dataProperty()
    public readonly landingStageEnclosureSurchargeThreshold: number;

    @DataController.dataProperty()
    public readonly installationMinTreads: number;

    @DataController.dataProperty()
    public readonly deliveryFreeKmCount: number;

    @DataController.dataProperty()
    public readonly deliveryKmCost: number;

    @DataController.dataProperty()
    public readonly widthMultiplier: number;

    @DataController.dataProperty()
    public readonly widthTreshold: number;

    @DataController.dataProperty()
    public readonly treadBase: number;

    @DataController.dataProperty()
    public readonly handrailBase: number;

    @DataController.dataProperty()
    public readonly placementPriceConcreteEnclosure: number;
    
    @DataController.dataProperty()
    public readonly placementPriceLandingStageHandrail: number;
    
    @DataController.dataProperty()
    public readonly placementPriceHandrail: number;
    
    @DataController.dataProperty()
    public readonly pricePerBend: number;
    
    @DataController.dataProperty()
    public readonly measurementSurcharge: number;
    
    @DataController.dataProperty()
    public readonly assemblySurcharge: number;
    
    @DataController.dataProperty()
    public readonly deliveryBasePrice: number;
    
    @DataController.dataProperty()
    public readonly globalMarginForDealers: number;
    
    @DataController.dataProperty()
    public defaultLargeThicknessSupplementId: ObjectID | null;

    public constructor(title: MultiLanguageString, description: MultiLanguageString,
        pricePerRiser: number,
        landingStageBase: number,
        landingStageSpindleBase: number,
        handrailSpindleBase: number,
        landingStageEnclosureBase: number,
        landingStageEnclosureSurcharge: number,
        landingStageEnclosureSurchargeThreshold: number,
        installationMinTreads: number,
        deliveryFreeKmCount: number,
        deliveryKmCost: number,
        widthMultiplier: number,
        widthTreshold: number,
        treadBase: number,
        handrailBase: number,
        placementPriceConcreteEnclosure: number,
        placementPriceLandingStageHandrail: number,
        placementPriceHandrail: number,
        pricePerBend: number,
        measurementSurcharge: number,
        assemblySurcharge: number,
        deliveryBasePrice: number,
        globalMarginForDealers: number,
        defaultLargeThicknessSupplementId: ObjectID | null,
    ){
        super();
        this.setTitle(title);
        this.setDescription(description);

        this.pricePerRiser = pricePerRiser;
        this.landingStageBase = landingStageBase;
        this.landingStageSpindleBase = landingStageSpindleBase;
        this.handrailSpindleBase = handrailSpindleBase;
        this.landingStageEnclosureBase = landingStageEnclosureBase;
        this.landingStageEnclosureSurcharge = landingStageEnclosureSurcharge;
        this.landingStageEnclosureSurchargeThreshold = landingStageEnclosureSurchargeThreshold;
        this.installationMinTreads = installationMinTreads;
        this.deliveryFreeKmCount = deliveryFreeKmCount;
        this.deliveryKmCost = deliveryKmCost;
        this.widthMultiplier = widthMultiplier;
        this.widthTreshold = widthTreshold;
        this.treadBase = treadBase;
        this.handrailBase = handrailBase;
        this.placementPriceConcreteEnclosure = placementPriceConcreteEnclosure;
        this.placementPriceLandingStageHandrail = placementPriceLandingStageHandrail;
        this.placementPriceHandrail = placementPriceHandrail;
        this.pricePerBend = pricePerBend;
        this.measurementSurcharge = measurementSurcharge;
        this.assemblySurcharge = assemblySurcharge;
        this.deliveryBasePrice = deliveryBasePrice;
        this.globalMarginForDealers = globalMarginForDealers;
        this.defaultLargeThicknessSupplementId = defaultLargeThicknessSupplementId;
    }

    public getTitle(): MultiLanguageString {
        return this.title;
    }

    public getDescription(): MultiLanguageString {
        return this.description;
    }

    public setTitle(newTitle: MultiLanguageString): void {
        this.title = newTitle;
    }

    public setDescription(newDescription: MultiLanguageString): void {
        this.description = newDescription;
    }

    public setDefaultLargeThicknessSupplementId(defaultLargeThicknessSupplementId: ObjectID | null): void {
        this.defaultLargeThicknessSupplementId = defaultLargeThicknessSupplementId;
    }

    public clone(): BaseConstantSet{
        return new BaseConstantSet(
            this.getTitle().clone(),
            this.getDescription().clone(),
            this.pricePerRiser,
            this.landingStageBase,
            this.landingStageSpindleBase,
            this.handrailSpindleBase,
            this.landingStageEnclosureBase,
            this.landingStageEnclosureSurcharge,
            this.landingStageEnclosureSurchargeThreshold,
            this.installationMinTreads,
            this.deliveryFreeKmCount,
            this.deliveryKmCost,
            this.widthMultiplier,
            this.widthTreshold,
            this.treadBase,
            this.handrailBase,
            this.placementPriceConcreteEnclosure,
            this.placementPriceLandingStageHandrail,
            this.placementPriceHandrail,
            this.pricePerBend,
            this.measurementSurcharge,
            this.assemblySurcharge,
            this.deliveryBasePrice,
            this.globalMarginForDealers,
            this.defaultLargeThicknessSupplementId,
        );
    }

    public getPricePerRiser(): number{
        return this.pricePerRiser;
    }

    public getLandingStageBase(): number{
        return this.landingStageBase;
    }

    public getLandingStageSpindleBase(): number{
        return this.landingStageSpindleBase;
    }

    public getHandrailSpindleBase(): number{
        return this.handrailSpindleBase;
    }

    public getLandingStageEnclosureBase(): number{
        return this.landingStageEnclosureBase;
    }

    public getLandingStageEnclosureSurcharge(): number{
        return this.landingStageEnclosureSurcharge;
    }

    public getLandingStageEnclosureSurchargeThreshold(): number{
        return this.landingStageEnclosureSurchargeThreshold;
    }

    public getInstallationMinTreads(): number{
        return this.installationMinTreads;
    }

    public getDeliveryFreeKmCount(): number{
        return this.deliveryFreeKmCount;
    }

    public getDeliveryKmCost(): number{
        return this.deliveryKmCost;
    }

    public getWidthMultiplier(): number{
        return this.widthMultiplier;
    }

    public getWidthTreshold(): number{
        return this.widthTreshold;
    }

    public getTreadBase(): number{
        return this.treadBase;
    }

    public getHandrailBase(): number{
        return this.handrailBase;
    }

    public getPlacementPriceConcreteEnclosure(): number{
        return this.placementPriceConcreteEnclosure;
    }

    public getPlacementPriceLandingStageHandrail(): number{
        return this.placementPriceLandingStageHandrail;
    }

    public getPlacementPriceHandrail(): number{
        return this.placementPriceHandrail;
    }

    
    public getPricePerBend(): number{
        return this.pricePerBend;
    }
    
    public getMeasurementSurcharge(): number{
        return this.measurementSurcharge;
    }
    
    public getAssemblySurcharge(): number{
        return this.assemblySurcharge;
    }
    
    public getDeliveryBasePrice(): number{
        return this.deliveryBasePrice;
    }
    
    public getGlobalMarginForDealers(): number{
        return this.globalMarginForDealers;
    }
    
    public getDefaultLargeThicknessSupplementId(): ObjectID | null{
        return this.defaultLargeThicknessSupplementId;
    }


}
