exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1n3TqOjzQzPSLT1H7Bv1nH{\n    padding: 15px 15px 0 15px;\n}", ""]);

// exports
exports.locals = {
	"container": "_1n3TqOjzQzPSLT1H7Bv1nH"
};