import React from 'react';
import IUniformNode, { deepenKey } from '../../../../../shared/quote-rendering/IUniformNode';
import { ITextNodeProps } from '../../../../../shared/quote-rendering/UniformNodeFactory';

export default class TextNode implements IUniformNode<React.ReactNode>{
    
    constructor(
        private readonly props: ITextNodeProps<React.ReactNode>
    ) {}

    renderWithManualKey() {
        return <p key={this.props.key} style={this.props.style}>{this.props.children.map(x => x.renderWithManualKey())}</p>
    }

    render(seedKey: string){
        return <p key={seedKey} style={this.props.style}>{this.props.children.map((x,i) => x.render(deepenKey(seedKey, i)))}</p>
    }

}