import ObjectID from "bson-objectid";
import {isValidId, isValidNumber} from "orbiter-core/src/basic";
import DataController from "orbiter-core/src/datastructures/DataController";
import {VALUE_EXCEPTION} from "orbiter-core/src/exceptions";

export interface ITypeDependentPrice extends DataController {
    getStaircaseTypeId(): ObjectID;
    setStaircaseTypeId(id: ObjectID): void;
    getPrice(): number;
    setPrice(price: number): void;
}

export default class TypeDependentPrice extends DataController implements ITypeDependentPrice {

    @DataController.dataProperty()
    private price: number;

    @DataController.dataProperty()
    private staircaseTypeId: ObjectID;

    public constructor(
        staircaseTypeId: ObjectID,
        price: number,
        ){
        super();
        this.setStaircaseTypeId(staircaseTypeId);
        this.setPrice(price);
    }

    public getStaircaseTypeId(): ObjectID {
        return this.staircaseTypeId;
    }

    public getPrice(): number {
        return this.price;
    }

    public setPrice(price: number): void {
        if(!isValidNumber(price)) {
            throw VALUE_EXCEPTION;
        }
        this.price = Number(price);
    }

    public setStaircaseTypeId(staircaseTypeId: ObjectID | null): void {
        if(!isValidId(staircaseTypeId) && staircaseTypeId != null) {
            throw VALUE_EXCEPTION;
        }
        this.staircaseTypeId = staircaseTypeId;
    }

    public static parseFromJson(json: any): TypeDependentPrice[]{
        const input: any[] = json;
        const output: TypeDependentPrice[] = [];
        for (let i = 0; i < input.length; i++) {
            const price = input[i];
            output.push(new TypeDependentPrice(new ObjectID(price.staircaseTypeId), price.price));
        }
        return output;
    }

    public clone(): TypeDependentPrice{
        return new TypeDependentPrice(
            this.staircaseTypeId,
            this.price,
        )
    }


}
