import React from 'react';
import IUniformNode, { deepenKey } from '../../../../../shared/quote-rendering/IUniformNode';
import { ISplitViewNodeProps } from '../../../../../shared/quote-rendering/UniformNodeFactory';

export default class SplitViewNode implements IUniformNode<React.ReactNode>{

    constructor(
        private readonly props: ISplitViewNodeProps<React.ReactNode>
    ) { }

    renderWithManualKey() {
        return <div key={this.props.key} style={{ flexDirection: 'row', display: 'flex' }}>
            <div style={{ flexGrow: 1 }}>
                {this.props.left.map(x => x.renderWithManualKey())}
            </div>
            <div style={{ flexGrow: 1 }}>
                {this.props.right.map(x => x.renderWithManualKey())}
            </div>
        </div>
    }

    render(seedKey: string){
        return <div key={seedKey} style={{ flexDirection: 'row', display: 'flex' }}>
            <div style={{ flexGrow: 1 }}>
                {this.props.left.map((x,i) => x.render(deepenKey(seedKey, i)))}
            </div>
            <div style={{ flexGrow: 1 }}>
                {this.props.right.map((x,i) => x.render(deepenKey(seedKey, i)))}
            </div>
        </div>
    }

}