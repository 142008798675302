import * as React from "react";
import { inu, inun } from "orbiter-core/src/basic";
import { connect } from "react-redux";
import { loadCurrentUser, loadDistributors } from "../../loader";
import { t } from 'ttag';
import ConfigBlock from "../../components/ConfigBlock";
import { toSelectItems } from "./helper";
import SimpleSettingsTemplate from "./SimpleSettingsTemplate";
import MappedInput from "webc-reactcore/src/js/components/mainlayout/MappedInput";
import InfoScreen from "../../components/InfoScreen";
import HotecUserAPI from "../../apicontroller/HotecUserAPI";
import Distributor from "../../../../../shared/datastructures/Distributor";
import { createEmptyAddress } from "../../../../../shared/factories/address";
import PortalCheckbox from "webc-reactcore/src/js/components/mainlayout/PortalCheckbox";
import SettingsTemplate from "./SettingsTemplate";
import { ToolbarMgr } from "webc-reactcore/src/js/components/toolbar/ToolbarContextProvider";
import ToolbarSeparator from "webc-reactcore/src/js/components/toolbar/ToolbarSeparator";
import SimpleToolbarItem from "webc-reactcore/src/js/components/toolbar/SimpleToolbarItem";
import { startLoading, stopLoading } from "../newquote/article/loaders/loaderHelper";

@(connect((store: any) => {
    return { settings: { ...store.settings }, globalInformation: { ...store.globalInformation } };
}) as any)
export default class ProfileUser extends React.Component<any, { user: HotecUserAPI, updated: boolean }> {

    constructor(_) {
        super(_);
        this.state = {
            user: null,
            updated: false,
        };
    }

    componentDidMount() {
        this.setState({ user: this.props.globalInformation.user });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.globalInformation.user !== state.user && !state.updated) {
            return {
                user: props.globalInformation.user,
            }
        }
        return null;
    }

    public render() {
        return <>
            <SettingsTemplate title={t`gebruiker` + "."}>
                {this.state.user ? this.renderContent() : <InfoScreen title={this.props.globalInformation.loading ? t`De nodige informatie wordt ingeladen.` : t`Uw profiel kon niet worden ingeladen.`} image={"/cdn/images/stack.svg"} />}
            </SettingsTemplate>
        </>;

    }

    private toolbarItems = [];

    private async removeToolbarItems(ctx) {
        await ctx.removeToolbarItems([...this.toolbarItems]);
        this.toolbarItems = [];
    }

    private async update() {
        startLoading();
        const selected: HotecUserAPI = this.state.user;
        await selected.pushUpdate();
        // await loadCurrentUser();
        this.setState({ updated: false });
        stopLoading();
        window.location.reload();
    }

    private store(user: HotecUserAPI) {
        this.setState({ user, updated: true });
    }

    private renderContent() {
        return <>
            <ToolbarMgr key="edit" onMount={async (ctx) => {
                await this.removeToolbarItems(ctx);
                this.toolbarItems.push(await ctx.addToolbarItem(<SimpleToolbarItem onClick={this.update.bind(this)}>{t`Opslaan`}</SimpleToolbarItem>));
            }} />
            {this.subrenderContent()}
        </>
    }

    private subrenderContent() {
        const a: HotecUserAPI = this.state.user;
        return <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4">
                        <ConfigBlock
                            title={"Gebruikersinformatie"}
                            description="Algemene informatie."
                            topMost={true}
                        >
                            <MappedInput placeholder={t`Naam`} onBlur={(name) => {
                                const cl: HotecUserAPI = HotecUserAPI.clone(a);
                                try {
                                    cl.getData().setName(name);
                                } catch (e) {
                                    cl.getData().setName("");
                                }
                                this.store(cl);
                            }} value={inun(a, (a) => a.getName(), () => "")} />

                            <MappedInput placeholder={t`E-mailadres`} onBlur={(email) => {
                                const cl: HotecUserAPI = HotecUserAPI.clone(a);
                                try {
                                    cl.getData().setEmail(email);
                                } catch (e) {
                                    cl.getData().setEmail("");
                                }
                                this.store(cl);
                            }} value={inun(a, (a) => a.getEmail(), () => "")} />

                            <MappedInput type="password" placeholder={t`Wachtwoord`} onBlur={(password) => {
                                const cl: HotecUserAPI = HotecUserAPI.clone(a);
                                try {
                                    cl.getData().setPassword(password);
                                } catch (e) {
                                    cl.getData().setPassword("");
                                }
                                this.store(cl);
                            }} value={inun(a, (a) => a.getPassword(), () => "")} />
                        </ConfigBlock>
                    </div>
                    <div className="col-md-4">

                    </div>
                    <div className="col-md-4">

                    </div>
                </div>
            </div>
        </>;
    }

}
