exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._15WkudwlP8Jl0FAkC6mC_z > * {\n    font-weight: bold;\n    color: white;\n}\n._15WkudwlP8Jl0FAkC6mC_z a, ._15WkudwlP8Jl0FAkC6mC_z a:active, ._15WkudwlP8Jl0FAkC6mC_z a:hover, ._15WkudwlP8Jl0FAkC6mC_z a:focus{\n    color: white;\n    text-decoration: none;\n}\n._15WkudwlP8Jl0FAkC6mC_z > * {\n\tborder-radius: 8px;\n}\n\n._15WkudwlP8Jl0FAkC6mC_z > * {\n\tfont-weight: bold;\n\tcolor: white;\n}\n\n.isVpL3M-hd1RvlyvYn-Gp{\n\t/* background-color: #FFCB04; */\n}\n._2N4tUyDdHczN2S7B5WYvlr{\n\topacity: 0.5;\n}", ""]);

// exports
exports.locals = {
	"languagechoice": "_15WkudwlP8Jl0FAkC6mC_z",
	"active": "isVpL3M-hd1RvlyvYn-Gp",
	"inactive": "_2N4tUyDdHczN2S7B5WYvlr"
};