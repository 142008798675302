import IArticleInput, { IShapeInput, IQuantifiedSupplement } from "../IArticleInput";
import { IThickness } from "../staircasedata/Thickness";
import { IDatabaseController } from "orbiter-core/src/databasecontroller/DatabaseController";
import StaircaseShape, { IStaircaseShape } from "../staircasedata/StaircaseShape";
import { inu, isDefined, isValidStrictlyPositiveFloatingPoint, inun, isValidPositiveFloatingPoint } from "orbiter-core/src/basic";
import {INPUT_NOT_DEFINED, INVALID_TYPE, PROHIBITED_SELECTION, TOO_SMALL_VALUE} from "./inputExceptions";
import ThicknessRule from "../staircasedata/ThicknessRule";

const _ = undefined;

/**
 * Immutable shape input class.
 */
export default class ShapeInput implements IShapeInput{
       
    constructor(
        readonly riserCount: number | null = 1,
        private readonly riserThickness: IThickness & IDatabaseController = null,
        readonly staircaseShape: IStaircaseShape & IDatabaseController = null,
        readonly stringerThickness: IThickness & IDatabaseController = null,
        readonly treadSupplements: IQuantifiedSupplement[] = [],
        readonly treadThickness: IThickness & IDatabaseController = null,
        readonly closedStaircase?: boolean,
    ){}

    static from(shape: IShapeInput): ShapeInput{
        return new ShapeInput(
            shape.riserCount,
            shape.getRiserThickness(),
            shape.staircaseShape,
            shape.stringerThickness,
            shape.treadSupplements,
            shape.treadThickness,
            shape.closedStaircase,
        ).clone();
    }

    static async validityCheck(article: IArticleInput): Promise<void>{
        const shape: IShapeInput = article.shape;
        this.riserCountValidityCheck(shape);
        await this.riserThicknessValidityCheck(shape.getRiserThickness(), shape.getRiserThickness(), article);
        await this.treadThicknessValidityCheck(shape.treadThickness, article);
        await this.stringerThicknessValidityCheck(shape.stringerThickness, article);
        await this.staircaseShapeValidityCheck(shape.staircaseShape, article);
    }

    static riserCountValidityCheck(shape: IShapeInput): void{
        if(shape.closedStaircase && shape.riserCount === null)
            return;
        if(!isDefined(shape.riserCount))
            throw INPUT_NOT_DEFINED;
        else if(!isValidPositiveFloatingPoint(shape.riserCount))
            throw INVALID_TYPE;
    }

    static async riserThicknessValidityCheck(riserThickness: IThickness & IDatabaseController, actualRiserThickness: IThickness & IDatabaseController, article: IArticleInput): Promise<void>{
        if(riserThickness === null && actualRiserThickness === null)
            throw INPUT_NOT_DEFINED;
        if(!isDefined(actualRiserThickness))
            throw INPUT_NOT_DEFINED;
        
        const min = await this.getMinRiserThickness(article);
        if(await riserThickness.getThickness() < min){
            throw TOO_SMALL_VALUE;
        }
        
    }

    static otherThicknessValidityCheck(thickness: IThickness & IDatabaseController): void{
        if(!isDefined(thickness))
            throw INPUT_NOT_DEFINED;
    }

    static async getMinTreadThickness(article: IArticleInput): Promise<number>{
        return ThicknessRule.getMinTreadThickness(
            article.shape.staircaseShape,
            article.width,
            !article.shape.closedStaircase,
            await article.articleInfoStore.getThicknessRules()
        )
    }

    static async getMinStringerThickness(article: IArticleInput): Promise<number>{
        return ThicknessRule.getMinStringerThickness(
            article.shape.staircaseShape,
            article.width,
            !article.shape.closedStaircase,
            await article.articleInfoStore.getThicknessRules()
        )
    }

    static async getMinRiserThickness(article: IArticleInput): Promise<number>{
        return ThicknessRule.getMinRiserThickness(
            article.shape.staircaseShape,
            article.width,
            !article.shape.closedStaircase,
            article.riserWoodType,
            await article.articleInfoStore.getThicknessRules(),
            article.articleInfoStore,
        )
    }

    static async treadThicknessValidityCheck(treadThickness: IThickness & IDatabaseController, article: IArticleInput): Promise<void>{
        this.otherThicknessValidityCheck(treadThickness);
        if(await treadThickness.getThickness() < await this.getMinTreadThickness(article)){
            throw TOO_SMALL_VALUE;
        }
    }

    static async stringerThicknessValidityCheck(stringerThickness: IThickness & IDatabaseController, article: IArticleInput): Promise<void>{
        this.otherThicknessValidityCheck(stringerThickness);
        if(await stringerThickness.getThickness() < await this.getMinStringerThickness(article)){
            throw TOO_SMALL_VALUE;
        }
    }

    static async staircaseShapeValidityCheck(staircaseShape: IStaircaseShape & IDatabaseController, article: IArticleInput): Promise<void>{
        if(!isDefined(staircaseShape))
            throw INPUT_NOT_DEFINED;

        if(await this.isProhibitedShape(staircaseShape, article)){
            throw PROHIBITED_SELECTION;
        }
    }

    static async isProhibitedShape(staircaseShape: IStaircaseShape & IDatabaseController, article: IArticleInput): Promise<boolean>{

        if(staircaseShape){
            // Check if combination with handrail is OK
            if(article.handrail?.type && (await staircaseShape.getProhibitedHandrailTypeIds()).find(x => x.toHexString() === article.handrail.type.getId().toHexString())){
                return true;
            }
    
            // Check if combination with extra handrail is OK
            if(article.extraHandrail?.type && (await staircaseShape.getProhibitedHandrailTypeIds()).find(x => x.toHexString() === article.extraHandrail.type.getId().toHexString())){
                return true;
            }
        }

        return false;

    }

    async isRiserThicknessEnabled(article: IArticleInput): Promise<boolean>{
        if(article.riserWoodType === null)
            return true;
        return !(await article.riserWoodType.isMultiplex());
    }

    getRiserThickness(): IThickness & IDatabaseController {
        return this.riserThickness;
    }
    
    /*
        TODO: validity checker for tread supplements
        TODO: validity checker for closedStaircase
    */

    clone(
        riserCount?: number,
        riserThickness?: IThickness & IDatabaseController,
        staircaseShape?: IStaircaseShape & IDatabaseController,
        stringerThickness?: IThickness & IDatabaseController,
        treadSupplements?: any[], // TODO: change
        treadThickness?: IThickness & IDatabaseController,
        closedStaircase?: boolean,
    ): ShapeInput{
        return new ShapeInput(
            inu(riserCount, ()=>riserCount, ()=>this.riserCount),
            inu(riserThickness, ()=>riserThickness, ()=>this.riserThickness),
            inu(staircaseShape, ()=>staircaseShape, ()=>this.staircaseShape),
            inu(stringerThickness, ()=>stringerThickness, ()=>this.stringerThickness),
            inu(treadSupplements, ()=>[...treadSupplements], ()=>[...this.treadSupplements]), // TODO: deep clone? Probably yes
            inu(treadThickness, ()=>treadThickness, ()=>this.treadThickness),
            inu(closedStaircase, ()=>closedStaircase, ()=>this.closedStaircase),
        );
    }

    setRiserCount(riserCount: number | null): ShapeInput{
        if(isNaN(riserCount))
            riserCount = 0;
        return this.clone(riserCount);
    }

    setRiserThickness(riserThickness: IThickness & IDatabaseController): ShapeInput{
        return this.clone(_, riserThickness);
    }

    setStaircaseShape(staircaseShape: IStaircaseShape & IDatabaseController): ShapeInput{
        return this.clone(_, _, staircaseShape);
    }

    setStringerThickness(stringerThickness: IThickness & IDatabaseController): ShapeInput{
        return this.clone(_, _, _, stringerThickness);
    }

    setTreadSupplements(treadSupplements: any[]): ShapeInput{
        return this.clone(_, _, _, _, treadSupplements);
    }

    setTreadThickness(treadThickness: IThickness & IDatabaseController): ShapeInput{
        return this.clone(_, _, _, _, _, treadThickness);
    }

    setClosedStaircase(closedStaircase: boolean): ShapeInput{
        return this.clone(_, _, _, _, _, _, closedStaircase);
    }

}