import * as React from "react";
import {connect} from "react-redux";
import {doJsonGetApiCall} from "webc-reactcore/src/js/apicommunication";
import {setBasicInformation} from "../../../../actions/articleCreationActions";
import { IArticleCreationStateDispatch } from "../../../../reducers/articleCreationReducer";
import ArticleInput from "../../../../../../../shared/datastructures/articleinput/ArticleInput";
import Loading from "webc-reactcore/src/js/components/mainlayout/Loading";
import * as loadingStyle from "../../../../../css/components/Loading.css";
import PortalArticleInfoStore from "./PortalArticleInfoStore";

@(connect((store: any) => {
    return {...store.articleCreation};
}) as any)
export default class ArticleLoader extends React.Component<IArticleCreationStateDispatch & {isWood: boolean, article: ArticleInput | null}, {loading: boolean}> {

    constructor(a){
        super(a);
        this.state = {
            loading: true,
        }
    }

    private setBasicInformation(info){
        this.props.dispatch(setBasicInformation(info));
    }

    public componentWillMount(){

        // TODO: maybe load this information on beginning of step creation process or even when opening the portal?
        // Load basic staircase information/**/
        // doJsonGetApiCall("hotec/article/staircase/data/retrieve", async (result) => {

            let article = new ArticleInput(new PortalArticleInfoStore());
            if(this.props.article === null){
                article = article.setIsWood(this.props.isWood);
            }else{
                article = this.props.article;
            }
                
            this.setBasicInformation({
                
                article,
                temp_show_equal_placement_options: article.transportation.staircasePlacementOptionsEqual(),
            });

            this.setState({loading: false});

        // });

    }

    public componentDidUpdate(prevProps){
        if(prevProps.article?.id !== this.props.article?.id)
            this.componentWillMount();
    }

    public render() {
        if(this.state.loading)
            return <Loading css={{compose: loadingStyle}}/>;
        const children = this.props.children;
        if(this.props.article === null)
            return null;
        return (<span>{children}</span>);
    }
}
