import { OrbiterErrorCatcher, OrbiterError } from "orbiter-core/src/exceptions";

export default class ValidityErrorCatcher{

    private f: Function;
    private oec: OrbiterErrorCatcher;

    constructor(operation: Function){
        this.f = operation;
        this.oec = new OrbiterErrorCatcher();
        this.oec.setLastResortCatcher(() => {
            return {
                valid: false,
                invalidMessage: "",
            }
        })
    }

    public addCatcher(enabled: boolean, e: OrbiterError, invalidMessage: string = ""){
        this.oec.addCatcher(e, () => {
            if(enabled)
                return {
                    valid: false,
                    invalidMessage,
                }
            else
                return {
                    valid: true,
                    invalidMessage: "",
                }
        })
        return this;
    }

    public async catchError(): Promise<{valid: boolean, invalidMessage: string}>{
        try{
            await this.f();
            return {
                valid: true,
                invalidMessage: "",
            }
        }catch(e){
            return this.oec.catchError(e);
        }
    }

}

export async function isValid(operation: Function): Promise<boolean>{
    return (await (new ValidityErrorCatcher(await operation)
            .catchError())).valid;
}