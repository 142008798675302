import {isValidNumber, isDefined, toNumber} from "orbiter-core/src/basic";
import DataController from "orbiter-core/src/datastructures/DataController";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import { VALUE_EXCEPTION } from "orbiter-core/src/exceptions";

export interface IStaircasePlacementOption extends DataController {
    getPrice(): number;
    getTitle(): MultiLanguageString;
    getDescription(): MultiLanguageString;
    setPrice(newPrice: number): void | Promise<void>;
    setTitle(newTitle: MultiLanguageString): void | Promise<void>;
    setDescription(newDescription: MultiLanguageString): void | Promise<void>;
}

export class StaircasePlacementOptionValidityCheckers{

    public static isValidTitle(t: MultiLanguageString){
        return isDefined(t);
    }

    public static isValidDescription(d: MultiLanguageString){
        return isDefined(d);
    }

    public static isValidPrice(p: any){
        return isValidNumber(p);
    }

}

export default class StaircasePlacementOption extends DataController implements IStaircasePlacementOption {

    @DataController.dataProperty()
    private price: number;
    @DataController.dataProperty(async (x) => await x.asList())
    private title: MultiLanguageString;
    @DataController.dataProperty(async (x) => await x.asList())
    private description: MultiLanguageString;

    public constructor(price: number, title: MultiLanguageString, description: MultiLanguageString){
        super();
        this.setPrice(price);
        this.setTitle(title);
        this.setDescription(description);
    }

    public getTitle(): MultiLanguageString {
        return this.title;
    }

    public getDescription(): MultiLanguageString {
        return this.description;
    }

    public getPrice(): number {
        return this.price;
    }

    public setTitle(newTitle: MultiLanguageString): void {
        this.title = newTitle;
    }

    public setDescription(newDescription: MultiLanguageString): void {
        this.description = newDescription;
    }

    public clone(): StaircasePlacementOption{
        return new StaircasePlacementOption(
            this.price,
            this.title.clone(),
            this.description.clone(),
        )
    }

    public setPrice(newPrice: number): void {
        // if(!isValidNumber(newPrice)) {
        //     throw VALUE_EXCEPTION;
        // }
        this.price = toNumber(newPrice);
    }

    public isValid(): boolean{
        return StaircasePlacementOptionValidityCheckers.isValidDescription(this.getDescription())
                && StaircasePlacementOptionValidityCheckers.isValidTitle(this.getTitle())
                && StaircasePlacementOptionValidityCheckers.isValidPrice(this.getPrice);
    }

}
