// TODO: Split in different sub-objects

const defaultState = {
    selectedDistributor: undefined,
    selectedStaircaseType: undefined,
    selectedWoodType: undefined,
};

export default function reducer(state = defaultState, action){
    // MAKE SURE THE STATE IS NOT MUTATED, ALWAYS RETURN BRAND NEW VALUES
    switch(action.type){
        case "SET_SETTINGS_BASIC_INFORMATION" : {
            return {...state, ...action.payload};
        }
        case "REMOVE_SETTINGS_INFORMATION" : {
            return {...defaultState};
        }
    }
    return state;
}
