import IArticleInput, { IQuantifiedSupplement } from "../IArticleInput";
import { IDatabaseController } from "orbiter-core/src/databasecontroller/DatabaseController";
import { IStaircaseType } from "../staircasedata/StaircaseType";
import { IWoodType } from "../staircasedata/WoodType";
import ArticleInput from "../articleinput/ArticleInput";
import ShapeInputDiff from "./ShapeInputDif";
import HandrailInputDiff from "./HandrailInputDiff";
import ExtraHandrailInputDiff from "./ExtraHandrailInputDiff";
import LandingStageInputDiff from "./LandingStageInputDiff";
import TreatmentInputDiff from "./TreatmentInputDiff";
import TransportationInputDiff from "./TransportationInputDiff";
import WallHandrailInputDiff from "./WallHandrailInputDiff";

const _ = undefined;

export interface IArticleDiffLine<T>{
    different: boolean,
    leftValue: T,
    rightValue: T,
}

export function newDiffFalseLine<T>(left: T = null, right: T = null): IArticleDiffLine<T>{
    return {
        different: false,
        leftValue: left,
        rightValue: right,
    }
}

export function newDiffTrueLine<T>(left: T, right: T): IArticleDiffLine<T>{
    return {
        different: true,
        leftValue: left,
        rightValue: right,
    }
}

export function differingNumbers(num1: number, num2: number): boolean{
    return num1 !== num2;
}

export function differingStrings(s1: string | undefined | null, s2: string | undefined | null): boolean{
    return s1 !== s2;
}

export function differingSupplements(sup1: IQuantifiedSupplement[], sup2: IQuantifiedSupplement[]): boolean{
    if(sup1.length !== sup2.length){
        return true;
    }
    for (const s1 of sup1) {
        if(!sup2.find(x => x.amount === s1.amount && x.supplement.getSid() === s1.supplement.getSid())){
            return true;
        }
    }
    return false;
}

export function differingUnorderedList(left: any[], right: any[]): boolean{
    if(left.length !== right.length){
        return true;
    }
    for (const a of left) {
        if(!right.find(x => x === a)){
            return true;
        }
    }
    return false;
}

export function differingDBControllerList(left: IDatabaseController[], right: IDatabaseController[]): boolean{
    if(left.length !== right.length){
        return true;
    }
    left.forEach((l, i) => {
        
    });
    for (let i = 0; i < left.length; i++) {
        const l = left[i];
        const r = right[i];

        if(differingStrings(l?.getSid(), r?.getSid())){
            return true;
        }
        
    }
    return false;
}

export async function calculateDifferences(mainArticle: IArticleInput, children: IArticleInput[]): Promise<ArticleInputDiff[]>{
    const main = ArticleInput.from(mainArticle);
    const entireDiff = children.map((c) => {
        const variant = ArticleInput.from(c);
        const calcDiff: Promise<ArticleInputDiff> = (async () => {
            const d = new ArticleInputDiff(main, variant);
            await d.diff();
            return d;
        })();
        return calcDiff;
    });
    return await Promise.all(entireDiff);
}

export default class ArticleInputDiff{

    public unitCount: IArticleDiffLine<number> = newDiffFalseLine();
    public width: IArticleDiffLine<number> = newDiffFalseLine();
    public floorHeight: IArticleDiffLine<number> = newDiffFalseLine();
    public treadCount: IArticleDiffLine<number> = newDiffFalseLine();
    public type: IArticleDiffLine<IStaircaseType & IDatabaseController> = newDiffFalseLine();
    public woodType: IArticleDiffLine<IWoodType & IDatabaseController> = newDiffFalseLine();
    public riserWoodType: IArticleDiffLine<IWoodType & IDatabaseController> = newDiffFalseLine();
    public isWood: IArticleDiffLine<boolean> = newDiffFalseLine();
    public shape: ShapeInputDiff;
    public handrail: HandrailInputDiff;
    public extraHandrail: ExtraHandrailInputDiff;
    public wallHandrail: WallHandrailInputDiff;
    public landingStage: LandingStageInputDiff;
    public treatment: TreatmentInputDiff;
    public transportation: TransportationInputDiff;  
    public discountPercentageStaircase: IArticleDiffLine<number> = newDiffFalseLine();      
    public discountPercentageTreatment: IArticleDiffLine<number> = newDiffFalseLine();      
    public discountPercentageDistribution: IArticleDiffLine<number> = newDiffFalseLine();      

    constructor(readonly a1: ArticleInput, readonly a2: ArticleInput){}

    public async diff(){
        await this.diffUnitCount();
        await this.diffWidth();
        await this.diffFloorHeight();
        await this.diffTreadCount();
        await this.diffType();
        await this.diffWoodType();
        await this.diffRiserWoodType();
        await this.diffIsWood();
        await this.diffShape();
        await this.diffHandrail();
        await this.diffExtraHandrail();
        await this.diffWallHandrail();
        await this.diffLandingStage();
        await this.diffTreatment();
        await this.diffTransportation();
        await this.diffDiscountPercentageStaircase();
        await this.diffDiscountPercentageTreatment();
        await this.diffDiscountPercentageDistribution();
    }

    public differs(): boolean{
        return this.unitCount.different
            || this.width.different
            || this.floorHeight.different
            || this.treadCount.different
            || this.type.different
            || this.woodType.different
            || this.riserWoodType.different
            || this.isWood.different
            || this.shape.differs()
            || this.handrail.differs()
            || this.extraHandrail.differs()
            || this.wallHandrail.differs()
            || this.landingStage.differs()
            || this.treatment.differs()
            || this.transportation.differs()
            || this.discountPercentageStaircase.different
            || this.discountPercentageTreatment.different
            || this.discountPercentageDistribution.different;
    }

    private async diffUnitCount(){
        if(differingNumbers(this.a1.unitCount, this.a2.unitCount)){
            this.unitCount = newDiffTrueLine(
                this.a1.unitCount,
                this.a2.unitCount,
            )
        }
    }

    private async diffDiscountPercentageStaircase(){
        if(differingNumbers(this.a1.discountPercentageStaircase, this.a2.discountPercentageStaircase)){
            this.discountPercentageStaircase = newDiffTrueLine(
                this.a1.discountPercentageStaircase,
                this.a2.discountPercentageStaircase,
            )
        }
    }

    private async diffDiscountPercentageTreatment(){
        if(differingNumbers(this.a1.discountPercentageTreatment, this.a2.discountPercentageTreatment)){
            this.discountPercentageTreatment = newDiffTrueLine(
                this.a1.discountPercentageTreatment,
                this.a2.discountPercentageTreatment,
            )
        }
    }

    private async diffDiscountPercentageDistribution(){
        if(differingNumbers(this.a1.discountPercentageDistribution, this.a2.discountPercentageDistribution)){
            this.discountPercentageDistribution = newDiffTrueLine(
                this.a1.discountPercentageDistribution,
                this.a2.discountPercentageDistribution,
            )
        }
    }

    private async diffWidth(){
        if(differingNumbers(this.a1.width, this.a2.width)){
            this.width = newDiffTrueLine(
                this.a1.width,
                this.a2.width,
            )
        }
    }

    private async diffFloorHeight(){
        if(differingNumbers(this.a1.floorHeight, this.a2.floorHeight)){
            this.floorHeight = newDiffTrueLine(
                this.a1.floorHeight,
                this.a2.floorHeight,
            )
        }
    }

    private async diffTreadCount(){
        if(differingNumbers(this.a1.treadCount, this.a2.treadCount)){
            this.treadCount = newDiffTrueLine(
                this.a1.treadCount,
                this.a2.treadCount,
            )
        }
    }

    private async diffType(){
        if(differingStrings(this.a1.type?.getSid(), this.a2.type?.getSid())){
            this.type = newDiffTrueLine(
                this.a1.type,
                this.a2.type,
            )
        }
    }

    private async diffWoodType(){
        if(differingStrings(this.a1.woodType?.getSid(), this.a2.woodType?.getSid())){
            this.woodType = newDiffTrueLine(
                this.a1.woodType,
                this.a2.woodType,
            )
        }
    }

    private async diffRiserWoodType(){
        const newRiserThicknessEquals12 = this.a2.shape.getRiserThickness().getThickness() == 12;
        const oldRiserThicknessEquals12 = this.a1.shape.getRiserThickness().getThickness() == 12;
        if(oldRiserThicknessEquals12 && newRiserThicknessEquals12){
            // Can not be different, they're both 'multiplex fineer' (#150)
        }else if(
            differingStrings(this.a1.riserWoodType?.getSid(), this.a2.riserWoodType?.getSid())
            || newRiserThicknessEquals12
        ){
            this.riserWoodType = newDiffTrueLine(
                this.a1.riserWoodType,
                this.a2.riserWoodType,
            )
        }
    }

    private async diffIsWood(){
        if(this.a1.isWood !== this.a2.isWood){
            this.isWood = newDiffTrueLine(
                this.a1.isWood,
                this.a2.isWood,
            )
        }
    }

    private async diffShape(){
        this.shape = new ShapeInputDiff(this.a1, this.a2);
        await this.shape.diff();
    }

    private async diffHandrail(){
        this.handrail = new HandrailInputDiff(this.a1.handrail, this.a2.handrail);
        await this.handrail.diff();
    }

    private async diffExtraHandrail(){
        this.extraHandrail = new ExtraHandrailInputDiff(this.a1, this.a2);
        await this.extraHandrail.diff();
    }

    private async diffWallHandrail(){
        this.wallHandrail = new WallHandrailInputDiff(this.a1, this.a2);
        await this.wallHandrail.diff();
    }

    private async diffLandingStage(){
        this.landingStage = new LandingStageInputDiff(this.a1.landingStage, this.a2.landingStage, this.a1, this.a2);
        await this.landingStage.diff();
    }

    private async diffTreatment(){
        this.treatment = new TreatmentInputDiff(this.a1.treatment, this.a2.treatment);
        await this.treatment.diff();
    }

    private async diffTransportation(){
        this.transportation = new TransportationInputDiff(this.a1.transportation, this.a2.transportation);
        await this.transportation.diff();
    }

}