exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".KuqWI5uoR8xpNMclbjw5o{\n    margin-left: auto;\n    margin-right: auto;\n    max-width: 1000px;\n    margin-top: 20px;\n    /* border-style: solid;\n    border-width: 1px;\n    border-color: black; */\n    padding: 20px;\n    box-shadow: 0 0 19px 7px rgb(0 0 0 / 14%);\n    border-radius: 8px;\n}\n.KuqWI5uoR8xpNMclbjw5o p{\n    padding: 0;\n    margin: 0;\n}\n._3rCd_mV5PRzxhRypnqr965{\n    margin-left: auto;\n    margin-right: auto;\n    max-width: 1000px;\n}\n\n._3rCd_mV5PRzxhRypnqr965 button:hover{\n    background-color: #f2c004;\n    border-color: #f2c004;\n}", ""]);

// exports
exports.locals = {
	"outer": "KuqWI5uoR8xpNMclbjw5o",
	"buttons": "_3rCd_mV5PRzxhRypnqr965"
};