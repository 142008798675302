import DataController from "orbiter-core/src/datastructures/DataController";
import { IArticle, initCalculation } from "./ArticleCalculation";
import ObjectID from "bson-objectid";
import { tAddress, tDateFromString } from "./simplifiedNetworkCommunicationTypes";
import IQuoteSpecificCharges from "./IQuoteSpecificCharges";
import { DELIVERY, PLACEMENT } from "./articleinput/TransportationInput";
import { IBaseConstantSet } from "./staircasedata/BaseConstantSet";
import { applyMarginFactor, MarginFactorType } from "../controllers/marginCalculations";
import { IDistributor } from "./Distributor";
import { initSubCalculation, SubCalculation } from "../controllers/subCalculations";
import ArticleInput from "./articleinput/ArticleInput";

export const QUOTE_STATES: string[] = ["QUOTE_DRAFT", "QUOTE_SENT", "QUOTE_CONFIRMED", "ORDER_DRAFT", "ORDER_SENT", "ORDER_CONFIRMED"];
export const CURRENT_QUOTE_CALCULATION_VERSION: number = 2;

export default class Quote extends DataController {

    @DataController.dataProperty()
    private distributorId: ObjectID;

    @DataController.dataProperty()
    private ownerId: ObjectID;

    @DataController.dataProperty()
    private ownerName: string;

    @DataController.dataProperty()
    private quoteState: string;

    @DataController.dataProperty()
    private quoteNumber: string;
    
    @DataController.dataProperty()
    private creationDate: tDateFromString;
    
    @DataController.dataProperty()
    private expirationDate: tDateFromString;
    
    @DataController.dataProperty()
    private lastUpdateDate: tDateFromString;
    
    @DataController.dataProperty()
    private dataDate: tDateFromString;

    @DataController.dataProperty()
    private customerName: string;

    @DataController.dataProperty()
    private customerEmail: string;

    @DataController.dataProperty()
    private customerPhone: string;
    
    @DataController.dataProperty()
    private customerAddress: tAddress;

    @DataController.dataProperty()
    private workSiteAddress: tAddress;

    @DataController.dataProperty()
    private articles: IArticle[];

    @DataController.dataProperty()
    private distance: number;

    @DataController.dataProperty()
    private origin: string | null;
    
    @DataController.dataProperty()
    private destination: string | null;
    
    @DataController.dataProperty()
    private stage: string | null;
    
    @DataController.dataProperty()
    private identification: number;
    
    @DataController.dataProperty()
    private title: string;
    
    @DataController.dataProperty()
    private description: string;

    @DataController.dataProperty()
    private quoteCalculationVersion: number;

    public constructor(
        distributorId: ObjectID,
        ownerId: ObjectID,
        ownerName: string,
        quoteState: string,
        quoteNumber: string,
        creationDate: tDateFromString,
        expirationDate: tDateFromString,
        lastUpdateDate: tDateFromString,
        dataDate: tDateFromString,
        customerName: string,
        customerEmail: string,
        customerPhone: string,
        customerAddress: tAddress,
        workSiteAddress: tAddress,
        articles: IArticle[],
        distance: number,
        origin: string | null,
        destination: string | null,
        stage: string,
        identification: number,
        title: string,
        description: string,
        quoteCalculationVersion: number,
    ){
        super();
        this.distributorId = distributorId;
        this.ownerId = ownerId;
        this.ownerName = ownerName;
        this.quoteState = quoteState;
        this.quoteNumber = quoteNumber;
        this.creationDate = creationDate;
        this.expirationDate = expirationDate;
        this.lastUpdateDate = lastUpdateDate;
        this.dataDate = dataDate;
        this.customerName = customerName;
        this.customerEmail = customerEmail;
        this.customerPhone = customerPhone;
        this.customerAddress = customerAddress;
        this.workSiteAddress = workSiteAddress;
        this.articles = articles;
        this.distance = distance;
        this.origin = origin;
        this.destination = destination;
        this.stage = stage;
        this.identification = identification;
        this.title = title;
        this.description = description;
        this.quoteCalculationVersion = quoteCalculationVersion;
    }

    public getDistributorId(): ObjectID {
        return this.distributorId;
    }

    public getOwnerId(): ObjectID {
        return this.ownerId;
    }

    public getOwnerName(): string {
        return this.ownerName;
    }

    public getQuoteState(): string {
        return this.quoteState;
    }

    public getQuoteNumber(): string {
        return this.quoteNumber;
    }

    public getCustomerName(): string {
        return this.customerName;
    }

    public getCustomerEmail(): string {
        return this.customerEmail;
    }

    public getCustomerPhone(): string {
        return this.customerPhone;
    }

    public getCustomerAddress(): tAddress {
        return this.customerAddress;
    }

    public getWorkSiteAddress(): tAddress {
        return this.workSiteAddress;
    }

    public getArticles(): IArticle[] {
        return this.articles;
    }

    public getActiveArticles(): IArticle[] {
        return this.articles.filter(article => ArticleInput.isIncluded(article.getArticleInput()));
    }

    public getCreationDate(): tDateFromString {
        return this.creationDate;
    }

    public getExpirationDate(): tDateFromString {
        return this.expirationDate;
    }

    public isExpired(): boolean {
        return Quote.isExpired(this.getExpirationDate());
    }

    public static isExpired(expirationDate: Date): boolean{
        return expirationDate && new Date() > expirationDate;
    }

    public getLastUpdateDate(): tDateFromString {
        return this.lastUpdateDate;
    }

    public getDataDate(): tDateFromString {
        return this.dataDate;
    }

    public getDistance(): number{
        return this.distance;
    }

    public getOrigin(): string | null{
        return this.origin;
    }

    public getDestination(): string | null{
        return this.destination;
    }

    public getStage(): string{
        return this.stage;
    }

    public isOrder(): boolean{
        if(this.quoteState)
            return this.quoteState.startsWith("ORDER");
        return false;
    }
    
    public getIdentification(): number{
        return this.identification;
    }

    public getTitle(): string{
        return this.title;
    }

    public getDescription(): string{
        return this.description;
    }

    public getQuoteCalculationVersion(): number{
        return this.quoteCalculationVersion;
    }

    public setDistance(distance: number){
        this.distance = distance;
    }

    public setLastUpdateDate(lastUpdateDate: Date){
        this.lastUpdateDate = lastUpdateDate;
    }

    public setOrigin(origin: string | null){
        this.origin = origin;
    }
    
    public setDestination(destination: string | null){
        this.destination = destination;
    }

    public async calculateQuoteSpecificCharges(): Promise<IQuoteSpecificCharges> {
        const quoteSpecificCharges: IQuoteSpecificCharges = {
            delivery: initCalculation(),

            total: initCalculation(),
        };

        
        if(this.quoteCalculationVersion === 1){ // Calculate delivery for an entire quote, instead of on a per-article basis (reversed in version 2)

            let activeArticles = this.getActiveArticles();
            if(activeArticles.length > 0){
                const distributorId = this.getDistributorId();
                const distributor: IDistributor = await activeArticles[0].getArticleInput().articleInfoStore.getDistributor(distributorId);

                let maxDeliveryPlacementPrice: SubCalculation = initSubCalculation();
                for (const article of activeArticles) {
                    const articleInput = article.getArticleInput();
                    const bcs: IBaseConstantSet = await articleInput.articleInfoStore.getBaseConstantSet();
                    
                    // Indicate whether the article will be delivered by Hotec
                    const isDelivered: number = articleInput.transportation.transportationMethod == PLACEMENT || articleInput.transportation.transportationMethod == DELIVERY ? 1 : 0;
                    // Calculate delivery price
                    const kmCount: number = Math.max(0, this.getDistance() - bcs.getDeliveryFreeKmCount());
                    let deliveryCharge = bcs.getDeliveryKmCost() * kmCount * isDelivered;
                    
                    if(articleInput?.transportation?.transportationMethod === DELIVERY){
                        // Add base delivery price (only if the article is delivered but not placed; in the case of placement this is included in the placement price)
                        deliveryCharge += bcs.getDeliveryBasePrice();
                        
                    }

                    const discountPercentages = {
                        discountPercentageStaircase: articleInput.discountPercentageStaircase,
                        discountPercentageDistribution: articleInput.discountPercentageDistribution,
                        discountPercentageTreatment: articleInput.discountPercentageTreatment,
                    }

                    const deliveryPlacementPrice = await applyMarginFactor(bcs, distributor, discountPercentages, deliveryCharge, MarginFactorType.DISTRIBUTION);

                    if(deliveryPlacementPrice.totalPrice > maxDeliveryPlacementPrice.totalPrice){
                        maxDeliveryPlacementPrice = deliveryPlacementPrice;
                    }
                }

                quoteSpecificCharges.delivery.placementPrice = maxDeliveryPlacementPrice;
                quoteSpecificCharges.delivery.totalPrice = quoteSpecificCharges.delivery.placementPrice;
            }
            
            
        }

        quoteSpecificCharges.total = quoteSpecificCharges.delivery;

        return quoteSpecificCharges;
    }

}