import * as React from "react";
import { connect } from "react-redux";
import { loadHandrailTypes } from "../../loader";
import { dt } from "webc-reactcore/src/js/stores/GlobalStore";
import { toSelectItems, createTitlePortalCollapsibleLanguageField, createDescriptionPortalCollapsibleLanguageField, createApiTitlePortalCollapsibleLanguageField, createApiDescriptionPortalCollapsibleLanguageField, toObjectSelectItems } from "./helper";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import SimpleSettingsTemplate from "./SimpleSettingsTemplate";
import ConfigBlock from "../../components/ConfigBlock";
import HandrailTypeAPI from "../../apicontroller/staircasedata/HandrailTypeAPI";
import HandrailType from "../../../../../shared/datastructures/staircasedata/HandrailType";
import MappedInput from "webc-reactcore/src/js/components/mainlayout/MappedInput";
import { toNumber, inu, inun } from "orbiter-core/src/basic";
import { t } from "ttag";
import PortalCheckbox from "webc-reactcore/src/js/components/mainlayout/PortalCheckbox";
import InfoScreen from "../../components/InfoScreen";
import PortalDropdown from "webc-reactcore/src/js/components/mainlayout/PortalDropdown";
import * as PortalDropdownStyle from "../../../css/PortalDropdown.css";
import HandrailHandleAPI from "../../apicontroller/staircasedata/HandrailHandleAPI";
import Select from "webc-reactcore/src/js/components/mainlayout/PortalSelect";
import * as PortalSelectStyle from "../../../css/PortalSelect.css";
import SpindleThreadTypeAPI from "../../apicontroller/staircasedata/SpindleThreadTypeAPI";
import ObjectID from "bson-objectid";
import SelectItem from "../../components/DeleteSelectItem";


@(connect((store: any) => {
    return { settings: { ...store.settings }, globalInformation: { ...store.globalInformation } };
}) as any)
export default class HandrailTypeSettings extends React.Component<any, any> {

    public handrailTypesToSelectItems() {
        return toSelectItems<HandrailTypeAPI>("handrailtype", this.props.globalInformation.handrailTypes.sort((x, y) => dt(x.getTitle()) < dt(y.getTitle()) ? -1 : 1), (x) => HandrailTypeAPI.clone(x), (x) => dt(x.getTitle()));
    }

    public supportedSpindleIdsToSelectItems(spindleIds: ObjectID[], onDelete: (id: ObjectID) => void) {
        const spindles : SpindleThreadTypeAPI[] = spindleIds.map(x => this.props.globalInformation.spindleThreadTypes.find(e => e.getId().toHexString() == x.toHexString()));

        const list = [];
        for (const spindle of spindles) {
            list.push(
                <SelectItem key={"supportedspindleidsselectitems" + list.length} 
                    id={spindle.getId()}
                    onDeleteClick={() => {
                        onDelete(spindle.getId());
                    }}
                    data={{
                        item: spindle,
                    }}>{dt(spindle.getTitle())}</SelectItem>,
            );
        }
        return list;
    }

    private addSupportedSpindleId(a: HandrailType, id: ObjectID) {
        let newSpindles;
        if(a.getSupportedSpindleIds().find(x => x == id)){
            // Already present
            newSpindles = [...a.getSupportedSpindleIds()];
        }else{
            // Not yet present
            newSpindles = [...a.getSupportedSpindleIds(), id];
        }

        a.setSupportedSpindleIds(newSpindles);
    }

    private removeSupportedSpindleId(a: HandrailType, id: ObjectID) {
        a.setSupportedSpindleIds(a.getSupportedSpindleIds().filter(x => x.toHexString() != id.toHexString()));
    }

    public spindleThreadTypesToSelectItems(a: HandrailType) {
        const unfilteredSpindleThreadTypes = this.props.globalInformation.spindleThreadTypes;
        const filteredSpindleThreadTypes = unfilteredSpindleThreadTypes.filter(x => a.getSupportedSpindleIds().findIndex( y => y.toHexString() == x.getId().toHexString()) == -1);
        return toObjectSelectItems<SpindleThreadTypeAPI>(filteredSpindleThreadTypes.sort((x, y) => dt(x.getTitle()) < dt(y.getTitle()) ? -1 : 1), (x) => SpindleThreadTypeAPI.clone(x), (x) => dt(x.getTitle()));
    }

    public handrailHandlesToSelectItems() {
        return toObjectSelectItems<HandrailHandleAPI>(this.props.globalInformation.handrailHandles.sort((x: HandrailHandleAPI, y: HandrailHandleAPI) => dt(x.getTitle()) < dt(y.getTitle()) ? -1 : 1), (x) => HandrailHandleAPI.clone(x), (x) => dt(x.getTitle()));
    }

    private async createEmpty(): Promise<HandrailType> {
        return new HandrailType(0, 0, new MultiLanguageString(), new MultiLanguageString(), false, false, null, []);
    }

    private async create(data: HandrailType) {
        await HandrailTypeAPI.create(data);
    }

    public render() {
        return <SimpleSettingsTemplate
            title="Leuningtypes"
            selectItems={this.handrailTypesToSelectItems()}

            reload={loadHandrailTypes.bind(this)}
            createEmpty={this.createEmpty.bind(this)}
            create={this.create.bind(this)}
            clone={(i: HandrailTypeAPI) => HandrailTypeAPI.clone(i)}

            renderEdit={this.renderEdit.bind(this)}
            renderCreate={this.renderCreate.bind(this)}

            infoScreen={<InfoScreen title={t`Selecteer een leuningtype.`} image={"/cdn/images/pole.svg"}/>}
        />;
    }


    private renderEdit(a: HandrailTypeAPI, setSelected: (i: HandrailTypeAPI) => void) {
        const supportedSpindlesSelectItems = this.supportedSpindleIdsToSelectItems(a.getSupportedSpindleIds(), (toDeleteId) => {
            const cl: HandrailTypeAPI = HandrailTypeAPI.clone(a);
            this.removeSupportedSpindleId(cl.getData(), toDeleteId);
            setSelected(cl);
        });
        return this.doRender(
            createApiTitlePortalCollapsibleLanguageField(a, (x) => HandrailTypeAPI.clone(x), setSelected.bind(this)),
            createApiDescriptionPortalCollapsibleLanguageField(a, (x) => HandrailTypeAPI.clone(x), setSelected.bind(this)),
            <MappedInput placeholder={t`Prijsfactor`}
                onBlur={(factor) => {
                    const cl: HandrailTypeAPI = HandrailTypeAPI.clone(a);
                    try {
                        cl.getData().setPrice(toNumber(factor));
                    } catch (e) {
                        cl.getData().setPrice(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getPrice(), () => "")} />,
            <MappedInput placeholder={t`L1-factor`}
                onBlur={(factor) => {
                    const cl: HandrailTypeAPI = HandrailTypeAPI.clone(a);
                    try {
                        cl.getData().setL1Factor(toNumber(factor));
                    } catch (e) {
                        cl.getData().setL1Factor(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getL1Factor(), () => "")} />,
            <PortalCheckbox placeholder={t`Toon modelspijlen`}
                onChange={(val) => {
                    if(val !== a.isShowSpindle()){
                        const cl: HandrailTypeAPI = HandrailTypeAPI.clone(a);
                        try {
                            cl.getData().setShowSpindle(val);
                        } catch (e) {
                            cl.getData().setShowSpindle(false);
                        }
                        setSelected(cl);
                    }
                }}
                value={inu(a, (a) => a.isShowSpindle(), () => "")}>Toon modelspijlen</PortalCheckbox>,
            <PortalCheckbox placeholder={t`Uitgesloten in prijsberekening`}
                onChange={(val) => {
                    if(val !== a.isExcludedFromPriceCalculations()){
                        const cl: HandrailTypeAPI = HandrailTypeAPI.clone(a);
                        try {
                            cl.getData().setExcludedFromPriceCalculations(val);
                        } catch (e) {
                            cl.getData().setExcludedFromPriceCalculations(false);
                        }
                        setSelected(cl);
                    }
                }}
                value={inu(a, (a) => a.isExcludedFromPriceCalculations(), () => "")}>Uitsluiten in prijsberekening</PortalCheckbox>,
                <PortalDropdown placeholder={t`Standaard handgreep`}
                    css={{ compose: PortalDropdownStyle }}
                    items={this.handrailHandlesToSelectItems()}
                    onSelectionChange={async (data) => {
                        const cl: HandrailTypeAPI = HandrailTypeAPI.clone(a);
                        if(data){
                            cl.getData().setDefaultHandrailHandleId((data.item as HandrailHandleAPI).getId());
                        }else{
                            cl.getData().setDefaultHandrailHandleId(null);
                        }
                        setSelected(cl);
                    }}
                    selectedId={inun(a, (a) => a.getDefaultHandrailHandleId() ? a.getDefaultHandrailHandleId().toHexString() : null, () => null)}
                />,
                <span>
                <Select placeholder={t`Geldige modelspijlen`} key={"_supportedspindlesselect"}
                    searchable={false}
                    css={{ compose: PortalSelectStyle }}
                    onSelectionChange={() => {}}
                    maxSelected={0}
                    selectedIds={[]}
                    selectedItems={[]}>
                    {supportedSpindlesSelectItems}
                </Select>
                <PortalDropdown
                    immediatelyClearSelection={true}
                    placeholder={t`Modelspijl toevoegen`}
                    css={{ compose: PortalDropdownStyle }}
                    items={this.spindleThreadTypesToSelectItems(a.getData())}
                    onSelectionChange={(data) => {
                        if(data !== null){
                            const cl: HandrailTypeAPI = HandrailTypeAPI.clone(a);
                            this.addSupportedSpindleId(cl.getData(), data.item.getId());
                            setSelected(cl);
                        }
                    }}
                />
            </span>,
        );
    }

    private renderCreate(a: HandrailType, setSelected: (i: HandrailType) => void) {
        const supportedSpindlesSelectItems = this.supportedSpindleIdsToSelectItems(a.getSupportedSpindleIds(), (toDeleteId) => {
            const cl: HandrailType = a.clone();
            this.removeSupportedSpindleId(cl, toDeleteId);
            setSelected(cl);
        });
        return this.doRender(
            createTitlePortalCollapsibleLanguageField(a, setSelected.bind(this)),
            createDescriptionPortalCollapsibleLanguageField(a, setSelected.bind(this)),
            <MappedInput placeholder={t`Prijsfactor`}
                onBlur={(factor) => {
                    const cl: HandrailType = a.clone();
                    try {
                        cl.setPrice(toNumber(factor));
                    } catch (e) {
                        cl.setPrice(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getPrice(), () => "")} />,
            <MappedInput placeholder={t`L1-factor`}
                onBlur={(factor) => {
                    const cl: HandrailType = a.clone();
                    try {
                        cl.setL1Factor(toNumber(factor));
                    } catch (e) {
                        cl.setL1Factor(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getL1Factor(), () => "")} />,
            <PortalCheckbox placeholder={t`Toon modelspijlen`}
                onChange={(val) => {
                    if(val !== a.isShowSpindle()){
                        const cl: HandrailType = a.clone();
                        try {
                            cl.setShowSpindle(val);
                        } catch (e) {
                            cl.setShowSpindle(false);
                        }
                        setSelected(cl);
                    }
                }}
                value={inu(a, (a) => a.isShowSpindle(), () => "")} >Toon modelspijlen</PortalCheckbox>,
            <PortalCheckbox placeholder={t`Uitgesloten in prijsberekening`}
                onChange={(val) => {
                    if(val !== a.isExcludedFromPriceCalculations()){
                        const cl: HandrailType = a.clone();
                        try {
                            cl.setExcludedFromPriceCalculations(val);
                        } catch (e) {
                            cl.setExcludedFromPriceCalculations(false);
                        }
                        setSelected(cl);
                    }
                }}
                value={inu(a, (a) => a.isExcludedFromPriceCalculations(), () => "")} >Uitsluiten in prijsberekening</PortalCheckbox>,
            <PortalDropdown placeholder={t`Standaard handgreep`}
                css={{ compose: PortalDropdownStyle }}
                items={this.handrailHandlesToSelectItems()}
                onSelectionChange={async (data) => {
                    const cl: HandrailType = a.clone();
                    if(data){
                        cl.setDefaultHandrailHandleId((data.item as HandrailHandleAPI).getId());
                    }else{
                        cl.setDefaultHandrailHandleId(null);
                    }
                    setSelected(cl);
                }}
                selectedId={inun(a, (a) => a.getDefaultHandrailHandleId() ? a.getDefaultHandrailHandleId().toHexString() : null, () => null)}
            />,
            <span>
                <Select placeholder={t`Geldige modelspijlen`} key={"_supportedspindlesselect"}
                    searchable={false}
                    css={{ compose: PortalSelectStyle }}
                    onSelectionChange={() => {}}
                    maxSelected={0}
                    selectedIds={[]}
                    selectedItems={[]}>
                    {supportedSpindlesSelectItems}
                </Select>
                <PortalDropdown
                    immediatelyClearSelection={true}
                    placeholder={t`Modelspijl toevoegen`}
                    css={{ compose: PortalDropdownStyle }}
                    items={this.spindleThreadTypesToSelectItems(a)}
                    onSelectionChange={(data) => {
                        if(data !== null){
                            const cl: HandrailType = a.clone();
                            this.addSupportedSpindleId(cl, data.item.getId());
                            setSelected(cl);
                        }
                    }}
                />
            </span>,
        );
    }

    private doRender(
        titlePCLF: React.ReactNode,
        descriptionPCLF: React.ReactNode,
        priceMI: React.ReactNode,
        L1MI: React.ReactNode,
        showSpindlePCB: React.ReactNode,
        excludedFromCalculationPCB: React.ReactNode,
        handleSelection: React.ReactNode,
        supportedSpindlesPCB: React.ReactNode,
    ): React.ReactNode {
        return <div className={"container-fluid"}>
            <div className="row">
                <div className="col-md-6">
                    <ConfigBlock
                        title="Titel"
                        description="Geef een titel in voor dit leuningtype."
                        topMost={true}
                    >
                        {titlePCLF}
                    </ConfigBlock>

                    <ConfigBlock
                        title="L1-factor"
                        description="Geef de L1-factor in voor dit leuningtype. Deze factor handelt over het leuningtype per lopende meter overloop."
                        topMost={false}
                    >
                        {L1MI}
                    </ConfigBlock>

                    <ConfigBlock
                        title="Standaard handgreep"
                        description="Kies een standaard handgreep voor dit leuningtype."
                        topMost={false}
                    >
                        {handleSelection}
                    </ConfigBlock>

                    <ConfigBlock
                        title="Uitgesloten in prijsberekening"
                        description="Geef aan of dit leuningtype meegenomen moet worden in de prijsberekening. Over het algemeen moet deze optie enkel aangevinkt zijn voor leuningtype 'A - geen leuning'."
                        topMost={false}
                    >
                        {excludedFromCalculationPCB}
                    </ConfigBlock>
                </div>
                <div className="col-md-6">
                    <ConfigBlock
                        title="Omschrijving"
                        description="Geef een omschrijving in voor dit leuningtype."
                        topMost={true}
                    >
                        {descriptionPCLF}
                    </ConfigBlock>

                    <ConfigBlock
                        title="Prijsfactor" // TODO: Prijs?
                        description="Geef de prijsfactor in voor dit leuningtype."
                        topMost={false}
                    >
                        {priceMI}
                    </ConfigBlock>  
    
                    <ConfigBlock
                        title="Toon modelspijlen"
                        description="Geef aan of dit leuningtype modelspijlen ondersteunt."
                        topMost={false}
                    >
                        {showSpindlePCB}
                    </ConfigBlock>
    
                    <ConfigBlock
                        title="Geldige modelspijlen"
                        description="Geef aan welke modelspijlen dit leuningtype ondersteunt."
                        topMost={false}
                    >
                        {supportedSpindlesPCB}
                    </ConfigBlock>
                </div>
            </div>
        </div>;
    }

}
