import { ADDRESS_EXCEPTION } from "orbiter-core/src/exceptions";

export interface AddressObject {
    streetAndNumber: string,
    postalCode: string,
    city: string,
    country: string,
}

export function createAddressFromObject(
    obj: AddressObject,
): AddressObject {

    // TODO: check value?

    if (obj.streetAndNumber === undefined
        || obj.postalCode === undefined
        || obj.city === undefined
        || obj.country === undefined) {
        throw ADDRESS_EXCEPTION;
    }

    const streetAndNumber = obj.streetAndNumber.toString();
    const postalCode = obj.postalCode.toString();
    const city = obj.city.toString();
    const country = obj.country.toString();

    return {streetAndNumber, postalCode, city, country};

}

export function createEmptyAddress(): AddressObject {

    const streetAndNumber = "";
    const postalCode = "";
    const city = "";
    const country = "";

    return {streetAndNumber, postalCode, city, country};

}

