import { tVersionedObjectId, articleInputToTArticle, tQuote, tQuoteMinified, tObjectId, tAddress } from "../../../../shared/datastructures/simplifiedNetworkCommunicationTypes";
import { doJsonApiCall, doGetApiCall, doJsonDeleteApiCall } from "webc-reactcore/src/js/apicommunication";
import * as tPromise from 'io-ts-promise';
import IArticleInput from "../../../../shared/datastructures/IArticleInput";
import ObjectID from "bson-objectid";
import dispatcher from "webc-reactcore/src/js/dispatcher";
import { t } from "ttag";
import { loadQuote, startLoading, stopLoading } from "../pages/newquote/article/loaders/loaderHelper";
import store from "../store";
import DistributorAPI from "./DistributorAPI";
import { QUOTE_LOADING_BATCH } from "../config";
import { Filters } from "../actions/globalInformationActions";
import moment from 'moment';

export async function createQuote(quiet: boolean = false): Promise<tVersionedObjectId>{
    startLoading();
    return new Promise((resolve, reject) =>{
        doJsonApiCall('hotec/quote/create', {
            quote: {
                creationDate: null,
                lastUpdateDate: null,
                dataDate: null,
                distributorId: (store.getState().globalInformation.distributor as DistributorAPI)?.getId().toHexString(),
                customerName: "",
                customerEmail: "",
                customerPhone: "",
                customerAddress: {
                    streetAndNumber: "",
                    postalCode: "",
                    city: "",
                    country: "",
                },
                workSiteAddress: {
                    streetAndNumber: "",
                    postalCode: "",
                    city: "",
                    country: "",
                },
                articles: [],
                distance: 0,
                origin: null,
                destination: null,
                stage: "draft",
                identification: -1,
                title: "",
                description: "",
            }
        }, async (data: any) => {
            resolve(await tPromise.decode(tVersionedObjectId, data.id));
            stopLoading();
        }, quiet, (e: any) => {reject(e); stopLoading();});
    })
}

export async function addArticle(quoteId: tObjectId, article:IArticleInput, quiet: boolean = false): Promise<IArticleInput>{
    startLoading();
    const tArticle = await articleInputToTArticle(article);
    tArticle.quoteId = quoteId;
    return new Promise((resolve, reject) =>{
        doJsonApiCall('hotec/quote/article/add', {
            article: tArticle,
        }, (_data: any) => {
            article.id = _data.id._id;
            resolve(article);
            stopLoading();
        }, quiet, (e: any) => {reject(e); stopLoading();});
    })
}

export async function deleteArticle(articleId: tObjectId, quiet: boolean = false): Promise<tObjectId>{
    startLoading();
    return new Promise((resolve, reject) =>{
        doJsonDeleteApiCall('hotec/quote/article/delete/' + articleId, (_data: any) => {
            resolve(articleId);
            stopLoading();
        }, quiet, (e: any) => {reject(e); stopLoading();});
    })
}

export async function updateArticle(quoteId: tObjectId, article:IArticleInput, quiet: boolean = false): Promise<IArticleInput>{
    startLoading();
    const tArticle = await articleInputToTArticle(article);
    tArticle.quoteId = quoteId;
    return new Promise((resolve, reject) =>{
        doJsonApiCall('hotec/quote/article/update', {
            article: tArticle,
        }, (_data: any) => {
            article.id = _data.id._id;
            resolve(article);
            stopLoading();
        }, quiet, (e: any) => {reject(e); stopLoading();});
    })
}

export async function retrieveQuotes(quiet: boolean = false): Promise<tQuote[]>{
    startLoading();
    return new Promise((resolve, reject) => {
        doGetApiCall('hotec/quote/retrieve/all', async (data: any) => {
            const quotes: tQuote[] = await Promise.all(data.quotes.map(async (q: any) => await tPromise.decode(tQuote, q)));
            resolve(quotes);
            stopLoading();
        }, quiet, (e: any) => {reject(e); stopLoading();});
    })
}

export async function retrieveQuotesMinified(skip?: number, limit?: number, quiet: boolean = false, filters?: Filters): Promise<{quotes: tQuoteMinified[], moreAvailable: boolean}>{
    startLoading();
    return new Promise((resolve, reject) => {
        const actualLimit = limit??QUOTE_LOADING_BATCH;
        let suffix = `?skip=${skip??0}&limit=${actualLimit}`;
        
        if(filters?.query)
            suffix += `&query=${filters.query}`
        if(filters?.startDate)
            suffix += `&startDate=${moment(filters.startDate).toISOString()}`
        if(filters?.endDate)
            suffix += `&endDate=${moment(filters.endDate).toISOString()}`
        if(filters?.quoteState)
            suffix += `&quoteState=${filters.quoteState}`
        if(filters?.distributorId)
            suffix += `&distributorId=${filters.distributorId}`
        if(filters?.ownerName)
            suffix += `&ownerName=${filters.ownerName}`

        doGetApiCall('hotec/quote/retrieve/all/min' + suffix, async (data: any) => {
            const quotes: tQuoteMinified[] = await Promise.all(data.quotes.map(async (q: any) => await tPromise.decode(tQuoteMinified, q)));
            resolve({quotes, moreAvailable: quotes.length < actualLimit ? false : true});
            stopLoading();
        }, quiet, (e: any) => {reject(e); stopLoading();});
    })
}

export async function updateCustomerProperty(quoteId: tObjectId, property: 'customerName' | 'customerEmail' | 'customerPhone' | 'title', value: string, quiet: boolean = false): Promise<void>{
    startLoading();
    return new Promise((resolve, reject) =>{
        doJsonApiCall('hotec/quote/update/' + quoteId.toHexString() + '/customer/' + property, {
            value,
        }, (_data: any) => {
            resolve();
            stopLoading();
        }, quiet, (e: any) => {reject(e); stopLoading();});
    })
}

export async function cloneQuote(quoteId: tObjectId, quiet: boolean = false): Promise<void>{
    startLoading();
    return new Promise((resolve, reject) =>{
        doJsonApiCall('hotec/quote/clone/' + quoteId.toHexString(), {}, (_data: any) => {
            resolve();
            stopLoading();
        }, quiet, (e: any) => {reject(e); stopLoading();});
    })
}

export async function updateAddress(quoteId: tObjectId, property: 'home' | 'worksite', address: tAddress, quiet: boolean = false): Promise<void>{
    startLoading();
    return new Promise((resolve, reject) =>{
        doJsonApiCall('hotec/quote/update/' + quoteId.toHexString() + '/customer/address/' + property, {
            address,
        }, (_data: any) => {
            resolve();
            stopLoading();
        }, quiet, (e: any) => {reject(e); stopLoading();});
    })
}

export async function sendEmail(quoteId: tObjectId, body: string, showPriceDetails: boolean = false, quiet: boolean = false): Promise<void>{
    startLoading();
    await new Promise<void>((resolve, reject) =>{
        doJsonApiCall('hotec/quote/email/' + quoteId.toHexString() + '/' + (showPriceDetails ? 'pd' : ''), {body}, (_data: any) => {
            resolve();
            stopLoading();
        }, quiet, (e: any) => {reject(e); stopLoading();});
    })
    await loadQuote(quoteId);
}

export async function updateDistributor(quoteId: tObjectId, distributorId: ObjectID, quiet: boolean = false): Promise<void>{
    startLoading();
    return new Promise((resolve, reject) =>{
        doJsonApiCall('hotec/quote/update/' + quoteId.toHexString() + '/distributor', {
            distributorId,
        }, (_data: any) => {
            resolve();
            stopLoading();
        }, quiet, (e: any) => {reject(e); stopLoading();});
    })
}

export async function updateQuoteState(quoteId: tObjectId, state: string, quiet: boolean = false): Promise<void>{
    startLoading();
    return new Promise((resolve, reject) =>{
        doJsonApiCall('hotec/quote/update/' + quoteId.toHexString() + '/state', {
            state,
        }, (_data: any) => {
            resolve();
            stopLoading();
        }, quiet, (e: any) => {reject(e); stopLoading();});
    })
}

export async function updateOwnerId(quoteId: tObjectId, ownerId: ObjectID, quiet: boolean = false): Promise<void>{
    startLoading();
    return new Promise((resolve, reject) =>{
        doJsonApiCall('hotec/quote/update/' + quoteId.toHexString() + '/ownerId', {
            ownerId,
        }, (_data: any) => {
            resolve();
            stopLoading();
        }, quiet, (e: any) => {reject(e); stopLoading();});
    })
}

export async function recalculateDistance(quoteId: tObjectId, quiet: boolean = false): Promise<{distance: number, origin: string|null, destination: string|null}>{
    startLoading();
    return new Promise((resolve, reject) =>{
        doJsonApiCall('hotec/quote/update/' + quoteId.toHexString() + '/distance/recalculate', {
        }, (data: any) => {
            resolve(data);
            stopLoading();
            if(data.origin === null || data.destination === null){
                dispatcher.dispatch({
                    type: "NOTIFICATION",
                    title: t`Afstand tot werf`,
                    message: t`De afstand tot de werf kon niet automatisch bepaald worden, het adres is niet geldig.`
                })
            }else{
                dispatcher.dispatch({
                    type: "NOTIFICATION",
                    nType: "success",
                    title: t`Afstand tot werf`,
                    message: t`De afstand tot de werf werd automatisch bepaald.`
                })
            }
        }, quiet, (e: any) => {reject(e); stopLoading();});
    })
}

export async function updateDistance(quoteId: tObjectId, distance: number, quiet: boolean = false): Promise<{distance: number, origin: string|null, destination: string|null}>{
    startLoading();
    return new Promise((resolve, reject) =>{
        doJsonApiCall('hotec/quote/update/' + quoteId.toHexString() + '/distance/update', {
            distance,
        }, (data: any) => {
            resolve(data);
            stopLoading();
        }, quiet, (e: any) => {reject(e); stopLoading();});
    })
}

export async function updateExpirationDate(quoteId: tObjectId, expirationDate: Date, quiet: boolean = false): Promise<{expirationDate: Date}>{
    startLoading();
    const p1: {expirationDate: Date} = await new Promise((resolve, reject) =>{
        doJsonApiCall('hotec/quote/update/' + quoteId.toHexString() + '/expirationdate', {
            expirationDate,
        }, (data: any) => {
            resolve(data);
            stopLoading();
        }, quiet, (e: any) => {reject(e); stopLoading();});
    })
    await loadQuote(quoteId);
    return p1;

}