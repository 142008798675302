import IArticleInput, { IHandrailInput, IQuantifiedSupplement } from "../IArticleInput";
import HandrailType, { IHandrailType } from "../staircasedata/HandrailType";
import { IDatabaseController } from "orbiter-core/src/databasecontroller/DatabaseController";
import { inu, isDefined } from "orbiter-core/src/basic";
import { INPUT_NOT_DEFINED, PROHIBITED_SELECTION } from "./inputExceptions";
import { ISpindleThreadType } from "../staircasedata/SpindleThreadType";
import { IHandrailPoleType } from "../staircasedata/HandrailPoleType";
import { IHandrailPoleFinish } from "../staircasedata/HandrailPoleFinish";
import { IHandrailHandle } from "../staircasedata/HandrailHandle";
import { IStaircaseShape } from "../staircasedata/StaircaseShape";

const _ = undefined;

/**
 * Immutable handrail input class.
 */
export default class HandrailInput implements IHandrailInput{

    constructor(
        readonly type: IHandrailType & IDatabaseController = null,
        readonly handle: IHandrailHandle & IDatabaseController = null,
        readonly spindleThreadType: ISpindleThreadType & IDatabaseController = null,
        readonly spindleThreadDescription: string = null,
        readonly supplements: IQuantifiedSupplement[] = [],
        readonly handrailPoleTypes: (IHandrailPoleType & IDatabaseController)[] = [],
        readonly handrailPoleFinishes: (IHandrailPoleFinish & IDatabaseController)[] = [],
    ){}

    static from(handrailInput: IHandrailInput): HandrailInput{
        if(handrailInput === null){
            return null;
        }
        return new HandrailInput(
            handrailInput.type,
            handrailInput.handle,
            handrailInput.spindleThreadType,
            handrailInput.spindleThreadDescription,
            handrailInput.supplements,
            handrailInput.handrailPoleTypes,
            handrailInput.handrailPoleFinishes,
        ).clone();
    }

    static async validityCheck(handrail: IHandrailInput, article: IArticleInput, numberOfPoles?: number, isCheckForLandingStage?: boolean): Promise<void>{
        if(handrail === null)
            return;
        await this.typeValidityCheck(handrail.type, article.shape?.staircaseShape, isCheckForLandingStage);
        this.handleValidityCheck(handrail.handle);
        if(handrail.type !== null && await handrail.type.isShowSpindle()){
            this.spindleThreadTypeValidityCheck(handrail.spindleThreadType, handrail.type);
        }
        let requiredNumberOfPoles = numberOfPoles;
        if(requiredNumberOfPoles === undefined)
            requiredNumberOfPoles = await article.type.getNumberOfPoles();
        
        this.poleTypesValidityCheck(handrail.handrailPoleTypes, requiredNumberOfPoles, handrail.type);
        this.poleFinishValidityCheck(handrail.handrailPoleFinishes, requiredNumberOfPoles, handrail.type);
    }

    static async typeValidityCheck(type: IHandrailType & IDatabaseController, staircaseShape: IStaircaseShape & IDatabaseController, isCheckForLandingStage?: boolean): Promise<void>{
        if(!isDefined(type))
            throw INPUT_NOT_DEFINED;

        if(!isCheckForLandingStage && staircaseShape && (await staircaseShape.getProhibitedHandrailTypeIds()).find(x => x.toHexString() === type.getId().toHexString())){
            throw PROHIBITED_SELECTION;
        }
    }

    static handleValidityCheck(handle: IHandrailHandle & IDatabaseController): void{
        
    }

    static spindleThreadTypeValidityCheck(threadNumber: ISpindleThreadType & IDatabaseController, handrailType: (IHandrailType & IDatabaseController)): void{
        if(!isDefined(threadNumber) && !HandrailType.isHandrailIncluded(handrailType))
            return
        if(!isDefined(threadNumber))
            throw INPUT_NOT_DEFINED;
    }

    static poleTypesValidityCheck(handrailPoleTypes: (IHandrailPoleType & IDatabaseController)[], requiredNumberOfPoles: number, handrailType: (IHandrailType & IDatabaseController)): void{
        if(handrailPoleTypes.length == 0 && !HandrailType.isHandrailIncluded(handrailType))
            return
        if(handrailPoleTypes.length < requiredNumberOfPoles)
            throw INPUT_NOT_DEFINED;
        for(let i = 0; i<handrailPoleTypes.length; i++){
            if(!isDefined(handrailPoleTypes[i]))
                throw INPUT_NOT_DEFINED;
        }
    }

    static poleFinishValidityCheck(handrailPoleFinishes: (IHandrailPoleFinish & IDatabaseController)[], requiredNumberOfPoles: number, handrailType: (IHandrailType & IDatabaseController)): void{
        if(handrailPoleFinishes.length == 0 && !HandrailType.isHandrailIncluded(handrailType))
            return
        if(handrailPoleFinishes.length < requiredNumberOfPoles)
            throw INPUT_NOT_DEFINED;
        for(let i = 0; i<handrailPoleFinishes.length; i++){
            if(!isDefined(handrailPoleFinishes[i]))
                throw INPUT_NOT_DEFINED;
        }
    }

    /*
        TODO: validity checker for supplements
    */

    clone(
        type?: IHandrailType & IDatabaseController,
        handle?: IHandrailHandle & IDatabaseController,
        spindleThreadType?: ISpindleThreadType & IDatabaseController,
        spindleThreadDescription?: string,
        supplements?: any[], // TODO: change
        handrailPoleTypes?: (IHandrailPoleType & IDatabaseController)[],
        handrailPoleFinishes?: (IHandrailPoleFinish & IDatabaseController)[],
    ):HandrailInput{
        return new HandrailInput(
            inu(type, ()=>type, ()=>this.type),
            inu(handle, ()=>handle, ()=>this.handle),
            inu(spindleThreadType, ()=>spindleThreadType, ()=>this.spindleThreadType),
            inu(spindleThreadDescription, ()=>spindleThreadDescription, ()=>this.spindleThreadDescription),
            inu(supplements, ()=>[...supplements], ()=>[...this.supplements]), // TODO: deep clone? Probably yes (supplementInput)
            inu(handrailPoleTypes, ()=>[...handrailPoleTypes], ()=>[...this.handrailPoleTypes]),
            inu(handrailPoleFinishes, ()=>[...handrailPoleFinishes], ()=>[...this.handrailPoleFinishes]),
        )
    }

    setType(type: IHandrailType & IDatabaseController): HandrailInput{
        return this.clone(type);
    }
    
    setHandle(handle: IHandrailHandle & IDatabaseController): HandrailInput{
        return this.clone(_, handle);
    }

    setSpindleThreadType(spindleThreadType: ISpindleThreadType & IDatabaseController): HandrailInput{
        return this.clone(_, _, spindleThreadType);
    }

    setSpindleThreadDescription(spindleThreadDescription: string): HandrailInput{
        return this.clone(_, _, _, spindleThreadDescription);
    }

    setSupplements(supplements: any[]): HandrailInput{
        return this.clone(_, _, _, _, supplements);
    }

    setHandrailPoleTypes(handrailPoleTypes: (IHandrailPoleType & IDatabaseController)[]): HandrailInput{
        return this.clone(_, _, _, _, _, handrailPoleTypes);
    }

    setHandrailPoleFinishes(handrailPoleFinishes: (IHandrailPoleFinish & IDatabaseController)[]): HandrailInput{
        return this.clone(_, _, _, _, _, _, handrailPoleFinishes);
    }

}