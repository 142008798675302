exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1AMQ79FEvaogWvmsfT3xcP{\n    border-radius: 4px !important;\n}\n\n._1AMQ79FEvaogWvmsfT3xcP *{\n    cursor: pointer !important;\n}\n\n._1AMQ79FEvaogWvmsfT3xcP > * > *{\n    background-color: #3F3F3F !important;\n}\n\n._1AMQ79FEvaogWvmsfT3xcP > * > *:hover{\n    /* background-color: #FFCB04 !important; */\n}\n\n.q6spb27YPrieyLJ93dnE > *{\n    border-top-left-radius: 4px;\n    border-bottom-left-radius: 4px;\n}\n\n.zDAmuPLsjDEx3b2xqXs1f > *{\n    border-top-right-radius: 4px;\n    border-bottom-right-radius: 4px;\n}", ""]);

// exports
exports.locals = {
	"toolbar": "_1AMQ79FEvaogWvmsfT3xcP",
	"first": "q6spb27YPrieyLJ93dnE",
	"last": "zDAmuPLsjDEx3b2xqXs1f"
};