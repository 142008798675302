import * as React from "react";
import { connect } from "react-redux";
import {withRouter, RouteComponentProps} from "react-router-dom";
import { loadSettings } from "../../loader";

type props = { };

@(connect((store: any) => {
    return { globalInformation: { ...store.globalInformation }, articleCreation: { ...store.articleCreation } };
}) as any)
class SettingsRelatedPage extends React.Component<props & RouteComponentProps> {

    componentDidMount(){
        loadSettings();
    }

    public render() {
        return (
            <>
                {this.props.children}
            </>
        );
    }

}
export default withRouter(SettingsRelatedPage);