import * as React from "react";
import styleable from "react-styleable";
import * as styles from "../../../css/components/Overview/SearchBox.css";
import * as generalStyles from "../../../css/general.css";
import TextWithIcon from "webc-reactcore/src/js/components/mainlayout/TextWithIcon";
import StyledBox from "../StyledBox";
import {t} from 'ttag';
import { connect } from "react-redux";
import Input from "webc-reactcore/src/js/components/mainlayout/PortalInput";
import { filterMinifiedQuotes, Filters, retrieveMinifiedQuotes } from "../../actions/globalInformationActions";
import store from "../../store";
import PortalDropdown from "webc-reactcore/src/js/components/mainlayout/PortalDropdown";
import * as PortalDropdownStyle from "../../../css/PortalDropdown.css";
import { hasMatchingPermission, quoteStateToLocale } from "../../helpers";
import { QUOTE_STATES } from "../../../../../shared/datastructures/Quote";
import { toObjectSelectItems } from "../../pages/settings/helper";
import DistributorAPI from "../../apicontroller/DistributorAPI";
import { DATA_ROOT } from "../../../../../shared/permissions";

/**
 * Quote search box.
 */
@(connect((store: any) => {
    return {globalInformation: {...store.globalInformation}};
}) as any)
class SearchBox extends React.Component<any> {


    private async filter(filters: Filters){
        const oldFilters: Filters = this.props.globalInformation.filters;
        store.dispatch(await filterMinifiedQuotes(filters));
        store.dispatch(await retrieveMinifiedQuotes());
    }
    
    public render() {
        return (
            <StyledBox css={{compose: {container: (styles as any).styledBoxContainer}}}>
                <TextWithIcon icon="/cdn/portal/assets/icons/binoculars.svg" text={t`zoek.`} css={{compose: {container: generalStyles.textWithIconContainer}}}/>
                <Input placeholder={t`Zoekterm`} autoFocus onChange={(query: string) => {
                    this.filter({query: query});
                }} value={this.props.globalInformation.filters.query}/>
                <p><i style={{color: '#cdcdcd'}}>{t`Zoek op nummer of klantnaam.`}</i></p>
                <Input placeholder={t`Offerte-eigenaar`} autoFocus onChange={(ownerName: string) => {
                    this.filter({ownerName});
                }} value={this.props.globalInformation.filters.ownerName}/>
                <hr/>
                <PortalDropdown placeholder={t`Status`}
                    css={{ compose: PortalDropdownStyle }}
                    items={QUOTE_STATES.map(x => ({
                        title: quoteStateToLocale(x),
                        id: x,
                        item: x,
                    }))}
                    onSelectionChange={async (data) => {
                        this.filter({quoteState: data?.item})
                    }}
                    selectedId={this.props.globalInformation.filters.quoteState ?? null}
                />
                <hr/>
                {!hasMatchingPermission(DATA_ROOT) ? "" : <>
                        <PortalDropdown placeholder={t`Verdeler`}
                            css={{ compose: PortalDropdownStyle }}
                            items={toObjectSelectItems<DistributorAPI>(this.props.globalInformation.distributors.sort((x: DistributorAPI, y: DistributorAPI) => x.getName() < y.getName() ? -1 : 1), (x) => DistributorAPI.clone(x), (x) => x.getName())}
                            onSelectionChange={async (data) => {
                                this.filter({distributorId: data?.id})
                            }}
                            selectedId={this.props.globalInformation.filters.distributorId ?? null}
                        />
                        <hr/>
                    </>
                }
                <Input type="date" placeholder={t`Start`} onChange={(startDate: string) => {
                    this.filter({startDate});
                }} value={this.props.globalInformation.filters.startDate}/>
                <Input type="date" placeholder={t`Einde`} onChange={(endDate: string) => {
                    this.filter({endDate});
                }} value={this.props.globalInformation.filters.endDate}/>
            </StyledBox>
        );
    }
}
export default styleable(styles)(SearchBox);
