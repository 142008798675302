import {isValidNumber} from "orbiter-core/src/basic";
import DataController from "orbiter-core/src/datastructures/DataController";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import {VALUE_EXCEPTION} from "orbiter-core/src/exceptions";

export interface ITreatmentType extends DataController {
    getStaircaseFactor(): number;
    getHandrailFactor(): number;
    getLandingStageFactor(): number;
    getWallHandrailFactor(): number;
    getTitle(): MultiLanguageString;
    getDescription(): MultiLanguageString;
    setStaircaseFactor(newPrice: number): void | Promise<void>;
    setHandrailFactor(newPrice: number): void | Promise<void>;
    setLandingStageFactor(newPrice: number): void | Promise<void>;
    setWallHandrailFactor(newPrice: number): void | Promise<void>;
    setTitle(newTitle: MultiLanguageString): void | Promise<void>;
    setDescription(newDescription: MultiLanguageString): void | Promise<void>;
}

// TODO: almost entirely code duplication with StaircaseShape
export default class TreatmentType extends DataController implements ITreatmentType {

    @DataController.dataProperty()
    private staircaseFactor: number;
    @DataController.dataProperty()
    private handrailFactor: number;
    @DataController.dataProperty()
    private landingStageFactor: number;
    @DataController.dataProperty()
    private wallHandrailFactor: number;
    @DataController.dataProperty(async (x) => await x.asList())
    private title: MultiLanguageString;
    @DataController.dataProperty(async (x) => await x.asList())
    private description: MultiLanguageString;

    public constructor(staircaseFactor: number, handrailFactor: number, landingStageFactor: number, wallHandrailFactor: number, title: MultiLanguageString, description: MultiLanguageString){
        super();
        this.setStaircaseFactor(staircaseFactor);
        this.setHandrailFactor(handrailFactor);
        this.setLandingStageFactor(landingStageFactor);
        this.setWallHandrailFactor(wallHandrailFactor);
        this.setTitle(title);
        this.setDescription(description);
    }

    public getTitle(): MultiLanguageString {
        return this.title;
    }

    public getDescription(): MultiLanguageString {
        return this.description;
    }

    public setTitle(newTitle: MultiLanguageString): void {
        this.title = newTitle;
    }

    public setDescription(newDescription: MultiLanguageString): void {
        this.description = newDescription;
    }

    getStaircaseFactor(): number {
        return this.staircaseFactor;
    }

    getHandrailFactor(): number {
        return this.handrailFactor;
    }

    getLandingStageFactor(): number {
        return this.landingStageFactor;
    }

    getWallHandrailFactor(): number {
        return this.wallHandrailFactor;
    }

    setStaircaseFactor(newFactor: number): void {
        if(!isValidNumber(newFactor)) {
            throw VALUE_EXCEPTION;
        }
        this.staircaseFactor = newFactor;
    }

    setHandrailFactor(newFactor: number): void {
        if(!isValidNumber(newFactor)) {
            throw VALUE_EXCEPTION;
        }
        this.handrailFactor = newFactor;
    }
    
    setLandingStageFactor(newFactor: number): void {
        if(!isValidNumber(newFactor)) {
            throw VALUE_EXCEPTION;
        }
        this.landingStageFactor = newFactor;
    }
    
    setWallHandrailFactor(newFactor: number): void {
        if(!isValidNumber(newFactor)) {
            throw VALUE_EXCEPTION;
        }
        this.wallHandrailFactor = newFactor;
    }

    clone(): TreatmentType{
        return new TreatmentType(
            this.staircaseFactor,
            this.handrailFactor,
            this.landingStageFactor,
            this.wallHandrailFactor,
            this.title.clone(),
            this.description.clone(),
        )
    }

}
