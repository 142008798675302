import * as React from "react";
import SubTitle from "./SubTitle";

interface InfoScreenProps{
    title: string;
    description?: string;
    image: string;
}

/**
 * Info screen
 */
export default class InfoScreen extends React.Component<InfoScreenProps> {
    public render() {
        return (
            <div style={{textAlign: "center"}}>
                <img style={{width: "150px", maxWidth: "50%"}} src={this.props.image}/>
                <p style={{fontWeight: 'lighter', fontSize: '16px'}}>{this.props.title}</p>
                <p style={{fontWeight: 'lighter', fontSize: '12px'}}>{this.props.description}</p>
            </div>
        );
    }
}