exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".QLXTxSGijSzJvcubrF22Y{\n    padding: 5px 10px;\n    display: inline-block;\n}\n.QLXTxSGijSzJvcubrF22Y:hover{\n    background-color: #FFCB04 !important;\n    color: #3F3F3F;\n}", ""]);

// exports
exports.locals = {
	"toolbarItem": "QLXTxSGijSzJvcubrF22Y"
};