exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1Lp1sFJBh1rXF5wzy4AaMz{\n    display: inline;\n    background-color: #dc0000;\n    color: white;\n    border-radius: 8px;\n    padding-left: 10px;\n    padding-right: 10px;\n    font-weight: bold;\n}\n._3UMyf9YeoYnCD-6OetsPU5{\n    border-style: solid;\n    border-color: #ffbfbf;\n    border-radius: 8px;\n    border-width: 1px;\n    background-color: #ffecec;\n    padding: 10px;\n    margin-left: -5px;\n    margin-right: -5px;\n    margin-bottom: 5px;\n}\n._300m3p9YnkyOerpX7bw-5E{\n    text-align: right;\n    margin-bottom: 10px;\n}\n._1uOjNa6H44hfA9akR0a9b8{\n    display: inline;\n    color: #dc0000;\n    margin-right: 5px;\n}", ""]);

// exports
exports.locals = {
	"validityIndicatorLabel": "_1Lp1sFJBh1rXF5wzy4AaMz",
	"validityIndicatorContainer": "_3UMyf9YeoYnCD-6OetsPU5",
	"validityIndicatorInnerContainer": "_300m3p9YnkyOerpX7bw-5E",
	"validityIndicatorMessage": "_1uOjNa6H44hfA9akR0a9b8"
};