import * as React from "react";
import SubTitle from "./SubTitle";

interface ConfigBlockProps{
    title: string;
    description: string;
    children: React.ReactNode;

    topMost?: boolean;
}

/**
 * Config Block
 */
export default class ConfigBlock extends React.Component<ConfigBlockProps> {
    public render() {
        return (
            <div>
                <SubTitle marginTop={this.props.topMost === false ? "30px" : undefined}>{this.props.title}</SubTitle>
                <p>{this.props.description}</p>
                <div>
                    {this.props.children}
                </div>
            </div>
        );
    }
}