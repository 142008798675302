import ObjectID from "bson-objectid";
import ArticleCalculation, { Calculation } from "./ArticleCalculation";
import ArticleInput from "./articleinput/ArticleInput";
import VariantGroup from "./articleinput/VariantGroup";
import ArticleInputDiff from "./diff/ArticleInputDiff";
import IArticleInput from "./IArticleInput";
import IQuoteSpecificCharges from "./IQuoteSpecificCharges";
import Quote from "./Quote";
import { IBaseConstantSet } from "./staircasedata/BaseConstantSet";

export interface ArticleContainer{
    article: IArticleInput;
    calculation: Calculation;
    children: ArticleContainer[];
    diffWithParent: ArticleInputDiff;
    baseConstantSet: IBaseConstantSet;
}

export default class VisibleArticleTree{
    
    public quote: Quote;
    public quoteSpecificCharges: IQuoteSpecificCharges;
    public articleTree: ArticleContainer[] = [];
    
    constructor(quote: Quote){
        this.quote = quote;
    }

    public async init(){
        const variantGroups: VariantGroup[] = VariantGroup.fromArticles(this.quote.getArticles().map(x => x.getArticleInput()));
        const distributorId: ObjectID = this.quote.getDistributorId();

        for (let i = 0; i < variantGroups.length; i++) {
            const variantGroup = variantGroups[i];

            const mainIncluded = ArticleInput.isIncluded(variantGroup.main);
            const includedChildren = variantGroup.children.filter(x => ArticleInput.isIncluded(x));
            const childrenIncluded = includedChildren.length > 0;

            if(mainIncluded || childrenIncluded){
                // At least one article must be shown -> Always include main article if children are included
                const calculation = await (await ArticleCalculation.init(variantGroup.main, this.quote.getDistance(), distributorId, this.quote.getQuoteCalculationVersion())).calculate();
                if(!childrenIncluded && mainIncluded){
                    // No children included
                    this.articleTree.push({
                        article: variantGroup.main,
                        calculation,
                        children: [],
                        diffWithParent: null,
                        baseConstantSet: await variantGroup.main.articleInfoStore.getBaseConstantSet(),
                    })
                }else if(childrenIncluded && mainIncluded){
                    // Parent with children, calculate diff for children

                    const children: ArticleContainer[] = [];
                    for (let j = 0; j < includedChildren.length; j++) {
                        const child = includedChildren[j];

                        const diffWithParent = new ArticleInputDiff(ArticleInput.from(variantGroup.main), ArticleInput.from(child));
                        await diffWithParent.diff();

                        children.push({
                            article: child,
                            calculation: await (await ArticleCalculation.init(child, this.quote.getDistance(), distributorId, this.quote.getQuoteCalculationVersion())).calculate(),
                            children: [],
                            diffWithParent,
                            baseConstantSet: await child.articleInfoStore.getBaseConstantSet(),
                        })
                    }

                    this.articleTree.push({
                        article: variantGroup.main,
                        calculation,
                        children,
                        diffWithParent: null,
                        baseConstantSet: await variantGroup.main.articleInfoStore.getBaseConstantSet(),
                    })
                }else if(childrenIncluded && !mainIncluded){
                    // Treat children as main without children
                    for (const child of includedChildren) {
                        const calculation = await (await ArticleCalculation.init(child, this.quote.getDistance(), distributorId, this.quote.getQuoteCalculationVersion())).calculate();
                        this.articleTree.push({
                            article: child,
                            calculation,
                            children: [],
                            diffWithParent: null,
                            baseConstantSet: await child.articleInfoStore.getBaseConstantSet(),
                        })
                    }
                }
            }
        }

        this.quoteSpecificCharges = await this.quote.calculateQuoteSpecificCharges();
    }

    public flatten(): {i: number, article: ArticleContainer, variant: boolean, parent: ArticleContainer}[]{
        const res = [];
        for (let i = 0; i < this.articleTree.length; i++) {
            const article = this.articleTree[i];
            res.push({
                i: res.length,
                article,
                variant: false,
                parent: null,
            })
            for (let j = 0; j < article.children.length; j++) {
                const child = article.children[j];
                res.push({
                    i: res.length,
                    article: child,
                    variant: true,
                    parent: article,
                })
                
            }
        }
        return res;
    }
}