import Language from "orbiter-core/src/datastructures/languages/Language";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import SingleLanguageString from "orbiter-core/src/datastructures/languages/SingleLanguageString";
import Thickness, {IThickness} from "../../../../../shared/datastructures/staircasedata/Thickness";
import ApiController from "../ApiController";

export abstract class ThicknessAPI extends ApiController<Thickness> implements IThickness {

    public static getApiKey(): string{
        return "thickness";
    }

    public static getPluralApiKey(): string{
        return "thickness";
    }

    public async parseFromDictionary(dictionary): Promise<void> {

        const title = new MultiLanguageString(
            dictionary.title.map((x) => {
                // TODO: LanguageAPI?
                const l = new Language(
                    x.languageId,
                    null,
                    null,
                );
                return new SingleLanguageString(
                    l,
                    x.value,
                );
            }),
        );

        const desc = new MultiLanguageString(
            dictionary.description.map((x) => {
                // TODO: LanguageAPI?
                const l = new Language(
                    x.languageId,
                    null,
                    null,
                );
                return new SingleLanguageString(
                    l,
                    x.value,
                );
            }),
        );

        const t = new Thickness(
            dictionary.thickness,
            dictionary.price,
            title,
            desc,
        );
        this.setData(t);
    }

    public getDescription(): MultiLanguageString {
        return this.getData().getDescription();
    }

    public getPrice(): number {
        return this.getData().getPrice();
    }

    public getThickness(): number {
        return this.getData().getThickness();
    }

    public getTitle(): MultiLanguageString {
        return this.getData().getTitle();
    }

    public async setDescription(description: MultiLanguageString): Promise<void> {
        this.getData().setDescription(description);
        await this.updateProperty("description", description);
    }

    public async setPrice(price: number): Promise<void> {
        this.getData().setPrice(price);
        await this.updateProperty("price", price);
    }

    public async setThickness(thickness: number): Promise<void> {
        this.getData().setThickness(thickness);
        await this.updateProperty("thickness", thickness);
    }

    public async setTitle(title: MultiLanguageString): Promise<void> {
        this.getData().setTitle(title);
        await this.updateProperty("title", title);
    }

}

export class TreadThicknessAPI extends ThicknessAPI{

    public static getBaseEndpoint(): string{
        return "hotec/thickness/tread";
    }

}

export class StringerThicknessAPI extends ThicknessAPI{

    public static getBaseEndpoint(): string{
        return "hotec/thickness/stringer";
    }

}

export class RiserThicknessAPI extends ThicknessAPI{

    public static getBaseEndpoint(): string{
        return "hotec/thickness/riser";
    }

}
