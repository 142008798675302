import IUniformNode from "./IUniformNode";

export enum TitleType{
    H1,
    H2,
    H3,
    H4,
}

export default abstract class UniformNodeFactory<NodeType>{

    public Document(title: string, ...children: IUniformNode<NodeType>[]): IUniformNode<NodeType>{
        return this.xDocument({title, children});
    }
    public xDocument(props: IDocumentNodeProps<NodeType>): IUniformNode<NodeType>{
        return this._createDocumentNode({...props});
    }

    public Page(size: string, style: any, ...children: IUniformNode<NodeType>[]): IUniformNode<NodeType>{
        return this.xPage({size, children, style});
    }
    public xPage(props: IPageNodeProps<NodeType>): IUniformNode<NodeType>{
        return this._createPageNode({...props});
    }

    public Text(...children: IUniformNode<NodeType>[]): IUniformNode<NodeType>{
        return this.xText({children});
    }
    public Bullet(...children: IUniformNode<NodeType>[]): IUniformNode<NodeType>{
        return this.xBullet({children});
    }
    public GrayedOutText(...children: IUniformNode<NodeType>[]): IUniformNode<NodeType>{
        return this.xText({children, style: {color: "gray"}});
    }
    public PlainText(text: string | number): IUniformNode<NodeType>{
        return this.xText({
            children: [this.xString({text: text?.toString() ?? ""})]
        });
    }
    public BoldPlainText(text: string | number): IUniformNode<NodeType>{
        return this.Bold(this.String(text?.toString() ?? ""));
    }
    public xText(props: ITextNodeProps<NodeType>): IUniformNode<NodeType>{
        return this._createTextNode({...props});
    }
    public xBullet(props: IBulletNodeProps<NodeType>): IUniformNode<NodeType>{
        return this._createBulletNode({...props});
    }

    public Title(type: TitleType, ...children: IUniformNode<NodeType>[]): IUniformNode<NodeType>{
        return this.xTitle({
            h1: type === TitleType.H1,
            h2: type === TitleType.H2,
            h3: type === TitleType.H3,
            h4: type === TitleType.H4,
            children,
        })
    }
    public xTitle(props: ITitleNodeProps<NodeType>): IUniformNode<NodeType>{
        return this._createTitleNode({
            h1: true,
            h1FontSize: "20px",
            h2FontSize: "16px",
            h3FontSize: "14px",
            h4FontSize: "13px",
            paddingTop: "10px",
            ...props
        });
    }

    public Bold(...children: IUniformNode<NodeType>[]): IUniformNode<NodeType>{
        return this.xBold({children});
    }
    public xBold(props: IBoldNodeProps<NodeType>): IUniformNode<NodeType>{
        return this._createBoldNode({...props});
    }

    public Italic(...children: IUniformNode<NodeType>[]): IUniformNode<NodeType>{
        return this.xItalic({children});
    }
    public xItalic(props: IItalicNodeProps<NodeType>): IUniformNode<NodeType>{
        return this._createItalicNode({...props});
    }

    public Link(src: string, ...children: IUniformNode<NodeType>[]): IUniformNode<NodeType>{
        return this.xLink({children, src});
    }
    public xLink(props: ILinkNodeProps<NodeType>): IUniformNode<NodeType>{
        return this._createLinkNode({...props});
    }

    public String(text: string): IUniformNode<NodeType>{
        return this.xString({text});
    }
    public xString(props: IStringNodeProps): IUniformNode<NodeType>{
        return this._createStringNode({...props});
    }

    public None(): IUniformNode<NodeType>{
        return this._createNoneNode();
    }

    public View(...children: IUniformNode<NodeType>[]): IUniformNode<NodeType>{
        return this.xView({children});
    }
    public IndentView(...children: IUniformNode<NodeType>[]): IUniformNode<NodeType>{
        return this.xView({children, style: {paddingLeft: '15px'}, wrap: false});
    }
    public StayTogetherView(...children: IUniformNode<NodeType>[]): IUniformNode<NodeType>{
        return this.xView({children, wrap: false});
    }
    public FixedView(...children: IUniformNode<NodeType>[]): IUniformNode<NodeType>{
        return this.xView({children, fixed: true});
    }
    public FlexView(...children: IUniformNode<NodeType>[]): IUniformNode<NodeType>{
        return this.xView({children, style: {flexGrow: 1}});
    }
    public FlexBreakView(...children: IUniformNode<NodeType>[]): IUniformNode<NodeType>{
        return this.xView({children, style: {flexGrow: 1}, break: true});
    }
    public BreakView(...children: IUniformNode<NodeType>[]): IUniformNode<NodeType>{
        return this.xView({children, break: true});
    }
    public xView(props: IViewNodeProps<NodeType>): IUniformNode<NodeType>{
        return this._createViewNode({wrap: true, break: false, fixed: false, ...props});
    }

    public Line(): IUniformNode<NodeType>{
        return this.xView({children: [], style: {
            borderBottom: "1px solid black",
        }});
    }

    public SplitView(left: IUniformNode<NodeType>[], right: IUniformNode<NodeType>[]): IUniformNode<NodeType>{
        return this.xSplitView({left, right});
    }
    public xSplitView(props: ISplitViewNodeProps<NodeType>): IUniformNode<NodeType>{
        return this._createSplitViewNode({...props});
    }

    public Space(height?: string): IUniformNode<NodeType>{
        return this.xSpace(height ? {height} : {});
    }
    public xSpace(props: ISpaceNodeProps = {}): IUniformNode<NodeType>{
        return this._createSpaceNode({height: "20px", ...props});
    }

    public Image(src: string | (()=>Promise<string>), width?: string): IUniformNode<NodeType>{
        return this.xImage(width ? {src, width} : {src});
    }

    public xImage(props: IImageNodeProps): IUniformNode<NodeType>{
        return this._createImageNode({width: "100%", ...props});
    }

    
    public Footer(leftText: string, date: Date): IUniformNode<NodeType>{
        return this.xFooter({leftText, date});
    }
    public xFooter(props: IFooterNodeProps): IUniformNode<NodeType>{
        return this._createFooterNode({...props});
    }

    public abstract _createNoneNode(): IUniformNode<NodeType>;
    public abstract _createDocumentNode(props: IDocumentNodeProps<NodeType>): IUniformNode<NodeType>;
    public abstract _createPageNode(props: IPageNodeProps<NodeType>): IUniformNode<NodeType>;
    public abstract _createTitleNode(props: ITitleNodeProps<NodeType>): IUniformNode<NodeType>;
    public abstract _createTextNode(props: ITextNodeProps<NodeType>): IUniformNode<NodeType>;
    public abstract _createBulletNode(props: IBulletNodeProps<NodeType>): IUniformNode<NodeType>;
    public abstract _createBoldNode(props: IBoldNodeProps<NodeType>): IUniformNode<NodeType>;
    public abstract _createItalicNode(props: IItalicNodeProps<NodeType>): IUniformNode<NodeType>;
    public abstract _createLinkNode(props: ILinkNodeProps<NodeType>): IUniformNode<NodeType>;
    public abstract _createStringNode(props: IStringNodeProps): IUniformNode<NodeType>;
    public abstract _createViewNode(props: IViewNodeProps<NodeType>): IUniformNode<NodeType>;
    public abstract _createSplitViewNode(props: ISplitViewNodeProps<NodeType>): IUniformNode<NodeType>;
    public abstract _createSpaceNode(props: ISpaceNodeProps): IUniformNode<NodeType>;
    public abstract _createImageNode(props: IImageNodeProps): IUniformNode<NodeType>;
    public abstract _createFooterNode(props: IFooterNodeProps): IUniformNode<NodeType>;

}

export interface INodeProps {
    key?: any;
}

export interface IDocumentNodeProps<NodeType> extends INodeProps{
    title: string;
    children: IUniformNode<NodeType>[];
}

export interface IPageNodeProps<NodeType> extends INodeProps{
    size: string;
    style?: {page: any};
    children: IUniformNode<NodeType>[];
}

export interface ITitleNodeProps<NodeType> extends INodeProps{
    children: IUniformNode<NodeType>[];
    h1?: boolean;
    h2?: boolean;
    h3?: boolean;
    h4?: boolean;

    paddingTop?: string;
    h1FontSize?: string;
    h2FontSize?: string;
    h3FontSize?: string;
    h4FontSize?: string;
}

export interface ITextNodeProps<NodeType> extends INodeProps{
    children: IUniformNode<NodeType>[];
    style?: any;
}

export interface IBulletNodeProps<NodeType> extends ITextNodeProps<NodeType> {
    
}

export interface IBoldNodeProps<NodeType> extends ITextNodeProps<NodeType> {
    
}

export interface IItalicNodeProps<NodeType> extends ITextNodeProps<NodeType> {
    
}

export interface ILinkNodeProps<NodeType> extends ITextNodeProps<NodeType> {
    src: string;
}

export interface IStringNodeProps extends INodeProps{
    text: string;
}

export interface IViewNodeProps<NodeType> extends INodeProps{
    children: IUniformNode<NodeType>[];
    wrap?: boolean;
    break?: boolean;
    fixed?: boolean;
    style?: any;
}

export interface ISplitViewNodeProps<NodeType> extends INodeProps{
    left: IUniformNode<NodeType>[];
    right: IUniformNode<NodeType>[];
}

export interface ISpaceNodeProps extends INodeProps{
    height?: string;
}

export interface IImageNodeProps extends INodeProps{
    src: string | (() => Promise<string>);
    width?: string;
}

export interface IFooterNodeProps extends INodeProps{
    leftText: string;
    date: Date;
}