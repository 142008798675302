import ApiController, { ConstructionMethods } from "./ApiController";
import HotecUserBrowserStandin, { HotecUserProperties } from '../../../../shared/datastructures/HotecUserBrowserStandin';
import { IUser } from "orbiter-core/src/datastructures/users/User";
import ObjectID from "bson-objectid";
import { doJsonApiCall, doJsonGetApiCall } from "webc-reactcore/src/js/apicommunication";
import DistributorAPI from "./DistributorAPI";

export default class HotecUserAPI extends ApiController<HotecUserBrowserStandin> implements IUser {
    
    public static getBaseEndpoint(): string{
        return "hotec/user";
    }

    public static getApiKey(): string{
        return "user";
    }

    public async parseFromDictionary(dictionary): Promise<void> {
        const user = new HotecUserBrowserStandin(
            dictionary.email,
            null,
            {
                ...dictionary.properties,
                distributorId: dictionary.properties.distributorId ? new ObjectID(dictionary.properties.distributorId) : null,
            },
            dictionary.permissions.map(x => new ObjectID(x)),
        );
        this.setData(user);
    }

    public static async retrieveTeam(): Promise<any[]> {
        return await new Promise<any[]>((resolve, reject) => {
            doJsonGetApiCall(this.getBaseEndpoint() + "/retrieve/team", async (result) => {
                const listOfResults = result[this.getPluralApiKey()];
                const output = [];
                for (const res of listOfResults) {
                    const apiController = new this(res._id, ConstructionMethods.PromiseToRetrieve);
                    await apiController.parseFromDictionary(res);
                    output.push(apiController);
                }
                resolve(output);
            }, false, () => reject());
        });
    }

    public static async createInTeam(data: HotecUserBrowserStandin): Promise<any> {
        // TODO: can this be done better than <any>? With some sort of generics?
        const dataDict = await data.asDict();
        return new Promise((resolve, reject) => {
            doJsonApiCall(this.getCreateEndpoint() + "inteam", dataDict, (result) => {
                const apiController = new this(result[this.getApiKey()]._id, ConstructionMethods.Data, result[this.getApiKey()]);
                resolve(apiController);
            }, false, () => reject());
        });
    }

    public getEmail(): string {
        return this.getData().getEmail();
    }

    public getPassword(): string {
        return this.getData().getPassword();
    }

    public getProperties(): HotecUserProperties {
        return this.getData().getProperties();
    }

    public getPermissions(): ObjectID[] {
        return this.getData().getPermissions();
    }

    public setPermissions(permissions: ObjectID[]): void{
        this.getData().setPermissions(permissions);
    }

    public setProperties(properties: HotecUserProperties): void{
        this.getData().setProperties(properties);
    }

    public async setEmail(email: string): Promise<void> {
        this.getData().setEmail(email);
        // TODO: apply on database?
        // await this.updateProperty("email", email);
    }

    public async setName(name: string): Promise<void> {
        this.getData().setName(name);
        // TODO: apply on database?
        // await this.updateProperty("name", name);
    }

    public async setPassword(password: string): Promise<void> {
        this.getData().setPassword(password);
        // TODO: apply on database?
        // await this.updateProperty("password", password);
    }

    async setPlainTextPassword(plainTextPassword: string): Promise<void> {
        await this.setPassword(plainTextPassword);
    }

    async isPassword(plainTextPassword: string): Promise<boolean> {
        return this.getPassword() === plainTextPassword;
    }

    public getName(): string {
        return this.getProperties().name;
    }

    public getDistributorId(): ObjectID | null {
        return this.getProperties().distributorId;
    }
    
    public setDistributorId(distributorId: ObjectID | null): void {
        this.getData().setDistributorId(distributorId);
    }
    
    public clone(): HotecUserAPI{
        return HotecUserAPI.clone(this);
    }

    public static async retrieveCurrent(): Promise<[HotecUserAPI, DistributorAPI, string[]]>{
        return new Promise((resolve, reject) => {
            try{
                doJsonGetApiCall("hotec/user/retrieve/current", async (result) => {
                    try{
                        if(result._status !== 1){
                            reject("Failure");
                        }else{
                            const user = new HotecUserAPI(result.user._id, ConstructionMethods.PromiseToRetrieve);
                            await user.parseFromDictionary(result.user);

                            let distributor = null;
                            if(result.distributor){
                                distributor = new DistributorAPI(result.distributor._id, ConstructionMethods.PromiseToRetrieve);
                                await distributor.parseFromDictionary(result.distributor);
                            }

                            const permissions = result.permissions;

                            resolve([user, distributor, permissions]);
                        }
                    }catch(e){
                        reject(e);
                    }
                });
            }catch(e){
                reject(e);
            }
        });
    }

}
