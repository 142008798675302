import { IDatabaseController } from "orbiter-core/src/databasecontroller/DatabaseController";
import { differingNumbers, differingStrings, IArticleDiffLine, newDiffFalseLine, newDiffTrueLine } from "./ArticleInputDiff";
import ArticleInput from "../articleinput/ArticleInput";
import WallHandrailInput from "../articleinput/WallHandrailInput";
import { IWallHook } from "../staircasedata/WallHook";
import { IWallHandrail } from "../staircasedata/WallHandrail";

export default class WallHandrailInputDiff{

    public main: IArticleDiffLine<WallHandrailInput> = newDiffFalseLine();

    public type: IArticleDiffLine<IWallHandrail & IDatabaseController> = newDiffFalseLine();
    public hook: IArticleDiffLine<IWallHook & IDatabaseController> = newDiffFalseLine();
    public length: IArticleDiffLine<number> = newDiffFalseLine();
    public hookCount: IArticleDiffLine<number> = newDiffFalseLine();
    public bendCount: IArticleDiffLine<number> = newDiffFalseLine();
    public ignoreWoodType: IArticleDiffLine<boolean> = newDiffFalseLine();
    
    readonly left: WallHandrailInput;
    readonly right: WallHandrailInput;

    constructor(readonly a1: ArticleInput, readonly a2: ArticleInput){
        this.left = a1.wallHandrail;
        this.right = a2.wallHandrail;
    }

    public async diff(){
        if(this.left && this.right){
            // Both defined
            await this.diffType();
            await this.diffHook();
            await this.diffLength();
            await this.diffHookCount();
            await this.diffBendCount();
            await this.diffIgnoreWoodType();
        }else if(this.left && !this.right){
            // Was defined, not anymore
            this.main = newDiffTrueLine(this.left, this.right);
            this.type = newDiffTrueLine(this.left.type, null);
            this.hook = newDiffTrueLine(this.left.hook, null);
            this.length = newDiffTrueLine(this.left.length, null);
            this.hookCount = newDiffTrueLine(this.left.hookCount, null);
            this.bendCount = newDiffTrueLine(this.left.bendCount, null);
            this.ignoreWoodType = newDiffTrueLine(this.left.ignoreWoodType, null);
        }else if(!this.left && this.right){
            // Was not defined, is now
            this.main = newDiffTrueLine(this.left, this.right);
            this.type = newDiffTrueLine(null, this.right.type);
            this.hook = newDiffTrueLine(null, this.right.hook);
            this.length = newDiffTrueLine(null, this.right.length);
            this.hookCount = newDiffTrueLine(null, this.right.hookCount);
            this.bendCount = newDiffTrueLine(null, this.right.bendCount);
            this.ignoreWoodType = newDiffTrueLine(null, this.right.ignoreWoodType);
        }
    }

    public differs(): boolean{
        return this.type.different
            || this.hook.different
            || this.length.different
            || this.hookCount.different
            || this.bendCount.different
            || this.ignoreWoodType.different
            || this.main.different;
    }

    private async diffType(){
        if(differingStrings(this.left.type?.getSid(), this.right.type?.getSid())){
            this.type = newDiffTrueLine(
                this.left.type,
                this.right.type,
            )
        }
    }

    private async diffHook(){
        if(differingStrings(this.left.hook?.getSid(), this.right.hook?.getSid())){
            this.hook = newDiffTrueLine(
                this.left.hook,
                this.right.hook,
            )
        }
    }

    private async diffLength(){
        if(differingNumbers(this.left.length, this.right.length)){
            this.length = newDiffTrueLine(
                this.left.length,
                this.right.length,
            )
        }
    }

    private async diffHookCount(){
        if(differingNumbers(this.left.hookCount, this.right.hookCount)){
            this.hookCount = newDiffTrueLine(
                this.left.hookCount,
                this.right.hookCount,
            )
        }
    }

    private async diffBendCount(){
        if(differingNumbers(this.left.bendCount, this.right.bendCount)){
            this.bendCount = newDiffTrueLine(
                this.left.bendCount,
                this.right.bendCount,
            )
        }
    }

    private async diffIgnoreWoodType(){
        if(this.left.ignoreWoodType !== this.right.ignoreWoodType){
            this.ignoreWoodType = newDiffTrueLine(
                this.left.ignoreWoodType,
                this.right.ignoreWoodType,
            )
        }
    }

}