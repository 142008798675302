exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2JwP-AhKkeDnMgaxWo3Smg{\n    padding: 0 20px 0 20px;\n    overflow: hidden;\n    white-space: nowrap;\n    height: auto;\n}", ""]);

// exports
exports.locals = {
	"group": "_2JwP-AhKkeDnMgaxWo3Smg"
};