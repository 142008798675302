import * as React from "react";
import { MouseEventHandler } from "react";
import * as styles from "../../css/components/ToggleButton.css";
import styleable from "react-styleable";

interface ToggleButtonProps{
    children: React.ReactNode;
    selectedIcon: React.ReactNode;
    unselectedIcon: React.ReactNode;
    selected: boolean;
    onClick: MouseEventHandler<HTMLButtonElement>;
    css: any;
}

class ToggleButton extends React.Component<ToggleButtonProps> {
    public render() {
        return (
            <button className={this.props.css.button + " " + (this.props.selected ? this.props.css.selected : this.props.css.unselected)} onClick={this.props.onClick}>{this.props.selected ? (this.props.selectedIcon ?? "") : (this.props.unselectedIcon ?? "")}{this.props.children}</button>
        );
    }
}
export default styleable(styles)(ToggleButton);