import * as React from "react";
import { inun } from "orbiter-core/src/basic";
import { connect } from "react-redux";
import { loadCurrentUser } from "../../loader";
import { t } from 'ttag';
import ConfigBlock from "../../components/ConfigBlock";
import MappedInput from "webc-reactcore/src/js/components/mainlayout/MappedInput";
import InfoScreen from "../../components/InfoScreen";
import DistributorAPI from "../../apicontroller/DistributorAPI";
import SettingsTemplate from "./SettingsTemplate";
import { ToolbarMgr } from "webc-reactcore/src/js/components/toolbar/ToolbarContextProvider";
import SimpleToolbarItem from "webc-reactcore/src/js/components/toolbar/SimpleToolbarItem";
import { startLoading, stopLoading } from "../newquote/article/loaders/loaderHelper";
import { renderBankAccountsInput } from "./DistributorHelper";
import PortalInputLarge from "webc-reactcore/src/js/components/mainlayout/PortalInputLarge";
import * as extendedPortalInputLargeStyle from "../../../css/extendedPortalInputLarge.css";
import axios from "axios";
import LoadingIcon from "../newquote/article/loaders/LoadingIcon";

@(connect((store: any) => {
    return { settings: { ...store.settings }, globalInformation: { ...store.globalInformation } };
}) as any)
export default class Profile extends React.Component<any, { distributor: DistributorAPI, updated: boolean, uploadingLogo: boolean, accountInput: string }> {

    constructor(_) {
        super(_);
        this.state = {
            distributor: null,
            updated: false,
            accountInput: "",
            uploadingLogo: false,
        };
    }

    componentDidMount() {
        this.setState({ distributor: this.props.globalInformation.distributor });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.globalInformation.distributor !== state.distributor && !state.updated) {
            return {
                distributor: props.globalInformation.distributor,
            }
        }
        return null;
    }

    private renderBankAccountsInput(accounts: string[], onAdd: (account: string) => void, onDelete: (account: string) => void) {
        return renderBankAccountsInput((i) => this.setState({ accountInput: i }), () => this.state.accountInput, accounts, onAdd, onDelete);
    }

    public render() {
        return <>
            <SettingsTemplate title={t`verdeler` + "."}>
                {this.state.distributor ? this.renderContent() : <InfoScreen title={this.props.globalInformation.loading ? t`De nodige informatie wordt ingeladen.` : t`Er is geen verdeler toegekend aan uw profiel.`} image={"/cdn/images/stack.svg"} />}
            </SettingsTemplate>
        </>;

    }

    private toolbarItems = [];

    private async removeToolbarItems(ctx) {
        await ctx.removeToolbarItems([...this.toolbarItems]);
        this.toolbarItems = [];
    }

    private async update() {
        startLoading();
        const selected: DistributorAPI = this.state.distributor;
        await selected.pushUpdate();
        await loadCurrentUser();
        this.setState({ updated: false });
        stopLoading();
    }

    private store(distributor: DistributorAPI) {
        this.setState({ distributor, updated: true });
    }

    private renderContent() {
        return <>
            <ToolbarMgr key="edit" onMount={async (ctx) => {
                await this.removeToolbarItems(ctx);
                this.toolbarItems.push(await ctx.addToolbarItem(<SimpleToolbarItem onClick={this.update.bind(this)}>{t`Opslaan`}</SimpleToolbarItem>));
            }} />
            {this.subrenderContent()}
        </>
    }

    private subrenderContent() {
        const a: DistributorAPI = this.state.distributor;
        return <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4">
                        <ConfigBlock
                            title={t`Logo`}
                            description={t`Opgelet, het wijzigen van uw logo wordt onmiddelijk toegepast.`}
                            topMost={true}
                        >
                            {this.state.uploadingLogo ? <LoadingIcon uncoupledRender={true} center={true} /> : <img width="50%" style={{ maxWidth: "100px" }} src={a.getLogoUrl(a?.getLogoId()?.toString())} />}
                            <br /><br />
                            <input type="file" id="logo-upload" name="file" onChange={async (e) => {
                                this.setState({ uploadingLogo: true });
                                const file: File = e.currentTarget.files[0];
                                const cl: DistributorAPI = DistributorAPI.clone(a);
                                await cl.uploadLogo(file);
                                this.store(cl);
                                this.setState({ uploadingLogo: false });
                            }} />
                        </ConfigBlock>
                    </div>
                    <div className="col-md-4">
                    </div>
                    <div className="col-md-4">
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <ConfigBlock
                            title={"Bedrijfsinformatie"}
                            description=""
                            topMost={false}
                        >
                            <MappedInput placeholder={t`Bedrijfsnaam`} onBlur={(name) => {
                                const cl: DistributorAPI = DistributorAPI.clone(a);
                                try {
                                    cl.getData().setName(name);
                                } catch (e) {
                                    cl.getData().setName("");
                                }
                                this.store(cl);
                            }} value={inun(a, (a) => a.getName(), () => "")} />

                            <MappedInput placeholder={t`Naam contactpersoon`} onBlur={(name) => {
                                const cl: DistributorAPI = DistributorAPI.clone(a);
                                try {
                                    cl.getData().setAdministrationName(name);
                                } catch (e) {
                                    cl.getData().setAdministrationName("");
                                }
                                this.store(cl);
                            }} value={inun(a, (a) => a.getAdministrationName(), () => "")} />

                            <MappedInput placeholder={t`E-mailadres`} onBlur={(email) => {
                                const cl: DistributorAPI = DistributorAPI.clone(a);
                                try {
                                    cl.getData().setAdministrationEmail(email);
                                } catch (e) {
                                    cl.getData().setAdministrationEmail("");
                                }
                                this.store(cl);
                            }} value={inun(a, (a) => a.getAdministrationEmail(), () => "")} />

                            <MappedInput placeholder={t`Telefoonnummer`} onBlur={(telephone) => {
                                const cl: DistributorAPI = DistributorAPI.clone(a);
                                try {
                                    cl.getData().setTelephone(telephone);
                                } catch (e) {
                                    cl.getData().setTelephone("");
                                }
                                this.store(cl);
                            }} value={inun(a, (a) => a.getTelephone(), () => "")} />

                            <MappedInput placeholder={t`Voorvoegsel offertenummer`} onBlur={(quoteNumberPrefix) => {
                                const cl: DistributorAPI = DistributorAPI.clone(a);
                                try {
                                    cl.getData().setQuoteNumberPrefix(quoteNumberPrefix);
                                } catch (e) {
                                    cl.getData().setQuoteNumberPrefix("");
                                }
                                this.store(cl);
                            }} value={inun(a, (a) => a.getQuoteNumberPrefix(), () => "")} />
                            <p><i>Opgelet, het wijzigen van het voorvoegsel zorgt ervoor dat de nummering van nieuwe offertes opnieuw vanaf 1 start, voorafgegaan door het nieuwe voorvoegsel. Je kan hier gebruik maken van de variabele <code>JAAR</code> om het huidige kalenderjaar aan te duiden.</i></p>
                        </ConfigBlock>
                    </div>
                    <div className="col-md-4">
                        <ConfigBlock
                            title={"Adres"}
                            description=""
                            topMost={false}
                        >

                            <MappedInput placeholder={t`Straat`} onBlur={(streetAndNumber) => {
                                const cl: DistributorAPI = DistributorAPI.clone(a);
                                try {
                                    cl.getData().setAddress({ ...cl.getData().getAddress(), streetAndNumber });
                                } catch (e) {
                                    cl.getData().setAddress({ ...cl.getData().getAddress() });
                                }
                                this.store(cl);
                            }} value={inun(a, (a) => a.getAddress().streetAndNumber, () => "")} />

                            <MappedInput placeholder={t`Postcode`} onBlur={(postalCode) => {
                                const cl: DistributorAPI = DistributorAPI.clone(a);
                                try {
                                    cl.getData().setAddress({ ...cl.getData().getAddress(), postalCode });
                                } catch (e) {
                                    cl.getData().setAddress({ ...cl.getData().getAddress() });
                                }
                                this.store(cl);
                            }} value={inun(a, (a) => a.getAddress().postalCode, () => "")} />

                            <MappedInput placeholder={t`Stad`} onBlur={(city) => {
                                const cl: DistributorAPI = DistributorAPI.clone(a);
                                try {
                                    cl.getData().setAddress({ ...cl.getData().getAddress(), city });
                                } catch (e) {
                                    cl.getData().setAddress({ ...cl.getData().getAddress() });
                                }
                                this.store(cl);
                            }} value={inun(a, (a) => a.getAddress().city, () => "")} />

                            <MappedInput placeholder={t`Land`} onBlur={(country) => {
                                const cl: DistributorAPI = DistributorAPI.clone(a);
                                try {
                                    cl.getData().setAddress({ ...cl.getData().getAddress(), country });
                                } catch (e) {
                                    cl.getData().setAddress({ ...cl.getData().getAddress() });
                                }
                                this.store(cl);
                            }} value={inun(a, (a) => a.getAddress().country, () => "")} />

                        </ConfigBlock>
                    </div>
                    <div className="col-md-4">
                        {a?.shouldApplyDealerMargin() || a?.shouldApplyHotecMargin() ?
                            <ConfigBlock
                                title={"Marges en kortingen"}
                                description="Bepaal de marges en kortingen, procentueel uitgedrukt, die gebruikt worden bij offerte-berekeningen."
                                topMost={false}
                            >

                                {a?.shouldApplyDealerMargin() ? <>
                                    <MappedInput placeholder={t`Marge verdeler trap` + " (%)"} onBlur={(margin) => {
                                        const cl: DistributorAPI = DistributorAPI.clone(a);
                                        try {
                                            cl.getData().setDealerMarginStaircase(parseFloat(margin));
                                        } catch (e) {
                                            cl.getData().setDealerMarginStaircase(cl.getData().getDealerMarginStaircase());
                                        }
                                        this.store(cl);
                                    }} value={inun(a, (a) => a.getDealerMarginStaircase(), () => "")} />

                                    <MappedInput placeholder={t`Marge verdeler behandeling` + " (%)"} onBlur={(margin) => {
                                        const cl: DistributorAPI = DistributorAPI.clone(a);
                                        try {
                                            cl.getData().setDealerMarginTreatment(parseFloat(margin));
                                        } catch (e) {
                                            cl.getData().setDealerMarginTreatment(cl.getData().getDealerMarginTreatment());
                                        }
                                        this.store(cl);
                                    }} value={inun(a, (a) => a.getDealerMarginTreatment(), () => "")} />

                                    <MappedInput placeholder={t`Marge verdeler plaatsing` + " (%)"} onBlur={(margin) => {
                                        const cl: DistributorAPI = DistributorAPI.clone(a);
                                        try {
                                            cl.getData().setDealerMarginDistribution(parseFloat(margin));
                                        } catch (e) {
                                            cl.getData().setDealerMarginDistribution(cl.getData().getDealerMarginDistribution());
                                        }
                                        this.store(cl);
                                    }} value={inun(a, (a) => a.getDealerMarginDistribution(), () => "")} />
                                </> : ""}

                                {a?.getHotecDiscountStaircase() > 0 && a?.shouldApplyHotecMargin() ? <p><b>{t`Korting Hotec trap`}:</b> {inun(a, (a) => a.getHotecDiscountStaircase(), () => "")}%</p> : ""}
                                {a?.getHotecDiscountTreatment() > 0 && a?.shouldApplyHotecMargin() ? <p><b>{t`Korting Hotec behandeling`}:</b> {inun(a, (a) => a.getHotecDiscountTreatment(), () => "")}%</p> : ""}
                                {a?.getHotecDiscountDistribution() > 0 && a?.shouldApplyHotecMargin() ? <p><b>{t`Korting Hotec plaatsing`}:</b> {inun(a, (a) => a.getHotecDiscountDistribution(), () => "")}%</p> : ""}


                            </ConfigBlock>
                            : ""}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <ConfigBlock
                            title={"Betalingsinformatie"}
                            description=""
                            topMost={false}
                        >

                            {
                                this.renderBankAccountsInput(a.getBankAccounts(), (account) => {
                                    // ON ADD
                                    const cl: DistributorAPI = DistributorAPI.clone(a);
                                    cl.getData().setBankAccounts([...a.getBankAccounts(), account]);
                                    this.store(cl);
                                }, (account) => {
                                    // ON DELETE
                                    const cl: DistributorAPI = DistributorAPI.clone(a);
                                    cl.getData().setBankAccounts([...a.getBankAccounts().filter(x => x !== account)]);
                                    this.store(cl);
                                })
                            }
                        </ConfigBlock>

                    </div>
                    <div className="col-md-4">
                        <ConfigBlock
                            title={"BTW"}
                            description=""
                            topMost={false}
                        >
                            <MappedInput placeholder={t`BTW-nummer`} onBlur={(vatNumber) => {
                                const cl: DistributorAPI = DistributorAPI.clone(a);
                                try {
                                    cl.getData().setVatNumber(vatNumber);
                                } catch (e) {
                                    cl.getData().setVatNumber("");
                                }
                                this.store(cl);
                            }} value={inun(a, (a) => a.getVatNumber(), () => "")} />
                        </ConfigBlock>

                    </div>
                    <div className="col-md-4">
                        <ConfigBlock
                            title={"Gebruikers"}
                            description="Gebruikers toegekend aan uw bedrijf."
                            topMost={false}
                        >
                            <ul>
                                {a.getUsers().length > 0 ? a.getUsers().map(u => {
                                    return <li key={u.getEmail() as string}>{u.getName() as string} - {u.getEmail() as string}</li>
                                }) : ""}
                            </ul>
                        </ConfigBlock>

                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-md-4">
                        <ConfigBlock
                            title={t`Introductie offerte`}
                            description="Introductie die weergegeven wordt op de offerte."
                            topMost={false}
                        >
                            <PortalInputLarge placeholder={t`Introductie offerte`}
                                css={{ compose: extendedPortalInputLargeStyle }}
                                onChange={(v) => {
                                    const cl: DistributorAPI = DistributorAPI.clone(a);
                                    cl.setPreface(v);
                                    this.store(cl);
                                }} value={inun(a, (a) => a.getPreface(), () => "")} />
                        </ConfigBlock>
                    </div>
                    <div className="col-md-4">
                        <ConfigBlock
                            title={t`Afsluiting offerte`}
                            description="Afsluiting die weergegeven wordt op de offerte."
                            topMost={false}
                        >
                            <PortalInputLarge placeholder={t`Afsluiting offerte`}
                                css={{ compose: extendedPortalInputLargeStyle }}
                                onChange={(v) => {
                                    const cl: DistributorAPI = DistributorAPI.clone(a);
                                    cl.setClosing(v);
                                    this.store(cl);
                                }} value={inun(a, (a) => a.getClosing(), () => "")} />
                        </ConfigBlock>
                    </div>
                    <div className="col-md-4">
                        <ConfigBlock
                            title={t`Voorwaarden offerte`}
                            description="Voorwaarden die weergegeven worden op de offerte."
                            topMost={false}
                        >
                            <PortalInputLarge placeholder={t`Voorwaarden offerte`}
                                css={{ compose: extendedPortalInputLargeStyle }}
                                onChange={(v) => {
                                    const cl: DistributorAPI = DistributorAPI.clone(a);
                                    cl.setConditions(v);
                                    this.store(cl);
                                }} value={inun(a, (a) => a.getConditions(), () => "")} />
                        </ConfigBlock>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <ConfigBlock
                            title={t`Introductie order`}
                            description="Introductie die weergegeven wordt op de offerte."
                            topMost={false}
                        >
                            <PortalInputLarge placeholder={t`Introductie order`}
                                css={{ compose: extendedPortalInputLargeStyle }}
                                onChange={(v) => {
                                    const cl: DistributorAPI = DistributorAPI.clone(a);
                                    cl.setOrderPreface(v);
                                    this.store(cl);
                                }} value={inun(a, (a) => a.getOrderPreface(), () => "")} />
                        </ConfigBlock>
                    </div>
                    <div className="col-md-4">
                        <ConfigBlock
                            title={t`Afsluiting order`}
                            description="Afsluiting die weergegeven wordt op de offerte."
                            topMost={false}
                        >
                            <PortalInputLarge placeholder={t`Afsluiting order`}
                                css={{ compose: extendedPortalInputLargeStyle }}
                                onChange={(v) => {
                                    const cl: DistributorAPI = DistributorAPI.clone(a);
                                    cl.setOrderClosing(v);
                                    this.store(cl);
                                }} value={inun(a, (a) => a.getOrderClosing(), () => "")} />
                        </ConfigBlock>
                    </div>
                    <div className="col-md-4">
                        <ConfigBlock
                            title={t`Voorwaarden order`}
                            description="Voorwaarden die weergegeven worden op de offerte."
                            topMost={false}
                        >
                            <PortalInputLarge placeholder={t`Voorwaarden order`}
                                css={{ compose: extendedPortalInputLargeStyle }}
                                onChange={(v) => {
                                    const cl: DistributorAPI = DistributorAPI.clone(a);
                                    cl.setOrderConditions(v);
                                    this.store(cl);
                                }} value={inun(a, (a) => a.getOrderConditions(), () => "")} />
                        </ConfigBlock>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-5">
                        <ConfigBlock
                            title={t`E-mailtemplate offerte`}
                            description={t`Dit template wordt als basis gebruikt bij het verzenden van offertes via e-mail. De effectieve inhoud is aanpasbaar bij het verzenden van de e-mail.`}
                            topMost={false}
                        >
                            <PortalInputLarge placeholder={t`Template`}
                                css={{ compose: extendedPortalInputLargeStyle }}
                                onChange={(v) => {
                                    const cl: DistributorAPI = DistributorAPI.clone(a);
                                    cl.setQuoteEmailTemplate(v);
                                    this.store(cl);
                                }} value={inun(a, (a) => a.getQuoteEmailTemplate(), () => "")} />
                        </ConfigBlock>
                    </div>
                    <div className="col-md-5">
                        <ConfigBlock
                            title={t`E-mailtemplate order`}
                            description={t`Dit template wordt als basis gebruikt bij het verzenden van orders via e-mail. De effectieve inhoud is aanpasbaar bij het verzenden van de e-mail.`}
                            topMost={false}
                        >
                            <PortalInputLarge placeholder={t`Template`}
                                css={{ compose: extendedPortalInputLargeStyle }}
                                onChange={(v) => {
                                    const cl: DistributorAPI = DistributorAPI.clone(a);
                                    cl.setOrderEmailTemplate(v);
                                    this.store(cl);
                                }} value={inun(a, (a) => a.getOrderEmailTemplate(), () => "")} />
                        </ConfigBlock>
                    </div>
                    <div className="col-md-2">
                        <ConfigBlock
                            title={t`E-mailconfiguratie`}
                            description={t`Valideer uw e-mailadres (${this.props.globalInformation.distributor.getAdministrationEmail()}). Vanaf dan kan u offertes via e-mail verzenden. Doe dit enkel wanneer uw e-mailadres nog niet eerder gevalideerd werd.`}
                            topMost={false}
                        >
                            <button className="styledHover" onClick={async (e) => {
                                await a.requestEmailVerification();
                                alert(t`Een bevestigingslink werd verzonden. Controleer uw inbox (${this.props.globalInformation.distributor.getAdministrationEmail()}) en klik op de bevestigingslink.`);
                            }}>Valideer e-mailadres</button>
                        </ConfigBlock>
                    </div>
                </div>
            </div>
        </>;
    }

}
