import UniformNodeFactory, { TitleType } from "./UniformNodeFactory";
import UniformNode from "./IUniformNode";
import VisibleArticleTree, { ArticleContainer } from "../datastructures/VisibleArticleTree";
import { IDistributor } from "../datastructures/Distributor";
import Quote from "../datastructures/Quote";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import moment from 'moment';
import { Calculation } from "../datastructures/ArticleCalculation";
import { DELIVERY, PICKUP, PLACEMENT } from "../datastructures/articleinput/TransportationInput";
import IArticleInput, { IQuantifiedSupplement, PageType } from "../datastructures/IArticleInput";
import { IHandrailPoleFinish } from "../datastructures/staircasedata/HandrailPoleFinish";
import { IHandrailPoleType } from "../datastructures/staircasedata/HandrailPoleType";
import { IDatabaseController } from "orbiter-core/src/databasecontroller/DatabaseController";
import ArticleInput from "../datastructures/articleinput/ArticleInput";
import ArticleInputDiff from "../datastructures/diff/ArticleInputDiff";
import { ArticleDiff } from "./QuoteRendererDiffLines";

export const MEASUREMENT_BY_HOTEC_TEXT = "Opmeting dient door Hotec uitgevoerd te worden.";
export const MEASUREMENT_BY_CUSTOMER_TEXT = "Maten worden door klant aangeleverd.";
export const ASSEMBLED_BY_HOTEC_FOR_DELIVERY_TEXT = "Trap wordt gemonteerd geleverd.";
export const ASSEMBLED_BY_CUSTOMER_FOR_DELIVERY_TEXT = "Trap wordt in delen geleverd.";
export const ASSEMBLED_BY_HOTEC_FOR_PICKUP_TEXT = "Trap wordt gemonteerd afgehaald.";
export const ASSEMBLED_BY_CUSTOMER_FOR_PICKUP_TEXT = "Trap wordt in delen afgehaald.";

function orderOrOfferString(quote: Quote): string {
    return quote.isOrder() ? "Orderbevestiging" : "Offerte";
}

export function transportationMethodToString(input: string): string {
    switch (input) {
        case PLACEMENT: {
            return `Plaatsing`;
        }
        case DELIVERY: {
            return `Levering`;
        }
        case PICKUP: {
            return `Afhaling`;
        }
    }
    return "ERROR";
}

function allPolesTheSame(poleTypes: (IHandrailPoleType & IDatabaseController)[], poleFinishes: (IHandrailPoleFinish & IDatabaseController)[]): boolean {
    if (poleTypes.length > 0) {
        let previousType = null;
        let previousFinish = null;
        for (let i = 0; i < poleTypes.length; i++) {
            const iType = poleTypes[i];
            const iFinish = poleFinishes[i];

            if (i === 0) {
                previousType = iType.getSid();
                previousFinish = iFinish.getSid();
            } else if (iType.getSid() !== previousType || iFinish.getSid() !== previousFinish) {
                return false;
            }
        }
    }
    return true;
}

export default class QuoteRenderer<NodeType> implements UniformNode<NodeType>{

    constructor(
        private visibleArticleTree: VisibleArticleTree,
        private nodeFactory: UniformNodeFactory<NodeType>,
        private showPriceDetails: boolean,
        private distributor: IDistributor,
        private distributorLogo: string | (() => Promise<string>),
        private dt: (mls: MultiLanguageString) => string,
    ) { }

    private generateDoc() {
        const f: UniformNodeFactory<NodeType> = this.nodeFactory;
        try {

            const quote: Quote = this.visibleArticleTree.quote;
            const dt: (mls: MultiLanguageString) => string = this.dt;

            return f.Document(orderOrOfferString(quote) + " " + quote.getQuoteNumber() + " - " + quote.getCustomerName(),
                f.Page(
                    "A4",
                    {
                        page: {
                            flexDirection: 'column',
                            backgroundColor: 'white',
                            fontWeight: 'normal',
                            fontSize: "10px",
                            fontFamily: "Poppins",
                            color: "#3F3F3F",
                            padding: 30,
                        }
                    },
                    f.FlexView(
                        f.SplitView([
                            f.View(
                                f.Image(this.distributorLogo, "80px"),
                                f.Space(),
                                f.BoldPlainText(this.distributor?.getName()),
                                this.distributor?.getAdministrationEmail() ? f.Link("mailto:" + this.distributor.getAdministrationEmail(), f.PlainText(this.distributor.getAdministrationEmail())) : f.None(),
                                this.distributor?.getAdministrationEmail() ? f.Link("tel:" + this.distributor.getTelephone(), f.PlainText(this.distributor.getTelephone())) : f.None(),
                                f.PlainText(this.distributor?.getAddress().streetAndNumber),
                                f.PlainText(this.distributor?.getAddress().postalCode + " " + this.distributor?.getAddress().city),
                                f.PlainText(this.distributor?.getAddress().country),
                                f.Space("10px"),
                                f.Text(
                                    f.BoldPlainText("BTW: "),
                                    f.String(this.distributor?.getVatNumber())
                                ),
                                f.BoldPlainText("Bank:"),
                                f.View(...this.distributor?.getBankAccounts().map(x => f.PlainText(x)))
                            )
                        ], [
                            f.View(
                                f.BoldPlainText("Klantgegevens"),
                                f.PlainText(quote.getCustomerName()),
                                f.Link("tel:" + quote.getCustomerPhone(), f.PlainText(quote.getCustomerPhone())),
                                f.Space(),
                                f.BoldPlainText("Adres"),
                                f.PlainText(quote.getCustomerAddress().streetAndNumber),
                                f.PlainText(quote.getCustomerAddress().postalCode + " " + quote.getCustomerAddress().city),
                                f.PlainText(quote.getCustomerAddress().country),
                                f.Space("10px"),
                                f.BoldPlainText("Werfadres"),
                                f.PlainText(quote.getWorkSiteAddress().streetAndNumber),
                                f.PlainText(quote.getWorkSiteAddress().postalCode + " " + quote.getWorkSiteAddress().city),
                                f.PlainText(quote.getWorkSiteAddress().country),
                            ),
                        ]),
                        f.Space(),
                        f.Title(TitleType.H1, f.String(orderOrOfferString(quote))),
                        this.generateHeader(dt),
                        f.Space("20px"),
                        f.PlainText(quote.isOrder() ? this.distributor.getOrderPreface() : this.distributor.getPreface()),
                        f.Space("50px"),
                        f.View(
                            this.generateArticles(dt),
                        ),
                        this.generateSurcharges(dt),
                        f.Space("20px"),
                        // f.Line(),
                        f.PlainText(quote.isOrder() ? this.distributor.getOrderClosing() : this.distributor.getClosing()),
                    ),
                    f.FlexBreakView(
                        f.Title(TitleType.H2, f.String("Voorwaarden")),
                        f.GrayedOutText(f.String(quote.isOrder() ? this.distributor?.getOrderConditions() : this.distributor?.getConditions()))
                    ),
                    f.Footer(orderOrOfferString(quote) + " " + quote.getQuoteNumber(), quote.getLastUpdateDate()),
                )
            )
        } catch (e) {
            console.error(e);
            return f.Document("ERROR", f.Page(
                "A4",
                {
                    page: {
                        flexDirection: 'column',
                        backgroundColor: 'white',
                        fontWeight: 'normal',
                        fontSize: "10px",
                        color: "#3F3F3F",
                        padding: 30,
                    }
                },
                f.View(f.PlainText("Er liep iets mis bij het opstellen van dit overzicht.")),
            ));
        }
    }

    private generateHeader(dt: (mls: MultiLanguageString) => string): UniformNode<NodeType> {
        const f = this.nodeFactory;
        const quote = this.visibleArticleTree.quote;

        return f.View(
            f.Title(TitleType.H2, f.String(quote.getTitle())),
            f.Text(
                f.String(orderOrOfferString(quote) + " opgemaakt door "),
                f.BoldPlainText(quote.getOwnerName()),
                !quote.isOrder() ? f.String(", geldig tot ") : f.None(),
                !quote.isOrder() ? f.BoldPlainText(quote.getExpirationDate() ? moment(quote.getExpirationDate()).format('DD/MM/YYYY') : "") : f.None(),
                f.String("."),
            ),
            f.Space(),
        );
    }

    private generateArticles(dt: (mls: MultiLanguageString) => string): UniformNode<NodeType> {
        const f = this.nodeFactory;
        const renderedArticles = [];

        for (let i = 0; i < this.visibleArticleTree.articleTree.length; i++) {
            const article = this.visibleArticleTree.articleTree[i];

            const resChildren: UniformNode<NodeType>[] = [];
            for (let j = 0; j < article.children.length; j++) {
                const child = article.children[j];
                const childPrice: Calculation = child.calculation;
                resChildren.push(f.StayTogetherView(
                    f.Title(TitleType.H4, f.String(`Variant ${j + 1} t.o.v. artikel ${i + 1}${child.article.reference ? ": " + child.article.reference : ""}`)),
                    this.generateArticleDiff(child.diffWithParent, childPrice, article.calculation, dt),
                ))

            }

            renderedArticles.push(
                f.xView({
                    break: i > 0,
                    wrap: true,
                    children: [
                        f.Title(TitleType.H2, f.String("Artikel " + (i + 1) + (article.article.reference ? ": " + article.article.reference : ""))),
                        this.generateArticle(article, dt),
                        f.StayTogetherView(
                            resChildren.length > 0 ? f.Title(TitleType.H3, f.String("Varianten")) : f.None(),
                            ...resChildren
                        ),
                    ]
                })
            )
        }

        return f.View(...renderedArticles);
    }

    private generateSurcharges(dt: (mls: MultiLanguageString) => string): UniformNode<NodeType> {
        const f = this.nodeFactory;
        const renderedSurcharges = [];

        const charges = this.visibleArticleTree.quoteSpecificCharges;

        if(charges.total.totalPrice.totalPrice > 0) {
            renderedSurcharges.push(f.Title(TitleType.H2, f.String("Overige kosten")));
            renderedSurcharges.push(f.Text(f.String("Levering: € "), f.String(charges.delivery.totalPrice.totalPrice.toFixed(2))));
            renderedSurcharges.push(f.Text(f.BoldPlainText("Totaal: "), f.String("€ " + charges.total.totalPrice.totalPrice.toFixed(2))));
        }
        
        return f.View(...renderedSurcharges);
    }

    private generateArticle(articleC: ArticleContainer, dt: (mls: MultiLanguageString) => string): UniformNode<NodeType> {
        const f = this.nodeFactory;
        const article = articleC.article;
        const price: Calculation = articleC.calculation;

        return f.View(
            f.Text(f.BoldPlainText("Aantal: "), f.String(article.unitCount?.toString())),
            article.discountPercentageStaircase > 0 ? f.Text(f.BoldPlainText("Korting trap: "), f.String(article.discountPercentageStaircase + "%")) : f.None(),
            article.discountPercentageTreatment > 0 ? f.Text(f.BoldPlainText("Korting behandeling: "), f.String(article.discountPercentageTreatment + "%")) : f.None(),
            article.discountPercentageDistribution > 0 ? f.Text(f.BoldPlainText("Korting plaatsing: "), f.String(article.discountPercentageDistribution + "%")) : f.None(),
            f.Text(f.BoldPlainText("Totaalprijs: "),
                article.unitCount > 1 ?
                    f.String(`${article.unitCount?.toString()} × € ${(price?.totalPrice.totalPrice / article.unitCount).toFixed(2)} = € ${price?.totalPrice.totalPrice.toFixed(2)}`)
                    :
                    f.String(`€ ${price?.totalPrice.totalPrice.toFixed(2)}`)
            ),

            // TRAP
            f.StayTogetherView(
                f.Title(TitleType.H3, f.String("Trap"), this.generatePrice(price.staircasePrice.totalPrice)),
                f.PlainText(`${article.shape?.closedStaircase ? `Gesloten` : `Open`} ${dt(article.type.getDescription()).toLowerCase()}, ${dt(article.shape.staircaseShape.getDescription()).toLowerCase()} uit ${dt(article.woodType.getDescription()).toLowerCase()} met ${article.treadCount} treden ${article.shape?.closedStaircase ? "" : `en ${article.shape.riserCount ?? `zonder`} tegentreden `}uit ${article.shape.getRiserThickness().getThickness() == 12 ? `multiplex fineer ` : ``}${dt(article.riserWoodType.getDescription()).toLowerCase()}.`),
                article.width > articleC.baseConstantSet.getWidthTreshold() ? f.PlainText(`De trap heeft een breedte van ${article.width}cm en overschrijdt hiermee de standaardbreedte van ${articleC.baseConstantSet.getWidthTreshold()}cm, hiervoor werd een meerkost aangerekend.`) : f.PlainText(`De trap heeft een breedte van ${article.width}cm.`),
                this.generateSupplements("Supplementen treden & trapbomen", article.shape.treadSupplements, dt),

                f.Title(TitleType.H4, f.String("Diktes")),
                f.Bullet(f.String(`Trapboom: ${dt(article.shape.stringerThickness.getDescription())} mm`)),
                f.Bullet(f.String(`Trede: ${dt(article.shape.treadThickness.getDescription())} mm`)),
                f.Bullet(f.String(`Tegentrede: ${dt(article.shape.getRiserThickness()?.getDescription())} mm`)),
                this.generateComment("trap", article, PageType.STAIRCASE),
                this.generateComment("trapvorm", article, PageType.SHAPE),
            ),

            !ArticleInput.handrailEnabled(article) && !ArticleInput.wallHandrailEnabled(article) && !ArticleInput.extraHandrailEnabled(article) ? f.StayTogetherView(
                f.StayTogetherView(
                    f.Title(TitleType.H3, f.String("Leuning"), this.generatePrice(price.handrailPrice.totalPrice)),
                    f.PlainText("Er zijn geen leuningen voorzien.")
                ),

                this.generateComment("leuning", article, PageType.HANDRAIL),
            ) : f.StayTogetherView(
                // LEUNING
                f.StayTogetherView(
                    f.Title(TitleType.H3, f.String("Leuning"), this.generatePrice(price.handrailPrice.totalPrice)),
                    f.Title(TitleType.H4, f.String("Leuning op trap")),
                    this.generateHandrail(article, dt),
                ),

                // EXTRA LEUNING
                f.StayTogetherView(
                    f.Title(TitleType.H4, f.String("Extra leuning")),
                    this.generateExtraHandrail(article, dt),
                ),

                // MUURLEUNING
                f.StayTogetherView(
                    f.Title(TitleType.H4, f.String("Muurleuning")),
                    this.generateWallHandrail(article, dt),
                ),

                this.generateComment("leuning", article, PageType.HANDRAIL),
            ),

            // OVERLOOP
            !ArticleInput.landingStageHandrailEnabled(article) && !ArticleInput.landingStageConcreteEnclosureEnabled(article) ?
                f.StayTogetherView(
                    f.Title(TitleType.H3, f.String("Overloop"), this.generatePrice(price.landingStagePrice.totalPrice)),
                    f.PlainText("Er is geen overloop voorzien."),

                    this.generateComment("overloop", article, PageType.LANDING_STAGE),
                ) : f.StayTogetherView(
                    f.Title(TitleType.H3, f.String("Overloop"), this.generatePrice(price.landingStagePrice.totalPrice)),
                    this.generateLandingStageHandrail(article, dt),
                    this.generateLandingStageConcreteEnclosure(article, dt),
                    this.generateComment("overloop", article, PageType.LANDING_STAGE),
                ),

            // BEHANDELING
            f.StayTogetherView(
                f.Title(TitleType.H3, f.String("Behandeling"), this.generatePrice(price.treatmentPrice.totalPrice)),
                this.generateTreatment(article, dt),
                this.generateComment("behandeling", article, PageType.TREATMENT),
            ),

            // TRANSPORT EN PLAATSING
            f.StayTogetherView(
                f.Title(TitleType.H3, f.String("Transport & plaatsing"), this.generatePrice(price.placementPrice.totalPrice)),
                this.generateTransportation(article, dt),
                this.generateSupplements("Supplementen plaatsing", article.transportation.placementSupplements, dt),
                this.generateComment("transport en plaatsing", article, PageType.TRANSPORTATION),
            ),

            f.Space(),
            this.generateComment("", article, PageType.GENERAL),

        )
    }

    private generatePrice(price: number): UniformNode<NodeType> {
        const f = this.nodeFactory;
        return this.showPriceDetails ? f.xItalic({
            style: {
                fontSize: '10px',
                opacity: 1,
            },
            children: [f.String(" • € " + price.toFixed(2))]
        }) : f.None()
    }

    private generateSupplements(title: string, supplements: IQuantifiedSupplement[], dt: (mls: MultiLanguageString) => string): UniformNode<NodeType> {
        const f = this.nodeFactory;

        if (supplements.length === 0)
            return f.None();

        return f.View(
            f.Title(TitleType.H4, f.String(title)),
            f.View(
                ...supplements.map(x => f.Bullet(f.String(`${x.amount} x ${dt(x.supplement?.getTitle())}`))),
            )
        )
    }

    private generateComment(title: string, article: IArticleInput, pageType: PageType): UniformNode<NodeType> {
        const f = this.nodeFactory;
        const comment = article.comments.find(x => x.page === pageType)?.content;
        if (comment) {
            return f.xView({
                style: { backgroundColor: "rgba(255, 203, 4, 0.2)", padding: "10px", borderRadius: "3px" },
                children: [f.Text(
                    f.BoldPlainText(`Opmerking ${title ?? ""}: `),
                    f.String(comment),
                )]
            })
        } else {
            return f.None()
        }
    }

    private generateExtraHandrail(article: IArticleInput, dt: (mls: MultiLanguageString) => string): UniformNode<NodeType> {
        const f = this.nodeFactory;
        if (ArticleInput.extraHandrailEnabled(article)) {
            const polesTheSame = allPolesTheSame(article.extraHandrail.handrailPoleTypes, article.extraHandrail.handrailPoleFinishes);
            return f.View(
                f.Text(
                    f.String("Er is een extra leuning met type "),
                    f.String(dt(article.extraHandrail.type.getDescription()) + " "),
                    f.String(`voor ${article.extraHandrail.treadCount} tredes voorzien `),
                    f.String(article.extraHandrail.handle ? `met handgreep ${dt(article.extraHandrail.handle.getDescription())} ` : `zonder handgreep `),
                    f.String(`uitgevoerd in ${dt(article.woodType.getHandrailDescription())} ${article.extraHandrail.handrailPoleTypes.length > 0 ? " met " + article.extraHandrail.handrailPoleTypes.length + " palen:" +
                        (polesTheSame ?
                            " " + dt(article.extraHandrail.handrailPoleTypes[0].getDescription()) + " met bovenafwerking " + dt(article.extraHandrail.handrailPoleFinishes[0].getDescription()) + "."
                            :
                            ""
                        ) : "zonder palen."}`),
                ),
                polesTheSame ? f.None() : f.View(
                    ...article.extraHandrail.handrailPoleTypes.map((p, i) => f.Bullet(f.String(`${dt(p.getDescription())} met bovenafwerking ${dt(article.extraHandrail.handrailPoleFinishes[i].getDescription())}`)))
                ),
                article.extraHandrail.spindleThreadType ? f.PlainText(`Er zijn modelspijlen van het type ${dt(article.extraHandrail.spindleThreadType.getDescription())}${article.extraHandrail.spindleThreadDescription ? ` (${article.extraHandrail.spindleThreadDescription})`: ``} geselecteerd.`) : f.None(),
            )
        } else {
            return f.PlainText("Er is geen extra leuning voorzien.")
        }
    }

    private generateWallHandrail(article: IArticleInput, dt: (mls: MultiLanguageString) => string): UniformNode<NodeType> {
        const f = this.nodeFactory;
        if (ArticleInput.wallHandrailEnabled(article)) {
            return f.View(
                f.Text(
                    f.String(`Er is een muurleuning van `),
                    f.String(`${article.wallHandrail.length} lopende meter `),
                    f.String(`van het type ${dt(article.wallHandrail.type.getDescription())} voorzien `),
                    article.wallHandrail.bendCount > 0 ? f.String(`met ${article.wallHandrail.bendCount} knikken met afgeronde hoeken, `) : f.String(`zonder knikken, `),
                    article.wallHandrail.hookCount > 0 ? f.String(`voorzien van ${article.wallHandrail.hookCount} muurhaken van type ${dt(article.wallHandrail.hook.getDescription())}.`) : f.String(`zonder muurhaken.`),
                ),
            )
        } else {
            return f.PlainText("Er is geen muurleuning voorzien.")
        }
    }

    private generateHandrail(article: IArticleInput, dt: (mls: MultiLanguageString) => string): UniformNode<NodeType> {
        const f = this.nodeFactory;
        if (ArticleInput.handrailEnabled(article)) {
            const polesTheSame = allPolesTheSame(article.handrail.handrailPoleTypes, article.handrail.handrailPoleFinishes);
            return f.View(
                f.Text(
                    f.String("Leuning met type "),
                    f.String(dt(article.handrail.type.getDescription()) + " "),
                    f.String(article.handrail.handle ? `met handgreep ${dt(article.handrail.handle.getDescription())} ` : `zonder handgreep `),
                    f.String(`uitgevoerd in ${dt(article.woodType.getHandrailDescription())}${article.handrail.handrailPoleTypes.length > 0 ? " met " + article.handrail.handrailPoleTypes.length + " palen:" +
                        (polesTheSame ?
                            " " + dt(article.handrail.handrailPoleTypes[0].getDescription()) + " met bovenafwerking " + dt(article.handrail.handrailPoleFinishes[0].getDescription()) + "."
                            :
                            ""
                        ) : "zonder palen."}`),
                ),
                polesTheSame ? f.None() : f.View(
                    ...article.handrail.handrailPoleTypes.map((p, i) => f.Bullet(f.String(`${dt(p.getDescription())} met bovenafwerking ${dt(article.handrail.handrailPoleFinishes[i].getDescription())}`)))
                ),
                article.handrail.spindleThreadType ? f.PlainText(`Er zijn modelspijlen van het type ${dt(article.handrail.spindleThreadType.getDescription())}${article.handrail.spindleThreadDescription ? ` (${article.handrail.spindleThreadDescription})`: ``} geselecteerd.`) : f.None(),
                this.generateSupplements("Supplementen leuning", article.handrail.supplements, dt),
            )
        } else {
            return f.PlainText("Er is geen leuning voorzien.")
        }
    }

    private generateLandingStageHandrail(article: IArticleInput, dt: (mls: MultiLanguageString) => string): UniformNode<NodeType> {
        const f = this.nodeFactory;
        if (ArticleInput.landingStageHandrailEnabled(article)) {
            return f.View(
                f.Title(TitleType.H4, f.String("Overloop leuning")),
                f.Text(
                    f.String(`Er is een overloopleuning van `),
                    f.String(`${article.landingStage.handrailLength} lopende meter `),
                    f.String(`van het type ${dt(article.landingStage.handrail.type.getDescription())} ${(() => {
                        if (article.landingStage.handrail.handle)
                            return "met handgreep " + dt(article.landingStage.handrail.handle.getDescription());
                        else
                            return "zonder handgreep";
                    })()} uitgevoerd in ${dt(article.woodType.getHandrailDescription())} voorzien.`),
                ),
                article.landingStage.handrail.spindleThreadType ? f.PlainText(`Er zijn modelspijlen van het type ${dt(article.landingStage.handrail.spindleThreadType.getDescription())}${article.landingStage.handrail.spindleThreadDescription ? ` (${article.landingStage.handrail.spindleThreadDescription})`: ``} geselecteerd.`) : f.None(),
                this.generateSupplements("Supplementen overloop leuning", article.landingStage.handrail.supplements, dt),
            )
        } else {
            return f.PlainText("Er is geen overloopleuning voorzien.")
        }
    }

    private generateLandingStageConcreteEnclosure(article: IArticleInput, dt: (mls: MultiLanguageString) => string): UniformNode<NodeType> {
        const f = this.nodeFactory;
        if (ArticleInput.landingStageConcreteEnclosureEnabled(article)) {
            return f.View(
                f.Title(TitleType.H4, f.String("Afkasting overloop")),
                f.PlainText(`${article.landingStage.concreteEnclosureLength} Meter afkasting uitgevoerd in ${article.shape.getRiserThickness().getThickness() == 12 ? "multiplex fineer " : ''}${dt(article.landingStage.concreteEnclosureWoodType.getDescription())} met een breedte van ${article.landingStage.concreteEnclosureWidth} cm.`),
            )
        } else {
            return f.PlainText("Er is geen afkasting voorzien.")
        }
    }

    private generateTreatment(article: IArticleInput, dt: (mls: MultiLanguageString) => string): UniformNode<NodeType> {
        const f = this.nodeFactory;
        if (article.treatment === null) {
            return f.PlainText(`Er is geen behandeling voorzien.`);
        } else if (article.treatment.colors.length > 0) {
            return f.PlainText(`${dt(article.treatment.type.getDescription())} in kleuren ${article.treatment.colors.reduce((pr, cur, i, arr) => pr + cur.toLowerCase() + (i !== arr.length - 1 ? ", " : ""), "")}.`);
        } else {
            return f.PlainText(`${dt(article.treatment.type.getDescription())} zonder kleuren.`);
        }
    }

    private generateTransportation(article: IArticleInput, dt: (mls: MultiLanguageString) => string): UniformNode<NodeType> {
        const f = this.nodeFactory;

        const delivery = article.transportation.transportationMethod === DELIVERY;
        const pickup = article.transportation.transportationMethod === PICKUP;

        return f.View(
            f.PlainText(`${transportationMethodToString(article.transportation.transportationMethod)}${article.transportation.staircasePlacementOptions.length === 0 ? "" : ", " + article.transportation.staircasePlacementOptions.reduce((pr, cur, i, arr) => pr + dt(cur.getDescription()).toLowerCase() + (i !== arr.length - 1 ? ", " : ""), "")}.`),
            delivery || pickup ? f.View(
                f.PlainText(`${article.transportation.measurementByHotec ? MEASUREMENT_BY_HOTEC_TEXT : MEASUREMENT_BY_CUSTOMER_TEXT}`),
                f.PlainText(article.transportation.assembledByHotec ? (
                    delivery ? ASSEMBLED_BY_HOTEC_FOR_DELIVERY_TEXT : ASSEMBLED_BY_HOTEC_FOR_PICKUP_TEXT
                ) : (
                    delivery ? ASSEMBLED_BY_CUSTOMER_FOR_DELIVERY_TEXT : ASSEMBLED_BY_CUSTOMER_FOR_PICKUP_TEXT
                ))
            ) : f.None()
        )
    }

    private generateArticleDiff(diff: ArticleInputDiff, price: Calculation | null, mainPrice: Calculation | null, dt: (mls: MultiLanguageString) => string): UniformNode<NodeType> {
        const f = this.nodeFactory;

        if (!diff) {
            return f.None();
        }

        let priceDiff = null;
        if (price !== null && mainPrice !== null) {
            const priceDiffNum = price.totalPrice.totalPrice - mainPrice.totalPrice.totalPrice;
            if (priceDiffNum < 0) {
                priceDiff = priceDiffNum.toFixed(2);
            } else if (priceDiffNum > 0) {
                priceDiff = "+" + priceDiffNum.toFixed(2)
            } else {
                priceDiff = "Geen prijsverschil";
            }
        }

        return diff.differs() ? f.View(
            f.Text(f.BoldPlainText("Verschil totaalprijs: "), f.String(priceDiff)),
            f.Text(f.BoldPlainText("Verschillen:")),
            ArticleDiff(f, dt, diff),
        ) : f.PlainText("Geen verschillen met hoofdartikel.")

    }

    public renderWithManualKey(): NodeType {
        return this.generateDoc().renderWithManualKey();
    }

    public render(seedKey: string = ""): NodeType {
        return this.generateDoc().render(seedKey);
    }

}