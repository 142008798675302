exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1yDFaMb2O8Ppiie6aXLU4s{\n    color: red;\n    font-weight: bold;\n}\n\n.uCm5UEx5OOfjDcC1-IBPp{\n    color: #999;\n}", ""]);

// exports
exports.locals = {
	"expired": "_1yDFaMb2O8Ppiie6aXLU4s",
	"gray": "uCm5UEx5OOfjDcC1-IBPp"
};