import { IDatabaseController } from "orbiter-core/src/databasecontroller/DatabaseController";
import { differingDBControllerList, differingNumbers, differingStrings, IArticleDiffLine, newDiffFalseLine, newDiffTrueLine } from "./ArticleInputDiff";
import ArticleInput from "../articleinput/ArticleInput";
import { IHandrailHandle } from "../staircasedata/HandrailHandle";
import { IHandrailPoleFinish } from "../staircasedata/HandrailPoleFinish";
import { IHandrailPoleType } from "../staircasedata/HandrailPoleType";
import { IHandrailType } from "../staircasedata/HandrailType";
import { ISpindleThreadType } from "../staircasedata/SpindleThreadType";
import ExtraHandrailInput from "../articleinput/ExtraHandrailInput";

export default class ExtraHandrailInputDiff{

    public main: IArticleDiffLine<ExtraHandrailInput> = newDiffFalseLine();

    public treadCount: IArticleDiffLine<number> = newDiffFalseLine();
    public poleCount: IArticleDiffLine<number> = newDiffFalseLine();
    public type: IArticleDiffLine<IHandrailType & IDatabaseController> = newDiffFalseLine();
    public handle: IArticleDiffLine<IHandrailHandle & IDatabaseController> = newDiffFalseLine();
    public spindleThreadType: IArticleDiffLine<ISpindleThreadType & IDatabaseController> = newDiffFalseLine();
    public spindleThreadDescription: IArticleDiffLine<string> = newDiffFalseLine();
    public handrailPoleTypes: IArticleDiffLine<(IHandrailPoleType & IDatabaseController)[]> = newDiffFalseLine();
    public handrailPoleFinishes: IArticleDiffLine<(IHandrailPoleFinish & IDatabaseController)[]> = newDiffFalseLine();

    readonly left: ExtraHandrailInput;
    readonly right: ExtraHandrailInput;

    constructor(readonly a1: ArticleInput, readonly a2: ArticleInput){
        this.left = a1.extraHandrail;
        this.right = a2.extraHandrail;
    }

    public async diff(){
        if(this.left && this.right){
            // Both defined
            await this.diffType();
            await this.diffHandle();
            await this.diffSpindleThreadType();
            await this.diffSpindleThreadDescription();
            await this.diffHandrailPoleTypes();
            await this.diffHandrailPoleFinishes();
            await this.diffTreadCount();
            await this.diffPoleCount();
        }else if(this.left && !this.right){
            // Was defined, not anymore
            this.main = newDiffTrueLine(this.left, this.right);
            this.treadCount = newDiffTrueLine(this.left.treadCount, null);
            this.poleCount = newDiffTrueLine(this.left.poleCount, null);
            this.type = newDiffTrueLine(this.left.type, null);
            this.handle = newDiffTrueLine(this.left.handle, null);
            this.spindleThreadType = newDiffTrueLine(this.left.spindleThreadType, null);
            this.spindleThreadDescription = newDiffTrueLine(this.left.spindleThreadDescription, null);
            this.handrailPoleTypes = newDiffTrueLine(this.left.handrailPoleTypes, null);
            this.handrailPoleFinishes = newDiffTrueLine(this.left.handrailPoleFinishes, null);
        }else if(!this.left && this.right){
            // Was not defined, is now
            this.main = newDiffTrueLine(this.left, this.right);
            this.treadCount = newDiffTrueLine(null, this.right.treadCount);
            this.poleCount = newDiffTrueLine(null, this.right.poleCount);
            this.type = newDiffTrueLine(null, this.right.type);
            this.handle = newDiffTrueLine(null, this.right.handle);
            this.spindleThreadType = newDiffTrueLine(null, this.right.spindleThreadType);
            this.spindleThreadDescription = newDiffTrueLine(null, this.right.spindleThreadDescription);
            this.handrailPoleTypes = newDiffTrueLine(null, this.right.handrailPoleTypes);
            this.handrailPoleFinishes = newDiffTrueLine(null, this.right.handrailPoleFinishes);
        }
    }

    public differs(): boolean{
        return this.type.different
            || this.handle.different
            || this.spindleThreadType.different
            || this.spindleThreadDescription.different
            || this.handrailPoleTypes.different
            || this.handrailPoleFinishes.different
            || this.treadCount.different
            || this.poleCount.different
            || this.main.different;
    }

    private async diffType(){
        if(differingStrings(this.left.type?.getSid(), this.right.type?.getSid())){
            this.type = newDiffTrueLine(
                this.left.type,
                this.right.type,
            )
        }
    }

    private async diffHandle(){
        if(differingStrings(this.left.handle?.getSid(), this.right.handle?.getSid())){
            this.handle = newDiffTrueLine(
                this.left.handle,
                this.right.handle,
            )
        }
    }

    private async diffSpindleThreadType(){
        if(differingStrings(this.left.spindleThreadType?.getSid(), this.right.spindleThreadType?.getSid())){
            this.spindleThreadType = newDiffTrueLine(
                this.left.spindleThreadType,
                this.right.spindleThreadType,
            )
        }
    }

    private async diffSpindleThreadDescription(){
        if(differingStrings(this.left.spindleThreadDescription, this.right.spindleThreadDescription)){
            this.spindleThreadDescription = newDiffTrueLine(
                this.left.spindleThreadDescription,
                this.right.spindleThreadDescription,
            )
        }
    }

    private async diffHandrailPoleTypes(){
        if(differingDBControllerList(this.left.handrailPoleTypes, this.right.handrailPoleTypes)){
            this.handrailPoleTypes = newDiffTrueLine(
                this.left.handrailPoleTypes,
                this.right.handrailPoleTypes,
            );
        }   
    }

    private async diffHandrailPoleFinishes(){
        if(differingDBControllerList(this.left.handrailPoleFinishes, this.right.handrailPoleFinishes)){
            this.handrailPoleFinishes = newDiffTrueLine(
                this.left.handrailPoleFinishes,
                this.right.handrailPoleFinishes,
            );
        }   
    }

    private async diffTreadCount(){
        if(differingNumbers(this.left.treadCount, this.right.treadCount)){
            this.treadCount = newDiffTrueLine(
                this.left.treadCount,
                this.right.treadCount,
            )
        }
    }

    private async diffPoleCount(){
        if(differingNumbers(this.left.poleCount, this.right.poleCount)){
            this.poleCount = newDiffTrueLine(
                this.left.poleCount,
                this.right.poleCount,
            )
        }
    }

}