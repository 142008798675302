import React = require("react");
import { DispatchProp } from "react-redux";
import { Popup, PopupManager } from "../../../components/Popup";
import { t } from "ttag";
import Input from "webc-reactcore/src/js/components/mainlayout/PortalInputLarge";
import styleable from "react-styleable";
import * as styles from "../../../../css/components/EmailInputComponent.css";

export function emailInputPopupFactory(onSend: (body: string) => void, initialValue: string, downloadUrl: string){
    let value = initialValue;
    return new Popup(<StyledEmailInputComponent initialValue={initialValue} downloadUrl={downloadUrl} onChange={(v) => value = v} {...{} as any}/>, [
        {
            text: t`Annuleren`,
            onClick: ()=>PopupManager.closePopup(),
        },
        {
            text: t`Verzenden`,
            onClick: ()=>{
                onSend(value);
                PopupManager.closePopup()
            },
        }
    ])
}

class EmailInputComponent extends React.Component<{initialValue: string, css: any, onChange: (body: string) => void, downloadUrl: string} & DispatchProp, {value: string}> {

    constructor(_){
        super(_);
        this.escPressed = this.escPressed.bind(this);
        this.state = {
            value: "",
        }
    }

    escPressed(event){
        if(event.keyCode === 27) {
            PopupManager.closePopup();
        }
    }

    componentDidMount(){
        this.setState({
            value: this.props.initialValue,
        })
        document.addEventListener("keydown", this.escPressed, false);
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.escPressed, false);
    }

    public render() {
        return <div className={this.props.css.container}>
            <Input placeholder={t`Bericht`}
                onChange={(v) => {
                    this.setState({value: v});
                    this.props.onChange(v);
                }}
                value={this.state.value} />
            <a href={this.props.downloadUrl} target="_blank">{t`Download bijlage`}</a>
        </div>
    }
}
const StyledEmailInputComponent = styleable(styles)(EmailInputComponent);
export default StyledEmailInputComponent;