import * as React from "react";
import styleable from "react-styleable";

import * as generalStyles from "../../../css/general.css";
import * as styles from "../../../css/pages/settings.css";
import * as toolbarStyles from "../../../css/components/toolbar/Toolbar.css";

import TextWithIcon from "webc-reactcore/src/js/components/mainlayout/TextWithIcon";
import StyledBox from "../../components/StyledBox";
import ToolbarContextProvider from "webc-reactcore/src/js/components/toolbar/ToolbarContextProvider";
import Toolbar from "webc-reactcore/src/js/components/toolbar/Toolbar";

// TODO: check height and scaling

/**
 * Settings page template.
 *
 * ## styleable
 * * **container** Container styling.
 * * **box** StyledBox element styling.
 *
 * @property {string} props.title - Title
 * @property {objects} props.children - Children
 */
class SettingsTemplate extends React.Component<any> {
    public render() {
        return (
            <div className={this.props.css.container}>
                <ToolbarContextProvider>
                    <StyledBox css={{compose: {container: this.props.css.box}}}>
                        <div className={this.props.css.toolbarContainer}>
                            <Toolbar css={{compose: toolbarStyles}}/>
                        </div>
                        <TextWithIcon text={this.props.title} css={{compose: {container: generalStyles.textWithIconContainer}}}/>
                        {this.props.children}
                    </StyledBox>
                </ToolbarContextProvider>
            </div>
        );
    }
}
export default styleable(styles)(SettingsTemplate);
