exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._15En_hMHEmeYpJ73zmDQVd{\n    background-color: #3F3F3F;\n}\n.sPnVuR5nHD4WWj4xn23tT{\n    padding-top: 90px;\n}\n._2LEz4W_IYfcGKlwagJwjH-{\n    background-color: #F4F6F7;\n    display: flex;\n    flex-flow: column;\n    height: 100%;\n}", ""]);

// exports
exports.locals = {
	"nav": "_15En_hMHEmeYpJ73zmDQVd",
	"contentBox": "sPnVuR5nHD4WWj4xn23tT",
	"contentBoxOuter": "_2LEz4W_IYfcGKlwagJwjH-"
};