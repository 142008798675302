import {doJsonGetApiCall} from "webc-reactcore/src/js/apicommunication";
import {retrieveAllLanguages} from "webc-reactcore/src/js/actions/LanguageActions";
import {setBasicInformation, setDistributors, retrieveMinifiedQuotes, setHotecUsers} from "./actions/globalInformationActions";
import {ConstructionMethods} from "./apicontroller/ApiController";
import DistributorAPI from "./apicontroller/DistributorAPI";
import StaircaseTypeAPI from "./apicontroller/staircasedata/StaircaseTypeAPI";
import WoodTypeAPI from "./apicontroller/staircasedata/WoodTypeAPI";
import store from "./store";
import HandrailPoleTypeAPI from "./apicontroller/staircasedata/HandrailPoleTypeAPI";
import SpindleThreadTypeAPI from "./apicontroller/staircasedata/SpindleThreadTypeAPI";
import StaircasePlacementOptionAPI from "./apicontroller/staircasedata/StaircasePlacementOptionAPI";
import StaircaseShapeAPI from "./apicontroller/staircasedata/StaircaseShapeAPI";
import { RiserThicknessAPI, TreadThicknessAPI, StringerThicknessAPI } from "./apicontroller/staircasedata/ThicknessAPI";
import { SupplementTreadAPI, SupplementHandrailAPI, SupplementLandingStageAPI, PlacementSupplementAPI } from "./apicontroller/staircasedata/SupplementAPI";
import TreatmentTypeAPI from "./apicontroller/staircasedata/TreatmentTypeAPI";
import HandrailTypeAPI from "./apicontroller/staircasedata/HandrailTypeAPI";
import HotecUserAPI from "./apicontroller/HotecUserAPI";
import HandrailHandleAPI from "./apicontroller/staircasedata/HandrailHandleAPI";
import BaseConstantSetAPI from "./apicontroller/staircasedata/BaseConstantSetAPI";
import HandrailPoleFinishAPI from "./apicontroller/staircasedata/HandrailPoleFinishAPI";
import WallHandrailAPI from "./apicontroller/staircasedata/WallHandrailAPI";
import WallHookAPI from "./apicontroller/staircasedata/WallHookAPI";
import ThicknessRuleAPI from "./apicontroller/staircasedata/ThicknessRuleAPI";
import { settingsLoaded, startLoading, stopLoading } from "./pages/newquote/article/loaders/loaderHelper";
import PermissionAPI from "./apicontroller/PermissionAPI";
import { hasMatchingPermission } from "./helpers";
import { DATA_ROOT, SETTINGS } from "../../../shared/permissions";

export default async function load(){
    startLoading();
    
    try{
        const dispatchQuotes = async () => store.dispatch(await retrieveMinifiedQuotes());
        await dispatchQuotes();
        await loadCurrentUser();
        if(hasMatchingPermission(DATA_ROOT))
            await loadDistributors();
    }catch(e){}

    stopLoading();

}

export async function loadSettings(force: boolean = false){
    if(!hasMatchingPermission(SETTINGS)){
        return;
    }
    if(force || !store.getState().globalInformation.settingsLoaded){
        startLoading();

        const promises: Promise<any>[] = [];
        let errors = false;

        try{
            // TODO: load all at once
            promises.push(loadWoodTypes());
            promises.push(loadStaircaseTypes());
            promises.push(loadSpindleThreadTypes());
            promises.push(loadBaseConstantSets());
            promises.push(loadHandrailHandles());
            promises.push(loadThicknessRules());
            promises.push(loadStaircasePlacementOptions());
            promises.push(loadStaircaseShapes());
            promises.push(loadRiserThickness());
            promises.push(loadTreadThickness());
            promises.push(loadStringerThickness());
            promises.push(loadTreadSupplements());
            promises.push(loadHandrailSupplements());
            promises.push(loadLandingStageSupplements());
            promises.push(loadPlacementSupplements());
            promises.push(loadTreatmentTypes());
            promises.push(loadHandrailTypes());
            promises.push(loadLanguages());
            promises.push(loadDistributors());
            promises.push(loadUsers());
            promises.push(loadHandrailPoleTypes());
            promises.push(loadHandrailPoleFinishes());
            promises.push(loadWallHandrails());
            promises.push(loadWallHooks());
            if(hasMatchingPermission())
                promises.push(loadPermissions());
        }catch(e){
            errors = true;
        }

        await Promise.all(promises);

        if(!errors){
            settingsLoaded();
        }

        stopLoading();
    }
    
}

export async function loadDistributors(){
    console.log("1. starting to load");
    doJsonGetApiCall("hotec/distributor/retrieve/all", async (result) => {
        console.log("2. retrieved");
        const distributors: DistributorAPI[] = await Promise.all<DistributorAPI>(
            result.distributors.map(async (d): Promise<DistributorAPI> => {
                const dApi = new DistributorAPI(d._id, ConstructionMethods.PromiseToRetrieve);
                await dApi.parseFromDictionary(d);
                return dApi;
            }));
        console.log("3. dispatching");
        store.dispatch(setDistributors(distributors));
    });
}

export async function loadCurrentUser(){
    return new Promise<string[]>((resolve, reject) => {
        HotecUserAPI.retrieveCurrent().then((info: [HotecUserAPI, DistributorAPI, string[]]) => {
            const user = info[0];
            store.dispatch(setBasicInformation({user, distributor: info[1], permissionStrings: info[2]}));
            resolve(info[2]);
        })
    })
}

export async function loadUsers(){
    HotecUserAPI.retrieveAll().then((users: HotecUserAPI[]) => {
        store.dispatch(setHotecUsers(users));
    })
}

export async function loadPermissions(){
    PermissionAPI.retrieveAll().then((permissions: PermissionAPI[]) => {
        store.dispatch(setBasicInformation({permissions}));
    })
}

export async function loadWoodTypes(){
    const woodTypes: WoodTypeAPI[] = await WoodTypeAPI.retrieveAll();
//    console.log("wood types", woodTypes);
    store.dispatch(setBasicInformation({woodTypes}));
}

export async function loadHandrailPoleTypes(){
    const handrailPoleTypes: HandrailPoleTypeAPI[] = await HandrailPoleTypeAPI.retrieveAll();
//    console.log("hptypes", handrailPoleTypes);
    store.dispatch(setBasicInformation({handrailPoleTypes}));
}

export async function loadHandrailPoleFinishes(){
    const handrailPoleFinishes: HandrailPoleFinishAPI[] = await HandrailPoleFinishAPI.retrieveAll();
//    console.log("hptypes", handrailPoleFinishs);
    store.dispatch(setBasicInformation({handrailPoleFinishes}));
}

export async function loadWallHandrails(){
    const wallHandrails: WallHandrailAPI[] = await WallHandrailAPI.retrieveAll();
//    console.log("hptypes", handrailPoleFinishs);
    store.dispatch(setBasicInformation({wallHandrails}));
}

export async function loadWallHooks(){
    const wallHooks: WallHookAPI[] = await WallHookAPI.retrieveAll();
//    console.log("hptypes", handrailPoleFinishs);
    store.dispatch(setBasicInformation({wallHooks}));
}

export async function loadStaircaseTypes(){
    const staircaseTypes: StaircaseTypeAPI[] = await StaircaseTypeAPI.retrieveAll();
//    console.log("staircase types", staircaseTypes);
    store.dispatch(setBasicInformation({staircaseTypes}));
}

export async function loadSpindleThreadTypes(){
    const spindleThreadTypes: SpindleThreadTypeAPI[] = await SpindleThreadTypeAPI.retrieveAll();
    store.dispatch(setBasicInformation({spindleThreadTypes}));
}

export async function loadBaseConstantSets(){
    const baseConstantSets: BaseConstantSetAPI[] = await BaseConstantSetAPI.retrieveAll();
    store.dispatch(setBasicInformation({baseConstantSets}));
}

export async function loadHandrailHandles(){
    const handrailHandles: HandrailHandleAPI[] = await HandrailHandleAPI.retrieveAll();
    store.dispatch(setBasicInformation({handrailHandles}));
}

export async function loadThicknessRules(){
    const thicknessRules: ThicknessRuleAPI[] = await ThicknessRuleAPI.retrieveAll();
    store.dispatch(setBasicInformation({thicknessRules}));
}

export async function loadStaircasePlacementOptions(){
    const staircasePlacementOptions: StaircasePlacementOptionAPI[] = await StaircasePlacementOptionAPI.retrieveAll();
    store.dispatch(setBasicInformation({staircasePlacementOptions}));
}

export async function loadStaircaseShapes(){
    const staircaseShapes: StaircaseShapeAPI[] = await StaircaseShapeAPI.retrieveAll();
    store.dispatch(setBasicInformation({staircaseShapes}));
}

export async function loadRiserThickness(){
    const riserThickness: RiserThicknessAPI[] = await RiserThicknessAPI.retrieveAll();
    store.dispatch(setBasicInformation({riserThickness}));
}

export async function loadStringerThickness(){
    const stringerThickness: StringerThicknessAPI[] = await StringerThicknessAPI.retrieveAll();
    store.dispatch(setBasicInformation({stringerThickness}));
}

export async function loadTreadThickness(){
    const treadThickness: TreadThicknessAPI[] = await TreadThicknessAPI.retrieveAll();
    store.dispatch(setBasicInformation({treadThickness}));
}

export async function loadTreadSupplements(){
    const treadSupplements: SupplementTreadAPI[] = await SupplementTreadAPI.retrieveAll();
    store.dispatch(setBasicInformation({treadSupplements}));
}

export async function loadHandrailSupplements(){
    const handrailSupplements: SupplementHandrailAPI[] = await SupplementHandrailAPI.retrieveAll();
    store.dispatch(setBasicInformation({handrailSupplements}));
}

export async function loadLandingStageSupplements(){
    const landingStageSupplements: SupplementLandingStageAPI[] = await SupplementLandingStageAPI.retrieveAll();
    store.dispatch(setBasicInformation({landingStageSupplements}));
}

export async function loadPlacementSupplements(){
    const placementSupplements: PlacementSupplementAPI[] = await PlacementSupplementAPI.retrieveAll();
    store.dispatch(setBasicInformation({placementSupplements}));
}

export async function loadTreatmentTypes(){
    const treatmentTypes: TreatmentTypeAPI[] = await TreatmentTypeAPI.retrieveAll();
    store.dispatch(setBasicInformation({treatmentTypes}));
}

export async function loadHandrailTypes(){
    const handrailTypes: HandrailTypeAPI[] = await HandrailTypeAPI.retrieveAll();
    store.dispatch(setBasicInformation({handrailTypes}));
}

export async function loadLanguages(){ // TODO: Do we always want to load all languages? Should they be loaded here? Is general functionality, not only Hotec!
    await retrieveAllLanguages();
}
