import ShapeInput from "../articleinput/ShapeInput";
import { IQuantifiedSupplement } from "../IArticleInput";
import { IStaircaseShape } from "../staircasedata/StaircaseShape";
import { IThickness } from "../staircasedata/Thickness";
import { IDatabaseController } from "orbiter-core/src/databasecontroller/DatabaseController";
import { differingNumbers, differingStrings, differingSupplements, IArticleDiffLine, newDiffFalseLine, newDiffTrueLine } from "./ArticleInputDiff";
import ArticleInput from "../articleinput/ArticleInput";

export default class ShapeInputDiff{

    public closedStaircase: IArticleDiffLine<boolean> = newDiffFalseLine();
    public treadThickness: IArticleDiffLine<IThickness & IDatabaseController> =  newDiffFalseLine();
    public stringerThickness: IArticleDiffLine<IThickness & IDatabaseController> =  newDiffFalseLine();
    public riserThickness: IArticleDiffLine<IThickness & IDatabaseController> =  newDiffFalseLine();
    public riserCount: IArticleDiffLine<number | null> =  newDiffFalseLine();
    public staircaseShape: IArticleDiffLine<IStaircaseShape & IDatabaseController> =  newDiffFalseLine();
    public treadSupplements: IArticleDiffLine<IQuantifiedSupplement[]> =  newDiffFalseLine();

    readonly s1: ShapeInput;
    readonly s2: ShapeInput;

    constructor(readonly a1: ArticleInput, readonly a2: ArticleInput){
        this.s1 = a1.shape;
        this.s2 = a2.shape;
    }

    public async diff(){
        await this.diffRiserCount();
        await this.diffRiserThickness();
        await this.diffStaircaseShape();
        await this.diffStringerThickness();
        await this.diffTreadSupplements();
        await this.diffTreadThickness();
        await this.diffClosedStaircase();
    }

    public differs(): boolean{
        return this.riserCount.different
            || this.riserThickness.different
            || this.staircaseShape.different
            || this.stringerThickness.different
            || this.treadSupplements.different
            || this.treadThickness.different
            || this.closedStaircase.different;
    }

    private async diffRiserCount(){
        if(differingNumbers(this.s1.riserCount, this.s2.riserCount)){
            this.riserCount = newDiffTrueLine(
                this.s1.riserCount,
                this.s2.riserCount,
            )
        }
    }

    private async diffRiserThickness(){
        const rt1 = (this.s1.getRiserThickness());
        const rt2 = (this.s2.getRiserThickness());
        if(differingStrings(rt1?.getSid(), rt2?.getSid())){
            this.riserThickness = newDiffTrueLine(
                rt1,
                rt2,
            )
        }
    }

    private async diffStaircaseShape(){
        if(differingStrings(this.s1.staircaseShape?.getSid(), this.s2.staircaseShape?.getSid())){
            this.staircaseShape = newDiffTrueLine(
                this.s1.staircaseShape,
                this.s2.staircaseShape,
            )
        }
    }

    private async diffStringerThickness(){
        if(differingStrings(this.s1.stringerThickness?.getSid(), this.s2.stringerThickness?.getSid())){
            this.stringerThickness = newDiffTrueLine(
                this.s1.stringerThickness,
                this.s2.stringerThickness,
            )
        }
    }

    private async diffTreadSupplements(){
        if(differingSupplements(this.s1.treadSupplements, this.s2.treadSupplements)){
            this.treadSupplements = newDiffTrueLine(
                this.s1.treadSupplements,
                this.s2.treadSupplements,
            );
        }   
    }

    private async diffTreadThickness(){
        if(differingStrings(this.s1.treadThickness?.getSid(), this.s2.treadThickness?.getSid())){
            this.treadThickness = newDiffTrueLine(
                this.s1.treadThickness,
                this.s2.treadThickness,
            )
        }
    }

    private async diffClosedStaircase(){
        if(this.s1.closedStaircase !== this.s2.closedStaircase){
            this.closedStaircase = newDiffTrueLine(
                this.s1.closedStaircase,
                this.s2.closedStaircase,
            )
        }
    }

}