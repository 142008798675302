import * as React from "react";
import StepTemplate from "./StepTemplate";
import { t } from "ttag";
import { connect, DispatchProp } from "react-redux";
import { IQuoteCreationStateDispatch } from "../../reducers/quoteCreationReducer";
import styleable from "react-styleable";
import * as styles from "../../../css/pages/newquote/Step5.css";
import { IArticleCreationStateDispatch } from "../../reducers/articleCreationReducer";
import { AnyAction } from "redux";
import LoadingIcon from "./article/loaders/LoadingIcon";
import QuoteLog from "../../../../../shared/datastructures/QuoteLog";
import moment from 'moment';
import { quoteLogToString } from "../../helpers";

type props = { quoteCreation: IQuoteCreationStateDispatch, articleCreation: IArticleCreationStateDispatch, globalInformation: any, css: any } & DispatchProp<AnyAction>;

/**
 * Log page.
 *
 */
@(connect((store: any) => {
    return { quoteCreation: { ...store.quoteCreation }, articleCreation: { ...store.articleCreation }, globalInformation: {...store.globalInformation} };
}) as any)
class Step5 extends React.Component<props, {}> {

    public render() {
        return (
            <StepTemplate title={t`logboek.`}>
                <div className={"container-fluid"}>
                    <div className="row">
                        <div className="col-md-12">
                            {this.props.globalInformation.loading ? <LoadingIcon large={true} subtitle={t`Deze offerte wordt bijgewerkt. Even geduld.`} /> : this.renderOverview()}
                        </div>
                    </div>
                </div>
            </StepTemplate>
        );
    }

    private renderOverview() {
        try {

            const logs = this.props.quoteCreation.logs;

            return <div className={this.props.css.outer}>
                <table className={this.props.css.fullWidthTable}>
                    <thead>
                        <tr>
                            <th>{t`Datum`}</th>
                            <th>{t`Gebruiker`}</th>
                            <th>{t`Actie`}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {logs.map((a, i) => this.renderRow(i, a))}
                    </tbody>
                </table>
            </div>

        } catch (e) {
            console.error(e);
            return <p>Something went wrong.</p>;
        }
    }

    private renderRow(i: number, log: QuoteLog){
        return <tr key={i}>
            <td>{moment(log.getTimestamp()).format('DD/MM/YYYY HH:mm')}</td>
            <td>{log.userName}</td>
            <td>{quoteLogToString(log)}</td>
        </tr>
    }

}
export default styleable(styles)(Step5);
