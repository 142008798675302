import ObjectID from "bson-objectid";
import { dt } from "webc-reactcore/src/js/stores/GlobalStore";
import SpindleThreadTypeAPI from "../../../../apicontroller/staircasedata/SpindleThreadTypeAPI";

export function spindleThreadTypesToSelectItems(availableSpindleThreadTypes: SpindleThreadTypeAPI[], allowedSpindleThreadTypes: ObjectID[]) {
    const list = [];
    for (const spindleThreadType of availableSpindleThreadTypes) {
        if(allowedSpindleThreadTypes.findIndex(x => x.toHexString() == spindleThreadType.getId().toHexString()) !== -1){
            list.push(
                {
                    id: spindleThreadType.getSid(),
                    title: dt(spindleThreadType.getTitle()),
                    type: spindleThreadType,
                }
            );
        }
    }
    return list.sort((a, b) => a.title < b.title ? -1 : 1);
}