import { t } from "ttag";
import { DELIVERY, PICKUP, PLACEMENT } from "../../../shared/datastructures/articleinput/TransportationInput";
import store from "./store";
import {ROOT} from "../../../shared/permissions";
import QuoteLog, { EMAIL_SENT, PDF_GENERATED, QUOTE_CREATED, STATE_UPDATED } from "../../../shared/datastructures/QuoteLog";
import React from "react";

export function transportationMethodToString(input: string): string{
    switch (input) {
        case PLACEMENT: {
            return t`Plaatsing`;
        }
        case DELIVERY: {
            return t`Levering`;
        }
        case PICKUP: {
            return t`Afhaling`;
        }
    }
    return "ERROR";
}

export function quoteStateToLocale(state: string): string{
    switch (state) {
        case "QUOTE_DRAFT":
            return t`Offerte aangemaakt`;
        case "QUOTE_SENT":
            return t`Offerte verzonden`;
        case "QUOTE_CONFIRMED":
            return t`Offerte bevestigd`;
        case "ORDER_DRAFT":
            return t`Order aangemaakt`;
        case "ORDER_SENT":
            return t`Order verzonden`;
        case "ORDER_CONFIRMED":
            return t`Order bevestigd`;
        default:
            break;
    }
    return "";
}

export function quoteLogToString(quoteLog: QuoteLog): React.ReactNode{
    switch (quoteLog.getLoggedAction()) {
        case QUOTE_CREATED:
            return t`Offerte aangemaakt`;
        case PDF_GENERATED:
            if(quoteLog.getMetadata()?.id)
                return <a href={`/api/hotec/distributor/files/direct-download/${quoteLog.getMetadata().id}`}>{t`Pdf gegenereerd`}</a>;
            else
                return t`Pdf gegenereerd`;
        case EMAIL_SENT:
            if(quoteLog.getMetadata()?.id)
                return <a href={`/api/hotec/distributor/files/direct-download/${quoteLog.getMetadata().id}`}>{t`E-mail verzonden`}</a>;
            else
                return t`E-mail verzonden`;
        case STATE_UPDATED:
            return t`Status bijgewerkt naar "${quoteStateToLocale(quoteLog.getMetadata())}"`;
        default:
            break;
    }
    return "";
}

export function hasMatchingPermission(...allowList: string[]){
    allowList = [ROOT, ...allowList];
    const availablePermissions: string[] = store.getState().globalInformation.permissionStrings;
    for (let i = 0; i < availablePermissions.length; i++) {
        const permission = availablePermissions[i];
        if(allowList.indexOf(permission) > -1)
            return true;
    }
    return false;
}

export const MEASUREMENT_BY_HOTEC_TEXT = t`Opmeting dient door Hotec uitgevoerd te worden.`;
export const MEASUREMENT_BY_CUSTOMER_TEXT = t`Maten worden door klant aangeleverd.`;
export const ASSEMBLED_BY_HOTEC_FOR_DELIVERY_TEXT = t`Trap wordt gemonteerd geleverd.`;
export const ASSEMBLED_BY_CUSTOMER_FOR_DELIVERY_TEXT = t`Trap wordt in delen geleverd.`;
export const ASSEMBLED_BY_HOTEC_FOR_PICKUP_TEXT = t`Trap wordt gemonteerd afgehaald.`;
export const ASSEMBLED_BY_CUSTOMER_FOR_PICKUP_TEXT = t`Trap wordt in delen afgehaald.`;