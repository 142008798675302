import * as React from "react";
import { inun, inu, toNumber } from "orbiter-core/src/basic";
import { connect } from "react-redux";
import PortalCollapsibleLanguageField from "webc-reactcore/src/js/components/mainlayout/PortalCollapsibleLanguageField";
import StaircaseTypeAPI from "../../apicontroller/staircasedata/StaircaseTypeAPI";
import { loadStaircaseTypes } from "../../loader";
import { dt } from "webc-reactcore/src/js/stores/GlobalStore";
import { t } from 'ttag';
import { toSelectItems, createTitlePortalCollapsibleLanguageField, createDescriptionPortalCollapsibleLanguageField, createApiTitlePortalCollapsibleLanguageField, createApiDescriptionPortalCollapsibleLanguageField } from "./helper";
import StaircaseType from "../../../../../shared/datastructures/staircasedata/StaircaseType";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import SimpleSettingsTemplate from "./SimpleSettingsTemplate";
import ConfigBlock from "../../components/ConfigBlock";
import MappedInput from "webc-reactcore/src/js/components/mainlayout/MappedInput";
import InfoScreen from "../../components/InfoScreen";

@(connect((store: any) => {
    return { settings: { ...store.settings }, globalInformation: { ...store.globalInformation } };
}) as any)
export default class StaircaseTypeSettings extends React.Component<any, any> {

    public staircaseTypesToSelectItems() {
        return toSelectItems<StaircaseTypeAPI>("staircasetype", this.props.globalInformation.staircaseTypes.sort((x, y) => dt(x.getTitle()) < dt(y.getTitle()) ? -1 : 1), (x) => StaircaseTypeAPI.clone(x), (x) => dt(x.getTitle()));
    }

    private async createEmpty(): Promise<StaircaseType> {
        return new StaircaseType(0, 0, 0, new MultiLanguageString(), new MultiLanguageString(), 0);
    }

    private async create(data: StaircaseType) {
        await StaircaseTypeAPI.create(data);
    }

    public render() {
        return <SimpleSettingsTemplate
            title="Trap types"
            selectItems={this.staircaseTypesToSelectItems()}

            reload={loadStaircaseTypes.bind(this)}
            createEmpty={this.createEmpty.bind(this)}
            create={this.create.bind(this)}
            clone={(i: StaircaseTypeAPI) => StaircaseTypeAPI.clone(i)}

            renderEdit={this.renderEdit.bind(this)}
            renderCreate={this.renderCreate.bind(this)}

            infoScreen={<InfoScreen title={t`Selecteer een traptype.`} image={"/cdn/images/staircase.svg"}/>}
        />;
    }


    private renderEdit(a: StaircaseTypeAPI, setSelected: (i: StaircaseTypeAPI) => void) {
        return this.doRender(
            createApiTitlePortalCollapsibleLanguageField(a, (x) => StaircaseTypeAPI.clone(x), setSelected.bind(this)),
            createApiDescriptionPortalCollapsibleLanguageField(a, (x) => StaircaseTypeAPI.clone(x), setSelected.bind(this)),
            <MappedInput placeholder={t`Prijsfactor`}
                onBlur={(price) => {
                    const cl: StaircaseTypeAPI = StaircaseTypeAPI.clone(a);
                    try{
                        cl.getData().setTreadPrice(toNumber(price));
                    }catch(e){
                        cl.getData().setTreadPrice(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getTreadPrice(), () => "")} />,
            <MappedInput placeholder={t`Prijsfactor`}
                onBlur={(price) => {
                    const cl: StaircaseTypeAPI = StaircaseTypeAPI.clone(a);
                    try{
                        cl.getData().setHandrailPrice(toNumber(price));
                    }catch(e){
                        cl.getData().setHandrailPrice(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getHandrailPrice(), () => "")} />,
            <MappedInput placeholder={t`Prijs`}
                onBlur={(price) => {
                    const cl: StaircaseTypeAPI = StaircaseTypeAPI.clone(a);
                    try{
                        cl.getData().setInstallationFactor(toNumber(price));
                    }catch(e){
                        cl.getData().setInstallationFactor(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getInstallationFactor(), () => "")} />,
            <MappedInput placeholder={t`Aantal palen`}
                onBlur={(factor) => {
                    const cl: StaircaseTypeAPI = StaircaseTypeAPI.clone(a);
                    try {
                        cl.getData().setNumberOfPoles(parseInt(factor));
                    } catch (e) {
                        cl.getData().setNumberOfPoles(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getNumberOfPoles(), () => "")} />,
        );
    }

    private renderCreate(a: StaircaseType, setSelected: (i: StaircaseType) => void) {
        return this.doRender(
            createTitlePortalCollapsibleLanguageField(a, setSelected.bind(this)),
            createDescriptionPortalCollapsibleLanguageField(a, setSelected.bind(this)),
            <MappedInput placeholder={t`Prijsfactor`}
                onBlur={(price) => {
                    const cl: StaircaseType = a.clone();
                    try{
                        cl.setTreadPrice(toNumber(price));
                    }catch(e){
                        cl.setTreadPrice(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getTreadPrice(), () => "")} />,
            <MappedInput placeholder={t`Prijsfactor`}
                onBlur={(price) => {
                    const cl: StaircaseType = a.clone();
                    try{
                        cl.setHandrailPrice(toNumber(price));
                    }catch(e){
                        cl.setHandrailPrice(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getHandrailPrice(), () => "")} />,
            <MappedInput placeholder={t`Prijs`}
                onBlur={(price) => {
                    const cl: StaircaseType = a.clone();
                    try{
                        cl.setInstallationFactor(toNumber(price));
                    }catch(e){
                        cl.setInstallationFactor(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getInstallationFactor(), () => "")} />,
            <MappedInput placeholder={t`Aantal palen`}
                onBlur={(factor) => {
                    const cl: StaircaseType = a.clone();
                    try {
                        cl.setNumberOfPoles(parseInt(factor));
                    } catch (e) {
                        cl.setNumberOfPoles(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getNumberOfPoles(), () => "")} />,
        );
    }

    private doRender(
        titlePCLF: React.ReactNode,
        descriptionPCLF: React.ReactNode,
        treadPriceMI: React.ReactNode,
        handrailPriceMI: React.ReactNode,
        installationPriceMI: React.ReactNode,
        poleCountMI: React.ReactNode,
    ): React.ReactNode {
        return <div className={"container-fluid"}>
            <div className="row">
                <div className="col-md-6">
                    <ConfigBlock
                        title="Titel"
                        description="Geef een titel in voor dit traptype."
                        topMost={true}
                    >
                        {titlePCLF}
                    </ConfigBlock>

                    <ConfigBlock
                        title={t`Trede prijsfactor`}
                        description="Geef de prijsfactor voor tredes in."
                        topMost={false}
                    >
                        {treadPriceMI}
                    </ConfigBlock>

                    <ConfigBlock
                        title={t`Installatieprijs`}
                        description="Geef de installatieprijs in."
                        topMost={false}
                    >
                        {installationPriceMI}
                    </ConfigBlock>
                </div>
                <div className="col-md-6">
                    <ConfigBlock
                        title="Omschrijving"
                        description="Geef een omschrijving in voor dit traptype."
                        topMost={true}
                    >
                        {descriptionPCLF}
                    </ConfigBlock>

                    <ConfigBlock
                        title={t`Leuning prijsfactor`}
                        description="Geef de prijsfactor voor leuning in."
                        topMost={false}
                    >
                        {handrailPriceMI}
                    </ConfigBlock>

                    <ConfigBlock
                        title="Aantal palen"
                        description="Geef het aantal palen voor dit traptype in. Dit aantal wordt enkel toegepast wanneer er een leuning geselecteerd is."
                        topMost={false}
                    >
                        {poleCountMI}
                    </ConfigBlock>
                </div>
            </div>
        </div>;
    }

}
