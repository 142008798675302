import * as React from "react";
import { inu, inun, toNumber } from "orbiter-core/src/basic";
import { connect } from "react-redux";
import PortalCheckbox from "webc-reactcore/src/js/components/mainlayout/PortalCheckbox";
import * as PortalCheckboxStyle from "../../../css/PortalCheckbox.css";
import WoodTypeAPI from "../../apicontroller/staircasedata/WoodTypeAPI";
import { loadWoodTypes } from "../../loader";
import { t } from 'ttag';
import { dt } from "webc-reactcore/src/js/stores/GlobalStore";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import ConfigBlock from "../../components/ConfigBlock";
import { toSelectItems, createTitlePortalCollapsibleLanguageField, createApiTitlePortalCollapsibleLanguageField, createApiDescriptionPortalCollapsibleLanguageField, createDescriptionPortalCollapsibleLanguageField, toObjectSelectItems, createPortalCollapsibleLanguageField, createApiPortalCollapsibleLanguageField } from "./helper";
import SimpleSettingsTemplate from "./SimpleSettingsTemplate";
import WoodType from "../../../../../shared/datastructures/staircasedata/WoodType";
import MappedInput from "webc-reactcore/src/js/components/mainlayout/MappedInput";
import InfoScreen from "../../components/InfoScreen";
import { RiserThicknessAPI } from "../../apicontroller/staircasedata/ThicknessAPI";
import PortalDropdown from "webc-reactcore/src/js/components/mainlayout/PortalDropdown";
import * as PortalDropdownStyle from "../../../css/PortalDropdown.css";

@(connect((store: any) => {
    return { settings: { ...store.settings }, globalInformation: { ...store.globalInformation } };
}) as any)
export default class Wood extends React.Component<any, any> {

    public riserThicknessToSelectItems() {
        return toObjectSelectItems<RiserThicknessAPI>(this.props.globalInformation.riserThickness.sort((x: RiserThicknessAPI, y: RiserThicknessAPI) => dt(x.getTitle()) < dt(y.getTitle()) ? -1 : 1), (x) => RiserThicknessAPI.clone(x), (x) => dt(x.getTitle()));
    }

    public woodTypesToSelectItems() {
        return toSelectItems<WoodTypeAPI>("woodtypespselect", this.props.globalInformation.woodTypes.sort((x, y) => dt(x.getTitle()) < dt(y.getTitle()) ? -1 : 1), (x) => WoodTypeAPI.clone(x), (x) => dt(x.getTitle()));
    }

    private async createEmpty(): Promise<WoodType> {
        return new WoodType(0, false, false, false, new MultiLanguageString(), new MultiLanguageString(), new MultiLanguageString(), null);
    }

    private async create(data: WoodType) {
        await WoodTypeAPI.create(data);
    }

    public render() {

        return <SimpleSettingsTemplate
            title={t`materialen.`}
            selectItems={this.woodTypesToSelectItems()}

            reload={loadWoodTypes.bind(this)}
            createEmpty={this.createEmpty.bind(this)}
            create={this.create.bind(this)}
            clone={(i: WoodTypeAPI) => WoodTypeAPI.clone(i)}

            renderEdit={this.renderEdit.bind(this)}
            renderCreate={this.renderCreate.bind(this)}

            infoScreen={<InfoScreen title={t`Selecteer een materiaaltype.`} image={"/cdn/images/log.svg"}/>}
        />;

    }

    private renderEdit(a: WoodTypeAPI, setSelected: (i: WoodTypeAPI) => void) {

        return this.doRender(
            createApiTitlePortalCollapsibleLanguageField(a, (x) => WoodTypeAPI.clone(x), setSelected.bind(this)),
            <MappedInput placeholder={t`Prijsfactor`} onBlur={(price) => {
                const cl: WoodTypeAPI = WoodTypeAPI.clone(a);
                try{
                    cl.getData().setPrice(toNumber(price));
                }catch(e){
                    cl.getData().setPrice(0);
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getPrice(), () => "")} />,
            <PortalCheckbox css={{ compose: PortalCheckboxStyle }} value={inu(a, (a) => a.isMultiplex(), () => "")} onChange={(checked) => {
                const cl: WoodTypeAPI = WoodTypeAPI.clone(a);
                if(cl.getData().isMultiplex() !== checked){
                    cl.getData().setMultiplex(checked);
                    setSelected(cl);
                }
            }}>{t`Multiplex`}</PortalCheckbox>,
            createApiDescriptionPortalCollapsibleLanguageField(a, (x) => WoodTypeAPI.clone(x), setSelected.bind(this)),
            createApiPortalCollapsibleLanguageField(t`Omschrijving voor leuning`, a, (x) => WoodTypeAPI.clone(x), setSelected.bind(this), (x: WoodTypeAPI) => x.getHandrailDescription(), (x: WoodTypeAPI, v: MultiLanguageString) => x.getData().setHandrailDescription(v)),
            <PortalCheckbox css={{ compose: PortalCheckboxStyle }} value={inu(a, (a) => a.isAvailableForEnclosure(), () => "")} onChange={(checked) => {
                const cl: WoodTypeAPI = WoodTypeAPI.clone(a);
                if(cl.getData().isAvailableForEnclosure() !== checked){
                    cl.getData().setAvailableForEnclosure(checked);
                    setSelected(cl);
                }
            }}>{t`Beschikbaar voor afkasting`}</PortalCheckbox>,
            <PortalCheckbox css={{ compose: PortalCheckboxStyle }} value={inu(a, (a) => a.isAvailableForRisers(), () => "")} onChange={(checked) => {
                const cl: WoodTypeAPI = WoodTypeAPI.clone(a);
                if(cl.getData().isAvailableForRisers() !== checked){
                    cl.getData().setAvailableForRisers(checked);
                    setSelected(cl);
                }
            }}>{t`Beschikbaar voor tegentredes`}</PortalCheckbox>,
            <PortalDropdown placeholder={t`Standaard minimale dikte tegentredes`}
                css={{ compose: PortalDropdownStyle }}
                items={this.riserThicknessToSelectItems()}
                onSelectionChange={async (data) => {
                    const cl: WoodTypeAPI = WoodTypeAPI.clone(a);
                    if(data){
                        cl.getData().setDefaultRiserThicknessId((data.item as RiserThicknessAPI).getId());
                    }else{
                        cl.getData().setDefaultRiserThicknessId(null);
                    }
                    setSelected(cl);
                }}
                selectedId={inun(a, (a) => a.getDefaultRiserThicknessId() ? a.getDefaultRiserThicknessId().toHexString() : null, () => null)}
            />,
        );

    }

    private renderCreate(a: WoodType, setSelected: (i: WoodType) => void) {
        return this.doRender(
            createTitlePortalCollapsibleLanguageField(a, setSelected.bind(this)),
            <MappedInput placeholder={t`Prijsfactor`}
                onBlur={(price) => {
                    const cl: WoodType = a.clone();
                    try{
                        cl.setPrice(toNumber(price));
                    }catch(e){
                        cl.setPrice(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getPrice(), () => "")} />,
            <PortalCheckbox css={{ compose: PortalCheckboxStyle }} value={inu(a, (a) => a.isMultiplex(), () => "")} onChange={(checked) => {
                const cl: WoodType = a.clone();
                if(cl.isMultiplex() !== checked){
                    cl.setMultiplex(checked);
                    setSelected(cl);
                }
            }}>{t`Multiplex`}</PortalCheckbox>,
            createDescriptionPortalCollapsibleLanguageField(a, setSelected.bind(this)),
            createPortalCollapsibleLanguageField(t`Omschrijving voor leuning`, a, setSelected.bind(this), (x: WoodType) => x.getHandrailDescription(), (x: WoodType, v: MultiLanguageString) => x.setHandrailDescription(v)),
            <PortalCheckbox css={{ compose: PortalCheckboxStyle }} value={inu(a, (a) => a.isAvailableForEnclosure(), () => "")} onChange={(checked) => {
                const cl: WoodType = a.clone();
                if(cl.isAvailableForEnclosure() !== checked){
                    cl.setAvailableForEnclosure(checked);
                    setSelected(cl);
                }
            }}>{t`Beschikbaar voor afkasting`}</PortalCheckbox>,
            <PortalCheckbox css={{ compose: PortalCheckboxStyle }} value={inu(a, (a) => a.isAvailableForRisers(), () => "")} onChange={(checked) => {
                const cl: WoodType = a.clone();
                if(cl.isAvailableForRisers() !== checked){
                    cl.setAvailableForRisers(checked);
                    setSelected(cl);
                }
            }}>{t`Beschikbaar voor tegentredes`}</PortalCheckbox>,
            <PortalDropdown placeholder={t`Standaard minimale dikte tegentredes`}
                css={{ compose: PortalDropdownStyle }}
                items={this.riserThicknessToSelectItems()}
                onSelectionChange={async (data) => {
                    const cl: WoodType = a.clone();
                    if(data){
                        cl.setDefaultRiserThicknessId((data.item as RiserThicknessAPI).getId());
                    }else{
                        cl.setDefaultRiserThicknessId(null);
                    }
                    setSelected(cl);
                }}
                selectedId={inun(a, (a) => a.getDefaultRiserThicknessId() ? a.getDefaultRiserThicknessId().toHexString() : null, () => null)}
            />,
        );
    }

    private doRender(
        titlePCLF: React.ReactNode,
        priceFactorInput: React.ReactNode,
        multiplexPCB: React.ReactNode,
        descriptionPCLF: React.ReactNode,
        handrailDescriptionPCLF: React.ReactNode,
        availableForEnclosurePCB: React.ReactNode,
        availableForRisersPCB: React.ReactNode,
        defaultRiserThicknessSelection: React.ReactNode,
    ) {
        return (<div className={"container-fluid"}>
            <div className="row">
                <div className="col-md-6">
                    <ConfigBlock
                        title="Titel"
                        description="Geef een titel in voor deze houtsoort."
                        topMost={true}
                    >
                        {titlePCLF}
                    </ConfigBlock>

                    <ConfigBlock
                        title="Prijsfactor"
                        description="Geef de prijsfactor in die bij deze houtsoort hoort."
                        topMost={false}
                    >
                        {priceFactorInput}
                    </ConfigBlock>

                    <ConfigBlock
                        title="Multiplex"
                        description="Is dit een multiplex materiaal?"
                        topMost={false}
                    >
                        {multiplexPCB}
                    </ConfigBlock>

                    <ConfigBlock
                        title="Standaard minimale dikte tegentredes"
                        description="Wanneer aan geen enkele regel voor minimale dikte voldaan wordt, wordt de minimale dikte voor tegentredes bepaald door het materiaaltype. Deze minimale dikte wordt hier aangegeven."
                        topMost={false}
                    >
                        {defaultRiserThicknessSelection}
                    </ConfigBlock>
                </div>
                <div className="col-md-6">

                    <ConfigBlock
                        title="Omschrijving"
                        description="Geef een omschrijving in voor deze houtsoort."
                        topMost={true}
                    >
                        {descriptionPCLF}
                    </ConfigBlock>

                    <ConfigBlock
                        title="Omschrijving voor leuning"
                        description="Geef een omschrijving in voor deze houtsoort die gebruikt wordt bij de leuning."
                        topMost={false}
                    >
                        {handrailDescriptionPCLF}
                    </ConfigBlock>

                    <ConfigBlock
                        title="Afkasting"
                        description="Is dit materiaal beschikbaar voor afkasting?"
                        topMost={false}
                    >
                        {availableForEnclosurePCB}
                    </ConfigBlock>

                    <ConfigBlock
                        title="Tegentredes"
                        description="Is dit materiaal beschikbaar voor tegentredes?"
                        topMost={false}
                    >
                        {availableForRisersPCB}
                    </ConfigBlock>

                </div>
            </div>
        </div>);
    }
}
