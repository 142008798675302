exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".YLSpuWbqzUbMSiVpgG9oU{\n    width: 100%;\n    padding: 20px;\n    background: #FFFFFF;\n    box-shadow: 0 0 19px 7px rgba(0,0,0,0.14);\n    border-radius: 8px;\n    min-height: 100px;\n    /* margin-bottom: 20px; */\n}", ""]);

// exports
exports.locals = {
	"container": "YLSpuWbqzUbMSiVpgG9oU"
};