exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2IF25z47LocypkYNfyWPKF p{\n    font-weight: 300;\n}\n._2IF25z47LocypkYNfyWPKF:hover{\n    background-color: #F5F5F5;\n    color: black;\n}\n._2CG5E4IaZ6bRzuykejShSJ{\n    color: black !important;\n    border-color: #FFCB04;\n}", ""]);

// exports
exports.locals = {
	"item": "_2IF25z47LocypkYNfyWPKF",
	"active": "_2CG5E4IaZ6bRzuykejShSJ"
};