import { IQuantifiedSupplement } from "../IArticleInput";
import { IDatabaseController } from "orbiter-core/src/databasecontroller/DatabaseController";
import { differingDBControllerList, differingStrings, differingSupplements, IArticleDiffLine, newDiffFalseLine, newDiffTrueLine } from "./ArticleInputDiff";
import { IHandrailHandle } from "../staircasedata/HandrailHandle";
import { IHandrailPoleFinish } from "../staircasedata/HandrailPoleFinish";
import { IHandrailPoleType } from "../staircasedata/HandrailPoleType";
import { IHandrailType } from "../staircasedata/HandrailType";
import { ISpindleThreadType } from "../staircasedata/SpindleThreadType";
import HandrailInput from "../articleinput/HandrailInput";

export default class HandrailInputDiff{
    
    public main: IArticleDiffLine<HandrailInput> = newDiffFalseLine();

    public type: IArticleDiffLine<IHandrailType & IDatabaseController> = newDiffFalseLine();
    public handle: IArticleDiffLine<IHandrailHandle & IDatabaseController> = newDiffFalseLine();
    public spindleThreadType: IArticleDiffLine<ISpindleThreadType & IDatabaseController> = newDiffFalseLine();
    public spindleThreadDescription: IArticleDiffLine<string> = newDiffFalseLine();
    public supplements: IArticleDiffLine<IQuantifiedSupplement[]> = newDiffFalseLine();
    public handrailPoleTypes: IArticleDiffLine<(IHandrailPoleType & IDatabaseController)[]> = newDiffFalseLine();
    public handrailPoleFinishes: IArticleDiffLine<(IHandrailPoleFinish & IDatabaseController)[]> = newDiffFalseLine();

    constructor(readonly left: HandrailInput, readonly right: HandrailInput){}

    public async diff(){
        if(this.left && this.right){
            // Both defined
            await this.diffType();
            await this.diffHandle();
            await this.diffSpindleThreadType();
            await this.diffSpindleThreadDescription();
            await this.diffSupplements();
            await this.diffHandrailPoleTypes();
            await this.diffHandrailPoleFinishes();
        }else if(this.left && !this.right){
            // Was defined, not anymore
            this.main = newDiffTrueLine(this.left, this.right);
            this.type = newDiffTrueLine(this.left.type, null);
            this.handle = newDiffTrueLine(this.left.handle, null);
            this.spindleThreadType = newDiffTrueLine(this.left.spindleThreadType, null);
            this.spindleThreadDescription = newDiffTrueLine(this.left.spindleThreadDescription, null);
            this.supplements = newDiffTrueLine(this.left.supplements, null);
            this.handrailPoleTypes = newDiffTrueLine(this.left.handrailPoleTypes, null);
            this.handrailPoleFinishes = newDiffTrueLine(this.left.handrailPoleFinishes, null);
        }else if(!this.left && this.right){
            // Was not defined, is now
            this.main = newDiffTrueLine(this.left, this.right);
            this.type = newDiffTrueLine(null, this.right.type);
            this.handle = newDiffTrueLine(null, this.right.handle);
            this.spindleThreadType = newDiffTrueLine(null, this.right.spindleThreadType);
            this.spindleThreadDescription = newDiffTrueLine(null, this.right.spindleThreadDescription);
            this.supplements = newDiffTrueLine(null, this.right.supplements);
            this.handrailPoleTypes = newDiffTrueLine(null, this.right.handrailPoleTypes);
            this.handrailPoleFinishes = newDiffTrueLine(null, this.right.handrailPoleFinishes);
        }
    }

    public differs(): boolean{
        return this.type.different
            || this.handle.different
            || this.spindleThreadType.different
            || this.spindleThreadDescription.different
            || this.supplements.different
            || this.handrailPoleTypes.different
            || this.handrailPoleFinishes.different
            || this.main.different;
    }

    private async diffType(){
        if(differingStrings(this.left.type?.getSid(), this.right.type?.getSid())){
            this.type = newDiffTrueLine(
                this.left.type,
                this.right.type,
            )
        }
    }

    private async diffHandle(){
        if(differingStrings(this.left.handle?.getSid(), this.right.handle?.getSid())){
            this.handle = newDiffTrueLine(
                this.left.handle,
                this.right.handle,
            )
        }
    }

    private async diffSpindleThreadType(){
        if(differingStrings(this.left.spindleThreadType?.getSid(), this.right.spindleThreadType?.getSid())){
            this.spindleThreadType = newDiffTrueLine(
                this.left.spindleThreadType,
                this.right.spindleThreadType,
            )
        }
    }

    private async diffSpindleThreadDescription(){
        if(differingStrings(this.left.spindleThreadDescription, this.right.spindleThreadDescription)){
            this.spindleThreadDescription = newDiffTrueLine(
                this.left.spindleThreadDescription,
                this.right.spindleThreadDescription,
            )
        }
    }

    private async diffSupplements(){
        if(differingSupplements(this.left.supplements, this.right.supplements)){
            this.supplements = newDiffTrueLine(
                this.left.supplements,
                this.right.supplements,
            );
        }   
    }

    private async diffHandrailPoleTypes(){
        if(differingDBControllerList(this.left.handrailPoleTypes, this.right.handrailPoleTypes)){
            this.handrailPoleTypes = newDiffTrueLine(
                this.left.handrailPoleTypes,
                this.right.handrailPoleTypes,
            );
        }
    }

    private async diffHandrailPoleFinishes(){
        if(differingDBControllerList(this.left.handrailPoleFinishes, this.right.handrailPoleFinishes)){
            this.handrailPoleFinishes = newDiffTrueLine(
                this.left.handrailPoleFinishes,
                this.right.handrailPoleFinishes,
            );
        }   
    }

}