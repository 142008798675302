import ObjectID from "bson-objectid";
import {isValidBoolean, isValidEmail} from "orbiter-core/src/basic";
import DataController from "orbiter-core/src/datastructures/DataController";
import {IUser} from "orbiter-core/src/datastructures/users/User";
import {EMAIL_EXCEPTION} from "orbiter-core/src/exceptions";
import { INVALID_VALUE } from "./articleinput/inputExceptions";
import { tAddress } from "./simplifiedNetworkCommunicationTypes";

export interface IDistributor extends DataController {

    getName(): string;
    getLogoId(): ObjectID | null;
    getAdministrationName(): string;
    getAdministrationEmail(): string;
    getAddress(): tAddress;
    getTelephone(): string;
    getVatNumber(): string;
    getBankAccounts(): string[];
    getPreface(): string;
    getClosing(): string;
    getConditions(): string;
    getOrderPreface(): string;
    getOrderClosing(): string;
    getOrderConditions(): string;
    getQuoteEmailTemplate(): string;
    getOrderEmailTemplate(): string;
    getUsers(): IUser[];
    getDistributorProperties(): {};
    getHotecMarginStaircase(): number;
    getHotecMarginTreatment(): number;
    getHotecMarginDistribution(): number;
    getHotecDiscountStaircase(): number;
    getHotecDiscountTreatment(): number;
    getHotecDiscountDistribution(): number;
    getDealerMarginStaircase(): number;
    getDealerMarginTreatment(): number;
    getDealerMarginDistribution(): number;
    shouldApplyGlobalMarginForDealers(): boolean;
    shouldApplyHotecMargin(): boolean;
    shouldApplyDealerMargin(): boolean;

    setName(name: string): void | Promise<void>;
    setLogoId(id: ObjectID | null): void | Promise<void>;
    setAdministrationName(administrationName: string): void | Promise<void>;
    setAdministrationEmail(administrationEmail: string): void | Promise<void>;
    setAddress(address: tAddress): void | Promise<void>;
    setTelephone(telephone: string): void | Promise<void>;
    setVatNumber(vatNumber: string): void | Promise<void>;
    setBankAccounts(bankAccounts: string[]): void | Promise<void>;
    setPreface(preface: string): void | Promise<void>;
    setClosing(closing: string): void | Promise<void>;
    setConditions(conditions: string): void | Promise<void>;
    setOrderPreface(preface: string): void | Promise<void>;
    setOrderClosing(closing: string): void | Promise<void>;
    setOrderConditions(conditions: string): void | Promise<void>;
    setQuoteEmailTemplate(template: string): void | Promise<void>;
    setOrderEmailTemplate(template: string): void | Promise<void>;
    setDistributorProperties(properties: {}): void | Promise<void>;
    setHotecMarginStaircase(v:number): void | Promise<void>;
    setHotecMarginTreatment(v:number): void | Promise<void>;
    setHotecMarginDistribution(v:number): void | Promise<void>;
    setHotecDiscountStaircase(v:number): void | Promise<void>;
    setHotecDiscountTreatment(v:number): void | Promise<void>;
    setHotecDiscountDistribution(v:number): void | Promise<void>;
    setDealerMarginStaircase(v:number): void | Promise<void>;
    setDealerMarginTreatment(v:number): void | Promise<void>;
    setDealerMarginDistribution(v:number): void | Promise<void>;
    setApplyGlobalMarginForDealers(v:boolean): void | Promise<void>;
    setApplyHotecMargin(v:boolean): void | Promise<void>;
    setApplyDealerMargin(v:boolean): void | Promise<void>;
}

export default class Distributor<UserType extends IUser> extends DataController implements IDistributor {

    @DataController.dataProperty()
    private name: string;
    @DataController.dataProperty()
    private logoId: ObjectID | null;
    @DataController.dataProperty()
    private administrationName: string;
    @DataController.dataProperty()
    private administrationEmail: string;
    @DataController.dataProperty()
    private address: tAddress;
    @DataController.dataProperty()
    private telephone: string;
    @DataController.dataProperty()
    private vatNumber: string;
    @DataController.dataProperty()
    private bankAccounts: string[];
    @DataController.dataProperty()
    private preface: string;
    @DataController.dataProperty()
    private closing: string;
    @DataController.dataProperty()
    private conditions: string;
    @DataController.dataProperty()
    private orderPreface: string;
    @DataController.dataProperty()
    private orderClosing: string;
    @DataController.dataProperty()
    private orderConditions: string;
    @DataController.dataProperty()
    private quoteEmailTemplate: string;
    @DataController.dataProperty()
    private orderEmailTemplate: string;
    @DataController.dataProperty(async (x) => await Promise.all(x.map(async (u) => await u.asDict())))
    private users: UserType[] = [];
    @DataController.dataProperty()
    private distributorProperties: {};

    @DataController.dataProperty()
    private hotecMarginStaircase: number;
    @DataController.dataProperty()
    private hotecMarginTreatment: number;
    @DataController.dataProperty()
    private hotecMarginDistribution: number;
    @DataController.dataProperty()
    private hotecDiscountStaircase: number;
    @DataController.dataProperty()
    private hotecDiscountTreatment: number;
    @DataController.dataProperty()
    private hotecDiscountDistribution: number;
    @DataController.dataProperty()
    private dealerMarginStaircase: number;
    @DataController.dataProperty()
    private dealerMarginTreatment: number;
    @DataController.dataProperty()
    private dealerMarginDistribution: number;
    @DataController.dataProperty()
    private applyGlobalMarginForDealers: boolean;
    @DataController.dataProperty()
    private applyHotecMargin: boolean;
    @DataController.dataProperty()
    private applyDealerMargin: boolean;
    @DataController.dataProperty()
    private quoteNumberPrefix: string;

    public constructor(
        name: string,
        logoId: ObjectID | null,
        administrationName: string,
        administrationEmail: string,
        address: tAddress,
        telephone: string,
        vatNumber: string,
        bankAccounts: string[],
        preface: string,
        closing: string,
        conditions: string,
        orderPreface: string,
        orderClosing: string,
        orderConditions: string,
        quoteEmailTemplate: string,
        orderEmailTemplate: string,
        users: UserType[],
        distributorProperties: {},
        hotecMarginStaircase: number,
        hotecMarginTreatment: number,
        hotecMarginDistribution: number,
        hotecDiscountStaircase: number,
        hotecDiscountTreatment: number,
        hotecDiscountDistribution: number,
        dealerMarginStaircase: number,
        dealerMarginTreatment: number,
        dealerMarginDistribution: number,
        applyGlobalMarginForDealers: boolean,
        applyHotecMargin: boolean,
        applyDealerMargin: boolean,
        quoteNumberPrefix: string,
    ){
        super();

        this.setName(name);
        this.setLogoId(logoId);
        this.setAdministrationName(administrationName);
        this.setAdministrationEmail(administrationEmail);
        this.setAddress(address);
        this.setTelephone(telephone);
        this.setVatNumber(vatNumber);
        this.setBankAccounts(bankAccounts);
        this.setPreface(preface);
        this.setClosing(closing);
        this.setConditions(conditions);
        this.setOrderPreface(orderPreface);
        this.setOrderClosing(orderClosing);
        this.setOrderConditions(orderConditions);
        this.setQuoteEmailTemplate(quoteEmailTemplate);
        this.setOrderEmailTemplate(orderEmailTemplate);
        users.forEach((u) => this.addUser(u));
        this.setDistributorProperties(distributorProperties);
        this.setHotecMarginStaircase(hotecMarginStaircase);
        this.setHotecMarginTreatment(hotecMarginTreatment);
        this.setHotecMarginDistribution(hotecMarginDistribution);
        this.setHotecDiscountStaircase(hotecDiscountStaircase);
        this.setHotecDiscountTreatment(hotecDiscountTreatment);
        this.setHotecDiscountDistribution(hotecDiscountDistribution);
        this.setDealerMarginStaircase(dealerMarginStaircase);
        this.setDealerMarginTreatment(dealerMarginTreatment);
        this.setDealerMarginDistribution(dealerMarginDistribution);
        this.setApplyGlobalMarginForDealers(applyGlobalMarginForDealers);
        this.setApplyHotecMargin(applyHotecMargin);
        this.setApplyDealerMargin(applyDealerMargin);
        this.setQuoteNumberPrefix(quoteNumberPrefix);
    }

    public addUser(user: UserType): void {
        this.users.push(user);
    }

    public getAddress(): tAddress {
        return this.address;
    }

    public getAdministrationEmail(): string {
        return this.administrationEmail;
    }

    public getAdministrationName(): string {
        return this.administrationName;
    }

    public getDistributorProperties(): {} {
        return this.distributorProperties;
    }

    public getName(): string {
        return this.name;
    }

    public getLogoId(): ObjectID | null {
        return this.logoId;
    }

    public getTelephone(): string {
        return this.telephone;
    }

    public getVatNumber(): string {
        return this.vatNumber;
    }

    public getPreface(): string {
        return this.preface;
    }

    public getClosing(): string {
        return this.closing;
    }

    public getConditions(): string {
        return this.conditions;
    }

    public getOrderPreface(): string {
        return this.orderPreface;
    }

    public getOrderClosing(): string {
        return this.orderClosing;
    }

    public getOrderConditions(): string {
        return this.orderConditions;
    }

    public getQuoteEmailTemplate(): string {
        return this.quoteEmailTemplate;
    }

    public getOrderEmailTemplate(): string {
        return this.orderEmailTemplate;
    }

    public getBankAccounts(): string[] {
        return this.bankAccounts;
    }

    public getUsers(): UserType[] {
        return this.users;
    }

    public getHotecMarginStaircase(): number{
        return this.hotecMarginStaircase;
    }

    public getDealerMarginStaircase(): number{
        return this.dealerMarginStaircase;
    }

    public getHotecMarginTreatment(): number{
        return this.hotecMarginTreatment;
    }

    public getDealerMarginTreatment(): number{
        return this.dealerMarginTreatment;
    }

    public getHotecMarginDistribution(): number{
        return this.hotecMarginDistribution;
    }
    public getHotecDiscountStaircase(): number{
        return this.hotecDiscountStaircase;
    }

    public getHotecDiscountTreatment(): number{
        return this.hotecDiscountTreatment;
    }

    public getHotecDiscountDistribution(): number{
        return this.hotecDiscountDistribution;
    }

    public getDealerMarginDistribution(): number{
        return this.dealerMarginDistribution;
    }

    public getQuoteNumberPrefix(): string{
        return this.quoteNumberPrefix;
    }

    public shouldApplyGlobalMarginForDealers(): boolean{
        return this.applyGlobalMarginForDealers;
    }

    public shouldApplyHotecMargin(): boolean{
        return this.applyHotecMargin;
    }

    public shouldApplyDealerMargin(): boolean{
        return this.applyDealerMargin;
    }

    public removeUser(user: UserType): void {
        const index = this.users.indexOf(user);
        if(index >= 0){
            this.users.splice(index);
        }
    }

    public setAddress(address: tAddress): void {
        this.address = address;
    }

    public setAdministrationEmail(administrationEmail: string): void {
        if(administrationEmail !== "" && !isValidEmail(administrationEmail)){
            throw EMAIL_EXCEPTION;
        }
        this.administrationEmail = administrationEmail;
    }

    public setAdministrationName(administrationName: string): void {
        // TODO: validity check
        this.administrationName = administrationName;
    }

    public setDistributorProperties(properties: {}): void {
        // TODO: validity check
        this.distributorProperties = properties;
    }

    public setName(name: string): void {
        // TODO: validity check
        this.name = name;
    }

    public setLogoId(logoId: ObjectID | null): void {
        // TODO: validity check
        this.logoId = logoId;
    }

    public setTelephone(telephone: string): void {
        // TODO: validity check
        this.telephone = telephone;
    }

    public setPreface(preface: string): void {
        // TODO: validity check
        this.preface = preface;
    }

    public setClosing(closing: string): void {
        // TODO: validity check
        this.closing = closing;
    }

    public setConditions(conditions: string): void {
        // TODO: validity check
        this.conditions = conditions;
    }

    public setOrderPreface(preface: string): void {
        // TODO: validity check
        this.orderPreface = preface;
    }

    public setOrderClosing(orderClosing: string): void {
        // TODO: validity check
        this.orderClosing = orderClosing;
    }

    public setOrderConditions(orderConditions: string): void {
        // TODO: validity check
        this.orderConditions = orderConditions;
    }

    public setQuoteEmailTemplate(quoteEmailTemplate: string): void {
        // TODO: validity check
        this.quoteEmailTemplate = quoteEmailTemplate;
    }

    public setOrderEmailTemplate(orderEmailTemplate: string): void {
        // TODO: validity check
        this.orderEmailTemplate = orderEmailTemplate;
    }

    public setVatNumber(vatNumber: string): void {
        // TODO: validity check
        this.vatNumber = vatNumber;
    }
    
    public setBankAccounts(bankAccounts: string[]): void {
        // TODO: validity check
        this.bankAccounts = bankAccounts;
    }

    public setHotecMarginStaircase(margin: number): void {
        if(margin<0){
            throw INVALID_VALUE;
        }
        this.hotecMarginStaircase = margin;
    }

    public setDealerMarginStaircase(margin: number): void {
        if(margin<0){
            throw INVALID_VALUE;
        }
        this.dealerMarginStaircase = margin;
    }

    public setHotecMarginTreatment(margin: number): void {
        if(margin<0){
            throw INVALID_VALUE;
        }
        this.hotecMarginTreatment = margin;
    }

    public setDealerMarginTreatment(margin: number): void {
        if(margin<0){
            throw INVALID_VALUE;
        }
        this.dealerMarginTreatment = margin;
    }

    public setHotecMarginDistribution(margin: number): void {
        if(margin<0){
            throw INVALID_VALUE;
        }
        this.hotecMarginDistribution = margin;
    }

    public setDealerMarginDistribution(margin: number): void {
        if(margin<0){
            throw INVALID_VALUE;
        }
        this.dealerMarginDistribution = margin;
    }

    public setHotecDiscountStaircase(margin: number): void {
        if(margin<0){
            throw INVALID_VALUE;
        }
        this.hotecDiscountStaircase = margin;
    }

    public setHotecDiscountTreatment(margin: number): void {
        if(margin<0){
            throw INVALID_VALUE;
        }
        this.hotecDiscountTreatment = margin;
    }

    public setHotecDiscountDistribution(margin: number): void {
        if(margin<0){
            throw INVALID_VALUE;
        }
        this.hotecDiscountDistribution = margin;
    }

    public setApplyGlobalMarginForDealers(val: boolean): void {
        if(!isValidBoolean(val)){
            throw INVALID_VALUE;
        }
        this.applyGlobalMarginForDealers = val;
    }

    public setApplyHotecMargin(val: boolean): void {
        if(!isValidBoolean(val)){
            throw INVALID_VALUE;
        }
        this.applyHotecMargin = val;
    }

    public setApplyDealerMargin(val: boolean): void {
        if(!isValidBoolean(val)){
            throw INVALID_VALUE;
        }
        this.applyDealerMargin = val;
    }

    public setQuoteNumberPrefix(val: string): void {
        this.quoteNumberPrefix = val;
    }

    public clone(): Distributor<UserType>{
        return new Distributor<UserType>(
            this.getName(),
            this.getLogoId(),
            this.getAdministrationName(),
            this.getAdministrationEmail(),
            {...this.getAddress()},
            this.getTelephone(),
            this.getVatNumber(),
            this.getBankAccounts(),
            this.getPreface(),
            this.getClosing(),
            this.getConditions(),
            this.getOrderPreface(),
            this.getOrderClosing(),
            this.getOrderConditions(),
            this.getQuoteEmailTemplate(),
            this.getOrderEmailTemplate(),
            this.getUsers().map(u => u.clone() as UserType),
            {...this.getDistributorProperties()},
            this.hotecMarginStaircase,
            this.hotecMarginTreatment,
            this.hotecMarginDistribution,
            this.hotecDiscountStaircase,
            this.hotecDiscountTreatment,
            this.hotecDiscountDistribution,
            this.dealerMarginStaircase,
            this.dealerMarginTreatment,
            this.dealerMarginDistribution,
            this.applyGlobalMarginForDealers,
            this.applyHotecMargin,
            this.applyDealerMargin,
            this.quoteNumberPrefix,
        );
    }

}
