import DataController from "orbiter-core/src/datastructures/DataController";
import IArticleInput, { IExtraHandrailInput, IHandrailInput, ILandingStageInput } from "./IArticleInput";
import ArticleInput from "./articleinput/ArticleInput";
import { tArticle, articleInputToTArticle } from "./simplifiedNetworkCommunicationTypes";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import { IBaseConstantSet } from "./staircasedata/BaseConstantSet";
import HandrailType from "./staircasedata/HandrailType";
import TypeDependentPrice from "./staircasedata/TypeDependentPrice";
import { DELIVERY, PICKUP, PLACEMENT } from "./articleinput/TransportationInput";
import { IDistributor } from "./Distributor";
import ObjectID from "bson-objectid";
import { applyMarginFactor, MarginFactorType } from "../controllers/marginCalculations";
import { initSubCalculation, SubCalculation } from "../controllers/subCalculations";

export interface IArticle extends DataController {
    getArticleInput(): IArticleInput;
    getArticleInputCopy(): IArticleInput;
}

export interface Calculation{
    staircasePrice: SubCalculation;
    handrailPrice: SubCalculation;
    treatmentPrice: SubCalculation;
    landingStagePrice: SubCalculation;
    placementPrice: SubCalculation;



    totalPrice: SubCalculation;
}

export function initCalculation(): Calculation{
    return {
        staircasePrice: initSubCalculation(),
        handrailPrice: initSubCalculation(),
        treatmentPrice: initSubCalculation(),
        landingStagePrice: initSubCalculation(),
        placementPrice: initSubCalculation(),

        totalPrice: initSubCalculation(),
    }
}

class DebugOutput {
    invoer: {
        aantalTrappen: number,
        traptype: string,
        materiaaltype: string,
        materiaaltypeTegentredes: string,
        aantalTredes: number,
        trapvorm: string,
        breedteTrap: number,
        aantalTegentredes: number,
        dikteTrede: string,
        dikteWangen: string,
        dikteTegentrede: string,
        leuningType: string,
        leuningTypeOverloop: string,
        breedteAfkasting: number,
        materiaaltypeAfkasting: string,
        lengteLopendeMeterAfkasting: number,
        lengteLopendeMeterLeuning: number,
        aantalPalen: number,
        behandeling: string,
        transport: string,
        afstand: number,
    }
    trap: {
        factorTraptype: number,
        prijsTrede: number,
        factorMateriaaltype: number,
        factorMateriaaltypeTegentredes: number,
        factorTrapvorm: number,
        totaalSupplementenTredeEnWang: number,
        factorD1: number,
        factorD2: number,
        factorD3: number,
        factorBreedte: number,
        basisTrapInBeuk: number,
        basisTrapInHoutsoort: number,
        tegentredes: number,
        totaalTrap: number,
    }
    leuning: {
        factorLeuningType: number,
        prijsLeuningType: number,
        aantalPalen: number,
        factorTraptypeLeuning: number,
        totaalSupplementenLeunging: number,
        prijsLeuningPerTrede: number,
        prijsLeuningPerTredeInMateriaal: number,
        totaalLeuning: number,
    }
    overloop: {
        leuningFactorL1: number,
        factorMateriaalTypeAfkasting: number,
        leuning: number,
        palen: number,
        afkasting: number,
        totaalSupplementenOverloop: number,
        totaalOverloop: number,
    }
    behandeling: {
        trap: number,
        trapleuning: number,
        overloop: number,
        prijsTrap: number,
        prijsTrapleuning: number,
        prijsOverloop: number,
        totaalBehandeling: number,
    }
    plaatsing: {
        plaatsingsprijsPerTrede: number,
        aantalTredes: number,
        aantalKm: number,
        inclusiefPlaatsing: boolean,
        inclusiefLevering: boolean,
        meerkostPlaatsing: number,
        plaatsing: number,
        levering: number,
        totaalSupplementenPlaatsing: number,
        totaalPlaatsing: number,
    }
    totaalprijs: number
}

function addSubCalculations(a: SubCalculation, b: SubCalculation): SubCalculation{
    return {
        base: a.base + b.base,
        hotecPriceBeforeDealerDiscount: a.hotecPriceBeforeDealerDiscount + b.hotecPriceBeforeDealerDiscount,
        hotecPriceAfterDealerDiscount: a.hotecPriceAfterDealerDiscount + b.hotecPriceAfterDealerDiscount,
        totalPrice: a.totalPrice + b.totalPrice,
    }
}

function addMultipleSubCalculations(...items: SubCalculation[]): SubCalculation{
    let prev: SubCalculation = {
        base: 0,
        hotecPriceBeforeDealerDiscount: 0,
        hotecPriceAfterDealerDiscount: 0,
        totalPrice: 0
    };
    items.forEach(i => {
        prev = addSubCalculations(prev, i);
    });
    return prev;
}

export default class ArticleCalculation extends DataController implements IArticle {

    // TODO: make sure all variables are included in calculations
    // TODO: check validity of combinations and selected items

    private articleInput: IArticleInput;

    private debugOutput: DebugOutput = new DebugOutput();

    @DataController.dataProperty((x) => {
        const y = { ...x };
        delete y.id;
        return y;
    })
    private article: tArticle;

    private _bcs: IBaseConstantSet = null;
    private async getbcs(): Promise<IBaseConstantSet> {
        if(!this._bcs){
            this._bcs = await this.articleInput.articleInfoStore.getBaseConstantSet();
        }
        return this._bcs;
    }

    @DataController.dataProperty()
    private readonly calculationVersion: number = 0;

    @DataController.dataProperty()
    private kmDistance: number;
    
    private quoteCalculationVersion: number;

    private distributorId: ObjectID;
    private _dist: IDistributor = null;
    public async getDistributor(): Promise<IDistributor> {
        if(!this._dist){
            this._dist = await this.articleInput.articleInfoStore.getDistributor(this.distributorId);
        }
        return this._dist;
    }

    public setVariantGroup(variantGroup: null | ObjectID): void{
        this.article.variantGroup = variantGroup;
        this.articleInput.variantGroup = variantGroup;
    }

    public initId(id: ObjectID): void{
        this.article.id = id;
        this.articleInput.id = id;
    }

    private async applyArticleMarginFactor(base: number, type: MarginFactorType): Promise<SubCalculation>{
        console.log("applying article margin factor")
        const bcs = await this.getbcs();
        const dist = await this.getDistributor();
        const discountPercentages = {
            discountPercentageStaircase: this.article.discountPercentageStaircase,
            discountPercentageDistribution: this.article.discountPercentageDistribution,
            discountPercentageTreatment: this.article.discountPercentageTreatment,
        }
        return applyMarginFactor(bcs, dist, discountPercentages, base, type);
    }

    private constructor(
        articleInput: IArticleInput,
        article: tArticle,
        kmDistance: number,
        distributorId: ObjectID,
        quoteCalculationVersion: number,
    ) {
        super();
        this.articleInput = articleInput;
        this.article = article;
        this.kmDistance = kmDistance;
        this.distributorId = distributorId;
        this.quoteCalculationVersion = quoteCalculationVersion;
    }

    public static async init(articleInput: IArticleInput, kmDistance: number, distributorId: ObjectID, quoteCalculationVersion: number, convertedArticle?: tArticle): Promise<ArticleCalculation> {
        let t: tArticle = convertedArticle;
        if (!convertedArticle) {
            t = await articleInputToTArticle(articleInput);
        }
        const articleCalculation = new ArticleCalculation(articleInput, t, kmDistance, distributorId, quoteCalculationVersion);

        // const calculation = await articleCalculation.calculate() // TODO: remove
        // console.log("TOTAL: " + calculation.totalPrice + " voor " + articleCalculation.article.unitCount + " trappen.", calculation.staircasePrice, calculation.handrailPrice, calculation.treatmentPrice, calculation.landingStagePrice, calculation.placementPrice)

        return articleCalculation;
    }

    private includeHandrailInCalculation(handrail: IHandrailInput | null | undefined): boolean {
        return HandrailType.isHandrailIncluded(handrail?.type);
    }

    private includeExtraHandrailInCalculation(handrail: IExtraHandrailInput | null | undefined): boolean {
        return HandrailType.isHandrailIncluded(handrail?.type);
    }

    private async getStaircaseShapeFactor(): Promise<number>{
        const typeDependentPrices: TypeDependentPrice[] = await this.articleInput.shape.staircaseShape.getTypeDependentPrices();
        if(typeDependentPrices.length > 0){
            for (let i = 0; i < typeDependentPrices.length; i++) {
                const element = typeDependentPrices[i];
                const staircaseType = this.articleInput.type;
                if(element.getStaircaseTypeId().toHexString() === staircaseType.getId().toHexString()){
                    return element.getPrice();
                }
            }
        }
        return await this.articleInput.shape.staircaseShape.getPrice();
    }

    public async calculateStaircasePrice(): Promise<SubCalculation> {

        const treadFactor: number = await this.articleInput.type.getTreadPrice();
        const shapeFactor: number = await this.getStaircaseShapeFactor();
        const treadPrice = treadFactor * (await this.getbcs()).getTreadBase() * shapeFactor;
        const materialFactor: number = await this.articleInput.woodType.getPrice();
        const riserMaterialFactor: number = await this.articleInput.riserWoodType.getPrice();
        const supplementsPrice: number = ((await Promise.all<number>(this.articleInput.shape.treadSupplements.map(async (s) => await s.supplement.getPrice() * s.amount))).reduce(((prev, cur) => prev + cur), 0));
        const treadThicknessFactor: number = await this.articleInput.shape.treadThickness.getPrice();
        const stringerThicknessFactor: number = await this.articleInput.shape.stringerThickness.getPrice();
        const riserThicknessFactor: number = await this.articleInput.shape.getRiserThickness().getPrice();
        const widthFactor: number = Math.max(this.articleInput.width - (await this.getbcs()).getWidthTreshold(), 0) * (await this.getbcs()).getWidthMultiplier() + 1;

        const staircaseBase: number = this.articleInput.treadCount * treadPrice * (treadThicknessFactor + stringerThicknessFactor - 1) * widthFactor;
        const staircaseBaseInMaterial: number = staircaseBase * materialFactor;

        let riserCount = this.articleInput.shape.riserCount;
        if (this.articleInput.shape.closedStaircase) {
            riserCount = this.articleInput.treadCount;
        }
        const riserBase: number = riserCount * (await this.getbcs()).getPricePerRiser() * riserThicknessFactor * riserMaterialFactor;

        let total: number = (staircaseBaseInMaterial + riserBase + supplementsPrice) * this.articleInput.unitCount;

        this.debugOutput.trap = {
            factorTraptype: treadFactor,
            prijsTrede: treadPrice,
            factorMateriaaltype: materialFactor,
            factorMateriaaltypeTegentredes: riserMaterialFactor,
            factorTrapvorm: shapeFactor,
            totaalSupplementenTredeEnWang: supplementsPrice,
            factorD1: treadThicknessFactor,
            factorD2: stringerThicknessFactor,
            factorD3: riserThicknessFactor,
            factorBreedte: widthFactor,
            basisTrapInBeuk: staircaseBase,
            basisTrapInHoutsoort: staircaseBaseInMaterial,
            tegentredes: riserBase,
            totaalTrap: total,
        }
        this.debugOutput.invoer.aantalTegentredes = riserCount;

        return this.applyArticleMarginFactor(total, MarginFactorType.STAIRCASE);
    }

    public async calculateExtraHandrailPrice(handrailBaseConstant: number): Promise<number> {
        const h = this.articleInput.extraHandrail;
        
        
        if (this.includeExtraHandrailInCalculation(h)) {
            const numberOfPoles: number = h.poleCount;
            const numberOfTreads: number = h.treadCount;

            if(numberOfPoles !== h.handrailPoleTypes.length || numberOfPoles !== h.handrailPoleFinishes.length){
                throw Error("Number of poles not correct.");
            }
    
            const handrailTypeFactor = await h.type.getPrice();
            const handrailBasePrice = handrailTypeFactor * handrailBaseConstant;
            const materialFactor = await this.articleInput.woodType.getPrice();
            
            const staircaseTypeHandrailFactor = await this.articleInput.type.getHandrailPrice();
            
            const treadPriceHandrail = handrailBasePrice * staircaseTypeHandrailFactor;
            const treadPriceHandrailInMaterial = treadPriceHandrail * materialFactor;
 
            let polePrice: number = 0;
            for (let i = 0; i < h.handrailPoleTypes.length; i++) {
                const poleType = h.handrailPoleTypes[i];
                const poleFinish = h.handrailPoleFinishes[i];
                polePrice += await poleType.getPrice();
                polePrice += await poleFinish.getPrice();
            }
            polePrice *= materialFactor;

            return (treadPriceHandrailInMaterial * numberOfTreads + polePrice); // Don't multiply with total margin factor, this is part of handrail price
        }

        return 0;

    }

    public async calculateWallHandrailPrice(): Promise<number> {
        const h = this.articleInput.wallHandrail;
        
        const pricePerLength: number = await h.type.getPrice();
        const length: number = h.length;
        let staircaseMaterialFactor: number;
        if(h.ignoreWoodType){
            staircaseMaterialFactor = 1;
        }else{
            staircaseMaterialFactor = await this.articleInput.woodType.getPrice();
        }

        let hookPrice: number = 0;
        if(h.hook && h.hookCount > 0){
            hookPrice = await h.hook.getPrice() * h.hookCount;
        }

        let bendPrice: number = 0;
        if(h.bendCount > 0){
            bendPrice = (await this.getbcs()).getPricePerBend() * h.bendCount;
        }

        return (pricePerLength * length * staircaseMaterialFactor + hookPrice + bendPrice); // Don't multiply with total margin factor, this is part of handrail price

    }

    public async calculateHandrailPrice(): Promise<SubCalculation> {

        let handrailTypeFactor: number = 0;
        let handrailBasePrice: number = 0;
        let numberOfPoles: number = 0;
        let materialFactor: number = 0;
        let staircaseTypeHandrailFactor: number = 0;
        let treadPriceHandrail: number = 0;
        let treadPriceHandrailInMaterial: number = 0;
        let supplementsPrice: number = 0;
        let polePrice: number = 0;
        let extraHandrailPrice = 0;
        let wallHandrailPrice = 0;

        
        let total: number = 0;
        
        const h = this.articleInput.handrail;

        const handrailEnabled = h !== null;
        const extraHandrailEnabled = ArticleInput.extraHandrailEnabled(this.articleInput);

        if(handrailEnabled || extraHandrailEnabled){
            let handrailBaseConstant = (await this.getbcs()).getHandrailBase();
            if (h !== null) {
                handrailTypeFactor = await h.type.getPrice();
                handrailBasePrice = handrailTypeFactor * handrailBaseConstant;
                numberOfPoles = await this.articleInput.type.getNumberOfPoles();
                materialFactor = await this.articleInput.woodType.getPrice();
    
                staircaseTypeHandrailFactor = await this.articleInput.type.getHandrailPrice();
    
                treadPriceHandrail = handrailBasePrice * staircaseTypeHandrailFactor;
                treadPriceHandrailInMaterial = treadPriceHandrail * materialFactor;
    
                if (this.includeHandrailInCalculation(h)) {
                    for (let i = 0; i < h.handrailPoleTypes.length; i++) {
                        const poleType = h.handrailPoleTypes[i];
                        const poleFinish = h.handrailPoleFinishes[i];
                        polePrice += await poleType.getPrice();
                        polePrice += await poleFinish.getPrice();
                    }
                    polePrice *= materialFactor;
    
                    supplementsPrice = ((await (Promise.all(h.supplements.map(async (s) => await s.supplement.getPrice() * s.amount)))).reduce(((prev, cur) => prev + cur), 0));
                }
    
    
            }
            
            if(ArticleInput.extraHandrailEnabled(this.articleInput)){
                extraHandrailPrice = await this.calculateExtraHandrailPrice(handrailBaseConstant);
            }
        }
        if(ArticleInput.wallHandrailEnabled(this.articleInput)){
            wallHandrailPrice = await this.calculateWallHandrailPrice();
        }

        total = (treadPriceHandrailInMaterial * this.articleInput.treadCount + supplementsPrice + polePrice + extraHandrailPrice + wallHandrailPrice) * this.articleInput.unitCount;

        this.debugOutput.leuning = {
            factorLeuningType: handrailTypeFactor,
            prijsLeuningType: handrailBasePrice,
            aantalPalen: numberOfPoles,
            factorTraptypeLeuning: staircaseTypeHandrailFactor,
            totaalSupplementenLeunging: supplementsPrice,
            prijsLeuningPerTrede: treadPriceHandrail,
            prijsLeuningPerTredeInMateriaal: treadPriceHandrailInMaterial,
            totaalLeuning: total,
        }

        return this.applyArticleMarginFactor(total, MarginFactorType.STAIRCASE);;

    }

    public async calculateLandingStagePrice(): Promise<SubCalculation> {

        const landingStage: ILandingStageInput = this.articleInput.landingStage;

        const materialFactor: number = await this.articleInput.woodType.getPrice();
        let enclosureMaterialFactor: number = 0;
        let enclosurePrice: number = 0;
        if((landingStage as ILandingStageInput).concreteEnclosureWoodType !== null){
            // Enclosure
            let enclosureMaterialFactor = await (landingStage as ILandingStageInput).concreteEnclosureWoodType.getPrice();
            const cew: number = (landingStage as ILandingStageInput).concreteEnclosureWidth;
            const cel: number = (landingStage as ILandingStageInput).concreteEnclosureLength;

            const surchargeTreshold = (await this.getbcs()).getLandingStageEnclosureSurchargeThreshold();
            const surcharge = (await this.getbcs()).getLandingStageEnclosureSurcharge();

            const surchargeWidth: number = cew > surchargeTreshold ? surcharge * (cew - surchargeTreshold) : 0;
            enclosurePrice = ((await this.getbcs()).getLandingStageEnclosureBase() + surchargeWidth) * enclosureMaterialFactor * cel;
        }

        let supplementsPrice: number = 0;
        let handrailPrice: number = 0;
        let polePrice: number = 0;

        let l1: number = 0;
        if (this.includeHandrailInCalculation(landingStage.handrail)) {
            l1 = await landingStage.handrail.type.getL1Factor();

            handrailPrice = (await this.getbcs()).getLandingStageBase() * l1 * (landingStage as ILandingStageInput).handrailLength;
            polePrice = 0;
            for (let i = 0; i < this.articleInput.landingStage.handrailPoleTypes.length; i++) {
                const poleType = this.articleInput.landingStage.handrailPoleTypes[i];
                const poleFinish = this.articleInput.landingStage.handrailPoleFinishes[i];
                polePrice += await poleType.getPrice();
                polePrice += await poleFinish.getPrice();
            }
            polePrice *= materialFactor;

            // Supplements
            supplementsPrice = ((await Promise.all(this.articleInput.landingStage.handrail.supplements.map(async (s) => await s.supplement.getPrice() * s.amount))).reduce(((prev, cur) => prev + cur), 0));
        }
        
        

        const total = (supplementsPrice + enclosurePrice + polePrice + handrailPrice);

        this.debugOutput.overloop = {
            leuningFactorL1: l1,
            factorMateriaalTypeAfkasting: enclosureMaterialFactor,
            leuning: handrailPrice,
            palen: polePrice,
            afkasting: enclosurePrice,
            totaalSupplementenOverloop: supplementsPrice,
            totaalOverloop: total,
        }

        return this.applyArticleMarginFactor(total, MarginFactorType.STAIRCASE);;
    }

    public async calculateTreatmentPrice(): Promise<SubCalculation> {

        
        let staircaseFactor: number = 0; 
        let handrailFactor: number = 0; 
        let landingStageFactor: number = 0; 
        let wallHandrailFactor: number = 0; 
        let staircasePrice: number = 0; 
        let handrailPrice: number = 0; 
        let landingStagePrice: number = 0; 
        
        let total: number = 0; 
        
        if(this.articleInput.treatment !== null){
            let landingStage: ILandingStageInput = this.articleInput.landingStage;
            let l1: number = 0;
            if (landingStage.handrail !== null) {
                l1 = await landingStage.handrail.type.getL1Factor();
            }
            
            staircaseFactor = await this.articleInput.treatment.type.getStaircaseFactor();
            handrailFactor = await this.articleInput.treatment.type.getHandrailFactor();
            landingStageFactor = await this.articleInput.treatment.type.getLandingStageFactor();
            wallHandrailFactor = await this.articleInput.treatment.type.getWallHandrailFactor();

            // Berekening voor trap
            staircasePrice = this.articleInput.treadCount * staircaseFactor;
            
            // Berekening voor leuning
            if (this.includeHandrailInCalculation(this.articleInput.handrail))
                handrailPrice = this.articleInput.treadCount * handrailFactor;
            
            // Berekening voor extra leuning
            if(this.includeExtraHandrailInCalculation(this.articleInput.extraHandrail))
                handrailPrice += this.articleInput.extraHandrail.treadCount * handrailFactor;

            // Berekening voor muurleuning
            if(ArticleInput.wallHandrailEnabled(this.articleInput))
                handrailPrice += this.articleInput.wallHandrail.length * wallHandrailFactor;
            
            // Berekening voor overloop
            landingStagePrice = landingStageFactor * (
                (landingStage as ILandingStageInput).concreteEnclosureLength
                +
                l1 * (landingStage as ILandingStageInput).handrailLength
            );

            total = (staircasePrice + handrailPrice + landingStagePrice) * this.articleInput.unitCount;
        }

        this.debugOutput.behandeling = {
            trap: staircaseFactor,
            trapleuning: handrailFactor,
            overloop: landingStageFactor,
            prijsTrap: staircasePrice,
            prijsTrapleuning: handrailPrice,
            prijsOverloop: landingStagePrice,
            totaalBehandeling: total,
        }

        return this.applyArticleMarginFactor(total, MarginFactorType.TREATMENT);
    }

    public async calculatePlacementPrice(): Promise<SubCalculation> {

        // Get relevant information
        const installationFactor: number = await this.articleInput.type.getInstallationFactor();
        const nrTreads: number = Math.max(this.articleInput.treadCount, (await this.getbcs()).getInstallationMinTreads());
        const staircaseShapeFactor: number = await this.getStaircaseShapeFactor();
        const kmCount: number = Math.max(0, this.kmDistance - (await this.getbcs()).getDeliveryFreeKmCount());

        // Indicate whether the article will be installed by Hotec
        const isInstalled: number = this.articleInput.transportation.transportationMethod == PLACEMENT ? 1 : 0;
        // Indicate whether the article will be delivered by Hotec
        const isDelivered: number = this.articleInput.transportation.transportationMethod == PLACEMENT || this.articleInput.transportation.transportationMethod == DELIVERY ? 1 : 0;
        // Indicate whether the article will be measured by Hotec
        const isMeasuredByHotec: number = (this.articleInput.transportation.transportationMethod == PICKUP || this.articleInput.transportation.transportationMethod == DELIVERY) && this.articleInput.transportation.measurementByHotec ? 1 : 0;
        // Indicate whether the article will be assembled by Hotec
        const isAssembledByHotec: number = (this.articleInput.transportation.transportationMethod == PICKUP || this.articleInput.transportation.transportationMethod == DELIVERY) && this.articleInput.transportation.assembledByHotec ? 1 : 0;

        // --------------------------------------------------
        // LANDING STAGE AND CONCRETE ENCLOSURE
        // --------------------------------------------------

        // Calculate the pricing for the placement of the concrete enclosure, based on its length
        const placementConcreteEnclosure: number = (await this.getbcs()).getPlacementPriceConcreteEnclosure() * this.articleInput.landingStage.concreteEnclosureLength;

        // Calculate the pricing for the placement of the landing stage handrail, based on its length
        let placementLandingStageHandrail: number = 0;
        if (this.includeHandrailInCalculation(this.articleInput.landingStage.handrail)) {
            // Landing stage handrail present
            placementLandingStageHandrail = (await this.getbcs()).getPlacementPriceLandingStageHandrail() * this.articleInput.landingStage.handrailLength;
        }

        // Total landing stage placement
        const placementLandingStage = placementConcreteEnclosure + placementLandingStageHandrail;

        // --------------------------------------------------
        // HANDRAIL
        // --------------------------------------------------

        // Calculate the pricing for the placement of the handrail, based on a constant
        let placementHandrail = 0;
        if (this.includeHandrailInCalculation(this.articleInput.handrail)) {
            // Handrail present
            placementHandrail = this.articleInput.unitCount * (await this.getbcs()).getPlacementPriceHandrail();
        }

        // --------------------------------------------------
        // PLACEMENT AND DELIVERY
        // --------------------------------------------------

        // Calculate additional placement pricing, based on the chosen options for placement
        let additionalPlacementPrice: number = 0;
        if (this.articleInput.transportation.transportationMethod == PLACEMENT) {
            const mappedOptions: number[] = await Promise.all(this.articleInput.transportation.staircasePlacementOptions.map((o) => o.getPrice()));
            additionalPlacementPrice = mappedOptions.reduce(((prev, cur) => prev + cur), 0);
        }
        // Total placement price
        const placementPrice: number = isInstalled * ((nrTreads * installationFactor * staircaseShapeFactor * this.articleInput.unitCount) + additionalPlacementPrice + placementLandingStage + placementHandrail);

        // Calculate delivery price
        let deliveryPrice: number = 0;
        if(this.quoteCalculationVersion != 1){ // In quoteCalculationVersion 1, the delivery price is calculated globally for each quote, not on a per-article basis (reversed in version 2)
            deliveryPrice = (await this.getbcs()).getDeliveryKmCost() * kmCount * isDelivered;
            if(this.articleInput.transportation.transportationMethod == DELIVERY){
                // Add base delivery price (only if the article is delivered but not placed; in the case of placement this is included in the placement price)
                deliveryPrice += (await this.getbcs()).getDeliveryBasePrice();
            }
        }

        // --------------------------------------------------
        // SUPPLEMENTS
        // --------------------------------------------------

        const supplementsPrice = ((await Promise.all(this.articleInput.transportation.placementSupplements.map(async (s) => await s.supplement.getPrice() * s.amount))).reduce(((prev, cur) => prev + cur), 0));

        // --------------------------------------------------
        // MEASUREMENT AND ASSEMBLY
        // --------------------------------------------------

        // Calculate the measurement and assembly price if applicable
        const measurementPrice = isMeasuredByHotec * (await this.getbcs()).getMeasurementSurcharge()  * this.articleInput.unitCount;
        const assemblyPrice = isAssembledByHotec * (await this.getbcs()).getAssemblySurcharge()  * this.articleInput.unitCount;

        // --------------------------------------------------
        // TOTAL
        // --------------------------------------------------

        // Calculate the total placement price
        let total = placementPrice + deliveryPrice + supplementsPrice + measurementPrice + assemblyPrice;

        this.debugOutput.plaatsing = {
            plaatsingsprijsPerTrede: installationFactor,
            aantalTredes: nrTreads,
            aantalKm: kmCount,
            inclusiefPlaatsing: isInstalled == 1 ? true : false,
            inclusiefLevering: isDelivered == 1 ? true : false,
            meerkostPlaatsing: additionalPlacementPrice,
            plaatsing: placementPrice,
            levering: deliveryPrice,
            totaalSupplementenPlaatsing: supplementsPrice,
            totaalPlaatsing: total,
        }

        return this.applyArticleMarginFactor(total, MarginFactorType.DISTRIBUTION);;

    }

    public async calculate():
        Promise<Calculation> {

        const dt = async (a: MultiLanguageString | Promise<MultiLanguageString>) => {
            const b = await a;
            if (b && b.getLanguages().length > 0)
                return b.getLanguages()[0].getValue();
            return "";
        }

        const a = this.articleInput;
        this.debugOutput.invoer = {
            aantalTrappen: a.unitCount,
            traptype: await dt(a.type.getTitle()),
            materiaaltype: await dt(a.woodType.getTitle()),
            materiaaltypeTegentredes: await dt(a.riserWoodType.getTitle()),
            aantalTredes: a.treadCount,
            trapvorm: await dt(a.shape.staircaseShape.getTitle()),
            breedteTrap: a.width,
            aantalTegentredes: a.shape.riserCount,
            dikteTrede: await dt(a.shape.treadThickness.getTitle()),
            dikteWangen: await dt(a.shape.stringerThickness.getTitle()),
            dikteTegentrede: await dt((a.shape.getRiserThickness())?.getTitle()),
            leuningType: await dt(a.handrail?.type.getTitle()),
            leuningTypeOverloop: await dt(a.landingStage.handrail?.type.getTitle()),
            breedteAfkasting: a.landingStage.concreteEnclosureWidth,
            materiaaltypeAfkasting: await dt(a.landingStage.concreteEnclosureWoodType?.getTitle()),
            lengteLopendeMeterAfkasting: a.landingStage.concreteEnclosureLength,
            lengteLopendeMeterLeuning: a.landingStage.handrailLength,
            aantalPalen: a.landingStage.handrailPoleCount,
            behandeling: await dt(a.treatment?.type.getTitle()),
            transport: a.transportation.transportationMethod,
            afstand: this.kmDistance,
        }

        const staircasePrice = await this.calculateStaircasePrice();
        const handrailPrice = await this.calculateHandrailPrice();
        const landingStagePrice = await this.calculateLandingStagePrice();
        const treatmentPrice = await this.calculateTreatmentPrice();
        const placementPrice = await this.calculatePlacementPrice();

        const totalPrice = addMultipleSubCalculations(staircasePrice, handrailPrice, treatmentPrice, landingStagePrice, placementPrice);
        this.debugOutput.totaalprijs = totalPrice.base;

        return {
            staircasePrice,
            handrailPrice,
            treatmentPrice,
            landingStagePrice,
            placementPrice,

            totalPrice,
        }
    }

    public getArticleInput(): IArticleInput {
        return this.articleInput;
    }

    public getArticleInputCopy(): IArticleInput {
        return ArticleInput.from(this.getArticleInput());
    }

    public downloadDebugCsv() {
        const d = this.debugOutput;
        const rows = [
            // INVOER
            ["INVOER", ""],
            ["Aantal trappen", d.invoer.aantalTrappen],
            ["Traptype", d.invoer.traptype],
            ["Materiaaltype", d.invoer.materiaaltype],
            ["Materiaaltype tegentredes", d.invoer.materiaaltypeTegentredes],
            ["Aantal tredes", d.invoer.aantalTredes],
            ["Trapvorm", d.invoer.trapvorm],
            ["Breedte trap", d.invoer.breedteTrap],
            ["Aantal tegentredes", d.invoer.aantalTegentredes],
            ["Dikte trede", d.invoer.dikteTrede],
            ["Dikte wangen", d.invoer.dikteWangen],
            ["Dikte tegentrede", d.invoer.dikteTegentrede],
            ["Leuning type", d.invoer.leuningType],
            ["Leuning type overloop", d.invoer.leuningTypeOverloop],
            ["Breedte afkasting (cm)", d.invoer.breedteAfkasting],
            ["Materiaaltype afkasting", d.invoer.materiaaltypeAfkasting],
            ["Lengte lopende meter afkasting (m)", d.invoer.lengteLopendeMeterAfkasting],
            ["Lengte lopende meter leuning (m)", d.invoer.lengteLopendeMeterLeuning],
            ["Aantal palen", d.invoer.aantalPalen],
            ["Behandeling", d.invoer.behandeling],
            ["Transport", d.invoer.transport],
            ["Afstand (km)", d.invoer.afstand],

            // TRAP
            ["TRAP", ""],
            ["Factor traptype", d.trap.factorTraptype],
            ["Prijs trede", d.trap.prijsTrede],
            ["Factor materiaaltype", d.trap.factorMateriaaltype],
            ["Factor materiaaltype tegentredes", d.trap.factorMateriaaltypeTegentredes],
            ["Factor trapvorm", d.trap.factorTrapvorm],
            ["Totaal supplementen tred & wang", d.trap.totaalSupplementenTredeEnWang],
            ["Factor dikte trede (D1)", d.trap.factorD1],
            ["Factor dikte wangen (D2)", d.trap.factorD2],
            ["Factor dikte tegentrede (D3)", d.trap.factorD3],
            ["Factor breedte", d.trap.factorBreedte],
            ["Basis trap in beuk", d.trap.basisTrapInBeuk],
            ["Basis trap in houtsoort", d.trap.basisTrapInHoutsoort],
            ["Tegentredes", d.trap.tegentredes],
            ["Totaal trap", d.trap.totaalTrap],

            // LEUNING
            ["LEUNING", ""],
            ["Factor leuningtype", d.leuning.factorLeuningType],
            ["Prijs leuningtype", d.leuning.prijsLeuningType],
            ["Aantal palen", d.leuning.aantalPalen],
            ["Factor traptype > leuning", d.leuning.factorTraptypeLeuning],
            ["Totaal supplementen leuning", d.leuning.totaalSupplementenLeunging],
            ["Prijs leuning per trede", d.leuning.prijsLeuningPerTrede],
            ["Prijs leuning per trede in materiaal", d.leuning.prijsLeuningPerTredeInMateriaal],
            ["Totaal leuning", d.leuning.totaalLeuning],

            // OVERLOOP
            ["OVERLOOP", ""],
            ["Leuningfactor L1", d.overloop.leuningFactorL1],
            ["Factor materiaaltype afkasting", d.overloop.factorMateriaalTypeAfkasting],
            ["Leuning", d.overloop.leuning],
            ["Palen", d.overloop.palen],
            ["Afkasting", d.overloop.afkasting],
            ["Totaal supplementen overloop", d.overloop.totaalSupplementenOverloop],
            ["Totaal overloop", d.overloop.totaalOverloop],

            // BEHANDELING
            ["BEHANDELING", ""],
            ["Trap (per trede)", d.behandeling.trap],
            ["Trapleuning (per trede)", d.behandeling.trapleuning],
            ["Overloop (per lopende meter)", d.behandeling.overloop],
            ["Prijs trap", d.behandeling.prijsTrap],
            ["Prijs trapleuning", d.behandeling.prijsTrapleuning],
            ["Prijs overloop", d.behandeling.prijsOverloop],
            ["Totaal behandeling", d.behandeling.totaalBehandeling],

            // PLAATSING
            ["PLAATSING", ""],
            ["Plaatsingsprijs per trede", d.plaatsing.plaatsingsprijsPerTrede],
            ["Aantal tredes (met minimum 'min tredes plaatsing')", d.plaatsing.aantalTredes],
            ["Aantal km (- gratis aantal km)", d.plaatsing.aantalKm],
            ["Is inclusief plaatsing", d.plaatsing.inclusiefPlaatsing],
            ["Is inclusief levering", d.plaatsing.inclusiefLevering],
            ["Meerkost plaatsing", d.plaatsing.meerkostPlaatsing],
            ["Plaatsing", d.plaatsing.plaatsing],
            ["Levering", d.plaatsing.levering],
            ["Totaal supplementen plaatsing", d.plaatsing.totaalSupplementenPlaatsing],
            ["Totaal plaatsing", d.plaatsing.totaalPlaatsing],

            // TOTAAL
            ["TOTAAL", ""],
            ["Totaalprijs", d.totaalprijs],

        ];

        let csvContentEurope = "data:text/csv;charset=utf-8,"
            + rows.map(e => e.join(";")).join("\n");

        let encodedUri = encodeURI(csvContentEurope);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "berekening-debugger-" + new Date().toISOString() + ".csv");
        document.body.appendChild(link);

        link.click();
    }

}
