import React = require("react");
import { connect, DispatchProp } from "react-redux";
import { PageType } from "../../../../../../shared/datastructures/IArticleInput";
import { Popup, PopupManager } from "../../../components/Popup";
import {setArticleComments} from "../../../actions/articleCreationActions";
import { IArticleCreationStateDispatch } from "../../../reducers/articleCreationReducer";
import CommentInput from "../../../../../../shared/datastructures/articleinput/CommentInput";
import { t } from "ttag";
import Input from "webc-reactcore/src/js/components/mainlayout/PortalInputLarge";
import store from "../../../store";

export function commentInputPopupFactory(pageType: PageType){
    return new Popup(<CommentInputComponent pageType={pageType} {...{} as any}/>, [{
        text: t`Sluiten`,
        onClick: ()=>PopupManager.closePopup(),
    }])
}

export function getCommentPreviewForPageType(pageType: PageType): string{
    const val = store.getState().articleCreation?.article?.comments?.find(x => x.page === pageType)?.content;
    return val ? val : "";
}

@(connect((store: any) => {
    return {...store.articleCreation};
}) as any)
class CommentInputComponent extends React.Component<{pageType: PageType} & IArticleCreationStateDispatch> {

    constructor(_){
        super(_);
        this.escPressed = this.escPressed.bind(this);
    }

    private setComment(value: string){
        let comments = this.props.article.comments;
        const newValue = new CommentInput(this.props.pageType, value);
        if(comments.findIndex(x => x.page === this.props.pageType) > -1){
            comments = comments.map(x => x.page !== this.props.pageType ? x : newValue);
        }else{
            comments = [...comments, newValue]
        }
        this.props.dispatch(setArticleComments(comments));
    }

    private addComment(comment: CommentInput){
        this.props.dispatch(setArticleComments([...this.props.article.comments, comment]));
    }

    escPressed(event){
        if(event.keyCode === 27) {
            PopupManager.closePopup();
        }
    }

    componentDidMount(){
        document.addEventListener("keydown", this.escPressed, false);
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.escPressed, false);
    }

    public render() {
        const content: string = this.props.article.comments.find(x => x.page == this.props.pageType)?.content;
        return <Input placeholder={t`Opmerking`}
            onChange={(v) => this.setComment(v)}
            value={content ? content : ""} />
    }
}
export default CommentInputComponent;