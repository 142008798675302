import ArticleInputDiff, { IArticleDiffLine } from '../datastructures/diff/ArticleInputDiff';
import { IQuantifiedSupplement } from '../datastructures/IArticleInput';
import { IDatabaseController } from 'orbiter-core/src/databasecontroller/DatabaseController';
import { IHandrailPoleFinish } from '../datastructures/staircasedata/HandrailPoleFinish';
import { IHandrailPoleType } from '../datastructures/staircasedata/HandrailPoleType';
import { IStaircasePlacementOption } from '../datastructures/staircasedata/StaircasePlacementOption';
import MultiLanguageString from 'orbiter-core/src/datastructures/languages/MultiLanguageString';
import { transportationMethodToString } from './QuoteRenderer';
import UniformNode from "./IUniformNode";
import UniformNodeFactory from './UniformNodeFactory';

function makeDiffLineBool(f: UniformNodeFactory<any>, dt: (mls: MultiLanguageString) => string, title: string, diffLine: IArticleDiffLine<any>, trueVal?: string, falseVal?: string) {
    return makeDiffLine(f, dt, title, diffLine, x => x ? (trueVal ?? `Ja`) : (falseVal ?? `Nee`));
}

function makeDiffLineSupplements(f: UniformNodeFactory<any>, dt: (mls: MultiLanguageString) => string, title: string, diffLine: IArticleDiffLine<IQuantifiedSupplement[]>) {
    return makeDiffLine(f, dt, title, diffLine, s => !s || s.length === 0 ? `Geen supplementen` : s.map(s => s.amount + " x " + dt(s.supplement.getDescription())).join(", "));
}

function makeDiffLinePlacementOptions(f: UniformNodeFactory<any>, dt: (mls: MultiLanguageString) => string, title: string, diffLine: IArticleDiffLine<(IStaircasePlacementOption & IDatabaseController)[]>) {
    return makeDiffLine(f, dt, title, diffLine, s => !s || s.length === 0 ? `Geen plaatsingsopties` : s.map(s => dt(s.getDescription())).join(", "));
}

function makeDiffLineHandrailPoleTypes(f: UniformNodeFactory<any>, dt: (mls: MultiLanguageString) => string, title: string, diffLine: IArticleDiffLine<(IHandrailPoleType & IDatabaseController)[]>) {
    return makeDiffLine(f, dt, title, diffLine, s => !s || s.length === 0 ? `Geen paaltypes` : s.map(s => dt(s.getDescription())).join(", "));
}

function makeDiffLineHandrailPoleFinishes(f: UniformNodeFactory<any>, dt: (mls: MultiLanguageString) => string, title: string, diffLine: IArticleDiffLine<(IHandrailPoleFinish & IDatabaseController)[]>) {
    return makeDiffLine(f, dt, title, diffLine, s => !s || s.length === 0 ? `Geen bovenafwerkingen` : s.map(s => dt(s.getDescription())).join(", "));
}

function makeDiffLineTitle(f: UniformNodeFactory<any>, dt: (mls: MultiLanguageString) => string, title: string, diffLine: IArticleDiffLine<{ getDescription: any }>, unit?: string) {
    return makeDiffLine(f, dt, title, diffLine, x => x ? dt(x.getDescription()) : `Niet ingesteld`, unit);
}

function makeDiffLineStringList(f: UniformNodeFactory<any>, dt: (mls: MultiLanguageString) => string, title: string, diffLine: IArticleDiffLine<string[]>) {
    return makeDiffLine(f, dt, title, diffLine, x => x ? x.join(", ") : `Niet ingesteld`);
}

function makeDiffLinePresent(f: UniformNodeFactory<any>, dt: (mls: MultiLanguageString) => string, title: string, diffLine: IArticleDiffLine<any>) {
    return makeDiffLine(f, dt, title, diffLine, x => x ? `Toegevoegd` : `Laten vallen`);
}

function makeDiffLine<T, NodeType>(f: UniformNodeFactory<NodeType>, dt: (mls: MultiLanguageString) => string, title: string, diffLine: IArticleDiffLine<T>, newValue?: (right: T) => string, unit?: string) {
    function generateUsedValue() {
        let usedValue: string;
        let set: boolean = false;
        if (newValue) {
            usedValue = newValue(diffLine.rightValue).toString();
            set = true;
        } else {
            if (diffLine.rightValue !== undefined && diffLine.rightValue !== null) {
                usedValue = diffLine.rightValue.toString();
                set = true;
            } else {
                usedValue = `Niet ingesteld`;
            }
        }
        if (set && unit)
            usedValue += " " + unit;
        return usedValue;
    }
    return diffLine.different ? f.Bullet(f.BoldPlainText(title + ": "), f.String(generateUsedValue())) : f.None();
}

function makeDiffLineWithUnit<T>(f: UniformNodeFactory<any>, dt: (mls: MultiLanguageString) => string, title: string, diffLine: IArticleDiffLine<T>, unit: string) {
    return makeDiffLine(f, dt, title, diffLine, undefined, unit);
}

export function ArticleDiff<NodeType>(f: UniformNodeFactory<NodeType>, dt: (mls: MultiLanguageString) => string, diff: ArticleInputDiff): UniformNode<NodeType> {
    const differences = [
        makeDiffLineWithUnit(f, dt, `Korting trap`, diff.discountPercentageStaircase, "%"),
        makeDiffLineWithUnit(f, dt, `Korting behandeling`, diff.discountPercentageTreatment, "%"),
        makeDiffLineWithUnit(f, dt, `Korting plaatsing`, diff.discountPercentageDistribution, "%"),

        makeDiffLine(f, dt, `Aantal dezelfde trappen`, diff.unitCount),
        makeDiffLineWithUnit(f, dt, `Breedte trap`, diff.width, "cm"),
        makeDiffLineWithUnit(f, dt, `Verdiepingshoogte`, diff.floorHeight, "cm"),
        makeDiffLine(f, dt, `Aantal tredes`, diff.treadCount),
        makeDiffLineTitle(f, dt, `Traptype`, diff.type),
        makeDiffLineTitle(f, dt, `Materiaaltype`, diff.woodType),
        makeDiffLine(f, dt, `Materiaaltype tegentredes`, diff.riserWoodType, x => x ? (diff.a2.shape.getRiserThickness().getThickness() == 12 ? `multiplex fineer ${dt(x.getDescription())}` : dt(x.getDescription())) : `Niet ingesteld`),
        makeDiffLineBool(f, dt, `Houten trap`, diff.isWood),

        // SHAPE
        makeDiffLineBool(f, dt, `Open of gesloten trap`, diff.shape.closedStaircase, `Gesloten`, `Open`),
        makeDiffLineTitle(f, dt, `Dikte tredes`, diff.shape.treadThickness, "mm"),
        makeDiffLineTitle(f, dt, `Dikte trapbomen`, diff.shape.stringerThickness, "mm"),
        makeDiffLineTitle(f, dt, `Dikte tegentreden`, diff.shape.riserThickness, "mm"),
        makeDiffLine(f, dt, `Aantal tegentreden`, diff.shape.riserCount),
        makeDiffLineTitle(f, dt, `Trapvorm`, diff.shape.staircaseShape),
        makeDiffLineSupplements(f, dt, `Supplementen treden & trapbomen`, diff.shape.treadSupplements),

        // HANDRAIL
        ...makeHandrailDiffLines(f, dt, diff),

        // EXTRA HANDRAIL
        ...makeExtraHandrailDiffLines(f, dt, diff),


        // WALL HANDRAIL
        ...makeWallHandrailDiffLines(f, dt, diff),


        // LANDING STAGE
        ...makeLandingStageHandrailDiffLines(f, dt, diff),
        ...makeLandingStageConcreteEnclosureDiffLines(f, dt, diff),

        

        // TREATMENT
        ...makeTreatmentDiffLines(f, dt, diff),

        // TRANSPORTATION
        makeDiffLine(f, dt, "Transport", diff.transportation.transportationMethod, x => transportationMethodToString(x)),
        makeDiffLinePlacementOptions(f, dt, "Plaatsingsopties", diff.transportation.staircasePlacementOptions),
        makeDiffLineBool(f, dt, `Opmeting door Hotec`, diff.transportation.measurementByHotec),
        makeDiffLineBool(f, dt, `Montage door Hotec`, diff.transportation.assembledByHotec),
        makeDiffLineSupplements(f, dt, "Supplementen plaatsing", diff.transportation.placementSupplements),
    ];

    return f.View(...differences.filter(x => x));
}

enum Change {
    ADDED,
    REMOVED,
    CHANGED,
    UNCHANGED,
}

function changeType<T>(diffLine: IArticleDiffLine<T>): Change {
    if (!diffLine.different)
        return Change.UNCHANGED;
    else if (
        diffLine.leftValue !== undefined && diffLine.leftValue !== null
        && (diffLine.rightValue === undefined || diffLine.rightValue === null || diffLine.rightValue as any === 0)
    )
        return Change.REMOVED;
    else if (
        diffLine.rightValue !== undefined && diffLine.rightValue !== null
        && (diffLine.leftValue === undefined || diffLine.leftValue === null || diffLine.leftValue as any === 0)
    )
        return Change.ADDED;
    return Change.CHANGED;
}

function indentBasedOnChangeType<T>(f: UniformNodeFactory<T>, dt: (mls: MultiLanguageString) => string, groupDiff: IArticleDiffLine<any>, lines: UniformNode<T>[]): UniformNode<T>[] {
    const ct = changeType(groupDiff);
    if (ct === Change.REMOVED) {
        return [
            lines[0]
        ]
    }

    if (ct === Change.ADDED) {
        return [
            lines[0],
            f.IndentView(
                ...lines.slice(1)
            ),
        ]
    }

    return lines;
}
function makeHandrailDiffLines<T>(f: UniformNodeFactory<T>, dt: (mls: MultiLanguageString) => string, diff: ArticleInputDiff): UniformNode<T>[] {
    return indentBasedOnChangeType(f, dt, 
        diff.handrail.main,
        [
            makeDiffLinePresent(f, dt, `Leuning`, diff.handrail.main),
            makeDiffLineTitle(f, dt, `Leuningtype`, diff.handrail.type),
            makeDiffLineTitle(f, dt, `Handgreep leuning`, diff.handrail.handle),
            makeDiffLineTitle(f, dt, `Type modelspijlen leuning`, diff.handrail.spindleThreadType),
            makeDiffLine(f, dt, `Omschrijving modelspijlen leuning`, diff.handrail.spindleThreadDescription),
            makeDiffLineSupplements(f, dt, `Supplementen leuning`, diff.handrail.supplements),
            makeDiffLineHandrailPoleTypes(f, dt, `Paaltypes leuning`, diff.handrail.handrailPoleTypes),
            makeDiffLineHandrailPoleFinishes(f, dt, `Bovenafwerkingen leuning`, diff.handrail.handrailPoleFinishes),
        ]  
    );
}

function makeExtraHandrailDiffLines<T>(f: UniformNodeFactory<T>, dt: (mls: MultiLanguageString) => string, diff: ArticleInputDiff): UniformNode<T>[] {
    return indentBasedOnChangeType(f, dt, 
        diff.extraHandrail.main,
        [
            makeDiffLinePresent(f, dt, `Extra leuning`, diff.extraHandrail.main),
            makeDiffLine(f, dt, `Aantal tredes extra leuning`, diff.extraHandrail.treadCount),
            makeDiffLine(f, dt, `Aantal palen extra leuning`, diff.extraHandrail.poleCount),
            makeDiffLineTitle(f, dt, `Leuningtype extra leuning`, diff.extraHandrail.type),
            makeDiffLineTitle(f, dt, `Handgreep extra leuning`, diff.extraHandrail.handle),
            makeDiffLineTitle(f, dt, `Type modelspijlen extra leuning`, diff.extraHandrail.spindleThreadType),
            makeDiffLine(f, dt, `Omschrijving modelspijlen extra leuning`, diff.extraHandrail.spindleThreadDescription),
            makeDiffLineHandrailPoleTypes(f, dt, `Paaltypes extra leuning`, diff.extraHandrail.handrailPoleTypes),
            makeDiffLineHandrailPoleFinishes(f, dt, `Bovenafwerkingen extra leuning`, diff.extraHandrail.handrailPoleFinishes),
        ]  
    );
}

function makeWallHandrailDiffLines<T>(f: UniformNodeFactory<T>, dt: (mls: MultiLanguageString) => string, diff: ArticleInputDiff): UniformNode<T>[] {
    return indentBasedOnChangeType(f, dt, 
        diff.wallHandrail.main,
        [
            makeDiffLinePresent(f, dt, `Muurleuning`, diff.wallHandrail.main),
            makeDiffLineTitle(f, dt, `Type muurleuning`, diff.wallHandrail.type),
            makeDiffLineWithUnit(f, dt, `Lengte muurleuning`, diff.wallHandrail.length, "m"),
            makeDiffLineTitle(f, dt, `Type muurhaak`, diff.wallHandrail.hook),
            makeDiffLine(f, dt, `Aantal muurhaken`, diff.wallHandrail.hookCount),
            makeDiffLine(f, dt, `Aantal knikken`, diff.wallHandrail.bendCount),
            makeDiffLineBool(f, dt, `Onafhankelijk van materiaaltype`, diff.wallHandrail.ignoreWoodType),
        ]  
    );
}

function makeLandingStageHandrailDiffLines<T>(f: UniformNodeFactory<T>, dt: (mls: MultiLanguageString) => string, diff: ArticleInputDiff): UniformNode<T>[] {
    return indentBasedOnChangeType(f, dt, 
        diff.landingStage.handrail.main,
        [
            makeDiffLinePresent(f, dt, `Overloopleuning`, diff.landingStage.handrail.main),
            makeDiffLineTitle(f, dt, `Leuningtype overloopleuning`, diff.landingStage.handrail.type),
            makeDiffLineTitle(f, dt, `Handgreep overloopleuning`, diff.landingStage.handrail.handle),
            makeDiffLineTitle(f, dt, `Type modelspijlen overloopleuning`, diff.landingStage.handrail.spindleThreadType),
            makeDiffLine(f, dt, `Omschrijving modelspijlen overloopleuning`, diff.landingStage.handrail.spindleThreadDescription),
            makeDiffLineSupplements(f, dt, `Supplementen overloopleuning`, diff.landingStage.handrail.supplements),
            makeDiffLineHandrailPoleTypes(f, dt, `Paaltypes overloopleuning`, diff.landingStage.handrail.handrailPoleTypes),
            makeDiffLineHandrailPoleFinishes(f, dt, `Bovenafwerkingen overloopleuning`, diff.landingStage.handrail.handrailPoleFinishes),
            makeDiffLineWithUnit(f, dt, `Lopende meter overloopleuning`, diff.landingStage.handrailLength, "m"),
            makeDiffLine(f, dt, `Aantal palen overloop`, diff.landingStage.handrailPoleCount),
            makeDiffLineHandrailPoleTypes(f, dt, `Paaltypes overloop`, diff.landingStage.handrailPoleTypes),
            makeDiffLineHandrailPoleFinishes(f, dt, `Bovenafwerkingen overloop`, diff.landingStage.handrailPoleFinishes),
        ]  
    );
}

function makeLandingStageConcreteEnclosureDiffLines<T>(f: UniformNodeFactory<T>, dt: (mls: MultiLanguageString) => string, diff: ArticleInputDiff): UniformNode<T>[] {
    return indentBasedOnChangeType(f, dt, 
        diff.landingStage.concreteEnclosureLength,
        [
            makeDiffLinePresent(f, dt, `Afkasting`, diff.landingStage.concreteEnclosureLength),
            makeDiffLineWithUnit(f, dt, `Lopende meter afkasting`, diff.landingStage.concreteEnclosureLength, "m"),
            makeDiffLineWithUnit(f, dt, `Breedte afkasting`, diff.landingStage.concreteEnclosureWidth, "cm"),
            makeDiffLine(f, dt, `Materiaaltype afkasting`, diff.landingStage.concreteEnclosureWoodType, x => x ? (diff.a2.shape.getRiserThickness().getThickness() == 12 ? `multiplex fineer ${dt(x.getDescription())}` : dt(x.getDescription())) : `Niet ingesteld`),
        ]  
    );
}

function makeTreatmentDiffLines<T>(f: UniformNodeFactory<T>, dt: (mls: MultiLanguageString) => string, diff: ArticleInputDiff): UniformNode<T>[] {
    return indentBasedOnChangeType(f, dt, 
        diff.treatment.main,
        [
            makeDiffLinePresent(f, dt, `Behandeling`, diff.treatment.main),
            makeDiffLineTitle(f, dt, `Type behandeling`, diff.treatment.type),
            makeDiffLineStringList(f, dt, `Kleuren`, diff.treatment.colors),
        ]  
    );
}