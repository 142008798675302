import { IDatabaseController } from "orbiter-core/src/databasecontroller/DatabaseController";
import { differingDBControllerList, differingNumbers, differingStrings, IArticleDiffLine, newDiffFalseLine, newDiffTrueLine } from "./ArticleInputDiff";
import { IHandrailPoleFinish } from "../staircasedata/HandrailPoleFinish";
import { IHandrailPoleType } from "../staircasedata/HandrailPoleType";
import HandrailInputDiff from "./HandrailInputDiff";
import { IWoodType } from "../staircasedata/WoodType";
import LandingStageInput from "../articleinput/LandingStageInput";
import ArticleInput from "../articleinput/ArticleInput";

export default class LandingStageInputDiff{

    public handrail: HandrailInputDiff;
    public handrailLength: IArticleDiffLine<number> = newDiffFalseLine();
    public concreteEnclosureLength: IArticleDiffLine<number> = newDiffFalseLine();
    public concreteEnclosureWidth: IArticleDiffLine<number> = newDiffFalseLine();
    public concreteEnclosureWoodType: IArticleDiffLine<IWoodType & IDatabaseController> = newDiffFalseLine();
    public handrailPoleCount: IArticleDiffLine<number> = newDiffFalseLine();
    public handrailPoleTypes: IArticleDiffLine<(IHandrailPoleType & IDatabaseController)[]> = newDiffFalseLine();
    public handrailPoleFinishes: IArticleDiffLine<(IHandrailPoleFinish & IDatabaseController)[]> = newDiffFalseLine();

    constructor(readonly left: LandingStageInput, readonly right: LandingStageInput, readonly a1: ArticleInput, readonly a2: ArticleInput){}

    public async diff(){
        await this.diffHandrail();
        await this.diffHandrailLength();
        await this.diffConcreteEnclosureLength();
        await this.diffConcreteEnclosureWidth();
        await this.diffConcreteEnclosureWoodType();
        await this.diffHandrailPoleCount();
        await this.diffHandrailPoleTypes();
        await this.diffHandrailPoleFinishes();
    }

    public differs(): boolean{
        return this.handrail.differs()
            || this.handrailLength.different
            || this.concreteEnclosureLength.different
            || this.concreteEnclosureWidth.different
            || this.concreteEnclosureWoodType.different
            || this.handrailPoleCount.different
            || this.handrailPoleTypes.different
            || this.handrailPoleFinishes.different;
    }

    private async diffHandrail(){
        this.handrail = new HandrailInputDiff(this.left.handrail, this.right.handrail);
        await this.handrail.diff();
    }

    private async diffHandrailLength(){
        if(differingNumbers(this.left.handrailLength, this.right.handrailLength)){
            this.handrailLength = newDiffTrueLine(
                this.left.handrailLength,
                this.right.handrailLength,
            )
        }
    }

    private async diffConcreteEnclosureLength(){
        if(differingNumbers(this.left.concreteEnclosureLength, this.right.concreteEnclosureLength)){
            this.concreteEnclosureLength = newDiffTrueLine(
                this.left.concreteEnclosureLength,
                this.right.concreteEnclosureLength,
            )
        }
    }

    private async diffConcreteEnclosureWidth(){
        if(differingNumbers(this.left.concreteEnclosureWidth, this.right.concreteEnclosureWidth)){
            this.concreteEnclosureWidth = newDiffTrueLine(
                this.left.concreteEnclosureWidth,
                this.right.concreteEnclosureWidth,
            )
        }
    }

    private async diffConcreteEnclosureWoodType(){
        const newRiserThicknessEquals12 = this.a2.shape.getRiserThickness().getThickness() == 12;
        const oldRiserThicknessEquals12 = this.a1.shape.getRiserThickness().getThickness() == 12;
        if(oldRiserThicknessEquals12 && newRiserThicknessEquals12){
            // Can not be different, they're both 'multiplex fineer' (#150)
        }else if(
            differingStrings(this.left.concreteEnclosureWoodType?.getSid(), this.right.concreteEnclosureWoodType?.getSid())
            || newRiserThicknessEquals12
        ){
            this.concreteEnclosureWoodType = newDiffTrueLine(
                this.left.concreteEnclosureWoodType,
                this.right.concreteEnclosureWoodType,
            )
        }
    }

    private async diffHandrailPoleCount(){
        if(differingNumbers(this.left.handrailPoleCount, this.right.handrailPoleCount)){
            this.handrailPoleCount = newDiffTrueLine(
                this.left.handrailPoleCount,
                this.right.handrailPoleCount,
            )
        }
    }

    private async diffHandrailPoleTypes(){
        if(differingDBControllerList(this.left.handrailPoleTypes, this.right.handrailPoleTypes)){
            this.handrailPoleTypes = newDiffTrueLine(
                this.left.handrailPoleTypes,
                this.right.handrailPoleTypes,
            );
        }   
    }

    private async diffHandrailPoleFinishes(){
        if(differingDBControllerList(this.left.handrailPoleFinishes, this.right.handrailPoleFinishes)){
            this.handrailPoleFinishes = newDiffTrueLine(
                this.left.handrailPoleFinishes,
                this.right.handrailPoleFinishes,
            );
        }   
    }

}