import ObjectID from "bson-objectid";
import DataController from "orbiter-core/src/datastructures/DataController";

export interface HotecUserProperties {
    distributorId: ObjectID;
    name: string;
}

export function createDefaultHotecUserProperties(): HotecUserProperties{
    return {
        distributorId: null,
        name: "",
    };
}

export default class HotecUserBrowserStandin extends DataController {

    @DataController.dataProperty()
    private email: string;

    @DataController.dataProperty()
    private password: string;

    @DataController.dataProperty()
    private properties?: HotecUserProperties;

    @DataController.dataProperty()
    private permissions: ObjectID[];

    constructor(email: string, password: string, properties?: HotecUserProperties, permissions?: ObjectID[]) {
        super();
        this.setEmail(email);
        this.setPassword(password ? password : "");
        this.setProperties(properties);
        this.setPermissions(permissions ?? []);
    }

    public getEmail(): string {
        return this.email;
    }

    public getPassword(): string {
        return this.password;
    }

    public getProperties(): HotecUserProperties {
        return this.properties;
    }

    public getPermissions(): ObjectID[] {
        return this.permissions;
    }

    public setEmail(email: string): void {

        // if(!isValidEmail(email)) {
        //     throw EMAIL_EXCEPTION;
        // }

        this.email = email;
    }

    public setPassword(password: string): void {
        this.password = password;
    }

    public setProperties(properties: HotecUserProperties): void{
        this.properties = properties;
    }

    public setPermissions(permissions: ObjectID[]): void{
        this.permissions = permissions;
    }

    public getDistributorId(): ObjectID | null {
        return this.getProperties().distributorId;
    }

    public setDistributorId(distributorId: ObjectID | null): void {
        this.setProperties({...this.getProperties(), distributorId});
    }

    public getName(): string {
        return this.getProperties().name;
    }

    public setName(name: string): void {
        this.setProperties({...this.getProperties(), name});
    }

    public clone(): HotecUserBrowserStandin{
        return new HotecUserBrowserStandin(
            this.getEmail(),
            this.getPassword(),
            {...this.getProperties()},
            this.getPermissions(),
        );
    }

}