import ObjectID from "bson-objectid";
import {isValidId} from "orbiter-core/src/basic";
import DatabaseController, {IDatabaseController} from "orbiter-core/src/databasecontroller/DatabaseController";
import DataController from "orbiter-core/src/datastructures/DataController";
import {ID_EXCEPTION, NOT_IMPLEMENTED_EXCEPTION, UPDATE_EXCEPTION} from "orbiter-core/src/exceptions";
import {doJsonApiCall, doJsonDeleteApiCall, doJsonGetApiCall} from "webc-reactcore/src/js/apicommunication";

// TODO: move to react-core
// TODO: errors when data isRetrieving or when isRetrieved is false

export enum ConstructionMethods {
    PromiseToRetrieve, // Don't retrieve immediately because it's done manually - synchronously and before usage, needs _id
    Data, // Don't retrieve but use passed data, needs _id, data
}

/**
 * To implement on class level:
 *
 *      methods:
 *          - parseFromDictionary
 *          - getBaseEndpoint
 *          - getApiKey
 *          - getPluralApiKey
 */
export default class ApiController<T extends DataController>
    extends DatabaseController implements IDatabaseController {

    /**
     * Has to be implemented on class level.
     *
     * @returns {string}
     */
    public static getBaseEndpoint(): string {
        throw NOT_IMPLEMENTED_EXCEPTION;
    }

    /**
     * Could be implemented on class level.
     *
     * @returns {string}
     */
    public static getCreateEndpoint(): string {
        return this.getBaseEndpoint() + "/create";
    }

    /**
     * Could be implemented on class level.
     *
     * @returns {string}
     */
    public static getUpdateEndpoint(): string {
        return this.getBaseEndpoint() + "/update";
    }

    /**
     * Could be implemented on class level.
     *
     * @returns {string}
     */
    public static getDeleteEndpoint(): string {
        return this.getBaseEndpoint() + "/delete";
    }

    /**
     * Could be implemented on class level.
     *
     * @returns {string}
     */
    public static getArchiveEndpoint(): string {
        return this.getBaseEndpoint() + "/archive";
    }

    /**
     * Could be implemented on class level.
     *
     * @returns {string}
     */
    public static getRetrieveEndpoint(): string {
        return this.getBaseEndpoint() + "/retrieve";
    }

    /**
     * Could be implemented on class level.
     *
     * @returns {string}
     */
    public static getRetrieveAllEndpoint(): string {
        return this.getBaseEndpoint() + "/retrieve/all";
    }

    /**
     * Has to be implemented on class level.
     *
     * @returns {string}
     */
    public static getApiKey(): string {
        throw NOT_IMPLEMENTED_EXCEPTION;
    }

    /**
     * Could be implemented on class level.
     *
     * @returns {string}
     */
    public static getPluralApiKey(): string {
        return this.getApiKey() + "s";
    }

    // ---
    // CRUD operations
    // ---
    // TODO: error handling

    /**
     * Given data is expected to be valid.
     * @param {DataController} data
     * @returns {Promise<any>}
     */
    public static async create(data: DataController): Promise<any> {
        // TODO: can this be done better than <any>? With some sort of generics?
        const dataDict = await data.asDict();
        return new Promise((resolve, reject) => {
            doJsonApiCall(this.getCreateEndpoint(), dataDict, (result) => {
                const apiController = new this(result[this.getApiKey()]._id, ConstructionMethods.Data, result[this.getApiKey()]);
                resolve(apiController);
            }, false, () => reject());
        });
    }

    public static async retrieveAll(): Promise<any[]> {
        return await new Promise<any[]>((resolve, reject) => {
            doJsonGetApiCall(this.getRetrieveAllEndpoint(), async (result) => {
                const listOfResults = result[this.getPluralApiKey()];
                const output = [];
                for (const res of listOfResults) {
                    const apiController = new this(res._id, ConstructionMethods.PromiseToRetrieve);
                    await apiController.parseFromDictionary(res);
                    output.push(apiController);
                }
                resolve(output);
            }, false, () => reject());
        });
    }

    public static async retrieve(id: ObjectID): Promise<any> {
        // TODO: can this be done better than <any>? With some sort of generics?
        if(! isValidId(id)) {
            throw ID_EXCEPTION;
        }
        const r = new this(id, ConstructionMethods.PromiseToRetrieve);
        await r.retrieve();
        return r;
    }

    public static clone(apiController: any): any{
        // TODO: can this be done better than any With some sort of generics?
        const dataClone = apiController.isRetrieved() ? apiController.getData().clone() : apiController.getData();
        return new this(apiController.getId(), ConstructionMethods.Data, dataClone);
    }
    private _sid: string;

    private retrieving: boolean = false;
    private retrieved: boolean = false;

    @DataController.mergedDataProperty()
    private data: T;

    /**
     * Defaults to lazy construction method.
     *
     * @param {} id
     * @param {ConstructionMethods} constructionMethod
     * @param {T} data?
     */
    public constructor(id: ObjectID | string, constructionMethod: ConstructionMethods = ConstructionMethods.Data, data?: T) {
        super(id);
        switch (constructionMethod) {
            case ConstructionMethods.Data: {
                this.data = data;
                this.retrieved = true;
                break;
            }
        }
    }

    public setId(id: ObjectID) {
        super.setId(id);
    }

    /**
     * Given data is expected to be valid.
     *
     * @param {string} property
     * @param value
     * @returns {Promise<void>}
     */
    public async updateProperty(property: string, value: any): Promise<void> {
        // TODO: can this be done better than <any>? With some sort of generics?
        return new Promise<void>((resolve, reject) => {
            doJsonApiCall(this.getClass().getUpdateEndpoint() + "/" + property.toLowerCase() + "/" + this.getId().toString(), {[property]: value}, async (result) => {
                await this.parseFromDictionary(result[this.getClass().getApiKey()]);
                resolve();
            }, false, () => reject());
        });
    }

    public async updateEntirely(data: T): Promise<void> {
        const d = await data.asDict();
        return new Promise<void>((resolve, reject) => {
            doJsonApiCall(this.getClass().getUpdateEndpoint() + "/" + this.getId().toString(), d, async (result) => {
                await this.parseFromDictionary(result[this.getClass().getApiKey()]);
                resolve();
            }, false, () => reject());
        });
    }

    public async pushUpdate(): Promise<void> {
        await this.updateEntirely(this.getData());
    }

    public async delete(): Promise<void> {
        // TODO: can this be done better than <any>? With some sort of generics?

        return new Promise<void>((resolve, reject) => {
            doJsonDeleteApiCall(this.getClass().getDeleteEndpoint() + "/" + this.getId().toString(), (result) => {
                resolve();
            }, false, () => reject());
        });
    }

    public async archive(): Promise<void> {
        // TODO: can this be done better than <any>? With some sort of generics?

        return new Promise<void>((resolve, reject) => {
            doJsonDeleteApiCall(this.getClass().getArchiveEndpoint() + "/" + this.getId().toString(), (result) => {
                resolve();
            }, false, () => reject());
        });
    }

    public getData(): T {
        return this.data;
    }

    public setData(data: T): void {
        this.data = data;
        this.retrieved = true;
    }

    public isRetrieved(): boolean {
        return this.retrieved;
    }

    public isRetrieving(): boolean {
        return this.retrieving;
    }

    /**
     * Method that can parse database representation and apply this to the current object.
     * Has to be implemented on class level.
     *
     * @param dictionary
     */
    public async parseFromDictionary(dictionary): Promise<void> {
        throw NOT_IMPLEMENTED_EXCEPTION;
    }

    public async retrieve(): Promise<void> {
        this.retrieving = true;

        const res = await new Promise<any[]>((resolve, reject) => {
            doJsonGetApiCall(this.getClass().getRetrieveEndpoint() + "/" + this.getId().toString(), (result) => {
                resolve(result[this.getClass().getApiKey()]);
            }, false, () => reject());
        });
        await this.parseFromDictionaryRunner(res);

        this.retrieved = true;
        this.retrieving = false;
    }

    public async retrieveIfNot(): Promise<void> {
        // If retrieved, don't do anything
        if (this.isRetrieved()) {
            return;
        }

        // If not yet retrieved, retrieve
        await this.retrieve();
    }

    public async asDict(): Promise<any> {
        await this.getData();
        return await super.asDict();
    }

    public async parseFromDictionaryRunner(dictionary): Promise<void> {
        await this.parseFromDictionary(dictionary);
    }

    private getClass(): any {
        return this.constructor;
    }
}
