import { tArticle, tHandrail, tQuote, tVersionedObjectId, tObjectId, tExtraHandrail } from "../../../shared/datastructures/simplifiedNetworkCommunicationTypes";
import ArticleInput from "../../../shared/datastructures/articleinput/ArticleInput";
import StaircaseTypeAPI from "./apicontroller/staircasedata/StaircaseTypeAPI";
import WoodTypeAPI from "./apicontroller/staircasedata/WoodTypeAPI";
import ShapeInput from "../../../shared/datastructures/articleinput/ShapeInput";
import { RiserThicknessAPI, StringerThicknessAPI, TreadThicknessAPI } from "./apicontroller/staircasedata/ThicknessAPI";
import StaircaseShapeAPI from "./apicontroller/staircasedata/StaircaseShapeAPI";
import { SupplementTreadAPI, PlacementSupplementAPI, SupplementHandrailAPI, SupplementAPI } from "./apicontroller/staircasedata/SupplementAPI";
import LandingStageInput from "../../../shared/datastructures/articleinput/LandingStageInput";
import HandrailPoleTypeAPI from "./apicontroller/staircasedata/HandrailPoleTypeAPI";
import TreatmentInput from "../../../shared/datastructures/articleinput/TreatmentInput";
import TreatmentTypeAPI from "./apicontroller/staircasedata/TreatmentTypeAPI";
import TransportationInput from "../../../shared/datastructures/articleinput/TransportationInput";
import StaircasePlacementOptionAPI from "./apicontroller/staircasedata/StaircasePlacementOptionAPI";
import HandrailInput from "../../../shared/datastructures/articleinput/HandrailInput";
import HandrailTypeAPI from "./apicontroller/staircasedata/HandrailTypeAPI";
import SpindleThreadTypeAPI from "./apicontroller/staircasedata/SpindleThreadTypeAPI";
import PortalArticleInfoStore from './pages/newquote/article/loaders/PortalArticleInfoStore';
import store from "./store";
import * as tPromise from 'io-ts-promise';
import { setBasicInformation } from "./actions/quoteCreationActions";
import { IQuoteCreationState } from "./reducers/quoteCreationReducer";
import DistributorAPI from "./apicontroller/DistributorAPI";
import ObjectID from "bson-objectid";
import { RetrievedArticleData } from "./pages/newquote/article/loaders/loaderHelper";
import HandrailHandleAPI from "./apicontroller/staircasedata/HandrailHandleAPI";
import HandrailPoleFinishAPI from "./apicontroller/staircasedata/HandrailPoleFinishAPI";
import CommentInput from "../../../shared/datastructures/articleinput/CommentInput";
import ExtraHandrailInput from "../../../shared/datastructures/articleinput/ExtraHandrailInput";
import WallHandrailInput from "../../../shared/datastructures/articleinput/WallHandrailInput";
import WallHandrailAPI from "./apicontroller/staircasedata/WallHandrailAPI";
import WallHookAPI from "./apicontroller/staircasedata/WallHookAPI";
import QuoteLog from "../../../shared/datastructures/QuoteLog";

const infoStore = () => (store.getState().globalInformation);
const articleInfoStore = () => (store.getState().quoteCreation);
export async function rawArticleToArticleInput(article: any): Promise<ArticleInput>{
    const tArt = await tPromise.decode(tArticle, article);
    return tArticleToArticleInput(tArt);
}

export async function loadQuoteToStore(quote: tQuote, id: ObjectID, articleInformation: RetrievedArticleData, logs: QuoteLog[]): Promise<void>{
    
    const quoteCreationState: IQuoteCreationState = {
        quoteId: id,

        customerName: quote.customerName,
        customerStreet: quote.customerAddress.streetAndNumber,
        customerCity: quote.customerAddress.city,
        customerPostalCode: quote.customerAddress.postalCode,
        customerCountry: quote.customerAddress.country,
        customerPhone: quote.customerPhone,
        customerEmail: quote.customerEmail,

        constructionSiteStreet: quote.workSiteAddress.streetAndNumber,
        constructionSiteCity: quote.workSiteAddress.city,
        constructionSitePostalCode: quote.workSiteAddress.postalCode,
        constructionSiteCountry: quote.workSiteAddress.country,

        equalAddresses: false,

        distributor: articleInformation.availableDistributors.find((x: DistributorAPI) => x.getId().equals(quote.distributorId)),
        ownerId: quote.ownerId,
        ownerName: quote.ownerName,
        quoteState: quote.quoteState,
        quoteNumber: quote.quoteNumber,
        quoteCalculationVersion: quote.quoteCalculationVersion,

        articles: await Promise.all(quote.articles.map(async (a) => tArticleToArticleInput(a, articleInformation))),
        
        ...articleInformation,

        distance: quote.distance,
        origin: quote.origin,
        destination: quote.destination,

        stage: quote.stage,
        identification: quote.identification,
        title: quote.title,
        description: quote.description,

        expirationDate: quote.expirationDate,
        expired: quote.expired,
        creationDate: quote.creationDate,
        lastUpdateDate: quote.lastUpdateDate,
        dataDate: quote.dataDate,
        _tmpDataDateChecked: false,

        logs: logs.map(x => QuoteLog.fromObj(x)),

        _localShowPriceDetails: false,
        _localShowDiscount: false,

    }

    store.dispatch(setBasicInformation({
        ...quoteCreationState
    }));

}

export async function tArticleToArticleInput(article: tArticle, articleData?: RetrievedArticleData): Promise<ArticleInput>{
    let infoStore = articleInfoStore;
    if(articleData){
        infoStore = () => articleData;
    }
    
    function loadHandrailInput(data: tHandrail, supplements: SupplementAPI[]): HandrailInput{
        if(data === null){
            return null;
        }
        return new HandrailInput(
            infoStore().availableHandrailTypes.find((x: HandrailTypeAPI) => x.getId().equals(data.type)),
            infoStore().availableHandrailHandles.find((x: HandrailHandleAPI) => x.getId().equals(data.handle)),
            data.spindleThreadType ? infoStore().availableSpindleThreadTypes.find((x: SpindleThreadTypeAPI) => x.getId().equals(data.spindleThreadType)) : null,
            data.spindleThreadDescription ?? null,
            data.supplements.map(sup => {return {
                amount: sup.amount,
                supplement: supplements.find((x: SupplementAPI) => x.getId().equals(sup.supplement)),
            }}),
            data.handrailPoleTypes.map(pt => 
                infoStore().availableHandrailPoleTypes.find((x: HandrailPoleTypeAPI) => x.getId().equals(pt))
            ),
            data.handrailPoleFinishes.map(pt => 
                infoStore().availableHandrailPoleFinishes.find((x: HandrailPoleFinishAPI) => x.getId().equals(pt))
            ),
        )
    }
    
    function loadExtraHandrailInput(data: tExtraHandrail): ExtraHandrailInput{
        if(data === null){
            return null;
        }
        return new ExtraHandrailInput(
            infoStore().availableHandrailTypes.find((x: HandrailTypeAPI) => x.getId().equals(data.type)),
            infoStore().availableHandrailHandles.find((x: HandrailHandleAPI) => x.getId().equals(data.handle)),
            data.spindleThreadType ? infoStore().availableSpindleThreadTypes.find((x: SpindleThreadTypeAPI) => x.getId().equals(data.spindleThreadType)) : null,
            data.spindleThreadDescription ?? null,
            data.handrailPoleTypes.map(pt => 
                infoStore().availableHandrailPoleTypes.find((x: HandrailPoleTypeAPI) => x.getId().equals(pt))
            ),
            data.handrailPoleFinishes.map(pt => 
                infoStore().availableHandrailPoleFinishes.find((x: HandrailPoleFinishAPI) => x.getId().equals(pt))
            ),
            data.treadCount,
            data.poleCount,
        )
    }

    return new ArticleInput(
        new PortalArticleInfoStore(),

        article.reference,
        article.unitCount,
        article.width,
        article.floorHeight,
        article.treadCount,
        infoStore().availableStaircaseTypes.find((x: StaircaseTypeAPI) => x.getId().equals(article.type)),
        infoStore().availableWoodTypes.find((x: WoodTypeAPI) => x.getId().equals(article.woodType)),
        infoStore().availableWoodTypes.find((x: WoodTypeAPI) => x.getId().equals(article.riserWoodType)),
        article.isWood,
        new ShapeInput(
            article.shape.riserCount,
            article.shape.rawRiserThickness ? infoStore().availableRiserThickness.find((x: RiserThicknessAPI) => x.getId().equals(article.shape.rawRiserThickness)) : null,
            infoStore().availableStaircaseShapes.find((x: StaircaseShapeAPI) => x.getId().equals(article.shape.staircaseShape)),
            infoStore().availableStringerThickness.find((x: StringerThicknessAPI) => x.getId().equals(article.shape.stringerThickness)),
            article.shape.treadSupplements.map(sup => {return {
                amount: sup.amount,
                supplement: infoStore().availableSupplementsTread.find((x: SupplementTreadAPI) => x.getId().equals(sup.supplement)),
            }}),
            infoStore().availableTreadThickness.find((x: TreadThicknessAPI) => x.getId().equals(article.shape.treadThickness)),
            article.shape.closedStaircase,
        ),
        await loadHandrailInput(article.handrail, infoStore().availableSupplementsHandrail),
        await loadExtraHandrailInput(article.extraHandrail),
        new LandingStageInput(
            article.landingStage.handrail ? loadHandrailInput(article.landingStage.handrail, infoStore().availableSupplementsLandingStageHandrail) : null,
            article.landingStage.handrailLength,
            article.landingStage.concreteEnclosureLength,
            article.landingStage.concreteEnclosureWidth,
            article.landingStage.concreteEnclosureWoodType === null ? null : infoStore().availableWoodTypes.find((x: WoodTypeAPI) => x.getId().equals(article.landingStage.concreteEnclosureWoodType)),
            article.landingStage.handrailPoleCount,
            article.landingStage.handrailPoleTypes.map(pt => 
                infoStore().availableHandrailPoleTypes.find((x: HandrailPoleTypeAPI) => x.getId().equals(pt))
            ),
            article.landingStage.handrailPoleFinishes.map(pt => 
                infoStore().availableHandrailPoleFinishes.find((x: HandrailPoleFinishAPI) => x.getId().equals(pt))
            ),
        ),
        article.treatment === null ? null : new TreatmentInput(
            infoStore().availableTreatmentTypes.find((x: TreatmentTypeAPI) => x.getId().equals(article.treatment.type)),
            article.treatment.colors,
        ),
        new TransportationInput(
            article.transportation.transportationMethod,
            article.transportation.placementSupplements.map(sup => {return {
                amount: sup.amount,
                supplement: infoStore().availableSupplementsPlacement.find((x: PlacementSupplementAPI) => x.getId().equals(sup.supplement)),
            }}),
            article.transportation.staircasePlacementOptions.map(po => 
                infoStore().availableStaircasePlacementOptions.find((x: StaircasePlacementOptionAPI) => x.getId().equals(po))
            ),
            article.transportation.measurementByHotec,
            article.transportation.assembledByHotec,
        ),
        CommentInput.fromTComments(article.comments),
        article.variantGroup,
        article.variantIncluded,
        article.wallHandrail ? new WallHandrailInput(
            infoStore().availableWallHandrails.find((x: WallHandrailAPI) => x.getId().equals(article.wallHandrail.type)),
            article.wallHandrail.hook ? infoStore().availableWallHooks.find((x: WallHookAPI) => x.getId().equals(article.wallHandrail.hook)) : null,
            article.wallHandrail.length,
            article.wallHandrail.hookCount,
            article.wallHandrail.bendCount,
            article.wallHandrail.ignoreWoodType,
        ) : null,
        article.discountPercentageStaircase,
        article.discountPercentageTreatment,
        article.discountPercentageDistribution,
        article.quoteId,
        article.id,
    )
}