import ObjectID from "bson-objectid";
import IArticleInput from "../IArticleInput";

export default class VariantGroup{

    public groupId: ObjectID;
    public main: IArticleInput = null;
    public children: IArticleInput[] = [];

    constructor(groupId: ObjectID){
        this.groupId = groupId;
    }

    public setMain(article: IArticleInput){
        this.main = article;
    }

    public addChild(article: IArticleInput){
        this.children.push(article);
    }

    public static fromArticles(articles: IArticleInput[]): VariantGroup[]{
        const res: VariantGroup[] = [];

        for (const article of articles) {

            if(article.variantGroup === null){
                // Not part of a variant group
                const vg = new VariantGroup(article.variantGroup);
                vg.setMain(article);
                res.push(vg)
            }else{
                // Part of a variant group
                const i = res.findIndex(x => x.groupId?.toString() === article.variantGroup?.toString());
                let vg: VariantGroup = null;
                if(i > -1){
                    vg = res[i]; // Variant group already present
                }else{
                    vg = new VariantGroup(article.variantGroup); // Variant group doesn't exist yet
                    res.push(vg);
                }

                if(article.id?.toString() == article.variantGroup?.toString()){
                    // Parent variant
                    vg.setMain(article);
                }else{
                    // Child variant
                    vg.addChild(article);
                }
            }
        }

        return res;
    }

}