import * as React from 'react';
import styleable from 'react-styleable';
import moment from 'moment';

import * as styles from '../../../css/components/Overview/QuoteRow.css'

import StatusIcon from './StatusIcon';

import {t} from 'ttag';
import { quoteStateToLocale } from '../../helpers';
import { DuplicateIcon } from '../Icon';

/**
 * Quote row.
 *
 * ## styleable
 * * **container** Container styling.
 * * **overviewRow** The first row with the basic quote overview.
 * * **cell** Single cell styling.
 * * **statusCell** Status cell styling.
 * * **customerNameCell** Customer name cell styling.
 * * **variantsDescriptionCell** Variants Description cell styling.
 * * **distributorCell** Distributor cell styling.
 * * **dateCell** Date cell styling.
 * * **collapseIconCell** Collapse icon cell styling.
 * * **statusIcon** Status icon styling.
 * * **icon** Single icon styling.
 *
 * @property {quote object} props.quote - Quote
 * @property {(quote object) => any} props.onClick - onClick event
 * @property {string} props.status - Order status
 * @property {string} props.customerName - Customer name
 * @property {array} props.variants - Array of variants
 * @property {date} props.creationDate - Quote creation date
 *
 */
class QuoteRow extends React.Component<any> {
    render() {
        let creationDate = moment(this.props.creationDate);
        return (
            <div className={this.props.css.container} onClick={() => {if(this.props.onClick){this.props.onClick(this.props.quote)}}}>
                <div className={this.props.css.overviewRow}>
                    <div className={this.props.css.cell + " " + this.props.css.statusCell}>
                        {/*{this.props.status}*/}
                        <StatusIcon status={this.props.status} css={{compose: {icon: this.props.css.statusIcon}}}/>
                    </div>
                    <div className={this.props.css.cell + " " + this.props.css.quoteNumberCell}>
                        {this.props.quote.quoteNumber !== "-1" ? this.props.quote.quoteNumber : ""}
                    </div>
                    <div className={this.props.css.cell + " " + this.props.css.customerNameCell}>
                        {this.props.customerName ? <b>{this.props.customerName}</b>: ""}{this.props.quote.title ? " - " + this.props.quote.title : ""}
                    </div>      
                    <div className={this.props.css.cell + " " + this.props.css.quoteStateCell}>
                        {quoteStateToLocale(this.props.quote.quoteState)}
                    </div>
                    <div className={this.props.css.cell + " " + this.props.css.ownerNameCell}>
                        {this.props.quote.ownerName}
                    </div>
                    <div className={this.props.css.cell + " " + this.props.css.dateCell}>
                        {creationDate.format('DD/MM/YYYY HH:mm')}
                    </div>
                    <div className={this.props.css.cell + " " + this.props.css.duplicateCell}>
                        <button onClick={(e) => {
                            e.stopPropagation();
                            if(this.props.onDuplicate)
                                this.props.onDuplicate(this.props.quote);
                        }} title={t`Maak een kopie`}><DuplicateIcon/></button>
                    </div>
                </div>
            </div>
        );
    }
}
export default styleable(styles)(QuoteRow)