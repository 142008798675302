import DataController from "orbiter-core/src/datastructures/DataController";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import { toNumber } from "orbiter-core/src/basic";

export interface IHandrailPoleFinish extends DataController {
    getPrice(): number;
    getTitle(): MultiLanguageString;
    getDescription(): MultiLanguageString;
    setPrice(newPrice: number): void | Promise<void>;
    setTitle(newTitle: MultiLanguageString): void | Promise<void>;
    setDescription(newDescription: MultiLanguageString): void | Promise<void>;
}

export default class HandrailPoleFinish extends DataController implements IHandrailPoleFinish {

    @DataController.dataProperty(async (x) => await x.asList())
    private title: MultiLanguageString;
    @DataController.dataProperty(async (x) => await x.asList())
    private description: MultiLanguageString;
    @DataController.dataProperty()
    private price: number;

    public constructor(
        title: MultiLanguageString, 
        description: MultiLanguageString,
        price: number,
    ){
        super();
        this.setTitle(title);
        this.setDescription(description);
        this.setPrice(price);
    }

    public getTitle(): MultiLanguageString {
        return this.title;
    }

    public getDescription(): MultiLanguageString {
        return this.description;
    }
    
    public getPrice(): number {
        return this.price;
    }

    public setTitle(newTitle: MultiLanguageString): void {
        this.title = newTitle;
    }

    public setDescription(newDescription: MultiLanguageString): void {
        this.description = newDescription;
    }
    
    public setPrice(newPrice: number): void {
        // if(!isValidNumber(newPrice)) {
        //     throw VALUE_EXCEPTION;
        // }
        this.price = toNumber(newPrice);
    }

    public clone(): HandrailPoleFinish{
        return new HandrailPoleFinish(
            this.getTitle().clone(),
            this.getDescription().clone(),
            this.getPrice(),
        )    
    }

}
