import * as React from "react";
import {connect} from "react-redux";
import styleable from "react-styleable";

import SubTitle from "../../components/SubTitle";
import StepTemplate from "./StepTemplate";

import {setBasicInformation, setEqualAddresses} from "../../actions/quoteCreationActions";
import * as styles from "../../../css/pages/step1.css";

import PortalCheckbox from "webc-reactcore/src/js/components/mainlayout/PortalCheckbox";
import Input from "webc-reactcore/src/js/components/mainlayout/PortalInput";
import Select from "webc-reactcore/src/js/components/mainlayout/PortalSelect";
import SelectItem from "webc-reactcore/src/js/components/mainlayout/PortalSelectItem";
import * as PortalCheckboxStyle from "../../../css/PortalCheckbox.css";
import * as PortalSelectStyle from "../../../css/PortalSelect.css";
import DistributorAPI from "../../apicontroller/DistributorAPI";
import {inu, inun} from "orbiter-core/src/basic";
import {t} from "ttag";
import { updateCustomerProperty, updateDistributor, updateAddress, recalculateDistance, updateDistance, updateExpirationDate, updateOwnerId, updateQuoteState } from "../../apicontroller/quote";
import { tAddress, tObjectId } from "../../../../../shared/datastructures/simplifiedNetworkCommunicationTypes";
import moment, { Moment } from "moment";
import { updateQuoteDataDate } from "./article/loaders/loaderHelper";
import { PopupManager, Popup } from "../../components/Popup";
import HotecUserAPI from "../../apicontroller/HotecUserAPI";
import { hasMatchingPermission, quoteStateToLocale } from "../../helpers";
import { DATA_ROOT } from "../../../../../shared/permissions";
import PortalDropdown from "webc-reactcore/src/js/components/mainlayout/PortalDropdown";
import * as PortalDropdownStyle from "../../../css/PortalDropdown.css";
import { QUOTE_STATES } from "../../../../../shared/datastructures/Quote";


/**
 * New quote step 1 page.
 *
 */
@(connect((store: any) => {
    return {
        _tmpDataDateChecked : store.quoteCreation._tmpDataDateChecked,

        equalAddresses : store.quoteCreation.equalAddresses,

        customerName: store.quoteCreation.customerName,
        customerStreet: store.quoteCreation.customerStreet,
        customerCity: store.quoteCreation.customerCity,
        customerPostalCode: store.quoteCreation.customerPostalCode,
        customerCountry: store.quoteCreation.customerCountry,
        customerPhone: store.quoteCreation.customerPhone,
        customerEmail: store.quoteCreation.customerEmail,

        constructionSiteStreet: store.quoteCreation.constructionSiteStreet,
        constructionSiteCity: store.quoteCreation.constructionSiteCity,
        constructionSitePostalCode: store.quoteCreation.constructionSitePostalCode,
        constructionSiteCountry: store.quoteCreation.constructionSiteCountry,
        constructionSitePhone: store.quoteCreation.constructionSitePhone,

        distributors: store.quoteCreation.availableDistributors,
        users: store.quoteCreation.availableUsers,
        distributor : store.quoteCreation.distributor,
        ownerId : store.quoteCreation.ownerId,
        quoteState : store.quoteCreation.quoteState,
        quoteNumber : store.quoteCreation.quoteNumber,
        quoteCalculationVersion: store.quoteCreation.quoteCalculationVersion,
        
        quoteId : store.quoteCreation.quoteId,

        distance: store.quoteCreation.distance,
        origin: store.quoteCreation.origin,
        destination: store.quoteCreation.destination,
        
        stage: store.quoteCreation.stage,
        identification: store.quoteCreation.identification,
        title: store.quoteCreation.title,
        description: store.quoteCreation.description,

        creationDate: store.quoteCreation.creationDate ? moment(store.quoteCreation.creationDate) : null,
        expirationDate: store.quoteCreation.expirationDate ? moment(store.quoteCreation.expirationDate) : null,
        expired: store.quoteCreation.expired,
        lastUpdateDate: store.quoteCreation.lastUpdateDate ? moment(store.quoteCreation.lastUpdateDate) : null,
        dataDate: store.quoteCreation.dataDate ? moment(store.quoteCreation.dataDate) : null,

    };
}) as any)
class Step1 extends React.Component<any, {tmpExpirationDate: string, tmpChangingExpiryDate: boolean, tmpSavingExpiryDate: boolean}> {

    constructor(_){
        super(_);
        this.state = {tmpExpirationDate: "", tmpChangingExpiryDate: false, tmpSavingExpiryDate: false};
    }

    public setBasicInformation(info){
        this.props.dispatch(setBasicInformation(info));
    }

    private isDataDateExpired(){
        // The popup should be shown when:
        //  - The quote is expired and it was not updated after expiry, or when
        //  - The quote was not updated in the last month
        const dataDate: Moment = this.props.dataDate;
        if(dataDate){
            if(this.props.expired){
                const expiryDate: Moment = moment(this.props.expirationDate);
                const lastUpdatedBeforeExpiryDate: boolean = expiryDate.diff(dataDate, 'minutes') > 0; // Quote was not updated after expiry
                if(lastUpdatedBeforeExpiryDate){
                    return true;
                }
            }
            const currentDate: Moment = moment();
            const olderThanMonth: boolean = currentDate.diff(dataDate, 'months') > 1; // Quote was not updated in the last month
            if(olderThanMonth){
                return true;
            }
        }
        return false;
    }

    public setEqualAddresses(val){
        this.props.dispatch(setEqualAddresses(val));
        if(val && this.props.quoteId){
            updateAddress(this.props.quoteId, 'worksite', {streetAndNumber: this.props.customerStreet, city: this.props.customerCity, postalCode: this.props.customerPostalCode, country: this.props.customerCountry});
            this.setBasicInformation({
                constructionSiteStreet: this.props.customerStreet,
                constructionSiteCity: this.props.customerCity,
                constructionSitePostalCode: this.props.customerPostalCode,
                constructionSiteCountry: this.props.customerCountry,
            })
        }
    }

    // TODO: updating all these properties individually raises the version count to > 20 without even adding articles. Could this be improved..?
    private async updateCustomerAddress(address: tAddress){
        await updateAddress(this.props.quoteId, 'home', address);
        if(this.props.equalAddresses){
            await updateAddress(this.props.quoteId, 'worksite', address);
        }
    }

    private async updateWorkSiteAddress(address: tAddress){
        if(this.props.equalAddresses){
            return;
        }
        await updateAddress(this.props.quoteId, 'worksite', address);
    }

    componentDidUpdate(){
        this.checkDataDateExpiration();
    }
    componentDidMount(){
        this.checkDataDateExpiration();
    }
    private checkDataDateExpiration(){
        if(this.props.dataDate && !this.props._tmpDataDateChecked && this.isDataDateExpired() && !this.state.tmpSavingExpiryDate){
            this.setBasicInformation({_tmpDataDateChecked: true});
            PopupManager.addPopup(new Popup(<p>{t`Opgelet, deze offerte werd voor het laatst bijgewerkt op` + " " + this.props.dataDate.format('DD/MM/YYYY') + ". " + t`Wil je deze offerte bijwerken? Hiermee worden alle productkenmerken en prijzen van deze offerte bijgewerkt naar de nieuwste informatie.`}</p>, [
                {
                    text: t`Werk bij`,
                    onClick: ()=>{
                        updateQuoteDataDate(this.props.quoteId);
                        PopupManager.closePopup();
                    }
                },
                {
                    text: t`Werk niet bij`,
                    onClick: ()=>{
                        PopupManager.closePopup();
                    },
                }
            ]))
        }
    }

    public render() {

        // Construction site address when differing from customer address
        const differentAddresses = this.props.equalAddresses ? null : <div>
            <Input placeholder={t`Straat`} onChange={(constructionSiteStreet) => this.setBasicInformation({constructionSiteStreet})} value={this.props.constructionSiteStreet} onBlur={(constructionSiteStreet: string) => this.updateWorkSiteAddress({streetAndNumber: constructionSiteStreet, city: this.props.constructionSiteCity, postalCode: this.props.constructionSitePostalCode, country: this.props.constructionSiteCountry})}/>
            <Input placeholder={t`Gemeente`} onChange={(constructionSiteCity) => this.setBasicInformation({constructionSiteCity})} value={this.props.constructionSiteCity} onBlur={(constructionSiteCity: string) => this.updateWorkSiteAddress({streetAndNumber: this.props.constructionSiteStreet, city: constructionSiteCity, postalCode: this.props.constructionSitePostalCode, country: this.props.constructionSiteCountry})}/>
            <Input placeholder={t`Postcode`} onChange={(constructionSitePostalCode) => this.setBasicInformation({constructionSitePostalCode})} value={this.props.constructionSitePostalCode} onBlur={(constructionSitePostalCode: string) => this.updateWorkSiteAddress({streetAndNumber: this.props.constructionSiteStreet, city: this.props.constructionSiteCity, postalCode: constructionSitePostalCode, country: this.props.constructionSiteCountry})}/>
            <Input placeholder={t`Land`} onChange={(constructionSiteCountry) => this.setBasicInformation({constructionSiteCountry})} value={this.props.constructionSiteCountry} onBlur={(constructionSiteCountry: string) => this.updateWorkSiteAddress({streetAndNumber: this.props.constructionSiteStreet, city: this.props.constructionSiteCity, postalCode: this.props.constructionSitePostalCode, country: constructionSiteCountry})}/>
            {/* <Input placeholder={t`Telefoon`} onChange={(constructionSitePhone) => this.setBasicInformation({constructionSitePhone})} value={this.props.constructionSitePhone}/> // TODO: is this necessary? */} 
        </div>;
        
        const quoteId: tObjectId = this.props.quoteId;
        
        return (
            <StepTemplate title={t`kennismaking.`} next="/quote/2">
                <div className={"container-fluid"}>
                    <div className="row">
                        <div className="col-md-4">
                            {!hasMatchingPermission(DATA_ROOT) ? "" : <>
                                    <SubTitle>{t`Verdeler`}</SubTitle>
                                    <PortalDropdown placeholder={t`Verdeler`}
                                        css={{ compose: PortalDropdownStyle }}
                                        items={this.props.distributors.map(x => ({
                                            title: x.getName(),
                                            id: x.getSid(),
                                            item: x,
                                        }))}
                                        onSelectionChange={async (data) => {
                                            this.setBasicInformation({
                                                distributor: data?.item ? DistributorAPI.clone(data.item) : null,
                                            });
                                            if(data?.item){
                                                updateDistributor(quoteId, data.item.getId())
                                            }
                                        }}
                                        selectedId={this.props.distributor?.getSid() ?? null}
                                    />
                                    <PortalDropdown placeholder={t`Eigenaar`}
                                        css={{ compose: PortalDropdownStyle }}
                                        items={this.props.users.filter(user => user.getDistributorId() && user.getDistributorId()?.toHexString() === this.props.distributor?.getSid()).map(x => ({
                                            title: x.getName(),
                                            id: x.getSid(),
                                            item: x,
                                        }))}
                                        onSelectionChange={async (data) => {
                                            this.setBasicInformation({
                                                ownerId: data?.item ? data.item.getSid() : null,
                                            });
                                            if(data?.item){
                                                updateOwnerId(quoteId, data.item.getSid())
                                            }
                                        }}
                                        selectedId={this.props.ownerId ?? null}
                                    />
                                </>
                            }
                            <SubTitle>{t`Referentie`}</SubTitle>
                            <Input placeholder={t`Referentie`} onChange={(title) => this.setBasicInformation({title})} onBlur={(title: string) => updateCustomerProperty(quoteId, 'title', title)} value={this.props.title}/>
                            <br/>
                            <br/>

                            <SubTitle>{t`Status`}</SubTitle>
                            <PortalDropdown placeholder={t`Status`}
                                css={{ compose: PortalDropdownStyle }}
                                items={QUOTE_STATES.map(x => ({
                                    title: quoteStateToLocale(x),
                                    id: x,
                                    item: x,
                                }))}
                                onSelectionChange={async (data) => {
                                    this.setBasicInformation({
                                        quoteState: data?.item,
                                    });
                                    if(data?.item){
                                        updateQuoteState(quoteId, data.item);
                                    }
                                }}
                                selectedId={this.props.quoteState ?? null}
                            />

                            <hr/>
                            
                            {this.props.quoteNumber ? <p>{t`Offertenummer`}: <i>{this.props.quoteNumber}</i></p> : ""}
                            {this.props.quoteState ? <p>{t`Status`}: <i>{quoteStateToLocale(this.props.quoteState)}</i></p> : ""}
                            
                            <br/>

                            {this.props.creationDate ? <p>{t`Aanmaakdatum`}: <i>{this.props.creationDate.format('DD/MM/YYYY HH:mm')}</i></p> : ""}
                            {this.props.expirationDate ? this.state.tmpChangingExpiryDate ? 
                                <p>{t`Geldig tot`}: <input className="date-change-input"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        this.setState({tmpExpirationDate: e.target.value});
                                    }} 
                                    onBlur={async (e: React.ChangeEvent<HTMLInputElement>) => {
                                        console.log("updating", moment(e.target.value, "DD/MM/YYYY HH:mm"));
                                        this.setState({tmpExpirationDate: "", tmpChangingExpiryDate: false, tmpSavingExpiryDate: true});
                                        
                                        const newDate = moment(e.target.value, "DD/MM/YYYY HH:mm").toDate();
                                        this.setBasicInformation({expirationDate: newDate});
                                        
                                        const result = await updateExpirationDate(quoteId, newDate);
                                        this.setBasicInformation({expirationDate: result.expirationDate});

                                        this.setState({tmpSavingExpiryDate: false});
                                    }} 
                                    value={this.state.tmpExpirationDate}
                                /></p>
                            : 
                                (<p>{t`Geldig tot`}: <i className={this.props.expired ? this.props.css.expired : ""}>{this.props.expirationDate.format('DD/MM/YYYY HH:mm')}</i> <a style={{cursor: "pointer"}} onClick={() => {
                                    this.setState({tmpChangingExpiryDate: true, tmpExpirationDate: this.props.expirationDate.format('DD/MM/YYYY HH:mm')});
                                }}>{t`bijwerken`}</a></p>) : ""}
                            {this.props.lastUpdateDate ? <p>{t`Laatst gewijzigd`}: <i>{this.props.lastUpdateDate.format('DD/MM/YYYY HH:mm')}</i></p> : ""}
                            {this.props.dataDate ? <p>{t`Gegevensdatum`}: <i>{this.props.dataDate.format('DD/MM/YYYY HH:mm')}</i> <a style={{cursor: "pointer"}} onClick={() => {
                                PopupManager.addPopup(new Popup(<p>{t`Opgelet, hiermee worden alle productkenmerken en prijzen van deze offerte bijgewerkt naar de nieuwste informatie. Wil je zeker verdergaan?`}</p>, [
                                    {
                                        text: t`Werk bij`,
                                        onClick: ()=>{
                                            updateQuoteDataDate(this.props.quoteId);
                                            PopupManager.closePopup();
                                        }
                                    },
                                    {
                                        text: t`Werk niet bij`,
                                        onClick: ()=>{
                                            PopupManager.closePopup();
                                        },
                                    }
                                ]))
                            }}>{t`bijwerken`}</a></p> : ""}
                            {this.props.quoteCalculationVersion !== undefined ? <p className={this.props.css.gray}>{t`Versie berekening`}: <i>{this.props.quoteCalculationVersion}</i></p> : ""}
                        </div>
                        <div className="col-md-4">
                            <SubTitle>{t`Eindklant`}</SubTitle>
                            <Input placeholder={t`Naam`} onChange={(customerName) => this.setBasicInformation({customerName})} onBlur={(customerName: string) => updateCustomerProperty(quoteId, 'customerName', customerName)} value={this.props.customerName}/>
                            <Input placeholder={t`Straat`} onChange={(customerStreet) => this.setBasicInformation({customerStreet})} value={this.props.customerStreet} onBlur={(customerStreet: string) => this.updateCustomerAddress({streetAndNumber: customerStreet, city: this.props.customerCity, postalCode: this.props.customerPostalCode, country: this.props.customerCountry})} />
                            <Input placeholder={t`Gemeente`} onChange={(customerCity) => this.setBasicInformation({customerCity})} value={this.props.customerCity} onBlur={(customerCity: string) => this.updateCustomerAddress({streetAndNumber: this.props.customerStreet, city: customerCity, postalCode: this.props.customerPostalCode, country: this.props.customerCountry})}/>
                            <Input placeholder={t`Postcode`} onChange={(customerPostalCode) => this.setBasicInformation({customerPostalCode})} value={this.props.customerPostalCode} onBlur={(customerPostalCode: string) => this.updateCustomerAddress({streetAndNumber: this.props.customerStreet, city: this.props.customerCity, postalCode: customerPostalCode, country: this.props.customerCountry})}/>
                            <Input placeholder={t`Land`} onChange={(customerCountry) => this.setBasicInformation({customerCountry})} value={this.props.customerCountry} onBlur={(customerCountry: string) => this.updateCustomerAddress({streetAndNumber: this.props.customerStreet, city: this.props.customerCity, postalCode: this.props.customerPostalCode, country: customerCountry})}/>
                            <Input placeholder={t`E-mail`} onChange={(customerEmail) => this.setBasicInformation({customerEmail})} onBlur={(customerEmail: string) => updateCustomerProperty(quoteId, 'customerEmail', customerEmail)} value={this.props.customerEmail}/>
                            <Input placeholder={t`Telefoon`} onChange={(customerPhone) => this.setBasicInformation({customerPhone})} onBlur={(customerPhone: string) => updateCustomerProperty(quoteId, 'customerPhone', customerPhone)} value={this.props.customerPhone}/>
                        </div>
                        <div className="col-md-4">
                            <SubTitle>{t`Werfadres`}</SubTitle>
                            <PortalCheckbox css={{compose: PortalCheckboxStyle}} value={this.props.equalAddresses} onChange={(checked) => {this.setEqualAddresses(checked)}}>{t`Hetzelfde als adres eindklant`}</PortalCheckbox>
                            {differentAddresses}
                            <SubTitle>{t`Afstand tot werf`}</SubTitle>
                            <Input placeholder={t`Afstand (km)`} onChange={(distance) => this.setBasicInformation({distance: isNaN(parseFloat(distance)) ? 0 : parseFloat(distance)})} onBlur={async (distance: string) => this.setBasicInformation(await updateDistance(quoteId, parseFloat(distance)))} value={this.props.distance}/>
                            {
                                this.props.origin === null || this.props.destination === null ?
                                <p>{t`De afstand werd niet automatisch bepaald`}.</p> :
                                <p>{t`De afstand naar`} {this.props.destination} {t`werd automatisch bepaald`}. {t`Bekijk de route `} <a href={"https://www.google.com/maps/dir/?api=1&origin="+this.props.origin+"&destination="+this.props.destination} target="_blank">{t`hier`}</a>.</p>
                            }
                            <button className="styledHover" onClick={async (e) => {
                                this.setBasicInformation(await recalculateDistance(quoteId));
                            }}>Bereken afstand</button>
                        </div>
                    </div>
                </div>
            </StepTemplate>
        );
    }
}
export default styleable(styles)(Step1);