import * as React from "react";
import styleable from "react-styleable";

import * as styles from "../../../css/pages/settings.css";
import * as toolbarStyles from "../../../css/components/toolbar/Toolbar.css";

import Toolbar from "webc-reactcore/src/js/components/toolbar/Toolbar";

class StyledToolbar extends React.Component<any> {
    public render() {
        return (
            <div className={this.props.css.toolbarContainer}>
                <Toolbar css={{compose: toolbarStyles}}/>
            </div>
        );
    }
}
export default styleable(styles)(StyledToolbar);
