import React from 'react';
import ArticleInputDiff from '../../../../shared/datastructures/diff/ArticleInputDiff';
import { dt } from 'webc-reactcore/src/js/stores/GlobalStore';
import PortalNodeFactory from '../quote-rendering/PortalNodeFactory';
import {ArticleDiff as ArticleDiffRenderer} from '../../../../shared/quote-rendering/QuoteRendererDiffLines';

export default function ArticleDiff(props: {diff: ArticleInputDiff}){
    const f = new PortalNodeFactory();
    const d = ArticleDiffRenderer(f, dt, props.diff);

    return <ul>
        {d.render("")}
    </ul>
}