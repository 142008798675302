import DataController from "orbiter-core/src/datastructures/DataController";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";

export interface ISpindleThreadType extends DataController {
    getTitle(): MultiLanguageString;
    getDescription(): MultiLanguageString;
    setTitle(newTitle: MultiLanguageString): void | Promise<void>;
    setDescription(newDescription: MultiLanguageString): void | Promise<void>;
}

export default class SpindleThreadType extends DataController implements ISpindleThreadType {

    @DataController.dataProperty(async (x) => await x.asList())
    private title: MultiLanguageString;
    @DataController.dataProperty(async (x) => await x.asList())
    private description: MultiLanguageString;

    public constructor(title: MultiLanguageString, description: MultiLanguageString){
        super();
        this.setTitle(title);
        this.setDescription(description);
    }

    public getTitle(): MultiLanguageString {
        return this.title;
    }

    public getDescription(): MultiLanguageString {
        return this.description;
    }

    public setTitle(newTitle: MultiLanguageString): void {
        this.title = newTitle;
    }

    public setDescription(newDescription: MultiLanguageString): void {
        this.description = newDescription;
    }

    public clone(): SpindleThreadType{
        return new SpindleThreadType(
            this.getTitle().clone(),
            this.getDescription().clone(),
        );
    }

}
