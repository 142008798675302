import {default as Distributor, IDistributor} from "../../../../shared/datastructures/Distributor";
import {createAddressFromObject} from "../../../../shared/factories/address";
import ApiController, { ConstructionMethods } from "./ApiController";
import { tAddress } from "../../../../shared/datastructures/simplifiedNetworkCommunicationTypes";
import HotecUserAPI from "./HotecUserAPI";
import HotecUserBrowserStandin from "../../../../shared/datastructures/HotecUserBrowserStandin";
import { doJsonApiCall } from "webc-reactcore/src/js/apicommunication";
import axios from "axios";
import ObjectID from "bson-objectid";
import { startLoading, stopLoading } from "../pages/newquote/article/loaders/loaderHelper";

// TODO: line below: change any to UserAPI
export default class DistributorAPI extends ApiController<Distributor<any>> implements IDistributor {

    public static getBaseEndpoint(): string{
        return "hotec/distributor";
    }

    public static getApiKey(): string{
        return "distributor";
    }

    public async parseFromDictionary(dictionary): Promise<void> {
        
        const users: HotecUserAPI[] = await Promise.all(dictionary.users.map(async (user) => new HotecUserAPI(user._id, ConstructionMethods.Data, new HotecUserBrowserStandin(user.email, null, user.properties))));

        const wt = new Distributor<HotecUserAPI>(
            dictionary.name,
            dictionary.logoId,
            dictionary.administrationName,
            dictionary.administrationEmail,
            await createAddressFromObject(dictionary.address),
            dictionary.telephone,
            dictionary.vatNumber,
            dictionary.bankAccounts,
            dictionary.preface,
            dictionary.closing,
            dictionary.conditions,
            dictionary.orderPreface,
            dictionary.orderClosing,
            dictionary.orderConditions,
            dictionary.quoteEmailTemplate,
            dictionary.orderEmailTemplate,
            users,
            dictionary.distributorProperties,
            dictionary.hotecMarginStaircase,
            dictionary.hotecMarginTreatment,
            dictionary.hotecMarginDistribution,
            dictionary.hotecDiscountStaircase,
            dictionary.hotecDiscountTreatment,
            dictionary.hotecDiscountDistribution,
            dictionary.dealerMarginStaircase,
            dictionary.dealerMarginTreatment,
            dictionary.dealerMarginDistribution,
            dictionary.applyGlobalMarginForDealers,
            dictionary.applyHotecMargin,
            dictionary.applyDealerMargin,
            dictionary.quoteNumberPrefix,
        );
        this.setData(wt);
    }

    public async requestEmailVerification(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            doJsonApiCall(DistributorAPI.getBaseEndpoint() + "/verifyemail/" + this.getId().toString(), {}, async (result) => {
                resolve();
            }, false, () => reject());
        });
    }

    public getAddress(): tAddress {
        return this.getData().getAddress();
    }

    public getAdministrationEmail(): string {
        return this.getData().getAdministrationEmail();
    }

    public getAdministrationName(): string {
        return this.getData().getAdministrationName();
    }

    public getDistributorProperties(): {} {
        return this.getData().getDistributorProperties();
    }

    public getName(): string {
        return this.getData().getName();
    }

    public getLogoId(): ObjectID | null {
        return this.getData().getLogoId();
    }

    public getLogoUrl(idForCache: string | undefined | null): string{
        return "/api/hotec/distributor/logo/" + this.getSid() + "?" + (idForCache ?? "");
    }

    public getTelephone(): string {
        return this.getData().getTelephone();
    }

    public getVatNumber(): string {
        return this.getData().getVatNumber();
    }

    public getPreface(): string {
        return this.getData().getPreface();
    }

    public getClosing(): string {
        return this.getData().getClosing();
    }
    
    public getConditions(): string {
        return this.getData().getConditions();
    }

    public getOrderPreface(): string {
        return this.getData().getOrderPreface();
    }

    public getOrderClosing(): string {
        return this.getData().getOrderClosing();
    }
    
    public getOrderConditions(): string {
        return this.getData().getOrderConditions();
    }
    
    public getQuoteEmailTemplate(): string {
        return this.getData().getQuoteEmailTemplate();
    }
    
    public getOrderEmailTemplate(): string {
        return this.getData().getOrderEmailTemplate();
    }

    public getBankAccounts(): string[] {
        return this.getData().getBankAccounts();
    }

    public getUsers(): HotecUserAPI[] {
        return this.getData().getUsers();
    }

    public getHotecMarginStaircase(): number{
        return this.getData().getHotecMarginStaircase();
    }

    public getDealerMarginStaircase(): number{
        return this.getData().getDealerMarginStaircase();
    }

    public getHotecMarginTreatment(): number{
        return this.getData().getHotecMarginTreatment();
    }

    public getDealerMarginTreatment(): number{
        return this.getData().getDealerMarginTreatment();
    }

    public getHotecMarginDistribution(): number{
        return this.getData().getHotecMarginDistribution();
    }

    public getDealerMarginDistribution(): number{
        return this.getData().getDealerMarginDistribution();
    }

    public getHotecDiscountStaircase(): number{
        return this.getData().getHotecDiscountStaircase();
    }

    public getHotecDiscountTreatment(): number{
        return this.getData().getHotecDiscountTreatment();
    }

    public getHotecDiscountDistribution(): number{
        return this.getData().getHotecDiscountDistribution();
    }

    public getQuoteNumberPrefix(): string{
        return this.getData().getQuoteNumberPrefix();
    }

    public shouldApplyGlobalMarginForDealers(): boolean{
        return this.getData().shouldApplyGlobalMarginForDealers();
    }

    public shouldApplyHotecMargin(): boolean{
        return this.getData().shouldApplyHotecMargin();
    }

    public shouldApplyDealerMargin(): boolean{
        return this.getData().shouldApplyDealerMargin();
    }

    public async setAddress(address: tAddress): Promise<void> {
        this.getData().setAddress(address);
        // TODO: apply on server
        // await this.updatePropertyInDatabase("address", address.asDict());
    }

    public async setAdministrationEmail(administrationEmail: string): Promise<void> {
        this.getData().setAdministrationEmail(administrationEmail);
        await this.updateProperty("administrationEmail", administrationEmail);
    }

    public async setAdministrationName(administrationName: string): Promise<void> {
        this.getData().setAdministrationName(administrationName);
        // TODO: apply on server
        // await this.updatePropertyInDatabase("administrationName", administrationName);
    }

    public async setDistributorProperties(properties: {}): Promise<void> {
        this.getData().setDistributorProperties(properties);
        // TODO: apply on server
        // await this.updatePropertyInDatabase("properties", properties);
    }

    public async setName(name: string): Promise<void> {
        this.getData().setName(name);
        // TODO: apply on server
        // await this.updatePropertyInDatabase("name", name);
    }

    public async setLogoId(logoId: ObjectID | null): Promise<void> {
        this.getData().setLogoId(logoId);
        // TODO: apply on server
        // await this.updatePropertyInDatabase("logoId", logoId);
    }

    public async setTelephone(telephone: string): Promise<void> {
        this.getData().setTelephone(telephone);
        // TODO: apply on server
        // await this.updatePropertyInDatabase("telephone", telephone);
    }

    public async setPreface(preface: string): Promise<void> {
        this.getData().setPreface(preface);
        // TODO: apply on server
        // await this.updatePropertyInDatabase("preface", preface);
    }

    public async setClosing(closing: string): Promise<void> {
        this.getData().setClosing(closing);
        // TODO: apply on server
        // await this.updatePropertyInDatabase("closing", closing);
    }

    public async setConditions(conditions: string): Promise<void> {
        this.getData().setConditions(conditions);
        // TODO: apply on server
        // await this.updatePropertyInDatabase("conditions", conditions);
    }

    public async setOrderPreface(preface: string): Promise<void> {
        this.getData().setOrderPreface(preface);
        // TODO: apply on server
        // await this.updatePropertyInDatabase("preface", preface);
    }

    public async setOrderClosing(closing: string): Promise<void> {
        this.getData().setOrderClosing(closing);
        // TODO: apply on server
        // await this.updatePropertyInDatabase("closing", closing);
    }

    public async setOrderConditions(conditions: string): Promise<void> {
        this.getData().setOrderConditions(conditions);
        // TODO: apply on server
        // await this.updatePropertyInDatabase("conditions", conditions);
    }

    public async setOrderEmailTemplate(conditions: string): Promise<void> {
        this.getData().setOrderEmailTemplate(conditions);
        // TODO: apply on server
        // await this.updatePropertyInDatabase("conditions", conditions);
    }

    public async setQuoteEmailTemplate(conditions: string): Promise<void> {
        this.getData().setQuoteEmailTemplate(conditions);
        // TODO: apply on server
        // await this.updatePropertyInDatabase("conditions", conditions);
    }

    public async setVatNumber(vatNumber: string): Promise<void> {
        this.getData().setVatNumber(vatNumber);
        // TODO: apply on server
        // await this.updatePropertyInDatabase("telephone", telephone);
    }

    public async setBankAccounts(bankAccounts: string[]): Promise<void> {
        this.getData().setBankAccounts(bankAccounts);
        // TODO: apply on server
        // await this.updatePropertyInDatabase("telephone", telephone);
    }

    public async setHotecMarginStaircase(margin: number): Promise<void> {
        this.getData().setHotecMarginStaircase(margin);
        // TODO: apply on server
    }

    public async setDealerMarginStaircase(margin: number): Promise<void> {
        this.getData().setDealerMarginStaircase(margin);
        // TODO: apply on server
    }

    public async setHotecMarginTreatment(margin: number): Promise<void> {
        this.getData().setHotecMarginTreatment(margin);
        // TODO: apply on server
    }

    public async setDealerMarginTreatment(margin: number): Promise<void> {
        this.getData().setDealerMarginTreatment(margin);
        // TODO: apply on server
    }

    public async setHotecMarginDistribution(margin: number): Promise<void> {
        this.getData().setHotecMarginDistribution(margin);
        // TODO: apply on server
    }

    public async setDealerMarginDistribution(margin: number): Promise<void> {
        this.getData().setDealerMarginDistribution(margin);
        // TODO: apply on server
    }

    public async setHotecDiscountStaircase(discount: number): Promise<void> {
        this.getData().setHotecDiscountStaircase(discount);
        // TODO: apply on server
    }

    public async setHotecDiscountTreatment(discount: number): Promise<void> {
        this.getData().setHotecDiscountTreatment(discount);
        // TODO: apply on server
    }

    public async setHotecDiscountDistribution(discount: number): Promise<void> {
        this.getData().setHotecDiscountDistribution(discount);
        // TODO: apply on server
    }

    public async setApplyGlobalMarginForDealers(val: boolean): Promise<void> {
        this.getData().setApplyGlobalMarginForDealers(val);
        // TODO: apply on server
    }

    public async setApplyHotecMargin(val: boolean): Promise<void> {
        this.getData().setApplyHotecMargin(val);
        // TODO: apply on server
    }

    public async setApplyDealerMargin(val: boolean): Promise<void> {
        this.getData().setApplyDealerMargin(val);
        // TODO: apply on server
    }

    public async uploadLogo(file: File): Promise<void>{
        startLoading();
        try{
            const formData = new FormData();
            formData.append("file", file);
            const res = await axios.post('/api/hotec/distributor/setlogo/' + this.getSid(), formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            this.setLogoId(new ObjectID(res.data.logoId));
        }catch{}
        stopLoading();
    }

}
