import * as React from "react";
import {connect, DispatchProp} from "react-redux";
import ReactLoading from 'react-loading';

@(connect((store: any) => {
    return {globalInformation: {...store.globalInformation}};
}) as any)
export default class LoadingIcon extends React.Component<{large?: boolean, subtitle?: string, globalInformation?: any, uncoupledRender?: boolean, center?: boolean}> {

    public render() {
        if(this.props.globalInformation.loading > 0 || this.props.uncoupledRender){
            return <>
                <ReactLoading className={(this.props.large ? "spinner-large" : "spinner") + " " + (this.props.center ? "spinner-no-float" : "")} type={"spin"} color={"#FFCB04"} height={20} width={20} />
                {this.props.subtitle ? <p className="spinner-subtitle">{this.props.subtitle}</p> : ""}
            </>;
        }
        return "";
    }
}
