import * as React from "react";
import { connect } from "react-redux";
import { IQuoteCreationStateDispatch } from "../../reducers/quoteCreationReducer";
import { IArticleCreationStateDispatch } from "../../reducers/articleCreationReducer";
import {withRouter, RouteComponentProps} from "react-router-dom";

type props = { quoteCreation: IQuoteCreationStateDispatch, articleCreation: IArticleCreationStateDispatch, css: any };

@(connect((store: any) => {
    return { quoteCreation: { ...store.quoteCreation }, articleCreation: { ...store.articleCreation } };
}) as any)
class QuoteRelatedPage extends React.Component<props & RouteComponentProps> {

    componentDidMount(){
        if(!this.props.quoteCreation.quoteId){
            this.props.history.push("/");
        }
    }

    public render() {
        return (
            <>
                {this.props.children}
            </>
        );
    }

}
export default withRouter(QuoteRelatedPage);