import React from 'react';
import IUniformNode from '../../../../../shared/quote-rendering/IUniformNode';
import { ISpaceNodeProps } from '../../../../../shared/quote-rendering/UniformNodeFactory';

export default class SpaceNode implements IUniformNode<React.ReactNode>{
    
    constructor(
        private readonly props: ISpaceNodeProps
    ) {}

    renderWithManualKey() {
        return <div key={this.props.key} style={{height: this.props.height}}/>;
    }

    render(seedKey: string) {
        return <div key={seedKey} style={{height: this.props.height}}/>;
    }

}