import IArticleInput from "../../../../shared/datastructures/IArticleInput";
import { QUOTE_STATES } from "../../../../shared/datastructures/Quote";
import { tVersionedObjectId, tObjectId } from "../../../../shared/datastructures/simplifiedNetworkCommunicationTypes";
import DistributorAPI from "../apicontroller/DistributorAPI";
import HotecUserAPI from "../apicontroller/HotecUserAPI";
import { createQuote, addArticle as addArticleApi, deleteArticle as deleteArticleApi, updateArticle as updateArticleApi, updateAddress } from "../apicontroller/quote";
import { loadArticleInformation, RetrievedArticleData } from "../pages/newquote/article/loaders/loaderHelper";
import store from "../store";
import { setBasicInformation as setGlobalBasicInformation } from "./globalInformationActions";

export async function initQuote(): Promise<{payload: {quoteId: tVersionedObjectId, articleInformation: RetrievedArticleData, ownerId: string, distributor: DistributorAPI, quoteState: string}, type: string}>{
    store.dispatch(setGlobalBasicInformation({ openingQuote: true }));
    const quoteId = await createQuote()
    const articleInformation = await loadArticleInformation(quoteId._id);
    store.dispatch(setGlobalBasicInformation({ openingQuote: false }));
    return {
        payload: {
            quoteId, 
            articleInformation,
            ownerId: (store.getState().globalInformation.user as HotecUserAPI).getSid(),
            distributor: (store.getState().globalInformation.distributor as DistributorAPI),
            quoteState: QUOTE_STATES[0],
        },
        type: 'INIT_QUOTE',
    }
}

export function removeInformation(){
    return {
        type : "REMOVE_QUOTE_CREATION_INFORMATION",
    };
}

export function setBasicInformation(information){
    return {
        payload: information,
        type : "SET_QUOTE_CREATION_BASIC_INFORMATION",
    };
}

export function setEqualAddresses(val){
    return {
        payload: val,
        type : "SET_QUOTE_CREATION_EQUAL_ADDRESSES",
    };
}

export async function addArticle(quoteId: tObjectId, article:IArticleInput): Promise<{payload: IArticleInput, type: string}>{
    return {
        payload: await addArticleApi(quoteId, article),
        type: "ADD_ARTICLE_TO_QUOTE"
    }
}

export async function deleteArticle(articleId: tObjectId): Promise<{payload: tObjectId, type: string}>{
    return {
        payload: await deleteArticleApi(articleId),
        type: "DELETE_ARTICLE_FROM_QUOTE"
    }
}

export async function updateArticle(quoteId: tObjectId, article:IArticleInput): Promise<{payload: IArticleInput, type: string}>{
    return {
        payload: await updateArticleApi(quoteId, article),
        type: "UPDATE_ARTICLE_FOR_QUOTE"
    }
}
