import { IDatabaseController } from "orbiter-core/src/databasecontroller/DatabaseController";
import { differingDBControllerList, differingStrings, differingSupplements, IArticleDiffLine, newDiffFalseLine, newDiffTrueLine } from "./ArticleInputDiff";
import { IQuantifiedSupplement } from "../IArticleInput";
import { IStaircasePlacementOption } from "../staircasedata/StaircasePlacementOption";
import TransportationInput, { PLACEMENT } from "../articleinput/TransportationInput";

export default class TransportationInputDiff{
    public transportationMethod: IArticleDiffLine<string> = newDiffFalseLine();
    public staircasePlacementOptions: IArticleDiffLine<(IStaircasePlacementOption & IDatabaseController)[]> = newDiffFalseLine();
    public placementSupplements: IArticleDiffLine<IQuantifiedSupplement[]> = newDiffFalseLine();
    public measurementByHotec: IArticleDiffLine<boolean> = newDiffFalseLine();
    public assembledByHotec: IArticleDiffLine<boolean> = newDiffFalseLine();
    
    constructor(readonly left: TransportationInput, readonly right: TransportationInput){}

    public async diff(){
        await this.diffTransportationMethod();
        await this.diffSupplements();
        await this.diffStaircasePlacementOptions();
        await this.diffMeasurementByHotec();
        await this.diffAssembledByHotec();
    }

    public differs(): boolean{
        return this.transportationMethod.different
            || this.placementSupplements.different
            || this.staircasePlacementOptions.different
            || this.measurementByHotec.different
            || this.assembledByHotec.different;
    }

    private async diffTransportationMethod(){
        if(differingStrings(this.left.transportationMethod, this.right.transportationMethod)){
            this.transportationMethod = newDiffTrueLine(
                this.left.transportationMethod,
                this.right.transportationMethod,
            )
        }
    }

    private async diffSupplements(){
        if(differingSupplements(this.left.placementSupplements, this.right.placementSupplements)){
            this.placementSupplements = newDiffTrueLine(
                this.left.placementSupplements,
                this.right.placementSupplements,
            );
        }   
    }

    private async diffStaircasePlacementOptions(){
        if(differingDBControllerList(this.left.staircasePlacementOptions, this.right.staircasePlacementOptions)){
            this.staircasePlacementOptions = newDiffTrueLine(
                this.left.staircasePlacementOptions,
                this.right.staircasePlacementOptions,
            );
        }   
    }

    private async diffMeasurementByHotec(){
        if(this.right.transportationMethod !== PLACEMENT){
            if(this.left.measurementByHotec !== this.right.measurementByHotec){
                this.measurementByHotec = newDiffTrueLine(
                    this.left.measurementByHotec,
                    this.right.measurementByHotec,
                );
            }   
        }
    }

    private async diffAssembledByHotec(){
        if(this.right.transportationMethod !== PLACEMENT){
            if(this.left.assembledByHotec !== this.right.assembledByHotec){
                this.assembledByHotec = newDiffTrueLine(
                    this.left.assembledByHotec,
                    this.right.assembledByHotec,
                );
            }   
        }
    }


}