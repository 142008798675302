import * as React from "react";
import { connect } from "react-redux";
import { loadStaircasePlacementOptions } from "../../loader";
import { dt } from "webc-reactcore/src/js/stores/GlobalStore";
import { toSelectItems, createTitlePortalCollapsibleLanguageField, createDescriptionPortalCollapsibleLanguageField, createApiTitlePortalCollapsibleLanguageField, createApiDescriptionPortalCollapsibleLanguageField } from "./helper";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import SimpleSettingsTemplate from "./SimpleSettingsTemplate";
import ConfigBlock from "../../components/ConfigBlock";
import StaircasePlacementOptionAPI from "../../apicontroller/staircasedata/StaircasePlacementOptionAPI";
import StaircasePlacementOption from "../../../../../shared/datastructures/staircasedata/StaircasePlacementOption";
import MappedInput from "webc-reactcore/src/js/components/mainlayout/MappedInput";
import { inu } from "orbiter-core/src/basic";
import { t } from "ttag";
import InfoScreen from "../../components/InfoScreen";

@(connect((store: any) => {
    return { settings: { ...store.settings }, globalInformation: { ...store.globalInformation } };
}) as any)
export default class StaircasePlacementOptionSettings extends React.Component<any, any> {

    public staircasePlacementOptionsToSelectItems() {
        return toSelectItems<StaircasePlacementOptionAPI>("staircaseplacementoption", this.props.globalInformation.staircasePlacementOptions.sort((x, y) => dt(x.getTitle()) < dt(y.getTitle()) ? -1 : 1), (x) => StaircasePlacementOptionAPI.clone(x), (x) => dt(x.getTitle()));
    }

    private async createEmpty(): Promise<StaircasePlacementOption> {
        return new StaircasePlacementOption(0, new MultiLanguageString(), new MultiLanguageString());
    }

    private async create(data: StaircasePlacementOption) {
        await StaircasePlacementOptionAPI.create(data);
    }

    public render() {
        return <SimpleSettingsTemplate
            title="Plaatsingsopties"
            selectItems={this.staircasePlacementOptionsToSelectItems()}

            reload={loadStaircasePlacementOptions.bind(this)}
            createEmpty={this.createEmpty.bind(this)}
            create={this.create.bind(this)}
            clone={(i: StaircasePlacementOptionAPI) => StaircasePlacementOptionAPI.clone(i)}

            renderEdit={this.renderEdit.bind(this)}
            renderCreate={this.renderCreate.bind(this)}

            infoScreen={<InfoScreen title={t`Selecteer een plaatsingsoptie.`} image={"/cdn/images/stack.svg"}/>}
        />;
    }


    private renderEdit(a: StaircasePlacementOptionAPI, setSelected: (i: StaircasePlacementOptionAPI) => void) {
        return this.doRender(
            createApiTitlePortalCollapsibleLanguageField(a, (x) => StaircasePlacementOptionAPI.clone(x), setSelected.bind(this)),
            createApiDescriptionPortalCollapsibleLanguageField(a, (x) => StaircasePlacementOptionAPI.clone(x), setSelected.bind(this)),
            <MappedInput placeholder={t`Prijs`}
                onBlur={(price) => {
                    const cl: StaircasePlacementOptionAPI = StaircasePlacementOptionAPI.clone(a);
                    try{
                        cl.getData().setPrice(price);
                    }catch(e){
                        cl.getData().setPrice(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getPrice(), () => "")} />,
        );
    }

    private renderCreate(a: StaircasePlacementOption, setSelected: (i: StaircasePlacementOption) => void) {
        return this.doRender(
            createTitlePortalCollapsibleLanguageField(a, setSelected.bind(this)),
            createDescriptionPortalCollapsibleLanguageField(a, setSelected.bind(this)),
            <MappedInput placeholder={t`Prijs`}
                onBlur={(price) => {
                    const cl: StaircasePlacementOption = a.clone();
                    try{
                        cl.setPrice(price);
                    }catch(e){
                        cl.setPrice(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getPrice(), () => "")} />,
        );
    }

    private doRender(
        titlePCLF: React.ReactNode,
        descriptionPCLF: React.ReactNode,
        priceMI: React.ReactNode,
    ): React.ReactNode {
        return <div className={"container-fluid"}>
            <div className="row">
                <div className="col-md-6">
                    <ConfigBlock
                        title="Titel"
                        description="Geef een titel in voor deze plaatsingsoptie."
                        topMost={true}
                    >
                        {titlePCLF}
                    </ConfigBlock>

                    <ConfigBlock
                        title="Prijs"
                        description="Geef een prijs in voor deze plaatsingsoptie."
                        topMost={false}
                    >
                        {priceMI}
                    </ConfigBlock>
                </div>
                <div className="col-md-6">
                    <ConfigBlock
                        title="Omschrijving"
                        description="Geef een omschrijving in voor deze plaatsingsoptie."
                        topMost={true}
                    >
                        {descriptionPCLF}
                    </ConfigBlock>
                </div>
            </div>
        </div>;
    }

}
