import React from "react";
import SelectItem from "../../components/DeleteSelectItem";
import Select from "webc-reactcore/src/js/components/mainlayout/PortalSelect";
import * as PortalSelectStyle from "../../../css/PortalSelect.css";
import RightFloatingMenuItem from "webc-reactcore/src/js/components/mainlayout/RightFloatingMenuItem";
import * as rightFloatingMenuItemStyle from "../../../css/rightFloatingMenuItemStyle.css";
import MappedInput from "webc-reactcore/src/js/components/mainlayout/MappedInput";
import { t } from 'ttag';

export function bankAccountsToSelectItems(accounts: string[], onDelete: (account: string) => void) {
    const list = [];
    for (const account of accounts) {
        list.push(
            <SelectItem key={"bankaccountsselectitems" + list.length} 
                id={account}
                onDeleteClick={() => {
                    onDelete(account);
                }}
                data={{
                    item: account,
                }}>{account}</SelectItem>,
        );
    }
    return list;
}

export function renderBankAccountsInput(setInput: (input: string) => void, getInput: () => string, accounts: string[], onAdd: (account: string) => void, onDelete: (account: string) => void){
    const bankAccountSelectItems = bankAccountsToSelectItems(accounts, onDelete);
    return <span>
        <Select placeholder={t`Geldige modelspijlen`} key={"_supportedspindlesselect"}
            searchable={false}
            css={{ compose: PortalSelectStyle }}
            onSelectionChange={() => {}}
            maxSelected={0}
            selectedIds={[]}
            selectedItems={[]}>
            {bankAccountSelectItems}
        </Select>
        <MappedInput placeholder={t`Bankrekening`} onChange={(accountInput) => {
            setInput(accountInput)
        }} value={getInput()} />
        <RightFloatingMenuItem onClick={() => {
            onAdd(getInput());
            setInput("");
        }} title={t`Bankrekening toevoegen`} css={{ compose: rightFloatingMenuItemStyle }} />
        
    </span>;
}