exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2YK8ViCSyRM983cREYOfUr{\n    height: 400px;\n}", ""]);

// exports
exports.locals = {
	"input": "_2YK8ViCSyRM983cREYOfUr"
};