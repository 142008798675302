import * as React from "react";
import styleable from "react-styleable";

import * as styles from "../../css/components/SubTitle.css";

/**
 * Subtitle
 * 
 * ## props
 * * **marginTop**
 *
 * ## styleable
 * * **subtitle** Add styling to the subtitle.
 *
 */
class SubTitle extends React.Component<any> {
    public render() {
        return (
            <p className={this.props.css.subtitle} style={this.props.marginTop !== undefined ? {marginTop: this.props.marginTop} : {}}>
                {this.props.children}
            </p>
        );
    }
}
export default styleable(styles)(SubTitle);
