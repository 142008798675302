import React from 'react';
import IUniformNode, { deepenKey } from '../../../../../shared/quote-rendering/IUniformNode';
import { IDocumentNodeProps } from '../../../../../shared/quote-rendering/UniformNodeFactory';

export default class DocumentNode implements IUniformNode<React.ReactNode>{
    
    constructor(
        private readonly props: IDocumentNodeProps<React.ReactNode>
    ) {}

    renderWithManualKey() {
        return <div key={this.props.key} title={this.props.title} >
            {this.props.children.map(x => x.renderWithManualKey())}
        </div>;
    }

    render(seedKey: string) {
        return <div key={seedKey} title={this.props.title} >
            {this.props.children.map((x, i) => x.render(deepenKey(seedKey, i)))}
        </div>;
    }

}