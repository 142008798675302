import React from 'react';
import IUniformNode, { deepenKey } from '../../../../../shared/quote-rendering/IUniformNode';

export default class NoneNode implements IUniformNode<React.ReactNode>{
    
    constructor(
    ) {}

    renderWithManualKey() {
        return undefined;
    }

    render(seedKey: string) {
        return undefined;
    }

}