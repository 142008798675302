import ArticleInput from "../../../../../../../shared/datastructures/articleinput/ArticleInput";
import ExtraHandrailInput from "../../../../../../../shared/datastructures/articleinput/ExtraHandrailInput";
import HandrailInput from "../../../../../../../shared/datastructures/articleinput/HandrailInput";
import { INPUT_NOT_DEFINED, INVALID_TYPE, INVALID_VALUE, PROHIBITED_SELECTION, TOO_SMALL_VALUE } from "../../../../../../../shared/datastructures/articleinput/inputExceptions";
import LandingStageInput from "../../../../../../../shared/datastructures/articleinput/LandingStageInput";
import ShapeInput from "../../../../../../../shared/datastructures/articleinput/ShapeInput";
import TransportationInput from "../../../../../../../shared/datastructures/articleinput/TransportationInput";
import TreatmentInput from "../../../../../../../shared/datastructures/articleinput/TreatmentInput";
import ValidityErrorCatcher from "../../../../../../../shared/datastructures/articleinput/ValidityErrorCatcher";
import WallHandrailInput from "../../../../../../../shared/datastructures/articleinput/WallHandrailInput";
import IArticleInput, { PageType } from "../../../../../../../shared/datastructures/IArticleInput";
import { setBasicInformation } from "../../../../actions/articleCreationActions";
import store from '../../../../store';


export function addPageToEmptyValidityCheck(pageType: PageType){
    let currentPageTypes: PageType[] = store.getState().articleCreation._tempValidityCheckEmptyAllowed;
    currentPageTypes = currentPageTypes ? currentPageTypes : [];
    if(currentPageTypes.findIndex(x => x === pageType) == -1){
        // Not present -> add
        store.dispatch(setBasicInformation({_tempValidityCheckEmptyAllowed: [...currentPageTypes, pageType]}))
    }
}

export function performEmptyValidityCheck(pageType?: PageType){
    let currentPageTypes: PageType[] = store.getState().articleCreation._tempValidityCheckEmptyAllowed;
    if(pageType !== undefined && currentPageTypes.findIndex(x => x === pageType) > -1){
        return true;
    }
    if(currentPageTypes.findIndex(x => x === PageType.GENERAL) > -1){
        return true; // Always perform validity check on empty values
    }
    return false;

}

export async function performPageValidityCheck(article: IArticleInput, pageType: PageType, enableEmptyCheckOverride?: boolean): Promise<boolean>{
    const enableEmptyCheck = enableEmptyCheckOverride !== undefined ? enableEmptyCheckOverride : performEmptyValidityCheck(pageType);

    switch(pageType){
        case PageType.SHAPE :{
            return (await new ValidityErrorCatcher(async () => await ShapeInput.validityCheck(article))
                .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED)
                .addCatcher(enableEmptyCheck, INVALID_TYPE)
                .addCatcher(true, TOO_SMALL_VALUE)
                .catchError()).valid;
        }
        case PageType.STAIRCASE :{
            return (await new ValidityErrorCatcher(() => ArticleInput.validityCheckShallow(article))
                .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED)
                .addCatcher(enableEmptyCheck, INVALID_TYPE)
                .catchError()).valid;
        }
        case PageType.HANDRAIL :{
            return (await new ValidityErrorCatcher((async () => await HandrailInput.validityCheck(article.handrail, article)))
                .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED)
                .addCatcher(enableEmptyCheck, INVALID_TYPE)
                .addCatcher(true, PROHIBITED_SELECTION)
                .catchError()).valid && 
                (await new ValidityErrorCatcher((async () => await ExtraHandrailInput.validityCheck(article.extraHandrail, article)))
                .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED)
                .addCatcher(enableEmptyCheck, INVALID_TYPE)
                .addCatcher(true, PROHIBITED_SELECTION)
                .catchError()).valid &&
                (await new ValidityErrorCatcher((async () => await WallHandrailInput.validityCheck(article.wallHandrail)))
                .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED)
                .addCatcher(enableEmptyCheck, INVALID_TYPE)
                .catchError()).valid;
        }
        case PageType.LANDING_STAGE :{
            return (await new ValidityErrorCatcher((async () => await LandingStageInput.validityCheck(article.landingStage, article)))
                .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED)
                .addCatcher(enableEmptyCheck, INVALID_TYPE)
                .catchError()).valid;
        }
        case PageType.TREATMENT :{
            return (await new ValidityErrorCatcher(() => TreatmentInput.validityCheck(article.treatment))
                .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED)
                .catchError()).valid;
        }
        case PageType.TRANSPORTATION :{
            return (await new ValidityErrorCatcher(() => TransportationInput.validityCheck(article.transportation, article))
                .addCatcher(enableEmptyCheck, INPUT_NOT_DEFINED)
                .catchError()).valid;
        }
        case PageType.GENERAL :{
            return (await new ValidityErrorCatcher(async () => await ArticleInput.discountPercentageValidityCheck(article.discountPercentageStaircase, article.discountPercentageTreatment, article.discountPercentageDistribution))
                .addCatcher(true, INPUT_NOT_DEFINED)
                .addCatcher(true, INVALID_TYPE)
                .addCatcher(true, INVALID_VALUE)
                .catchError()).valid;
        }

    }

    return false;
}