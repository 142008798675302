import IArticleInput, { ILandingStageInput, IHandrailInput } from "../IArticleInput";
import { IWoodType } from "../staircasedata/WoodType";
import { IDatabaseController } from "orbiter-core/src/databasecontroller/DatabaseController";
import { inu, isDefined, isValidStrictlyPositiveFloatingPoint, isValidPositiveInteger, fuzzyEquals, isValidPositiveFloatingPoint } from "orbiter-core/src/basic";
import HandrailInput from "./HandrailInput";
import { INPUT_NOT_DEFINED, INVALID_TYPE, INVALID_VALUE } from "./inputExceptions";
import { IHandrailPoleType } from "../staircasedata/HandrailPoleType";
import { IHandrailPoleFinish } from "../staircasedata/HandrailPoleFinish";
import HandrailType, { IHandrailType } from "../../datastructures/staircasedata/HandrailType";

const _ = undefined;

/**
 * Immutable landing stage input class.
 */
export default class LandingStageInput implements ILandingStageInput{

    constructor(
        readonly handrail: HandrailInput = new HandrailInput(),
        readonly handrailLength: number = 0,
        readonly concreteEnclosureLength: number = 0,
        readonly concreteEnclosureWidth: number = 0,
        readonly concreteEnclosureWoodType: IWoodType & IDatabaseController = null,
        readonly handrailPoleCount: number = 0,
        readonly handrailPoleTypes: (IHandrailPoleType & IDatabaseController)[] = [],
        readonly handrailPoleFinishes: (IHandrailPoleFinish & IDatabaseController)[] = [],
    ){}

    static from(landingStageInput: ILandingStageInput): LandingStageInput{
        return new LandingStageInput(
            landingStageInput.handrail === null ? null : HandrailInput.from(landingStageInput.handrail),
            landingStageInput.handrailLength,
            landingStageInput.concreteEnclosureLength,
            landingStageInput.concreteEnclosureWidth,
            landingStageInput.concreteEnclosureWoodType,
            landingStageInput.handrailPoleCount,
            landingStageInput.handrailPoleTypes,
            landingStageInput.handrailPoleFinishes,
        ).clone();
    }

    static async validityCheck(landingStage: ILandingStageInput, article: IArticleInput): Promise<void>{
        if(landingStage.handrail !== null){
            await this.handrailValidityCheck(landingStage.handrail, article);

            this.handrailLengthValidityCheck(landingStage.handrailLength);
        }
        this.concreteEnclosureLengthValidityCheck(landingStage.concreteEnclosureLength, landingStage);
        this.concreteEnclosureWidthValidityCheck(landingStage.concreteEnclosureWidth, landingStage);
        this.concreteEnclosureWoodTypeValidityCheck(landingStage.concreteEnclosureWoodType, landingStage);
        this.handrailPoleCountValidityCheck(landingStage.handrailPoleCount);
        this.handrailPoleTypesValidityCheck(landingStage.handrailPoleTypes, landingStage.handrailPoleCount, landingStage.handrail?.type);
        this.handrailPoleFinishValidityCheck(landingStage.handrailPoleFinishes, landingStage.handrailPoleCount, landingStage.handrail?.type);
    }

    static handrailPoleCountValidityCheck(handrailPoleCount: number): void{
        if(!isDefined(handrailPoleCount))
            throw INPUT_NOT_DEFINED;
        else if(!isValidPositiveInteger(handrailPoleCount))
            throw INVALID_TYPE;
    }

    static handrailPoleTypesValidityCheck(handrailPoleTypes: (IHandrailPoleType & IDatabaseController)[], requiredNumberOfPoles: number, handrailType: (IHandrailType & IDatabaseController)): void{
        if(handrailPoleTypes.length == 0 && !HandrailType.isHandrailIncluded(handrailType))
            return
        if(handrailPoleTypes.length < requiredNumberOfPoles)
            throw INPUT_NOT_DEFINED;
        for(let i = 0; i<handrailPoleTypes.length; i++){
            if(!isDefined(handrailPoleTypes[i]))
                throw INPUT_NOT_DEFINED;
        }
    }

    static handrailPoleFinishValidityCheck(handrailPoleFinishes: (IHandrailPoleFinish & IDatabaseController)[], requiredNumberOfPoles: number, handrailType: (IHandrailType & IDatabaseController)): void{
        if(handrailPoleFinishes.length == 0 && !HandrailType.isHandrailIncluded(handrailType))
            return
        if(handrailPoleFinishes.length < requiredNumberOfPoles)
            throw INPUT_NOT_DEFINED;
        for(let i = 0; i<handrailPoleFinishes.length; i++){
            if(!isDefined(handrailPoleFinishes[i]))
                throw INPUT_NOT_DEFINED;
        }
    }

    static async handrailValidityCheck(handrail: IHandrailInput, article: IArticleInput): Promise<void>{
        if(!isDefined(handrail))
            throw INPUT_NOT_DEFINED;
        if(!isDefined(article))
            throw INPUT_NOT_DEFINED;
        await HandrailInput.validityCheck(handrail, article, 0, true);
    }

    static handrailLengthValidityCheck(handrailLength: number): void{
        if(!isDefined(handrailLength))
            throw INPUT_NOT_DEFINED;
        else if(!isValidStrictlyPositiveFloatingPoint(handrailLength))
            throw INVALID_TYPE;
        const afterComma = (handrailLength - Math.floor(handrailLength));
        if(!fuzzyEquals(afterComma, 0.5) && !fuzzyEquals(afterComma, 0)) // In stappen van halve meter
            throw INVALID_VALUE;
    }

    static concreteEnclosureLengthValidityCheck(concreteEnclosureLength: number, landingStage: ILandingStageInput): void{
        if(!isDefined(landingStage.concreteEnclosureWoodType) && (concreteEnclosureLength == 0) || !isDefined(concreteEnclosureLength)){
            return;
        }
        if(!isDefined(concreteEnclosureLength))
            throw INPUT_NOT_DEFINED;
        else if(!isValidStrictlyPositiveFloatingPoint(concreteEnclosureLength))
            throw INVALID_TYPE;

        const afterComma = (concreteEnclosureLength - Math.floor(concreteEnclosureLength));
        if(!fuzzyEquals(afterComma, 0.5) && !fuzzyEquals(afterComma, 0)) // In stappen van halve meter
            throw INVALID_VALUE;
    }

    static concreteEnclosureWidthValidityCheck(concreteEnclosureWidth: number, landingStage: ILandingStageInput): void{
        if(!isDefined(landingStage.concreteEnclosureWoodType) && (concreteEnclosureWidth == 0) || !isDefined(concreteEnclosureWidth)){
            return;
        }
        if(!isDefined(concreteEnclosureWidth))
            throw INPUT_NOT_DEFINED;
        else if(!isValidStrictlyPositiveFloatingPoint(concreteEnclosureWidth))
            throw INVALID_TYPE;
    }

    static concreteEnclosureWoodTypeValidityCheck(woodType: IWoodType & IDatabaseController, landingStage: ILandingStageInput): void{
        
    }

    clone(
        handrail?: HandrailInput,
        handrailLength?: number,
        concreteEnclosureLength?: number,
        concreteEnclosureWidth?: number,
        concreteEnclosureWoodType?: IWoodType & IDatabaseController,
        handrailPoleCount?: number,
        handrailPoleTypes?: (IHandrailPoleType & IDatabaseController)[],
        handrailPoleFinishes?: (IHandrailPoleFinish & IDatabaseController)[],
    ):LandingStageInput{
        return new LandingStageInput(
            inu(handrail, ()=>handrail === null ? null : handrail.clone(), ()=>this.handrail === null ? null : this.handrail.clone()),
            inu(handrailLength, ()=>handrailLength, ()=>this.handrailLength),
            inu(concreteEnclosureLength, ()=>concreteEnclosureLength, ()=>this.concreteEnclosureLength),
            inu(concreteEnclosureWidth, ()=>concreteEnclosureWidth, ()=>this.concreteEnclosureWidth),
            inu(concreteEnclosureWoodType, ()=>concreteEnclosureWoodType, ()=>this.concreteEnclosureWoodType),
            inu(handrailPoleCount, ()=>handrailPoleCount, ()=>this.handrailPoleCount),
            inu(handrailPoleTypes, ()=>[...handrailPoleTypes], ()=>[...this.handrailPoleTypes]),
            inu(handrailPoleFinishes, ()=>[...handrailPoleFinishes], ()=>[...this.handrailPoleFinishes]),
        )
    }

    setHandrail(handrail: HandrailInput): LandingStageInput{
        return this.clone(handrail);
    }

    setHandrailLength(handrailLength: number): LandingStageInput{
        if(isNaN(handrailLength))
            handrailLength = 0;
        return this.clone(_, handrailLength);
    }

    setConcreteEnclosureLength(concreteEnclosureLength: number): LandingStageInput{
        if(isNaN(concreteEnclosureLength))
            concreteEnclosureLength = 0;
        return this.clone(_, _, concreteEnclosureLength);
    }

    setConcreteEnclosureWidth(concreteEnclosureWidth: number): LandingStageInput{
        if(isNaN(concreteEnclosureWidth))
            concreteEnclosureWidth = 0;
        return this.clone(_, _, _, concreteEnclosureWidth);
    }

    setConcreteEnclosureWoodType(concreteEnclosureWoodType: IWoodType & IDatabaseController): LandingStageInput{
        return this.clone(_, _, _, _, concreteEnclosureWoodType);
    }

    setHandrailPoleCount(poleCount: number): LandingStageInput{
        return this.clone(_, _, _, _, _, poleCount);
    }

    setHandrailPoleTypes(poleTypes: (IHandrailPoleType & IDatabaseController)[]): LandingStageInput{
        return this.clone(_, _, _, _, _, _, poleTypes);
    }

    setHandrailPoleFinishes(poleFinishes: (IHandrailPoleFinish & IDatabaseController)[]): LandingStageInput{
        return this.clone(_, _, _, _, _, _, _, poleFinishes);
    }

}