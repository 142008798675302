import DistributorAPI from "../apicontroller/DistributorAPI";
import { tQuoteMinified } from "../../../../shared/datastructures/simplifiedNetworkCommunicationTypes";
import { retrieveQuotesMinified } from "../apicontroller/quote";
import HotecUserAPI from "../apicontroller/HotecUserAPI";
import { Popup } from "../components/Popup";
import {QUOTE_LOADING_BATCH} from "../config";
import store from "../store";

export function removeInformation(){
    return {
        type : "REMOVE_GLOBAL_INFORMATION",
    };
}

export function setBasicInformation(information){
    return {
        payload: information,
        type : "SET_GLOBAL_BASIC_INFORMATION",
    };
}

export function addPopup(popupContent: Popup){
    return {
        payload: popupContent,
        type : "ADD_POPUP",
    };
}

export function closePopup(){
    return {
        type : "CLOSE_POPUP",
    };
}

export function setDistributors(distributors: DistributorAPI[]){
    return {
        payload: distributors,
        type : "SET_GOBAL_DISTRIBUTORS",
    };
}

export function setHotecUsers(users: HotecUserAPI[]){
    return {
        payload: users,
        type : "SET_USERS",
    };
}

export async function retrieveMinifiedQuotes(): Promise<{payload: {quotes: tQuoteMinified[], moreAvailable: boolean}, type: string}>{
    return{
        payload: await retrieveQuotesMinified(undefined, undefined, false, store.getState().globalInformation.filters),
        type: 'SET_MINIFIED_QUOTES',
    }
}

export async function retrieveMoreMinifiedQuotes(skip: number): Promise<{payload: {quotes: tQuoteMinified[], moreAvailable: boolean}, type: string}>{
    return{
        payload: await retrieveQuotesMinified(skip, QUOTE_LOADING_BATCH, false, store.getState().globalInformation.filters),
        type: 'APPEND_MINIFIED_QUOTES',
    }
}

export type Filters = {query?: string, ownerName?: string, startDate?: string, endDate?: string, quoteState?: string, distributorId?: string};
export async function filterMinifiedQuotes(filters: Filters): Promise<{payload: Filters, type: string}>{
    return{
        payload: filters,
        type: 'FILTER_MINIFIED_QUOTES',
    }
}