export interface SubCalculation{
    base: number;
    hotecPriceBeforeDealerDiscount: number; // After global and hotec margin
    hotecPriceAfterDealerDiscount: number; // After global and hotec margin and subtraction of dealer discount
    totalPrice: number; // After global and hotec margin and dealer margin (= totaal dat de klant betaalt)
}

export function initSubCalculation(): SubCalculation{
    return {
        base: 0,
        hotecPriceBeforeDealerDiscount: 0,
        hotecPriceAfterDealerDiscount: 0,
        totalPrice: 0,
    }
}