import * as React from "react";
import { connect } from "react-redux";
import { dt } from "webc-reactcore/src/js/stores/GlobalStore";
import { toSelectItems, createTitlePortalCollapsibleLanguageField, createDescriptionPortalCollapsibleLanguageField, createApiTitlePortalCollapsibleLanguageField, createApiDescriptionPortalCollapsibleLanguageField } from "./helper";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import SimpleSettingsTemplate from "./SimpleSettingsTemplate";
import ConfigBlock from "../../components/ConfigBlock";
import MappedInput from "webc-reactcore/src/js/components/mainlayout/MappedInput";
import { inu, toNumber } from "orbiter-core/src/basic";
import { t } from "ttag";
import SettingsTemplate from "./SettingsTemplate";
import ToolbarContextProvider from "webc-reactcore/src/js/components/toolbar/ToolbarContextProvider";
import StyledToolbar from "./StyledToolbar";
import Supplement from "../../../../../shared/datastructures/staircasedata/Supplement";
import { SupplementTreadAPI, SupplementAPI, SupplementHandrailAPI, SupplementLandingStageAPI, PlacementSupplementAPI } from "../../apicontroller/staircasedata/SupplementAPI";
import { loadTreadSupplements, loadHandrailSupplements, loadLandingStageSupplements, loadPlacementSupplements } from "../../loader";
import InfoScreen from "../../components/InfoScreen";

@(connect((store: any) => {
    return { settings: { ...store.settings }, globalInformation: { ...store.globalInformation } };
}) as any)
export default class SupplementSettings extends React.Component<any, any> {

    private async createEmpty(): Promise<Supplement> {
        return new Supplement(new MultiLanguageString(), new MultiLanguageString(), 0, -1);
    }

    public treadSupplementsToSelectItems() {
        return toSelectItems<SupplementTreadAPI>("treadsupplement", this.props.globalInformation.treadSupplements.sort((x, y) => dt(x.getTitle()) < dt(y.getTitle()) ? -1 : 1), (x) => SupplementTreadAPI.clone(x), (x) => dt(x.getTitle()));
    }

    private async createTreadSupplement(data: Supplement) {
        await SupplementTreadAPI.create(data);
    }

    public handrailSupplementsToSelectItems() {
        return toSelectItems<SupplementHandrailAPI>("handrailsupplement", this.props.globalInformation.handrailSupplements.sort((x, y) => dt(x.getTitle()) < dt(y.getTitle()) ? -1 : 1), (x) => SupplementHandrailAPI.clone(x), (x) => dt(x.getTitle()));
    }

    private async createHandrailSupplement(data: Supplement) {
        await SupplementHandrailAPI.create(data);
    }

    public landingStageSupplementsToSelectItems() {
        return toSelectItems<SupplementLandingStageAPI>("landingstagesupplement", this.props.globalInformation.landingStageSupplements.sort((x, y) => dt(x.getTitle()) < dt(y.getTitle()) ? -1 : 1), (x) => SupplementLandingStageAPI.clone(x), (x) => dt(x.getTitle()));
    }

    private async createLandingStageSupplement(data: Supplement) {
        await SupplementLandingStageAPI.create(data);
    }

    public placementSupplementsToSelectItems() {
        return toSelectItems<PlacementSupplementAPI>("placementsupplement", this.props.globalInformation.placementSupplements.sort((x, y) => dt(x.getTitle()) < dt(y.getTitle()) ? -1 : 1), (x) => PlacementSupplementAPI.clone(x), (x) => dt(x.getTitle()));
    }

    private async createPlacementSupplement(data: Supplement) {
        await PlacementSupplementAPI.create(data);
    }

    public render() {
        return <SettingsTemplate title={"supplementen."}>
            <ToolbarContextProvider>
                <StyledToolbar/>
                <SimpleSettingsTemplate
                    title="Supplementen trede"
                    selectItems={this.treadSupplementsToSelectItems()}

                    reload={loadTreadSupplements.bind(this)}
                    createEmpty={this.createEmpty.bind(this)}
                    create={this.createTreadSupplement.bind(this)}
                    clone={(i: SupplementTreadAPI) => SupplementTreadAPI.clone(i)}

                    renderEdit={(a: SupplementAPI, setSelected: (i: SupplementAPI) => void) => this.renderEdit("trede-supplement", SupplementTreadAPI, a, setSelected)}
                    renderCreate={(a: Supplement, setSelected: (i: Supplement) => void) => this.renderCreate("trede-supplement", a, setSelected)}

                    excludeSettingsTemplate={true}

                    infoScreen={<InfoScreen title={t`Selecteer een supplement.`} image={"/cdn/images/add-box.svg"}/>}
                />
            </ToolbarContextProvider>
            <hr/>
            <ToolbarContextProvider>
                <StyledToolbar/>
                <SimpleSettingsTemplate
                    title="Supplementen leuning"
                    selectItems={this.handrailSupplementsToSelectItems()}

                    reload={loadHandrailSupplements.bind(this)}
                    createEmpty={this.createEmpty.bind(this)}
                    create={this.createHandrailSupplement.bind(this)}
                    clone={(i: SupplementHandrailAPI) => SupplementHandrailAPI.clone(i)}

                    renderEdit={(a: SupplementAPI, setSelected: (i: SupplementAPI) => void) => this.renderEdit("leuning-supplement", SupplementHandrailAPI, a, setSelected)}
                    renderCreate={(a: Supplement, setSelected: (i: Supplement) => void) => this.renderCreate("leuning-supplement", a, setSelected)}

                    excludeSettingsTemplate={true}

                    infoScreen={<InfoScreen title={t`Selecteer een supplement.`} image={"/cdn/images/add-box.svg"}/>}
                />
            </ToolbarContextProvider>
            <hr/>
            <ToolbarContextProvider>
                <StyledToolbar/>
                <SimpleSettingsTemplate
                    title="Supplementen overloop"
                    selectItems={this.landingStageSupplementsToSelectItems()}

                    reload={loadLandingStageSupplements.bind(this)}
                    createEmpty={this.createEmpty.bind(this)}
                    create={this.createLandingStageSupplement.bind(this)}
                    clone={(i: SupplementLandingStageAPI) => SupplementLandingStageAPI.clone(i)}

                    renderEdit={(a: SupplementAPI, setSelected: (i: SupplementAPI) => void) => this.renderEdit("overloop-supplement", SupplementLandingStageAPI, a, setSelected)}
                    renderCreate={(a: Supplement, setSelected: (i: Supplement) => void) => this.renderCreate("overloop-supplement", a, setSelected)}

                    excludeSettingsTemplate={true}

                    infoScreen={<InfoScreen title={t`Selecteer een supplement.`} image={"/cdn/images/add-box.svg"}/>}
                />
            </ToolbarContextProvider>
            <hr/>
            <ToolbarContextProvider>
                <StyledToolbar/>
                <SimpleSettingsTemplate
                    title="Plaatsingssupplementen"
                    selectItems={this.placementSupplementsToSelectItems()}

                    reload={loadPlacementSupplements.bind(this)}
                    createEmpty={this.createEmpty.bind(this)}
                    create={this.createPlacementSupplement.bind(this)}
                    clone={(i: PlacementSupplementAPI) => PlacementSupplementAPI.clone(i)}

                    renderEdit={(a: SupplementAPI, setSelected: (i: SupplementAPI) => void) => this.renderEdit("plaatsingssupplement", PlacementSupplementAPI, a, setSelected)}
                    renderCreate={(a: Supplement, setSelected: (i: Supplement) => void) => this.renderCreate("plaatsingssupplement", a, setSelected)}

                    excludeSettingsTemplate={true}

                    infoScreen={<InfoScreen title={t`Selecteer een supplement.`} image={"/cdn/images/add-box.svg"}/>}
                />
            </ToolbarContextProvider>
            
        </SettingsTemplate>;
    }

    private renderEdit(descriptiveType: string, apiCls: typeof SupplementTreadAPI, a: SupplementTreadAPI, setSelected: (i: SupplementTreadAPI) => void) {
        return this.doRender(
            descriptiveType,
            createApiTitlePortalCollapsibleLanguageField(a, (x) => apiCls.clone(x), setSelected.bind(this)),
            createApiDescriptionPortalCollapsibleLanguageField(a, (x) => apiCls.clone(x), setSelected.bind(this)),
            <MappedInput placeholder={t`Prijs`}
                onBlur={(price) => {
                    const cl: SupplementTreadAPI = apiCls.clone(a);
                    try {
                        cl.getData().setPrice(toNumber(price));
                    } catch (e) {
                        cl.getData().setPrice(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getPrice(), () => "")} />,
            <MappedInput placeholder={t`Maximum aantal stuks`}
                onBlur={(pieces) => {
                    const cl: SupplementTreadAPI = apiCls.clone(a);
                    try {
                        cl.getData().setMaxPieces(toNumber(pieces));
                    } catch (e) {
                        cl.getData().setMaxPieces(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getMaxPieces(), () => "")} />,
        );
    }

    private renderCreate(descriptiveType: string, a: Supplement, setSelected: (i: Supplement) => void) {
        return this.doRender(
            descriptiveType,
            createTitlePortalCollapsibleLanguageField(a, setSelected.bind(this)),
            createDescriptionPortalCollapsibleLanguageField(a, setSelected.bind(this)),
            <MappedInput placeholder={t`Prijs`}
                onBlur={(price) => {
                    const cl: Supplement = a.clone();
                    try {
                        cl.setPrice(toNumber(price));
                    } catch (e) {
                        cl.setPrice(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getPrice(), () => "")} />,
            <MappedInput placeholder={t`Maximum aantal stuks`}
                onBlur={(pieces) => {
                    const cl: Supplement = a.clone();
                    try {
                        cl.setMaxPieces(toNumber(pieces));
                    } catch (e) {
                        cl.setMaxPieces(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getMaxPieces(), () => "")} />,
        );
    }

    private doRender(
        type: string,
        titlePCLF: React.ReactNode,
        descriptionPCLF: React.ReactNode,
        priceMI: React.ReactNode,
        maxPiecesMI: React.ReactNode,
    ): React.ReactNode {
        return <div className={"container-fluid"}>
            <div className="row">
                <div className="col-md-6">
                    <ConfigBlock
                        title="Titel"
                        description={"Geef een titel in voor dit " + type + "."}
                        topMost={true}
                    >
                        {titlePCLF}
                    </ConfigBlock>

                    <ConfigBlock
                        title="Prijs"
                        description={"Geef een prijs in voor dit " + type + "."}
                        topMost={false}
                    >
                        {priceMI}
                    </ConfigBlock>
                </div>
                <div className="col-md-6">
                    <ConfigBlock
                        title="Omschrijving"
                        description={"Geef een omschrijving in voor dit " + type + "."}
                        topMost={true}
                    >
                        {descriptionPCLF}
                    </ConfigBlock>

                    <ConfigBlock
                        title="Maximum aantal stuks"
                        description={"Geef een maximum aantal stuks in voor dit " + type + ". Gebruik -1 wanneer je geen limiet wilt instellen."}
                        topMost={false}
                    >
                        {maxPiecesMI}
                    </ConfigBlock>
                </div>
            </div>
        </div>;
    }

}
