import * as React from "react";
import { connect } from "react-redux";
import { loadStaircaseShapes } from "../../loader";
import { dt } from "webc-reactcore/src/js/stores/GlobalStore";
import { toSelectItems, createTitlePortalCollapsibleLanguageField, createDescriptionPortalCollapsibleLanguageField, createApiTitlePortalCollapsibleLanguageField, createApiDescriptionPortalCollapsibleLanguageField, toObjectSelectItems } from "./helper";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import SimpleSettingsTemplate from "./SimpleSettingsTemplate";
import ConfigBlock from "../../components/ConfigBlock";
import StaircaseShapeAPI from "../../apicontroller/staircasedata/StaircaseShapeAPI";
import StaircaseShape from "../../../../../shared/datastructures/staircasedata/StaircaseShape";
import MappedInput from "webc-reactcore/src/js/components/mainlayout/MappedInput";
import { inu } from "orbiter-core/src/basic";
import { t } from "ttag";
import InfoScreen from "../../components/InfoScreen";
import Select from "webc-reactcore/src/js/components/mainlayout/PortalSelect";
import SelectItem from "../../components/AmountSelectItem";
import DeleteSelectItem from "../../components/DeleteSelectItem";
import * as PortalSelectStyle from "../../../css/PortalSelect.css";
import * as PortalDropdownStyle from "../../../css/PortalDropdown.css";
import PortalDropdown from "webc-reactcore/src/js/components/mainlayout/PortalDropdown";
import StaircaseTypeAPI from "../../apicontroller/staircasedata/StaircaseTypeAPI";
import TypeDependentPrice from "../../../../../shared/datastructures/staircasedata/TypeDependentPrice";
import ObjectID from "bson-objectid";
import HandrailTypeAPI from "../../apicontroller/staircasedata/HandrailTypeAPI";

@(connect((store: any) => {
    return { settings: { ...store.settings }, globalInformation: { ...store.globalInformation } };
}) as any)
export default class StaircaseShapeSettings extends React.Component<any, any> {

    private staircaseTypesToSelectItems(staircaseShape: StaircaseShape) {
        return toObjectSelectItems<StaircaseTypeAPI>(this.props.globalInformation.staircaseTypes
                .filter((x:StaircaseTypeAPI) => 
                    staircaseShape.getTypeDependentPrices().map(x=>x.getStaircaseTypeId().toHexString()).indexOf(x.getId().toHexString()) === -1)
                .sort((x: StaircaseTypeAPI, y: StaircaseTypeAPI) => dt(x.getTitle()) < dt(y.getTitle()) ? -1 : 1), 
            (x) => StaircaseTypeAPI.clone(x), (x) => dt(x.getTitle()));
    }

    public prohibitedHandrailTypesToSelectItems(handrailTypeIds: ObjectID[], onDelete: (id: ObjectID) => void) {
        const handrailTypes : HandrailTypeAPI[] = handrailTypeIds.map(x => this.props.globalInformation.handrailTypes.find(e => e.getId().toHexString() == x.toHexString()));

        const list = [];
        for (const type of handrailTypes) {
            list.push(
                <DeleteSelectItem key={"handrailtypeselectitems" + list.length} 
                    id={type.getId()}
                    onDeleteClick={() => {
                        onDelete(type.getId());
                    }}
                    data={{
                        item: type,
                    }}>{dt(type.getTitle())}</DeleteSelectItem>,
            );
        }
        return list;
    }

    private addProhibitedHandrailTypeId(a: StaircaseShape, id: ObjectID) {
        let newSpindles;
        if(a.getProhibitedHandrailTypeIds().find(x => x == id)){
            // Already present
            newSpindles = [...a.getProhibitedHandrailTypeIds()];
        }else{
            // Not yet present
            newSpindles = [...a.getProhibitedHandrailTypeIds(), id];
        }

        a.setProhibitedHandrailTypeIds(newSpindles);
    }

    private removeProhibitedHandrailTypeId(a: StaircaseShape, id: ObjectID) {
        a.setProhibitedHandrailTypeIds(a.getProhibitedHandrailTypeIds().filter(x => x.toHexString() != id.toHexString()));
    }

    public handrailTypesToSelectItems(a: StaircaseShape) {
        const unfiltered = this.props.globalInformation.handrailTypes;
        const filtered = unfiltered.filter(x => a.getProhibitedHandrailTypeIds().findIndex( y => y.toHexString() == x.getId().toHexString()) == -1);
        return toObjectSelectItems<HandrailTypeAPI>(filtered.sort((x, y) => dt(x.getTitle()) < dt(y.getTitle()) ? -1 : 1), (x) => HandrailTypeAPI.clone(x), (x) => dt(x.getTitle()));
    }

    private getAddedTypeDependentPrices(staircaseShape: StaircaseShape, setSelected: (i: StaircaseShape) => void) {
        const list = [];
        const typeDependentPrices = staircaseShape.getTypeDependentPrices();
        for (let i = 0; i < typeDependentPrices.length; i++) {
            const element = typeDependentPrices[i];
            list.push(
                <SelectItem key={"typedependentpriceselectitem" + list.length}
                    id={i}
                    amount={element.getPrice().toString()}
                    onDeleteClick={() => {
                        const newStaircaseShape: StaircaseShape = staircaseShape.clone();
                        const newTypeDependentPrices = [...typeDependentPrices];
                        newTypeDependentPrices.splice(i, 1);
                        newStaircaseShape.setTypeDependentPrices(newTypeDependentPrices);
                        setSelected(newStaircaseShape);
                    }}
                    onAmountChange={(newAmount) => {
                        const newStaircaseShape: StaircaseShape = staircaseShape.clone();
                        const newTypeDependentPrice = new TypeDependentPrice(element.getStaircaseTypeId(), newAmount);
                        const newTypeDependentPrices = [...typeDependentPrices];
                        newTypeDependentPrices.splice(i, 1, newTypeDependentPrice);
                        newStaircaseShape.setTypeDependentPrices(newTypeDependentPrices);
                        setSelected(newStaircaseShape);
                    }}
                    data={{
                        price: element,
                    }}>{dt((this.props.globalInformation.staircaseTypes.find((x: StaircaseTypeAPI) => x.getId().toHexString() === element.getStaircaseTypeId().toHexString()))?.getTitle())}</SelectItem>,
            );
        }
        return list;
    } 

    public staircaseShapesToSelectItems() {
        return toSelectItems<StaircaseShapeAPI>("staircaseshapes", this.props.globalInformation.staircaseShapes.sort((x, y) => dt(x.getTitle()) < dt(y.getTitle()) ? -1 : 1), (x) => StaircaseShapeAPI.clone(x), (x) => dt(x.getTitle()));
    }

    private async createEmpty(): Promise<StaircaseShape> {
        return new StaircaseShape(0, new MultiLanguageString(), new MultiLanguageString(), [], []);
    }

    private async create(data: StaircaseShape) {
        await StaircaseShapeAPI.create(data);
    }

    public render() {
        return <SimpleSettingsTemplate
            title="Trapvormen"
            selectItems={this.staircaseShapesToSelectItems()}

            reload={loadStaircaseShapes.bind(this)}
            createEmpty={this.createEmpty.bind(this)}
            create={this.create.bind(this)}
            clone={(i: StaircaseShapeAPI) => StaircaseShapeAPI.clone(i)}

            renderEdit={this.renderEdit.bind(this)}
            renderCreate={this.renderCreate.bind(this)}

            infoScreen={<InfoScreen title={t`Selecteer een trapvorm.`} image={"/cdn/images/staircase.svg"}/>}
        />;
    }


    private renderEdit(a: StaircaseShapeAPI, setSelected: (i: StaircaseShapeAPI) => void) {
        const prohibitedHandrailTypesSelectItems = this.prohibitedHandrailTypesToSelectItems(a.getProhibitedHandrailTypeIds(), (toDeleteId) => {
            const cl: StaircaseShapeAPI = StaircaseShapeAPI.clone(a);
            this.removeProhibitedHandrailTypeId(cl.getData(), toDeleteId);
            setSelected(cl);
        });
        return this.doRender(
            createApiTitlePortalCollapsibleLanguageField(a, (x) => StaircaseShapeAPI.clone(x), setSelected.bind(this)),
            createApiDescriptionPortalCollapsibleLanguageField(a, (x) => StaircaseShapeAPI.clone(x), setSelected.bind(this)),
            <MappedInput placeholder={t`Prijs`}
                onBlur={(price) => {
                    const cl: StaircaseShapeAPI = StaircaseShapeAPI.clone(a);
                    try{
                        cl.getData().setPrice(price);
                    }catch(e){
                        cl.getData().setPrice(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getPrice(), () => "")} />,
                <>
                    <Select placeholder={t`Type-afhankelijke factoren`} key={"_typedependentpriceselect"}
                        css={{ compose: PortalSelectStyle }}
                        onSelectionChange={() => {}}
                        maxSelected={0}
                        selectedIds={[]}
                        selectedItems={[]}>
                        {
                            this.getAddedTypeDependentPrices(
                                a.getData(), 
                                (x: StaircaseShape) => {
                                    const cl: StaircaseShapeAPI = StaircaseShapeAPI.clone(a);
                                    cl.setData(x);
                                    setSelected(cl);
                                }
                            )
                        }
                    </Select>
                    <PortalDropdown
                        immediatelyClearSelection={true}
                        placeholder={t`Prijsfactor toevoegen`}
                        css={{ compose: PortalDropdownStyle }}
                        items={this.staircaseTypesToSelectItems(a.getData())}
                        onSelectionChange={(data) => {
                            if(data !== null){
                                const cl: StaircaseShapeAPI = StaircaseShapeAPI.clone(a);
                                cl.getData().setTypeDependentPrices([...a.getTypeDependentPrices(), new TypeDependentPrice((data.item as StaircaseTypeAPI).getId(), a.getPrice())]);
                                setSelected(cl);
                            }
                        }}
                    />
                </>,
                <>
                    <Select placeholder={t`Verboden leuningtypes`} key={"_prohibitedhandrailtypesselect"}
                        searchable={false}
                        css={{ compose: PortalSelectStyle }}
                        onSelectionChange={() => {}}
                        maxSelected={0}
                        selectedIds={[]}
                        selectedItems={[]}>
                        {prohibitedHandrailTypesSelectItems}
                    </Select>
                    <PortalDropdown
                        immediatelyClearSelection={true}
                        placeholder={t`Leuningtype toevoegen`}
                        css={{ compose: PortalDropdownStyle }}
                        items={this.handrailTypesToSelectItems(a.getData())}
                        onSelectionChange={(data) => {
                            if(data !== null){
                                const cl: StaircaseShapeAPI = StaircaseShapeAPI.clone(a);
                                this.addProhibitedHandrailTypeId(cl.getData(), data.item.getId());
                                setSelected(cl);
                            }
                        }}
                    />
                </>,
        );
    }

    private renderCreate(a: StaircaseShape, setSelected: (i: StaircaseShape) => void) {
        const prohibitedHandrailTypesSelectItems = this.prohibitedHandrailTypesToSelectItems(a.getProhibitedHandrailTypeIds(), (toDeleteId) => {
            const cl: StaircaseShape = a.clone();
            this.removeProhibitedHandrailTypeId(cl, toDeleteId);
            setSelected(cl);
        });
        return this.doRender(
            createTitlePortalCollapsibleLanguageField(a, setSelected.bind(this)),
            createDescriptionPortalCollapsibleLanguageField(a, setSelected.bind(this)),
            <MappedInput placeholder={t`Prijs`}
                onBlur={(price) => {
                    const cl: StaircaseShape = a.clone();
                    try{
                        cl.setPrice(price);
                    }catch(e){
                        cl.setPrice(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getPrice(), () => "")} />,
                <>
                    <Select placeholder={t`Type-afhankelijke factoren`} key={"_typedependentpriceselect"}
                        css={{ compose: PortalSelectStyle }}
                        onSelectionChange={() => {}}
                        maxSelected={0}
                        selectedIds={[]}
                        selectedItems={[]}>
                        {this.getAddedTypeDependentPrices(a, setSelected.bind(this))}
                    </Select>
                    <PortalDropdown
                        immediatelyClearSelection={true}
                        placeholder={t`Prijsfactor toevoegen`}
                        css={{ compose: PortalDropdownStyle }}
                        items={this.staircaseTypesToSelectItems(a)}
                        onSelectionChange={(data) => {
                            if(data !== null){
                                const cl: StaircaseShape = a.clone();
                                cl.setTypeDependentPrices([...a.getTypeDependentPrices(), new TypeDependentPrice((data.item as StaircaseTypeAPI).getId(), a.getPrice())]);
                                setSelected(cl);
                            }
                        }}
                    />
                </>,
                <>
                <Select placeholder={t`Verboden leuningtypes`} key={"_prohibitedhandrailtypesselect"}
                    searchable={false}
                    css={{ compose: PortalSelectStyle }}
                    onSelectionChange={() => {}}
                    maxSelected={0}
                    selectedIds={[]}
                    selectedItems={[]}>
                    {prohibitedHandrailTypesSelectItems}
                </Select>
                <PortalDropdown
                    immediatelyClearSelection={true}
                    placeholder={t`Leuningtype toevoegen`}
                    css={{ compose: PortalDropdownStyle }}
                    items={this.handrailTypesToSelectItems(a)}
                    onSelectionChange={(data) => {
                        if(data !== null){
                            const cl: StaircaseShape = a.clone();
                            this.addProhibitedHandrailTypeId(cl, data.item.getId());
                            setSelected(cl);
                        }
                    }}
                />
            </>,
        );
    }

    private doRender(
        titlePCLF: React.ReactNode,
        descriptionPCLF: React.ReactNode,
        priceMI: React.ReactNode,
        typeDependentPriceSelect: React.ReactNode,
        prohibitedHandrailTypeIdsSelect: React.ReactNode,
    ): React.ReactNode {
        return <div className={"container-fluid"}>
            <div className="row">
                <div className="col-md-6">
                    <ConfigBlock
                        title="Titel"
                        description="Geef een titel in voor deze trapvorm."
                        topMost={true}
                    >
                        {titlePCLF}
                    </ConfigBlock>

                    <ConfigBlock
                        title="Prijs"
                        description="Geef een prijs in voor deze trapvorm."
                        topMost={false}
                    >
                        {priceMI}
                    </ConfigBlock>

                    <ConfigBlock
                        title="Verboden leuningtypes"
                        description="Geef aan welke leuningtypes niet gecombineerd mogen worden met deze trapvorm."
                        topMost={false}
                    >
                        {prohibitedHandrailTypeIdsSelect}
                    </ConfigBlock>
                </div>
                <div className="col-md-6">
                    <ConfigBlock
                        title="Omschrijving"
                        description="Geef een omschrijving in voor deze trapvorm."
                        topMost={true}
                    >
                        {descriptionPCLF}
                    </ConfigBlock>

                    <ConfigBlock
                        title="Type-afhankelijke prijsfactor"
                        description="Indien deze trapvorm gecombineerd wordt met het gespecifieerde traptype, wordt de prijsfactor overschreven door de onderstaande factor."
                        topMost={false}
                    >
                        {typeDependentPriceSelect}
                    </ConfigBlock>
                </div>
            </div>
        </div>;
    }

}
