import * as React from "react";
import StepTemplate from "./StepTemplate";
import { t } from "ttag";
import { connect, DispatchProp } from "react-redux";
import { IQuoteCreationStateDispatch, quoteCreateStateToQuote } from "../../reducers/quoteCreationReducer";
import styleable from "react-styleable";
import * as styles from "../../../css/pages/newquote/Step4.css";
import { IArticleCreationStateDispatch } from "../../reducers/articleCreationReducer";
import VisibleArticleTree, { ArticleContainer } from "../../../../../shared/datastructures/VisibleArticleTree";
import { AnyAction } from "redux";
import Quote from "../../../../../shared/datastructures/Quote";
import LoadingIcon from "./article/loaders/LoadingIcon";
import LoadingQuote from "./LoadingQuote";
import { Calculation } from "../../../../../shared/datastructures/ArticleCalculation";

type props = { quoteCreation: IQuoteCreationStateDispatch, articleCreation: IArticleCreationStateDispatch, globalInformation: any, css: any } & DispatchProp<AnyAction>;

/**
 * New quote step 4 page.
 *
 */
@(connect((store: any) => {
    return { quoteCreation: { ...store.quoteCreation }, articleCreation: { ...store.articleCreation }, globalInformation: {...store.globalInformation} };
}) as any)
class Step4 extends React.Component<props, {visibleArticleTree: VisibleArticleTree}> {

    constructor(_){
        super(_);
        this.state = {visibleArticleTree: null};
    }

    private async loadVisibleArticleTree(){
        const qc: IQuoteCreationStateDispatch = this.props.quoteCreation;
        const quote: Quote = await quoteCreateStateToQuote(qc);
        const visibleArticleTree: VisibleArticleTree = new VisibleArticleTree(quote);
        await visibleArticleTree.init();
        
        this.setState({
            visibleArticleTree,
        })
    }

    componentDidMount(){
        this.loadVisibleArticleTree();    
    }

    public render() {
        return (
            <StepTemplate title={t`inzichten.`}>
                <LoadingQuote onLoaded={() => this.loadVisibleArticleTree()}>
                    <div className={"container-fluid"}>
                        <div className="row">
                            <div className="col-md-12">
                                {this.props.globalInformation.loading ? <LoadingIcon large={true} subtitle={t`Deze offerte wordt bijgewerkt. Even geduld.`} /> : this.renderOverview()}
                            </div>
                        </div>
                    </div>
                </LoadingQuote>
            </StepTemplate>
        );
    }

    private calculateMargin(totalPrice: number, hotecPriceAfterDealerDiscount: number): number{
        return totalPrice - hotecPriceAfterDealerDiscount;
    }

    private calculateMarginPct(totalPrice: number, hotecPriceAfterDealerDiscount: number): number{
        if(totalPrice === 0)
            return 0;
        return 100*this.calculateMargin(totalPrice, hotecPriceAfterDealerDiscount)/totalPrice;
    }

    private renderOverview() {
        if(!this.state.visibleArticleTree){
            return "";
        }
        try {
            const flattenedArticles = this.state.visibleArticleTree.flatten();
            const quoteSpecificCharges = this.state.visibleArticleTree.quoteSpecificCharges;
            const calculations = [...flattenedArticles.map(x => x.article.calculation), quoteSpecificCharges.total];

            let totalPriceOverArticles = calculations.reduce((prev, x) => prev + x.totalPrice.totalPrice, 0);
            const totalHotecPriceAfterDealerDiscountOverArticles = calculations.reduce((prev, x) => prev + x.totalPrice.hotecPriceAfterDealerDiscount, 0);

            let totalPlacementPriceOverArticles = calculations.reduce((prev, x) => prev + x.placementPrice.totalPrice, 0);
            let totalTreatmentPriceOverArticles = calculations.reduce((prev, x) => prev + x.treatmentPrice.totalPrice, 0);
            let totalRemainingPriceOverArticles = calculations.reduce((prev, x) => prev + x.staircasePrice.totalPrice + x.handrailPrice.totalPrice + x.landingStagePrice.totalPrice, 0);            
            
            let totalHotecPlacementPriceOverArticles = calculations.reduce((prev, x) => prev + x.placementPrice.hotecPriceAfterDealerDiscount, 0);
            let totalHotecTreatmentPriceOverArticles = calculations.reduce((prev, x) => prev + x.treatmentPrice.hotecPriceAfterDealerDiscount, 0);
            let totalHotecRemainingPriceOverArticles = calculations.reduce((prev, x) => prev + x.staircasePrice.hotecPriceAfterDealerDiscount + x.handrailPrice.hotecPriceAfterDealerDiscount + x.landingStagePrice.hotecPriceAfterDealerDiscount, 0);            

            const margin = this.calculateMargin(totalPriceOverArticles, totalHotecPriceAfterDealerDiscountOverArticles);
            const marginPct = this.calculateMarginPct(totalPriceOverArticles, totalHotecPriceAfterDealerDiscountOverArticles);

            return <div className={this.props.css.outer}>
                <table className={this.props.css.fullWidthTable}>
                    <thead>
                        <tr>
                            <th>Artikel</th>
                            <th>Totaalprijs</th>
                            <th className={this.props.css.gray}>Trap en toebehoren</th>
                            <th className={this.props.css.gray}>Behandeling</th>
                            <th className={this.props.css.gray}>Plaatsing</th>
                            <th>Te betalen aan Hotec</th>
                            <th className={this.props.css.gray}>Trap en toebehoren</th>
                            <th className={this.props.css.gray}>Behandeling</th>
                            <th className={this.props.css.gray}>Plaatsing</th>
                            <th>Marge</th>
                            <th>Procentuele marge</th>
                            <th className={this.props.css.progressBarCell}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {flattenedArticles.map(a => this.renderRow(a.i, a.article, a.variant))}
                        {this._renderRow(flattenedArticles.length, "Algemene toeslag (levering)", quoteSpecificCharges.total)}
                        <tr className={this.props.css.lastRow}>
                            <td></td>
                            <td><b>€{totalPriceOverArticles.toFixed(2)}</b></td>
                            <td className={this.props.css.gray}><b>€{totalRemainingPriceOverArticles.toFixed(2)}</b></td>
                            <td className={this.props.css.gray}><b>€{totalTreatmentPriceOverArticles.toFixed(2)}</b></td>
                            <td className={this.props.css.gray}><b>€{totalPlacementPriceOverArticles.toFixed(2)}</b></td>
                            <td><b>€{totalHotecPriceAfterDealerDiscountOverArticles.toFixed(2)}</b></td>
                            <td className={this.props.css.gray}><b>€{totalHotecRemainingPriceOverArticles.toFixed(2)}</b></td>
                            <td className={this.props.css.gray}><b>€{totalHotecTreatmentPriceOverArticles.toFixed(2)}</b></td>
                            <td className={this.props.css.gray}><b>€{totalHotecPlacementPriceOverArticles.toFixed(2)}</b></td>
                            <td><b>€{margin.toFixed(2)}</b></td>
                            <td><b>{marginPct.toFixed(2)} %</b></td>
                            <td>{this.renderMarginGraph(marginPct)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

        } catch (e) {
            console.error(e);
            return <p>Something went wrong.</p>;
        }
    }

    private _renderRow(i: number, title: string, calculation: Calculation){
        if(calculation.totalPrice.totalPrice === 0)
            return "";
        const margin = this.calculateMargin(calculation.totalPrice.totalPrice, calculation.totalPrice.hotecPriceAfterDealerDiscount);
        const marginPct = this.calculateMarginPct(calculation.totalPrice.totalPrice, calculation.totalPrice.hotecPriceAfterDealerDiscount);
        return <tr key={i}>
            <td>{title}</td>
            <td>€{calculation.totalPrice.totalPrice.toFixed(2)}</td>
            <td className={this.props.css.gray}>€{(calculation.staircasePrice.totalPrice + calculation.handrailPrice.totalPrice + calculation.landingStagePrice.totalPrice).toFixed(2)}</td>
            <td className={this.props.css.gray}>€{calculation.treatmentPrice.totalPrice.toFixed(2)}</td>
            <td className={this.props.css.gray}>€{calculation.placementPrice.totalPrice.toFixed(2)}</td>
            <td>€{calculation.totalPrice.hotecPriceAfterDealerDiscount.toFixed(2)}</td>
            <td className={this.props.css.gray}>€{(calculation.staircasePrice.hotecPriceAfterDealerDiscount + calculation.handrailPrice.hotecPriceAfterDealerDiscount + calculation.landingStagePrice.hotecPriceAfterDealerDiscount).toFixed(2)}</td>
            <td className={this.props.css.gray}>€{calculation.treatmentPrice.hotecPriceAfterDealerDiscount.toFixed(2)}</td>
            <td className={this.props.css.gray}>€{calculation.placementPrice.hotecPriceAfterDealerDiscount.toFixed(2)}</td>
            <td>€{margin.toFixed(2)}</td>
            <td>{marginPct.toFixed(2)} %</td>
            <td>{this.renderMarginGraph(marginPct)}</td>
        </tr>
    }

    private renderRow(i: number, ac: ArticleContainer, variant: boolean){
        return this._renderRow(i, variant ? "-" : "" + " " + ac.article.reference, ac.calculation);
    }

    private renderMarginGraph(marginPct: number){
        return  <div className={this.props.css.progressBorder + (marginPct < 0 ? " " + this.props.css.progressBorderRed : "")}>
                    <div className={this.props.css.progressFill} style={{width: Math.max(marginPct, 0).toFixed(2) + "%"}}></div>
                </div>;
    }

}
export default styleable(styles)(Step4);
