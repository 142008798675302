import {combineReducers} from "redux";

import articleCreation from "./articleCreationReducer";
import globalInformation from "./globalInformationReducer";
import quoteCreation from "./quoteCreationReducer";
import settings from "./settingsReducer";

export default combineReducers({
    articleCreation,
    globalInformation,
    quoteCreation,
    settings,
});
