import * as React from "react";
import { connect } from "react-redux";
import { loadBaseConstantSets } from "../../loader";
import { dt } from "webc-reactcore/src/js/stores/GlobalStore";
import { toSelectItems, createTitlePortalCollapsibleLanguageField, createDescriptionPortalCollapsibleLanguageField, createApiTitlePortalCollapsibleLanguageField, createApiDescriptionPortalCollapsibleLanguageField, toObjectSelectItems } from "./helper";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import SimpleSettingsTemplate from "./SimpleSettingsTemplate";
import ConfigBlock from "../../components/ConfigBlock";
import BaseConstantSetAPI from "../../apicontroller/staircasedata/BaseConstantSetAPI";
import BaseConstantSet from "../../../../../shared/datastructures/staircasedata/BaseConstantSet";
import InfoScreen from "../../components/InfoScreen";
import { gettext, t } from "ttag";
import MappedInput from "webc-reactcore/src/js/components/mainlayout/MappedInput";
import { inu, inun, toNumber } from "orbiter-core/src/basic";
import PortalDropdown from "webc-reactcore/src/js/components/mainlayout/PortalDropdown";
import * as PortalDropdownStyle from "../../../css/PortalDropdown.css";
import { PlacementSupplementAPI } from "../../apicontroller/staircasedata/SupplementAPI";

@(connect((store: any) => {
    return { settings: { ...store.settings }, globalInformation: { ...store.globalInformation } };
}) as any)
export default class BaseConstantSetSettings extends React.Component<any, any> {

    public baseConstantSetsToSelectItems() {
        return toSelectItems<BaseConstantSetAPI>("baseconstantset", this.props.globalInformation.baseConstantSets.sort((x, y) => dt(x.getTitle()) < dt(y.getTitle()) ? -1 : 1), (x) => BaseConstantSetAPI.clone(x), (x) => dt(x.getTitle()));
    }

    public placementSupplementsToSelectItems() {
        return toObjectSelectItems<PlacementSupplementAPI>(this.props.globalInformation.placementSupplements.sort((x: PlacementSupplementAPI, y: PlacementSupplementAPI) => dt(x.getTitle()) < dt(y.getTitle()) ? -1 : 1), (x) => PlacementSupplementAPI.clone(x), (x) => dt(x.getTitle()));
    }

    private async createEmpty(): Promise<BaseConstantSet> {
        return new BaseConstantSet(new MultiLanguageString(), new MultiLanguageString(),
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,     
            0,   
            0,   
            0,   
            0,
            0,
            null,
        );
    }

    private async create(data: BaseConstantSet) {
        await BaseConstantSetAPI.create(data);
    }

    public render() {
        return <SimpleSettingsTemplate
            title="Basisconstanten"
            selectItems={this.baseConstantSetsToSelectItems()}

            reload={loadBaseConstantSets.bind(this)}
            createEmpty={this.createEmpty.bind(this)}
            create={this.create.bind(this)}
            clone={(i: BaseConstantSetAPI) => BaseConstantSetAPI.clone(i)}

            renderEdit={this.renderEdit.bind(this)}
            renderCreate={this.renderCreate.bind(this)}

            infoScreen={<InfoScreen title={t`Selecteer een set basisconstanten.`} image={"/cdn/images/pole.svg"}/>}
        />;
    }

    private renderNumericInput(placeholder: string, property: string, baseConstantSet: BaseConstantSetAPI, setSelected: (i: BaseConstantSetAPI) => void){
        return <MappedInput placeholder={gettext(placeholder)} onBlur={(val) => {
            const cl: BaseConstantSetAPI = BaseConstantSetAPI.clone(baseConstantSet);
            try{
                cl.getData()[property] = toNumber(val);
            }catch(e){
                cl.getData()[property] = 0;
            }
            setSelected(cl);
        }} value={inu(baseConstantSet, (baseConstantSet) => baseConstantSet.getData()[property], () => "")} />;
    }


    private renderNumericInputCreate(placeholder: string, property: string, baseConstantSet: BaseConstantSet, setSelected: (i: BaseConstantSet) => void){
        return <MappedInput placeholder={gettext(placeholder)} onBlur={(val) => {
            const cl: BaseConstantSet = baseConstantSet.clone();
            try{
                cl[property] = toNumber(val);
            }catch(e){
                cl[property] = 0;
            }
            setSelected(cl);
        }} value={inu(baseConstantSet, (baseConstantSet) => baseConstantSet[property], () => "")} />;
    }


    private renderEdit(a: BaseConstantSetAPI, setSelected: (i: BaseConstantSetAPI) => void) {
        return this.doRender(
            createApiTitlePortalCollapsibleLanguageField(a, (x) => BaseConstantSetAPI.clone(x), setSelected.bind(this)),
            createApiDescriptionPortalCollapsibleLanguageField(a, (x) => BaseConstantSetAPI.clone(x), setSelected.bind(this)),
            this.renderNumericInput("Prijs per tegentrede", "pricePerRiser", a, setSelected.bind(this)),
            this.renderNumericInput("Basisprijs overloop", "landingStageBase", a, setSelected.bind(this)),
            this.renderNumericInput("Basisprijs spijlen overloop", "landingStageSpindleBase", a, setSelected.bind(this)),
            this.renderNumericInput("Basisprijs spijlen", "handrailSpindleBase", a, setSelected.bind(this)),
            this.renderNumericInput("Basisprijs overloop afkasting", "landingStageEnclosureBase", a, setSelected.bind(this)),
            this.renderNumericInput("Meerprijs overloop afkasting", "landingStageEnclosureSurcharge", a, setSelected.bind(this)),
            this.renderNumericInput("Treshold breedte afkasting overloop", "landingStageEnclosureSurchargeThreshold", a, setSelected.bind(this)),
            this.renderNumericInput("Minimum aantal tredes plaatsing", "installationMinTreads", a, setSelected.bind(this)),
            this.renderNumericInput("Gratis aantal km plaatsing", "deliveryFreeKmCount", a, setSelected.bind(this)),
            this.renderNumericInput("Kost per km", "deliveryKmCost", a, setSelected.bind(this)),
            this.renderNumericInput("Factor breedte trap", "widthMultiplier", a, setSelected.bind(this)),
            this.renderNumericInput("Basisbreedte trap", "widthTreshold", a, setSelected.bind(this)),
            this.renderNumericInput("Basisprijs trede", "treadBase", a, setSelected.bind(this)),
            this.renderNumericInput("Basisprijs leuning", "handrailBase", a, setSelected.bind(this)),
            this.renderNumericInput("Plaatsingsprijs afkasting", "placementPriceConcreteEnclosure", a, setSelected.bind(this)),
            this.renderNumericInput("Plaatsingsprijs overloopleuning", "placementPriceLandingStageHandrail", a, setSelected.bind(this)),
            this.renderNumericInput("Plaatsingsprijs trapleuning", "placementPriceHandrail", a, setSelected.bind(this)),
            this.renderNumericInput("Prijs per knik", "pricePerBend", a, setSelected.bind(this)),
            this.renderNumericInput("Meerprijs opmeting", "measurementSurcharge", a, setSelected.bind(this)),
            this.renderNumericInput("Meerprijs montage", "assemblySurcharge", a, setSelected.bind(this)),
            this.renderNumericInput("Basisprijs levering", "deliveryBasePrice", a, setSelected.bind(this)),
            this.renderNumericInput("Procentuele basismarge voor alle verdelers (%)", "globalMarginForDealers", a, setSelected.bind(this)),
            <PortalDropdown placeholder={t`Standaard dikte-supplement plaatsing`}
                css={{ compose: PortalDropdownStyle }}
                items={this.placementSupplementsToSelectItems()}
                onSelectionChange={async (data) => {
                    const cl: BaseConstantSetAPI = BaseConstantSetAPI.clone(a);
                    if(data){
                        cl.getData().setDefaultLargeThicknessSupplementId((data.item as PlacementSupplementAPI).getId());
                    }else{
                        cl.getData().setDefaultLargeThicknessSupplementId(null);
                    }
                    setSelected(cl);
                }}
                selectedId={a?.getDefaultLargeThicknessSupplementId()?.toHexString() ?? null}
            />,
        );
    }

    private renderCreate(a: BaseConstantSet, setSelected: (i: BaseConstantSet) => void) {
        return this.doRender(
            createTitlePortalCollapsibleLanguageField(a, setSelected.bind(this)),
            createDescriptionPortalCollapsibleLanguageField(a, setSelected.bind(this)),
            this.renderNumericInputCreate("Prijs per tegentrede", "pricePerRiser", a, setSelected.bind(this)),
            this.renderNumericInputCreate("Basisprijs overloop", "landingStageBase", a, setSelected.bind(this)),
            this.renderNumericInputCreate("Basisprijs spijlen overloop", "landingStageSpindleBase", a, setSelected.bind(this)),
            this.renderNumericInputCreate("Basisprijs spijlen", "handrailSpindleBase", a, setSelected.bind(this)),
            this.renderNumericInputCreate("Basisprijs overloop afkasting", "landingStageEnclosureBase", a, setSelected.bind(this)),
            this.renderNumericInputCreate("Meerprijs overloop afkasting", "landingStageEnclosureSurcharge", a, setSelected.bind(this)),
            this.renderNumericInputCreate("Treshold breedte afkasting overloop", "landingStageEnclosureSurchargeThreshold", a, setSelected.bind(this)),
            this.renderNumericInputCreate("Minimum aantal tredes plaatsing", "installationMinTreads", a, setSelected.bind(this)),
            this.renderNumericInputCreate("Gratis aantal km plaatsing", "deliveryFreeKmCount", a, setSelected.bind(this)),
            this.renderNumericInputCreate("Kost per km", "deliveryKmCost", a, setSelected.bind(this)),
            this.renderNumericInputCreate("Factor breedte trap", "widthMultiplier", a, setSelected.bind(this)),
            this.renderNumericInputCreate("Basisbreedte trap", "widthTreshold", a, setSelected.bind(this)),
            this.renderNumericInputCreate("Basisprijs trede", "treadBase", a, setSelected.bind(this)),
            this.renderNumericInputCreate("Basisprijs leuning", "handrailBase", a, setSelected.bind(this)),
            this.renderNumericInputCreate("Plaatsingsprijs afkasting", "placementPriceConcreteEnclosure", a, setSelected.bind(this)),
            this.renderNumericInputCreate("Plaatsingsprijs overloopleuning", "placementPriceLandingStageHandrail", a, setSelected.bind(this)),
            this.renderNumericInputCreate("Plaatsingsprijs trapleuning", "placementPriceHandrail", a, setSelected.bind(this)),
            this.renderNumericInputCreate("Prijs per knik", "pricePerBend", a, setSelected.bind(this)),
            this.renderNumericInputCreate("Meerprijs opmeting", "measurementSurcharge", a, setSelected.bind(this)),
            this.renderNumericInputCreate("Meerprijs montage", "assemblySurcharge", a, setSelected.bind(this)),
            this.renderNumericInputCreate("Basisprijs levering", "deliveryBasePrice", a, setSelected.bind(this)),
            this.renderNumericInputCreate("Procentuele basismarge voor alle verdelers (%)", "globalMarginForDealers", a, setSelected.bind(this)),
            <PortalDropdown placeholder={t`Standaard dikte-supplement plaatsing`}
                css={{ compose: PortalDropdownStyle }}
                items={this.placementSupplementsToSelectItems()}
                onSelectionChange={async (data) => {
                    const cl: BaseConstantSet = a.clone();
                    if(data){
                        cl.setDefaultLargeThicknessSupplementId((data.item as PlacementSupplementAPI).getId());
                    }else{
                        cl.setDefaultLargeThicknessSupplementId(null);
                    }
                    setSelected(cl);
                }}
                selectedId={a?.getDefaultLargeThicknessSupplementId()?.toHexString() ?? null}
            />,
        );
    }

    private doRender(
        titlePCLF: React.ReactNode,
        descriptionPCLF: React.ReactNode,
        pricePerRiser: React.ReactNode,
        landingStageBase: React.ReactNode,
        landingStageSpindleBase: React.ReactNode,
        handrailSpindleBase: React.ReactNode,
        landingStageEnclosureBase: React.ReactNode,
        landingStageEnclosureSurcharge: React.ReactNode,
        landingStageEnclosureSurchargeThreshold: React.ReactNode,
        installationMinTreads: React.ReactNode,
        deliveryFreeKmCount: React.ReactNode,
        deliveryKmCost: React.ReactNode,
        widthMultiplier: React.ReactNode,
        widthTreshold: React.ReactNode,
        treadBase: React.ReactNode,
        handrailBase: React.ReactNode,
        placementPriceConcreteEnclosure: React.ReactNode,
        placementPriceLandingStageHandrail: React.ReactNode,
        placementPriceHandrail: React.ReactNode,
        pricePerBend: React.ReactNode,
        measurementSurcharge: React.ReactNode,
        assemblySurcharge: React.ReactNode,
        deliveryBasePrice: React.ReactNode,
        globalMarginForDealers: React.ReactNode,
        defaultLargeThicknessSupplementId: React.ReactNode,
    ): React.ReactNode {
        return <div className={"container-fluid"}>
            <div className="row">
                <div className="col-md-6">
                    <ConfigBlock
                        title="Titel"
                        description="Geef een titel in voor deze set basisconstanten."
                        topMost={true}
                    >
                        {titlePCLF}
                    </ConfigBlock>
                </div>
                <div className="col-md-6">
                    <ConfigBlock
                        title="Omschrijving"
                        description="Geef een omschrijving in voor deze set basisconstanten."
                        topMost={true}
                    >
                        {descriptionPCLF}
                    </ConfigBlock>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <ConfigBlock
                        title="Constanten"
                        description="Geef de basisconstanten in."
                        topMost={false}
                    >
                    </ConfigBlock>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    {pricePerRiser}
                    {landingStageBase}
                    {landingStageSpindleBase}
                    {handrailSpindleBase}
                    {landingStageEnclosureBase}
                    {landingStageEnclosureSurcharge}
                    {landingStageEnclosureSurchargeThreshold}
                    {installationMinTreads}
                    {pricePerBend}
                    {measurementSurcharge}
                    {assemblySurcharge}
                    {defaultLargeThicknessSupplementId}
                </div>
                <div className="col-md-6">
                    {deliveryKmCost}
                    {widthMultiplier}
                    {widthTreshold}
                    {treadBase}
                    {handrailBase}
                    {placementPriceConcreteEnclosure}
                    {placementPriceLandingStageHandrail}
                    {placementPriceHandrail}
                    {deliveryFreeKmCount}
                    {deliveryBasePrice}
                    {globalMarginForDealers}
                </div>
            </div>
        </div>;
    }

}
