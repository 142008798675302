exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".XajjZvkj7UmV3Tpfbm64c{\n    padding: 15px;\n}\n._8tw0a4xxw8QRA2HrG3rPq{\n    height: calc(100vh - 160px) ;\n    overflow-y: auto;\n}\n._3rczFVCfC06rZOVwomTH6K{\n    position: fixed;\n    width: 100%;\n    height: 40px;\n    bottom: 0;\n    left: 0;\n    padding: 0 20px 0 20px;\n    background-color: white;\n}", ""]);

// exports
exports.locals = {
	"container": "XajjZvkj7UmV3Tpfbm64c",
	"box": "_8tw0a4xxw8QRA2HrG3rPq",
	"bottomBar": "_3rczFVCfC06rZOVwomTH6K"
};