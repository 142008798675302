import Language from "orbiter-core/src/datastructures/languages/Language";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import SingleLanguageString from "orbiter-core/src/datastructures/languages/SingleLanguageString";
import ApiController from "../ApiController";
import {
    IWallHook,
    default as WallHook
} from "../../../../../shared/datastructures/staircasedata/WallHook";

// TODO: too much code duplication
export default class WallHookAPI extends ApiController<WallHook> implements IWallHook {

    public static getBaseEndpoint(): string{
        return "hotec/wallhook";
    }

    public static getApiKey(): string{
        return "wallHook";
    }

    public async parseFromDictionary(dictionary): Promise<void> {

        const title = new MultiLanguageString(
            dictionary.title.map((x) => {
                // TODO: LanguageAPI?
                const l = new Language(
                    x.languageId,
                    null,
                    null,
                );
                return new SingleLanguageString(
                    l,
                    x.value,
                );
            }),
        );

        const desc = new MultiLanguageString(
            dictionary.description.map((x) => {
                // TODO: LanguageAPI?
                const l = new Language(
                    x.languageId,
                    null,
                    null,
                );
                return new SingleLanguageString(
                    l,
                    x.value,
                );
            }),
        );

        const wallHook = new WallHook(
            title,
            desc,
            dictionary.price,
        );
        this.setData(wallHook);
    }

    public getDescription(): MultiLanguageString {
        return this.getData().getDescription();
    }

    public getPrice(): number {
        return this.getData().getPrice();
    }

    public async setPrice(price: number): Promise<void> {
        this.getData().setPrice(price);
        await this.updateProperty("price", price);
    }

    public async setDescription(newDescription: MultiLanguageString): Promise<void> {
        this.getData().setDescription(newDescription);
        await this.updateProperty("description", newDescription);
    }

    public async setTitle(newTitle: MultiLanguageString): Promise<void> {
        this.getData().setTitle(newTitle);
        await this.updateProperty("title", newTitle);
    }

    public getTitle(): MultiLanguageString {
        return this.getData().getTitle();
    }

}
