exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3QNQX7XK9ybXbkOQ5Fx4kY{\n    width: 100%;\n    display: table;\n    table-layout: fixed;\n}\n._3QNQX7XK9ybXbkOQ5Fx4kY > div:nth-child(even){\n    background-color: #F5F5F5;\n}", ""]);

// exports
exports.locals = {
	"container": "_3QNQX7XK9ybXbkOQ5Fx4kY"
};