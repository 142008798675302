import * as React from "react";
import styleable from "react-styleable";

import * as generalStyles from "../../../css/general.css";
import * as styles from "../../../css/pages/newquote.css";
import * as rightFloatingMenuItemStyle from "../../../css/rightFloatingMenuItemStyle.css";

import RightFloatingMenuItem from "webc-reactcore/src/js/components/mainlayout/RightFloatingMenuItem";
import TextWithIcon from "webc-reactcore/src/js/components/mainlayout/TextWithIcon";
import StyledBox from "../../components/StyledBox";

import {t} from "ttag";
import { Popup, PopupManager } from "../../components/Popup";
import CommentInput, { commentInputPopupFactory, getCommentPreviewForPageType } from "./article/CommentInputComponent";
import { addPageToEmptyValidityCheck, performPageValidityCheck } from "./article/helpers/validityCheckHelper";
import store from "../../store";
import { RouteComponentProps, withRouter } from "react-router-dom";

/**
 * Quote step template.
 *
 * ## styleable
 * * **container** Container styling.
 * * **box** StyledBox element styling.
 * * **bottomBar** Bottom bar element styling.
 *
 * @property {string} props.next - Path to next step (undefined to hide bar)
 * @property {string} props.title - Title
 * @property {PageType?} props.pageType - PageType, if given comment input is available
 * @property {objects} props.children - Children
 */
class StepTemplate extends React.Component<{pageType, next, title, css} & RouteComponentProps> {
    public render() {
        const bottomBar = this.props.next === undefined ? null :   <div className={this.props.css.bottomBar}>
                                                                <RightFloatingMenuItem onClick={async () => {
                                                                    if(this.props.pageType){
                                                                        addPageToEmptyValidityCheck(this.props.pageType);
                                                                        const isValid = await performPageValidityCheck(store.getState().articleCreation.article, this.props.pageType, true);

                                                                        if(!isValid){
                                                                            PopupManager.addPopup(new Popup(<p>{t`Opgelet, je hebt niet alle velden ingevuld. Wil je zeker verdergaan?`}</p>, [
                                                                                {
                                                                                    text: t`Ga verder`,
                                                                                    onClick: ()=>{
                                                                                        this.props.history.push(this.props.next);
                                                                                        PopupManager.closePopup();
                                                                                    }
                                                                                },
                                                                                {
                                                                                    text: t`Blijf hier`,
                                                                                    onClick: ()=>{
                                                                                        PopupManager.closePopup();
                                                                                    },
                                                                                }
                                                                        ]))
                                                                        }else{
                                                                            this.props.history.push(this.props.next);
                                                                        }
                                                                    }else{
                                                                        this.props.history.push(this.props.next);
                                                                    }
                                                                }} title={t`Verder`} css={{compose: rightFloatingMenuItemStyle}}/>
                                                                
                                                                {this.props.pageType ? <span><RightFloatingMenuItem onClick={()=>PopupManager.addPopup(commentInputPopupFactory(this.props.pageType))} title={t`Opmerking`} css={{compose: rightFloatingMenuItemStyle}}/><span className={generalStyles.commentPreview}>{getCommentPreviewForPageType(this.props.pageType)}</span></span> : ""}
                                                            </div>;
        return (
            <div className={this.props.css.container}>
                <StyledBox css={{compose: {container: this.props.css.box}}}>
                    <TextWithIcon text={this.props.title} css={{compose: {container: generalStyles.textWithIconContainer}}}/>
                    {this.props.children}
                </StyledBox>
                {bottomBar}
            </div>
        );
    }
}
export default styleable(styles)(withRouter(StepTemplate));
