import IArticleInput, { ITransportationInput, IQuantifiedSupplement } from "../IArticleInput";
import { inu, isDefined } from "orbiter-core/src/basic";
import { IStaircasePlacementOption } from "../../datastructures/staircasedata/StaircasePlacementOption";
import { IDatabaseController } from "orbiter-core/src/databasecontroller/DatabaseController";
import { INPUT_NOT_DEFINED } from "./inputExceptions";

const _ = undefined;

export const DELIVERY = "delivery"
export const PLACEMENT = "placement"
export const PICKUP = "pickup"

/**
 * Immutable transportation input class.
 */
export default class TransportationInput implements ITransportationInput{

    constructor(
        readonly transportationMethod: string = PLACEMENT,
        readonly placementSupplements: IQuantifiedSupplement[] = [],
        readonly staircasePlacementOptions: (IStaircasePlacementOption & IDatabaseController)[] = [],
        readonly measurementByHotec: boolean = true,
        readonly assembledByHotec: boolean = true,
    ){}

    static async validityCheck(transportationInput: ITransportationInput, article: IArticleInput): Promise<void>{
        this.staircasePlacementOptionsValidityCheck(transportationInput.staircasePlacementOptions, article)
    }

    static staircasePlacementOptionsValidityCheck(placementOptions: (IStaircasePlacementOption & IDatabaseController)[], article: IArticleInput): void{
        if(article.transportation.transportationMethod !== PLACEMENT)
            return;
        const requiredNumberOfPlacementOptions: number = article.unitCount;
        if(placementOptions.length < requiredNumberOfPlacementOptions)
            throw INPUT_NOT_DEFINED;
        for(let i = 0; i<placementOptions.length; i++){
            if(!isDefined(placementOptions[i]))
                throw INPUT_NOT_DEFINED;
        }
    }

    static from(transportationInput: ITransportationInput): TransportationInput{
        return new TransportationInput(
            transportationInput.transportationMethod,
            transportationInput.placementSupplements,
            transportationInput.staircasePlacementOptions,
            transportationInput.measurementByHotec,
            transportationInput.assembledByHotec,
        ).clone();
    }

    clone(
        transportationMethod?: string,
        placementSupplements?: any[], // TODO: change
        staircasePlacementOptions?: (IStaircasePlacementOption & IDatabaseController)[],
        measurementByHotec?: boolean,
        assembledByHotec?: boolean,
    ):TransportationInput{
        return new TransportationInput(
            inu(transportationMethod, ()=>transportationMethod, ()=>this.transportationMethod),
            inu(placementSupplements, ()=>[...placementSupplements], ()=>[...this.placementSupplements]),
            inu(staircasePlacementOptions, ()=>[...staircasePlacementOptions], ()=>[...this.staircasePlacementOptions]),
            inu(measurementByHotec, ()=>measurementByHotec, ()=>this.measurementByHotec),
            inu(assembledByHotec, ()=>assembledByHotec, ()=>this.assembledByHotec),
        )
    }

    public staircasePlacementOptionsEqual(): boolean{
        let prevEl: (IStaircasePlacementOption & IDatabaseController) = null;
        for (let i = 0; i < this.staircasePlacementOptions.length; i++) {
            const element = this.staircasePlacementOptions[i];
            if(prevEl !== null && prevEl.getSid() !== element.getSid()){
                return false;
            }
            prevEl = element;
        }
        return true;
    }

    setTransportationMethod(transportationMethod: string): TransportationInput{
        if(transportationMethod !== this.transportationMethod){
            return this.clone(transportationMethod, []); // Clear supplements when transportation method changes
        }
        return this.clone(transportationMethod);
    }
    
    setPlacementSupplements(placementSupplements: any[]): TransportationInput{
        return this.clone(_, placementSupplements);
    }
    
    setStaircasePlacementOptions(staircasePlacementOptions: any[]): TransportationInput{
        if(this.transportationMethod !== PLACEMENT)
        staircasePlacementOptions = []
        return this.clone(_, _, staircasePlacementOptions);
    }
    
    setMeasurementByHotec(measurementByHotec: boolean): TransportationInput{
        return this.clone(_, _, _, measurementByHotec);
    }
    
    setAssembledByHotec(assembledByHotec: boolean): TransportationInput{
        return this.clone(_, _, _, _, assembledByHotec);
    }
}