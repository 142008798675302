import * as React from "react";
import { inu } from "orbiter-core/src/basic";
import { connect } from "react-redux";
import { loadDistributors } from "../../loader";
import { t } from 'ttag';
import ConfigBlock from "../../components/ConfigBlock";
import { toSelectItems } from "./helper";
import SimpleSettingsTemplate from "./SimpleSettingsTemplate";
import MappedInput from "webc-reactcore/src/js/components/mainlayout/MappedInput";
import InfoScreen from "../../components/InfoScreen";
import DistributorAPI from "../../apicontroller/DistributorAPI";
import Distributor from "../../../../../shared/datastructures/Distributor";
import { createEmptyAddress } from "../../../../../shared/factories/address";
import PortalCheckbox from "webc-reactcore/src/js/components/mainlayout/PortalCheckbox";
import { renderBankAccountsInput } from "./DistributorHelper";
import PortalInputLarge from "webc-reactcore/src/js/components/mainlayout/PortalInputLarge";
import * as extendedPortalInputLargeStyle from "../../../css/extendedPortalInputLarge.css";
import LoadingIcon from "../newquote/article/loaders/LoadingIcon";

@(connect((store: any) => {
    return { settings: { ...store.settings }, globalInformation: { ...store.globalInformation } };
}) as any)
export default class Distributors extends React.Component<any, any> {

    constructor(_) {
        super(_);
        this.state = {
            accountInput: "",
            uploadingLogo: false,
        }
    }

    public distributorsToSelectItems() {
        return toSelectItems<DistributorAPI>("distributorpselect", this.props.globalInformation.distributors.sort((x: DistributorAPI, y: DistributorAPI) => x.getName() < y.getName() ? -1 : 1), (x) => DistributorAPI.clone(x), (x) => x.getName());
    }

    private async createEmpty(): Promise<Distributor<any>> {
        return new Distributor("", null, "", "", createEmptyAddress(), "", "", [], "", "", "", "", "", "", "", "", [], {}, 0, 0, 0, 0, 0, 0, 0, 0, 0, true, true, true, undefined);
    }

    private async create(data: Distributor<any>) {
        await DistributorAPI.create(data);
    }

    public render() {

        return <SimpleSettingsTemplate
            title={t`verdelers.`}
            selectItems={this.distributorsToSelectItems()}

            reload={loadDistributors.bind(this)}
            createEmpty={this.createEmpty.bind(this)}
            create={this.create.bind(this)}
            clone={(i: DistributorAPI) => DistributorAPI.clone(i)}

            renderEdit={this.renderEdit.bind(this)}
            renderCreate={this.renderCreate.bind(this)}

            infoScreen={<InfoScreen title={t`Selecteer een verdeler.`} image={"/cdn/images/log.svg"} />}
        />;

    }

    private renderBankAccountsInput(accounts: string[], onAdd: (account: string) => void, onDelete: (account: string) => void) {
        return renderBankAccountsInput((i) => this.setState({ accountInput: i }), () => this.state.accountInput, accounts, onAdd, onDelete);
    }

    private renderEdit(a: DistributorAPI, setSelected: (i: DistributorAPI) => void) {
        return this.doRender(
            <MappedInput placeholder={t`Bedrijfsnaam`} onBlur={(name) => {
                const cl: DistributorAPI = DistributorAPI.clone(a);
                try {
                    cl.getData().setName(name);
                } catch (e) {
                    cl.getData().setName("");
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getName(), () => "")} />,
            <MappedInput placeholder={t`Naam contactpersoon`} onBlur={(name) => {
                const cl: DistributorAPI = DistributorAPI.clone(a);
                try {
                    cl.getData().setAdministrationName(name);
                } catch (e) {
                    cl.getData().setAdministrationName("");
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getAdministrationName(), () => "")} />,
            <MappedInput placeholder={t`E-mailadres`} onBlur={(email) => {
                const cl: DistributorAPI = DistributorAPI.clone(a);
                try {
                    cl.getData().setAdministrationEmail(email);
                } catch (e) {
                    cl.getData().setAdministrationEmail("");
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getAdministrationEmail(), () => "")} />,
            <MappedInput placeholder={t`Telefoonnummer`} onBlur={(telephone) => {
                const cl: DistributorAPI = DistributorAPI.clone(a);
                try {
                    cl.getData().setTelephone(telephone);
                } catch (e) {
                    cl.getData().setTelephone("");
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getTelephone(), () => "")} />,
            <>
                <MappedInput placeholder={t`Voorvoegsel offertenummer`} onBlur={(quoteNumberPrefix) => {
                    const cl: DistributorAPI = DistributorAPI.clone(a);
                    try {
                        cl.getData().setQuoteNumberPrefix(quoteNumberPrefix);
                    } catch (e) {
                        cl.getData().setQuoteNumberPrefix("");
                    }
                    setSelected(cl);
                }} value={inu(a, (a) => a.getQuoteNumberPrefix(), () => "")} />
                <p><i>Opgelet, het wijzigen van het voorvoegsel zorgt ervoor dat de nummering van nieuwe offertes opnieuw vanaf 1 start, voorafgegaan door het nieuwe voorvoegsel. Je kan hier gebruik maken van de variabele <code>JAAR</code> om het huidige kalenderjaar aan te duiden.</i></p>
            </>,
            <>
                {this.state.uploadingLogo ? <LoadingIcon uncoupledRender={true} center={true} /> : <img width="50%" style={{ maxWidth: "100px" }} src={a.getLogoUrl(a?.getLogoId()?.toString())} />}
                <br /><br />
                <input type="file" id="logo-upload" name="file" onChange={async (e) => {
                    this.setState({ uploadingLogo: true });
                    const file: File = e.currentTarget.files[0];
                    const cl: DistributorAPI = DistributorAPI.clone(a);
                    await cl.uploadLogo(file);
                    setSelected(cl);
                    this.setState({ uploadingLogo: false });
                }} />
            </>,
            <MappedInput placeholder={t`BTW-nummer`} onBlur={(vatNumber) => {
                const cl: DistributorAPI = DistributorAPI.clone(a);
                try {
                    cl.getData().setVatNumber(vatNumber);
                } catch (e) {
                    cl.getData().setVatNumber("");
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getVatNumber(), () => "")} />,
            this.renderBankAccountsInput(a.getBankAccounts(), (account) => {
                // ON ADD
                const cl: DistributorAPI = DistributorAPI.clone(a);
                cl.getData().setBankAccounts([...a.getBankAccounts(), account]);
                setSelected(cl);
            }, (account) => {
                // ON DELETE
                const cl: DistributorAPI = DistributorAPI.clone(a);
                cl.getData().setBankAccounts([...a.getBankAccounts().filter(x => x !== account)]);
                setSelected(cl);
            }),
            <MappedInput placeholder={t`Straat`} onBlur={(streetAndNumber) => {
                const cl: DistributorAPI = DistributorAPI.clone(a);
                try {
                    cl.getData().setAddress({ ...cl.getData().getAddress(), streetAndNumber });
                } catch (e) {
                    cl.getData().setAddress({ ...cl.getData().getAddress() });
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getAddress().streetAndNumber, () => "")} />,
            <MappedInput placeholder={t`Postcode`} onBlur={(postalCode) => {
                const cl: DistributorAPI = DistributorAPI.clone(a);
                try {
                    cl.getData().setAddress({ ...cl.getData().getAddress(), postalCode });
                } catch (e) {
                    cl.getData().setAddress({ ...cl.getData().getAddress() });
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getAddress().postalCode, () => "")} />,
            <MappedInput placeholder={t`Stad`} onBlur={(city) => {
                const cl: DistributorAPI = DistributorAPI.clone(a);
                try {
                    cl.getData().setAddress({ ...cl.getData().getAddress(), city });
                } catch (e) {
                    cl.getData().setAddress({ ...cl.getData().getAddress() });
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getAddress().city, () => "")} />,
            <MappedInput placeholder={t`Land`} onBlur={(country) => {
                const cl: DistributorAPI = DistributorAPI.clone(a);
                try {
                    cl.getData().setAddress({ ...cl.getData().getAddress(), country });
                } catch (e) {
                    cl.getData().setAddress({ ...cl.getData().getAddress() });
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getAddress().country, () => "")} />,
            <MappedInput placeholder={t`Marge Hotec trap` + " (%)"} onBlur={(margin) => {
                const cl: DistributorAPI = DistributorAPI.clone(a);
                try {
                    cl.getData().setHotecMarginStaircase(parseFloat(margin));
                } catch (e) {
                    cl.getData().setHotecMarginStaircase(cl.getData().getHotecMarginStaircase());
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getHotecMarginStaircase(), () => "")} />,
            <MappedInput placeholder={t`Marge Hotec behandeling` + " (%)"} onBlur={(margin) => {
                const cl: DistributorAPI = DistributorAPI.clone(a);
                try {
                    cl.getData().setHotecMarginTreatment(parseFloat(margin));
                } catch (e) {
                    cl.getData().setHotecMarginTreatment(cl.getData().getHotecMarginTreatment());
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getHotecMarginTreatment(), () => "")} />,
            <MappedInput placeholder={t`Marge Hotec plaatsing` + " (%)"} onBlur={(margin) => {
                const cl: DistributorAPI = DistributorAPI.clone(a);
                try {
                    cl.getData().setHotecMarginDistribution(parseFloat(margin));
                } catch (e) {
                    cl.getData().setHotecMarginDistribution(cl.getData().getHotecMarginDistribution());
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getHotecMarginDistribution(), () => "")} />,
            <MappedInput placeholder={t`Korting Hotec trap` + " (%)"} onBlur={(discount) => {
                const cl: DistributorAPI = DistributorAPI.clone(a);
                try {
                    cl.getData().setHotecDiscountStaircase(parseFloat(discount));
                } catch (e) {
                    cl.getData().setHotecDiscountStaircase(cl.getData().getHotecDiscountStaircase());
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getHotecDiscountStaircase(), () => "")} />,
            <MappedInput placeholder={t`Korting Hotec behandeling` + " (%)"} onBlur={(discount) => {
                const cl: DistributorAPI = DistributorAPI.clone(a);
                try {
                    cl.getData().setHotecDiscountTreatment(parseFloat(discount));
                } catch (e) {
                    cl.getData().setHotecDiscountTreatment(cl.getData().getHotecDiscountTreatment());
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getHotecDiscountTreatment(), () => "")} />,
            <MappedInput placeholder={t`Korting Hotec plaatsing` + " (%)"} onBlur={(discount) => {
                const cl: DistributorAPI = DistributorAPI.clone(a);
                try {
                    cl.getData().setHotecDiscountDistribution(parseFloat(discount));
                } catch (e) {
                    cl.getData().setHotecDiscountDistribution(cl.getData().getHotecDiscountDistribution());
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getHotecDiscountDistribution(), () => "")} />,
            <MappedInput placeholder={t`Marge verdeler trap` + " (%)"} onBlur={(margin) => {
                const cl: DistributorAPI = DistributorAPI.clone(a);
                try {
                    cl.getData().setDealerMarginStaircase(parseFloat(margin));
                } catch (e) {
                    cl.getData().setDealerMarginStaircase(cl.getData().getDealerMarginStaircase());
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getDealerMarginStaircase(), () => "")} />,
            <MappedInput placeholder={t`Marge verdeler behandeling` + " (%)"} onBlur={(margin) => {
                const cl: DistributorAPI = DistributorAPI.clone(a);
                try {
                    cl.getData().setDealerMarginTreatment(parseFloat(margin));
                } catch (e) {
                    cl.getData().setDealerMarginTreatment(cl.getData().getDealerMarginTreatment());
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getDealerMarginTreatment(), () => "")} />,
            <MappedInput placeholder={t`Marge verdeler plaatsing` + " (%)"} onBlur={(margin) => {
                const cl: DistributorAPI = DistributorAPI.clone(a);
                try {
                    cl.getData().setDealerMarginDistribution(parseFloat(margin));
                } catch (e) {
                    cl.getData().setDealerMarginDistribution(cl.getData().getDealerMarginDistribution());
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getDealerMarginDistribution(), () => "")} />,
            <PortalCheckbox placeholder={t`Marge en korting Hotec toepassen`}
                onChange={(val) => {
                    if (val !== a.shouldApplyHotecMargin()) {
                        const cl: DistributorAPI = DistributorAPI.clone(a);
                        try {
                            cl.getData().setApplyHotecMargin(val);
                        } catch (e) {
                            cl.setApplyHotecMargin(false);
                        }
                        setSelected(cl);
                    }
                }}
                value={inu(a, (a) => a.shouldApplyHotecMargin(), () => "")} >Marge en korting Hotec toepassen</PortalCheckbox>,
            <PortalCheckbox placeholder={t`Marge verdeler toepassen`}
                onChange={(val) => {
                    if (val !== a.shouldApplyDealerMargin()) {
                        const cl: DistributorAPI = DistributorAPI.clone(a);
                        try {
                            cl.getData().setApplyDealerMargin(val);
                        } catch (e) {
                            cl.setApplyDealerMargin(false);
                        }
                        setSelected(cl);
                    }
                }}
                value={inu(a, (a) => a.shouldApplyDealerMargin(), () => "")} >Marge verdeler toepassen</PortalCheckbox>,
            <PortalCheckbox placeholder={t`Globale marge voor verdelers toepassen`}
                onChange={(val) => {
                    if (val !== a.shouldApplyGlobalMarginForDealers()) {
                        const cl: DistributorAPI = DistributorAPI.clone(a);
                        try {
                            cl.getData().setApplyGlobalMarginForDealers(val);
                        } catch (e) {
                            cl.setApplyGlobalMarginForDealers(false);
                        }
                        setSelected(cl);
                    }
                }}
                value={inu(a, (a) => a.shouldApplyGlobalMarginForDealers(), () => "")} >Globale marge voor verdelers toepassen</PortalCheckbox>,
            <PortalInputLarge placeholder={t`Introductie offerte`}
                css={{ compose: extendedPortalInputLargeStyle }}
                onChange={(v) => {
                    const cl: DistributorAPI = DistributorAPI.clone(a);
                    cl.setPreface(v);
                    setSelected(cl);
                }} value={inu(a, (a) => a.getPreface(), () => "")} />,
            <PortalInputLarge placeholder={t`Afsluiting offerte`}
                css={{ compose: extendedPortalInputLargeStyle }}
                onChange={(v) => {
                    const cl: DistributorAPI = DistributorAPI.clone(a);
                    cl.setClosing(v);
                    setSelected(cl);
                }} value={inu(a, (a) => a.getClosing(), () => "")} />,
            <PortalInputLarge placeholder={t`Voorwaarden offerte`}
                css={{ compose: extendedPortalInputLargeStyle }}
                onChange={(v) => {
                    const cl: DistributorAPI = DistributorAPI.clone(a);
                    cl.setConditions(v);
                    setSelected(cl);
                }} value={inu(a, (a) => a.getConditions(), () => "")} />,
            <PortalInputLarge placeholder={t`Introductie order`}
                css={{ compose: extendedPortalInputLargeStyle }}
                onChange={(v) => {
                    const cl: DistributorAPI = DistributorAPI.clone(a);
                    cl.setOrderPreface(v);
                    setSelected(cl);
                }} value={inu(a, (a) => a.getOrderPreface(), () => "")} />,
            <PortalInputLarge placeholder={t`Afsluiting order`}
                css={{ compose: extendedPortalInputLargeStyle }}
                onChange={(v) => {
                    const cl: DistributorAPI = DistributorAPI.clone(a);
                    cl.setOrderClosing(v);
                    setSelected(cl);
                }} value={inu(a, (a) => a.getOrderClosing(), () => "")} />,
            <PortalInputLarge placeholder={t`Voorwaarden order`}
                css={{ compose: extendedPortalInputLargeStyle }}
                onChange={(v) => {
                    const cl: DistributorAPI = DistributorAPI.clone(a);
                    cl.setOrderConditions(v);
                    setSelected(cl);
                }} value={inu(a, (a) => a.getOrderConditions(), () => "")} />,
            <PortalInputLarge placeholder={t`Template`}
                css={{ compose: extendedPortalInputLargeStyle }}
                onChange={(v) => {
                    const cl: DistributorAPI = DistributorAPI.clone(a);
                    cl.setQuoteEmailTemplate(v);
                    setSelected(cl);
                }} value={inu(a, (a) => a.getQuoteEmailTemplate(), () => "")} />,
            <PortalInputLarge placeholder={t`Template`}
                css={{ compose: extendedPortalInputLargeStyle }}
                onChange={(v) => {
                    const cl: DistributorAPI = DistributorAPI.clone(a);
                    cl.setOrderEmailTemplate(v);
                    setSelected(cl);
                }} value={inu(a, (a) => a.getOrderEmailTemplate(), () => "")} />,
            <button className="styledHover" onClick={async (e) => {
                await a.requestEmailVerification();
                alert(t`Een bevestigingslink werd verzonden. Vraag de verdeler zijn inbox (${a.getAdministrationEmail()}) te controleren en op de bevestigingslink te klikken.`);
            }}>Valideer e-mailadres</button>,
            a.getUsers().length > 0 ? <div>
                <ul>
                    {(() => {
                        return a.getUsers().map(u => {
                            return <li key={u.getEmail() as string}>{u.getName() as string} - {u.getEmail() as string}</li>
                        });
                    })()}
                </ul>
            </div> : undefined,
        );

    }

    private renderCreate(a: Distributor<any>, setSelected: (i: Distributor<any>) => void) {
        return this.doRender(
            <MappedInput placeholder={t`Bedrijfsnaam`} onBlur={(name) => {
                const cl: Distributor<any> = a.clone();
                try {
                    cl.setName(name);
                } catch (e) {
                    cl.setName("");
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getName(), () => "")} />,
            <MappedInput placeholder={t`Naam contactpersoon`} onBlur={(name) => {
                const cl: Distributor<any> = a.clone();
                try {
                    cl.setAdministrationName(name);
                } catch (e) {
                    cl.setAdministrationName("");
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getAdministrationName(), () => "")} />,
            <MappedInput placeholder={t`E-mailadres`} onBlur={(email) => {
                const cl: Distributor<any> = a.clone();
                try {
                    cl.setAdministrationEmail(email);
                } catch (e) {
                    cl.setAdministrationEmail("");
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getAdministrationEmail(), () => "")} />,
            <MappedInput placeholder={t`Telefoonnummer`} onBlur={(telephone) => {
                const cl: Distributor<any> = a.clone();
                try {
                    cl.setTelephone(telephone);
                } catch (e) {
                    cl.setTelephone("");
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getTelephone(), () => "")} />,
            null,
            <>
                <p><i>{t`Het logo kan pas ingesteld worden nadat de verdeler aangemaakt is.`}</i></p>
            </>,
            <MappedInput placeholder={t`BTW-nummer`} onBlur={(vatNumber) => {
                const cl: Distributor<any> = a.clone();
                try {
                    cl.setVatNumber(vatNumber);
                } catch (e) {
                    cl.setVatNumber("");
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getVatNumber(), () => "")} />,
            this.renderBankAccountsInput(a.getBankAccounts(), (account) => {
                // ON ADD
                const cl: Distributor<any> = a.clone();
                cl.setBankAccounts([...a.getBankAccounts(), account]);
                setSelected(cl);
            }, (account) => {
                // ON DELETE
                const cl: Distributor<any> = a.clone();
                cl.setBankAccounts([...a.getBankAccounts().filter(x => x !== account)]);
                setSelected(cl);
            }),
            <MappedInput placeholder={t`Straat`} onBlur={(streetAndNumber) => {
                const cl: Distributor<any> = a.clone();
                try {
                    cl.setAddress({ ...cl.getAddress(), streetAndNumber });
                } catch (e) {
                    cl.setAddress({ ...cl.getAddress() });
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getAddress().streetAndNumber, () => "")} />,
            <MappedInput placeholder={t`Postcode`} onBlur={(postalCode) => {
                const cl: Distributor<any> = a.clone();
                try {
                    cl.setAddress({ ...cl.getAddress(), postalCode });
                } catch (e) {
                    cl.setAddress({ ...cl.getAddress() });
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getAddress().postalCode, () => "")} />,
            <MappedInput placeholder={t`Stad`} onBlur={(city) => {
                const cl: Distributor<any> = a.clone();
                try {
                    cl.setAddress({ ...cl.getAddress(), city });
                } catch (e) {
                    cl.setAddress({ ...cl.getAddress() });
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getAddress().city, () => "")} />,
            <MappedInput placeholder={t`Land`} onBlur={(country) => {
                const cl: Distributor<any> = a.clone();
                try {
                    cl.setAddress({ ...cl.getAddress(), country });
                } catch (e) {
                    cl.setAddress({ ...cl.getAddress() });
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getAddress().country, () => "")} />,
            <MappedInput placeholder={t`Marge Hotec trap` + " (%)"} onBlur={(margin) => {
                const cl: Distributor<any> = a.clone();
                try {
                    cl.setHotecMarginStaircase(parseFloat(margin));
                } catch (e) {
                    cl.setHotecMarginStaircase(cl.getHotecMarginStaircase());
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getHotecMarginStaircase(), () => "")} />,
            <MappedInput placeholder={t`Marge Hotec behandeling` + " (%)"} onBlur={(margin) => {
                const cl: Distributor<any> = a.clone();
                try {
                    cl.setHotecMarginTreatment(parseFloat(margin));
                } catch (e) {
                    cl.setHotecMarginTreatment(cl.getHotecMarginTreatment());
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getHotecMarginTreatment(), () => "")} />,
            <MappedInput placeholder={t`Marge Hotec plaatsing` + " (%)"} onBlur={(margin) => {
                const cl: Distributor<any> = a.clone();
                try {
                    cl.setHotecMarginDistribution(parseFloat(margin));
                } catch (e) {
                    cl.setHotecMarginDistribution(cl.getHotecMarginDistribution());
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getHotecMarginDistribution(), () => "")} />,
            <MappedInput placeholder={t`Korting Hotec trap` + " (%)"} onBlur={(discount) => {
                const cl: Distributor<any> = a.clone();
                try {
                    cl.setHotecDiscountStaircase(parseFloat(discount));
                } catch (e) {
                    cl.setHotecDiscountStaircase(cl.getHotecDiscountStaircase());
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getHotecDiscountStaircase(), () => "")} />,
            <MappedInput placeholder={t`Korting Hotec behandeling` + " (%)"} onBlur={(discount) => {
                const cl: Distributor<any> = a.clone();
                try {
                    cl.setHotecDiscountTreatment(parseFloat(discount));
                } catch (e) {
                    cl.setHotecDiscountTreatment(cl.getHotecDiscountTreatment());
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getHotecDiscountTreatment(), () => "")} />,
            <MappedInput placeholder={t`Korting Hotec plaatsing` + " (%)"} onBlur={(discount) => {
                const cl: Distributor<any> = a.clone();
                try {
                    cl.setHotecDiscountDistribution(parseFloat(discount));
                } catch (e) {
                    cl.setHotecDiscountDistribution(cl.getHotecDiscountDistribution());
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getHotecDiscountDistribution(), () => "")} />,
            <MappedInput placeholder={t`Marge verdeler trap` + " (%)"} onBlur={(margin) => {
                const cl: Distributor<any> = a.clone();
                try {
                    cl.setDealerMarginStaircase(parseFloat(margin));
                } catch (e) {
                    cl.setDealerMarginStaircase(cl.getDealerMarginStaircase());
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getDealerMarginStaircase(), () => "")} />,
            <MappedInput placeholder={t`Marge verdeler behandeling` + " (%)"} onBlur={(margin) => {
                const cl: Distributor<any> = a.clone();
                try {
                    cl.setDealerMarginTreatment(parseFloat(margin));
                } catch (e) {
                    cl.setDealerMarginTreatment(cl.getDealerMarginTreatment());
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getDealerMarginTreatment(), () => "")} />,
            <MappedInput placeholder={t`Marge verdeler plaatsing` + " (%)"} onBlur={(margin) => {
                const cl: Distributor<any> = a.clone();
                try {
                    cl.setDealerMarginDistribution(parseFloat(margin));
                } catch (e) {
                    cl.setDealerMarginDistribution(cl.getDealerMarginDistribution());
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getDealerMarginDistribution(), () => "")} />,
            <PortalCheckbox placeholder={t`Marge en korting Hotec toepassen`}
                onChange={(val) => {
                    if (val !== a.shouldApplyHotecMargin()) {
                        const cl: Distributor<any> = a.clone();
                        try {
                            cl.setApplyHotecMargin(val);
                        } catch (e) {
                            cl.setApplyHotecMargin(false);
                        }
                        setSelected(cl);
                    }
                }}
                value={inu(a, (a) => a.shouldApplyHotecMargin(), () => "")} >Marge en korting Hotec toepassen</PortalCheckbox>,
            <PortalCheckbox placeholder={t`Marge verdeler toepassen`}
                onChange={(val) => {
                    if (val !== a.shouldApplyDealerMargin()) {
                        const cl: Distributor<any> = a.clone();
                        try {
                            cl.setApplyDealerMargin(val);
                        } catch (e) {
                            cl.setApplyDealerMargin(false);
                        }
                        setSelected(cl);
                    }
                }}
                value={inu(a, (a) => a.shouldApplyDealerMargin(), () => "")} >Marge verdeler toepassen</PortalCheckbox>,
            <PortalCheckbox placeholder={t`Globale marge voor verdelers toepassen`}
                onChange={(val) => {
                    if (val !== a.shouldApplyGlobalMarginForDealers()) {
                        const cl: Distributor<any> = a.clone();
                        try {
                            cl.setApplyGlobalMarginForDealers(val);
                        } catch (e) {
                            cl.setApplyGlobalMarginForDealers(false);
                        }
                        setSelected(cl);
                    }
                }}
                value={inu(a, (a) => a.shouldApplyGlobalMarginForDealers(), () => "")} >Globale marge voor verdelers toepassen</PortalCheckbox>,
            <PortalInputLarge placeholder={t`Introductie offerte`}
                css={{ compose: extendedPortalInputLargeStyle }}
                onChange={(v) => {
                    const cl: Distributor<any> = a.clone();
                    cl.setPreface(v);
                    setSelected(cl);
                }} value={inu(a, (a) => a.getPreface(), () => "")} />,
            <PortalInputLarge placeholder={t`Afsluiting offerte`}
                css={{ compose: extendedPortalInputLargeStyle }}
                onChange={(v) => {
                    const cl: Distributor<any> = a.clone();
                    cl.setClosing(v);
                    setSelected(cl);
                }} value={inu(a, (a) => a.getClosing(), () => "")} />,
            <PortalInputLarge placeholder={t`Voorwaarden offerte`}
                css={{ compose: extendedPortalInputLargeStyle }}
                onChange={(v) => {
                    const cl: Distributor<any> = a.clone();
                    cl.setConditions(v);
                    setSelected(cl);
                }} value={inu(a, (a) => a.getConditions(), () => "")} />,
            <PortalInputLarge placeholder={t`Introductie order`}
                css={{ compose: extendedPortalInputLargeStyle }}
                onChange={(v) => {
                    const cl: Distributor<any> = a.clone();
                    cl.setOrderPreface(v);
                    setSelected(cl);
                }} value={inu(a, (a) => a.getOrderPreface(), () => "")} />,
            <PortalInputLarge placeholder={t`Afsluiting order`}
                css={{ compose: extendedPortalInputLargeStyle }}
                onChange={(v) => {
                    const cl: Distributor<any> = a.clone();
                    cl.setOrderClosing(v);
                    setSelected(cl);
                }} value={inu(a, (a) => a.getOrderClosing(), () => "")} />,
            <PortalInputLarge placeholder={t`Voorwaarden order`}
                css={{ compose: extendedPortalInputLargeStyle }}
                onChange={(v) => {
                    const cl: Distributor<any> = a.clone();
                    cl.setOrderConditions(v);
                    setSelected(cl);
                }} value={inu(a, (a) => a.getOrderConditions(), () => "")} />,
            <PortalInputLarge placeholder={t`Template`}
                css={{ compose: extendedPortalInputLargeStyle }}
                onChange={(v) => {
                    const cl: Distributor<any> = a.clone();
                    cl.setQuoteEmailTemplate(v);
                    setSelected(cl);
                }} value={inu(a, (a) => a.getQuoteEmailTemplate(), () => "")} />,
            <PortalInputLarge placeholder={t`Template`}
                css={{ compose: extendedPortalInputLargeStyle }}
                onChange={(v) => {
                    const cl: Distributor<any> = a.clone();
                    cl.setOrderEmailTemplate(v);
                    setSelected(cl);
                }} value={inu(a, (a) => a.getOrderEmailTemplate(), () => "")} />,
        );
    }

    private doRender(
        distributorNameInput: React.ReactNode,
        contactPersonNameInput: React.ReactNode,
        emailInput: React.ReactNode,
        telephoneInput: React.ReactNode,
        quoteNumberPrefixInput: React.ReactNode,
        logoInput: React.ReactNode,
        vatNumberInput: React.ReactNode,
        bankAccountsInput: React.ReactNode,
        streetInput: React.ReactNode,
        postalCodeInput: React.ReactNode,
        cityInput: React.ReactNode,
        countryInput: React.ReactNode,
        hotecMarginStaircaseInput: React.ReactNode,
        hotecMarginTreatmentInput: React.ReactNode,
        hotecMarginDistributionInput: React.ReactNode,
        hotecDiscountStaircaseInput: React.ReactNode,
        hotecDiscountTreatmentInput: React.ReactNode,
        hotecDiscountDistributionInput: React.ReactNode,
        dealerMarginStaircaseInput: React.ReactNode,
        dealerMarginTreatmentInput: React.ReactNode,
        dealerMarginDistributionInput: React.ReactNode,
        applyHotecMarginInput: React.ReactNode,
        applyDealerMarginInput: React.ReactNode,
        applyGlobalMarginForDealersInput: React.ReactNode,
        prefaceInput: React.ReactNode,
        closingInput: React.ReactNode,
        conditionsInput: React.ReactNode,
        orderPrefaceInput: React.ReactNode,
        orderClosingInput: React.ReactNode,
        orderConditionsInput: React.ReactNode,
        quoteEmailTemplateInput: React.ReactNode,
        orderEmailTemplateInput: React.ReactNode,
        emailVerificationInput?: React.ReactNode,
        users?: React.ReactNode,
    ) {
        return (<div className={"container-fluid"}>
            <div className="row">
                <div className="col-md-6">
                    <ConfigBlock
                        title={t`Logo`}
                        description={t`Opgelet, het wijzigen van het logo wordt onmiddelijk toegepast.`}
                        topMost={true}
                    >
                        {logoInput}
                    </ConfigBlock>
                </div>
                <div className="col-md-6">
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <ConfigBlock
                        title={"Bedrijfsinformatie"}
                        description="Algemene informatie."
                        topMost={false}
                    >
                        {distributorNameInput}
                        {contactPersonNameInput}
                        {emailInput}
                        {telephoneInput}
                        {quoteNumberPrefixInput}
                    </ConfigBlock>
                </div>
                <div className="col-md-6">

                    <ConfigBlock
                        title="Adres"
                        description="Het adres van deze verdeler."
                        topMost={false}
                    >
                        {streetInput}
                        {postalCodeInput}
                        {cityInput}
                        {countryInput}
                    </ConfigBlock>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <ConfigBlock
                        title={"Betalingsinformatie"}
                        description="BTW- en betalingsinformatie."
                        topMost={false}
                    >
                        <div className="row">
                            <div className="col-md-6">
                                {bankAccountsInput}
                            </div>

                            <div className="col-md-6">
                                {vatNumberInput}
                            </div>

                        </div>
                    </ConfigBlock>
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-md-6">
                    <ConfigBlock
                        title={"Marges"}
                        description="Bepaal de marges, procentueel uitgedrukt, die gebruikt worden - indien toegepast - bij offerte-berekeningen."
                        topMost={false}
                    >
                        {hotecMarginStaircaseInput}
                        {hotecMarginTreatmentInput}
                        {hotecMarginDistributionInput}
                        {hotecDiscountStaircaseInput}
                        {hotecDiscountTreatmentInput}
                        {hotecDiscountDistributionInput}
                        {dealerMarginStaircaseInput}
                        {dealerMarginTreatmentInput}
                        {dealerMarginDistributionInput}
                    </ConfigBlock>
                </div>
                <div className="col-md-6">
                    <ConfigBlock
                        title={"Toepassing marges"}
                        description="Bepaal welke marges toegepast mogen worden bij offerte-berekeningen."
                        topMost={false}
                    >
                        {applyHotecMarginInput}
                        {applyDealerMarginInput}
                        {applyGlobalMarginForDealersInput}
                    </ConfigBlock>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <ConfigBlock
                        title={"Gebruikers"}
                        description="Gebruikers toegekend aan deze verdeler."
                        topMost={false}
                    >
                        {users ? users : <i>Geen gebruikers toegekend.</i>}
                    </ConfigBlock>
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-md-4">
                    <ConfigBlock
                        title={t`Introductie offerte`}
                        description="Introductie die weergegeven wordt op de offerte."
                        topMost={false}
                    >
                        {prefaceInput}
                    </ConfigBlock>
                </div>
                <div className="col-md-4">
                    <ConfigBlock
                        title={t`Afsluiting offerte`}
                        description="Afsluiting die weergegeven wordt op de offerte."
                        topMost={false}
                    >
                        {closingInput}
                    </ConfigBlock>
                </div>
                <div className="col-md-4">
                    <ConfigBlock
                        title={t`Voorwaarden offerte`}
                        description="Voorwaarden die weergegeven worden op de offerte."
                        topMost={false}
                    >
                        {conditionsInput}
                    </ConfigBlock>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <ConfigBlock
                        title={t`Introductie order`}
                        description="Introductie die weergegeven wordt op de order."
                        topMost={false}
                    >
                        {orderPrefaceInput}
                    </ConfigBlock>
                </div>
                <div className="col-md-4">
                    <ConfigBlock
                        title={t`Afsluiting order`}
                        description="Afsluiting die weergegeven wordt op de order."
                        topMost={false}
                    >
                        {orderClosingInput}
                    </ConfigBlock>
                </div>
                <div className="col-md-4">
                    <ConfigBlock
                        title={t`Voorwaarden order`}
                        description="Voorwaarden die weergegeven worden op de order."
                        topMost={false}
                    >
                        {orderConditionsInput}
                    </ConfigBlock>
                </div>
            </div>
            <div className="row">
                <div className="col-md-5">
                    <ConfigBlock
                        title={t`E-mailtemplate offerte`}
                        description={t`Dit template wordt als basis gebruikt bij het verzenden van offertes via e-mail. De effectieve inhoud is aanpasbaar bij het verzenden van de e-mail.`}
                        topMost={false}
                    >
                        {quoteEmailTemplateInput}
                    </ConfigBlock>
                </div>
                <div className="col-md-5">
                    <ConfigBlock
                        title={t`E-mailtemplate order`}
                        description={t`Dit template wordt als basis gebruikt bij het verzenden van orders via e-mail. De effectieve inhoud is aanpasbaar bij het verzenden van de e-mail.`}
                        topMost={false}
                    >
                        {orderEmailTemplateInput}
                    </ConfigBlock>
                </div>
                <div className="col-md-2">
                    {emailVerificationInput ? <ConfigBlock
                        title={t`E-mailconfiguratie`}
                        description={t`Valideer het e-mailadres van de verdeler. Vanaf dan kan de verdeler offertes via e-mail verzenden.`}
                        topMost={false}
                    >
                        {emailVerificationInput}
                    </ConfigBlock> : ""}
                </div>
            </div>
        </div>);
    }
}
