import { IWallHandrailInput } from "../IArticleInput";
import { IDatabaseController } from "orbiter-core/src/databasecontroller/DatabaseController";
import { inu, isDefined, isValidStrictlyPositiveInteger, isValidPositiveInteger, isValidStrictlyPositiveFloatingPoint, isValidBoolean } from "orbiter-core/src/basic";
import { INPUT_NOT_DEFINED, INVALID_TYPE } from "./inputExceptions";
import { IWallHandrail } from "../staircasedata/WallHandrail";
import { IWallHook } from "../staircasedata/WallHook";

const _ = undefined;

/**
 * Immutable wall handrail input class.
 */
export default class WallHandrailInput implements IWallHandrailInput{

    constructor(
        readonly type: IWallHandrail & IDatabaseController = null,
        readonly hook: IWallHook & IDatabaseController | null = null,
        readonly length: number = 0,
        readonly hookCount: number = 0,
        readonly bendCount: number = 0,
        readonly ignoreWoodType: boolean = false,
    ){}

    static from(wallHandrailInput: IWallHandrailInput): WallHandrailInput{
        if(wallHandrailInput === null){
            return null;
        }
        return new WallHandrailInput(
            wallHandrailInput.type,
            wallHandrailInput.hook,
            wallHandrailInput.length,
            wallHandrailInput.hookCount,
            wallHandrailInput.bendCount,
            wallHandrailInput.ignoreWoodType,
        ).clone();
    }

    static async validityCheck(wallHandrail: IWallHandrailInput): Promise<void>{
        if(wallHandrail === null)
            return;
        this.typeValidityCheck(wallHandrail.type);
        this.hookValidityCheck(wallHandrail.hook);
        this.lengthValidityCheck(wallHandrail.length);
        this.hookCountValidityCheck(wallHandrail.hookCount, wallHandrail.hook);
        this.bendCountValidityCheck(wallHandrail.bendCount);
        if(!isValidBoolean(wallHandrail.ignoreWoodType)){
            throw INVALID_TYPE;
        }
    }

    static typeValidityCheck(type: IWallHandrail & IDatabaseController): void{
        if(!isDefined(type))
            throw INPUT_NOT_DEFINED;
    }

    static hookValidityCheck(hook: IWallHook & IDatabaseController): void{
        if(hook === null)
            return;
        if(!isDefined(hook))
            throw INPUT_NOT_DEFINED;
    }

    static lengthValidityCheck(length: number): void{
        if(!isDefined(length))
            throw INPUT_NOT_DEFINED;
        else if(!isValidStrictlyPositiveFloatingPoint(length))
            throw INVALID_TYPE;
    }

    static hookCountValidityCheck(hookCount: number, hook: IWallHook | null): void{
        if(!isDefined(hookCount))
            throw INPUT_NOT_DEFINED;
        else if(!isValidPositiveInteger(hookCount))
            throw INVALID_TYPE;
        if(hook !== null && !isValidStrictlyPositiveInteger(hookCount))
            throw INVALID_TYPE;
    }

    static bendCountValidityCheck(bendCount: number): void{
        if(!isDefined(bendCount))
            throw INPUT_NOT_DEFINED;
        else if(!isValidPositiveInteger(bendCount))
            throw INVALID_TYPE;
    }

    clone(
        type?: IWallHandrail & IDatabaseController,
        hook?: IWallHook & IDatabaseController | null,
        length?: number,
        hookCount?: number,
        bendCount?: number,
        ignoreWoodType?: boolean,
    ):WallHandrailInput{
        return new WallHandrailInput(
            inu(type, ()=>type, ()=>this.type),
            inu(hook, ()=>hook, ()=>this.hook),
            inu(length, ()=>length, ()=>this.length),
            inu(hookCount, ()=>hookCount, ()=>this.hookCount),
            inu(bendCount, ()=>bendCount, ()=>this.bendCount),
            inu(ignoreWoodType, ()=>ignoreWoodType, ()=>this.ignoreWoodType),
        )
    }

    setType(type: IWallHandrail & IDatabaseController): WallHandrailInput{
        return this.clone(type);
    }
    
    setHook(hook: IWallHook & IDatabaseController | null): WallHandrailInput{
        return this.clone(_, hook);
    }

    setLength(length: number): WallHandrailInput{
        return this.clone(_, _, length);
    }

    setHookCount(hookCount: number): WallHandrailInput{
        return this.clone(_, _, _, hookCount);
    }

    setBendCount(bendCount: number): WallHandrailInput{
        return this.clone(_, _, _, _, bendCount);
    }

    setIgnoreWoodType(ignoreWoodType: boolean): WallHandrailInput{
        return this.clone(_, _, _, _, _, ignoreWoodType);
    }

}