import * as React from "react";
import {connect} from "react-redux";
import Loading from "webc-reactcore/src/js/components/mainlayout/Loading";
import * as loadingStyle from "../../../../../css/components/Loading.css";

@(connect((store: any) => {
    return {globalInformation: {...store.globalInformation}, quoteCreation: {...store.quoteCreation}};
}) as any)
export default class QuoteLoadingStatus extends React.Component<any> {

    public render() {
        if(this.props.globalInformation.openingQuote /* || this.props.quoteCreation.loading === 1*/){
            return <Loading css={{compose: loadingStyle}}/>;
        }else{
            return this.props.children;
        }
    }
}
