import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { HashRouter, Route, Switch, withRouter } from "react-router-dom";
import { removeInformation, initQuote } from "./actions/quoteCreationActions";
import load, { loadSettings } from "./loader";
import store from "./store";
import PopupRenderer from './components/Popup';
import Choose from "webc-reactcore/src/js/components/mainlayout/Choose";
import HMenu from "webc-reactcore/src/js/components/mainlayout/HorizontalMenu";
import MenuItem from "webc-reactcore/src/js/components/mainlayout/MenuItem";
import RightFloatingMenuItem from "webc-reactcore/src/js/components/mainlayout/RightFloatingMenuItem";
import MainLayout from "webc-reactcore/src/js/pages/mainlayout";

import * as horizontalMenuStyle from "../css/horizontalMenuStyle.css";
import * as horizontalMenuStyleSettings from "../css/horizontalMenuStyleSettings.css";
import * as mainLayoutStyle from "../css/mainlayout.css";
import * as menuItemStyle from "../css/menuItemStyle.css";
import * as menuSeparatorStyle from "../css/menuSeparatorStyle.css";
import * as rightFloatingMenuItemStyle from "../css/rightFloatingMenuItemStyle.css";
import * as languageChoiceStyle from "../css/LanguageChoice.css";

import Step1 from "./pages/newquote/Step1";
import Step2 from "./pages/newquote/Step2";
import Step3 from "./pages/newquote/Step3";
import Step4 from "./pages/newquote/Step4";
import Step5 from "./pages/newquote/Step5";
import Overview from "./pages/Overview";
import StaircaseType from "./pages/settings/StaircaseType";
import Wood from "./pages/settings/Wood";

import ArticleHandrail from "./pages/newquote/article/Handrail";
import ArticleLandingStage from "./pages/newquote/article/LandingStage";
import ArticleShape from "./pages/newquote/article/Shape";
import ArticleStaircase from "./pages/newquote/article/Staircase";
import ArticleSummary from "./pages/newquote/article/Summary";
import ArticleTransportation from "./pages/newquote/article/Transportation";
import ArticleTreatment from "./pages/newquote/article/Treatment";

import NewArticleMenuItems from "./pages/menus/NewArticleMenu";
import ArticleLoader from "./pages/newquote/article/loaders/ArticleLoader";
import Profile from "./pages/settings/Profile";
import Distributors from "./pages/settings/Distributors";
import HandrailPoleTypeSettings from "./pages/settings/HandrailPoleTypeSettings";
import HandrailPoleFinishSettings from "./pages/settings/HandrailPoleFinishSettings";
import WallHandrailSettings from "./pages/settings/WallHandrailSettings";

import { t } from 'ttag';
import i18n from "./i18n";
import { changeLocale } from "webc-reactcore/src/js/actions/LanguageActions";
import globalStore from "webc-reactcore/src/js/stores/GlobalStore";
import SpindleThreadTypeSettings from "./pages/settings/SpindleThreadType";
import StaircasePlacementOptionSettings from "./pages/settings/StaircasePlacementOption";
import StaircaseShapeSettings from "./pages/settings/StaircaseShape";
import ThicknessSettings from "./pages/settings/Thickness";
import SupplementSettings from "./pages/settings/Supplement";
import TreatmentTypeSettings from "./pages/settings/TreatmentType";
import HandrailTypeSettings from "./pages/settings/HandrailType";
import Users from "./pages/settings/Users";
import Permissions from "./pages/settings/Permissions";
import { retrieveMinifiedQuotes } from "./actions/globalInformationActions";
import HandrailHandleSettings from "./pages/settings/HandrailHandle";
import QuoteLoadingStatus from "./pages/newquote/article/loaders/QuoteLoadingStatus";
import BaseConstantSetSettings from "./pages/settings/BaseConstantSet";
import WallHookSettings from "./pages/settings/WallHookSettings";
import LoadingIcon from "./pages/newquote/article/loaders/LoadingIcon";
import ThicknessRuleSettings from "./pages/settings/ThicknessRule";
import QuoteRelatedPage from "./pages/newquote/QuoteRelatedPage";
import SettingsRelatedPage from "./pages/newquote/SettingsRelatedPage";
import ProfileUser from "./pages/settings/ProfileUser";
import { hasMatchingPermission } from "./helpers";
import { SETTINGS, TEAM_USER_MANAGEMENT } from "../../../shared/permissions";
import { doJsonApiCall } from "webc-reactcore/src/js/apicommunication";
import Team from "./pages/settings/Team";

// load locale & translations
const availableLanguages = ['en', 'nl'];
// const defaultLanguage = 'en';
i18n.setAvailableLanguages(availableLanguages);
changeLocale(undefined, i18n);

const app = document.getElementById("app");

const logo = <img src="/cdn/portal/assets/logohotec.png" />;

class RegularMenu extends React.Component<any, any>{
    public render(){
        return <HMenu key="general" title={t`Website`} css={{ compose: horizontalMenuStyle }}>
        <MenuItem key="general_overview" to="/" title={t`Overzicht`} css={{ compose: menuItemStyle }} />
        <MenuItem key="general_profile" to="/general/profile/distributor" title={t`Profiel`} css={{ compose: menuItemStyle }} />
        {!hasMatchingPermission(SETTINGS) ? "" : 
            <MenuItem key="general_settings" to="/general/settings/distributors" title={t`Instellingen`} css={{ compose: menuItemStyle }} />
        }
       
        <RightFloatingMenuItem key="new_step1" title={t`Nieuwe offerte`} css={{ compose: rightFloatingMenuItemStyle }}  onClick={async () => {
            store.dispatch(await initQuote());
            this.props.history.push('/quote/1');
        }}/>
        <LoadingIcon />
    </HMenu>;
    }
}
const RegularMenuWithRouter = withRouter(RegularMenu);

class All extends React.Component<any, any>{

    constructor(a) {
        super(a);
        this.state = {}; // Required for language force update
    }

    private regularMenuItems = () => <RegularMenuWithRouter/>;

    private newQuoteMenuItems = () => <HMenu key="newquote" css={{ compose: horizontalMenuStyle }}>
        <MenuItem key="new_step1" to="/quote/1" title={t`Kennismaking`} css={{ compose: menuItemStyle }} />
        <MenuItem key="new_step2" to="/quote/2" title={t`Artikels`} css={{ compose: menuItemStyle }} />
        <MenuItem key="new_step3" to="/quote/3" title={t`Finaliseren`} css={{ compose: menuItemStyle }} />
        <MenuItem key="new_step4" to="/quote/4" title={t`Inzichten`} css={{ compose: menuItemStyle }} />
        <MenuItem key="new_step5" to="/quote/5" title={t`Logboek`} css={{ compose: menuItemStyle }} />
        
        <RightFloatingMenuItem key="new_to_general_overview" to="/" title={t`Sluiten`} css={{ compose: rightFloatingMenuItemStyle }} onClick={async () => {
            store.dispatch(removeInformation());
            store.dispatch(await retrieveMinifiedQuotes())
        }} />
        <LoadingIcon />
    </HMenu>;

    private settingsMenuItems = () => <HMenu key="settings" css={{ compose: horizontalMenuStyleSettings }}>
        <MenuItem key="settings_back" to="/" title="<" css={{ compose: menuItemStyle }} />
        <LoadingIcon />
        
        <MenuItem key="settings_distributors" to="/general/settings/distributors" title={t`Verdelers`} css={{ compose: menuItemStyle }} />
        <MenuItem key="settings_users" to="/general/settings/users" title={t`Gebruikers`} css={{ compose: menuItemStyle }} />
        {!hasMatchingPermission() ? "" : // Requires root permission
            <MenuItem key="settings_permissions" to="/general/settings/permissions" title={t`Toegangsrechten`} css={{ compose: menuItemStyle }} />
        }
        <MenuItem key="separator" title={t`|`} css={{ compose: menuSeparatorStyle }} />

        <MenuItem key="settings_treatmenttype" to="/general/settings/treatmenttype" title={t`Behandelingen`} css={{ compose: menuItemStyle }} />
        <MenuItem key="settings_handrailpolefinish" to="/general/settings/handrailpolefinish" title={t`Bovenafwerkingen`} css={{ compose: menuItemStyle }} />
        <MenuItem key="settings_baseconstantsets" to="/general/settings/baseconstantsets" title={t`Constanten`} css={{ compose: menuItemStyle }} />
        <MenuItem key="settings_thickness" to="/general/settings/thickness" title={t`Diktes`} css={{ compose: menuItemStyle }} />
        <MenuItem key="settings_handrailhandle" to="/general/settings/handrailhandle" title={t`Handgrepen`} css={{ compose: menuItemStyle }} />
        <MenuItem key="settings_handrailtype" to="/general/settings/handrailtype" title={t`Leuningtypes`} css={{ compose: menuItemStyle }} />
        <MenuItem key="settings_wood" to="/general/settings/wood" title={t`Materialen`} css={{ compose: menuItemStyle }} />
        <MenuItem key="settings_spindlethreadtype" to="/general/settings/spindlethreadtype" title={t`Modelspijlen`} css={{ compose: menuItemStyle }} />
        <MenuItem key="settings_wallhook" to="/general/settings/wallhook" title={t`Muurhaak types`} css={{ compose: menuItemStyle }} />
        <MenuItem key="settings_wallhandrail" to="/general/settings/wallhandrail" title={t`Muurleuning types`} css={{ compose: menuItemStyle }} />
        <MenuItem key="settings_handrailpoletype" to="/general/settings/handrailpoletype" title={t`Palen`} css={{ compose: menuItemStyle }} />
        <MenuItem key="settings_splacementoption" to="/general/settings/staircaseplacementoption" title={t`Plaatsingsopties`} css={{ compose: menuItemStyle }} />
        <MenuItem key="settings_thicknessrules" to="/general/settings/thicknessrules" title={t`Regels`} css={{ compose: menuItemStyle }} />
        <MenuItem key="settings_supplements" to="/general/settings/supplements" title={t`Supplementen`} css={{ compose: menuItemStyle }} />
        <MenuItem key="settings_staircasetype" to="/general/settings/staircasetype" title={t`Trap types`} css={{ compose: menuItemStyle }} />
        <MenuItem key="settings_sshape" to="/general/settings/staircaseshape" title={t`Trapvormen`} css={{ compose: menuItemStyle }} />
        
    </HMenu>;

    private profileMenuItems = () => <HMenu key="profile" css={{ compose: horizontalMenuStyleSettings }}>
        <MenuItem key="profile_back" to="/" title="<" css={{ compose: menuItemStyle }} />
        <LoadingIcon />
        
        <MenuItem key="profile_distributor" to="/general/profile/distributor" title={t`Verdeler`} css={{ compose: menuItemStyle }} />
        {hasMatchingPermission(SETTINGS, TEAM_USER_MANAGEMENT) ? <MenuItem key="profile_team" to="/general/profile/team" title={t`Team`} css={{ compose: menuItemStyle }} /> : ""}
        <MenuItem key="profile_user" to="/general/profile/user" title={t`Gebruiker`} css={{ compose: menuItemStyle }} />  

        <RightFloatingMenuItem key="sign_out" title={t`Afmelden`} css={{ compose: rightFloatingMenuItemStyle }}  onClick={async () => {
            doJsonApiCall("core/users/logout", {}, (result) => {
                window.location.reload();
            }, false);
        }}/>   
    </HMenu>;

    private globalStoreListener;

    public componentWillMount() {
        this.globalStoreListener = globalStore.on("change", () => {
            if (globalStore.getLocale() !== this.state.locale) {
                this.setState({ changingLanguage: true });
                setTimeout(() => { this.setState({ changingLanguage: false, locale: globalStore.getLocale() }) }, 500);
            }
        })
    }

    public componentWillUnmount() {
        globalStore.removeListener("change", this.globalStoreListener);
    }

    public render() {
        let content;
        if (this.state.changingLanguage) {
            content = <span/>;
        } else {
            content = (
                <Switch>
                    <Route exact path="/">
                        <span>
                            <QuoteLoadingStatus>
                                {this.regularMenuItems()}
                                <Overview />
                            </QuoteLoadingStatus>
                        </span>
                    </Route>
                    <Route path="/general/profile/distributor">
                        <span>
                            {this.profileMenuItems()}
                            <Profile />
                        </span>
                    </Route>
                    <Route path="/general/profile/user">
                        <span>
                            {this.profileMenuItems()}
                            <ProfileUser />
                        </span>
                    </Route>
                    <Route path="/general/profile/team">
                        <span>
                            {this.profileMenuItems()}
                            <Team />
                        </span>
                    </Route>
                    <Route path="/general/settings/wood">
                        <SettingsRelatedPage>
                            {this.settingsMenuItems()}
                            <Wood />
                        </SettingsRelatedPage>
                    </Route>
                    <Route path="/general/settings/handrailpoletype">
                        <SettingsRelatedPage>
                            {this.settingsMenuItems()}
                            <HandrailPoleTypeSettings />
                        </SettingsRelatedPage>
                    </Route>
                    <Route path="/general/settings/handrailpolefinish">
                        <SettingsRelatedPage>
                            {this.settingsMenuItems()}
                            <HandrailPoleFinishSettings />
                        </SettingsRelatedPage>
                    </Route>
                    <Route path="/general/settings/wallhandrail">
                        <SettingsRelatedPage>
                            {this.settingsMenuItems()}
                            <WallHandrailSettings />
                        </SettingsRelatedPage>
                    </Route>
                    <Route path="/general/settings/wallhook">
                        <SettingsRelatedPage>
                            {this.settingsMenuItems()}
                            <WallHookSettings />
                        </SettingsRelatedPage>
                    </Route>
                    <Route path="/general/settings/baseconstantsets">
                        <SettingsRelatedPage>
                            {this.settingsMenuItems()}
                            <BaseConstantSetSettings />
                        </SettingsRelatedPage>
                    </Route>
                    <Route path="/general/settings/handrailhandle">
                        <SettingsRelatedPage>
                            {this.settingsMenuItems()}
                            <HandrailHandleSettings />
                        </SettingsRelatedPage>
                    </Route>
                    <Route path="/general/settings/spindlethreadtype">
                        <SettingsRelatedPage>
                            {this.settingsMenuItems()}
                            <SpindleThreadTypeSettings />
                        </SettingsRelatedPage>
                    </Route>
                    <Route path="/general/settings/staircaseplacementoption">
                        <SettingsRelatedPage>
                            {this.settingsMenuItems()}
                            <StaircasePlacementOptionSettings />
                        </SettingsRelatedPage>
                    </Route>
                    <Route path="/general/settings/staircasetype">
                        <SettingsRelatedPage>
                            {this.settingsMenuItems()}
                            <StaircaseType />
                        </SettingsRelatedPage>
                    </Route>
                    <Route path="/general/settings/staircaseshape">
                        <SettingsRelatedPage>
                            {this.settingsMenuItems()}
                            <StaircaseShapeSettings />
                        </SettingsRelatedPage>
                    </Route>
                    <Route path="/general/settings/handrailtype">
                        <SettingsRelatedPage>
                            {this.settingsMenuItems()}
                            <HandrailTypeSettings />
                        </SettingsRelatedPage>
                    </Route>
                    <Route path="/general/settings/distributors">
                        <SettingsRelatedPage>
                            {this.settingsMenuItems()}
                            <Distributors />
                        </SettingsRelatedPage>
                    </Route>
                    <Route path="/general/settings/users">
                        <SettingsRelatedPage>
                            {this.settingsMenuItems()}
                            <Users />
                        </SettingsRelatedPage>
                    </Route>
                    <Route path="/general/settings/permissions">
                        <SettingsRelatedPage>
                            {this.settingsMenuItems()}
                            <Permissions />
                        </SettingsRelatedPage>
                    </Route>
                    <Route path="/general/settings/thickness">
                        <SettingsRelatedPage>
                            {this.settingsMenuItems()}
                            <ThicknessSettings />
                        </SettingsRelatedPage>
                    </Route>
                    <Route path="/general/settings/thicknessrules">
                        <SettingsRelatedPage>
                            {this.settingsMenuItems()}
                            <ThicknessRuleSettings />
                        </SettingsRelatedPage>
                    </Route>
                    <Route path="/general/settings/supplements">
                        <SettingsRelatedPage>
                            {this.settingsMenuItems()}
                            <SupplementSettings />
                        </SettingsRelatedPage>
                    </Route>
                    <Route path="/general/settings/treatmenttype">
                        <SettingsRelatedPage>
                            {this.settingsMenuItems()}
                            <TreatmentTypeSettings />
                        </SettingsRelatedPage>
                    </Route>
                    <Route path="/quote/1">
                        <QuoteRelatedPage {...{} as any}>
                            {this.newQuoteMenuItems()}
                            <Step1 />
                        </QuoteRelatedPage>
                    </Route>
                    <Route path="/quote/2">
                        <QuoteRelatedPage {...{} as any}>
                            {this.newQuoteMenuItems()}
                            <Step2 {...{} as any} />
                        </QuoteRelatedPage>
                    </Route>
                    <Route path="/quote/3">
                        <QuoteRelatedPage {...{} as any}>
                            {this.newQuoteMenuItems()}
                            <Step3 {...{} as any} />
                        </QuoteRelatedPage>
                    </Route>
                    <Route path="/quote/4">
                        <QuoteRelatedPage {...{} as any}>
                            {this.newQuoteMenuItems()}
                            <Step4 {...{} as any} />
                        </QuoteRelatedPage>
                    </Route>
                    <Route path="/quote/5">
                        <QuoteRelatedPage {...{} as any}>
                            {this.newQuoteMenuItems()}
                            <Step5 {...{} as any} />
                        </QuoteRelatedPage>
                    </Route>
                    <Route path="/quote/article/wood/1">
                        <QuoteRelatedPage {...{} as any}>
                            <ArticleLoader isWood={true} {...{} as any}>
                                <NewArticleMenuItems descriptor="wood" {...{} as any} />
                                <ArticleStaircase {...{} as any} />
                            </ArticleLoader>
                        </QuoteRelatedPage>
                    </Route>
                    <Route path="/quote/article/wood/2">
                        <QuoteRelatedPage {...{} as any}>
                            <ArticleLoader isWood={true} {...{} as any}>
                                <NewArticleMenuItems descriptor="wood" {...{} as any} />
                                <ArticleShape {...{} as any} />
                            </ArticleLoader>
                        </QuoteRelatedPage>
                    </Route>
                    <Route path="/quote/article/wood/3">
                        <QuoteRelatedPage {...{} as any}>
                            <ArticleLoader isWood={true} {...{} as any}>
                                <NewArticleMenuItems descriptor="wood" {...{} as any} />
                                <ArticleHandrail {...{} as any} />
                            </ArticleLoader>
                        </QuoteRelatedPage>
                    </Route>
                    <Route path="/quote/article/wood/4">
                        <QuoteRelatedPage {...{} as any}>
                            <ArticleLoader isWood={true} {...{} as any}>
                                <NewArticleMenuItems descriptor="wood" {...{} as any} />
                                <ArticleLandingStage  {...{} as any} />
                            </ArticleLoader>
                        </QuoteRelatedPage>
                    </Route>
                    <Route path="/quote/article/wood/5">
                        <QuoteRelatedPage {...{} as any}>
                            <ArticleLoader isWood={true} {...{} as any}>
                                <NewArticleMenuItems descriptor="wood" {...{} as any} />
                                <ArticleTreatment {...{} as any} />
                            </ArticleLoader>
                        </QuoteRelatedPage>
                    </Route>
                    <Route path="/quote/article/wood/6">
                        <QuoteRelatedPage {...{} as any}>
                            <ArticleLoader isWood={true} {...{} as any}>
                                <NewArticleMenuItems descriptor="wood" {...{} as any} />
                                <ArticleTransportation {...{} as any} />
                            </ArticleLoader>
                        </QuoteRelatedPage>
                    </Route>
                    <Route path="/quote/article/wood/7">
                        <QuoteRelatedPage {...{} as any}>
                            <ArticleLoader isWood={true} {...{} as any}>
                                <NewArticleMenuItems descriptor="wood" {...{} as any} />
                                <ArticleSummary {...{} as any} />
                            </ArticleLoader>
                        </QuoteRelatedPage>
                    </Route>
                    <Route path="/quote/article/concrete/1">
                        <QuoteRelatedPage {...{} as any}>
                            <ArticleLoader isWood={false} {...{} as any}>
                                <NewArticleMenuItems descriptor="concrete" {...{} as any} />
                                <ArticleStaircase {...{} as any} />
                            </ArticleLoader>
                        </QuoteRelatedPage>
                    </Route>
                    <Route path="/quote/article/concrete/2">
                        <QuoteRelatedPage {...{} as any}>
                            <ArticleLoader isWood={false} {...{} as any}>
                                <NewArticleMenuItems descriptor="concrete" {...{} as any} />
                                <ArticleShape {...{} as any} />
                            </ArticleLoader>
                        </QuoteRelatedPage>
                    </Route>
                    <Route path="/quote/article/concrete/3">
                        <QuoteRelatedPage {...{} as any}>
                            <ArticleLoader isWood={false} {...{} as any}>
                                <NewArticleMenuItems descriptor="concrete" {...{} as any} />
                                <ArticleHandrail {...{} as any} />
                            </ArticleLoader>
                        </QuoteRelatedPage>
                    </Route>
                    <Route path="/quote/article/concrete/4">
                        <QuoteRelatedPage {...{} as any}>
                            <ArticleLoader isWood={false} {...{} as any}>
                                <NewArticleMenuItems descriptor="concrete" {...{} as any} />
                                <ArticleLandingStage  {...{} as any} />
                            </ArticleLoader>
                        </QuoteRelatedPage>
                    </Route>
                    <Route path="/quote/article/concrete/5">
                        <QuoteRelatedPage {...{} as any}>
                            <ArticleLoader isWood={false} {...{} as any}>
                                <NewArticleMenuItems descriptor="concrete" {...{} as any} />
                                <ArticleTreatment {...{} as any} />
                            </ArticleLoader>
                        </QuoteRelatedPage>
                    </Route>
                    <Route path="/quote/article/concrete/6">
                        <QuoteRelatedPage {...{} as any}>
                            <ArticleLoader isWood={false} {...{} as any}>
                                <NewArticleMenuItems descriptor="concrete" {...{} as any} />
                                <ArticleTransportation {...{} as any} />
                            </ArticleLoader>
                        </QuoteRelatedPage>
                    </Route>
                    <Route path="/quote/article/concrete/7">
                        <QuoteRelatedPage {...{} as any}>
                            <ArticleLoader isWood={false} {...{} as any}>
                                <NewArticleMenuItems descriptor="concrete" {...{} as any} />
                                <ArticleSummary {...{} as any} />
                            </ArticleLoader>
                        </QuoteRelatedPage>
                    </Route>
                    {/*<Route path="/website/openingsuren">*/}
                    {/*<ManagementGroup managementGroupID="5a19bdc0187a4d5503f5a0ac"/>*/}
                    {/*</Route>*/}
                    {/*<Route path="/website/prijslijst">*/}
                    {/*<ManagementGroup managementGroupID="5a19bdc0187a4d5503f5a0ad"/>*/}
                    {/*</Route>*/}
                    {/*<Route path="/website/over-ons">*/}
                    {/*<ManagementGroup managementGroupID="5a19bdc0187a4d5503f5a0ae"/>*/}
                    {/*</Route>*/}
                    <Route>
                        <span>
                            {this.regularMenuItems()}
                            <Choose />
                        </span>
                    </Route>
                </Switch>
            );
        }
        return (
            <Provider store={store}> 
                <HashRouter>
                    <span>
                        <PopupRenderer/>
                        <MainLayout languages={availableLanguages} css={{ compose: mainLayoutStyle }} languageChoiceCss={languageChoiceStyle} location={location} route={{ headerprops: { title: "", subtitle: "", image: "/cdn/portal/assets/logo.svg" } }} loginChildren={logo} onSignIn={() => { load(); }} i18n={i18n}>
                            {content}
                        </MainLayout>
                    </span>
                </HashRouter>
            </Provider>
        );
    }
}

ReactDOM.render(
    <All />,
    app);
