exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3Q7Cp_6v5SgtYnroLb6Rww{\n    width: 100vw;\n    height: 100vh;\n    position: fixed;\n    background-color: rgba(0, 0, 0, 0.5);\n    z-index: 9999;\n    backdrop-filter: blur(5px);\n}\n\n._3kTpmZ6xaGBG2o5I4IL0GB{\n    margin-left: auto;\n    margin-right: auto;\n    max-width: 500px;\n    background: #FFFFFF;\n    box-shadow: 0 0 19px 7px rgba(0,0,0,0.14);\n    border-radius: 8px;\n    margin-bottom: 20px;\n    margin-top: 200px;\n    padding: 20px;\n}\n\n._16C4y6pBWHOHX0l1m-2-pu{\n    width: 100%;\n    height: 25px;\n}\n\n._3c8Oq3AM7BljdNaJNyjjQg{\n    height: 35px;\n}", ""]);

// exports
exports.locals = {
	"container": "_3Q7Cp_6v5SgtYnroLb6Rww",
	"box": "_3kTpmZ6xaGBG2o5I4IL0GB",
	"separator": "_16C4y6pBWHOHX0l1m-2-pu",
	"buttons": "_3c8Oq3AM7BljdNaJNyjjQg"
};