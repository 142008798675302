import Language from "orbiter-core/src/datastructures/languages/Language";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import SingleLanguageString from "orbiter-core/src/datastructures/languages/SingleLanguageString";
import ApiController, { ConstructionMethods } from "../ApiController";
import {
    IBaseConstantSet,
    default as BaseConstantSet
} from "../../../../../shared/datastructures/staircasedata/BaseConstantSet";
import { doJsonGetApiCall } from "webc-reactcore/src/js/apicommunication";
import ObjectID from "bson-objectid";

// TODO: too much code duplication
export default class BaseConstantSetAPI extends ApiController<BaseConstantSet> implements IBaseConstantSet {

    public static getBaseEndpoint(): string{
        return "hotec/baseconstantset";
    }

    public static getApiKey(): string{
        return "baseConstantSet";
    }

    public async parseFromDictionary(dictionary): Promise<void> {

        const title = new MultiLanguageString(
            dictionary.title.map((x) => {
                // TODO: LanguageAPI?
                const l = new Language(
                    x.languageId,
                    null,
                    null,
                );
                return new SingleLanguageString(
                    l,
                    x.value,
                );
            }),
        );

        const desc = new MultiLanguageString(
            dictionary.description.map((x) => {
                // TODO: LanguageAPI?
                const l = new Language(
                    x.languageId,
                    null,
                    null,
                );
                return new SingleLanguageString(
                    l,
                    x.value,
                );
            }),
        );

        const baseConstantSet = new BaseConstantSet(
            title,
            desc,
            dictionary.pricePerRiser,
            dictionary.landingStageBase,
            dictionary.landingStageSpindleBase,
            dictionary.handrailSpindleBase,
            dictionary.landingStageEnclosureBase,
            dictionary.landingStageEnclosureSurcharge,
            dictionary.landingStageEnclosureSurchargeThreshold,
            dictionary.installationMinTreads,
            dictionary.deliveryFreeKmCount,
            dictionary.deliveryKmCost,
            dictionary.widthMultiplier,
            dictionary.widthTreshold,
            dictionary.treadBase,
            dictionary.handrailBase,
            dictionary.placementPriceConcreteEnclosure,
            dictionary.placementPriceLandingStageHandrail,
            dictionary.placementPriceHandrail,
            dictionary.pricePerBend,
            dictionary.measurementSurcharge,
            dictionary.assemblySurcharge,
            dictionary.deliveryBasePrice,
            dictionary.globalMarginForDealers,
            dictionary.defaultLargeThicknessSupplementId ? new ObjectID(dictionary.defaultLargeThicknessSupplementId) : null,
        );
        this.setData(baseConstantSet);
    }

    public getDescription(): MultiLanguageString {
        return this.getData().getDescription();
    }

    public async setDescription(newDescription: MultiLanguageString): Promise<void> {
        this.getData().setDescription(newDescription);
        await this.updateProperty("description", newDescription);
    }

    public async setTitle(newTitle: MultiLanguageString): Promise<void> {
        this.getData().setTitle(newTitle);
        await this.updateProperty("title", newTitle);
    }

    public getTitle(): MultiLanguageString {
        return this.getData().getTitle();
    }

    public static async retrieveDefaultBaseConstantSet(): Promise<BaseConstantSetAPI>{
        const res = await new Promise<any>((resolve, reject) => {
            doJsonGetApiCall(BaseConstantSetAPI.getRetrieveEndpoint() + "/default", (result) => {
                resolve(result[BaseConstantSetAPI.getApiKey()]);
            });
        });
        const r = new BaseConstantSetAPI(res._id, ConstructionMethods.PromiseToRetrieve);
        await r.parseFromDictionaryRunner(res);
        return r;
    }

    public getPricePerRiser(): number{
        return this.getData().pricePerRiser;
    }

    public getLandingStageBase(): number{
        return this.getData().landingStageBase;
    }

    public getLandingStageSpindleBase(): number{
        return this.getData().landingStageSpindleBase;
    }

    public getHandrailSpindleBase(): number{
        return this.getData().handrailSpindleBase;
    }

    public getLandingStageEnclosureBase(): number{
        return this.getData().landingStageEnclosureBase;
    }

    public getLandingStageEnclosureSurcharge(): number{
        return this.getData().landingStageEnclosureSurcharge;
    }

    public getLandingStageEnclosureSurchargeThreshold(): number{
        return this.getData().landingStageEnclosureSurchargeThreshold;
    }

    public getInstallationMinTreads(): number{
        return this.getData().installationMinTreads;
    }

    public getDeliveryFreeKmCount(): number{
        return this.getData().deliveryFreeKmCount;
    }

    public getDeliveryKmCost(): number{
        return this.getData().deliveryKmCost;
    }

    public getWidthMultiplier(): number{
        return this.getData().widthMultiplier;
    }

    public getWidthTreshold(): number{
        return this.getData().widthTreshold;
    }

    public getTreadBase(): number{
        return this.getData().treadBase;
    }

    public getHandrailBase(): number{
        return this.getData().handrailBase;
    }

    public getPlacementPriceConcreteEnclosure(): number{
        return this.getData().placementPriceConcreteEnclosure;
    }

    public getPlacementPriceLandingStageHandrail(): number{
        return this.getData().placementPriceLandingStageHandrail;
    }

    public getPlacementPriceHandrail(): number{
        return this.getData().placementPriceHandrail;
    }

    public getPricePerBend(): number{
        return this.getData().pricePerBend;
    }

    public getMeasurementSurcharge(): number{
        return this.getData().getMeasurementSurcharge();
    }

    public getAssemblySurcharge(): number{
        return this.getData().getAssemblySurcharge();
    }

    public getDeliveryBasePrice(): number{
        return this.getData().getDeliveryBasePrice();
    }

    public getGlobalMarginForDealers(): number{
        return this.getData().getGlobalMarginForDealers();
    }

    public getDefaultLargeThicknessSupplementId(): ObjectID | null{
        return this.getData().getDefaultLargeThicknessSupplementId();
    }

}
