import WoodTypeAPI from "../../../../apicontroller/staircasedata/WoodTypeAPI";
import { ConstructionMethods } from "../../../../apicontroller/ApiController";
import TreatmentTypeAPI from "../../../../apicontroller/staircasedata/TreatmentTypeAPI";
import StaircaseTypeAPI from "../../../../apicontroller/staircasedata/StaircaseTypeAPI";
import StaircaseShapeAPI from "../../../../apicontroller/staircasedata/StaircaseShapeAPI";
import { TreadThicknessAPI, StringerThicknessAPI, RiserThicknessAPI, ThicknessAPI } from "../../../../apicontroller/staircasedata/ThicknessAPI";
import HandrailTypeAPI from "../../../../apicontroller/staircasedata/HandrailTypeAPI";
import SpindleThreadTypeAPI from "../../../../apicontroller/staircasedata/SpindleThreadTypeAPI";
import HandrailPoleTypeAPI from "../../../../apicontroller/staircasedata/HandrailPoleTypeAPI";
import StaircasePlacementOptionAPI from "../../../../apicontroller/staircasedata/StaircasePlacementOptionAPI";
import { SupplementHandrailAPI, SupplementLandingStageAPI, SupplementAPI, SupplementTreadAPI, PlacementSupplementAPI } from "../../../../apicontroller/staircasedata/SupplementAPI";
import ObjectID from "bson-objectid";
import store from "../../../../store";
import { setBasicInformation as setQuoteCreationBasicInformation } from "../../../../actions/quoteCreationActions";
import { doJsonApiCall, doJsonGetApiCall } from "webc-reactcore/src/js/apicommunication";
import { loadQuoteToStore } from "../../../../portalNetworkCommunicationTypes";
import HandrailHandleAPI from "../../../../apicontroller/staircasedata/HandrailHandleAPI";
import { setBasicInformation as setGlobalBasicInformation } from "../../../../actions/globalInformationActions";
import HandrailPoleFinishAPI from "../../../../apicontroller/staircasedata/HandrailPoleFinishAPI";
import WallHandrailAPI from "../../../../apicontroller/staircasedata/WallHandrailAPI";
import WallHookAPI from "../../../../apicontroller/staircasedata/WallHookAPI";
import ThicknessRuleAPI from "../../../../apicontroller/staircasedata/ThicknessRuleAPI";
import BaseConstantSetAPI from "../../../../apicontroller/staircasedata/BaseConstantSetAPI";
import DistributorAPI from "../../../../apicontroller/DistributorAPI";
import HotecUserAPI from "../../../../apicontroller/HotecUserAPI";

export async function loadArticleInformation(quoteId: ObjectID): Promise<RetrievedArticleData> {
    startLoading();
    return new Promise((resolve, reject) => {
        doJsonApiCall("hotec/article/staircase/data/retrieve", {quoteId}, async (result) => {
            stopLoading();
            resolve(await interpretDataRetrieval(result));
        }, false, () => {
            reject();
            stopLoading();
        });
    })
}

export async function updateQuoteDataDate(quoteId: ObjectID): Promise<void>{
    startLoading();
    try{
        await new Promise((resolve, reject) => {
            doJsonGetApiCall("hotec/quote/update/" + quoteId.toHexString() + "/datadate/now", async (result) => {
                resolve(result.quote);
            }, false, async () => {
                reject();
            });
        })
        await loadQuote(quoteId);
    }catch(e){
    }
    stopLoading();
}

export async function loadQuote(quoteId: ObjectID): Promise<void> {
    enum STATUS {
        NOT_LOADING_YET,
        LOADING_DATA,
    }
    let state: STATUS = STATUS.NOT_LOADING_YET;

    const loadQuoteInformation = async () => {
        return new Promise((resolve, reject) => {
            doJsonGetApiCall("hotec/quote/retrieve/" + quoteId.toHexString(), async (result) => {
                resolve(result);
            }, false, async () => {
                reject();
            });
        })
    }

    let loadedArticleInformation = null;
    let loadedQuoteInformation = null;
    let loadedLogsInformation = null;

    const loadData = async () => {
        const loadingArticleInformation = loadArticleInformation(quoteId);
        const loadingQuoteInformation = loadQuoteInformation();
        const loaded: any = await Promise.all([loadingArticleInformation, loadingQuoteInformation]);
        loadedArticleInformation = loaded[0];
        loadedQuoteInformation = loaded[1].quote;
        loadedLogsInformation = loaded[1].logs;
    }
    
    return new Promise((resolve, reject) => {
        store.subscribe(async () => {
            if (state === STATUS.NOT_LOADING_YET && store.getState().globalInformation.openingQuote === true) {
                state = STATUS.LOADING_DATA;
                console.log("START LOADING")
                try{
                    await loadData();
                }catch(e){
                    console.log("ERROR")
                    store.dispatch(setGlobalBasicInformation({ openingQuote: false }));
                }
                console.log("DONE LOADING")
                await loadQuoteToStore(loadedQuoteInformation, quoteId, loadedArticleInformation, loadedLogsInformation);
                store.dispatch(setGlobalBasicInformation({ openingQuote: false }));
                resolve();
            }
        })
        store.dispatch(setGlobalBasicInformation({ openingQuote: true }));
    })
}

export function settingsLoaded(){
    store.dispatch(setGlobalBasicInformation({ settingsLoaded: true }));
}

export function startLoading(){
    store.dispatch(setGlobalBasicInformation({ loading: store.getState().globalInformation.loading + 1 }));
}

export function stopLoading(){
    store.dispatch(setGlobalBasicInformation({ loading: store.getState().globalInformation.loading - 1 }));
}

export type RetrievedArticleData = {
    baseConstantSet: BaseConstantSetAPI,
    availableHandrailTypes: HandrailTypeAPI[],
    availableHandrailPoleTypes: HandrailPoleTypeAPI[],
    availableHandrailPoleFinishes: HandrailPoleFinishAPI[],
    availableRiserThickness: RiserThicknessAPI[],
    availableStaircaseShapes: StaircaseShapeAPI[],
    availableStaircaseTypes: StaircaseTypeAPI[],
    availableStringerThickness: StringerThicknessAPI[],
    availableSupplementsHandrail: SupplementHandrailAPI[],
    availableSupplementsLandingStageHandrail: SupplementLandingStageAPI[],
    availableSupplementsPlacement: PlacementSupplementAPI[],
    availableSupplementsTread: SupplementTreadAPI[],
    availableTreadThickness: TreadThicknessAPI[],
    availableTreatmentTypes: TreatmentTypeAPI[],
    availableWoodTypes: WoodTypeAPI[],
    availableSpindleThreadTypes: SpindleThreadTypeAPI[],
    availableHandrailHandles: HandrailHandleAPI[],
    availableStaircasePlacementOptions: StaircasePlacementOptionAPI[],
    availableWallHandrails: WallHandrailAPI[],
    availableWallHooks: WallHookAPI[],
    availableThicknessRules: ThicknessRuleAPI[],
    availableDistributors: DistributorAPI[],
    availableUsers: HotecUserAPI[],
};

export async function interpretDataRetrieval(retrievedData): Promise<RetrievedArticleData> {
    const baseConstantSet: BaseConstantSetAPI = new BaseConstantSetAPI(retrievedData.baseConstantSet._id, ConstructionMethods.PromiseToRetrieve);
    await baseConstantSet.parseFromDictionary(retrievedData.baseConstantSet);
    const availableWoodTypes: WoodTypeAPI[] = await Promise.all(retrievedData.woodTypes.map(async (wt) => {
        const woodTypeAPI = new WoodTypeAPI(wt._id, ConstructionMethods.PromiseToRetrieve);
        await woodTypeAPI.parseFromDictionary(wt);
        return woodTypeAPI;
    }));
    const availableTreatmentTypes: TreatmentTypeAPI[] = await Promise.all(retrievedData.treatmentTypes.map(async (tt) => {
        const treatmentTypeAPI = new TreatmentTypeAPI(tt._id, ConstructionMethods.PromiseToRetrieve);
        await treatmentTypeAPI.parseFromDictionary(tt);
        return treatmentTypeAPI;
    }));
    const availableStaircaseTypes: StaircaseTypeAPI[] = await Promise.all(retrievedData.staircaseTypes.map(async (st) => {
        const staircaseTypeAPI = new StaircaseTypeAPI(st._id, ConstructionMethods.PromiseToRetrieve);
        await staircaseTypeAPI.parseFromDictionary(st);
        return staircaseTypeAPI;
    }));
    const availableStaircaseShapes: StaircaseShapeAPI[] = await Promise.all(retrievedData.staircaseShapes.map(async (staircaseShape) => {
        const staircaseShapeAPI = new StaircaseShapeAPI(staircaseShape._id, ConstructionMethods.PromiseToRetrieve);
        await staircaseShapeAPI.parseFromDictionary(staircaseShape);
        return staircaseShapeAPI;
    }));
    const availableTreadThickness: TreadThicknessAPI[] = await Promise.all(retrievedData.treadThickness.map(async (t) => {
        const thickness = new TreadThicknessAPI(t._id, ConstructionMethods.PromiseToRetrieve);
        await thickness.parseFromDictionary(t);
        return thickness;
    }));
    const availableStringerThickness: StringerThicknessAPI[] = await Promise.all(retrievedData.stringerThickness.map(async (t) => {
        const thickness = new StringerThicknessAPI(t._id, ConstructionMethods.PromiseToRetrieve);
        await thickness.parseFromDictionary(t);
        return thickness;
    }));
    const availableRiserThickness: RiserThicknessAPI[] = await Promise.all(retrievedData.riserThickness.map(async (t) => {
        const thickness = new RiserThicknessAPI(t._id, ConstructionMethods.PromiseToRetrieve);
        await thickness.parseFromDictionary(t);
        return thickness;
    }));
    const availableHandrailTypes: HandrailTypeAPI[] = await Promise.all<HandrailTypeAPI>(retrievedData.handrailTypes.map(async (t) => {
        const type = new HandrailTypeAPI(t._id, ConstructionMethods.PromiseToRetrieve);
        await type.parseFromDictionary(t);
        return type;
    }));
    const availableHandrailHandles: HandrailHandleAPI[] = await Promise.all<HandrailHandleAPI>(retrievedData.handrailHandles.map(async (t) => {
        const type = new HandrailHandleAPI(t._id, ConstructionMethods.PromiseToRetrieve);
        await type.parseFromDictionary(t);
        return type;
    }));
    const availableHandrailPoleTypes: HandrailPoleTypeAPI[] = await Promise.all<HandrailPoleTypeAPI>(retrievedData.handrailPoleTypes.map(async (t) => {
        const type = new HandrailPoleTypeAPI(t._id, ConstructionMethods.PromiseToRetrieve);
        await type.parseFromDictionary(t);
        return type;
    }));
    const availableHandrailPoleFinishes: HandrailPoleFinishAPI[] = await Promise.all<HandrailPoleFinishAPI>(retrievedData.handrailPoleFinishes.map(async (t) => {
        const finish = new HandrailPoleFinishAPI(t._id, ConstructionMethods.PromiseToRetrieve);
        await finish.parseFromDictionary(t);
        return finish;
    }));
    const availableSpindleThreadTypes: SpindleThreadTypeAPI[] = await Promise.all<SpindleThreadTypeAPI>(retrievedData.spindleThreadTypes.map(async (t) => {
        const type = new SpindleThreadTypeAPI(t._id, ConstructionMethods.PromiseToRetrieve);
        await type.parseFromDictionary(t);
        return type;
    }));
    const availableStaircasePlacementOptions: StaircasePlacementOptionAPI[] = await Promise.all<StaircasePlacementOptionAPI>(retrievedData.staircasePlacementOptions.map(async (t) => {
        const option = new StaircasePlacementOptionAPI(t._id, ConstructionMethods.PromiseToRetrieve);
        await option.parseFromDictionary(t);
        return option;
    }));

    const availableSupplementsTread: SupplementTreadAPI[] = await Promise.all<SupplementTreadAPI>(retrievedData.treadSupplements.map(async (t) => {
        const sup = new SupplementTreadAPI(t._id, ConstructionMethods.PromiseToRetrieve);
        await sup.parseFromDictionary(t);
        return sup;
    }))

    const availableSupplementsHandrail: SupplementHandrailAPI[] = await Promise.all<SupplementHandrailAPI>(retrievedData.handrailSupplements.map(async (s) => {
        const sup = new SupplementHandrailAPI(s._id, ConstructionMethods.PromiseToRetrieve);
        await sup.parseFromDictionary(s);
        return sup;
    }))

    const availableSupplementsPlacement: PlacementSupplementAPI[] = await Promise.all<PlacementSupplementAPI>(retrievedData.placementSupplements.map(async (s) => {
        const sup = new PlacementSupplementAPI(s._id, ConstructionMethods.PromiseToRetrieve);
        await sup.parseFromDictionary(s);
        return sup;
    }))

    const availableSupplementsLandingStageHandrail: SupplementLandingStageAPI[] = await Promise.all<SupplementLandingStageAPI>(retrievedData.supplementsLandingStageHandrail.map(async (s) => {
        const sup = new SupplementLandingStageAPI(s._id, ConstructionMethods.PromiseToRetrieve);
        await sup.parseFromDictionary(s);
        return sup;
    }))

    const availableWallHandrails: WallHandrailAPI[] = await Promise.all<WallHandrailAPI>(retrievedData.wallHandrails.map(async (t) => {
        const type = new WallHandrailAPI(t._id, ConstructionMethods.PromiseToRetrieve);
        await type.parseFromDictionary(t);
        return type;
    }));

    const availableWallHooks: WallHookAPI[] = await Promise.all<WallHookAPI>(retrievedData.wallHooks.map(async (t) => {
        const type = new WallHookAPI(t._id, ConstructionMethods.PromiseToRetrieve);
        await type.parseFromDictionary(t);
        return type;
    }));

    const availableThicknessRules: ThicknessRuleAPI[] = await Promise.all<ThicknessRuleAPI>(retrievedData.thicknessRules.map(async (t) => {
        const type = new ThicknessRuleAPI(t._id, ConstructionMethods.PromiseToRetrieve);
        await type.parseFromDictionary(t);
        return type;
    }));

    const availableDistributors: DistributorAPI[] = await Promise.all<DistributorAPI>(retrievedData.distributors.map(async (d) => {
        const distributor = new DistributorAPI(d._id, ConstructionMethods.PromiseToRetrieve);
        await distributor.parseFromDictionary(d);
        return distributor;
    }));

    const availableUsers: HotecUserAPI[] = await Promise.all<HotecUserAPI>(retrievedData.users.map(async (d) => {
        const user = new HotecUserAPI(d._id, ConstructionMethods.PromiseToRetrieve);
        await user.parseFromDictionary(d);
        return user;
    }));


    return {
        baseConstantSet,
        availableHandrailTypes,
        availableHandrailPoleTypes,
        availableHandrailPoleFinishes,
        availableRiserThickness,
        availableStaircaseShapes,
        availableStaircaseTypes,
        availableStringerThickness,
        availableSupplementsHandrail,
        availableSupplementsLandingStageHandrail,
        availableSupplementsPlacement,
        availableSupplementsTread,
        availableTreadThickness,
        availableTreatmentTypes,
        availableWoodTypes,
        availableSpindleThreadTypes,
        availableHandrailHandles,
        availableStaircasePlacementOptions,
        availableWallHandrails,
        availableWallHooks,
        availableThicknessRules,
        availableDistributors,
        availableUsers,
    }
}