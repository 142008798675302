exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._38U2ahknFzyUcYvhlHTYLK{\n    width: 100%;\n    margin-bottom: 50px;\n    border-collapse:collapse;\n    border:1px solid #d9d9d9;\n}\n._38U2ahknFzyUcYvhlHTYLK th{\n    text-align: center;\n}\n._38U2ahknFzyUcYvhlHTYLK td, ._38U2ahknFzyUcYvhlHTYLK th{\n    padding: 8px;\n    border:1px solid #d9d9d9;\n}\n._38U2ahknFzyUcYvhlHTYLK tbody tr{\n    cursor: pointer;\n}\n._38U2ahknFzyUcYvhlHTYLK tbody tr:hover{\n    background-color: #d9d9d9;\n}\n\n.OVx56VtojhdtlLu9UFxrw, ._38U2ahknFzyUcYvhlHTYLK tbody tr.OVx56VtojhdtlLu9UFxrw:hover{\n    background-color: #FFCB04;\n}\n\n._3IPvICiyH2SAbjlsMFLoop{\n    text-align: right;\n}\n\nth.ozxjJ0RwsEoRvveUOlO1F, td.ozxjJ0RwsEoRvveUOlO1F{\n    border-left-color: #3F3F3F;\n    border-left-width: 2px;\n}", ""]);

// exports
exports.locals = {
	"table": "_38U2ahknFzyUcYvhlHTYLK",
	"selected": "OVx56VtojhdtlLu9UFxrw",
	"lastColumn": "_3IPvICiyH2SAbjlsMFLoop",
	"minThickness": "ozxjJ0RwsEoRvveUOlO1F"
};