exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._274zO6jpdpAl2LL8EEMbxX{\n    background-color: #FFCB04 !important;\n    color: #3F3F3F;\n}\n._274zO6jpdpAl2LL8EEMbxX:hover, ._274zO6jpdpAl2LL8EEMbxX.gCWxGhvQL4YZOEM6qFQol{\n    background-color: #f2c004 !important;\n    color: #3F3F3F;\n}", ""]);

// exports
exports.locals = {
	"itemSelected": "_274zO6jpdpAl2LL8EEMbxX",
	"itemActive": "gCWxGhvQL4YZOEM6qFQol"
};