import IArticleInput from "../IArticleInput";
import { IDatabaseController } from "orbiter-core/src/databasecontroller/DatabaseController";
import { IStaircaseType } from "../staircasedata/StaircaseType";
import { IWoodType } from "../staircasedata/WoodType";
import { inu, isDefined, isValidStrictlyPositiveInteger, isValidStrictlyPositiveFloatingPoint, isValidPositiveFloatingPoint, isValidNumber } from "orbiter-core/src/basic";
import ShapeInput from "./ShapeInput";
import TreatmentInput from "./TreatmentInput";
import TransportationInput from "./TransportationInput";
import HandrailInput from "./HandrailInput";
import LandingStageInput from "./LandingStageInput";
import { INPUT_NOT_DEFINED, INVALID_VALUE, INVALID_TYPE, PROHIBITED_SELECTION } from "./inputExceptions";
import IArticleInfoStore from "./IArticleInfoStore";
import ObjectID from "bson-objectid";
import CommentInput from "./CommentInput";
import ExtraHandrailInput from "./ExtraHandrailInput";
import WallHandrailInput from "./WallHandrailInput";

const _ = undefined;

/**
 * Immutable article input class.
 */
export default class ArticleInput implements IArticleInput{

    constructor(
        readonly articleInfoStore: IArticleInfoStore,
        
        readonly reference: string = "",
        readonly unitCount: number = 1,
        readonly width: number = 0,
        readonly floorHeight: number = 0,
        readonly treadCount: number = 0,
        readonly type: IStaircaseType & IDatabaseController = null,
        readonly woodType: IWoodType & IDatabaseController = null,
        readonly riserWoodType: IWoodType & IDatabaseController = null,
        readonly isWood: boolean = true,
        readonly shape: ShapeInput = new ShapeInput(),
        readonly handrail: HandrailInput = new HandrailInput(),
        readonly extraHandrail: ExtraHandrailInput = null,
        readonly landingStage: LandingStageInput = new LandingStageInput(),
        readonly treatment: TreatmentInput = new TreatmentInput(),
        readonly transportation: TransportationInput = new TransportationInput(),
        readonly comments: CommentInput[] = [],
        readonly variantGroup: ObjectID | null = null,
        readonly variantIncluded: boolean | null = null,
        readonly wallHandrail: WallHandrailInput = null,
        readonly discountPercentageStaircase: number = 0,
        readonly discountPercentageTreatment: number = 0,
        readonly discountPercentageDistribution: number = 0,
        readonly quoteId?: ObjectID,
        readonly id?: ObjectID,
    ){}

    static from(article: IArticleInput): ArticleInput{
        return new ArticleInput(
            article.articleInfoStore,

            article.reference,
            article.unitCount,
            article.width,
            article.floorHeight,
            article.treadCount,
            article.type,
            article.woodType,
            article.riserWoodType,
            article.isWood,
            ShapeInput.from(article.shape),
            article.handrail === null ? null : HandrailInput.from(article.handrail),
            article.extraHandrail === null ? null : ExtraHandrailInput.from(article.extraHandrail),
            LandingStageInput.from(article.landingStage),
            TreatmentInput.from(article.treatment),
            TransportationInput.from(article.transportation),
            article.comments.map(x => CommentInput.from(x)),
            article.variantGroup,
            article.variantIncluded,
            article.wallHandrail === null ? null : WallHandrailInput.from(article.wallHandrail),
            article.discountPercentageStaircase,
            article.discountPercentageTreatment,
            article.discountPercentageDistribution,
            article.quoteId,
            article.id,
        ).clone();
    }

    static isIncluded(article: IArticleInput): boolean{
        return (article.variantIncluded === null || article.variantIncluded === true);
    }

    static extraHandrailEnabled(article: IArticleInput): boolean{
        return article.extraHandrail !== null;
    }

    static wallHandrailEnabled(article: IArticleInput): boolean{
        return article.wallHandrail !== null;
    }

    static handrailEnabled(article: IArticleInput): boolean{
        return article.handrail !== null && !article.handrail.type.isExcludedFromPriceCalculations();
    }

    static landingStageHandrailEnabled(article: IArticleInput): boolean{
        return article.landingStage.handrail != null && !article.landingStage.handrail.type.isExcludedFromPriceCalculations();
    }

    static landingStageConcreteEnclosureEnabled(article: IArticleInput): boolean{
        return article.landingStage.concreteEnclosureWoodType && article.landingStage.concreteEnclosureLength > 0;
    }

    /**
     * Check validity without checking recursive components (shape, handrail, landingStage, treatment, transportation)
     * @param article 
     */
    static validityCheckShallow(article: IArticleInput): void{
        this.unitCountValidityCheck(article.unitCount);
        this.widthValidityCheck(article.width);
        this.floorHeightValidityCheck(article.floorHeight);
        this.treadCountValidityCheck(article.treadCount);
        this.woodTypeValidityCheck(article.woodType);
        this.riserWoodTypeValidityCheck(article.riserWoodType);
        this.staircaseTypeValidityCheck(article.type);
        this.isWoodValidityCheck(article.isWood);
    }

    static async validityCheck(article: IArticleInput): Promise<void>{
        this.validityCheckShallow(article);
        await ShapeInput.validityCheck(article);
        await HandrailInput.validityCheck(article.handrail, article);
        await ExtraHandrailInput.validityCheck(article.extraHandrail, article);
        await WallHandrailInput.validityCheck(article.wallHandrail);
        await LandingStageInput.validityCheck(article.landingStage, article);
        await TreatmentInput.validityCheck(article.treatment);
        await TransportationInput.validityCheck(article.transportation, article);
        this.discountPercentageValidityCheck(article.discountPercentageStaircase, article.discountPercentageTreatment, article.discountPercentageDistribution);
    }

    /*
        TODO: validity checker for transportation
    */

    static unitCountValidityCheck(unitCount: number): void{
        if(!isDefined(unitCount))
            throw INPUT_NOT_DEFINED;
        else if(!isValidStrictlyPositiveInteger(unitCount))
            throw INVALID_TYPE;
    }

    static widthValidityCheck(width: number): void{
        if(!isDefined(width))
            throw INPUT_NOT_DEFINED;
        else if(!isValidStrictlyPositiveFloatingPoint(width))
            throw INVALID_TYPE;
    }

    static floorHeightValidityCheck(floorHeight: number): void{
        // if(!isDefined(floorHeight))
        //     throw INPUT_NOT_DEFINED;
        // else if(!isValidPositiveFloatingPoint(floorHeight))
        //     throw INVALID_TYPE;
        if(!isValidPositiveFloatingPoint(floorHeight))
            throw INVALID_TYPE;
    }

    static treadCountValidityCheck(treadCount: number): void{
        if(!isDefined(treadCount))
            throw INPUT_NOT_DEFINED;
        else if(!isValidStrictlyPositiveInteger(treadCount))
            throw INVALID_TYPE;
    }

    static staircaseTypeValidityCheck(staircaseType: IStaircaseType & IDatabaseController): void{
        if(!isDefined(staircaseType))
            throw INPUT_NOT_DEFINED;
    }

    static woodTypeValidityCheck(woodType: IWoodType & IDatabaseController): void{
        if(!isDefined(woodType))
            throw INPUT_NOT_DEFINED;
    }

    static riserWoodTypeValidityCheck(woodType: IWoodType & IDatabaseController): void{
        if(!isDefined(woodType))
            throw INPUT_NOT_DEFINED;
        if(!woodType.isAvailableForRisers)
            throw PROHIBITED_SELECTION;
    }

    static isWoodValidityCheck(isWood: boolean): void{
        if(!isDefined(isWood))
            throw INPUT_NOT_DEFINED;
        if(isWood !== false && isWood !== true)
            throw INVALID_VALUE;
    }

    static discountPercentageValidityCheck(discountPercentageStaircase: number, discountPercentageTreatment: number, discountPercentageDistribution: number): void{
        if(!isDefined(discountPercentageStaircase))
            throw INPUT_NOT_DEFINED;
        if(!isValidNumber(discountPercentageStaircase))
            throw INVALID_TYPE;
        if(discountPercentageStaircase < 0 || discountPercentageStaircase > 100)
            throw INVALID_VALUE;

        if(!isDefined(discountPercentageTreatment))
            throw INPUT_NOT_DEFINED;
        if(!isValidNumber(discountPercentageTreatment))
            throw INVALID_TYPE;
        if(discountPercentageTreatment < 0 || discountPercentageTreatment > 100)
            throw INVALID_VALUE;

        if(!isDefined(discountPercentageDistribution))
            throw INPUT_NOT_DEFINED;
        if(!isValidNumber(discountPercentageDistribution))
            throw INVALID_TYPE;
        if(discountPercentageDistribution < 0 || discountPercentageDistribution > 100)
            throw INVALID_VALUE;
    }

    clone(
        reference?: string,
        unitCount?: number,
        width?: number,
        floorHeight?: number,
        treadCount?: number,
        type?: IStaircaseType & IDatabaseController,
        woodType?: IWoodType & IDatabaseController,
        riserWoodType?: IWoodType & IDatabaseController,
        isWood?: boolean,
        shape?: ShapeInput,
        handrail?: HandrailInput,
        extraHandrail?: ExtraHandrailInput,
        landingStage?: LandingStageInput,
        treatment?: TreatmentInput,
        transportation?: TransportationInput,
        comments?: CommentInput[],
        variantGroup?: ObjectID | null,
        variantIncluded?: boolean | null,
        wallHandrail?: WallHandrailInput,
        discountPercentageStaircase?: number,
        discountPercentageTreatment?: number,
        discountPercentageDistribution?: number,
        quoteId?: ObjectID,
        id?: ObjectID,
    ): ArticleInput{
        return new ArticleInput(
            this.articleInfoStore,
            inu(reference, () => reference, ()=>this.reference),
            inu(unitCount, () => unitCount, ()=>this.unitCount),
            inu(width, () => width, () => this.width),
            inu(floorHeight, () => floorHeight, () => this.floorHeight),
            inu(treadCount, ()=>treadCount, ()=>this.treadCount),
            inu(type, ()=>type, ()=>this.type), // Must not be cloned because there is only one instance
            inu(woodType, ()=>woodType, ()=>this.woodType), // Must not be cloned because there is only one instance
            inu(riserWoodType, ()=>riserWoodType, ()=>this.riserWoodType), // Must not be cloned because there is only one instance
            inu(isWood, ()=>isWood, ()=>this.isWood),
            inu(shape, ()=>shape.clone(), ()=>this.shape.clone()),
            inu(handrail, ()=>handrail === null ? null : handrail.clone(), ()=> this.handrail === null ? null : this.handrail.clone()),
            inu(extraHandrail, ()=>extraHandrail === null ? null : extraHandrail.clone(), ()=> this.extraHandrail === null ? null : this.extraHandrail.clone()),
            inu(landingStage, ()=>landingStage.clone(), ()=>this.landingStage.clone()), // TODO: clone
            inu(treatment, ()=> treatment === null ? null : treatment.clone(), ()=> this.treatment === null ? null : this.treatment.clone()),
            inu(transportation, ()=>transportation.clone(), ()=>this.transportation.clone()),
            inu(comments, () => comments, ()=>this.comments).map(x=>x.clone()),
            inu(variantGroup, () => variantGroup, () => this.variantGroup),
            inu(variantIncluded, () => variantIncluded, () => this.variantIncluded),
            inu(wallHandrail, ()=>wallHandrail === null ? null : wallHandrail.clone(), ()=> this.wallHandrail === null ? null : this.wallHandrail.clone()),
            inu(discountPercentageStaircase, ()=> discountPercentageStaircase, ()=> this.discountPercentageStaircase),
            inu(discountPercentageTreatment, ()=> discountPercentageTreatment, ()=> this.discountPercentageTreatment),
            inu(discountPercentageDistribution, ()=> discountPercentageDistribution, ()=> this.discountPercentageDistribution),
            inu(quoteId, ()=>quoteId, ()=>this.quoteId),
            inu(id, ()=>id, ()=>this.id),
        );
    }

    setReference(reference: string): ArticleInput{
        return this.clone(reference);
    }

    setUnitCount(unitCount: number): ArticleInput{
        if(isNaN(unitCount))
            unitCount = 0;
        return this.clone(_, unitCount);
    }

    setWidth(width: number): ArticleInput{
        if(isNaN(width))
            width = 0;
        return this.clone(_, _, width);
    }

    setFloorHeight(floorHeight: number): ArticleInput{
        if(isNaN(floorHeight))
            floorHeight = 0;
        return this.clone(_, _, _, floorHeight);
    }

    setTreadCount(treadCount: number): ArticleInput{
        if(isNaN(treadCount))
            treadCount = 0;
        return this.clone(_, _, _, _, treadCount);
    }

    setType(type: IStaircaseType & IDatabaseController): ArticleInput{
        return this.clone(_, _, _, _, _, type);
    }

    setWoodType(woodType: IWoodType & IDatabaseController): ArticleInput{
        return this.clone(_, _, _, _, _, _, woodType);
    }

    setRiserWoodType(woodType: IWoodType & IDatabaseController): ArticleInput{
        return this.clone(_, _, _, _, _, _, _, woodType);
    }

    setIsWood(isWood: boolean): ArticleInput{
        return this.clone(_, _, _, _, _, _, _, _, isWood);
    }

    setShape(shape: ShapeInput): ArticleInput{
        return this.clone(_, _, _, _, _, _, _, _, _, shape);
    }

    setHandrail(handrail: HandrailInput): ArticleInput{
        return this.clone(_, _, _, _, _, _, _, _, _, _, handrail);
    }

    setExtraHandrail(extraHandrail: ExtraHandrailInput): ArticleInput{
        return this.clone(_, _, _, _, _, _, _, _, _, _, _, extraHandrail);
    }

    setLandingStage(landingStage: LandingStageInput): ArticleInput{
        return this.clone(_, _, _, _, _, _, _, _, _, _, _, _, landingStage);
    }

    setTreatment(treatment: TreatmentInput): ArticleInput{
        return this.clone(_, _, _, _, _, _, _, _, _, _, _, _, _, treatment);
    }

    setTransportation(transportation: TransportationInput): ArticleInput{
        return this.clone(_, _, _, _, _, _, _, _, _, _, _, _, _, _, transportation);
    }

    setComments(comments: CommentInput[]): ArticleInput{
        return this.clone(_, _, _, _, _, _, _, _, _, _, _, _, _, _, _, comments);
    }

    setVariantGroup(variantGroup: ObjectID | null): ArticleInput{
        return this.clone(_, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, variantGroup);
    }

    setVariantIncluded(variantIncluded: boolean | null): ArticleInput{
        return this.clone(_, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, variantIncluded);
    }

    setWallHandrail(wallHandrail: WallHandrailInput): ArticleInput{
        return this.clone(_, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, wallHandrail);
    }

    setDiscountPercentageStaircase(discountPercentage: number): ArticleInput{
        return this.clone(_, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, discountPercentage);
    }

    setDiscountPercentageTreatment(discountPercentage: number): ArticleInput{
        return this.clone(_, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, discountPercentage);
    }

    setDiscountPercentageDistribution(discountPercentage: number): ArticleInput{
        return this.clone(_, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, discountPercentage);
    }


}