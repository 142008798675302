import ObjectID from "bson-objectid";
import Language from "orbiter-core/src/datastructures/languages/Language";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import SingleLanguageString from "orbiter-core/src/datastructures/languages/SingleLanguageString";
import {default as WoodType, IWoodType} from "../../../../../shared/datastructures/staircasedata/WoodType";
import ApiController from "../ApiController";

export default class WoodTypeAPI extends ApiController<WoodType> implements IWoodType {

    public static getBaseEndpoint(): string{
        return "hotec/woodtype";
    }

    public static getApiKey(): string{
        return "woodType";
    }

    public static getPluralApiKey(): string{
        return "types";
    }

    public async parseFromDictionary(dictionary): Promise<void> {

        const title = new MultiLanguageString(
            dictionary.title.map((x) => {
                // TODO: LanguageAPI?
                const l = new Language(
                    x.languageId,
                    null,
                    null,
                );
                return new SingleLanguageString(
                    l,
                    x.value,
                );
            }),
        );

        const staircaseDesc = new MultiLanguageString(
            dictionary.description.map((x) => {
                // TODO: LanguageAPI?
                const l = new Language(
                    x.languageId,
                    null,
                    null,
                );
                return new SingleLanguageString(
                    l,
                    x.value,
                );
            }),
        );

        const handrailDesc = new MultiLanguageString(
            dictionary.handrailDescription.map((x) => {
                // TODO: LanguageAPI?
                const l = new Language(
                    x.languageId,
                    null,
                    null,
                );
                return new SingleLanguageString(
                    l,
                    x.value,
                );
            }),
        );

        const wt = new WoodType(
            dictionary.price,
            dictionary.multiplex,
            dictionary.enclosure,
            dictionary.risers,
            title,
            staircaseDesc,
            handrailDesc,
            dictionary.defaultRiserThicknessId ? new ObjectID(dictionary.defaultRiserThicknessId) : null,
        );
        this.setData(wt);
    }

    public getTitle(): MultiLanguageString {
        return this.getData().getTitle();
    }

    public getDescription(): MultiLanguageString {
        return this.getData().getDescription();
    }

    public getHandrailDescription(): MultiLanguageString {
        return this.getData().getHandrailDescription();
    }

    public getDefaultRiserThicknessId(): ObjectID | null {
        return this.getData().getDefaultRiserThicknessId();
    }

    public getPrice(): number {
        return this.getData().getPrice();
    }

    public isMultiplex(): boolean {
        return this.getData().isMultiplex();
    }

    public isAvailableForEnclosure(): boolean {
        return this.getData().isAvailableForEnclosure();
    }

    public isAvailableForRisers(): boolean {
        return this.getData().isAvailableForRisers();
    }

    public async setTitle(newTitle: MultiLanguageString): Promise<void> {
        this.getData().setTitle(newTitle);
        await this.updateProperty("title", newTitle);
    }

    public async setDescription(newDescription: MultiLanguageString): Promise<void> {
        this.getData().setDescription(newDescription);
        await this.updateProperty("description", newDescription);
    }

    public async setHandrailDescription(newDescription: MultiLanguageString): Promise<void> {
        this.getData().setHandrailDescription(newDescription);
        await this.updateProperty("handrailDescription", newDescription);
    }

    public async setDefaultRiserThicknessId(riserThicknessId: ObjectID): Promise<void> {
        this.getData().setDefaultRiserThicknessId(riserThicknessId);
        await this.updateProperty("defaultRiserThicknessId", riserThicknessId);
    }

    public async setMultiplex(val: boolean): Promise<void> {
        this.getData().setMultiplex(val);
        await this.updateProperty("multiplex", val);
    }

    public async setAvailableForEnclosure(val: boolean): Promise<void> {
        this.getData().setAvailableForEnclosure(val);
        await this.updateProperty("enclosure", val);
    }

    public async setAvailableForRisers(val: boolean): Promise<void> {
        this.getData().setAvailableForRisers(val);
        await this.updateProperty("risers", val);
    }

    public async setPrice(newPrice: number): Promise<void> {
        this.getData().setPrice(newPrice);
        await this.updateProperty("price", newPrice);
    }

}
