import ApiController from "../ApiController";
import {
    IThicknessRule,
    default as ThicknessRule
} from "../../../../../shared/datastructures/staircasedata/ThicknessRule";
import ObjectID from "bson-objectid";

// TODO: too much code duplication
export default class ThicknessRuleAPI extends ApiController<ThicknessRule> implements IThicknessRule {

    public static getBaseEndpoint(): string{
        return "hotec/thicknessrule";
    }

    public static getApiKey(): string{
        return "thicknessRule";
    }

    public async parseFromDictionary(dictionary): Promise<void> {

        const thicknessRule = new ThicknessRule(
            dictionary.staircaseShape ? new ObjectID(dictionary.staircaseShape) : null,
            dictionary.openStaircase,
            dictionary.minWidth,
            dictionary.maxWidth,
            dictionary.minTreadThickness,
            dictionary.minStringerThickness,
            dictionary.minRiserThickness,
            dictionary.order,
        );
        this.setData(thicknessRule);
    }

    
    public getStaircaseShape(): ObjectID | null{
        return this.getData().getStaircaseShape();
    }
    public getOpenStaircase(): boolean{
        return this.getData().getOpenStaircase();
    }
    public getMinWidth(): number{
        return this.getData().getMinWidth();
    }
    public getMaxWidth(): number{
        return this.getData().getMaxWidth();
    }
    public getMinTreadThickness(): number{
        return this.getData().getMinTreadThickness();
    }
    public getMinStringerThickness(): number{
        return this.getData().getMinStringerThickness();
    }
    public getMinRiserThickness(): number{
        return this.getData().getMinRiserThickness();
    }
    public getOrder(): number{
        return this.getData().getOrder();
    }

    public async setStaircaseShape(newValue: ObjectID | null): Promise<void>{
        (await this.getData()).setStaircaseShape(newValue);
        await this.updateProperty("staircaseShape", newValue);
    }
    public async setOpenStaircase(newValue: boolean): Promise<void>{
        (await this.getData()).setOpenStaircase(newValue);
        await this.updateProperty("openStaircase", newValue);
    }
    public async setMinWidth(newValue: number): Promise<void>{
        (await this.getData()).setMinWidth(newValue);
        await this.updateProperty("minWidth", newValue);
    }
    public async setMaxWidth(newValue: number): Promise<void>{
        (await this.getData()).setMaxWidth(newValue);
        await this.updateProperty("maxWidth", newValue);
    }
    public async setMinTreadThickness(newValue: number): Promise<void>{
        (await this.getData()).setMinTreadThickness(newValue);
        await this.updateProperty("minTreadThickness", newValue);
    }
    public async setMinStringerThickness(newValue: number): Promise<void>{
        (await this.getData()).setMinStringerThickness(newValue);
        await this.updateProperty("minStringerThickness", newValue);
    }
    public async setMinRiserThickness(newValue: number): Promise<void>{
        (await this.getData()).setMinRiserThickness(newValue);
        await this.updateProperty("minRiserThickness", newValue);
    }
    public async setOrder(newValue: number): Promise<void>{
        (await this.getData()).setOrder(newValue);
        await this.updateProperty("order", newValue);
    }

}
