import { tQuoteMinified } from '../../../../shared/datastructures/simplifiedNetworkCommunicationTypes';
import moment from 'moment';
import { Filters } from '../actions/globalInformationActions';
import {PopupManager} from '../components/Popup';
// TODO: Split in different sub-objects

const defaultState = {
    quotesLoaded: 0,
    moreQuotesAvailable: true,
    // Filtering
    filters: {},

    openingQuote: false,
    loading: 0,
    settingsLoaded: false,
    popups: new PopupManager([]),

    user: null,
    permissionStrings: [],
    distributor: null,

    distributors: [],
    users: [],
    baseConstantSets: [],
    quotes: [],
    filteredQuotes: [],
    woodTypes: [],
    handrailPoleTypes: [],
    handrailPoleFinishes: [],
    wallHandrails: [],
    wallHooks: [],
    staircaseTypes: [],
    spindleThreadTypes: [],
    handrailHandles: [],
    thicknessRules: [],
    staircasePlacementOptions: [],
    staircaseShapes: [],
    riserThickness: [],
    treadThickness: [],
    stringerThickness: [],
    treadSupplements: [],
    handrailSupplements: [],
    landingStageSupplements: [],
    placementSupplements: [],
    treatmentTypes: [],
    handrailTypes: [],
    permissions: [],
};

export default function reducer(state = defaultState, action){
    // MAKE SURE THE STATE IS NOT MUTATED, ALWAYS RETURN BRAND NEW VALUES

    const filter = (quotes: tQuoteMinified[], filters: Filters) => {
        return quotes.filter((quote) => {
            const q = filters.query?.toLowerCase();
            if(
                q
                && quote.customerName.toLowerCase().indexOf(q) === -1 // Customer name does not contain query
                && quote.quoteNumber.toLowerCase().indexOf(q) === -1 // Quote number does not contain query
                // && quote.distributorName.toLowerCase().indexOf(q) === -1 // Distributor name does not contain query
            )
                return false;

            if(filters.ownerName && quote.ownerName.toLowerCase().indexOf(filters.ownerName.toLowerCase()) === -1)
                return false;

            // if(state.quoteState && quote.quoteState !== state.quoteState)
            //     return false;

            if(filters.distributorId && quote.distributorId.toHexString() !== filters.distributorId)
                return false;

            if(filters.startDate && quote.creationDate < moment(filters.startDate).toDate())
                return false;
            if(filters.endDate && quote.creationDate > moment(filters.endDate).toDate())
                return false;

            return true;
        });
    }

    switch(action.type){
        case "SET_GLOBAL_BASIC_INFORMATION" : {
            return {...state, ...action.payload};
        }
        case "ADD_POPUP" : {
            return {...state, popups: state.popups._addPopup(action.payload)};
        }
        case "CLOSE_POPUP" : {
            return {...state, popups: state.popups._closePopup()};
        }
        case "REMOVE_GLOBAL_INFORMATION" : {
            return {...defaultState};
        }
        case "SET_GOBAL_DISTRIBUTORS" : {
            return {...state, distributors: action.payload};
        }
        case "SET_USERS" : {
            return {...state, users: action.payload};
        }
        case "SET_MINIFIED_QUOTES" : {
            return {...state, quotes: action.payload.quotes, filteredQuotes: filter(action.payload.quotes, state.filters), quotesLoaded: action.payload.quotes.length, moreQuotesAvailable: action.payload.moreAvailable};
        }
        case "APPEND_MINIFIED_QUOTES" : {
            const q = [...state.quotes, ...action.payload.quotes];
            return {...state, quotes: q, filteredQuotes: filter(q, state.filters), quotesLoaded: state.quotesLoaded + action.payload.quotes.length, moreQuotesAvailable: action.payload.moreAvailable};
        }
        case "FILTER_MINIFIED_QUOTES" : {
            const pl: Filters = {...state.filters, ...action.payload};
            return {...state, filters: pl, filteredQuotes: filter(state.quotes, pl)};
        }
    }
    return state;
}
