import { tComments } from "../simplifiedNetworkCommunicationTypes";
import { inu } from "orbiter-core/src/basic";
import { ICommentInput, PageType, stringToPageType } from "../IArticleInput";

const _ = undefined;

/**
 * Immutable comment input class.
 */
export default class CommentInput implements ICommentInput{

    constructor(
        readonly page: PageType,
        readonly content: string,
    ){}

    static from(commentInput: ICommentInput): CommentInput{
        return new CommentInput(
            commentInput.page,
            commentInput.content,
        ).clone();
    }

    static fromTComments(tCommentInput: tComments): CommentInput[]{
        return tCommentInput.map(x => new CommentInput(stringToPageType(x.page), x.content));
    }

    clone(
        page?: PageType,
        content?: string,
    ):CommentInput{
        return new CommentInput(
            inu(page, ()=>page, ()=>this.page),
            inu(content, ()=>content, ()=>this.content),
        )
    }

    setPage(page: PageType): CommentInput{
        return this.clone(page);
    }

    setContent(content: string): CommentInput{
        return this.clone(_, content);
    }

}