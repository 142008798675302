import * as React from "react";
import styleable from "react-styleable";
import * as styles from 'webc-reactcore/src/css/components/mainlayout/portalselect.css';
import * as styles2 from '../../css/components/AmountSelectItem.css';
import * as inputStyles from 'webc-reactcore/src/css/components/mainlayout/PortalInput.css';
import {PortalSelectItem} from "webc-reactcore/src/js/components/mainlayout/PortalSelectItem";

export class AmountSelectItem extends PortalSelectItem {

    render() {
        var selectedClass = "";
        if(this.props.selected === true){
            selectedClass = this.props.css.portalSelectItemSelected + " " + this.props.css.portalSelectItemSelectedAppendix;
        }
        return (
        <div className={this.props.css.portalSelectItem + " " + this.props.css.portalSelectItemAppendix + " " + selectedClass} onClick={() => this.props.onSelectionChange(this)}>
            <p className={this.props.css.textContent}>{this.props.children}</p>
            <button type="submit" onClick={this.props.onDeleteClick} className={this.props.css.baseInput + " " + this.props.css.deleteInput}><img src="/cdn/portal/assets/icons/remove.svg" /></button>
            <input className={this.props.css.baseInput + " " + this.props.css.amountInput} type="number" placeholder="#" min={1} value={this.props.amount} onChange={e => this.props.onAmountChange(parseFloat(e.target.value))} />
        </div>
    );
    }
}
export default styleable({...styles, ...styles2, baseInput: inputStyles.input})(AmountSelectItem);