import * as React from "react";
import styleable from "react-styleable";

import * as styles from "../../css/components/StyledBox.css";

/**
 * Styled box.
 *
 * ## styleable
 * * **container** Add styling to the box.
 *
 */
class StyledBox extends React.Component<any> {
    public render() {
        return (
            <div className={this.props.css.container}>
                {this.props.children}
            </div>
        );
    }
}
export default styleable(styles)(StyledBox);
