import {isValidNumber, isDefined} from "orbiter-core/src/basic";
import DataController from "orbiter-core/src/datastructures/DataController";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import { VALUE_EXCEPTION } from "orbiter-core/src/exceptions";

export interface IThickness extends DataController {
    getThickness(): number;
    getPrice(): number;
    getTitle(): MultiLanguageString;
    getDescription(): MultiLanguageString;
    setThickness(newPrice: number): void | Promise<void>;
    setPrice(newPrice: number): void | Promise<void>;
    setTitle(newTitle: MultiLanguageString): void | Promise<void>;
    setDescription(newDescription: MultiLanguageString): void | Promise<void>;
}

export class ThicknessValidityCheckers{

    public static isValidTitle(t: MultiLanguageString){
        return isDefined(t);
    }

    public static isValidDescription(d: MultiLanguageString){
        return isDefined(d);
    }

    public static isValidPrice(p: any){
        return isValidNumber(p);
    }

    public static isValidThickness(p: any){
        return isValidNumber(p);
    }

}

export default class Thickness extends DataController implements IThickness {

    @DataController.dataProperty()
    private price: number;
    @DataController.dataProperty()
    private thickness: number;
    @DataController.dataProperty(async (x) => await x.asList())
    private title: MultiLanguageString;
    @DataController.dataProperty(async (x) => await x.asList())
    private description: MultiLanguageString;

    public constructor(thickness: number, price: number, title: MultiLanguageString, description: MultiLanguageString){
        super();
        this.setThickness(thickness);
        this.setPrice(price);
        this.setTitle(title);
        this.setDescription(description);
    }

    public getTitle(): MultiLanguageString {
        return this.title;
    }

    public getDescription(): MultiLanguageString {
        return this.description;
    }

    public getPrice(): number {
        return this.price;
    }

    public getThickness(): number {
        return this.thickness;
    }

    public setTitle(newTitle: MultiLanguageString): void {
        this.title = newTitle;
    }

    public setDescription(newDescription: MultiLanguageString): void {
        this.description = newDescription;
    }

    public setPrice(newPrice: number): void {
        if(!isValidNumber(newPrice)) {
            throw VALUE_EXCEPTION;
        }
        this.price = Number(newPrice);
    }

    public setThickness(newThicnsetThickness: number): void {
        if(!isValidNumber(newThicnsetThickness)) {
            throw VALUE_EXCEPTION;
        }
        this.thickness = Number(newThicnsetThickness);
    }

    public isValid(): boolean{
        return ThicknessValidityCheckers.isValidDescription(this.getDescription())
                && ThicknessValidityCheckers.isValidTitle(this.getTitle())
                && ThicknessValidityCheckers.isValidPrice(this.getPrice);
    }

    public clone(): Thickness{
        return new Thickness(this.thickness, this.price, this.title.clone(), this.description.clone());
    }

}
