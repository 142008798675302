import React from 'react';
import IUniformNode, { deepenKey } from '../../../../../shared/quote-rendering/IUniformNode';
import { IBulletNodeProps } from '../../../../../shared/quote-rendering/UniformNodeFactory';

export default class BulletNode implements IUniformNode<React.ReactNode>{
    
    constructor(
        private readonly props: IBulletNodeProps<React.ReactNode>
    ) {}

    renderWithManualKey() {
        return <li key={this.props.key} style={{...this.props.style, paddingLeft: '10px'}}>{this.props.children.map(x => x.renderWithManualKey())}</li>
    }

    render(seedKey: string){
        return <li key={seedKey} style={{...this.props.style, paddingLeft: '10px'}}>{this.props.children.map((x,i) => x.render(deepenKey(seedKey, i)))}</li>
    }

}