import { IDatabaseController } from "orbiter-core/src/databasecontroller/DatabaseController";
import { differingStrings, differingUnorderedList, IArticleDiffLine, newDiffFalseLine, newDiffTrueLine } from "./ArticleInputDiff";
import { ITreatmentType } from "../staircasedata/TreatmentType";
import TreatmentInput from "../articleinput/TreatmentInput";

export default class TreatmentInputDiff{
    
    public main: IArticleDiffLine<TreatmentInput> = newDiffFalseLine();

    public type: IArticleDiffLine<ITreatmentType & IDatabaseController> = newDiffFalseLine();
    public colors: IArticleDiffLine<any[]> = newDiffFalseLine(); // TODO: change

    constructor(readonly left: TreatmentInput, readonly right: TreatmentInput){}

    public async diff(){
        if(this.left && this.right){
            // Both defined
            await this.diffType();
            await this.diffColors();
        }else if(this.left && !this.right){
            // Was defined, not anymore
            this.main = newDiffTrueLine(this.left, this.right);
            this.type = newDiffTrueLine(this.left.type, null);
            this.colors = newDiffTrueLine(this.left.colors, null);
        }else if(!this.left && this.right){
            // Was not defined, is now
            this.main = newDiffTrueLine(this.left, this.right);
            this.type = newDiffTrueLine(null, this.right.type);
            this.colors = newDiffTrueLine(null, this.right.colors);
        }
        
    }

    public differs(): boolean{
        return this.type.different
            || this.colors.different
            || this.main.different;
    }

    private async diffType(){
        if(differingStrings(this.left.type?.getSid(), this.right.type?.getSid())){
            this.type = newDiffTrueLine(
                this.left.type,
                this.right.type,
            )
        }
    }

    private async diffColors(){
        if(differingUnorderedList(this.left.colors, this.right.colors)){
            this.colors = newDiffTrueLine(
                this.left.colors,
                this.right.colors,
            )
        }
    }


}