import IArticleInput, { IExtraHandrailInput } from "../IArticleInput";
import HandrailType, { IHandrailType } from "../staircasedata/HandrailType";
import { IDatabaseController } from "orbiter-core/src/databasecontroller/DatabaseController";
import { inu, isDefined, isValidStrictlyPositiveInteger, isValidPositiveInteger } from "orbiter-core/src/basic";
import { INPUT_NOT_DEFINED, INVALID_TYPE, PROHIBITED_SELECTION } from "./inputExceptions";
import { ISpindleThreadType } from "../staircasedata/SpindleThreadType";
import { IHandrailPoleType } from "../staircasedata/HandrailPoleType";
import { IHandrailPoleFinish } from "../staircasedata/HandrailPoleFinish";
import { IHandrailHandle } from "../staircasedata/HandrailHandle";
import { IStaircaseShape } from "../staircasedata/StaircaseShape";

const _ = undefined;

/**
 * Immutable handrail input class.
 */
export default class ExtraHandrailInput implements IExtraHandrailInput{

    constructor(
        readonly type: IHandrailType & IDatabaseController = null,
        readonly handle: IHandrailHandle & IDatabaseController = null,
        readonly spindleThreadType: ISpindleThreadType & IDatabaseController = null,
        readonly spindleThreadDescription: string = null,
        readonly handrailPoleTypes: (IHandrailPoleType & IDatabaseController)[] = [],
        readonly handrailPoleFinishes: (IHandrailPoleFinish & IDatabaseController)[] = [],
        readonly treadCount: number = 0,
        readonly poleCount: number = 0,
    ){}

    static from(handrailInput: IExtraHandrailInput): ExtraHandrailInput{
        if(handrailInput === null){
            return null;
        }
        return new ExtraHandrailInput(
            handrailInput.type,
            handrailInput.handle,
            handrailInput.spindleThreadType,
            handrailInput.spindleThreadDescription,
            handrailInput.handrailPoleTypes,
            handrailInput.handrailPoleFinishes,
            handrailInput.treadCount,
            handrailInput.poleCount,
        ).clone();
    }

    static async validityCheck(handrail: IExtraHandrailInput, article: IArticleInput): Promise<void>{
        if(handrail === null)
            return;
        await this.typeValidityCheck(handrail.type, article.shape?.staircaseShape);
        this.handleValidityCheck(handrail.handle);
        if(handrail.type !== null && await handrail.type.isShowSpindle()){
            this.spindleThreadTypeValidityCheck(handrail.spindleThreadType, handrail.type);
        }
        const requiredNumberOfPoles = handrail.poleCount;
        const extraHandrailTreadCount = handrail.treadCount;

        this.treadCountValidityCheck(extraHandrailTreadCount);
        this.poleCountValidityCheck(requiredNumberOfPoles);
        
        this.poleTypesValidityCheck(handrail.handrailPoleTypes, requiredNumberOfPoles, handrail.type);
        this.poleFinishValidityCheck(handrail.handrailPoleFinishes, requiredNumberOfPoles, handrail.type);
        
    }

    static async typeValidityCheck(type: IHandrailType & IDatabaseController, staircaseShape: IStaircaseShape & IDatabaseController): Promise<void>{
        if(!isDefined(type))
            throw INPUT_NOT_DEFINED;

        if(staircaseShape && (await staircaseShape.getProhibitedHandrailTypeIds()).find(x => x.toHexString() === type.getId().toHexString())){
            throw PROHIBITED_SELECTION;
        }
    }

    static handleValidityCheck(handle: IHandrailHandle & IDatabaseController): void{
        
    }

    static poleCountValidityCheck(handrailPoleCount: number): void{
        if(!isDefined(handrailPoleCount))
            throw INPUT_NOT_DEFINED;
        else if(!isValidPositiveInteger(handrailPoleCount))
            throw INVALID_TYPE;
    }

    static treadCountValidityCheck(treadCount: number): void{
        if(!isDefined(treadCount))
            throw INPUT_NOT_DEFINED;
        else if(!isValidStrictlyPositiveInteger(treadCount))
            throw INVALID_TYPE;
    }

    static spindleThreadTypeValidityCheck(threadNumber: ISpindleThreadType & IDatabaseController, handrailType: (IHandrailType & IDatabaseController)): void{
        if(!isDefined(threadNumber) && !HandrailType.isHandrailIncluded(handrailType))
            return
        if(!isDefined(threadNumber))
            throw INPUT_NOT_DEFINED;
    }

    static poleTypesValidityCheck(handrailPoleTypes: (IHandrailPoleType & IDatabaseController)[], requiredNumberOfPoles: number, handrailType: (IHandrailType & IDatabaseController)): void{
        if(handrailPoleTypes.length == 0 && !HandrailType.isHandrailIncluded(handrailType))
            return
        if(handrailPoleTypes.length < requiredNumberOfPoles)
            throw INPUT_NOT_DEFINED;
        for(let i = 0; i<handrailPoleTypes.length; i++){
            if(!isDefined(handrailPoleTypes[i]))
                throw INPUT_NOT_DEFINED;
        }
    }

    static poleFinishValidityCheck(handrailPoleFinishes: (IHandrailPoleFinish & IDatabaseController)[], requiredNumberOfPoles: number, handrailType: (IHandrailType & IDatabaseController)): void{
        if(handrailPoleFinishes.length == 0 && !HandrailType.isHandrailIncluded(handrailType))
            return
        if(handrailPoleFinishes.length < requiredNumberOfPoles)
            throw INPUT_NOT_DEFINED;
        for(let i = 0; i<handrailPoleFinishes.length; i++){
            if(!isDefined(handrailPoleFinishes[i]))
                throw INPUT_NOT_DEFINED;
        }
    }

    clone(
        type?: IHandrailType & IDatabaseController,
        handle?: IHandrailHandle & IDatabaseController,
        spindleThreadType?: ISpindleThreadType & IDatabaseController,
        spindleThreadDescription?: string,
        handrailPoleTypes?: (IHandrailPoleType & IDatabaseController)[],
        handrailPoleFinishes?: (IHandrailPoleFinish & IDatabaseController)[],
        treadCount?: number,
        poleCount?: number,
    ):ExtraHandrailInput{
        return new ExtraHandrailInput(
            inu(type, ()=>type, ()=>this.type),
            inu(handle, ()=>handle, ()=>this.handle),
            inu(spindleThreadType, ()=>spindleThreadType, ()=>this.spindleThreadType),
            inu(spindleThreadDescription, ()=>spindleThreadDescription, ()=>this.spindleThreadDescription),
            inu(handrailPoleTypes, ()=>[...handrailPoleTypes], ()=>[...this.handrailPoleTypes]),
            inu(handrailPoleFinishes, ()=>[...handrailPoleFinishes], ()=>[...this.handrailPoleFinishes]),
            inu(treadCount, ()=>treadCount, ()=>this.treadCount),
            inu(poleCount, ()=>poleCount, ()=>this.poleCount),
        )
    }

    setType(type: IHandrailType & IDatabaseController): ExtraHandrailInput{
        return this.clone(type);
    }
    
    setHandle(handle: IHandrailHandle & IDatabaseController): ExtraHandrailInput{
        return this.clone(_, handle);
    }

    setSpindleThreadType(spindleThreadType: ISpindleThreadType & IDatabaseController): ExtraHandrailInput{
        return this.clone(_, _, spindleThreadType);
    }

    setSpindleThreadDescription(spindleThreadDescription: string): ExtraHandrailInput{
        return this.clone(_, _, _, spindleThreadDescription);
    }

    setHandrailPoleTypes(handrailPoleTypes: (IHandrailPoleType & IDatabaseController)[]): ExtraHandrailInput{
        return this.clone(_, _, _, _, handrailPoleTypes);
    }

    setHandrailPoleFinishes(handrailPoleFinishes: (IHandrailPoleFinish & IDatabaseController)[]): ExtraHandrailInput{
        return this.clone(_, _, _, _, _, handrailPoleFinishes);
    }

    setTreadCount(treadCount: number | null): ExtraHandrailInput{
        if(isNaN(treadCount))
            treadCount = 0;
        return this.clone(_, _, _, _, _, _, treadCount);
    }

    setPoleCount(poleCount: number | null): ExtraHandrailInput{
        if(isNaN(poleCount))
            poleCount = 0;
        return this.clone(_, _, _, _, _, _, _, poleCount);
    }


}