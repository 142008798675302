import * as React from "react";
import { t } from "ttag";
import { connect } from "react-redux";
import LoadingIcon from "./article/loaders/LoadingIcon";

@(connect((store: any) => {
    return { globalInformation: {...store.globalInformation} };
}) as any)
export default class LoadingQuote extends React.Component<{children: React.ReactNode, onLoaded?: ()=>void, globalInformation?: any}, {previousLoading: boolean}> {

    constructor(_){
        super(_);
        this.state = {previousLoading: false};
    }

    public render() {
        const isLoading = this.props.globalInformation.loading > 0;
        if(this.props.onLoaded && isLoading !== this.state.previousLoading){
            this.setState({previousLoading: isLoading});
            this.props.onLoaded();
        }
        return isLoading ? <LoadingIcon large={true} subtitle={t`Deze offerte wordt bijgewerkt. Even geduld.`} /> : this.props.children;
    }

}
