import * as React from "react";
import { inu } from "orbiter-core/src/basic";
import { connect } from "react-redux";
import { t } from 'ttag';
import ConfigBlock from "../../components/ConfigBlock";
import { toSelectItems } from "./helper";
import SimpleSettingsTemplate from "./SimpleSettingsTemplate";
import MappedInput from "webc-reactcore/src/js/components/mainlayout/MappedInput";
import InfoScreen from "../../components/InfoScreen";
import HotecUserAPI from "../../apicontroller/HotecUserAPI";
import HotecUserBrowserStandin, { createDefaultHotecUserProperties } from "../../../../../shared/datastructures/HotecUserBrowserStandin";

@(connect((store: any) => {
    return { settings: { ...store.settings }, globalInformation: { ...store.globalInformation } };
}) as any)
export default class Team extends React.Component<any, any> {

    public usersToSelectItems() {
        return toSelectItems<HotecUserAPI>("userpselect", this.state.users.sort((x: HotecUserAPI, y: HotecUserAPI) => x.getEmail() < y.getEmail() ? -1 : 1), (x) => HotecUserAPI.clone(x), (x) => x.getEmail());
    }

    private async createEmpty(): Promise<HotecUserBrowserStandin> {
        return new HotecUserBrowserStandin("", "", createDefaultHotecUserProperties());
    }

    private async create(data: HotecUserBrowserStandin) {
        await HotecUserAPI.createInTeam(data);
    }

    private loadTeam(){
        HotecUserAPI.retrieveTeam().then((users: HotecUserAPI[]) => {
            this.setState({users});
        })
    }

    componentDidMount(){
        this.loadTeam();
    }

    constructor(_) {
        super(_);
        this.state = {users: []};
    }

    public render() {

        return <SimpleSettingsTemplate
            title={t`team.`}
            selectItems={this.usersToSelectItems()}

            reload={() => {
                this.loadTeam();
            }}
            createEmpty={this.createEmpty.bind(this)}
            create={this.create.bind(this)}
            clone={(i: HotecUserAPI) => HotecUserAPI.clone(i)}

            renderEdit={this.renderEdit.bind(this)}
            renderCreate={this.renderCreate.bind(this)}

            infoScreen={<InfoScreen title={t`Selecteer een gebruiker.`} image={"/cdn/images/log.svg"}/>}
        />;

    }

    private renderEdit(a: HotecUserAPI, setSelected: (i: HotecUserAPI) => void) {
        return this.doRender(
            <MappedInput placeholder={t`Naam`} onBlur={(name) => {
                const cl: HotecUserAPI = HotecUserAPI.clone(a);
                try{
                    cl.getData().setName(name);
                }catch(e){
                    cl.getData().setName("");
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getName(), () => "")} />,
            <MappedInput placeholder={t`E-mailadres`} onBlur={(email) => {
                const cl: HotecUserAPI = HotecUserAPI.clone(a);
                try{
                    cl.getData().setEmail(email);
                }catch(e){
                    cl.getData().setEmail("");
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getEmail(), () => "")} />,
            <>
                <MappedInput type="password" placeholder={t`Wachtwoord`} onBlur={(password) => {
                    const cl: HotecUserAPI = HotecUserAPI.clone(a);
                    try{
                        cl.getData().setPassword(password);
                    }catch(e){
                        cl.getData().setPassword("");
                    }
                    setSelected(cl);
                }} value={inu(a, (a) => a.getPassword(), () => "")} />
                <i>{t`Laat het wachtwoord-veld leeg indien je het wachtwoord niet wilt wijzigen.`}</i>
            </>,
        );

    }

    private renderCreate(a: HotecUserBrowserStandin, setSelected: (i: HotecUserBrowserStandin) => void) {
        return this.doRender(
            <MappedInput placeholder={t`Naam`} onBlur={(name) => {
                const cl: HotecUserBrowserStandin = a.clone();
                try{
                    cl.setName(name);
                }catch(e){
                    cl.setName("");
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getName(), () => "")} />,
            <MappedInput placeholder={t`E-mailadres`} onBlur={(email) => {
                const cl: HotecUserBrowserStandin = a.clone();
                try{
                    cl.setEmail(email);
                }catch(e){
                    cl.setEmail("");
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getEmail(), () => "")} />,
            <MappedInput type="password" placeholder={t`Wachtwoord`} onBlur={(password) => {
                const cl: HotecUserBrowserStandin = a.clone();
                try{
                    cl.setPassword(password);
                }catch(e){
                    cl.setPassword("");
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getPassword(), () => "")} />,
        );
    }

    private doRender(
        nameInput: React.ReactNode,
        emailInput: React.ReactNode,
        passwordInput: React.ReactNode,
    ) {
        return (<div className={"container-fluid"}>
            <div className="row">
                <div className="col-md-6">
                    <ConfigBlock
                        title={"Gebruikersinformatie"}
                        description="Algemene informatie."
                        topMost={true}
                    >
                        {nameInput}
                        {emailInput}
                        {passwordInput}
                    </ConfigBlock>
                </div>
                <div className="col-md-6">
                </div>
            </div>
        </div>);
    }
}
