import * as React from "react";
import styleable from "react-styleable";

import * as styles from "../../../css/components/Overview/InputIndicator.css";
import RightFloatingMenuItem from "webc-reactcore/src/js/components/mainlayout/RightFloatingMenuItem";
import * as rightFloatingMenuItemStyle from "../../../css/rightFloatingMenuItemStyle.css";

/**
 * # Styleable
 * * **inputIndicatorInputContainer**
 * * **inputIndicatorButtonContainer**
 * * **inputIndicatorInputInnerContainer**
 * * **inputIndicatorInputMessage**
 * * **inputIndicatorInputLabel**
 * 
 * # Props
 * * **message: string**
 * * **buttons: {key: string, title: string}[]**
 * * **children**
 * * **onClick(key)**
 */
class InputIndicator extends React.Component<any> {
    public render() {
        if(!this.props.valid)
            return (
                <div className={this.props.css.inputIndicatorContainer}>
                    <div className={this.props.css.inputIndicatorInnerContainer}>
                        <p className={this.props.css.inputIndicatorMessage}>{this.props.message}</p>
                        <p className={this.props.css.inputIndicatorLabel}>?</p>
                    </div>
                    {this.props.children}
                    <div className={this.props.css.inputIndicatorButtonContainer}>
                        {
                            this.props.buttons.map(b => {
                                return <RightFloatingMenuItem key={b.key} onClick={() => {
                                    this.props.onClick(b.key)
                                }} title={b.title} css={{compose: rightFloatingMenuItemStyle}}/>
                            })
                        }
                    </div>
                </div>
            );
        else return this.props.children === undefined ? null : this.props.children;
    }
}
export default styleable(styles)(InputIndicator);
