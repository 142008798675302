exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1SQ936pRO9nh1ok8dpAPF6{\n    border-radius: 50%;\n    background-position: center;\n    background-size: 12px;\n    background-repeat: no-repeat;\n    width: 20px;\n    height: 20px;\n}", ""]);

// exports
exports.locals = {
	"icon": "_1SQ936pRO9nh1ok8dpAPF6"
};