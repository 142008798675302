exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1MYYGJ0I2MG47LMnZW_YjH{\n    background-color: #dc0000;\n    border-radius: 8px;\n}\n._1MYYGJ0I2MG47LMnZW_YjH p{\n    font-size: 14px;\n}\n._1MYYGJ0I2MG47LMnZW_YjH:hover{\n    background-color: #dc0000;\n}\n._3bcV6nl0R22dPQN0OraIkp{\n    background-color: #dc0000;\n}", ""]);

// exports
exports.locals = {
	"item": "_1MYYGJ0I2MG47LMnZW_YjH",
	"active": "_3bcV6nl0R22dPQN0OraIkp"
};