import { IStaircaseType } from "./staircasedata/StaircaseType";
import { IWoodType } from "./staircasedata/WoodType";
import { IDatabaseController } from "orbiter-core/src/databasecontroller/DatabaseController";
import { IThickness } from "./staircasedata/Thickness";
import { IStaircaseShape } from "./staircasedata/StaircaseShape";
import { IHandrailType } from "./staircasedata/HandrailType";
import { ITreatmentType } from "./staircasedata/TreatmentType";
import { ISpindleThreadType } from "./staircasedata/SpindleThreadType";
import { IHandrailPoleType } from "./staircasedata/HandrailPoleType";
import { IStaircasePlacementOption } from "./staircasedata/StaircasePlacementOption";
import IArticleInfoStore from "./articleinput/IArticleInfoStore";
import { ISupplement } from "./staircasedata/Supplement";
import ObjectID from "bson-objectid";
import { IHandrailHandle } from "./staircasedata/HandrailHandle";
import { IHandrailPoleFinish } from "./staircasedata/HandrailPoleFinish";
import { VALUE_EXCEPTION } from "orbiter-core/src/exceptions";
import { IWallHandrail } from "./staircasedata/WallHandrail";
import { IWallHook } from "./staircasedata/WallHook";

export interface IQuantifiedSupplement {amount: number, supplement: ISupplement & IDatabaseController}

export interface IShapeInput {
    riserCount: number | null, // Can be null in case of closed staircase (numer of risers equals number of treads)
    getRiserThickness(): IThickness & IDatabaseController,
    isRiserThicknessEnabled(article: IArticleInput): Promise<boolean>,
    staircaseShape: IStaircaseShape & IDatabaseController,
    stringerThickness: IThickness & IDatabaseController,
    treadSupplements: IQuantifiedSupplement[],
    treadThickness: IThickness & IDatabaseController,
    closedStaircase?: boolean,
}

export interface IHandrailInput {
    type: IHandrailType & IDatabaseController,
    handle: IHandrailHandle & IDatabaseController | null,
    spindleThreadType: ISpindleThreadType & IDatabaseController,
    spindleThreadDescription: string,
    supplements: IQuantifiedSupplement[],
    handrailPoleTypes: (IHandrailPoleType & IDatabaseController)[],
    handrailPoleFinishes: (IHandrailPoleFinish & IDatabaseController)[],
}

export interface IWallHandrailInput {
    type: IWallHandrail & IDatabaseController,
    hook: IWallHook & IDatabaseController | null,
    length: number,
    hookCount: number,
    bendCount: number,
    ignoreWoodType: boolean,
}

export interface IExtraHandrailInput {
    type: IHandrailType & IDatabaseController,
    handle: IHandrailHandle & IDatabaseController | null,
    spindleThreadType: ISpindleThreadType & IDatabaseController,
    spindleThreadDescription: string,
    handrailPoleTypes: (IHandrailPoleType & IDatabaseController)[],
    handrailPoleFinishes: (IHandrailPoleFinish & IDatabaseController)[],
    treadCount: number,
    poleCount: number,
}

export interface ITreatmentInput {
    type: ITreatmentType & IDatabaseController,
    colors: string[], // TODO: change type?
}

export enum PageType{
    STAIRCASE = "staircase",
    SHAPE = "shape",
    HANDRAIL = "handrail",
    LANDING_STAGE = "landing_stage",
    TREATMENT = "treatment",
    TRANSPORTATION = "transportation",
    GENERAL = "general",
}

export function stringToPageType(value: string): PageType{
    switch(value.toUpperCase().trim()){
        case "STAIRCASE": return PageType.STAIRCASE;
        case "SHAPE": return PageType.SHAPE;
        case "HANDRAIL": return PageType.HANDRAIL;
        case "LANDING_STAGE": return PageType.LANDING_STAGE;
        case "TREATMENT": return PageType.TREATMENT;
        case "TRANSPORTATION": return PageType.TRANSPORTATION;
        case "GENERAL": return PageType.GENERAL;
    }
    throw VALUE_EXCEPTION;
}

export interface ICommentInput {
    page: PageType,
    content: string,
}

export interface ILandingStageInput {
    handrail: IHandrailInput,
    handrailLength: number,
    concreteEnclosureLength: number, // TODO: is this part of landing stage handrail?
    concreteEnclosureWidth: number,
    concreteEnclosureWoodType: IWoodType & IDatabaseController,
    handrailPoleCount: number,
    handrailPoleTypes: (IHandrailPoleType & IDatabaseController)[],
    handrailPoleFinishes: (IHandrailPoleFinish & IDatabaseController)[],
}

export interface ITransportationInput {
    transportationMethod: string,
    placementSupplements: IQuantifiedSupplement[],
    staircasePlacementOptions: (IStaircasePlacementOption & IDatabaseController)[],
    measurementByHotec: boolean,
    assembledByHotec: boolean,
}

export default interface IArticleInput {
    readonly articleInfoStore: IArticleInfoStore,

    reference: string,
    unitCount: number,
    width: number,
    floorHeight: number,
    treadCount: number,
    type: IStaircaseType & IDatabaseController,
    woodType: IWoodType & IDatabaseController,
    riserWoodType: IWoodType & IDatabaseController,
    isWood: boolean,
    shape: IShapeInput,
    handrail: IHandrailInput,
    wallHandrail: IWallHandrailInput | null,
    extraHandrail: IExtraHandrailInput | null,
    landingStage: ILandingStageInput,
    treatment: ITreatmentInput,
    transportation: ITransportationInput,
    comments: ICommentInput[],
    variantGroup: ObjectID | null,
    variantIncluded: boolean | null,
    discountPercentageStaircase: number,
    discountPercentageTreatment: number,
    discountPercentageDistribution: number,
    quoteId?: ObjectID,
    id?: ObjectID,
}