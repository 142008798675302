import { DispatchProp } from "react-redux";
import DistributorAPI from "../apicontroller/DistributorAPI";
import IArticleInput from "../../../../shared/datastructures/IArticleInput";
import { tObjectId } from "../../../../shared/datastructures/simplifiedNetworkCommunicationTypes";
import StaircaseTypeAPI from "../apicontroller/staircasedata/StaircaseTypeAPI";
import WoodTypeAPI from "../apicontroller/staircasedata/WoodTypeAPI";
import StaircaseShapeAPI from "../apicontroller/staircasedata/StaircaseShapeAPI";
import HandrailTypeAPI from "../apicontroller/staircasedata/HandrailTypeAPI";
import { SupplementHandrailAPI, PlacementSupplementAPI, SupplementLandingStageAPI } from "../apicontroller/staircasedata/SupplementAPI";
import HandrailPoleTypeAPI from "../apicontroller/staircasedata/HandrailPoleTypeAPI";
import TreatmentTypeAPI from "../apicontroller/staircasedata/TreatmentTypeAPI";
import { TreadThicknessAPI, RiserThicknessAPI, StringerThicknessAPI } from "../apicontroller/staircasedata/ThicknessAPI";
import SpindleThreadTypeAPI from "../apicontroller/staircasedata/SpindleThreadTypeAPI";
import StaircasePlacementOptionAPI from "../apicontroller/staircasedata/StaircasePlacementOptionAPI";
import { RetrievedArticleData } from "../pages/newquote/article/loaders/loaderHelper";
import HandrailHandleAPI from "../apicontroller/staircasedata/HandrailHandleAPI";
import HandrailPoleFinishAPI from "../apicontroller/staircasedata/HandrailPoleFinishAPI";
import WallHookAPI from "../apicontroller/staircasedata/WallHookAPI";
import WallHandrailAPI from "../apicontroller/staircasedata/WallHandrailAPI";
import ThicknessRuleAPI from "../apicontroller/staircasedata/ThicknessRuleAPI";
import BaseConstantSetAPI from "../apicontroller/staircasedata/BaseConstantSetAPI";
import Quote, { CURRENT_QUOTE_CALCULATION_VERSION } from "../../../../shared/datastructures/Quote";
import ArticleCalculation, { IArticle } from "../../../../shared/datastructures/ArticleCalculation";
import HotecUserAPI from "../apicontroller/HotecUserAPI";
import QuoteLog from "../../../../shared/datastructures/QuoteLog";

export type IQuoteCreationStateDispatch = IQuoteCreationState & DispatchProp;

export interface IQuoteCreationState {
    quoteId: tObjectId,

    customerName: string,
    customerStreet: string,
    customerCity: string,
    customerPostalCode: string,
    customerCountry: string,
    customerPhone: string,
    customerEmail: string,

    constructionSiteStreet: string,
    constructionSiteCity: string,
    constructionSitePostalCode: string,
    constructionSiteCountry: string,

    equalAddresses: boolean,

    distributor: DistributorAPI,
    ownerId: tObjectId,
    ownerName: string,
    quoteState: string,
    quoteNumber: string,
    quoteCalculationVersion: number,

    articles: IArticleInput[],

    distance: number,
    origin: string | null,
    destination: string | null,

    stage: string,
    identification: number,
    title: string,
    description: string,

    creationDate: Date,
    expirationDate: Date,
    expired: boolean,
    lastUpdateDate: Date,
    dataDate: Date,
    _tmpDataDateChecked: boolean;

    logs: QuoteLog[],

    _localShowPriceDetails: boolean;
    _localShowDiscount: boolean;
    
    baseConstantSet: BaseConstantSetAPI,
    availableStaircaseTypes: StaircaseTypeAPI[],
    availableWoodTypes: WoodTypeAPI[],
    availableStaircaseShapes: StaircaseShapeAPI[],
    availableSupplementsTread: any[],
    availableHandrailTypes: HandrailTypeAPI[],
    availableWallHandrails: WallHandrailAPI[],
    availableWallHooks: WallHookAPI[],
    availableThicknessRules: ThicknessRuleAPI[],
    availableSupplementsHandrail: SupplementHandrailAPI[],
    availableHandrailPoleTypes: HandrailPoleTypeAPI[],
    availableHandrailPoleFinishes: HandrailPoleFinishAPI[],
    availableSupplementsLandingStageHandrail: SupplementLandingStageAPI[],
    availableSupplementsPlacement: PlacementSupplementAPI[],
    availableTreatmentTypes: TreatmentTypeAPI[],
    availableTreadThickness: TreadThicknessAPI[],
    availableRiserThickness: RiserThicknessAPI[],
    availableStringerThickness: StringerThicknessAPI[],
    availableSpindleThreadTypes: SpindleThreadTypeAPI[],
    availableHandrailHandles: HandrailHandleAPI[],
    availableStaircasePlacementOptions: StaircasePlacementOptionAPI[],
    availableDistributors: DistributorAPI[],
    availableUsers: HotecUserAPI[],
}

// TODO: Split in different sub-objects
const defaultState: IQuoteCreationState = {
    quoteId: undefined,

    customerName: "",
    customerStreet: "",
    customerCity: "",
    customerPostalCode: "",
    customerCountry: "",
    customerPhone: "",
    customerEmail: "",

    constructionSiteStreet: "",
    constructionSiteCity: "",
    constructionSitePostalCode: "",
    constructionSiteCountry: "",

    equalAddresses: true,

    distributor: undefined,
    ownerId: undefined,
    ownerName: "",
    quoteState: undefined,
    quoteNumber: undefined,
    quoteCalculationVersion: CURRENT_QUOTE_CALCULATION_VERSION,

    articles: [],

    distance: 0,
    origin: null,
    destination: null,

    stage: "draft",
    identification: -1,
    title: "",
    description: "",

    creationDate: null,
    expirationDate: null,
    expired: false,
    lastUpdateDate: null,
    dataDate: null,
    _tmpDataDateChecked: false,

    logs: [],

    _localShowPriceDetails: false,
    _localShowDiscount: false,
    
    baseConstantSet: null,
    availableStaircaseTypes: [],
    availableWoodTypes: [],
    availableStaircaseShapes: [], // TODO: different for wood/concrete?
    availableSupplementsTread: [], // TODO: different for wood/concrete?
    availableHandrailTypes: [], // TODO: different for wood/concrete? // TODO: different landing stage handrail types?
    availableWallHandrails: [],
    availableWallHooks: [],
    availableThicknessRules: [],
    availableHandrailPoleTypes: [], // TODO: different for wood/concrete?
    availableHandrailPoleFinishes: [], // TODO: different for wood/concrete?
    availableSupplementsHandrail: [], // TODO: different for wood/concrete?
    availableSupplementsLandingStageHandrail: [], // TODO: different for wood/concrete?
    availableSupplementsPlacement: [],
    availableTreatmentTypes: [], // TODO: different for wood/concrete?
    availableTreadThickness: [], // TODO: different for wood/concrete?
    availableRiserThickness: [], // TODO: different for wood/concrete?
    availableStringerThickness: [], // TODO: different for wood/concrete?
    availableSpindleThreadTypes: [], // TODO: different for wood/concrete?
    availableHandrailHandles: [],
    availableStaircasePlacementOptions: [],
    availableDistributors: [],
    availableUsers: [],
};
export default function reducer(state = defaultState, action){
    // MAKE SURE THE STATE IS NOT MUTATED, ALWAYS RETURN BRAND NEW VALUES
    switch(action.type){
        case "SET_QUOTE_CREATION_BASIC_INFORMATION" : {
            return {...state, ...action.payload};
        }
        case "SET_QUOTE_CREATION_EQUAL_ADDRESSES" : {
            return {...state, equalAddresses: action.payload};
        }
        case "INIT_QUOTE" : {
            const quoteId: string = action.payload.quoteId._id;
            const ownerId: string = action.payload.ownerId;
            const distributor: DistributorAPI = action.payload.distributor;
            const quoteState: string = action.payload.quoteState;
            const articleInformation: RetrievedArticleData = action.payload.articleInformation;
            return {...defaultState, quoteId, ownerId, distributor, quoteState, ...articleInformation};
        }
        case "REMOVE_QUOTE_CREATION_INFORMATION" : {
            return {...defaultState};
        }
        case "ADD_ARTICLE_TO_QUOTE" : {
            return {...state, articles: [...state.articles, action.payload]};
        }
        case "DELETE_ARTICLE_FROM_QUOTE" : {
            return {...state, articles: state.articles.filter(x => x.id?.toString() !== action.payload.toString() && x.variantGroup?.toString() !== action.payload.toString())};
        }
        case "UPDATE_ARTICLE_FOR_QUOTE" : {
            const newArticles = [];
            for(const article of state.articles){
                if(article.id === action.payload.id){
                    newArticles.push(action.payload);
                }else{
                    newArticles.push(article);
                }
            }
            return {...state, articles: newArticles};
        }
    }
    return state;
}

export async function quoteCreateStateToQuote(qcs: IQuoteCreationState): Promise<Quote>{
    const articles: IArticle[] = [];
    for (let i = 0; i < qcs.articles.length; i++) {
        const article = qcs.articles[i];
        const articleCalc = await ArticleCalculation.init(article, qcs.distance, qcs.distributor?.getId(), qcs.quoteCalculationVersion);
        articles.push(articleCalc);
    }
    return new Quote(
        qcs.distributor?.getId(),
        qcs.ownerId,
        qcs.ownerName,
        qcs.quoteState,
        qcs.quoteNumber,
        qcs.creationDate,
        qcs.expirationDate,
        qcs.lastUpdateDate,
        qcs.dataDate,
        qcs.customerName,
        qcs.customerEmail,
        qcs.customerPhone,
        {streetAndNumber: qcs.customerStreet, postalCode: qcs.customerPostalCode, city: qcs.customerCity, country: qcs.customerCountry},
        {streetAndNumber: qcs.constructionSiteStreet, postalCode: qcs.constructionSitePostalCode, city: qcs.constructionSiteCity, country: qcs.constructionSiteCountry},
        articles,
        qcs.distance,
        qcs.origin,
        qcs.destination,
        qcs.stage,
        qcs.identification,
        qcs.title,
        qcs.description,
        qcs.quoteCalculationVersion,
    )
}