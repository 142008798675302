import ObjectID from "bson-objectid";
import IArticleInfoStore from "../articleinput/IArticleInfoStore";
import { IDatabaseController } from "orbiter-core/src/databasecontroller/DatabaseController";
import DataController from "orbiter-core/src/datastructures/DataController";
import StaircaseShape, { IStaircaseShape } from "./StaircaseShape";
import WoodType, { IWoodType } from "./WoodType";

export interface IThicknessRule extends DataController {
    getStaircaseShape(): ObjectID | null | Promise<ObjectID | null>;
    getOpenStaircase(): boolean | Promise<boolean>;
    getMinWidth(): number | Promise<number>;
    getMaxWidth(): number | Promise<number>;
    getMinTreadThickness(): number | Promise<number>;
    getMinStringerThickness(): number | Promise<number>;
    getMinRiserThickness(): number | Promise<number>;
    getOrder(): number | Promise<number>;
    setStaircaseShape(newValue: ObjectID | null): void | Promise<void>;
    setOpenStaircase(newValue: boolean): void | Promise<void>;
    setMinWidth(newValue: number): void | Promise<void>;
    setMaxWidth(newValue: number): void | Promise<void>;
    setMinTreadThickness(newValue: number): void | Promise<void>;
    setMinStringerThickness(newValue: number): void | Promise<void>;
    setMinRiserThickness(newValue: number): void | Promise<void>;
    setOrder(newValue: number): void | Promise<void>;
}

export default class ThicknessRule extends DataController implements IThicknessRule {

    
    @DataController.dataProperty()
    private staircaseShape: ObjectID | null;
    @DataController.dataProperty()
    private openStaircase: boolean;
    @DataController.dataProperty()
    private minWidth: number;
    @DataController.dataProperty()
    private maxWidth: number;
    @DataController.dataProperty()
    private minTreadThickness: number;
    @DataController.dataProperty()
    private minStringerThickness: number;
    @DataController.dataProperty()
    private minRiserThickness: number;
    @DataController.dataProperty()
    private order: number;

    public constructor(
        staircaseShape: ObjectID | null,
        openStaircase: boolean,
        minWidth: number,
        maxWidth: number,
        minTreadThickness: number,
        minStringerThickness: number,
        minRiserThickness: number,
        order: number,
    ){
        super();
        this.setStaircaseShape(staircaseShape);
        this.setOpenStaircase(openStaircase);
        this.setMinWidth(minWidth);
        this.setMaxWidth(maxWidth);
        this.setMinTreadThickness(minTreadThickness);
        this.setMinStringerThickness(minStringerThickness);
        this.setMinRiserThickness(minRiserThickness);
        this.setOrder(order);
    }

    public getStaircaseShape(): ObjectID | null{
        return this.staircaseShape;
    }
    public getOpenStaircase(): boolean{
        return this.openStaircase;
    }
    public getMinWidth(): number{
        return this.minWidth;
    }
    public getMaxWidth(): number{
        return this.maxWidth;
    }
    public getMinTreadThickness(): number{
        return this.minTreadThickness;
    }
    public getMinStringerThickness(): number{
        return this.minStringerThickness;
    }
    public getMinRiserThickness(): number{
        return this.minRiserThickness;
    }
    public getOrder(): number{
        return this.order;
    }

    public setStaircaseShape(newValue: ObjectID | null): void{
        this.staircaseShape = newValue;
    }
    public setOpenStaircase(newValue: boolean): void{
        this.openStaircase = newValue;
    }
    public setMinWidth(newValue: number): void{
        this.minWidth = newValue;
    }
    public setMaxWidth(newValue: number): void{
        this.maxWidth = newValue;
    }
    public setMinTreadThickness(newValue: number): void{
        this.minTreadThickness = newValue;
    }
    public setMinStringerThickness(newValue: number): void{
        this.minStringerThickness = newValue;
    }
    public setMinRiserThickness(newValue: number): void{
        this.minRiserThickness = newValue;
    }
    public setOrder(newValue: number): void{
        this.order = newValue;
    }

    public clone(): ThicknessRule{
        return new ThicknessRule(
            this.staircaseShape ? new ObjectID(this.staircaseShape.toHexString()) : null,
            this.openStaircase,
            this.minWidth,
            this.maxWidth,
            this.minTreadThickness,
            this.minStringerThickness,
            this.minRiserThickness,
            this.order,
        );
    }
    

    public static async getMatchingRules(staircaseShape: (IStaircaseShape & IDatabaseController) | null, staircaseWidth: number, openStaircase: boolean, rules: IThicknessRule[]): Promise<IThicknessRule[]>{
        const sortedRules = rules.sort((x, y) => x.getOrder() < y.getOrder() ? -1 : 1);
        const matchingRules: IThicknessRule[] = [];
        for (let i = 0; i < sortedRules.length; i++) {
            const rule = sortedRules[i];
            const ruleStaircaseShape = (await rule.getStaircaseShape());
            if(ruleStaircaseShape === null || (staircaseShape !== null && staircaseShape.getId().toHexString() === ruleStaircaseShape.toHexString())){
                if(staircaseWidth >= (await rule.getMinWidth()) && staircaseWidth <= (await rule.getMaxWidth())){
                    if(openStaircase === (await rule.getOpenStaircase())){
                        matchingRules.push(rule);
                    }
                }
            }            
        }    
        return matchingRules;
    }

    public static async getMaxFromMatchingRules(rules: IThicknessRule[], thicknessFormula: (rule: IThicknessRule) => Promise<number>): Promise<number>{
        let max: number = 0;
        for (let i = 0; i < rules.length; i++) {
            const rule = rules[i];
            const currentThickness = await thicknessFormula(rule);
            if(currentThickness > max){
                max = currentThickness;
            }
        }
        return max;
    }
    
    public static async getMinTreadThickness(staircaseShape: (IStaircaseShape & IDatabaseController) | null, staircaseWidth: number, openStaircase: boolean, rules: IThicknessRule[]): Promise<number>{
        const matchingRules = await this.getMatchingRules(staircaseShape, staircaseWidth, openStaircase, rules);
        return this.getMaxFromMatchingRules(matchingRules, async rule => await rule.getMinTreadThickness());
    }
    
    public static async getMinStringerThickness(staircaseShape: (IStaircaseShape & IDatabaseController) | null, staircaseWidth: number, openStaircase: boolean, rules: IThicknessRule[]): Promise<number>{
        const matchingRules = await this.getMatchingRules(staircaseShape, staircaseWidth, openStaircase, rules);
        return this.getMaxFromMatchingRules(matchingRules, async rule => await rule.getMinStringerThickness());
    }
    
    public static async getMinRiserThickness(staircaseShape: (IStaircaseShape & IDatabaseController) | null, staircaseWidth: number, openStaircase: boolean, material: (IWoodType & IDatabaseController), rules: IThicknessRule[], articleInfoStore: IArticleInfoStore): Promise<number>{
        const matchingRules = await this.getMatchingRules(staircaseShape, staircaseWidth, openStaircase, rules);
        if(matchingRules.length > 0){
            return this.getMaxFromMatchingRules(matchingRules, async rule => await rule.getMinRiserThickness());
        }
        if(material){
            const defaultRiserThicknessId = await material.getDefaultRiserThicknessId();
            if(defaultRiserThicknessId){
                const defaultRiserThickness = await articleInfoStore.getRiserThickness(defaultRiserThicknessId);
                if(defaultRiserThickness){
                    return await defaultRiserThickness.getThickness();
                }
            }
        }
        return 0;
    }

}
