import React from 'react';
import IUniformNode, { deepenKey } from '../../../../../shared/quote-rendering/IUniformNode';
import { ILinkNodeProps } from '../../../../../shared/quote-rendering/UniformNodeFactory';

export default class LinkNode implements IUniformNode<React.ReactNode>{
    
    constructor(
        private readonly props: ILinkNodeProps<React.ReactNode>
    ) {}

    renderWithManualKey() {
        return <a key={this.props.key} href={this.props.src}>{this.props.children.map(x => x.renderWithManualKey())}</a>
    }

    render(seedKey: string){
        return <a key={seedKey} href={this.props.src}>{this.props.children.map((x,i) => x.render(deepenKey(seedKey, i)))}</a>
    }

}