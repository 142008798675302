import * as React from "react";
import { connect } from "react-redux";
import { loadTreatmentTypes } from "../../loader";
import { dt } from "webc-reactcore/src/js/stores/GlobalStore";
import { toSelectItems, createTitlePortalCollapsibleLanguageField, createDescriptionPortalCollapsibleLanguageField, createApiTitlePortalCollapsibleLanguageField, createApiDescriptionPortalCollapsibleLanguageField } from "./helper";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import SimpleSettingsTemplate from "./SimpleSettingsTemplate";
import ConfigBlock from "../../components/ConfigBlock";
import TreatmentTypeAPI from "../../apicontroller/staircasedata/TreatmentTypeAPI";
import TreatmentType from "../../../../../shared/datastructures/staircasedata/TreatmentType";
import MappedInput from "webc-reactcore/src/js/components/mainlayout/MappedInput";
import { t } from "ttag";
import { toNumber, inu } from "orbiter-core/src/basic";
import InfoScreen from "../../components/InfoScreen";

@(connect((store: any) => {
    return { settings: { ...store.settings }, globalInformation: { ...store.globalInformation } };
}) as any)
export default class TreatmentTypeSettings extends React.Component<any, any> {

    public treatmentTypesToSelectItems() {
        return toSelectItems<TreatmentTypeAPI>("treatmenttype", this.props.globalInformation.treatmentTypes.sort((x, y) => dt(x.getTitle()) < dt(y.getTitle()) ? -1 : 1), (x) => TreatmentTypeAPI.clone(x), (x) => dt(x.getTitle()));
    }

    private async createEmpty(): Promise<TreatmentType> {
        return new TreatmentType(0, 0, 0, 0, new MultiLanguageString(), new MultiLanguageString());
    }

    private async create(data: TreatmentType) {
        await TreatmentTypeAPI.create(data);
    }

    public render() {
        return <SimpleSettingsTemplate
            title="Behandelingstypes"
            selectItems={this.treatmentTypesToSelectItems()}

            reload={loadTreatmentTypes.bind(this)}
            createEmpty={this.createEmpty.bind(this)}
            create={this.create.bind(this)}
            clone={(i: TreatmentTypeAPI) => TreatmentTypeAPI.clone(i)}

            renderEdit={this.renderEdit.bind(this)}
            renderCreate={this.renderCreate.bind(this)}

            infoScreen={<InfoScreen title={t`Selecteer een behandelingstype.`} image={"/cdn/images/paint-brush.svg"}/>}
        />;
    }


    private renderEdit(a: TreatmentTypeAPI, setSelected: (i: TreatmentTypeAPI) => void) {
        return this.doRender(
            createApiTitlePortalCollapsibleLanguageField(a, (x) => TreatmentTypeAPI.clone(x), setSelected.bind(this)),
            createApiDescriptionPortalCollapsibleLanguageField(a, (x) => TreatmentTypeAPI.clone(x), setSelected.bind(this)),
            <MappedInput placeholder={t`Prijs per trede voor trap`}
                onBlur={(factor) => {
                    const cl: TreatmentTypeAPI = TreatmentTypeAPI.clone(a);
                    try {
                        cl.getData().setStaircaseFactor(toNumber(factor));
                    } catch (e) {
                        cl.getData().setStaircaseFactor(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getStaircaseFactor(), () => "")} />,
            <MappedInput placeholder={t`Prijs per trede voor leuning`}
                onBlur={(factor) => {
                    const cl: TreatmentTypeAPI = TreatmentTypeAPI.clone(a);
                    try {
                        cl.getData().setHandrailFactor(toNumber(factor));
                    } catch (e) {
                        cl.getData().setHandrailFactor(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getHandrailFactor(), () => "")} />,
            <MappedInput placeholder={t`Prijs per lopende meter voor overloop`}
                onBlur={(factor) => {
                    const cl: TreatmentTypeAPI = TreatmentTypeAPI.clone(a);
                    try {
                        cl.getData().setLandingStageFactor(toNumber(factor));
                    } catch (e) {
                        cl.getData().setLandingStageFactor(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getLandingStageFactor(), () => "")} />,
            <MappedInput placeholder={t`Prijs per lopende meter voor muurleuning`}
                onBlur={(factor) => {
                    const cl: TreatmentTypeAPI = TreatmentTypeAPI.clone(a);
                    try {
                        cl.getData().setWallHandrailFactor(toNumber(factor));
                    } catch (e) {
                        cl.getData().setWallHandrailFactor(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getWallHandrailFactor(), () => "")} />,
        );
    }

    private renderCreate(a: TreatmentType, setSelected: (i: TreatmentType) => void) {
        return this.doRender(
            createTitlePortalCollapsibleLanguageField(a, setSelected.bind(this)),
            createDescriptionPortalCollapsibleLanguageField(a, setSelected.bind(this)),
            <MappedInput placeholder={t`Prijs per trede voor trap`}
                onBlur={(factor) => {
                    const cl: TreatmentType = a.clone();
                    try {
                        cl.setStaircaseFactor(toNumber(factor));
                    } catch (e) {
                        cl.setStaircaseFactor(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getStaircaseFactor(), () => "")} />,
            <MappedInput placeholder={t`Prijs per trede voor leuning`}
                onBlur={(factor) => {
                    const cl: TreatmentType = a.clone();
                    try {
                        cl.setHandrailFactor(toNumber(factor));
                    } catch (e) {
                        cl.setHandrailFactor(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getHandrailFactor(), () => "")} />,
            <MappedInput placeholder={t`Prijs per lopende meter voor overloop`}
                onBlur={(factor) => {
                    const cl: TreatmentType = a.clone();
                    try {
                        cl.setLandingStageFactor(toNumber(factor));
                    } catch (e) {
                        cl.setLandingStageFactor(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getLandingStageFactor(), () => "")} />,
            <MappedInput placeholder={t`Prijs per lopende meter voor muurleuning`}
                onBlur={(factor) => {
                    const cl: TreatmentType = a.clone();
                    try {
                        cl.setWallHandrailFactor(toNumber(factor));
                    } catch (e) {
                        cl.setWallHandrailFactor(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getWallHandrailFactor(), () => "")} />,
        );
    }

    private doRender(
        titlePCLF: React.ReactNode,
        descriptionPCLF: React.ReactNode,
        staircaseFactorMI: React.ReactNode,
        handrailFactorMI: React.ReactNode,
        landingStageFactorMI: React.ReactNode,
        wallHandrailFactorMI: React.ReactNode,
    ): React.ReactNode {
        return <div className={"container-fluid"}>
            <div className="row">
                <div className="col-md-6">
                    <ConfigBlock
                        title="Titel"
                        description="Geef een titel in voor dit behandelingstype."
                        topMost={true}
                    >
                        {titlePCLF}
                    </ConfigBlock>

                    <ConfigBlock
                        title="Prijs per trede voor trap"
                        description="Geef een prijs per trede in voor dit behandelingstype."
                        topMost={false}
                    >
                        {staircaseFactorMI}
                    </ConfigBlock>
                    <ConfigBlock
                        title="Prijs per lopende meter voor overloop"
                        description="Geef een prijs per lopende meter in voor dit behandelingstype."
                        topMost={false}
                    >
                        {landingStageFactorMI}
                    </ConfigBlock>
                </div>
                <div className="col-md-6">
                    <ConfigBlock
                        title="Omschrijving"
                        description="Geef een omschrijving in voor dit behandelingstype."
                        topMost={true}
                    >
                        {descriptionPCLF}
                    </ConfigBlock>

                    <ConfigBlock
                        title="Prijs per trede voor leuning"
                        description="Geef een prijs per trede in voor dit behandelingstype."
                        topMost={false}
                    >
                        {handrailFactorMI}
                    </ConfigBlock>

                    <ConfigBlock
                        title="Prijs per lopende meter voor muurleuning"
                        description="Geef een prijs per lopende meter in voor dit behandelingstype."
                        topMost={false}
                    >
                        {wallHandrailFactorMI}
                    </ConfigBlock>

                </div>
            </div>
        </div>;
    }

}
