exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._7u2CTU3M-rRLbsjXBqPrt{\n    background-color: #FFCB04;\n    color: #3F3F3F;\n    border-radius: 8px;\n}\n._7u2CTU3M-rRLbsjXBqPrt p{\n    font-size: 14px;\n    color: #3F3F3F;\n}\n._7u2CTU3M-rRLbsjXBqPrt:hover{\n    background-color: #f2c004;\n}\n._1Lp-PqHaEB_ufuohklQbN_{\n    background-color: #FFCB04;\n    color: #3F3F3F;\n}", ""]);

// exports
exports.locals = {
	"item": "_7u2CTU3M-rRLbsjXBqPrt",
	"active": "_1Lp-PqHaEB_ufuohklQbN_"
};