import React from 'react';
import IUniformNode from '../../../../../shared/quote-rendering/IUniformNode';
import { IFooterNodeProps } from '../../../../../shared/quote-rendering/UniformNodeFactory';

export default class FooterNode implements IUniformNode<React.ReactNode>{
    
    constructor(
        private readonly props: IFooterNodeProps
    ) {}

    renderWithManualKey() {
        return this.render(""); 
    }

    render(seedKey:string) {
        return "";
    }

}