import * as React from "react";
import styleable from "react-styleable";

import * as styles from "../../../css/components/Overview/QuotesOverview.css";
import * as generalStyles from "../../../css/general.css";

import TextWithIcon from "webc-reactcore/src/js/components/mainlayout/TextWithIcon";
import StyledBox from "../StyledBox";
import QuoteRow from "./QuoteRow";
import GeneralTable from "../GeneralTable";

import {t} from 'ttag';
import { tQuoteMinified } from "../../../../../shared/datastructures/simplifiedNetworkCommunicationTypes";
import { connect } from "react-redux";
import { loadQuote } from "../../pages/newquote/article/loaders/loaderHelper";
import { withRouter } from "react-router-dom";
import store from "../../store";
import { retrieveMinifiedQuotes, retrieveMoreMinifiedQuotes } from "../../actions/globalInformationActions";
import { cloneQuote } from "../../apicontroller/quote";

// TODO: fix height of the styled box and how it scales

/**
 * Quotes overview.
 */
@(connect((store: any) => {
    return {globalInformation: {...store.globalInformation}};
}) as any)
class QuotesOverview extends React.Component<any> {

    private quotesToQuoteRowComponents(quotes: tQuoteMinified[]): React.ReactNode[]{
        const quoteRowComponents: React.ReactNode[] = [];
        for(const quote of quotes/*.sort((a, b) => a.lastUpdateDate && b.lastUpdateDate ? b.lastUpdateDate.getTime() - a.lastUpdateDate.getTime(): 0)*/){
            quoteRowComponents.push(
                <QuoteRow
                    key={quote.id._id.toHexString()}
                    quote={quote}
                    onClick={async (q: tQuoteMinified) => {
                        await loadQuote(q.id._id);
                        this.props.history.push('/quote/1');
                    }}
                    onDuplicate={async (q: tQuoteMinified) => {
                        await cloneQuote(q.id._id);
                        store.dispatch(await retrieveMinifiedQuotes());
                    }}

                    status={quote.quoteState}
                    customerName={quote.customerName}
                    variants={[]}
                    creationDate={quote.creationDate}
                />
            );
        }

        return quoteRowComponents;
    }

    public render() {
        const handleScroll = async (e) => {
            if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
                if(this.props.globalInformation.moreQuotesAvailable)
                    store.dispatch(await retrieveMoreMinifiedQuotes(this.props.globalInformation.quotesLoaded))
            }
        }
        return (
            <StyledBox css={{compose: {container: styles.styledBoxContainer}}}>
                <TextWithIcon icon="/cdn/portal/assets/icons/pen-write-1.svg" text={t`offertes.`} css={{compose: {container: generalStyles.textWithIconContainer}}}/>
                <div style={{
                        maxHeight: "calc(100vh - 220px)",
                        overflowY: "auto",
                        borderStyle: "solid",
                        borderColor: "#F5F5F5",
                        borderWidth: "1px",
                    }}
                    onScroll={handleScroll}
                >
                    <GeneralTable>
                        {(()=>{
                            return this.quotesToQuoteRowComponents(this.props.globalInformation.filteredQuotes);
                        })()}
                        {this.props.globalInformation.filteredQuotes.length === 0 ? <p style={{textAlign: 'center', paddingTop: '10px'}}>{t`Geen offertes gevonden.`}</p> : ""}
                    </GeneralTable>
                    {(() => {
                        if(this.props.globalInformation.moreQuotesAvailable){
                            return <button style={{margin: '20px', marginLeft: 'auto', marginRight: 'auto', display: 'block'}} onClick={async () => {
                                store.dispatch(await retrieveMoreMinifiedQuotes(this.props.globalInformation.quotesLoaded))
                            }}>Meer laden</button>
                        }
                    })()}
                </div>
            </StyledBox>
        );
    }
}
export default styleable(styles)(withRouter(QuotesOverview));
