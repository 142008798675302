import React from 'react';
import IUniformNode, { deepenKey } from '../../../../../shared/quote-rendering/IUniformNode';
import { IViewNodeProps } from '../../../../../shared/quote-rendering/UniformNodeFactory';

export default class ViewNode implements IUniformNode<React.ReactNode>{
    
    constructor(
        private readonly props: IViewNodeProps<React.ReactNode>
    ) {}

    renderWithManualKey() {
        return <div key={this.props.key} style={this.props.style} >
            {this.props.children.map(x => x.renderWithManualKey())}
        </div>;
    }

    render(seedKey: string){
        return <div key={seedKey} style={this.props.style} >
            {this.props.children.map((x,i) => x.render(deepenKey(seedKey, i)))}
        </div>;
    }

}