exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".dM2xpBwEJteb-SC3BKOpI{\n    display: inline;\n    background-color: #FFCB04;\n    color: #3F3F3F;\n    border-radius: 8px;\n    padding-left: 10px;\n    padding-right: 10px;\n    margin-right: 10px;\n    font-weight: bold;\n}\n._2p6LGMsTa5hGXyWDKH1Vln{\n    border-style: solid;\n    border-color: #FFCB04;\n    border-radius: 8px;\n    border-width: 1px;\n    background-color: #fff9e0;\n    padding: 10px;\n    margin-bottom: 10px;\n}\n._3ZO6lwd9sxiIixNKfDn64r{\n    text-align: left;\n}\n._2gC6KmtojD_2-VO5I8zfJE{\n    display: inline;\n    color: #3F3F3F;\n}", ""]);

// exports
exports.locals = {
	"warningLabel": "dM2xpBwEJteb-SC3BKOpI",
	"warningContainer": "_2p6LGMsTa5hGXyWDKH1Vln",
	"warningInnerContainer": "_3ZO6lwd9sxiIixNKfDn64r",
	"warningMessage": "_2gC6KmtojD_2-VO5I8zfJE"
};