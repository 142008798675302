import React from 'react';
import IUniformNode from '../../../../shared/quote-rendering/IUniformNode';
import UniformNodeFactory, { IBoldNodeProps, IBulletNodeProps, IDocumentNodeProps, IFooterNodeProps, IImageNodeProps, IItalicNodeProps, ILinkNodeProps, IPageNodeProps, ISpaceNodeProps, ISplitViewNodeProps, IStringNodeProps, ITextNodeProps, ITitleNodeProps, IViewNodeProps } from '../../../../shared/quote-rendering/UniformNodeFactory';
import BoldNode from './nodes/BoldNode';
import BulletNode from './nodes/BulletNode';
import DocumentNode from './nodes/DocumentNode';
import FooterNode from './nodes/FooterNode';
import ImageNode from './nodes/ImageNode';
import ItalicNode from './nodes/ItalicNode';
import LinkNode from './nodes/LinkNode';
import NoneNode from './nodes/NoneNode';
import PageNode from './nodes/PageNode';
import SpaceNode from './nodes/SpaceNode';
import SplitViewNode from './nodes/SplitViewNode';
import StringNode from './nodes/StringNode';
import TextNode from './nodes/TextNode';
import TitleNode from './nodes/TitleNode';
import ViewNode from './nodes/ViewNode';

export default class ServerNodeFactory extends UniformNodeFactory<React.ReactNode>{

    public _createNoneNode(): IUniformNode<React.ReactNode> {
        return new NoneNode();
    }
    public _createDocumentNode(props: IDocumentNodeProps<React.ReactNode>): IUniformNode<React.ReactNode> {
        return new DocumentNode(props);
    }
    public _createPageNode(props: IPageNodeProps<React.ReactNode>): IUniformNode<React.ReactNode> {
        return new PageNode(props);
    }
    public _createTitleNode(props: ITitleNodeProps<React.ReactNode>): IUniformNode<React.ReactNode> {
        return new TitleNode(props);
    }
    public _createTextNode(props: ITextNodeProps<React.ReactNode>): IUniformNode<React.ReactNode> {
        return new TextNode(props);
    }
    public _createBulletNode(props: IBulletNodeProps<React.ReactNode>): IUniformNode<React.ReactNode> {
        return new BulletNode(props);
    }
    public _createBoldNode(props: IBoldNodeProps<React.ReactNode>): IUniformNode<React.ReactNode> {
        return new BoldNode(props);
    }
    public _createItalicNode(props: IItalicNodeProps<React.ReactNode>): IUniformNode<React.ReactNode> {
        return new ItalicNode(props);
    }
    public _createLinkNode(props: ILinkNodeProps<React.ReactNode>): IUniformNode<React.ReactNode> {
        return new LinkNode(props);
    }
    public _createStringNode(props: IStringNodeProps): IUniformNode<React.ReactNode> {
        return new StringNode(props);
    }
    public _createViewNode(props: IViewNodeProps<React.ReactNode>): IUniformNode<React.ReactNode> {
        return new ViewNode(props);
    }
    public _createSplitViewNode(props: ISplitViewNodeProps<React.ReactNode>): IUniformNode<React.ReactNode> {
        return new SplitViewNode(props);
    }
    public _createSpaceNode(props: ISpaceNodeProps): IUniformNode<React.ReactNode> {
        return new SpaceNode(props);
    }
    public _createImageNode(props: IImageNodeProps): IUniformNode<React.ReactNode> {
        return new ImageNode(props);
    }
    public _createFooterNode(props: IFooterNodeProps): IUniformNode<React.ReactNode> {
        return new FooterNode(props);
    }

}