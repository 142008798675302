import ObjectID from "bson-objectid";
import { IDatabaseController } from "orbiter-core/src/databasecontroller/DatabaseController";
import IArticleInfoStore from "../../../../../../../shared/datastructures/articleinput/IArticleInfoStore";
import { IDistributor } from "../../../../../../../shared/datastructures/Distributor";
import { IThickness } from "../../../../../../../shared/datastructures/staircasedata/Thickness";
import { IThicknessRule } from "../../../../../../../shared/datastructures/staircasedata/ThicknessRule";
import DistributorAPI from "../../../../apicontroller/DistributorAPI";
import BaseConstantSetAPI from "../../../../apicontroller/staircasedata/BaseConstantSetAPI";
import { RiserThicknessAPI } from "../../../../apicontroller/staircasedata/ThicknessAPI";
import store from "../../../../store";

export default class PortalArticleInfoStore implements IArticleInfoStore{
    
    getBaseConstantSet(): BaseConstantSetAPI{
        return (store.getState().quoteCreation).baseConstantSet;
    }

    getThicknessRules(): (IThicknessRule & IDatabaseController)[]{
        return (store.getState().quoteCreation).availableThicknessRules;
    }

    getRiserThickness(id: ObjectID): (IThickness & IDatabaseController){
        return ((store.getState().quoteCreation).availableRiserThickness as RiserThicknessAPI[]).find(x => x.getId().toHexString() === id.toHexString());
    }

    getDistributor(id: ObjectID): (IDistributor & IDatabaseController){
        const currentDistributor: DistributorAPI = (store.getState().quoteCreation).distributor;
        if(currentDistributor?.getId()?.toHexString() === id.toHexString()){
            return currentDistributor; // In practice we should only need the currently selected distributor when calculating price
        }
        return null;
    }

}