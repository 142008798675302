import * as React from "react";
import SettingsTemplate from "./SettingsTemplate";
import { inu } from "orbiter-core/src/basic";
import Select from "webc-reactcore/src/js/components/mainlayout/PortalSelect";
import * as artworkStyle from "../../../css/artwork.css";
import * as PortalSelectStyle from "../../../css/PortalSelect.css";
import { t } from 'ttag';
import { ToolbarMgr } from "webc-reactcore/src/js/components/toolbar/ToolbarContextProvider";
import ToolbarSeparator from "webc-reactcore/src/js/components/toolbar/ToolbarSeparator";
import {ModifiedSimpleToolbarItem as SimpleToolbarItem} from "../../components/toolbar";
import ApiController from "../../apicontroller/ApiController";
import DataController from "orbiter-core/src/datastructures/DataController";
import InfoScreen from "../../components/InfoScreen";
import { startLoading, stopLoading } from "../newquote/article/loaders/loaderHelper";

interface SimpleSettingsTemplateProps<T extends ApiController<D>, D extends DataController> {
    selectItems?: JSX.Element[],
    customSelect?: (selectedItem: T, select: (item: T) => void) => React.ReactNode,

    title: string,

    excludeSettingsTemplate?: boolean,

    reload: () => void,
    createEmpty: () => Promise<T>,
    create: (data? : D) => Promise<T>,
    clone: (i: T) => T,

    infoScreen?: React.ReactNode,

    renderCreate: (item: D, setSelected: (i: D) => void) => React.ReactNode,
    renderEdit: (item: T, setSelected: (i: T) => void) => React.ReactNode,
}

export default class SimpleSettingsTemplate<T extends ApiController<D>, D extends DataController> extends React.Component<SimpleSettingsTemplateProps<T, D>, any> {

    constructor(_){
        super(_);
        this.state = {
            selectedItem: undefined,
            creating: false,
            itemBeingCreated: undefined,
            modified: false,
        }
    }

    private async startCreating(){
        if(this.warningIfModified()){
            this.setState({
                selectedItem: undefined,
                creating: true,
                itemBeingCreated: await this.props.createEmpty(),
            });
        }
    }

    public async create() {
        startLoading();
        try{
            const selected: D = this.state.itemBeingCreated;
            await this.props.create(selected);
            this.setState({creating: false, itemBeingCreated: undefined, modified: false,});
            await this.props.reload();
        }finally{
            stopLoading();
        }
    }

    public async cancelCreate() {
        this.setState({creating: false, itemBeingCreated: undefined, modified: false,});
    }

    public async update() {
        startLoading();
        try{
            const selected: T = this.state.selectedItem;
            await selected.pushUpdate();
            await this.props.reload();
            this.setState({modified: false,})
        }finally{
            stopLoading();
        }
    }

    public async delete() {
        startLoading();
        try{
            await this.state.selectedItem.archive();
            this.setState({
                selectedItem: undefined,
            });
            await this.props.reload();
        }finally{
            stopLoading();
        }
    }

    private async removeToolbarItems(ctx){
        await ctx.removeToolbarItems([...this.toolbarItems]);
        this.toolbarItems = [];
    }

    private warningIfModified(): boolean{
        return this.state.modified ? confirm("Je laatste wijzigingen zijn niet opgeslagen. Wil je verder gaan?") : true;
    }

    private toolbarItems = [];

    private changeSelection(item: T){
        if(this.warningIfModified()){
            this.setState({ 
                selectedItem: item === null ? undefined : this.props.clone(item),
                modified: false,
            });
        }
    }

    public render() {

        const selectItems = this.props.selectItems;

        let subrender;
        if(this.state.selectedItem !== undefined){
            subrender = this.subrenderEdit();
        }else if(this.state.itemBeingCreated !== undefined && this.state.creating === true){
            subrender = this.subrenderCreate();
        }else{
            subrender = (
                <div>
                    <ToolbarMgr onMount={(ctx) => {
                        this.removeToolbarItems(ctx);
                    }} />
                    {this.props.infoScreen === undefined ? <InfoScreen title={t`Maak uw selectie.`} description="" image={"/cdn/images/wand.svg"}/> : this.props.infoScreen}
                    {/* <img className={artworkStyle.img} src={"/cdn/images/woodtype-select." + t`locale` + ".svg"} /> */}
                </div>
            );
        }

        const inner = <div>
            <ToolbarMgr onMount={async (ctx) => {
                    this.toolbarItems.push(await ctx.addToolbarItem(<SimpleToolbarItem onClick={()=>this.startCreating()}>{t`Nieuw`}</SimpleToolbarItem>));
                }} />
                {selectItems ? 
                <div className={"container-fluid"}>
                    <div className="row">
                        <div className="col-md-4">
                            <Select placeholder={this.props.title} key={"_select"} css={{ compose: PortalSelectStyle }}
                                onSelectionChange={(data) => {
                                        this.changeSelection(data.item);
                                }} selectedId={inu(this.state.selectedItem, (a) => a.getSid())}>
                                {selectItems}
                            </Select>
                        </div>
                        <div className="col-md-8">
                                {subrender}
                        </div>
                    </div>
                </div>
                :
                <div className={"container-fluid"}>
                    <div className="row">
                        <div className="col-md-12">
                            {this.props.customSelect ? this.props.customSelect(this.state.selectedItem, this.changeSelection.bind(this)) : ""}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {subrender}
                        </div>
                    </div>
                </div>
                }
        </div>;

        return this.props.excludeSettingsTemplate === true ? (
            <div>
                {inner}
            </div>
        ) : (
            <SettingsTemplate title={this.props.title.toLowerCase() + (this.props.title.endsWith(".") ? "" : ".")}>
                {inner}
            </SettingsTemplate>
        );
    }

    private subrenderEdit() {
        return (<span>
            
            <ToolbarMgr key="edit" onMount={async (ctx) => {
                await this.removeToolbarItems(ctx);
                this.toolbarItems.push(await ctx.addToolbarItem(<ToolbarSeparator/>));
                this.toolbarItems.push(await ctx.addToolbarItem(<SimpleToolbarItem onClick={this.update.bind(this)}>{t`Opslaan`}</SimpleToolbarItem>));
                this.toolbarItems.push(await ctx.addToolbarItem(<SimpleToolbarItem onClick={() => {
                    if(confirm("Weet je zeker dat je dit item wilt verwijderen?")){
                        this.delete.bind(this)();
                    }
                }}>{t`Verwijderen`}</SimpleToolbarItem>));
            }} />

            {this.props.renderEdit(this.state.selectedItem, (i) => this.setState({selectedItem: i, modified: true,}))}
            
        </span>);
    }

    
    private subrenderCreate() {
        return (<span>
                <ToolbarMgr key="create" onMount={async (ctx) => {
                    await this.removeToolbarItems(ctx);
                    this.toolbarItems.push(await ctx.addToolbarItem(<ToolbarSeparator/>));
                    this.toolbarItems.push(await ctx.addToolbarItem(<SimpleToolbarItem onClick={this.create.bind(this)}>{t`Toevoegen`}</SimpleToolbarItem>));
                    this.toolbarItems.push(await ctx.addToolbarItem(<SimpleToolbarItem onClick={this.cancelCreate.bind(this)}>{t`Annuleren`}</SimpleToolbarItem>));
                }} />

                {this.state.itemBeingCreated !== undefined ? this.props.renderCreate(this.state.itemBeingCreated, (i) => this.setState({itemBeingCreated: i, modified: true,})) : ""}

        </span>);
    }
}
