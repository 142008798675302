import IArticleInput, { IShapeInput, IHandrailInput, ILandingStageInput, ITreatmentInput, ICommentInput, IExtraHandrailInput, IWallHandrailInput, ITransportationInput } from "../../../../shared/datastructures/IArticleInput";
import HandrailType, { IHandrailType } from "../../../../shared/datastructures/staircasedata/HandrailType";
import { IDatabaseController } from "orbiter-core/src/databasecontroller/DatabaseController";
import { ISpindleThreadType } from "../../../../shared/datastructures/staircasedata/SpindleThreadType";
import { IHandrailHandle } from "../../../../shared/datastructures/staircasedata/HandrailHandle";
import CommentInput from "../../../../shared/datastructures/articleinput/CommentInput";

export function removeInformation(){
    return {
        type : "REMOVE_ARTICLE_CREATION_INFORMATION"
    }
}

export function setBasicInformation(information){
    return {
        type : "SET_ARTICLE_CREATION_BASIC_INFORMATION",
        payload: information
    }
}

export function setArticle(article: IArticleInput){
    return {
        type : "SET_ARTICLE",
        payload: article,
    }
}

export function setArticleShape(shape: IShapeInput){
    return {
        type : "SET_ARTICLE_SHAPE",
        payload: shape
    }
}

export function setArticleTransportation(shape: ITransportationInput){
    return {
        type : "SET_ARTICLE_TRANSPORTATION",
        payload: shape
    }
}

export function setArticleComments(comments: ICommentInput[]){
    return {
        type : "SET_ARTICLE_COMMENTS",
        payload: comments
    }
}
export function setArticleHandrail(handrail: IHandrailInput){
    return {
        type : "SET_ARTICLE_HANDRAIL",
        payload: handrail
    }
}
export function setArticleExtraHandrail(handrail: IExtraHandrailInput){
    return {
        type : "SET_ARTICLE_EXTRA_HANDRAIL",
        payload: handrail
    }
}
export function setArticleWallHandrail(handrail: IWallHandrailInput){
    return {
        type : "SET_ARTICLE_WALL_HANDRAIL",
        payload: handrail
    }
}
export function setArticleLandingStage(landingStage: ILandingStageInput){
    return {
        type : "SET_ARTICLE_LANDING_STAGE",
        payload: landingStage
    }
}
export function setArticleLandingStageHandrail(landingStageHandrail: IHandrailInput){
    return {
        type : "SET_ARTICLE_LANDING_STAGE_HANDRAIL",
        payload: landingStageHandrail
    }
}
export function setArticleTreatment(treatment: ITreatmentInput){
    return {
        type : "SET_ARTICLE_TREATMENT",
        payload: treatment
    }
}
export function addArticleTreatmentColor(color: string){
    return {
        type : "ADD_ARTICLE_TREATMENT_COLOR",
        payload: color
    }
}
export function removeArticleTreatmentColor(color: string){
    return {
        type : "REMOVE_ARTICLE_TREATMENT_COLOR",
        payload: color
    }
}
export function setLandingStageHandrailType(handrailType: IHandrailType & IDatabaseController){
    return {
        type: "SET_LANDING_STAGE_HANDRAIL_TYPE",
        payload: handrailType,
    }
}
export function setLandingStageHandrailTypeAndSpindleThreadTypeAndHandrailHandle(handrailType: IHandrailType & IDatabaseController, spindleThreadType: ISpindleThreadType & IDatabaseController, spindleThreadDescription: string, handrailHandle: IHandrailHandle & IDatabaseController){
    return {
        type: "SET_LANDING_STAGE_HANDRAIL_TYPE_AND_SPINDLE_THREAD_TYPE_AND_HANDRAIL_HANDLE",
        payload: {handrailType, spindleThreadType, spindleThreadDescription, handrailHandle},
    }
}
export function disableLandingStageHandrail(){
    return {
        type: "DISABLE_LANDING_STAGE_HANDRAIL",
    }
}

export function disableConcreteEnclosure(){
    return {
        type: "DISABLE_CONCRETE_ENCLOSURE",
    }
}

export function disableHandrail(){
    return {
        type: "DISABLE_HANDRAIL",
    }
}

export function enableExtraHandrail(){
    return {
        type: "ENABLE_EXTRA_HANDRAIL",
    }
}

export function disableExtraHandrail(){
    return {
        type: "DISABLE_EXTRA_HANDRAIL",
    }
}

export function enableWallHandrail(){
    return {
        type: "ENABLE_WALL_HANDRAIL",
    }
}

export function disableWallHandrail(){
    return {
        type: "DISABLE_WALL_HANDRAIL",
    }
}

export function disableTreatment(){
    return {
        type: "DISABLE_TREATMENT",
    }
}

export function setStaircasePlacementOptionsEqual(equality: boolean){
    return {
        type: "SET_STAIRCASE_PLACEMENT_OPTIONS_EQUAL",
        payload: equality,
    }
}