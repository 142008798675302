import { ITreatmentInput } from "../IArticleInput";
import { ITreatmentType } from "../staircasedata/TreatmentType";
import { IDatabaseController } from "orbiter-core/src/databasecontroller/DatabaseController";
import { inu, isDefined } from "orbiter-core/src/basic";
import { INPUT_NOT_DEFINED } from "./inputExceptions";

const _ = undefined;

/**
 * Immutable treatment input class.
 */
export default class TreatmentInput implements ITreatmentInput{

    constructor(
        readonly type: ITreatmentType & IDatabaseController = null,
        readonly colors: any[] = [], // TODO: change
    ){}

    static from(treatmentInput: ITreatmentInput): TreatmentInput{
        if(treatmentInput === null){
            return null;
        }
        return new TreatmentInput(
            treatmentInput.type,
            treatmentInput.colors,
        ).clone();
    }

    static validityCheck(treatment: ITreatmentInput): void{
        if(treatment === null){
            return;
        }
        this.typeValidityCheck(treatment.type);
    }

    static typeValidityCheck(type: ITreatmentType & IDatabaseController){
        if(!isDefined(type))
            throw INPUT_NOT_DEFINED;
    }

    /*
        TODO: implement colors validity check
    */

    clone(
        type?: ITreatmentType & IDatabaseController,
        colors?: any[], // TODO: change
    ):TreatmentInput{
        return new TreatmentInput(
            inu(type, ()=>type, ()=>this.type),
            inu(colors, ()=>[...colors], ()=>[...this.colors]),
        )
    }

    setType(type: ITreatmentType & IDatabaseController): TreatmentInput{
        return this.clone(type);
    }

    setColors(colors: any[]): TreatmentInput{
        return this.clone(_, colors);
    }

    addColor(color: any): TreatmentInput{
        return this.clone().setColors([...this.colors, color]);
    }

}