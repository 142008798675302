declare var require: any;

import {addLocale, LocaleData, useLocale} from "ttag";
import I18n from "webc-reactcore/src/js/i18n";

/**
 * 	@class ProjectI18n (Internationalisation)
 * 	@extends I18n (ReactCore class)
 *
 * 	Adds internationalisation & multiple languages to an app.
 *
 * 	See parent class for all documentation
 *
 * 	Overrides 2 functions that need to be overridden
 *
 *  To use this: call, at the begin of the application:
 *      const availableLanguages = ['en', 'nl'];                    // list all available locales for the app
 *      const i18n = new ProjectI18n(availableLanguages, "nl");     // second parameter is the fallback language. Default is en (English)
 *      i18n.load("snif-browser");                                  // in the load parameter a preferred locale can be set, if empty an educated guess is made
 *
 *  When adding an extra locale,
 *  1. use the ttag functions (init, update, transform) as described in the documentation of the parent class
 *  2. add the new locale to the availableLanguages array as mentioned above
 *
 * 	One extra note:
 * 	declare of require above is needed because these .json files are "require"d into the code.
 * 		If there is a better way, feel free to improve!
 */
class ProjectI18n extends I18n
{
    /**
     * Constructor: just call the parent constructor!
     *
     * @param string[] availableLanguages An array of locales the app is available in
     * @param string fallback The fallback locale if no valid one is found
     */
    public constructor(availableLanguages: string[] = [], fallback: string = "en")
    {
        super(availableLanguages, fallback);
    }

    /**
     * Load the correct locale & translations.
     * See parent load() function for all documentation.
     *
     * Needs to call the addLocale & useLocale here as well, or the project's translations won't be available in the app
     *
     * @param string preferredLocale The preferred locale (one of the values in this.available, or "snif-browser", in which case the browser will be probed
     * @return locale
     */
    public load(preferredLocale: string = null): string
    {
        // call the parent, it handles everything
        super.load(preferredLocale);

        // call these functions, or else the translations for the project won't be available
        addLocale(this.locale, this.translations);
        useLocale(this.locale);
        return this.locale;
    }

    /**
     * Load translations from the Project for the given locale, and return them.
     *
     * @param locale The locale to load the translations for
     * @return LocaleData The found LocaleData (including translations), or null upon failure
     */
    protected loadProjectTranslationsFromFile(locale: string): LocaleData
    {
        try
        {
            // try to load the json file containing all locale data & translations
            return require(`./../i18n/compiled/${locale}.json`);
        }
        catch (e)
        {
            // if loading failed (due to file not found for example): we might be using the fallback, which is OK
            return null;
        }
    }
}

export default new ProjectI18n();