import * as React from "react";
import styleable from "react-styleable";

import * as styles from "../../css/pages/Overview.css";

import QuotesOverview from "../components/Overview/QuotesOverview";
import SearchBox from "../components/Overview/SearchBox";

/**
 * Overview page.
 */
class Overview extends React.Component {
    public render() {
        return (
            <div className={"container-fluid " + styles.container}>
                <div className="row">
                    <div className="col-md-3">
                        <SearchBox/>
                    </div>
                    <div className="col-md-9">
                        <QuotesOverview/>
                    </div>
                </div>
            </div>
        );
    }
}
export default styleable(styles)(Overview);
