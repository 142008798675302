export function convertToNearestHalveMeter(value: number): number{
    let res = value;
    const floating = res - Math.floor(res);

    // Round up to the nearest 0.5m
    if (floating < 0.5 && floating !== 0)
        res = Math.floor(res) + 0.5;
    else if (floating > 0.5)
        res = Math.floor(res) + 1;

    return res;
}