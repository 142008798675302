import { IDistributor } from "../datastructures/Distributor";
import { IBaseConstantSet } from "../datastructures/staircasedata/BaseConstantSet";
import { SubCalculation } from "./subCalculations";

export enum MarginFactorType {
    STAIRCASE,
    TREATMENT,
    DISTRIBUTION
}

export type DiscountPercentages = {
    discountPercentageStaircase: number,
    discountPercentageTreatment: number,
    discountPercentageDistribution: number,
}

async function getMarginFactors(
    bcs: IBaseConstantSet,
    distributor: IDistributor,
    discountPercentages: DiscountPercentages,
    type: MarginFactorType,
): Promise<{ globalMarginFactor, hotecMarginFactor, hotecDealerDiscountFactor, dealerMarginFactor, articleDiscountFactor }> {
    let globalMarginFactor: number = 1;
    let hotecMarginFactor: number = 1;
    let hotecDealerDiscountFactor: number = 1;
    let dealerMarginFactor: number = 1;
    let articleDiscountFactor: number = 1;


    if (distributor?.shouldApplyGlobalMarginForDealers()) {
        globalMarginFactor = 1 + bcs.getGlobalMarginForDealers() / 100;
    }

    switch (type) {
        case MarginFactorType.STAIRCASE: {
            if (distributor?.shouldApplyHotecMargin()) {
                hotecMarginFactor = 1 + distributor.getHotecMarginStaircase() / 100;
                hotecDealerDiscountFactor = 1 - distributor.getHotecDiscountStaircase() / 100;
            }
            if (distributor?.shouldApplyDealerMargin()) {
                dealerMarginFactor = 1 + distributor.getDealerMarginStaircase() / 100;
            }
            if (discountPercentages.discountPercentageStaircase > 0) {
                articleDiscountFactor = 1 - discountPercentages.discountPercentageStaircase / 100;
            }
            break;
        }
        case MarginFactorType.DISTRIBUTION: {
            if (distributor?.shouldApplyHotecMargin()) {
                hotecMarginFactor = 1 + distributor.getHotecMarginDistribution() / 100;
                hotecDealerDiscountFactor = 1 - distributor.getHotecDiscountDistribution() / 100;
            }
            if (distributor?.shouldApplyDealerMargin()) {
                dealerMarginFactor = 1 + distributor.getDealerMarginDistribution() / 100;
            }
            if (discountPercentages.discountPercentageDistribution > 0) {
                articleDiscountFactor = 1 - discountPercentages.discountPercentageDistribution / 100;
            }
            break;
        }
        case MarginFactorType.TREATMENT: {
            if (distributor?.shouldApplyHotecMargin()) {
                hotecMarginFactor = 1 + distributor.getHotecMarginTreatment() / 100;
                hotecDealerDiscountFactor = 1 - distributor.getHotecDiscountTreatment() / 100;
            }
            if (distributor?.shouldApplyDealerMargin()) {
                dealerMarginFactor = 1 + distributor.getDealerMarginTreatment() / 100;
            }
            if (discountPercentages.discountPercentageTreatment > 0) {
                articleDiscountFactor = 1 - discountPercentages.discountPercentageTreatment / 100;
            }
            break;
        }
    }

    return {
        globalMarginFactor,
        hotecMarginFactor,
        hotecDealerDiscountFactor,
        dealerMarginFactor,
        articleDiscountFactor,
    }
}

export async function applyMarginFactor(
    bcs: IBaseConstantSet,
    distributor: IDistributor,
    discountPercentages: DiscountPercentages,
    base: number,
    type: MarginFactorType,
): Promise<SubCalculation> {
    const marginFactors = await getMarginFactors(bcs, distributor, discountPercentages, type);

    const hotecPriceBeforeDealerDiscount = base * marginFactors.globalMarginFactor * marginFactors.hotecMarginFactor;
    const hotecPriceAfterDealerDiscount = hotecPriceBeforeDealerDiscount * marginFactors.hotecDealerDiscountFactor;
    const totalPrice = hotecPriceBeforeDealerDiscount * marginFactors.dealerMarginFactor * marginFactors.articleDiscountFactor;

    return {
        base,
        hotecPriceBeforeDealerDiscount,
        hotecPriceAfterDealerDiscount,
        totalPrice,
    };
}