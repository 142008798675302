import * as React from "react";
import { inun, inu } from "orbiter-core/src/basic";
import { connect } from "react-redux";
import SubTitle from "../../components/SubTitle";
import { loadHandrailPoleTypes } from "../../loader";
import HandrailPoleTypeAPI from "../../apicontroller/staircasedata/HandrailPoleTypeAPI";
import PortalCollapsibleLanguageField from "webc-reactcore/src/js/components/mainlayout/PortalCollapsibleLanguageField";
import { t } from 'ttag';
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import { dt } from "webc-reactcore/src/js/stores/GlobalStore";
import HandrailPoleType from "../../../../../shared/datastructures/staircasedata/HandrailPoleType";
import SimpleSettingsTemplate from "./SimpleSettingsTemplate";
import { toSelectItems, createApiTitlePortalCollapsibleLanguageField, createApiDescriptionPortalCollapsibleLanguageField, createTitlePortalCollapsibleLanguageField, createDescriptionPortalCollapsibleLanguageField, toObjectSelectItems } from "./helper";
import ConfigBlock from "../../components/ConfigBlock";
import InfoScreen from "../../components/InfoScreen";
import MappedInput from "webc-reactcore/src/js/components/mainlayout/MappedInput";
import PortalDropdown from "webc-reactcore/src/js/components/mainlayout/PortalDropdown";
import * as PortalDropdownStyle from "../../../css/PortalDropdown.css";
import HandrailPoleFinishAPI from "../../apicontroller/staircasedata/HandrailPoleFinishAPI";

@(connect((store: any) => {
    return { settings: { ...store.settings }, globalInformation: { ...store.globalInformation } };
}) as any)
export default class HandrailPoleTypeSettings extends React.Component<any, any> {

    public handrailPoleTypesToSelectItems() {
        return toSelectItems<HandrailPoleTypeAPI>("handrailpoletype", this.props.globalInformation.handrailPoleTypes.sort((x, y) => dt(x.getTitle()) < dt(y.getTitle()) ? -1 : 1), (x) => HandrailPoleTypeAPI.clone(x), (x) => dt(x.getTitle()));
    }

    private async createEmpty(): Promise<HandrailPoleType> {
        return new HandrailPoleType(new MultiLanguageString(), new MultiLanguageString(), 0, null);
    }

    private async create(data: HandrailPoleType) {
        await HandrailPoleTypeAPI.create(data);
    }

    private handrailPoleFinishesToSelectItems() {
        return toObjectSelectItems<HandrailPoleFinishAPI>(this.props.globalInformation.handrailPoleFinishes.sort((x: HandrailPoleFinishAPI, y: HandrailPoleFinishAPI) => dt(x.getTitle()) < dt(y.getTitle()) ? -1 : 1), (x) => HandrailPoleFinishAPI.clone(x), (x) => dt(x.getTitle()));
    }

    public render() {
        return <SimpleSettingsTemplate
            title="Palen"
            selectItems={this.handrailPoleTypesToSelectItems()}

            reload={loadHandrailPoleTypes.bind(this)}
            createEmpty={this.createEmpty.bind(this)}
            create={this.create.bind(this)}
            clone={(i: HandrailPoleTypeAPI) => HandrailPoleTypeAPI.clone(i)}

            renderEdit={this.renderEdit.bind(this)}
            renderCreate={this.renderCreate.bind(this)}

            infoScreen={<InfoScreen title={t`Selecteer een paaltype.`} image={"/cdn/images/pole.svg"}/>}
        />;
    }

    private renderEdit(a: HandrailPoleTypeAPI, setSelected: (i: HandrailPoleTypeAPI) => void) {
        return this.doRender(
            createApiTitlePortalCollapsibleLanguageField(a, (x) => HandrailPoleTypeAPI.clone(x), setSelected.bind(this)),
            createApiDescriptionPortalCollapsibleLanguageField(a, (x) => HandrailPoleTypeAPI.clone(x), setSelected.bind(this)),
            <MappedInput placeholder={t`Prijs`}
                onBlur={(price) => {
                    const cl: HandrailPoleTypeAPI = HandrailPoleTypeAPI.clone(a);
                    try{
                        cl.getData().setPrice(price);
                    }catch(e){
                        cl.getData().setPrice(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getPrice(), () => "")} />,
            <PortalDropdown placeholder={t`Standaard bovenafwerking`}
                css={{ compose: PortalDropdownStyle }}
                items={this.handrailPoleFinishesToSelectItems()}
                onSelectionChange={async (data) => {
                    const cl: HandrailPoleTypeAPI = HandrailPoleTypeAPI.clone(a);
                    if(data){
                        cl.getData().setDefaultHandrailPoleFinishId((data.item as HandrailPoleFinishAPI).getId());
                    }else{
                        cl.getData().setDefaultHandrailPoleFinishId(null);
                    }
                    setSelected(cl);
                }}
                selectedId={inun(a, (a) => a.getDefaultHandrailPoleFinishId() ? a.getDefaultHandrailPoleFinishId().toHexString() : null, () => null)}
            />,
        );
    }

    private renderCreate(a: HandrailPoleType, setSelected: (i: HandrailPoleType) => void) {
        return this.doRender(
            createTitlePortalCollapsibleLanguageField(a, setSelected.bind(this)),
            createDescriptionPortalCollapsibleLanguageField(a, setSelected.bind(this)),
            <MappedInput placeholder={t`Prijs`}
                onBlur={(price) => {
                    const cl: HandrailPoleType = a.clone();
                    try{
                        cl.setPrice(price);
                    }catch(e){
                        cl.setPrice(0);
                    }
                    setSelected(cl);
                }}
                value={inu(a, (a) => a.getPrice(), () => "")} />,
            <PortalDropdown placeholder={t`Standaard bovenafwerking`}
                css={{ compose: PortalDropdownStyle }}
                items={this.handrailPoleFinishesToSelectItems()}
                onSelectionChange={async (data) => {
                    const cl: HandrailPoleType = a.clone();
                    if(data){
                        cl.setDefaultHandrailPoleFinishId((data.item as HandrailPoleFinishAPI).getId());
                    }else{
                        cl.setDefaultHandrailPoleFinishId(null);
                    }
                    setSelected(cl);
                }}
                selectedId={inun(a, (a) => a.getDefaultHandrailPoleFinishId() ? a.getDefaultHandrailPoleFinishId().toHexString() : null, () => null)}
            />,
        );
    }

    private doRender(
        titlePCLF: React.ReactNode,
        descriptionPCLF: React.ReactNode,
        priceMI: React.ReactNode,
        poleFinishSelection: React.ReactNode,
    ): React.ReactNode {
        return <div className={"container-fluid"}>
            <div className="row">
                <div className="col-md-6">
                    <ConfigBlock
                        title="Titel"
                        description="Geef een titel in voor deze paal."
                        topMost={true}
                    >
                        {titlePCLF}
                    </ConfigBlock>

                    <ConfigBlock
                        title="Prijs"
                        description="Geef een prijs in voor deze paal."
                        topMost={false}
                    >
                        {priceMI}
                    </ConfigBlock>
                </div>
                <div className="col-md-6">
                    <ConfigBlock
                        title="Omschrijving"
                        description="Geef een omschrijving in voor deze paal."
                        topMost={true}
                    >
                        {descriptionPCLF}
                    </ConfigBlock>

                    <ConfigBlock
                        title="Standaard bovenafwerking"
                        description="Kies een standaard bovenafwerking voor dit paaltype."
                        topMost={false}
                    >
                        {poleFinishSelection}
                    </ConfigBlock>
                </div>
            </div>
        </div>;
    }
}
