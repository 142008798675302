import * as React from "react";
import { inu } from "orbiter-core/src/basic";
import { connect } from "react-redux";
import { loadPermissions, loadDistributors } from "../../loader";
import { t } from 'ttag';
import ConfigBlock from "../../components/ConfigBlock";
import { toSelectItems, toObjectSelectItems, createDescriptionPortalCollapsibleLanguageField, createApiDescriptionPortalCollapsibleLanguageField } from "./helper";
import SimpleSettingsTemplate from "./SimpleSettingsTemplate";
import MappedInput from "webc-reactcore/src/js/components/mainlayout/MappedInput";
import InfoScreen from "../../components/InfoScreen";
import PermissionAPI from "../../apicontroller/PermissionAPI";
import Permission from "orbiter-core/src/datastructures/permissions/Permission";
import DistributorAPI from "../../apicontroller/DistributorAPI";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";

@(connect((store: any) => {
    return { settings: { ...store.settings }, globalInformation: { ...store.globalInformation } };
}) as any)
export default class Permissions extends React.Component<any, any> {

    public permissionsToSelectItems() {
        return toSelectItems<PermissionAPI>("permissionpselect", this.props.globalInformation.permissions.sort((x: PermissionAPI, y: PermissionAPI) => x.getName() < y.getName() ? -1 : 1), (x) => PermissionAPI.clone(x), (x) => x.getName());
    }

    public distributorsToSelectItems() {
        return toObjectSelectItems<DistributorAPI>(this.props.globalInformation.distributors.sort((x: DistributorAPI, y: DistributorAPI) => x.getName() < y.getName() ? -1 : 1), (x) => DistributorAPI.clone(x), (x) => x.getName());
    }

    private async createEmpty(): Promise<Permission> {
        return new Permission("", new MultiLanguageString());
    }

    private async create(data: Permission) {
        await PermissionAPI.create(data);
    }

    public render() {

        return <SimpleSettingsTemplate
            title={t`toegangsrechten.`}
            selectItems={this.permissionsToSelectItems()}

            reload={() => {
                loadPermissions();
                loadDistributors();
            }}
            createEmpty={this.createEmpty.bind(this)}
            create={this.create.bind(this)}
            clone={(i: PermissionAPI) => PermissionAPI.clone(i)}

            renderEdit={this.renderEdit.bind(this)}
            renderCreate={this.renderCreate.bind(this)}

            infoScreen={<InfoScreen title={t`Selecteer een machtiging.`} image={"/cdn/images/log.svg"}/>}
        />;

    }

    private renderEdit(a: PermissionAPI, setSelected: (i: PermissionAPI) => void) {
        return this.doRender(
            <MappedInput placeholder={t`Naam`} onBlur={(name) => {
                const cl: PermissionAPI = PermissionAPI.clone(a);
                try{
                    cl.getData().setName(name);
                }catch(e){
                    cl.getData().setName("");
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getName(), () => "")} />,
            createApiDescriptionPortalCollapsibleLanguageField(a, (x) => PermissionAPI.clone(x), setSelected.bind(this)),
        );

    }

    private renderCreate(a: Permission, setSelected: (i: Permission) => void) {
        return this.doRender(
            <MappedInput placeholder={t`Naam`} onBlur={(name) => {
                const cl: Permission = a.clone();
                try{
                    cl.setName(name);
                }catch(e){
                    cl.setName("");
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getName(), () => "")} />,
            createDescriptionPortalCollapsibleLanguageField(a, setSelected.bind(this)),
        );
    }

    private doRender(
        nameInput: React.ReactNode,
        descriptionInput: React.ReactNode,
    ) {
        return (<div className={"container-fluid"}>
            <div className="row">
                <div className="col-md-6">
                    <ConfigBlock
                        title={"Machtiging"}
                        description="Algemene informatie."
                        topMost={true}
                    >
                        {nameInput}
                        {descriptionInput}
                    </ConfigBlock>
                </div>
                <div className="col-md-6">
                    
                </div>
            </div>
        </div>);
    }
}
