exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1WdR5odDkM8hxDItQtnrV_{\n    margin-top: -20px;\n}\n\n._21ObgG5G70trHjWFZZRFjb{\n    overflow: hidden;\n    white-space: nowrap;\n    text-align: left;\n    color: #3F3F3F;\n    display: flex;\n    justify-content: right;\n    align-items: center;\n    height: 100%;\n    /* background: #3F3F3F;\n    background: -webkit-linear-gradient(to right, #3F3F3F 90%, #3f3f3f54 100%);\n    background: -moz-linear-gradient(to right, #3F3F3F 90%, #3f3f3f54 100%);\n    background: linear-gradient(to right, #3F3F3F 90%, #3f3f3f54 100%);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent; */\n}\n", ""]);

// exports
exports.locals = {
	"textWithIconContainer": "_1WdR5odDkM8hxDItQtnrV_",
	"commentPreview": "_21ObgG5G70trHjWFZZRFjb"
};