import ObjectID from "bson-objectid";
import {isValidBoolean, isValidId, isValidNumber} from "orbiter-core/src/basic";
import DataController from "orbiter-core/src/datastructures/DataController";
import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import {VALUE_EXCEPTION} from "orbiter-core/src/exceptions";

export interface IWoodType extends DataController {
    isMultiplex(): boolean;
    isAvailableForEnclosure(): boolean;
    isAvailableForRisers(): boolean;
    getPrice(): number;
    getTitle(): MultiLanguageString;
    getDescription(): MultiLanguageString;
    getHandrailDescription(): MultiLanguageString;
    getDefaultRiserThicknessId(): ObjectID | null;
    setMultiplex(val: boolean): void | Promise<void>;
    setAvailableForEnclosure(val: boolean): void | Promise<void>;
    setAvailableForRisers(val: boolean): void | Promise<void>;
    setPrice(newPrice: number): void | Promise<void>;
    setTitle(newTitle: MultiLanguageString): void | Promise<void>;
    setDescription(newDescription: MultiLanguageString): void | Promise<void>;
    setHandrailDescription(newDescription: MultiLanguageString): void | Promise<void>;
    setDefaultRiserThicknessId(id: ObjectID | null): void | Promise<void>;
}

export default class WoodType extends DataController implements IWoodType {

    @DataController.dataProperty()
    private multiplex: boolean;
    @DataController.dataProperty()
    private enclosure: boolean;
    @DataController.dataProperty()
    private risers: boolean;
    @DataController.dataProperty()
    private price: number;
    @DataController.dataProperty(async (x) => await x.asList())
    private title: MultiLanguageString;
    @DataController.dataProperty(async (x) => await x.asList())
    private description: MultiLanguageString;
    @DataController.dataProperty(async (x) => await x.asList())
    private handrailDescription: MultiLanguageString;
    @DataController.dataProperty()
    private defaultRiserThicknessId: ObjectID | null;

    public constructor(price: number, multiplex: boolean, enclosure: boolean, risers: boolean, title: MultiLanguageString, description: MultiLanguageString, handrailDescription: MultiLanguageString, defaultRiserThicknessId: ObjectID | null){
        super();
        this.setPrice(price);
        this.setMultiplex(multiplex);
        this.setAvailableForEnclosure(enclosure);
        this.setAvailableForRisers(risers);
        this.setTitle(title);
        this.setDescription(description);
        this.setHandrailDescription(handrailDescription);
        this.setDefaultRiserThicknessId(defaultRiserThicknessId);
    }

    public getTitle(): MultiLanguageString {
        return this.title;
    }

    public getDescription(): MultiLanguageString {
        return this.description;
    }

    public getHandrailDescription(): MultiLanguageString {
        return this.handrailDescription;
    }

    public getDefaultRiserThicknessId(): ObjectID {
        return this.defaultRiserThicknessId;
    }

    public getPrice(): number {
        return this.price;
    }

    public isMultiplex(): boolean {
        return this.multiplex;
    }

    public isAvailableForEnclosure(): boolean{
        return this.enclosure;
    }

    public isAvailableForRisers(): boolean{
        return this.risers;
    }

    public setTitle(newTitle: MultiLanguageString): void {
        this.title = newTitle;
    }

    public setDescription(newDescription: MultiLanguageString): void {
        this.description = newDescription;
    }

    public setHandrailDescription(newDescription: MultiLanguageString): void {
        this.handrailDescription = newDescription;
    }

    public setDefaultRiserThicknessId(riserThicknessId: ObjectID | null): void {
        if(!isValidId(riserThicknessId) && riserThicknessId != null) {
            throw VALUE_EXCEPTION;
        }
        this.defaultRiserThicknessId = riserThicknessId;
    }

    public setMultiplex(val: boolean): void {
        if(!isValidBoolean(val)) {
            throw VALUE_EXCEPTION;
        }
        this.multiplex = val;
    }

    public setAvailableForEnclosure(val: boolean): void{
        if(!isValidBoolean(val)) {
            throw VALUE_EXCEPTION;
        }
        this.enclosure = val;
    }

    public setAvailableForRisers(val: boolean): void{
        if(!isValidBoolean(val)) {
            throw VALUE_EXCEPTION;
        }
        this.risers = val;
    }

    public setPrice(newPrice: number): void {
        if(!isValidNumber(newPrice)) {
            throw VALUE_EXCEPTION;
        }
        this.price = Number(newPrice);
    }

    public clone(): WoodType{
        return new WoodType(
            this.getPrice(),
            this.isMultiplex(),
            this.isAvailableForEnclosure(),
            this.isAvailableForRisers(),
            this.getTitle().clone(),
            this.getDescription().clone(),
            this.getHandrailDescription().clone(),
            this.getDefaultRiserThicknessId(),
        );
    }

}
