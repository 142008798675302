import React from 'react';
import IUniformNode, { deepenKey } from '../../../../../shared/quote-rendering/IUniformNode';
import { ITitleNodeProps } from '../../../../../shared/quote-rendering/UniformNodeFactory';

export default class TitleNode implements IUniformNode<React.ReactNode>{
    
    constructor(
        private readonly props: ITitleNodeProps<React.ReactNode>
    ) {}

    private getStyle(){
        let fontSize = "16px";
        let paddingTop = this.props.paddingTop;

        if(this.props.h1){
            fontSize = this.props.h1FontSize;
        }else if(this.props.h2){
            fontSize = this.props.h2FontSize;
        }else if(this.props.h3){
            fontSize = this.props.h3FontSize;
        }else if(this.props.h4){
            fontSize = this.props.h4FontSize;
        }
        return {
            fontWeight: 'bold',
            fontSize,
            paddingTop,
        }
    }

    renderWithManualKey() {
        return <p key={this.props.key} style={this.getStyle() as any}>{this.props.children.map(x => x.renderWithManualKey())}</p>
    }

    render(seedKey: string){
        return <p key={seedKey} style={this.getStyle() as any}>{this.props.children.map((x,i) => x.render(deepenKey(seedKey, i)))}</p>
    }

}