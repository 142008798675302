import * as React from "react";
import { inu, inn, inun } from "orbiter-core/src/basic";
import { connect } from "react-redux";
import { loadUsers, loadDistributors } from "../../loader";
import { t } from 'ttag';
import ConfigBlock from "../../components/ConfigBlock";
import { toSelectItems, toObjectSelectItems, toSelectItemsWithDelete } from "./helper";
import SimpleSettingsTemplate from "./SimpleSettingsTemplate";
import MappedInput from "webc-reactcore/src/js/components/mainlayout/MappedInput";
import InfoScreen from "../../components/InfoScreen";
import HotecUserAPI from "../../apicontroller/HotecUserAPI";
import HotecUserBrowserStandin, { createDefaultHotecUserProperties } from "../../../../../shared/datastructures/HotecUserBrowserStandin";
import PortalDropdown from "webc-reactcore/src/js/components/mainlayout/PortalDropdown";
import * as PortalDropdownStyle from "../../../css/PortalDropdown.css";
import DistributorAPI from "../../apicontroller/DistributorAPI";
import PermissionAPI from "../../apicontroller/PermissionAPI";
import Select from "webc-reactcore/src/js/components/mainlayout/PortalSelect";
import * as PortalSelectStyle from "../../../css/PortalSelect.css";
import ObjectID from "bson-objectid";
import { dt } from "webc-reactcore/src/js/stores/GlobalStore";

@(connect((store: any) => {
    return { settings: { ...store.settings }, globalInformation: { ...store.globalInformation } };
}) as any)
export default class Users extends React.Component<any, any> {

    private permissionsToSelectItems(a: HotecUserBrowserStandin) {
        const unfilteredPermissions: PermissionAPI[] = this.props.globalInformation.permissions;
        const filteredPermissions = unfilteredPermissions.filter(x => a.getPermissions().findIndex( y => y.toHexString() == x.getId().toHexString()) == -1);
        const sortedPermissions = filteredPermissions.sort((x: PermissionAPI, y: PermissionAPI) => x.getName() < y.getName() ? -1 : 1);
        return toObjectSelectItems<PermissionAPI>(sortedPermissions, (x) => PermissionAPI.clone(x), (x) => `${dt(x.getDescription())} (${x.getName()})`);
    }

    private assignedPermissionsToSelectItems(a: HotecUserBrowserStandin, onDelete: (id: ObjectID) => void){
        const permissions: PermissionAPI[] =  a.getPermissions().map(x => this.props.globalInformation.permissions.find(y => y.getSid() === x.toString())).filter(x => x !== undefined);
        return toSelectItemsWithDelete<PermissionAPI>("assignedpermissionpselect", permissions.sort((x: PermissionAPI, y: PermissionAPI) => x.getName() < y.getName() ? -1 : 1), (x) => PermissionAPI.clone(x), (x) => `${dt(x.getDescription())} (${x.getName()})`, (x) => onDelete(x.getId()));
    }

    private assignPermission(a: HotecUserBrowserStandin, permission: PermissionAPI): void{
        const id = permission.getId();
        
        let newPermissions;
        if(a.getPermissions().find(x => x.toString() == id.toHexString())){
            // Already present
            newPermissions = [...a.getPermissions()];
        }else{
            // Not yet present
            newPermissions = [...a.getPermissions(), id];
        }

        a.setPermissions(newPermissions);
    }

    private removeAssignedPermission(a: HotecUserBrowserStandin, permissionId: ObjectID): void{
        a.setPermissions(a.getPermissions().filter(x => x.toHexString() != permissionId.toHexString()));
    }

    public usersToSelectItems() {
        return toSelectItems<HotecUserAPI>("userpselect", this.props.globalInformation.users.sort((x: HotecUserAPI, y: HotecUserAPI) => x.getEmail() < y.getEmail() ? -1 : 1), (x) => HotecUserAPI.clone(x), (x) => x.getEmail());
    }

    public distributorsToSelectItems() {
        return toObjectSelectItems<DistributorAPI>(this.props.globalInformation.distributors.sort((x: DistributorAPI, y: DistributorAPI) => x.getName() < y.getName() ? -1 : 1), (x) => DistributorAPI.clone(x), (x) => x.getName());
    }

    private async createEmpty(): Promise<HotecUserBrowserStandin> {
        return new HotecUserBrowserStandin("", "", createDefaultHotecUserProperties());
    }

    private async create(data: HotecUserBrowserStandin) {
        await HotecUserAPI.create(data);
    }

    public render() {

        return <SimpleSettingsTemplate
            title={t`gebruikers.`}
            selectItems={this.usersToSelectItems()}

            reload={() => {
                loadUsers();
                loadDistributors();
            }}
            createEmpty={this.createEmpty.bind(this)}
            create={this.create.bind(this)}
            clone={(i: HotecUserAPI) => HotecUserAPI.clone(i)}

            renderEdit={this.renderEdit.bind(this)}
            renderCreate={this.renderCreate.bind(this)}

            infoScreen={<InfoScreen title={t`Selecteer een gebruiker.`} image={"/cdn/images/log.svg"}/>}
        />;

    }

    private renderEdit(a: HotecUserAPI, setSelected: (i: HotecUserAPI) => void) {
        const assignedPermissionsSelectItems = this.assignedPermissionsToSelectItems(a.getData(), (toDeleteId) => {
            const cl: HotecUserAPI = HotecUserAPI.clone(a);
            this.removeAssignedPermission(cl.getData(), toDeleteId);
            setSelected(cl);
        });
        return this.doRender(
            <MappedInput placeholder={t`Naam`} onBlur={(name) => {
                const cl: HotecUserAPI = HotecUserAPI.clone(a);
                try{
                    cl.getData().setName(name);
                }catch(e){
                    cl.getData().setName("");
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getName(), () => "")} />,
            <MappedInput placeholder={t`E-mailadres`} onBlur={(email) => {
                const cl: HotecUserAPI = HotecUserAPI.clone(a);
                try{
                    cl.getData().setEmail(email);
                }catch(e){
                    cl.getData().setEmail("");
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getEmail(), () => "")} />,
            <>
                <MappedInput type="password" placeholder={t`Wachtwoord`} onBlur={(password) => {
                    const cl: HotecUserAPI = HotecUserAPI.clone(a);
                    try{
                        cl.getData().setPassword(password);
                    }catch(e){
                        cl.getData().setPassword("");
                    }
                    setSelected(cl);
                }} value={inu(a, (a) => a.getPassword(), () => "")} />
                <i>{t`Laat het wachtwoord-veld leeg indien je het wachtwoord niet wilt wijzigen.`}</i>
            </>,
            <PortalDropdown placeholder={t`Verdeler`}
                css={{ compose: PortalDropdownStyle }}
                items={this.distributorsToSelectItems()}
                onSelectionChange={async (data) => {
                    const cl: HotecUserAPI = HotecUserAPI.clone(a);
                    if(data){
                        cl.setDistributorId((data.item as DistributorAPI).getId());
                    }else{
                        cl.setDistributorId(null);
                    }
                    setSelected(cl);
                }}
                selectedId={inun(a, (a) => a.getDistributorId() ? a.getDistributorId()?.toHexString() : null, () => null)}
            />,
            <span>
                <Select placeholder={t`Toegangsrechten`} key={"_permissions"}
                    searchable={false}
                    css={{ compose: PortalSelectStyle }}
                    onSelectionChange={() => {}}
                    maxSelected={0}
                    selectedIds={[]}
                    selectedItems={[]}>
                    {assignedPermissionsSelectItems}
                </Select>
                <PortalDropdown
                    immediatelyClearSelection={true}
                    placeholder={t`Machtiging toevoegen`}
                    css={{ compose: PortalDropdownStyle }}
                    items={this.permissionsToSelectItems(a.getData())}
                    onSelectionChange={(data) => {
                        if(data !== null){
                            const cl: HotecUserAPI = HotecUserAPI.clone(a);
                            this.assignPermission(cl.getData(), data.item);
                            setSelected(cl);
                        }
                    }}
                />
            </span>,
        );

    }

    private renderCreate(a: HotecUserBrowserStandin, setSelected: (i: HotecUserBrowserStandin) => void) {
        const assignedPermissionsSelectItems = this.assignedPermissionsToSelectItems(a, (toDeleteId) => {
            const cl: HotecUserBrowserStandin = a.clone();
            this.removeAssignedPermission(cl, toDeleteId);
            setSelected(cl);
        });
        return this.doRender(
            <MappedInput placeholder={t`Naam`} onBlur={(name) => {
                const cl: HotecUserBrowserStandin = a.clone();
                try{
                    cl.setName(name);
                }catch(e){
                    cl.setName("");
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getName(), () => "")} />,
            <MappedInput placeholder={t`E-mailadres`} onBlur={(email) => {
                const cl: HotecUserBrowserStandin = a.clone();
                try{
                    cl.setEmail(email);
                }catch(e){
                    cl.setEmail("");
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getEmail(), () => "")} />,
            <MappedInput type="password" placeholder={t`Wachtwoord`} onBlur={(password) => {
                const cl: HotecUserBrowserStandin = a.clone();
                try{
                    cl.setPassword(password);
                }catch(e){
                    cl.setPassword("");
                }
                setSelected(cl);
            }} value={inu(a, (a) => a.getPassword(), () => "")} />,
            <PortalDropdown placeholder={t`Verdeler`}
                css={{ compose: PortalDropdownStyle }}
                items={this.distributorsToSelectItems()}
                onSelectionChange={async (data) => {
                    const cl: HotecUserBrowserStandin = a.clone();
                    if(data){
                        cl.setDistributorId((data.item as DistributorAPI).getId());
                    }else{
                        cl.setDistributorId(null);
                    }
                    setSelected(cl);
                }}
                selectedId={inun(a, (a) => a.getDistributorId() ? a.getDistributorId()?.toHexString() : null, () => null)}
            />,
            <span>
                <Select placeholder={t`Toegangsrechten`} key={"_permissions"}
                    searchable={false}
                    css={{ compose: PortalSelectStyle }}
                    onSelectionChange={() => {}}
                    maxSelected={0}
                    selectedIds={[]}
                    selectedItems={[]}>
                    {assignedPermissionsSelectItems}
                </Select>
                <PortalDropdown
                    immediatelyClearSelection={true}
                    placeholder={t`Machtiging toevoegen`}
                    css={{ compose: PortalDropdownStyle }}
                    items={this.permissionsToSelectItems(a)}
                    onSelectionChange={(data) => {
                        if(data !== null){
                            const cl: HotecUserBrowserStandin = a.clone();
                            this.assignPermission(cl, data.item);
                            setSelected(cl);
                        }
                    }}
                />
            </span>,
        );
    }

    private doRender(
        nameInput: React.ReactNode,
        emailInput: React.ReactNode,
        passwordInput: React.ReactNode,
        distributorInput: React.ReactNode,
        permissionsInput: React.ReactNode,
    ) {
        return (<div className={"container-fluid"}>
            <div className="row">
                <div className="col-md-6">
                    <ConfigBlock
                        title={"Gebruikersinformatie"}
                        description="Algemene informatie."
                        topMost={true}
                    >
                        {nameInput}
                        {emailInput}
                        {passwordInput}
                    </ConfigBlock>
                </div>
                <div className="col-md-6">
                    <ConfigBlock
                        title={"Verdeler"}
                        description="Selecteer een verdeler waar deze gebruiker aan toegekend is."
                        topMost={true}
                    >
                        {distributorInput}
                    </ConfigBlock>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <ConfigBlock
                        title={"Toegangsrechten"}
                        description="Ken speciale machtigingen toe aan deze gebruiker."
                        topMost={false}
                    >
                        {permissionsInput}
                    </ConfigBlock>
                </div>
            </div>
        </div>);
    }
}
