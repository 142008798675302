exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2aaeaLc7fmdLngn6j5O8Vz p{\n    font-weight: 300;\n    color: #F1F1F1;\n}\n._2aaeaLc7fmdLngn6j5O8Vz:hover{\n    background-color: transparent;\n    color: #F1F1F1;\n}\n._19lXDI8bHllF3XiPlO7ztR{\n    color: #F1F1F1 !important;\n}", ""]);

// exports
exports.locals = {
	"item": "_2aaeaLc7fmdLngn6j5O8Vz",
	"active": "_19lXDI8bHllF3XiPlO7ztR"
};