exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2EtfDOEzy1ocZvA4ibBZNJ p{\n    font-weight: 300;\n}\n._2EtfDOEzy1ocZvA4ibBZNJ{\n    border-color: #ffbfbf;\n}\n._2EtfDOEzy1ocZvA4ibBZNJ:hover{\n    background-color: #F5F5F5;\n    color: black;\n}\n._10JQo35OOgbuXqUU6zIiU-{\n    color: black !important;\n    border-color: #dc0000;\n}", ""]);

// exports
exports.locals = {
	"item": "_2EtfDOEzy1ocZvA4ibBZNJ",
	"active": "_10JQo35OOgbuXqUU6zIiU-"
};