import MultiLanguageString from "orbiter-core/src/datastructures/languages/MultiLanguageString";
import {default as Supplement, ISupplement} from "../../../../../shared/datastructures/staircasedata/Supplement";
import ApiController from "../ApiController";
import SingleLanguageString from "orbiter-core/src/datastructures/languages/SingleLanguageString";
import Language from "orbiter-core/src/datastructures/languages/Language";

// TODO: Too much code duplication with SupplementDBC
export abstract class SupplementAPI extends ApiController<Supplement> implements ISupplement {

    public static getApiKey(): string{
        return "supplement";
    }

    public async parseFromDictionary(dictionary): Promise<void> {
        const wt = new Supplement(
            new MultiLanguageString(
                dictionary.title.map((x) => {
                    // TODO: LanguageAPI?
                    const l = new Language(
                        x.languageId,
                        null,
                        null,
                    );
                    return new SingleLanguageString(
                        l,
                        x.value,
                    );
                }),
            ),
            new MultiLanguageString(
                dictionary.description.map((x) => {
                    // TODO: LanguageAPI?
                    const l = new Language(
                        x.languageId,
                        null,
                        null,
                    );
                    return new SingleLanguageString(
                        l,
                        x.value,
                    );
                }),
            ),
            dictionary.price,
            dictionary.maxPieces);
        this.setData(wt);
    }

    public getDescription(): MultiLanguageString {
        return this.getData().getDescription();
    }

    public getMaxPieces(): number {
        return this.getData().getMaxPieces();
    }

    public getPrice(): number {
        return this.getData().getPrice();
    }

    public getTitle(): MultiLanguageString {
        return this.getData().getTitle();
    }

    public async setDescription(description: MultiLanguageString): Promise<void> {
        await (await this.getData()).setDescription(description);
        await this.updateProperty("description", description.asList()); // TODO: is this correct?
    }

    public async setMaxPieces(maxPieces: number): Promise<void> {
        await (await this.getData()).setMaxPieces(maxPieces);
        await this.updateProperty("maxPieces", maxPieces);
    }

    public async setPrice(price: number): Promise<void> {
        await (await this.getData()).setPrice(price);
        await this.updateProperty("price", price);
    }

    public async setTitle(title: MultiLanguageString): Promise<void> {
        await (await this.getData()).setTitle(title);
        await this.updateProperty("title", title.asList()); // TODO: is this correct?
    }

}

export class SupplementTreadAPI extends SupplementAPI{

    public static getBaseEndpoint(): string{
        return "hotec/supplement/tread";
    }

}

export class SupplementHandrailAPI extends SupplementAPI{

    public static getBaseEndpoint(): string{
        return "hotec/supplement/handrail";
    }

}

export class SupplementLandingStageAPI extends SupplementAPI{

    public static getBaseEndpoint(): string{
        return "hotec/supplement/landingstage";
    }

}

export class PlacementSupplementAPI extends SupplementAPI{

    public static getBaseEndpoint(): string{
        return "hotec/supplement/placement";
    }

}
