import * as React from "react";
import {connect} from "react-redux";
import {withRouter, RouteComponentProps} from "react-router-dom";
import HMenu from "webc-reactcore/src/js/components/mainlayout/HorizontalMenu";
import MenuItem from "webc-reactcore/src/js/components/mainlayout/MenuItem";
import RightFloatingMenuItem from "webc-reactcore/src/js/components/mainlayout/RightFloatingMenuItem";
import * as horizontalMenuStyle from "../../../css/horizontalMenuStyle.css";
import * as menuItemStyle from "../../../css/menuItemStyle.css";
import * as menuItemStyleWarning from "../../../css/menuItemStyleWarning.css";
import * as rightFloatingMenuItemStyle from "../../../css/rightFloatingMenuItemStyle.css";
import * as rightFloatingMenuItemStyleCancel from "../../../css/rightFloatingMenuItemStyleCancel.css";
import {removeInformation as removeArticleInformation} from "../../actions/articleCreationActions";
import store from "../../store";
import { IArticleCreationStateDispatch } from "../../reducers/articleCreationReducer";

import {t} from 'ttag';
import { performPageValidityCheck } from "../newquote/article/helpers/validityCheckHelper";
import { PageType } from "../../../../../shared/datastructures/IArticleInput";
import LoadingIcon from "../newquote/article/loaders/LoadingIcon";

@(connect((store: any) => {
    return {...store.articleCreation};
}) as any)
class NewArticleMenu extends React.Component<IArticleCreationStateDispatch & RouteComponentProps & {descriptor: string}, any> {

    private defaultCancelState;

    constructor(_) {
        super(_);

        this.defaultCancelState = {
            canClose: false,
            cancelButtonTitle: t`Annuleren`,
            timer: null,
        };

        this.state = {...this.defaultCancelState, validity: {}};
    }

    public componentWillUnmount(){
        if(this.state.timer !== null) {
            clearTimeout(this.state.timer);
        }
    }

    private async checkValidity(nextProps){
        /**
         * Validity checks
         */
        const shapeValid = await performPageValidityCheck(nextProps.article, PageType.SHAPE);
        const staircaseValid = await performPageValidityCheck(nextProps.article, PageType.STAIRCASE);
        const handrailValid = await performPageValidityCheck(nextProps.article, PageType.HANDRAIL);
        const landingStageValid = await performPageValidityCheck(nextProps.article, PageType.LANDING_STAGE);
        const treatmentValid = await performPageValidityCheck(nextProps.article, PageType.TREATMENT);
        const transportationValid = await performPageValidityCheck(nextProps.article, PageType.TRANSPORTATION);
        const generalValid = await performPageValidityCheck(nextProps.article, PageType.GENERAL);

        this.setState({validity:{
            shapeValid,
            staircaseValid,
            handrailValid,
            landingStageValid,
            treatmentValid,
            transportationValid,
            generalValid,
        }})
    }

    componentDidMount(){
        this.checkValidity(this.props);
    }

    componentWillReceiveProps(nextProps){
        this.checkValidity(nextProps);
    }

    public render() {
        const cancelButtonStyle = this.state.canClose ? rightFloatingMenuItemStyleCancel : rightFloatingMenuItemStyle;

        return (
            <HMenu key="newarticlemenu" css={{compose: horizontalMenuStyle}}>
                <MenuItem key="new_article1" to={"/quote/article/" + this.props.descriptor + "/1"} title={t`Trap`} css={{compose: this.state.validity.staircaseValid ? menuItemStyle : menuItemStyleWarning}}/>
                <MenuItem key="new_article2" to={"/quote/article/" + this.props.descriptor + "/2"} title={t`Trapvorm`} css={{compose: this.state.validity.shapeValid ? menuItemStyle : menuItemStyleWarning}}/>
                <MenuItem key="new_article3" to={"/quote/article/" + this.props.descriptor + "/3"} title={t`Leuning`} css={{compose: this.state.validity.handrailValid ? menuItemStyle : menuItemStyleWarning}}/>
                <MenuItem key="new_article4" to={"/quote/article/" + this.props.descriptor + "/4"} title={t`Overloop`} css={{compose: this.state.validity.landingStageValid ? menuItemStyle : menuItemStyleWarning}}/>
                <MenuItem key="new_article5" to={"/quote/article/" + this.props.descriptor + "/5"} title={t`Behandeling`} css={{compose: this.state.validity.treatmentValid ? menuItemStyle : menuItemStyleWarning}}/>
                <MenuItem key="new_article6" to={"/quote/article/" + this.props.descriptor + "/6"} title={t`Transport & Plaatsing`} css={{compose: this.state.validity.transportationValid ? menuItemStyle : menuItemStyleWarning}}/>
                <MenuItem key="new_article7" to={"/quote/article/" + this.props.descriptor + "/7"} title={t`Overzicht`} css={{compose: this.state.validity.generalValid ? menuItemStyle : menuItemStyleWarning}}/>
                <RightFloatingMenuItem key="cancel_new_article" title={this.state.cancelButtonTitle}
                                       css={{compose: cancelButtonStyle}} onClick={() => {
                    if (this.state.canClose) {
                        store.dispatch(removeArticleInformation());
                        this.props.history.push("/quote/2");
                    }else{

                        const timer = setTimeout(() => {
                            this.setState({...this.defaultCancelState});
                        }, 3000);

                        this.setState({canClose: true, cancelButtonTitle: t`Zeker annuleren?`, timer});
                    }
                }}/>
                <LoadingIcon />
            </HMenu>
        );
    }
}

export default withRouter(NewArticleMenu);
