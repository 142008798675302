import ObjectID from "bson-objectid";
import DataController from "orbiter-core/src/datastructures/DataController";
import { VALUE_EXCEPTION } from "orbiter-core/src/exceptions";
import { tDateFromString } from "./simplifiedNetworkCommunicationTypes";

export const QUOTE_CREATED = "QUOTE_CREATED";
export const PDF_GENERATED = "PDF_GENERATED";
export const EMAIL_SENT = "EMAIL_SENT";
export const STATE_UPDATED = "STATE_UPDATED";


export const QUOTE_LOG_ACTIONS: string[] = [QUOTE_CREATED, PDF_GENERATED, EMAIL_SENT, STATE_UPDATED];

export default class QuoteLog extends DataController {

    @DataController.dataProperty()
    private quoteId: ObjectID;

    @DataController.dataProperty()
    private distributorId: ObjectID;

    @DataController.dataProperty()
    private userId: ObjectID | null;

    @DataController.dataProperty()
    private timestamp: tDateFromString;

    @DataController.dataProperty()
    private loggedAction: string;

    @DataController.dataProperty()
    private metadata: any;
    
    public userName?: string;

    public constructor(
        quoteId: ObjectID,
        distributorId: ObjectID,
        userId: ObjectID | null,
        timestamp: tDateFromString,
        loggedAction: string,
        metadata: any,
    ){
        super();

        if(!QUOTE_LOG_ACTIONS.includes(loggedAction)){
            throw VALUE_EXCEPTION;
        }

        this.quoteId = quoteId;
        this.distributorId = distributorId;
        this.userId = userId;
        this.timestamp = timestamp;
        this.loggedAction = loggedAction;
        this.metadata = metadata;
    }

    public getQuoteId(): ObjectID {
        return this.quoteId;
    }

    public getDistributorId(): ObjectID {
        return this.distributorId;
    }

    public getUserId(): ObjectID | null {
        return this.userId;
    }

    public getTimestamp(): tDateFromString {
        return this.timestamp;
    }

    public getLoggedAction(): string {
        return this.loggedAction;
    }

    public getMetadata(): any {
        return this.metadata;
    }

    public static fromObj(obj: any): QuoteLog{
        const ql = new QuoteLog(
            new ObjectID(obj.quoteId),
            new ObjectID(obj.distributorId),
            obj.userId ? new ObjectID(obj.userId) : null,
            new Date(obj.timestamp),
            obj.loggedAction,
            obj.metadata,
        );

        ql.userName = obj.userName;

        return ql;
    }
    
    public async asExtendedDict(): Promise<any> {
        const dict = await super.asDict();
        dict.userName = this.userName;
        return dict;
    }

}