import * as React from "react";
import styleable from "react-styleable";

import * as styles from "../../../css/components/Overview/Warning.css";

/**
 * # Styleable
 * * **warningContainer**
 * * **warningInnerContainer**
 * * **warningMessage**
 * * **warningLabel**
 * 
 * # Props
 * * **message: string**
 * * **buttons: {key: string, title: string}[]**
 * * **children**
 * * **onClick(key)**
 */
class Warning extends React.Component<any> {
    public render() {
            return (
                <div className={this.props.css.warningContainer}>
                    <div className={this.props.css.warningInnerContainer}>
                        <p className={this.props.css.warningLabel}>!</p>
                        <p className={this.props.css.warningMessage}>{this.props.children}</p>
                    </div>
                </div>
            );
    }
}
export default styleable(styles)(Warning);
