import * as React from "react";
import { connect, DispatchProp } from "react-redux";
import { setArticle, setBasicInformation as aSetBasicInformation } from "../../../actions/articleCreationActions";
import { addArticle, setBasicInformation, updateArticle } from "../../../actions/quoteCreationActions";
import Input from "webc-reactcore/src/js/components/mainlayout/PortalInput";

import SubTitle from "../../../components/SubTitle";
import StepTemplate from "../StepTemplate";
import Article from "../../../../../../shared/datastructures/ArticleCalculation";
import { IArticleCreationState } from "../../../reducers/articleCreationReducer";
import RightFloatingMenuItem from "webc-reactcore/src/js/components/mainlayout/RightFloatingMenuItem";
import * as rightFloatingMenuItemStyle from "../../../../css/rightFloatingMenuItemStyle.css";
import { IQuoteCreationState } from "../../../reducers/quoteCreationReducer";
import * as artworkStyle from "../../../../css/artwork.css";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { removeInformation as removeArticleInformation } from "../../../actions/articleCreationActions";
import IArticleInput, { PageType } from "../../../../../../shared/datastructures/IArticleInput";
import ValidityErrorCatcher, { isValid } from "../../../../../../shared/datastructures/articleinput/ValidityErrorCatcher";
import ArticleInput from "../../../../../../shared/datastructures/articleinput/ArticleInput";
import { t } from "ttag";
import { addPageToEmptyValidityCheck } from "./helpers/validityCheckHelper";
import ToggleButton from "../../../components/ToggleButton";
import { DiscountIcon, EyeIcon, NoEyeIcon } from "../../../components/Icon";
import MappedInput from "webc-reactcore/src/js/components/mainlayout/MappedInput";
import ValidityIndicator from "../../../components/Overview/ValidityIndicator";
import { INPUT_NOT_DEFINED, INVALID_TYPE, INVALID_VALUE } from "../../../../../../shared/datastructures/articleinput/inputExceptions";

@(connect((store: any) => {
    return { articleCreation: { ...store.articleCreation }, quoteCreation: { ...store.quoteCreation } };
}) as any)
class Summary extends React.Component<{ articleCreation: IArticleCreationState, quoteCreation: IQuoteCreationState } & DispatchProp & RouteComponentProps, any> {

    private async setBasicArticleInformation(info) {
        await this.props.dispatch(aSetBasicInformation(info));
    }

    private async updateArticle(article: ArticleInput) {
        await this.props.dispatch(setArticle(article));
    }

    private getArticle(): IArticleInput {
        return this.props.articleCreation.article;
    }

    private getArticleInput(): ArticleInput {
        return ArticleInput.from(this.props.articleCreation.article);
    }

    private async addArticleToQuote(article: IArticleInput) {
        await this.props.dispatch(await addArticle(this.props.quoteCreation.quoteId, article));
    }

    private async updateArticleForQuote(article: IArticleInput) {
        await this.props.dispatch(await updateArticle(this.props.quoteCreation.quoteId, article));
    }

    private showDiscount(): boolean {
        return this.props.quoteCreation._localShowDiscount || this.getArticle().discountPercentageStaircase !== 0 || this.getArticle().discountPercentageTreatment !== 0 || this.getArticle().discountPercentageDistribution !== 0;
    }

    private async doCalculations(article: Article) {

        const p = await article.calculate();

        await this.setBasicArticleInformation({
            temp_price_staircase: p.staircasePrice.totalPrice,
            temp_price_handrail: p.handrailPrice.totalPrice,
            temp_price_treatment: p.treatmentPrice.totalPrice,
            temp_price_landingstage: p.landingStagePrice.totalPrice,
            temp_price_placement: p.placementPrice.totalPrice,

            temp_price: p.totalPrice.totalPrice,
        });

    }

    private downloadDebugCsv(article: Article) {
        article.downloadDebugCsv();
    }

    constructor(props) {
        super(props);

        this.state = { validCalculations: false, validity: {} };
    }

    private async validityCheck(props) {
        const discountPercentageValidity = await new ValidityErrorCatcher(() => ArticleInput.discountPercentageValidityCheck(props.articleCreation.article.discountPercentageStaircase, props.articleCreation.article.discountPercentageTreatment, props.articleCreation.article.discountPercentageDistribution))
            .addCatcher(true, INPUT_NOT_DEFINED, t`Kies een geldige waarde.`)
            .addCatcher(true, INVALID_TYPE, t`Geef een getal tussen 0 en 100 in.`)
            .addCatcher(true, INVALID_VALUE, t`Geef een getal tussen 0 en 100 in.`)
            .catchError();

        const validCalculations = await this.isValidForCalculations();

        this.setState({ validity: { discountPercentageValidity }, validCalculations });
    }

    private async isValidForCalculations(): Promise<boolean> {
        const validCalculations = await isValid(async () => await ArticleInput.validityCheck(this.props.articleCreation.article));
        console.log("validCalculations?", validCalculations);

        return validCalculations;
    }

    private async loadArticle() {

        // Once the summary has been loaded, empty values will be indicated as invalid
        addPageToEmptyValidityCheck(PageType.GENERAL);

        const validCalculations = await this.isValidForCalculations();

        if (validCalculations !== false) {
            let article = await Article.init(this.props.articleCreation.article, this.props.quoteCreation.distance, this.props.quoteCreation.distributor.getId(), this.props.quoteCreation.quoteCalculationVersion);
            // console.log(await (this.props.articleCreation.article as ArticleInput).toJson())
            this.doCalculations(article);
            this.setState({ article, validCalculations });
        } else {
            this.setState({ article: null, validCalculations });
        }

        this.validityCheck(this.props);
    }

    public componentDidMount() {
        this.loadArticle();
    }

    private allowRecalculation = false;
    componentWillReceiveProps(nextProps) {
        if (this.allowRecalculation) {
            this.loadArticle();
            this.allowRecalculation = false;
        } else {
            this.validityCheck(nextProps);
        }
    }

    public render() {

        let content;
        if (this.state.validCalculations === false || this.state.article === undefined) {
            content = (
                <span>
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <img className={artworkStyle.img} src={"/cdn/images/oops-missing-information." + t`locale` + ".svg"} />
                    </div>
                    <div className="col-md-2"></div>
                </span>
            )
        } else {
            content = <span>
                <div className="col-md-4">
                </div>
                <div className="col-md-4 boxed">
                    <SubTitle>{t`Overzicht` + " "}

                        <ToggleButton
                            onClick={() => {
                                this.props.dispatch(setBasicInformation({ _localShowPriceDetails: !this.props.quoteCreation._localShowPriceDetails }));
                            }}
                            selected={this.props.quoteCreation._localShowPriceDetails}
                            selectedIcon={<EyeIcon />}
                            unselectedIcon={<NoEyeIcon />}
                        />
                        <ToggleButton
                            onClick={() => {
                                this.props.dispatch(setBasicInformation({ _localShowDiscount: !this.props.quoteCreation._localShowDiscount }));
                            }}
                            selected={this.showDiscount()}
                            selectedIcon={<DiscountIcon />}
                            unselectedIcon={<DiscountIcon />}
                        />

                    </SubTitle>
                    {(() => {
                        if (this.props.quoteCreation._localShowPriceDetails) {
                            return <>
                                <p>{t`Trap`}: {this.props.articleCreation.temp_price_staircase.toFixed(2)}</p>
                                <p>{t`Leuning`}: {this.props.articleCreation.temp_price_handrail.toFixed(2)}</p>
                                <p>{t`Behandeling`}: {this.props.articleCreation.temp_price_treatment.toFixed(2)}</p>
                                <p>{t`Overloop`}: {this.props.articleCreation.temp_price_landingstage.toFixed(2)}</p>
                                <p>{t`Plaatsing`}: {this.props.articleCreation.temp_price_placement.toFixed(2)}</p>
                            </>
                        }
                    })()}
                    <p>{t`Totaal`}: {this.props.articleCreation.temp_price.toFixed(2)}</p>
                    {/* <i>Id: {this.props.articleCreation.article.id}</i> */}

                    {(() => {
                        if (this.showDiscount()) {
                            return <ValidityIndicator
                                {...this.state.validity.discountPercentageValidity}
                            >
                                <div>
                                    <MappedInput placeholder={t`Kortingspercentage trap (%)`}
                                        onBlur={async (discountPercentage: string) => {
                                            let newPercentage = parseFloat(discountPercentage);
                                            if (isNaN(newPercentage)) {
                                                newPercentage = 0;
                                            }
                                            this.allowRecalculation = true;
                                            this.updateArticle(this.getArticleInput().setDiscountPercentageStaircase(newPercentage));
                                        }}
                                        value={this.getArticle().discountPercentageStaircase}
                                    />
                                </div>
                                <div>
                                    <MappedInput placeholder={t`Kortingspercentage behandeling (%)`}
                                        onBlur={async (discountPercentage: string) => {
                                            let newPercentage = parseFloat(discountPercentage);
                                            if (isNaN(newPercentage)) {
                                                newPercentage = 0;
                                            }
                                            this.allowRecalculation = true;
                                            this.updateArticle(this.getArticleInput().setDiscountPercentageTreatment(newPercentage));
                                        }}
                                        value={this.getArticle().discountPercentageTreatment}
                                    />
                                </div>
                                <div>
                                    <MappedInput placeholder={t`Kortingspercentage plaatsing (%)`}
                                        onBlur={async (discountPercentage: string) => {
                                            let newPercentage = parseFloat(discountPercentage);
                                            if (isNaN(newPercentage)) {
                                                newPercentage = 0;
                                            }
                                            this.allowRecalculation = true;
                                            this.updateArticle(this.getArticleInput().setDiscountPercentageDistribution(newPercentage));
                                        }}
                                        value={this.getArticle().discountPercentageDistribution}
                                    />
                                </div>
                            </ValidityIndicator>
                        }
                    })()}
                    <br></br>

                    <br></br>

                    <RightFloatingMenuItem css={{ compose: rightFloatingMenuItemStyle }} onClick={async () => {
                        const article: ArticleInput = this.state.article.getArticleInput();
                        if (article.id) {
                            await this.updateArticleForQuote(article);
                        } else {
                            await this.addArticleToQuote(article);
                        }
                        this.props.dispatch(removeArticleInformation());
                        // Navigate away
                        this.props.history.push("/quote/2");
                    }} title={this.props.articleCreation.article.id ? t`Bijwerken` : t`Toevoegen`} />
                    {/* <RightFloatingMenuItem onClick={async () => {
                        this.downloadDebugCsv(this.state.article);
                    }} title={t`Download controlebestand`} css={{compose: rightFloatingMenuItemStyle}}/> */}

                </div>
                <div className="col-md-4">

                </div>
            </span>
        }

        // TODO: type of input (for example unitCount should be integer)
        return (
            <StepTemplate pageType={PageType.GENERAL} title={t`overzicht.`}>
                <div className={"container-fluid"}>
                    <div className="row">
                        {content}
                    </div>
                </div>
            </StepTemplate>
        );
    }
}
export default withRouter(Summary);
