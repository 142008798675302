import * as React from "react";
import styleable from "react-styleable";

import * as styles from "../../../css/components/Overview/StatusIcon.css";

/**
 * Status icon.
 *
 * ## styleable
 * * **icon** Status icon styling.
 *
 * @property {string} props.status - Order status
 *
 */
class StatusIcon extends React.Component<any> {
    public render() {
        let iconUrl;
        let iconColor;
        
        switch (this.props.status) {
            case "QUOTE_DRAFT": {
                iconUrl = "/cdn/portal/assets/icons/white/pen-4.svg";
                iconColor = "#B355C1";       
                break;
            }
            case "QUOTE_SENT": {
                iconUrl = "/cdn/portal/assets/icons/white/envelope.svg";
                iconColor = "#B355C1";       
                break;
            }
            case "QUOTE_CONFIRMED": {
                iconUrl = "/cdn/portal/assets/icons/white/check.svg";
                iconColor = "#B355C1";       
                break;
            }
            case "ORDER_DRAFT": {
                iconUrl = "/cdn/portal/assets/icons/white/pen-4.svg";
                iconColor = "#3F3F3F";       
                break;
            }
            case "ORDER_SENT": {
                iconUrl = "/cdn/portal/assets/icons/white/envelope.svg";
                iconColor = "#3F3F3F";       
                break;
            }
            case "ORDER_CONFIRMED": {
                iconUrl = "/cdn/portal/assets/icons/white/check.svg";
                iconColor = "#3F3F3F";       
                break;
            }
            default:
                break;
        }

        return (
            <div className={this.props.css.icon} title={this.props.status}
                 style={{
                     backgroundImage: "url(\"" + iconUrl + "\")",
                     backgroundColor: iconColor,
                 }}
            />
        );
    }
}
export default styleable(styles)(StatusIcon);
